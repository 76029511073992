import React from 'react';
//import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CForm,
	CInputGroup,
	CFormInput,
	CButton,
	CTooltip,
	CImage,
	CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';

const pjson     = require('../../../package.json');
const Headersubkasselisih = (props) => {
	//const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uIsEOMExecuted= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp		= useSelector(state => state.effectApp);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const [uKeyword,setKeyword] = React.useState(uKeywordObj.kwd_kasselisih || "");

	const hdlKeydown=(_EV)=>{
		if(_EV.which===13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari=()=>{
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_kasselisih = uKeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikTambah=()=>{
		uHeaderActionObj.isHeaderTambah = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_kasselisih = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}

	//console.log("("+uComponentname+") Checked..");
	return(
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+uActiveroute+".png"} 
				className="me-1 align-middle"
				height={30}/>
			<small className="fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup 
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CFormInput size="sm" 
					value={uKeyword} 
					onChange={(e) => setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKeydown(e)} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} 
					id="inpkeyword"/>
				<CButton size="sm" color="light" 
					className="border"
					onClick={()=>hdlKlikCari()} 
					id="btnCari">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
			</CForm>

			<div className="d-none d-md-block ms-1">
			<span className="mx-1 d-none d-lg-inline">&middot;</span>

			{(uIsEOMExecuted) ? (
			<CTooltip content={(uBahasaObj.caption_inputdata || "caption_inputdata")+": "+uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}>
				<CLink className="classikon classikontambah classcursorpointer" 
					onClick={()=>hdlKlikTambah()}/>
			</CTooltip>
			) : (
			<span className="classblinking">
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui" height={30}/>
			</span>
			)}

			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
			</CTooltip>
			<span className="mx-1">&middot;</span>
			<b className="me-1" id="idjmldata">0</b>{uBahasaObj.word_data||"word_data"}
			</div>

			<div className="d-md-none ms-1">
			{(uIsEOMExecuted) ? (
				<CLink className="classikon classikontambah" onClick={()=>hdlKlikTambah()}/>
			) : (
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui classblinking" height={30}/>
			)}
			</div>
		</div>
		</>
	)//>
}

export default Headersubkasselisih;