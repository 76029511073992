import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {
	CForm,
	CInputGroup,
	CFormInput,
	CButton,
	CTooltip,
	CLink,
	CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';

const pjson     = require('../../../package.json');
const Headersubkaryawan = (props) => {
	const uDispatch			= useDispatch();

	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uEfekapp			= useSelector(state => state.effectApp);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	//const [isHeaderView,setHeaderView] 	= React.useState(false);
	const [uKeyword,setKeyword] 		= React.useState(uKeywordObj.kwd_karyawan || "");

	const hdlKeydown=(_EV)=>{
		if(_EV.which === 13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") && 
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari=()=>{
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_karyawan = uKeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/
		//setHeaderView(true);

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikTambah=()=>{
		//uDispatch({type: "set", isHeaderTambah: true});

		uHeaderActionObj.isHeaderTambah = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset=async()=>{
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_karyawan = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//prosesReset(); return;
		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}

	//console.log("("+uComponentname+") Checked..");
	return(
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+uActiveroute+".png"}
				className="align-middle me-2" 
				height={30} />
			<small className="fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup 
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CFormInput size="sm"
					value={uKeyword} 
					onChange={(e) => setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKeydown(e)} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."}
					id="inpkeyword" name="inpkeyword"/>
					<CButton size="sm" color="light" className="border" 
						onClick={()=>hdlKlikCari()} 
						id="btnCari">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
				</CInputGroup>
			</CForm>

			<div className="d-none d-md-block ms-1">
			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			<CTooltip content={uBahasaObj.caption_tambahdata || "caption_tambahdata"}>
				<CLink onClick={()=>hdlKlikTambah()} className="classikon classikontambah classcursorpointer"/>
			</CTooltip>

			<span className="mx-1 d-none d-lg-inline">&middot;</span>

			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
			</CTooltip>
			<span className="me-1">&middot;</span>
			<b id="idjmldata" className="me-1">0</b>{uBahasaObj.word_data||"word_data"}
			</div>

			<div className="d-md-none ms-1">
				<CLink onClick={()=>hdlKlikTambah()} className="classikon classikontambah"/>
			</div>
		</div>
		</>
	);
}

export default Headersubkaryawan;