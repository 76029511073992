import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CFooter,
	CRow,
	CCol,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPage500,
	MyDialoglookup,
	MyButton,
	MyProfileDetil,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../../package.json');
const Kasbontambah 	= (props) => {
	const uComponentname	= Kasbontambah.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif 
	} = props; 

	let uFuncname;
	const uNavigate			= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uDefaultSetting	= useSelector(state => state.gListDefaultSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);

	const uKasGLArr							= JSON.parse(localStorage.getItem("kasgllist") || "[]");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uKaryawanArr,setKaryawanArr]		= React.useState([]);

	const [uInpStatustembak,setInpStatustembak]= React.useState("REGULER");
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate());
	const [uInpKaryawanID,setInpKaryawanID]	= React.useState("0");
	const [uInpKaryawancaption,setInpKaryawancaption]= React.useState("");
	const [uInpKasID,setInpKasID]			= React.useState("0")
	const [uInpKascaption,setInpKascaption]	= React.useState("");
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");
	const [uInpNilai,setInpNilai]			= React.useState(0);
	const [uIsFormprocess,setIsFormprocess]	= React.useState(false);

	//--DLG_LOOKUP--/
	const [uDlglookupjenis,setDlglookupjenis]	= React.useState("");
	const [uDlglookupheader,setDlglookupheader]	= React.useState("");
	const [uInpDlglookup,setInpDlglookup]		= React.useState("");
	const [uDataDlglookupArr,setDataDlglookupArr]= React.useState([]);
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false);
	//--END DLG_LOOKUP--/

	//---HANDEL--/
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			_EV.preventDefault();
			hdlKlikSimpan();
		}
	}
	const hdlKlikSimpan=()=>{
		//---VALIDASI--/
		if(UFunc.isEmpty(uInpTanggal)) {
			document.getElementById("inptanggal").focus();
			showToast(uBahasaObj.caption_tanggal+" "+uBahasaObj.caption_invalid+"..");
			return;
		} else {
			const vTanggalDt 	= new Date(uInpTanggal);

			const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
			if(vDateBeyond) {
				document.getElementById("inptanggal").focus();
				showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
				return;
			}
			const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
			if(vDateExceededBl) {
				document.getElementById("inptanggal").focus();
				showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
				return;
			}
			//alert("("+uComponentname+") "+cFuncname+" vDateExceededBl = "+vDateExceededBl); return;
		}
		if((parseInt(uInpNilai)||0) <= 0) {
			document.getElementById("inpnilai").focus();
			showToast((uBahasaObj.word_nilai||"word_nilai")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		if((parseInt(uInpKaryawanID)||0) === 0) {
			document.getElementById("inpkaryawancaption").focus();
			showToast((uBahasaObj.caption_namakaryawan||"caption_namakaryawan")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		if((parseInt(uInpKasID)||0) === 0) {
			document.getElementById("inpkascaption").focus();
			showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		if((uInpKeterangan||"") === "") {
			document.getElementById("inpket").focus();
			showToast((uBahasaObj.word_keperluan||"word_keperluan")+ 
				" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+
				"..");
			return;
		}
		//---END VALIDASI--/

		apiProsessimpan();
	}
	const hdlToggleDlglookup = () => { setDlglookupshow(false) }
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		const vIdx = UFunc.getObjectIndeks(uDataDlglookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		let vElsfocusnext;
		if(uDlglookupjenis==="KAS") {
			vElsfocusnext = document.getElementById("inpket");
			setInpKasID(uDataDlglookupArr[vIdx].id)
			setInpKascaption(uDataDlglookupArr[vIdx].caption)
		} else if(uDlglookupjenis==="KARYAWAN") {
			vElsfocusnext = document.getElementById("inpnilai");
			setInpKaryawanID(uDataDlglookupArr[vIdx].id)
			setInpKaryawancaption(uDataDlglookupArr[vIdx].caption)
		}
		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			vElsfocusnext && vElsfocusnext.focus()
		},350);
	}
	const hdlKlikKaslookup = () => {
		//setInpDlglookup(uInpKascaption);
		setInpKascaption(""); setInpKasID("");
		const vTmpArr	= uKasGLArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDlglookupjenis("KAS")
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_asalkas||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKlikKaryawanlookup = () => {
		//setInpDlglookup(uInpKascaption);
		setInpKaryawancaption(""); setInpKaryawanID("0");
		const vTmpArr	= uKaryawanArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDlglookupjenis("KARYAWAN")
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.word_karyawan||"").toUpperCase());
		setDlglookupshow(true)
	}
	//---END HANDEL--/

	//--INIT--/
	const initFormAwal=()=>{
		setInpStatustembak("REGULER");
		setInpTanggal(UFunc.DbDate());
		setInpKasID("0");
		setInpKascaption("");
		setInpKaryawanID("0");
		setInpKaryawancaption("");
		setInpKeterangan("");
		setInpNilai(0);
	}
	const initContentForm = () => {
		if(uHTMLError500) return ""
		
		return (
			<CForm className="px-lg-5">

			{uTokenObj.userhak === "FREEROLE" && (
			<>
			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
			</CCol>
			<CCol xs="8" lg="4">
				<CFormSelect size="sm"
						onChange={(e)=>setInpStatustembak(e.target.value)}
						value={uInpStatustembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
				</CFormSelect>

				<div className="classpetunjuk">
					<small>*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL</small>
				</div>
			</CCol>
			</CRow>

			<div className="mx-0 my-3 classborderbottom"/>
			</>
			)}

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_tanggal||"word_tanggal"}
				</CCol>
				<CCol xs="8" md="5" lg="4">
					<CFormInput size="sm"
						type="date"
						onChange={(e)=>setInpTanggal(e.target.value)}
						value={uInpTanggal}
						name="inptanggal"
						id="inptanggal"/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_namakaryawan||"caption_namakaryawan"}
				</CCol>
				<CCol xs="8" md="5" lg="4">
					<CInputGroup size="sm">
						<CFormInput size="sm"
							value={uInpKaryawancaption}
							onClick={()=>hdlKlikKaryawanlookup()}
							placeholder={"--"+uBahasaObj.word_pilih+": "+(uBahasaObj.caption_namakaryawan)+".."}
							id="inpkaryawancaption"/>
					<CInputGroupText className="p-0 bg-dark">
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikKaryawanlookup()}
							id="btnkaryawanlookup">
						<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
					</CInputGroupText>
					</CInputGroup>
					<div className="classpetunjuk">*) {uBahasaObj.petunjuk_karyawannotexist||"petunjuk_karyawannotexist"}</div>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_jmlkasbon||"caption_jmlkasbon"}
				</CCol>
				<CCol xs="8" md="5" lg="4">
					<NumberFormat 
						value={uInpNilai}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpNilai(values.value)}
						renderText={values => (
							<CFormInput 
								value={values}
								size="sm"
								className="text-end"
								onChange={(e) => setInpNilai(e.target.value)}
								onFocus={(e)=>e.target.select()}
							id="inpnilai"/>
						)} 
						prefix={'Rp'}/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_asalkas||"caption_asalkas"}
				</CCol>
				<CCol xs="8" md="5" lg="4">
					<CInputGroup size="sm">
						<CFormInput size="sm"
							value={uInpKascaption}
							onClick={()=>hdlKlikKaslookup()}
							placeholder={"--"+uBahasaObj.word_pilih+": "+(uBahasaObj.caption_asalkas)+".."}
							id="inpkascaption"/>
					<CInputGroupText className="p-0 bg-dark">
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikKaslookup()}
							id="btnkaslookup">
						<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
					</CInputGroupText>
					</CInputGroup>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_keperluan||"word_keperluan"}
				</CCol>
				<CCol>
					<CFormInput maxLength={255} 
						size="sm"
						type="text"
						onChange={(e)=>setInpKeterangan(e.target.value)}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpanmain")}
						value={uInpKeterangan}
						name="inpket"
						id="inpket"/>
					{uTokenObj.userhak==="FREEROLE" && (
					<div className="classpetunjuk">*) {UFunc.renderHTML(uBahasaObj.petunjuk_iskasbonpermanen||"petunjuk_iskasbonpermanen")}</div>
					)}
				</CCol>
			</CRow>
			</CForm>
		)
	}
	//--END INIT--/

	//--API--/
	const apiLoadawal = () => {
		uFuncname = apiLoadawal.name;
		setHTMLError500();

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		//setKasGLArr([]);
		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasbon/ld_init";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setIsFormprocess(false);
				if(output_string.karyawanobject) {
					const vTmpObj = JSON.parse(output_string.karyawanobject);
					setKaryawanArr(vTmpObj);
				}
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
				//showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
				//showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		/*//--TESTING_FRONTEND--//
		initFormAwal();
		showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
		if(!isMobile) document.getElementById("inptanggal").focus();
		return;
		//--END TESTING_FRONTEND--//*/

		const vIdx 			= UFunc.getObjectIndeks(uKaryawanArr,uInpKaryawanID,"value");
		const vKaryawannama	= uKaryawanArr[(vIdx || 0)].caption;
		//alert(vKaryawannama);return;

		const vDATAS	= JSON.stringify({
			send_statusjurnal: uInpStatustembak,
			send_tanggal 	: uInpTanggal,
			send_kasid 		: uInpKasID,
			send_karyawanid : uInpKaryawanID,
			send_karyawannama : vKaryawannama,
			send_keterangan : uInpKeterangan,
			send_nilai		: uInpNilai,
			send_tokenauth 	: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasbon/pr_simpaninput";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setIsFormprocess(false);

			if(output_string.sukses) {
				const vPesan = output_string.pesankhusus || (uBahasaObj.pesan_sukses || "pesan_sukses");
				showToast(vPesan,"SUKSES");
				initFormAwal();

				if(!isMobile) document.getElementById("inptanggal").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setIsFormprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);

		return () => {
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadawal();

		if(!isMobile) {
			const vElsTanggal = document.getElementById("inptanggal");
			vElsTanggal && vElsTanggal.focus();
		}

		return () => {
			hdlToggleDlglookup()
			setDataDlglookupArr([])
			setKaryawanArr([])
			initFormAwal();
		}
	},[]);
	//---END EFFECT---/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() => uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={25}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_kasbon||"menus_kasbon").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">
			<CContainer xxl className="p-0">
				{(uHTMLError500) ? (
					<MyPage500 content={uHTMLError500} 
						isWithrefresh={true}
						hdlReset={()=>apiLoadawal()}
						{...props}/>
				) : (
					<CCard className="mx-lg-5 classcardbg">
					<CCardHeader>
						<CIcon className="classikon classikontambah me-2" height="30" />
						<small>{uBahasaObj.caption_inputkasbonbaru||"caption_inputkasbonbaru"}</small>
					</CCardHeader>
					<CCardBody>{initContentForm()}</CCardBody>
					</CCard>
				)}
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uIsFormprocess}
				onClick={()=>hdlKlikSimpan()}
				id="btnSimpanmain">Simpan [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlglookupheader}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>
		</>
	)
}

export default Kasbontambah;
