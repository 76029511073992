import React from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderToggler,
	CHeaderBrand,
	CHeaderNav,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CFooter,
	CForm,
	CRow,
	CCol,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CCardFooter,
	CFormCheck,
	CButton,
	CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyProfileDetil,
	MyPage500,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

let pjson     		= require('../../../package.json');
const Stokopnameset = (props) => {
	const uComponentname	= Stokopnameset.name;
	const { 
		prosesExpired,setLoading, showToast, setSessionaktif
	} = props; 
	const { barang_id }		= useParams();
	let uFuncname;

	const uNavigate			= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview]			= React.useState(false);

	const uBarangID			= parseInt(barang_id)||0;

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uKasGLArrObj,setKasGLArrObj]		= React.useState(JSON.parse(localStorage.getItem("kasgllist")));
	const [uDataInitObj,setDataInitObj]		= React.useState({});
	/*
		uInitTabelID: parseInt(uBarangID)||0,
		uInitNamaBarang: null,
		uInitStokAkhir: null,
		uInitSatuan: null,
	});//-*/

	const [uFormfieldObj,setFormfieldObj]	= React.useState({});

	const [uIsFormloading,setIsFormloading]	= React.useState(false);

	//---HANDEL--/
	const hdlKlikReset = () => { apiLoadGL(); }
	const hdlKlikSimpan=()=>{
		//---VALIDASI--/
		if((parseInt(uFormfieldObj.selisih)||0) === 0) { return; }
		if((parseInt(uFormfieldObj.jml_riil)||0) < 0) {
			document.getElementById("inpjmlriil").focus();
			showToast((uBahasaObj.caption_jmlriil||"caption_jmlriil")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		if((parseInt(uFormfieldObj.nilai_hpp)||0) < 0) {
			document.getElementById("inphpplast").focus();
			showToast((uBahasaObj.caption_estimasihpp||"caption_estimasihpp")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		//---END VALIDASI--/

		apiProsessimpan();
	}
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			_EV.preventDefault();
			document.getElementById("btnSimpan") && hdlKlikSimpan();
		}
	}
	//---END HANDEL--/

	//--INIT--/
	const initFormAwal=()=>{
		uFormfieldObj.jml_sisa 		= parseInt(uDataInitObj.uInitStokAkhir)||0;
		uFormfieldObj.nilai_hpp		= parseInt(uDataInitObj.uInitHPPTerakhir)||0;
		uFormfieldObj.selisih		= 0;
		uFormfieldObj.status_opname	= "";
		uFormfieldObj.batch_new		= "";
		uFormfieldObj.expdate_new	= "";
		uFormfieldObj.keterangan 	= "";
	}
	//--END INIT--/

	const contentMain = () => {
		if(uHTMLError500) return "";
		
		return (
		<CForm className="mx-lg-5 px-lg-5">
		<CCard className="classcardbg mb-3">
			<CCardHeader className="classfontsmaller py-1">
			<CRow className="mx-0">
			<CCol xs="5" className="px-1 d-md-none">{uBahasaObj.caption_setstokopname||"caption_setstokopname"}</CCol>
			<CCol xs="7" className="px-1 text-end text-md-start">
				<span className="d-none d-md-inline-block">{uBahasaObj.caption_setstokopname||"caption_setstokopname"}:&nbsp;</span>
				<b>{UFunc.HariAngka()}</b>
			</CCol>
			{(!uIsFormloading && !uHTMLError500) && (
			<>
			<CCol xs="5" className="px-1 d-md-none">{uBahasaObj.caption_stoksistem||"caption_stoksistem"}</CCol>
			<CCol xs="7" className="px-1 d-md-none text-end text-md-start">
			<span className="text-success">{UFunc.formatAngka(uDataInitObj.uInitStokAkhir)} {uDataInitObj.uInitSatuan}</span>
			</CCol>

			<CCol xs="5" className="px-1 d-md-none">{uBahasaObj.caption_jmlproses||"Jml Proses"}</CCol>
			<CCol xs="7" className="px-1 d-md-none text-end">
			<span className="text-primary">{UFunc.formatAngka(uDataInitObj.jml_proses)} {uDataInitObj.uInitSatuan}</span>
			</CCol>
			</>
			)}
			</CRow>
			</CCardHeader>

			<CCardBody>
			<CRow className="my-2">
				<CCol xs="6" md="4" className="text-start text-md-end text-info">
					{uBahasaObj.caption_jmlriil||"caption_jmlriil"}
				</CCol>
				<CCol xs="6" md="3" lg="2" className="">
					<CInputGroup size="sm">
						<NumberFormat 
							value={uFormfieldObj.jml_riil||"0"}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							allowNegative={false}
							onValueChange={(values) => uFormfieldObj.jml_riil=(values.value)}
							renderText={values => (
								<CFormInput size="sm"
									value={values}
									className="text-end"
									onChange={(e) => {uFormfieldObj.jml_riil=e.target.value;setEfekview(!uEfekview)}}
									onFocus={(e)=>e.target.select()}
								id="inpjmlriil"/>
							)} 
							prefix={""}/>
					<CInputGroupText>{uDataInitObj.uInitSatuan||"PCS"}</CInputGroupText>
					</CInputGroup>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol md="4" className="text-start text-md-end">
					{uBahasaObj.word_keterangan||"word_keterangan"}
				</CCol>
				<CCol md="8" className="">
					<CFormInput size="sm" maxLength={255}
						value={uFormfieldObj.keterangan||""}
						className="text-capitalize"
						onChange={(e) => {uFormfieldObj.keterangan=(e.target.value);setEfekview(!uEfekview)}}
						id="uInpKeterangan"/>
				</CCol>
			</CRow>
			</CCardBody>
		</CCard>

		<CCard className="classcardbg mb-3">
			<CCardHeader className="classfontsmaller py-1">
			<span>{uBahasaObj.caption_statusopname||"caption_statusopname"}: </span>
			<b className={uFormfieldObj.status_opname==="TAMBAH"?"text-primary":"text-danger"}>
			{uFormfieldObj.status_opname==="TAMBAH"?(uBahasaObj.caption_stoklebih||"caption_stoklebih"):(uFormfieldObj.status_opname==="KURANG"?(uBahasaObj.caption_stokkurang||"caption_stokkurang"):(uBahasaObj.word_unknown||"word_unknown")).toUpperCase()}
			</b>
			</CCardHeader>

			<CCardBody>
				<CRow className="my-2">
					<CCol xs="4" className="text-start text-md-end">
					{uBahasaObj.word_selisih||"word_selisih"}
					</CCol>
					<CCol xs="8" md="4" className={"text-end text-md-start"+(uFormfieldObj.status_opname==="TAMBAH"?" text-primary":" text-danger")}>
						<strong>{UFunc.formatAngka(uFormfieldObj.selisih)}</strong>{" "}
						{uDataInitObj.uInitSatuan||"PCS"}
					</CCol>
				</CRow>
				
				<div className="d-md-none my-3 classborderbottom"/>

				<CRow className={"my-2 mb-0"+(uFormfieldObj.status_opname!=="TAMBAH"?" bg-gray-100":"")}>
					<CCol xs="4" className="text-start text-md-end">
					{uBahasaObj.caption_batchno||"caption_batchno"}
					</CCol>
					<CCol xs="8" md="5" lg="4" className="">
						<CFormInput maxLength={15} 
							size="sm"
							value={uFormfieldObj.batch_new||""}
							onChange={(e) => {uFormfieldObj.batch_new=(e.target.value);setEfekview(!uEfekview)}}
							readOnly={uFormfieldObj.status_opname!=="TAMBAH"}
							className="text-uppercase"
							id="inpbatchno"/>
					</CCol>
				</CRow>
				<CRow className="my-2 mt-0">
					<CCol xs="4" className=""/>
					<CCol xs="8" md="8">
						<div className="classpetunjuk">
						<small>*) {uBahasaObj.petunjuk_batchno||"petunjuk_batchno"}</small>
						</div>
					</CCol>
				</CRow>

				<CRow className={"my-2"+(uFormfieldObj.status_opname!=="TAMBAH"?" bg-gray-100":"")}>
					<CCol xs="4" className="text-start text-md-end">
					{uBahasaObj.caption_expdate||"caption_expdate"}
					</CCol>
					<CCol xs="8" md="5" lg="4" className="">
						<CFormInput type="date" size="sm"
							readOnly={uFormfieldObj.status_opname!=="TAMBAH"}
							value={uFormfieldObj.expdate_new||""}
							onChange={(e) => {uFormfieldObj.expdate_new=(e.target.value);setEfekview(!uEfekview)}}
							id="inpexpdate"/>
					</CCol>
				</CRow>

				<CRow className={"my-2"+(uFormfieldObj.status_opname!=="TAMBAH"?" bg-gray-100":"")}>
					<CCol xs="4" className="text-start text-md-end text-info">
					{uBahasaObj.caption_estimasihpp||"caption_estimasihpp"}
					</CCol>
					<CCol xs="8" md="5" lg="4" className="">
						<NumberFormat 
							value={uFormfieldObj.nilai_hpp||"0"}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							allowNegative={false}
							onValueChange={(values) => uFormfieldObj.nilai_hpp=(values.value)}
							renderText={values => (
								<CFormInput 
									value={values}
									size="sm"
									className="text-end"
									readOnly={uFormfieldObj.status_opname!=="TAMBAH"}
									onChange={(e) => {uFormfieldObj.nilai_hpp=(e.target.value);setEfekview(!uEfekview)}}
									onFocus={(e)=>e.target.select()}
									onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
								id="inphpplast"/>
							)} 
							prefix={isMobile ? "" :"Rp"}/>
							<div className="classpetunjuk">
							<small>*) {uBahasaObj.caption_lasthpp||"caption_lasthpp"} Rp {UFunc.formatAngka(uDataInitObj.uInitHPPTerakhir)}</small>
						</div>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
		</CForm>
		)//>
	}

	//--API--/
	const apiLoadGL = () => {
		uFuncname = apiLoadGL.name;
		setHTMLError500();

		setLoading(true);
		setIsFormloading(true);

		setDataInitObj({});
		initFormAwal();
		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_barangid : uBarangID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_stokopname/ld_initform";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setIsFormloading(false);
				let vTmpObj = [];
				if(output_string.barangobject) {
					vTmpObj = JSON.parse(output_string.barangobject);
					setDataInitObj(vTmpObj);
				}
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setIsFormloading(false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		const vElbtnsimpan	= document.getElementById("btnSimpan");
		vElbtnsimpan && (vElbtnsimpan.disabled=true);
		setLoading(true);

		/*//--TESTING_FRONTEND--//
		const vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
		showToast(vPesan,"SUKSES");
		uNavigate(-1);
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_barangid: uBarangID,
			send_datalist: JSON.stringify(uFormfieldObj),
			send_tokenauth : uTokenObj.userinit
		});

		const vURLs	= pjson.homepage+"api/api_stokopname/pr_simpan";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			vElbtnsimpan && (vElbtnsimpan.disabled=false);
			setLoading(false);

			if(output_string.sukses) {
				const vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				showToast(vPesan,"SUKSES");
				uNavigate(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbtnsimpan && (vElbtnsimpan.disabled=false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);

		return () => {
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		
		apiLoadGL();

		if(!isMobile) {
			const vElsFocus = document.getElementById("inpjmlriil");
			if(!UFunc.isEmpty(vElsFocus)) vElsFocus.focus();
		}

		return ()=>{
			setDataInitObj({})
		}
	},[]);
	React.useEffect(()=>{ 
		uFormfieldObj.jml_riil 		= parseInt(uDataInitObj.uInitStokAkhir)||0;
		initFormAwal() 
		setEfekview(!uEfekview);
	},[uDataInitObj]);
	React.useEffect(()=>{
		if(uFormfieldObj.jml_riil === undefined) return;

		const vJmlriil	= parseInt(uFormfieldObj.jml_riil)||0;
		const vJmlsisa	= parseInt(uFormfieldObj.jml_sisa)||0;

		const vSelisih = vJmlsisa - vJmlriil;

		if(vSelisih > 0) {
			initFormAwal();
			uFormfieldObj.status_opname = ("KURANG");
			uFormfieldObj.selisih = vSelisih;
		} else if(vSelisih < 0) {
			uFormfieldObj.status_opname = ("TAMBAH");
			uFormfieldObj.selisih = Math.abs(vSelisih); 
		} else {
			initFormAwal();
		}
		setEfekview(!uEfekview);
	},[uFormfieldObj,uFormfieldObj.jml_riil]);
	//---END EFFECT---/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return "" }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="classheadermain border-bottom" style={{minHeight:64}}>
			<CHeaderToggler className="px-0" onClick={() => {uNavigate(-1);}}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_stokopname||"menus_stokopname").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="p-2 px-3" style={{minHeight:52}}>
			{uIsFormloading ? (
				<div className="mx-auto text-primary text-center classblinking">Loading...</div>
			) : (
			<>
				{uHTMLError500 ? (
					<b className="mx-auto">Oops,Sorry There is a problems..</b>
				) : (
				<>
				<div>
					<span className="text-info fw-bolder">{uDataInitObj.uInitNamaBarang||"UNDEFINED BARANG"}</span>
					<span className="mx-1 d-none d-md-inline-block">&middot;</span>
					<span className="d-none d-md-inline-block classfontsmaller">
						{uBahasaObj.caption_stoksistem||"caption_stoksistem"}:{" "}
						<span className="text-success">
						{UFunc.formatAngka(uDataInitObj.uInitStokAkhir)||"0"}{" "}{uDataInitObj.uInitSatuan||"PCS"}
						</span>
					</span>
				
					<span className="mx-1 d-none d-md-inline-block">&middot;</span>

					<span className="d-none d-md-inline-block classfontsmaller">
					Proses: <span className="text-primary">
					{UFunc.formatAngka(uDataInitObj.jml_proses)||"0"}{" "}{uDataInitObj.uInitSatuan||"PCS"}
					</span>
					</span>
				</div>
				
				<CLink
					className="classcursorpointer classikon classikonreset"
					onClick={()=>hdlKlikReset()}/>
				</>
				)}
			</>
			)}
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
			<CContainer xxl className="">
			{(uHTMLError500) ? (
				<MyPage500 content={uHTMLError500} 
					isWithrefresh={true} 
					hdlReset={()=>hdlKlikReset()}
					{...props}/>
			) : (<>{contentMain()}</>)}
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uIsFormloading || uHTMLError500 
					|| (parseInt(uFormfieldObj.jml_riil) === parseInt(uFormfieldObj.jml_sisa))}
				onClick={()=>hdlKlikSimpan()}
				id="btnSimpan">Simpan [F9]</MyButton>
		</CFooter>
		</div>
		</>
	)//>
}

export default Stokopnameset;
