import React from 'react';

const cHariPendek 	= ["Min","Sen","Sel","Rab","Kam","Jum","Sab"];
const cHariPanjang 	= ["Minggu","Senin","Selasa","Rabu","Kamis","Jumat","Sabtu"];
const cKeycodebypass= [
	112,113,114,115,116,117,118,119,120,121,122,123,9,20,16,17,18,27,37,38,39,40
	];
let vTmpTgl		= [];
for(let i=1;i<=31;i++) { vTmpTgl.push(i)}
const cAngkaTgl = vTmpTgl;
const cBulanPendek = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"Mei",
	"Jun",
	"Jul",
	"Agu",
	"Sep",
	"Okt",
	"Nov",
	"Des"
];
const cBulanPanjang = [
	"Januari",
	"Februari",
	"Maret",
	"April",
	"Mei",
	"Juni",
	"Juli",
	"Agustus",
	"September",
	"Oktober",
	"November",
	"Desember"
];

const UFunc = {
handleErrors: function(response) {
	if (!response.ok) {
		throw Error(response.statusText);
	}
	return response;
},
formatAngka: function(_VARS){
	_VARS = parseFloat(_VARS) || 0;
	return _VARS.toLocaleString("ID", {maximumFractionDigits:2}); // "1,234.57"
},
isEmpty: function(val){
    return (val === undefined
        || typeof(val) === "undefined"
        || val === null
        || val === "NaN"
        //|| val === NaN
        //|| isNaN(val)===true
        || Object.keys(val).length === 0
        || val.length <= 0) ? true : false;
},
nl2br: function(str) {
	if(UFunc.isEmpty(str)) return "";
    return str.replace(/\n/g,"<br/>");
},
br2nl: function(str) {
	if(UFunc.isEmpty(str)) return "";
	
    var HASIL    = str;
    HASIL        = HASIL.replace(/<br\s*\/?>/mg,"\n");
    HASIL        = HASIL.replace(/<BR\s*\/?>/mg,"\n");

    return HASIL;
},
removeTAGS: function(str) {
	if(UFunc.isEmpty(str)) return "";

	var div = document.createElement("div"); 
	div.innerHTML = str;

	return (div.textContent || div.innerText || "");
},
capitalize: function(str){

	return str.charAt(0).toUpperCase() + str.slice(1);
},
filterRegex: function(_KEYWORD) {
	_KEYWORD		= _KEYWORD||"";
	var vInvalidchr = /[°"§%()\[\]{}=\\?´`'#<>|,;:+_-]+/g;

	return _KEYWORD.replace(vInvalidchr,"");
},
getObjectIndeks: function(_OBJECTDATA,_DATASRC,_NAMAOBJ){
	const vHasil = -1;
	_NAMAOBJ = (_NAMAOBJ || "id").trim();

	_DATASRC = (_DATASRC || "").toString().trim();
	if(_DATASRC === "") return vHasil;
	if((_OBJECTDATA||[]).length <= 0) return vHasil;

	return _OBJECTDATA.findIndex((vItems,vKey)=>{
		return (vItems[_NAMAOBJ]||"").toString() === (_DATASRC||"").toString();
	});

	/*for(var i=0;i<_OBJECTDATA.length;i++) {
		let vDataTks	= (_OBJECTDATA[i][_NAMAOBJ]) || "";
		if(vDataTks.toString() === _DATASRC.toString()) {
			return i;
			//vHasil = i; break;
		}
	}//-*/
},
getTabelRowIndeks: function(_TABELID,_TABELTR) {
	const vElTabel	= document.getElementById(_TABELID) || undefined;
	if(UFunc.isEmpty(vElTabel)) return -1;
	const vElTabelBody	= vElTabel.getElementsByTagName("tbody")[0] || undefined;
	if(UFunc.isEmpty(vElTabelBody)) return -1;

	const vELTabelRow = vElTabelBody.getElementsByTagName("tr") || undefined;
	//console.log("(UFunc) getTabelRowIndeks (vELTabelRow): "+vELTabelRow);
	if(UFunc.isEmpty(vELTabelRow)) return -1;

	for (var i=0; i<vELTabelRow.length; i++) {
		if((vELTabelRow[i].getAttribute("id")||"").toUpperCase() === _TABELTR.toUpperCase()) {
			return i;
		}
	}
	return -1;
},
getOptionSelectTeks:function(_ELEMENT) {
	if(UFunc.isEmpty(_ELEMENT)) return "";

	return (_ELEMENT.options[_ELEMENT.selectedIndex].text);
},
renderHTML: function(escapedHTML: string) { 

	return React.createElement("span", { dangerouslySetInnerHTML: { __html: escapedHTML } });
},
Usercaptionfilter:function(_NAME,_JMLWORD) {
	_NAME 	= (_NAME||"Undf").trim();
	_JMLWORD = parseInt(_JMLWORD) || 2;

	const vTmpArr 	= _NAME.split(" ");
	let vUsercaption= "";
	for (var i = 0; i < vTmpArr.length; i++) {
		vUsercaption += vTmpArr[i];
		if(i >= _JMLWORD-1) break;
		vUsercaption += " "; 
	}

	return vUsercaption.trim();
},
getWordnumber:function(_NAME,_IDXWORD) {
	_NAME 	= (_NAME||"Undf").trim();
	_IDXWORD = parseInt(_IDXWORD) || 0;

	const vTmpArr 	= _NAME.split(" ");
	return vTmpArr[_IDXWORD];
},
leadZero: function(str, max) {
	str 	= str || "0";
    max 	= parseInt(max)||0;
    if(max<2)max=2;

    str = str.toString();
    var vDigits = max - (str.length);//-> 2 - 1 = 1
    for(var i=0;i <= vDigits - 1;i++)
	    str = "0" + str.toString();

    return str.toString();
},
toDate: function(_STRDATE) {
	if((_STRDATE||"")==="") return new Date();

    var DatesCHK = _STRDATE;//---2011-04-21 OR 2011-04-21 00:00:00
    if(_STRDATE.length > 10 ) {
    	let vSplittwo	= _STRDATE.split(" ");
    	DatesCHK		= vSplittwo[0];
    }
    const [year, month, day] = [...DatesCHK.split('-')];
    return new Date(year, (month-1), day);//-->INDEX_MONTH_0_11;
},
toDatetime: function(_STRDATETIME) {
	//console.log("_STRDATETIME = "+_STRDATETIME);
	if((_STRDATETIME||"")==="") return new Date();

	var dt  = _STRDATETIME.split(/\-|\s/);
	return new Date(dt.slice(0,3).join('-') + ' ' + dt[3]);
},
isDateValid: function(_DATE) {
	return _DATE instanceof Date && !isNaN(_DATE);
},
DbDate: function(_DATE) {
	_DATE = _DATE || new Date();

	const vHasil = (_DATE.getFullYear() + "-" +UFunc.leadZero(_DATE.getMonth() + 1) + "-" + 
		UFunc.leadZero(_DATE.getDate()));
	return vHasil.toString();
},
SelisihHari: function(_TGLAWAL, _TGLAKHIR) {
    var vDateAwal   = UFunc.toDate(_TGLAWAL);
    var vDateAkhir  = UFunc.toDate(_TGLAKHIR);

    //return Math.round(Math.abs(vDateAkhir-vDateAwal)/(1000*60*60*24));
    return Math.round((vDateAkhir-vDateAwal)/(1000*60*60*24));
},
Datetimeselisih: function(_TGLAWAL,_TGLAKHIR){
	const vSelisih = Math.abs(_TGLAKHIR - _TGLAWAL);

	return { 
		day: Math.floor(vSelisih / (1000 * 60 * 60 * 24)),
		jam: Math.floor(vSelisih / (1000 * 60 * 60)),
		mnt: Math.floor(vSelisih / (1000 * 60)),
		dtk: Math.floor(vSelisih / 1000)
	};
},
DateIsBeyond: function(_TGLCEKDt) {
	var vTglToday 	= UFunc.DbDate(new Date());
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vTglToday);

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	//console.log("vTglCek = "+vTglCek);

	if(vSelisih > 0) return true; else return false;
},
DateIsMundur: function(_TGLCEKDt){
	var vTglToday 	= UFunc.DbDate(new Date());
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vTglToday);

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	if(vSelisih < 0) return true; else return false;
},
DateIsExceededYear: function(_TGLCEKDt) {
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

	const vTglTodayDt= new Date();
	const vYearNow	= vTglTodayDt.getFullYear();

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vYearNow+"-01-01");

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	//console.log("vSelisih = "+vSelisih);

	if(vSelisih < 0) return true; else return false;
},
TglAngka: function(_DATE) {
    var vTANGGAL = _DATE || new Date();
    //if(UFunc.isEmpty(vTANGGAL.getDay())) {
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDate(_DATE);
    }
	//console.log("(TglAngka) _DATE = "+_DATE);
    return UFunc.leadZero(vTANGGAL.getDate())+"/"+UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+vTANGGAL.getFullYear();
},
HariAngka: function(_DATE) {
    var vTANGGAL = _DATE || new Date();
    //console.log("vTANGGAL = "+vTANGGAL);

    //if(UFunc.isEmpty(vTANGGAL.getDay())) {
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDate(_DATE);
    }

    return cHariPendek[ vTANGGAL.getDay() ]+", "+ UFunc.leadZero(vTANGGAL.getDate())+"/"+UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+vTANGGAL.getFullYear();
},
HariPendek: function(_DATE) {
	let vTANGGAL = _DATE || new Date();
	//console.log("vTANGGAL = "+vTANGGAL);

	if(vTANGGAL instanceof Date === false) {
		vTANGGAL = UFunc.toDate(_DATE);
	}

	return cHariPendek[ vTANGGAL.getDay() ]+", "+ 
		UFunc.leadZero(vTANGGAL.getDate())+"-"+
		cBulanPendek[vTANGGAL.getMonth()]+"-"+
		//UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+
		vTANGGAL.getFullYear();
},
HariPanjang: function(_DATE) {
    var vTANGGAL = _DATE || new Date();
    //console.log("vTANGGAL = "+vTANGGAL);

    //if(UFunc.isEmpty(vTANGGAL.getDay())) {
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDate(_DATE);
    }

    return cHariPanjang[ vTANGGAL.getDay() ]+", "+ 
    	UFunc.leadZero(vTANGGAL.getDate())+"-"+
    	cBulanPendek[vTANGGAL.getMonth()]+"-"+
    	//UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+
    	vTANGGAL.getFullYear();
},
TglAngkaDT: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return UFunc.leadZero(vTANGGAL.getDate())+"/"+
    	UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+
    	vTANGGAL.getFullYear()+
    "";
},
WaktuAngka: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return UFunc.leadZero(vTANGGAL.getDate())+"/"+
    	UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+
    	vTANGGAL.getFullYear()+" "+
    	UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+
    "";
},
WaktuPendek: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return cHariPendek[ vTANGGAL.getDay() ]+", "+
    	UFunc.leadZero(vTANGGAL.getDate())+"/"+
    	UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+
    	vTANGGAL.getFullYear()+" "+
    	UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+
    "";
},
JamMenit: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+
    "";
},
JamMenitDetik: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+":"+
    	UFunc.leadZero(vTANGGAL.getSeconds())+
    "";
},
hdlKeyDownInput: function(_EV,_NEXTFIELD) {
	//console.log("(UFunc.hdlKeyDownInput) function = "+_EV.which);
	if(_EV.which===13 && (_EV.target.nodeName === "INPUT"
			|| _EV.target.nodeName === "SELECT"
			|| _EV.target.nodeName === "TEXTAREA")
	) {
		if (_EV.shiftKey && _EV.target.nodeName === "TEXTAREA") {
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();
		const ElNext	= document.getElementById(_NEXTFIELD);
		//if(!UFunc.isEmpty(ElNext))
		ElNext && ElNext.focus();
	}
},
getRandomColor: function() {
	const letters = '0123456789ABCDEF'.split('');
	let color = '#';
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	} 
	return color;
},
getBase64Image: function(img) {
	var canvas 		= document.createElement("canvas");
	canvas.width 	= img.width;
	canvas.height 	= img.height;
	var ctx 		= canvas.getContext("2d");
	ctx.drawImage(img, 0, 0);
	var dataURL 	= canvas.toDataURL("image/png");
	return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
},
getBase64: function(file) {
	return new Promise((resolve,reject) => {
		let fileInfo;
		// Make new FileReader
		let reader = new FileReader();
		// Convert the file to base64 text
		reader.readAsDataURL(file);

		// on reader load somthing...
		reader.onload = () => {
			// Make a fileInfo Object
			const baseURL = reader.result;
			resolve(baseURL);
		};
		reader.onError = (error)=>{
			console.log(error);
			reject(error);
		}
    });
},
validateBase64image: function(_BASE64IMGSTR) {
	return new Promise((resolve) => {
		const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^\s]*)$/;

		if (!base64Regex.test(_BASE64IMGSTR)) {
			resolve(false);
		}

		const vIMG 	= new Image();
		vIMG.onload = () =>	resolve(vIMG.width > 0 && vIMG.height > 0);
		vIMG.onerror= () => resolve(false);

		vIMG.src 	= _BASE64IMGSTR;
	})//--*/
	/*const vIMG 	= new Image();
	vIMG.src 	= _BASE64IMGSTR;
	return vIMG.complete && vIMG.width > 0 && vIMG.height > 0;//*/
},
Rendernota: function(_DATANOTAOBJ) {
	const vDatanotaObj = _DATANOTAOBJ || {};
	//if(vDatanotaObj.length <= 0) return "";

	let vHTMLs	= "";

	const vDataItemArr	= (vDatanotaObj.arritems || []);
	const vKembalian	= parseInt(vDatanotaObj.kembali || 0);
	const vJumlah		= parseInt(vDatanotaObj.total)||0;
	const vSubtotal		= vJumlah - (parseInt(vDatanotaObj.dp_awal)||0) - (parseInt(vDatanotaObj.diskon)||0);
	const vTotal 		= vSubtotal + (parseInt(vDatanotaObj.b_kartu)||0);

	const vNetto		= parseInt(vDatanotaObj.total)||0;
	const vDiskon		= parseInt(vDatanotaObj.diskon)||0;
	const vDPSebelum	= parseInt(vDatanotaObj.dp_awal)||0;
	const vBiayaTrf		= parseInt(vDatanotaObj.b_kartu)||0;
	const vNilaiuang	= parseInt(vDatanotaObj.jml_bayar)||0;
	const vTagihan		= vNetto + vBiayaTrf - vDiskon;
	const vNilaisisa 	= vTagihan - vDPSebelum - vNilaiuang;

	const vIsPiutang	= (vDatanotaObj.tabelref||"").toUpperCase() === ("tpiutang_bayar").toUpperCase() ? true : false;
	const vMasihPiutang	= vKembalian < 0 ? true : false; 

	vHTMLs += `
		<table id="idtabelhtml" align="left">
		<tbody>
		_TRBODYLOGO_
	`;
	vHTMLs += `
		<tr valign="top">
			<td colSpan="4" align="center">_NAMAPERUSAHAAN_</td>
		</tr>
		<tr valign="top">
			<td colSpan="4" align="center">_ALAMATPERUSAHAAN_</td>
		</tr>
		_TRBODYTELP_
		_TRBODYTAGLINE_
	`;
	vHTMLs += `
		<tr valign="top">
			<td colSpan="4"><hr/></td>
		</tr>
		<tr valign="top">
			<td colSpan="2">Tanggal</td>
			<td colSpan="2">`+UFunc.WaktuPendek(vDatanotaObj.tgl_bayar || "")+`</td>
		</tr>
		<tr valign="top">
			<td colSpan="2">No.Nota</td>
			<td colSpan="2">`+(vDatanotaObj.no_nota||"{UNDFx}")+`</td>
		</tr>
		<tr valign="top">
			<td colSpan="2">Kasir</td>
			<td colSpan="2">`+(vDatanotaObj.kasir||"-")+`</td>
		</tr>
		<tr>
			<td colSpan="4"><hr/></td>
		</tr>
		`+
		((vDatanotaObj.customer||"")!==""?`
			<tr valign="top">
				<td colSpan="2">Customer</td>
				<td colSpan="2">`+(vDatanotaObj.customer||"").toUpperCase()+`</td>
			</tr>
			<tr>
				<td colSpan="4"><hr/></td>
			</tr>
		`:``)+
		``;
	vDataItemArr.map((vItems,vKey)=>{
		const {
			caption_pasien,caption_item,qty,h_jual_sat//,jenis_item
		} = vItems;

		const vCaption = ((caption_pasien||"")!==""?"["+caption_pasien+"] ":"")+caption_item;

		vHTMLs += `
		<tr valign="top" key="`+vKey+`">
			<td align="right">`+(vKey+1)+`.</td>
			<td colSpan="3">`+(vCaption)+`</td>
		</tr>
		<tr valign="top">
			<td align="right" colSpan="3">`+
				(
				//jenis_item==="BARANG" ?"("+UFunc.formatAngka(qty)+" x @Rp"+UFunc.formatAngka(h_jual_sat)+")" :"&nbsp;"
				"("+UFunc.formatAngka(qty)+" x @Rp"+UFunc.formatAngka(h_jual_sat)+")"
				)+`</td>
			<td align="right">`+UFunc.formatAngka(h_jual_sat * qty)+`</td>
		</tr>
		`;
	});
	if(vIsPiutang)
		vHTMLs += `
			<tr>
			<td colSpan="4"><hr/></td>
			</tr>
			<tr valign="top">
			<td colSpan="3">Nota Transaksi</td>
			<td  align="right" style="font-style:italic;">`+(vDatanotaObj.nota_sebelum||"-")+`</td>
			</tr>
			<tr valign="top">
			<td colSpan="3">Tgl Transaksi</td>
			<td align="right" style="font-style:italic;">`+UFunc.HariAngka(vDatanotaObj.tgl_transaksi||"")+`</td>
			</tr>
			<tr valign="top">
			<td colSpan="3">Jumlah Transaksi (Rp)</td>
			<td align="right" style="font-style:italic;">`+UFunc.formatAngka(vDatanotaObj.nilai_transaksi||0)+`</td>
			</tr>
			`;

	if((vDatanotaObj.ket_kartu||"").trim() !=="")
		vHTMLs += `
			<tr>
			<td colSpan="4"><hr/></td>
			</tr>
			<tr valign="top">
			<td colSpan="2">Metode Non Tunai</td>
			<td colSpan="2" align="right">`+UFunc.capitalize(vDatanotaObj.ket_kartu||"")+`</td>
			</tr>
			`;
	vHTMLs += `
			<tr>
			<td colSpan="4"><hr/></td>
			</tr>
			<tr valign="top">
			<td colSpan="3" align="right">`+
				(!vIsPiutang  ? "Jumlah"  : "Piutang Sebelum").toUpperCase()+
			` (Rp)</td>
			<td align="right">`+UFunc.formatAngka(vNetto)+`</td>
			</tr>
			`;
		if((parseInt(vDatanotaObj.diskon)||0) > 0)
			vHTMLs += `
			<tr valign="top">
			<td colSpan="3" align="right">Diskon (Rp)</td>
			<td align="right">`+UFunc.formatAngka(vDatanotaObj.diskon||0)+`</td>
			</tr>
			<tr>
			<td colSpan="3">&nbsp;</td>
			<td><hr/></td>
			</tr>
			<tr valign="top">
			<td colSpan="3" align="right">Total (Rp)</td>
			<td align="right">`+
				UFunc.formatAngka(vNetto - parseInt(vDatanotaObj.diskon))+
			`</td>
			</tr>
			`;
		if((parseInt(vDatanotaObj.b_kartu)||0) > 0)
			vHTMLs += `
			<tr valign="top">
			<td colSpan="3" align="right">Biaya Non Tunai (Rp)</td>
			<td align="right">`+UFunc.formatAngka(vBiayaTrf)+`</td>
			</tr>
			<tr>
			<td colSpan="3">&nbsp;</td>
			<td><hr/></td>
			</tr>
			<tr valign="top">
			<td colSpan="3" align="right">Tagihan (Rp)</td>
			<td align="right">`+
				UFunc.formatAngka(vTagihan)+
			`</td>
			</tr>
			`;
		if((parseInt(vDatanotaObj.dp_awal)||0) > 0)
			vHTMLs += `
			<tr valign="top">
			<td colSpan="3" align="right">Titipan DP (Rp)</td>
			<td align="right">`+UFunc.formatAngka(vDPSebelum)+`</td>
			</tr>
			`;
		vHTMLs += `
			<tr valign="top">
			<td colSpan="3" align="right"><B>`+
				("Jml BAYAR").toUpperCase()+` (Rp)</B></td>
			<td align="right"><B>`+
				UFunc.formatAngka(vNilaiuang)+
			`</B></td>
			</tr>
			<tr>
			<td colSpan="3">&nbsp;</td>
			<td><hr/></td>
			</tr>
			<tr valign="top">
			<td colSpan="3" align="right">`+(!vMasihPiutang
					? "Kembalian"
					:"<B>Sisa Piutang</B>")+` (Rp)</td>
			<td align="right" style="`+(vMasihPiutang?"font-weight:bolder;":"")+`">`+
				UFunc.formatAngka(Math.abs(vKembalian))+
			`</td>
			</tr>
			`;
		if((vDatanotaObj.catatan_pasien||"").trim() !=="")
			vHTMLs += `
			<tr>
			<td colSpan="4"><hr/></td>
			</tr>
			<tr>
			<td colSpan="4"><B>Cat.Customer</B><BR><I>`+
				UFunc.capitalize(vDatanotaObj.catatan_pasien||"")+
			`</I></td>
			</tr>
			`;
		vHTMLs += `
			_FOOTERNOTA_
			<tr>
				<td width="5%" style="min-width:7mm;">&nbsp;</td>
				<td width="30%">&nbsp;</td>
				<td>&nbsp;</td>
				<td width="30%">&nbsp;</td>
			</tr>
			</tbody>
			</table>
			`;
	//-*/
	return vHTMLs;
},
RenderTabel: function(_IDENTITASOBJ,_COLSPAN,_ISWITHFOOTER) {
	let vHTMLs	= ``;
	_ISWITHFOOTER = _ISWITHFOOTER || true;

	const vColspan = parseInt(_COLSPAN) || 1;
	vHTMLs += `
		<table id="idtabelhtml">
		<thead>
		<tr>
		<td colspan="`+vColspan+`" style="text-align:left">
		<div style="display:flex;justify-content:space-between;">
			<small style="font-style:italic">
				<div><b>`+(_IDENTITASOBJ.nama_perusahaan||"")+`</b></div>
				<div>`+UFunc.nl2br(_IDENTITASOBJ.alamat||"")+`</div>
				`+((_IDENTITASOBJ.telp||"")==="" ? `` : `
					<div>Telp/HP. `+(_IDENTITASOBJ.telp)+`</div>
				`)+`
			</small>	
			<div style="text-align:right">			
			<small>_TITELSUB_</small>
			</div>
		</div>
		</td>
		</tr>
		<tr>
		<td colspan="`+vColspan+`">
		<big style="font-weight:bolder;text-transform:uppercase">_JUDULLAPORAN_</big>
		</td>
		</tr>
		<tr valign="top">_TABELHEADER_</tr>
		</thead>
		<tbody>_TABELBODY_</tbody>

		<tfoot style="page-break-inside:avoid !important;">`+
		(_ISWITHFOOTER ? `
			<tr>
			_TABELFOOTER_
			</tr>
			` : ``)+`
		<tr>
		<td colspan="`+(vColspan)+`" class="classpagefooter">
		<div style="display:flex;justify-content:space-between;font-style:italic;">
			<small>Waktu Cetak: `+UFunc.WaktuPendek()+`</small>				
			<small id="idpagefooter">Hal </small>
		</div>
		</td>
		</tr>
		</tfoot>
	`;

	return vHTMLs;
}
}
export {UFunc,cBulanPanjang,cHariPendek,cAngkaTgl,cBulanPendek,cKeycodebypass};