import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
	CInputGroup,
	CForm,
	CFormInput,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
} from '@coreui/react';
import { MyButton } from '../components/index';
import { UFunc } from '../helpers/functions'

const pjson     = require('../../package.json');

const A1profile = (props) => {
	const uComponentname = A1profile.name;
	const {setToken,setLoading,showToast,prosesExpired}	= props;

	let uFuncname;
	const uNavigate		= useNavigate();
	const uDispatch		= useDispatch();

	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uTokenObj		= JSON.parse(localStorage.getItem("token")||"{}");

	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	const [vUsername,setUsername]		= React.useState(uTokenObj.username);
	const [vUsercaption,setUsercaption]	= React.useState(uTokenObj.usercaption);
	const [vUserpasswd1,setUserpasswd1]	= React.useState('');
	const [vUserpasswd2,setUserpasswd2]	= React.useState('');
	const [vUserpasswdold,setUserpasswdold]	= React.useState('');
	const [vBtnSimpanDisabled,setBtnSimpanDisabled]	= React.useState(false);

	React.useEffect(()=>{ 
		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		if(uTokenObj.username.toUpperCase() === "SUPERADMIN") {
			uNavigate("/user"); return;
		}
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
	},[]);//-*/

	const hdlKlikSimpan = () => {
		//---CEK_VALIDASI--/
		let vElfocus	= document.getElementById("inpusername")
		if(vUsername === "") {
			vElfocus && vElfocus.focus();
			showToast(uBahasaObj.word_username+" "+uBahasaObj.caption_mustfilled+".."); return;
		}
		vElfocus	= document.getElementById("inpusercaption")
		if(vUsercaption === "") {
			vElfocus && vElfocus.focus();
			showToast(uBahasaObj.caption_namauser+" "+uBahasaObj.caption_mustfilled+".."); return;
		}
		vElfocus	= document.getElementById("inppasswd1")
		if(vUserpasswd1 !== vUserpasswd2) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.word_password||"Password")+" / "+uBahasaObj.caption_passwdconfirm+" "+(uBahasaObj.caption_mustsame||"Harus Sama")+".."); return;
		} else {
			vElfocus	= document.getElementById("inppasswdold")
			if(vUserpasswd1 !== "" || vUserpasswd2 !== "")
				if(vUserpasswdold === "") {
					vElfocus && vElfocus.focus();
					showToast(uBahasaObj.caption_passwdlama+" "+uBahasaObj.caption_mustfilled+".."); return;
				}
		}
		//---END CEK_VALIDASI--/

		apiProsessimpan();
	}

	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		//---PROSES_SIMPAN--/
		const vDATAS    = JSON.stringify({
			send_username : vUsername,
			send_usercaption : vUsercaption,
			send_passwd1 : vUserpasswd1,
			send_passwd2 : vUserpasswd2,
			send_passwdold : vUserpasswdold,
			send_tokenauth : uTokenObj.userinit,
		});
		const vURLs     = pjson.homepage+"api/api_init/pr_simpanprofile";

		setLoading(true);
		setBtnSimpanDisabled(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+uComponentname+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setBtnSimpanDisabled(false);
			setLoading(false);
			if(output_string.sukses) {
				setToken(JSON.parse(output_string.token||"{}"));
				showToast(uBahasaObj.pesan_sukses,"SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		}).catch((error) =>{
			setBtnSimpanDisabled(false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
		//---END PROSES_SIMPAN--/
	}

	return (
		<CCard className="mx-lg-3">
			<CCardHeader className="fw-bolder">
				{uBahasaObj.caption_personalinfo || "caption_personalinfo"}
			</CCardHeader>
			<CCardBody>
			<CForm className="mx-2 mx-md-3 mx-lg-5 px-lg-5">
				<CRow className="my-2">
				<CCol md="4" className="text-md-right">
					{uBahasaObj.caption_namauser || "caption_namauser"}
				</CCol>
				<CCol md="8">
					<CFormInput maxLength="50"
						type="text"
						size="sm" 
						value={vUsercaption}
						onChange={(e) => setUsercaption(e.target.value)}
						id="inpusercaption" name="inpusercaption" />
				</CCol>
				</CRow>

				<CRow className="my-2">
				<CCol md="4" className="text-md-right">
					{uBahasaObj.word_username || "word_username"}
				</CCol>
				<CCol md="5">
					<CFormInput maxLength="30"
						type="text"
						size="sm" 
						className="form-control"
						value={vUsername}
						onChange={(e) => setUsername(e.target.value)}
						id="inpusername" name="inpusername" />
				</CCol>
				</CRow>

				<CRow className="my-3"><CCol className="classborderbottom"/></CRow>
				
				<CRow className="my-2">
				<CCol md="4" className="text-md-right">
					{uBahasaObj.caption_passwdlama || "caption_passwdlama"}
				</CCol>
				<CCol md="5">
					<CFormInput
						size="sm" 
						autoComplete="off"
						onChange={(e) => setUserpasswdold(e.target.value)}
						id="inppasswdold" type="password" />
					<div className="classpetunjuk">*) {uBahasaObj.petunjuk_passwdold || "petunjuk_passwdold"}</div>
				</CCol>
				</CRow>

				<CRow className="my-3"><CCol className="classborderbottom"/></CRow>

				<CRow className="my-2">
				<CCol md="4" className="text-md-right">
					{uBahasaObj.word_passwd || "word_passwd"}
				</CCol>
				<CCol md="5">
					<CFormInput
						size="sm" 
						autoComplete="off"
						onChange={(e) => setUserpasswd1(e.target.value)}
						id="inppasswd1" type="password" />
				</CCol>
				</CRow>

				<CRow className="my-2">
				<CCol md="4" className="text-md-right">
					{uBahasaObj.caption_passwdconfirm || "caption_passwdconfirm"}
				</CCol>
				<CCol md="5">
					<CFormInput
						size="sm" 
						autoComplete="off"
						onChange={(e) => setUserpasswd2(e.target.value)}
						onKeyDown={(e) => UFunc.hdlKeyDownInput(e,"btnsimpan")}
						id="inppasswd2" type="password" />
					<div className="classpetunjuk">*) {uBahasaObj.petunjuk_inputpasswd || "petunjuk_inputpasswd"}</div>
				</CCol>
				</CRow>

				<CRow className="my-3"><CCol className="classborderbottom"/></CRow>

				<CRow className="my-2">
				<CCol md="4" className="text-md-right">
					{uBahasaObj.caption_hakakses || "caption_hakakses"}
				</CCol>
				<CCol md="8" className="fw-bolder">{uBahasaObj["hakuser_"+(uTokenObj.userhak||"").toLowerCase()]||"UNDF"}</CCol>
				</CRow>
			</CForm>
			</CCardBody>

            <CCardFooter className="text-center">
            	<MyButton 
            		onClick={()=>hdlKlikSimpan()}
            		disabled={vBtnSimpanDisabled}
            		iconname="cilCheckAlt"
            		id="btnsimpan">
            		{uBahasaObj.word_simpan || "Simpan_"}
            	</MyButton>
            </CCardFooter>
		</CCard>
	)
}

export default A1profile;