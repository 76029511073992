import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderBrand,
	CHeaderToggler,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CButton,
	CLink,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPagination,
	MyPage500,
	MyFormHapus,
	MyProfileDetil,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../../package.json');
const Kasbonpreview = (props) => {
	const uComponentname 	= Kasbonpreview.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//--GLOBAL_VARS--/
	const uIsAllowedhapus	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword") || "{}");
	const uKeywordload		= uKeywordObj.kwd_kasbonpreview||"";

	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uEfekreset,setEfekreset]		= React.useState(false);
	const [uInpKeyword,setInpKeyword]	= React.useState("");
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uPageActive,setPageActive]	= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]	= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]= React.useState(0);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const uElJmldata					= document.getElementById("idjmldata");

	//---FORMDIALOG--/
	const [uIsShowFormhapus,setIsShowFormhapus]	= React.useState(false);
	const [uDialogHeader,setDialogHeader]		= React.useState("");
	const [uInpKethapus,setInpKethapus]			= React.useState("");

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	//---END FORMDIALOG--/
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleFormhapus=()=>{ setIsShowFormhapus(false); }
	const hdlKlikFormhapusSimpan=()=>{
		if((uInpKethapus||"")==="") {
			document.getElementById("inpkethapus") &&
				document.getElementById("inpkethapus").focus();
			showToast((uBahasaObj.caption_alasanpenghapusan||"caption_alasanpenghapusan")+" "+
				(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
			return;
		}

		apiProseshapus();
	}

	const hdlKeydownCari=(_EV)=>{
		if(_EV.which===13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari=()=>{ setEfekreset(true); }
	const hdlKlikReset=async()=>{
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
	    	setInpKeyword("");
			//---END CLEAR_KEYWORD--/
			
			setEfekreset(true);
		}

		//prosesReset(); return;
		if((uKeywordload||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}
	const hdlKlikHapus=(_ID)=>{
		setTabelIDPilih(_ID);

		const vIdx 		= UFunc.getObjectIndeks(uDatamainArr,_ID,"id");
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_ID);
		setTabelRowSelect(vIdxRow);

		const vHeadercaption = (uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			(uDatamainArr[vIdx].nama_karyawan||"").toUpperCase()+" "+
			"("+UFunc.HariAngka(uDatamainArr[vIdx].tanggal||"")+")"+
			"";
		setDialogHeader(vHeadercaption);

		setIsShowFormhapus(true);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentSubheader = () => {
		return (
		<CContainer  
			className="bg-white p-2 px-lg-4 justify-content-center justify-content-md-between align-items-center"
			style={{minHeight:52}}
			fluid>
		<div className="d-none d-lg-block"/>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup size="sm">
				<CFormInput size="sm" 
					id="inpkeyword"
					value={uInpKeyword} 
					onChange={(e) => setInpKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKeydownCari(e)} 
					style={{minWidth:270,width:"auto"}} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} />
				<CButton size="sm" color="light" className="border"
					onClick={()=>hdlKlikCari()} id="btnCari">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
			</CForm>

			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink onClick={()=>hdlKlikReset()}
					className="classcursorpointer classikon classikonreset"/>
			</CTooltip>

			<div className="d-none d-md-block">
			<span className="me-1">&middot;</span>

			<b id="idjmldata">0</b> {uBahasaObj.word_data||"word_data"}
			</div>
		</div>
		</CContainer>
		)//>
	}
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordload || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		//console.log("("+uComponentname+"-contentMain) vDataviewArr = "+JSON.stringify(vDataviewArr));
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width={35} className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.caption_namakaryawan||"caption_namakaryawan"}</CCol>
				<CCol md="3" lg="4" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" lg="1" className="text-start">{uBahasaObj.caption_akunkas||"Kas Akun"}</CCol>
				<CCol md="2" className="">{uBahasaObj.word_nilai||"word_nilai"}<small className="d-block">(Rp)</small></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>
			<tr className="d-md-none">
			<th width={25} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,id_tabel,nama_karyawan,keterangan,jenis,status_jurnal,
				tanggal,tgl_input,username,nilai,init_expired,kas_caption
			} = vItems;
			const vCaptionUpper	= ((nama_karyawan||"")+" ("+UFunc.TglAngka(tanggal)+")").toUpperCase();

			const vNamaKaryawan	= vKeyword==="" ? UFunc.capitalize(nama_karyawan||"") 
				: (UFunc.capitalize(nama_karyawan||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tanggal) 
				: (UFunc.TglAngka(tanggal)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: (UFunc.WaktuAngka(tgl_input)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUsername	= vKeyword==="" ? (username||"") 
				: ((username||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKascaption	= vKeyword==="" ? (kas_caption||"UNDF") 
				: ((kas_caption||"UNDF")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vJenis	= vKeyword==="" ? uBahasaObj["caption_"+(jenis||"").toLowerCase()]||"caption_"+(jenis||"").toLowerCase() 
				: (uBahasaObj["caption_"+(jenis||"").toLowerCase()]||"caption_"+(jenis||"").toLowerCase()).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKeterangan	= vKeyword==="" ? UFunc.capitalize(keterangan||"-") 
				: (UFunc.capitalize(keterangan||"-")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			vNumber++;
			const vColorstatustembak = (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{(vNumber)}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="2" className="px-1 text-start">
					<div className="d-flex justify-content-between">
						<div>
						{UFunc.renderHTML(vTanggal)}
						{uTokenObj.userhak==="FREEROLE" && (
							<span className="classfontsmaller"> &middot; <small>({id})</small></span>
						)}
						</div>

						{uIsAllowedhapus && (init_expired||"YA")==="TIDAK" && (
						<CLink 
							className="classikontabel classikonhapus d-md-none"
							onClick={()=>hdlKlikHapus(id)} />
						)}
					</div>

					<div className="d-none d-md-block text-danger classfontsmaller">
						<small>[{(tgl_input||"")!=="" && (
							<>{UFunc.renderHTML(vTglInput)} &middot; </>
							)}
						{UFunc.renderHTML(vUsername)}
						]</small>
					</div>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_karyawan||"word_karyawan"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-start">
					<div className="text-info">{UFunc.renderHTML(vNamaKaryawan)}</div>

					{(uTokenObj.userhak==="FREEROLE" && (status_jurnal||"")!=="") && (
						<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
						</div>
					)}
				</CCol>

				<CCol xs="3" className="px-1 text-start d-md-none classfontsmaller">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol xs="9" md="3" lg="4" className="px-1 text-md-start">
					<div className={"d-none d-md-block"+(jenis==="KASBONBARU"?" fw-bolder":" text-success")}>
						{UFunc.renderHTML(vJenis)}
					</div>
					<small className="fst-italic">{UFunc.renderHTML(vKeterangan)}</small>
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_sjurnal||"S.Jurnal"}</CCol>
				<CCol xs="8" md="4" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal || "-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_jenis||"word_jenis"}</CCol>
				<CCol xs="8" 
					className={"px-1 d-md-none text-md-start"+(jenis==="KASBONBARU"?" fw-bolder":" text-success")}>
					{UFunc.renderHTML(vJenis)}
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_akunkas||"Akun Kas"}</CCol>
				<CCol xs="8" md="2" lg="1" className="px-1 text-md-start">
					{UFunc.renderHTML(vKascaption)}
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_inputby||"Input By"}</CCol>
				<CCol xs="8" md="2" className="px-1 classfontsmaller d-md-none text-danger">
					<small>[{(tgl_input||"")!=="" && (
						<>{UFunc.renderHTML(vTglInput)} &middot; </>
						)}
					{UFunc.renderHTML(vUsername)}
					]</small>
				</CCol>

				<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.word_nilai||"word_nilai"} (Rp)</CCol>
				<CCol xs="7" md="2" className="px-1 text-primary">
					{UFunc.formatAngka(nilai)}
				</CCol>

				<CCol md="1" className="px-1 text-center d-none d-md-block">
				{uIsAllowedhapus && (init_expired||"YA")==="TIDAK" && (
					<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
					<CLink 
						className={"classcursorpointer classikontabel classikonhapus"}
						onClick={()=>hdlKlikHapus(id)} />
					</CTooltip>
				)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata=(_PAGE)=>{
		uFuncname 	= apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_kasbonpreview || "";
		const vHandelview	= (uEfekreset===true || uFirstload==="YA") ?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoaddata) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasbon/ld_datainput";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				setHTMLError500(vMsg);
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProseshapus=()=>{
		uFuncname = apiProseshapus.name;

		const vIdx 	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih,"id");

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiProseshapus) uTabelIDPilih = "+uTabelIDPilih);
		console.log("("+uComponentname+"-apiProseshapus) vIdx = "+vIdx);
		console.log("("+uComponentname+"-apiProseshapus) uDatamainArr = "+JSON.stringify(uDatamainArr[vIdx]));

		uDatamainArr.splice(vIdx,1);
		setTabelRowSelect(-1);
		setJmlData((parseInt(uDatamainArr.length)||0));
		setEfekview(!uEfekview);
		hdlToggleFormhapus();
		return;
		//--END TESTING_FRONTEND--*/

		const vTabelID	= uDatamainArr[vIdx].id_tabel;
		const vJenis	= uDatamainArr[vIdx].jenis;
		const vDATAS	= JSON.stringify({
			send_tabelid 	: vTabelID,
			send_jenis 		: vJenis,
			send_keterangan	: uInpKethapus,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasbon/pr_hapus";

		setLoading(true);
		uElBtnDlgsimpan.disabled=true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnDlgsimpan.disabled=false;
			//console.log("("+uComponentName+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr.splice(vIdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
				hdlToggleFormhapus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				uElBtnDlgsimpan.disabled=true;
				prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnDlgsimpan.disabled=false;
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
    	setIsShowFormhapus(false);
    	
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoaddata();

    	return ()=>{
			uKeywordObj.kwd_kasbonpreview = undefined;
			localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

			setFirstload("YA")
    		setDatamainArr([]);
    	}
	},[]);
	React.useEffect(()=>{
		if(!uEfekreset) return;

		uKeywordObj.kwd_kasbonpreview = uInpKeyword.trim();
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
		setEfekreset(false)
	},[uEfekreset]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		if(uElJmldata) {
			const vKeyword = uKeywordload || "";
			if (vKeyword.length >= 3) {
				if(uElJmldata.innerHTML !== UFunc.formatAngka(uJmlData)) {
					uElJmldata.innerHTML = UFunc.formatAngka(uJmlData);
				}
			} else {
				if(uElJmldata.innerHTML !== "&#8734;") {
					uElJmldata.innerHTML = "&#8734;";
				}
			}
		}
	},[uJmlData]);
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		//console.log("("+uComponentname+" - [uPageActive]) vDatapageArr => "+JSON.stringify(vDatapageArr));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uIsShowFormhapus) {
			setInpKethapus("");
			return;
		}

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnDlgsimpan(document.getElementById("btnDialogSimpan"))
			document.getElementById("inpkethapus") && 
				document.getElementById("inpkethapus").focus();
		},350);
	},[uIsShowFormhapus]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_cComponentName
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() => uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={25}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_kasbon||"menus_kasbon").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		
		{contentSubheader()}
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">
			<CContainer xxl className="p-0">
				{(uHTMLError500) ? (
					<MyPage500 content={uHTMLError500} {...props}/>
				) : (
				<CCard className="classcardbg">
					<CCardHeader className="d-flex justify-content-between align-items-center">
					<small className="text-primary">
						Rekap Input
						<b className="d-none d-md-inline">: {uBahasaObj["menus_"+uActiveroute.toLowerCase()]}</b>

						<span className="d-md-none fw-bolder">
						<span className="mx-1">&middot;</span>
						{(()=>{
							const vKeyword = uKeywordObj.kwd_kasbon || "";
							if(vKeyword.length < 3) {
								return (<>&#8734;</>)
							}

							return "("+UFunc.formatAngka(uJmlData)+")"
						})()}
						</span>
						<span className="d-none d-lg-block"/>
					</small>

					<div className="d-lg-none"/>
					<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
					</CCardHeader>
					
					<CCardBody className="px-0">{contentMain()}</CCardBody>

					{(uJmlData > uMaxData) && (
					<CCardFooter>
						<MyPagination
							activePage={uPageActive}
							pages={uJmlHal}
							onActivePageChange={(i) => setPageActive(i)}/>
					</CCardFooter>
					)}
				</CCard>
				)}
			</CContainer>
		</div>
		</div>

		<MyFormHapus
			value={uInpKethapus}
			onCHvalue={(e)=>setInpKethapus(e.target.value)}
			onSimpan={()=>hdlKlikFormhapusSimpan()}
			show={uIsShowFormhapus} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleFormhapus}/>
		</>
	)//>
}
	
export default Kasbonpreview;
