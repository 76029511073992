import React from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { 
	CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
//import PropTypes from "prop-types";
import { UFunc } from "../helpers/functions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from "react-device-detect";

const pjson			= require('../../package.json');
const MyDatepicker 	= (props) => {
	const {
		tglValue, onChangevalue, id, ...rest
	} = props;

	const uBahasaObj  					= useSelector(state => state.listBahasa);
	const [uEfekview,setEfekview] 		= React.useState(false);
	const buttonRef 					= React.useRef(null);

	const ContentButtonref = React.forwardRef(({ value, onClick,...refrest }, ref) => {
		return (
		<CButton size="sm" color="light"
			className="border-secondary d-flex justify-content-between align-items-center w-100" 
			onClick={onClick}
			ref={ref}>
			<span>{UFunc.HariPendek(value)}</span>
			<CIcon icon="cilCalendar" className="ms-2"/>
		</CButton>
		)	
	});

	React.useEffect(() => {
		//console.log("buttonRef => "+JSON.stringify(buttonRef));
		if (buttonRef.current) {
			buttonRef.current.style.width = "100%";
		}
	},[buttonRef]);//-*/

	return (
		<DatePicker
			dateFormat="yyyy-MM-dd"
			closeOnScroll={true}
			selected={tglValue}
			onChange={onChangevalue}
			customInput={<ContentButtonref ref={buttonRef}/>}
			id={id}/>
	)
}

export default MyDatepicker