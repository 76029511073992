import React from 'react'
import { useNavigate,Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { 
	CImage,
	COffcanvas,COffcanvasTitle,COffcanvasHeader,COffcanvasBody,
	CLink,CCloseButton,
	CNav,CNavItem,CNavLink,
	CTabContent,CTabPane,
	CRow,CCol,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { UFunc } from '../helpers/functions'

// sidebar nav config
//import navigation from '../_nav'
const pjson = require('../../package.json');

const ContMasterdata = (props) => {
	const uDispatch 		= useDispatch()
	const uNavigate 		= useNavigate()

	const uActiveroute		= useSelector(state => state.activeRoute)
	const uListnav 			= useSelector((state) => state.listNav)
	const uMasterdatashow	= useSelector((state) => state.masterdataShow) || false;
	const [uMenuekstrakArr,setMenuekstrakArr]	= React.useState([]);
	const [uActivekey,setActivekey]				= React.useState(0);

	const hdlKlikPilihtab = (_NAMAMENU) => {
		_NAMAMENU	= (_NAMAMENU||"").trim().toLowerCase();

		const vArridx	= UFunc.getObjectIndeks(uMenuekstrakArr,_NAMAMENU,"name");
		setActivekey(vArridx);
	}

	const initExtractmenu = () => {
		let vMenuextractArr = []
		let vTmpObj 		= {}
		let vChildrenArr 	= []
		let vKomponenbefore	= "";
		let vIdx			= 0;
		const vMenuheaderArr = uListnav.filter(vItems=>vItems.posisi !== "SIDEBAR");
		vMenuheaderArr.map((vItems,vKeys)=>{
			const { komponen, caption, src, to,name } = vItems

			if(vIdx > 1 && vKomponenbefore !== komponen) {
				vTmpObj.children_arr = vChildrenArr;
				vMenuextractArr.push(vTmpObj);

				vTmpObj				= {}
				vChildrenArr		= []
				vIdx				= 0;
			}
			vIdx++;

			if(komponen==="CNavTitle") {
				vTmpObj = {
					name: name,
					caption: caption,
					src: (src||"")!==""?src:pjson.homepage+"api/images/menus_"+(name||"").toLowerCase()+".png",
					to: to,
				};
			} else {
				vChildrenArr.push({
					name: name,
					caption: caption,
					src: src,
					to: to,
				})
			}
			if(vKeys >= vMenuheaderArr.length-1) {
				vTmpObj.children_arr = vChildrenArr;
				vMenuextractArr.push(vTmpObj);
			}
			vKomponenbefore = komponen;
		})//-*/

		return vMenuextractArr;
	}

	const contentMenuheader = () => {
		const dropMain = (vItems, vKeys) => {
			const {
				caption, src, to, name
			} = vItems
			return (
			<CNavItem key={vKeys} className="" active={uActivekey===vKeys}>
				<CNavLink className="classcursorpointer p-1"
					onClick={()=>hdlKlikPilihtab(name)}>
				<CImage src={(src||"")!==""?src:pjson.homepage+"api/images/menus_"+(name||"").toLowerCase()+".png"} 
					className="classikoncoreui p-1" 
					style={{
						backgroundColor: uActivekey===vKeys ? "#FD756F33" : "",
						borderColor: uActivekey===vKeys ? "#FD756F" : "",
					}}
					height={33}/>
				</CNavLink>
			</CNavItem>
			)
		}

		return (
		<>
		{uMenuekstrakArr &&
			uMenuekstrakArr.map((item, index) => dropMain(item, index))}
		</>
		)
	}
	React.useEffect(()=>{
		const vMenuextractArr = initExtractmenu();
		setMenuekstrakArr(vMenuextractArr);

		return()=>{
			setMenuekstrakArr([]);
			setActivekey(0);
		}
	},[]);
	React.useEffect(()=>{
		if(!uMasterdatashow) {
			const vFiltermatchArr = (uListnav.filter(vItems=>
				vItems.posisi !== "SIDEBAR"
			)).filter(vItems=>
				(vItems.name||"").toUpperCase()===(uActiveroute||"").toUpperCase()
			);

			if(vFiltermatchArr.length <= 0) setActivekey(0);
		}
	},[uActiveroute]);

	//console.log("(ContMasterdata) uListnav => "+JSON.stringify(uListnav));
	return (
		<>
		<COffcanvas visible={uMasterdatashow} 
			onHide={() => uDispatch({ type: 'set', masterdataShow: false }) }
			placement="end">

			<COffcanvasHeader className="classborderbottom">
			<COffcanvasTitle>
			<CNav variant="tabs" className="border-0">{contentMenuheader()}</CNav>
			</COffcanvasTitle>

			<CCloseButton onClick={() => uDispatch({ type: 'set', masterdataShow: false })} 
				 className="text-reset"/>
			</COffcanvasHeader>

			<COffcanvasBody>
			<CTabContent className="">
			{uMenuekstrakArr.map((vItems,vKeys)=>{
				const vItemArr	= vItems.children_arr || [];

				return (
				<CTabPane role="tabpanel" key={vKeys} aria-labelledby={vItems.nama+"_tab"} visible={uActivekey === vKeys}>
				<div className="fw-bolder">{vItems.caption}</div>
				<div className="classborderbottom mt-2"/>
				{vItemArr.map((vItemchildren,vKeychildren)=>{
					return (
					<Link className="classcursorpointer text-decoration-none d-flex classborderbottom py-2" 
						onMouseOver={(e)=>{e.currentTarget.style.backgroundColor="#f0f0f0"}}
						onMouseOut={(e)=>{e.currentTarget.style.backgroundColor="transparent"}}
						onClick={()=>{ 
							uDispatch({ type: 'set', masterdataShow: false });
						}}
						to={"/"+vItemchildren.name}
						key={vKeychildren}>
					<div className="px-1">
						<CImage src={vItemchildren.src} className="" height={28}/>
					</div>
					<div className={"ps-1"+(uActiveroute===vItemchildren.name ? " fw-bolder" : "")}>{vItemchildren.caption}</div>
					</Link>
					)
				})}
				<div className="d-none">{JSON.stringify(vItemArr)}</div>
				</CTabPane>
				)
			})}
			</CTabContent>
			</COffcanvasBody>
		</COffcanvas>
		</>
	)
}

export default React.memo(ContMasterdata)