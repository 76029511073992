import React from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CFooter,
	CHeader,
	CHeaderToggler,
	CHeaderNav,
	CHeaderBrand,
	CContainer,
	CForm,
	CFormSelect,
	CFormInput,
	CFormTextarea,
	CFormCheck,
	CInputGroup,
	CInputGroupText,
	CButton,
	CImage,
	CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyDialoglookupapi,
	MyDialogview,
	MyButton,
	MyDialogform,
	MyPage500,
	MyProfileDetil,
} from '../../components/index';
import { UFunc,cBulanPanjang,cHariPendek,cAngkaTgl,cBulanPendek } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import Resizer from "react-image-file-resizer";
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     = require('../../../package.json');
const Pasieninput = (props) => {
	const uComponentname 	= Pasieninput.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 
	const { id } 			= useParams(); 

	let uFuncName;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted) || true;
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uIsInitHapus		= (uTokenObj.userhak!=="FREEROLE"&&uTokenObj.userhak!=="ADMIN")||!uIsEOMExecuted 
		? false : true;
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uStartThnLahir,setStartThnLahir]	= React.useState(uDefaultSettingObj.start_thn_lahir || "");
	const [uThnLahirArr,setThnLahirArr]		= React.useState([]);

	const uDefaultFoto						= pjson.homepage+"api/images/icon_profile.png";
	const refFileImg 						= React.useRef();
	const uDefaultJenishewanArr				= [{id:"0",caption:(uBahasaObj.word_lainnya||"word_lainnya")}];

	const uIDPasien							= parseInt(id) || 0;
	const [uInpPasienedit,setInpPasienedit]	= React.useState("");
	const [uIsFormprocess,setFormprocess]	= React.useState(false);
	//const [uCustomerArr,setCustomerArr]		= React.useState([]);
	const [uGenderArr,setGenderArr]			= React.useState([]);
	const [uReproduksiArr,setReproduksiArr]	= React.useState([]);
	const [uJenishewanArr,setJenishewanArr]	= React.useState(uDefaultJenishewanArr);
	const [uDataPasienObj,setDataPasienObj]	= React.useState({});
	const [uIsActionchange,setActionchange]	= React.useState(false);

	const [uInpPasiennama,setInpPasiennama]	= React.useState("");
	const [uInpSignalement,setInpSignalement]= React.useState("");
	const [uInpCustomercaption,setInpCustomercaption]	= React.useState("");
	const [uInpCustomernama,setInpCustomernama]			= React.useState("");
	const [uInpCustomerkeyword,setInpCustomerkeyword]	= React.useState("");
	const [uInpCustomeralamat,setInpCustomeralamat]		= React.useState("");
	const [uInpCustomertelp,setInpCustomertelp]			= React.useState("");
	const [uInpCustomerID,setInpCustomerID]	= React.useState("0");
	const [uInpAlergi,setInpAlergi]			= React.useState("");
	const [uInpLahirTGL,setInpLahirTGL]		= React.useState("0");
	const [uInpLahirBLN,setInpLahirBLN]		= React.useState("0");
	const [uInpLahirTHN,setInpLahirTHN]		= React.useState("0");
	const [uInpNochip,setInpNochip]			= React.useState("");
	const [uInpCustomerJenis,setInpCustomerJenis]= React.useState("LAMA");
	const [uInpImageprofile,setInpImageprofile]= React.useState("");
	const [uInpJenishewanID,setInpJenishewanID]= React.useState("1");
	const [uInpKodepasien,setInpKodepasien]	= React.useState("");
	const [uInpGender,setInpGender]			= React.useState("UNKNOWN");
	const [uInpReproduksi,setInpReproduksi]	= React.useState("0");
	const [uInpHistorivaksin,setHistorivaksin]	= React.useState([]);

	//---FORMFOTO--/
	const [uIsShowDlgfoto,setShowDlgfoto]	= React.useState(false);
	//---END FORMFOTO--/

	//---FORMDIALOG--/
	const [uIsShowDlgform,setShowDlgform]	= React.useState(false);
	const [uInpVaksintgl,setInpVaksintgl]	= React.useState("");
	const [uInpVaksinjenis,setInpVaksinjenis]= React.useState("");
	const [uInpVaksinket,setInpVaksinket]	= React.useState("");
	//---END FORMDIALOG--/

	//---LOOKUP--/
	let uLookupparsObj = {};
	const [uIsLookupShow,setLookupShow]		= React.useState(false);
	const [uISLookuptrigger,setLookuptrigger]= React.useState(false);
	//const [uLookupDataArr,setLookupDataArr]	= React.useState([]);
	const [uInpLookupKwd,setInpLookupKwd]	= React.useState("");
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	//---END LOOKUP--/

	const vBtnSimpanmain	= document.getElementById("btnSimpan");
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			if(uIsFormprocess) return;

			_EV.preventDefault();
			hdlKlikSimpan();
		}
	}
	const hdlKlikReset=()=>{
		if(uHTMLError500) {
			apiInitform(); return;
		}

		setHistorivaksin([]);
		initFormawal();
		setActionchange(!uIsActionchange);
	}
	const hdlKlikEditfoto=()=>{
		//refFileImg.current.click();

		setDialogHeader((uBahasaObj.caption_updatefoto||"caption_updatefoto"));
		setShowDlgfoto(true);//-*/
	}
	const hdlKlikUploadfoto=()=>{ refFileImg.current.click(); }
	const hdlChangeFotofake=(_EV)=>{
		const fileUploaded 	= _EV.target.files[0];
		//console.log("(Pasieninput - hdlChangeFotofake) fileUploaded => "+fileUploaded);
		if(!fileUploaded) return;

		//--CEK_VALIDASI_FILE--/
		const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
		if (!allowedTypes.includes(fileUploaded?.type)) {
			_EV.target.value = '';
			showToast("<b>Only JPEG, PNG, and GIF images are allowed</b>.."+
				"<br/>Ada TIPE File yang Tidak Berformat IMAGE."+
				"<br/><br/>Mohon Diperbaiki !"+
				"");
			return;
		}
		//--END CEK_VALIDASI_FILE--/

		const resizeAndConvertToBase64 = (_FILE,_TYPE) => {
			return new Promise((resolve, reject) => {
			});
		};

		//--JIKA_FILESIZE_LEBIH_DARI_1MB--/
		const vFilesize	= (parseFloat(fileUploaded.size) || 0) / 1024 / 1024;
		if(vFilesize > 1) {
			const vFiletype	= (fileUploaded.type||"jpeg").
				replace(/^image\//, "").toUpperCase();

			Resizer.imageFileResizer(
				fileUploaded,
				1300, // New width
				800, // New height
				vFiletype, // Format
				50, // Quality
				0, // Rotation
				(uri) => {
					//resolve(uri); // This will be a base64 string
					setInpImageprofile(uri);
					_EV.target.value = '';
				},
				'base64' // Output type
			);
		
			return;
		}
		//--END JIKA_FILESIZE_LEBIH_DARI_1MB--/

		UFunc.getBase64(fileUploaded).then(result => {
			const fileBase64 = result;
			setInpImageprofile(fileBase64);
			//console.log("File Is", fileBase64);
			_EV.target.value = '';
		}).catch(err => {
			_EV.target.value = '';
			console.log(err);
		});
	}
	const hdlKlikHapusfoto=async()=>{
		if(await Konfirm(
			(uBahasaObj.petunjuk_hapusfoto||"petunjuk_hapusfoto")+
			"<BR>"+
			"<BR>"+
			(uBahasaObj.confirm_yakin||"confirm_yakin"))) {
			setInpImageprofile("");
		}
	}
	const hdlKlikKamerafoto=()=>{ showToast("Maaf, Fitur Belum Tersedia..!"); }
	const hdlToggleDlgfoto=()=>{setShowDlgfoto(false)}

	const hdlToggleDlgform=()=>{setShowDlgform(false)}
	const hdlKlikDlgSimpan=()=>{
		let vElsFocus = document.getElementById("inpvaksintgl");
		if(uInpVaksintgl === "") {
			vElsFocus.focus();
			showToast((uBahasaObj.caption_tglvaksin||"Tgl Vaksin")+" "+uBahasaObj.caption_invalid+".."); return;
		}
		vElsFocus = document.getElementById("inpvaksinjenis");
		if(uInpVaksinjenis === "") {
			vElsFocus.focus();
			showToast((uBahasaObj.caption_jenisvaksin||"Jenis Vaksin")+" "+uBahasaObj.caption_mustfilled+".."); return;
		}
		vElsFocus = document.getElementById("inpvaksinket");
		if(uInpVaksinket === "") {
			vElsFocus.focus();
			showToast((uBahasaObj.caption_pemberivaksin||"Pemberi Vaksin")+" "+uBahasaObj.caption_mustfilled+".."); return;
		}

		let vVaksinhistoriArr = uInpHistorivaksin;
		const vVaksinObj	= {
			id:"0",
			tgl_vaksin: uInpVaksintgl,
			vaksin_caption: UFunc.capitalize(uInpVaksinjenis.trim()),
			pemberi: UFunc.capitalize(uInpVaksinket.trim()),
		}
		setHistorivaksin([...uInpHistorivaksin,vVaksinObj]);

		setShowDlgform(false);
		//initDlgformreset(); document.getElementById("inpvaksintgl").focus();
	}

	const hdlToggleFLookup=()=>{setLookupShow(false)}
	const hdlKlikLookupcustomer=()=>{
		setInpLookupKwd(uInpCustomercaption);
		setDialogHeader((uBahasaObj.word_lookup||"word_lookup")+": "+(uBahasaObj.word_customer||"word_customer"));
		setLookupShow(true);
	}
	const hdlKeyDownCustomer=(_EV)=>{
		if(_EV.keyCode !== 13) {
			if(uInpCustomerJenis === "LAMA") {
				setInpCustomeralamat("")
				setInpCustomertelp("")
				setInpCustomerID("0");
				setInpCustomernama("");
				setInpCustomeralamat("");
				setInpCustomertelp("");
				setInpCustomerkeyword("");
			}
			return;
		}
		if(uInpCustomercaption.trim()==="") { 
			//document.getElementById("inp").focus();
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();

		setLookuptrigger(true)
		hdlKlikLookupcustomer();
	}
	const hdlKlikPilihLookup=(_SELECTEDSTR)=>{
		_SELECTEDSTR = _SELECTEDSTR||"{}";
		//if(_SELECTEDSTR==="" || _SELECTEDSTR === "{}") return;
		const vLookupObj	= JSON.parse(_SELECTEDSTR);
		if(vLookupObj==={}) return;

		setInpCustomerID(vLookupObj.id || "0");
		setInpCustomercaption(vLookupObj.caption);
		setInpCustomernama(vLookupObj.nama_customer || "");
		setInpCustomerkeyword(vLookupObj.keyword || "");
		setInpCustomeralamat(UFunc.br2nl(vLookupObj.alamat));
		setInpCustomertelp(vLookupObj.telp);

		hdlToggleFLookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			const vElsFocus	= document.getElementById("inpcustomerkeyword");
			vElsFocus && vElsFocus.focus();
		},350);
	}

	const hdlKlikTambahvaksin=()=>{ 
		setDialogHeader(uBahasaObj.caption_tambahdata+" "+(uBahasaObj.caption_historivaksin||"caption_historivaksin"));
		setShowDlgform(true); 
	}
	const hdlKlikHapusvaksin=async(_IDX)=>{
		_IDX = parseInt(_IDX) || 0;
		if(_IDX < 0) return;
		
		const vProseshapus=()=>{
			const vIDtabel = parseInt(uInpHistorivaksin[_IDX].id)||0;
			if(vIDtabel <= 0)
				uInpHistorivaksin.splice(_IDX,1);
			else
				uInpHistorivaksin[_IDX].status_hapus = "YA";

			setActionchange(!uIsActionchange);
		} 

		if(await Konfirm((uBahasaObj.confirm_yakin||"confirm_yakin"))) {
			vProseshapus();
		}
	}

	const hdlKlikSimpan=()=>{
		//---VALIDASI--/
		let vElsFocus = document.getElementById("inpnamapasien").focus();
		if((uInpPasiennama||"").trim()==="") {
			vElsFocus && vElsFocus.focus()
			showToast((uBahasaObj.caption_namapasien||"caption_namapasien")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
			return;
		}
		if((uInpPasiennama||"").trim().length < 3) {
			vElsFocus && vElsFocus.focus()
			showToast((uBahasaObj.caption_namapasien||"caption_namapasien")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		vElsFocus = document.getElementById("inpsignalement").focus();
		if((uInpSignalement||"").trim()==="") {
			vElsFocus && vElsFocus.focus()
			showToast((uBahasaObj.word_signalement||"word_signalement")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
			return;
		} else {
			if((uInpSignalement||"").trim().length < 3) {
				vElsFocus && vElsFocus.focus()
				showToast((uBahasaObj.word_signalement||"word_signalement")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
				return;
			}
		}
		if((uInpCustomernama||"").trim()==="") {
			if(uInpCustomerJenis === "LAMA") {
				vElsFocus = document.getElementById("inpcustomercaption").focus();
				vElsFocus && vElsFocus.focus()
				showToast((uBahasaObj.word_customer||"word_customer")+" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
				return;
			} else {
				vElsFocus = document.getElementById("inpcustomernama").focus();
				vElsFocus && vElsFocus.focus()
				showToast((uBahasaObj.word_customer||"word_customer")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
				return;
			}
		}
		vElsFocus = document.getElementById("inpcustomeralamat").focus();
		if((uInpCustomeralamat||"").trim()==="" && (uInpCustomertelp||"").trim()==="") {
			vElsFocus && vElsFocus.focus()
			showToast((uBahasaObj.word_alamat||"word_alamat")+" / "+
				(uBahasaObj.caption_telphp||"caption_telphp")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
			return;
		}
		vElsFocus = document.getElementById("inpthn").focus();
		if((parseInt(uInpLahirTGL)||0)>0 || (parseInt(uInpLahirBLN)||0)>0)
			if((parseInt(uInpLahirTHN)||0)<=0) {
				vElsFocus && vElsFocus.focus()
				showToast((uBahasaObj.caption_tahunlahir||"caption_tahunlahir")+" "+
					(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
				return;
			}
		//---END VALIDASI--/

		//console.log(uInpImageprofile); return;
		apiSimpanmain();
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initFormawal=()=>{
		const vDataObj	= uDataPasienObj;
		//console.log("(Pasieninput - initFormawal) uDataPasienObj = "+JSON.stringify(uDataPasienObj));

		setInpPasiennama(vDataObj.nama_pasien || "");
		setInpPasienedit(vDataObj.nama_pasien || "");
		setInpSignalement(vDataObj.signalement || "");
		setInpImageprofile(vDataObj.url_foto || "");

		setInpCustomerJenis("LAMA");
		setInpCustomerID(vDataObj.customer_id || "0");
		setInpCustomercaption(vDataObj.customer_caption || "");
		setInpCustomernama(vDataObj.customer_caption || "");
		setInpCustomerkeyword(vDataObj.customer_keyword || "");
		setInpCustomeralamat(UFunc.br2nl(vDataObj.customer_alamat || ""));
		setInpCustomertelp(vDataObj.customer_telp || "");

		setInpNochip(vDataObj.nochip || "");
		setInpLahirTGL(vDataObj.lahir_tgl || "0");
		setInpLahirBLN(vDataObj.lahir_bln || "0");
		setInpLahirTHN(vDataObj.lahir_thn || "0");
		setInpAlergi(vDataObj.alergi || "");

		setInpJenishewanID(vDataObj.id_jenis || "1");
		setInpKodepasien(vDataObj.kode_pasien || "");
		setInpGender(vDataObj.gender || "UNKNOWN");
		setInpReproduksi(vDataObj.reproduksi || "0");

		let vVaksinhistoriArr	= vDataObj.vaksin_arr || [];
		vVaksinhistoriArr.map((vItems,vKeys)=>{ if(vItems.status_hapus==="YA") vItems.status_hapus = "TIDAK"; });
		setHistorivaksin(vVaksinhistoriArr);

		//if(uInpCustomerJenis!=="LAMA") initFormawal();
	}
	const initDlgformreset=()=>{
		setInpVaksintgl("");
		setInpVaksinjenis("");
		setInpVaksinket("");
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";
		//console.log("("+uComponentname+" - contentMain) uJenishewanArr=> "+JSON.stringify(uJenishewanArr));

		return (
		<CForm className="m-0 mx-md-3 mx-lg-5">
		<CRow className="my-2">
			<CCol md="3" className="px-2 text-md-end">{uBahasaObj.word_foto||"word_foto"}</CCol> 
			<CCol md="7" lg="4" className="px-2 d-flex justify-content-center">
				<CLink onClick={()=>hdlKlikEditfoto()}
					className="classcursorpointer position-relative">
					<div className="classimgcircle" style={{
						height:(uInpImageprofile!==""?100:70),
						width:(uInpImageprofile!==""?100:70),
					}}>
					<CImage
						src={uInpImageprofile===""?uDefaultFoto:uInpImageprofile}
						className={"classprofilepic"+(uInpImageprofile===""?" classgrayscale":"")} 
						/>
					</div>
					<CBadge color="dark" className="classposbottomcenter">Edit</CBadge>
				</CLink> 
			</CCol> 
		</CRow> 
		
		<CRow className="m-0 my-3">
			<CCol md="3" className="px-2" />
			<CCol md="9" lg="6" className="px-2 classborderbottom" />
		</CRow> 
		<CRow className="my-2">
			<CCol md="3" className="px-2 text-md-end fw-bolder">{uBahasaObj.caption_namapasien||"caption_namapasien"}</CCol> 
			<CCol md="7" lg="5" className="px-2">
				<CFormInput
					type="text"
					size="sm"
					maxLength={100}
					className="text-capitalize"
					value={uInpPasiennama}
					onChange={(e)=>{setInpPasiennama(e.target.value)}}
					id="inpnamapasien"/>
			</CCol> 
		</CRow> 
		<CRow className="my-2">
			<CCol md="3" className="px-2 text-md-end fw-bolder">{uBahasaObj.word_signalement||"word_signalement"}</CCol> 
			<CCol md="7" lg="5" className="px-2">
				<CFormInput size="sm"
					maxLength={200}
					className="text-capitalize"
					value={uInpSignalement}
					onChange={(e)=>{setInpSignalement(e.target.value)}}
					id="inpsignalement"/>
			</CCol> 
		</CRow>
		<CRow className="my-2">
			<CCol md="3" className="px-2 text-md-end">{uBahasaObj.caption_kodepasien||"caption_kodepasien"}</CCol> 
			<CCol md="7" lg="5" className="px-2">
				<CFormInput
					type="text"
					size="sm"
					maxLength={30}
					className="text-uppercase"
					value={uInpKodepasien}
					onChange={(e)=>{setInpKodepasien(e.target.value)}}
					id="inpkodepasien"/>
			</CCol> 
		</CRow> 
		
		<CRow className="m-0 my-3">
			<CCol md="3" className="px-2" />
			<CCol md="9" lg="6" className="px-2 classborderbottom" />
		</CRow> 
		<CRow className="my-2">
			<CCol md="3" className="px-2 text-md-end">{uBahasaObj.caption_nochip||"caption_nochip"}</CCol> 
			<CCol md="5" lg="3" className="px-2">
				<CFormInput size="sm"
					maxLength={30}
					value={uInpNochip}
					className="text-uppercase"
					onChange={(e)=>{setInpNochip(e.target.value)}}
					id="inpnochip"/>
			</CCol> 
		</CRow>
		<CRow className="my-2">
			<CCol md="3" className="px-2 text-md-end">{uBahasaObj.caption_jenishewan||"caption_jenishewan"}</CCol> 
			<CCol md="5" lg="3" className="px-2">
				<CFormSelect size="sm"
					value={uInpJenishewanID}
					onChange={(e)=>setInpJenishewanID(e.target.value)}
					id="inpjenishewan">
				{uJenishewanArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.id} key={vKeys}>{(vItems.caption||"").toUpperCase()}</option>
					);
				})}
				</CFormSelect>
			</CCol> 
		</CRow> 
		<CRow className="my-2">
			<CCol md="3" className="px-2 text-md-end">{uBahasaObj.caption_jeniskelamin||"Jenis Kelamin"}</CCol> 
			<CCol xs="6" md="5" lg="3" className="px-2 pr-1">
				<CFormSelect size="sm"
					value={uInpGender}
					onChange={(e)=>setInpGender(e.target.value)}
					id="inpgender">
				{uGenderArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.value} key={vKeys}>{(vItems.caption||"").toUpperCase()}</option>
					);
				})}
				</CFormSelect>
			</CCol>
			<CCol xs="6" md="4" lg="3" className="px-1">
				<CFormSelect size="sm"
					value={uInpReproduksi}
					onChange={(e)=>setInpReproduksi(e.target.value)}
					id="inpreproduksi">
				<option value="0">--{(uBahasaObj.caption_statusreproduksi||"Status Reproduksi").toUpperCase()}</option>
				{uReproduksiArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.value} key={vKeys}>{(vItems.caption||"").toUpperCase()}</option>
					);
				})}
				</CFormSelect>
			</CCol> 
		</CRow> 

		<CRow className="m-0 my-3">
			<CCol md="3" className="px-2" />
			<CCol md="9" lg="6" className="px-2 classborderbottom" />
		</CRow> 

		<CRow className="my-2">
			<CCol md="3" className="px-2 text-md-end">{uBahasaObj.word_pemilik||"word_pemilik"}</CCol> 
			<CCol xs="6" md="4" lg="3" className="px-2">
				<CFormCheck size="sm" variant="checkbox"
					value="LAMA"
					onChange={(e)=>setInpCustomerJenis(e.target.value)}
					checked={uInpCustomerJenis!=="BARU"}
					label={(uBahasaObj.caption_customerlama||"caption_customerlama").toUpperCase()}
					name="inpcustomerjenis"
					id="inpcustomerlama"/>
			</CCol>
			<CCol xs="6" md="5" lg="3" className="px-2">
				<CFormCheck size="sm" variant="checkbox"
					value="BARU"
					onChange={(e)=>setInpCustomerJenis(e.target.value)}
					checked={uInpCustomerJenis==="BARU"}
					label={(uBahasaObj.caption_customerbaru||"caption_customerbaru").toUpperCase()}
					name="inpcustomerjenis"
					id="inpcustomerbaru"/>
			</CCol> 
		</CRow> 
		{uInpCustomerJenis==="LAMA" && (
		<>
		<CRow className="my-2">
			<CCol md="3" className="px-2"/>
			<CCol md="9" lg="6" className="px-2">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					placeholder={"---"+(uBahasaObj.petunjuk_keywordcustomer||"petunjuk_keywordcustomer")+".."}
					value={uInpCustomercaption}
					onKeyDown={(e)=>hdlKeyDownCustomer(e)}
					onChange={(e)=>{setInpCustomercaption(e.target.value);setInpCustomerID(0)}}
					id="inpcustomercaption"/>
				<CInputGroupText className="p-0">
					<CButton color="light" size="sm"
						onClick={()=>hdlKlikLookupcustomer()} 
						tabIndex={-1}
						id="btncustomerlookup">
					<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
				</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow> 
		</>
		)}
		{uInpCustomerJenis==="BARU" && (
		<>
		<CRow className="my-2">
			<CCol md="3" className="px-2"/>
			<CCol md="9" lg="6" className="px-2">
				<CFormInput
					type="text"
					size="sm"
					maxLength="100"
					placeholder={"---"+(uBahasaObj.caption_namacustomer||"caption_namacustomer").toUpperCase()+".."}
					className="text-capitalize"
					value={uInpCustomernama}
					onChange={(e)=>{setInpCustomernama(e.target.value)}}
					id="inpcustomernama"/>
			</CCol>
		</CRow> 
		</>
		)}
		<CRow className="my-2">
			<CCol md="3" className="px-2"/>
			<CCol md="9" lg="6" className="px-2">
				<CFormInput
					type="text"
					size="sm"
					maxLength="100"
					placeholder={"---"+((uBahasaObj.word_keyword||"Keyword")).toUpperCase()+".."}
					className=""
					value={uInpCustomerkeyword}
					onChange={(e)=>{setInpCustomerkeyword(e.target.value)}}
					id="inpcustomerkeyword"/>
			</CCol>
		</CRow> 
		<CRow className="my-2">
			<CCol md="3" className="px-2"/>
			<CCol md="9" lg="6" className="px-2">
				<CFormTextarea
					size="sm"
					className="text-capitalize"
					placeholder={"---"+(uBahasaObj.word_alamat||"word_alamat").toUpperCase()+".."}
					value={uInpCustomeralamat||""}
					onChange={(e)=>{setInpCustomeralamat(e.target.value)}}
					id="inpcustomeralamat"/>
			</CCol>
		</CRow> 
		<CRow className="my-2">
			<CCol md="3" className="px-2"/>
			<CCol md="5" lg="3" className="px-2">
				<CFormInput
					type="text"
					size="sm"
					maxLength="50"
					placeholder={"---"+(uBahasaObj.caption_telphp||"caption_telphp").toUpperCase()+".."}
					className="text-uppercase"
					value={uInpCustomertelp}
					onChange={(e)=>{setInpCustomertelp(e.target.value)}}
					id="inpcustomertelp"/>
			</CCol>
		</CRow> 

		<CRow className="m-0 mt-3">
			<CCol md="3" className="px-2" />
			<CCol md="9" className="px-2 classborderbottom" />
		</CRow> 
		<CRow className="m-0 my-2 classfontsmaller">
			<CCol md="3" className="px-2 text-md-end text-primary fw-bolder">
				<big>{uBahasaObj.caption_historivaksin||"History Vaksin"}</big>
			</CCol> 
			<CCol xs="3" md="2" className="px-0 pl-2 mb-2 text-primary">{uBahasaObj.caption_tglvaksin||"Tgl Vaksin"}</CCol> 
			<CCol xs="4" md="3" className="px-0 pl-1 mb-2 text-primary">{uBahasaObj.caption_jenisvaksin||"Jenis Vaksin"}</CCol> 
			<CCol xs="4" md="3" className="px-0 pl-1 mb-2 text-primary">{uBahasaObj.caption_pemberivaksin||"Pemberi Vaksin"}</CCol> 
			<CCol xs="1" className="px-0 text-center mb-2">
				<CLink 
					onClick={()=>hdlKlikTambahvaksin()}
					className="classcursorpointer classikontabel classikontambah3"/>
			</CCol>
			{uInpHistorivaksin.
				filter((vItemf,vKeyf)=>{return ((vItemf.status_hapus||"TIDAK")==="TIDAK")}).
				map((vItems,vKeys)=>{
				return (
				<>
				<CCol md="3"  key={vKeys} className="px-2 d-sm-down-none"/>
				<CCol xs="3" md="2" className="px-0 pl-2">{UFunc.TglAngka(vItems.tgl_vaksin)}</CCol> 
				<CCol xs="4" md="3" className="px-0 pl-1">{vItems.vaksin_caption||"UNDF"}</CCol> 
				<CCol xs="4" md="3" className="px-0 pl-1">{vItems.pemberi||"UNDF"}</CCol> 
				<CCol xs="1" className="px-0 text-center">
					<CLink className="classcursorpointer" 
						onClick={()=>hdlKlikHapusvaksin(vKeys)}>
						<CIcon icon="cilXCircle" height="20"/>
					</CLink>
				</CCol>
				</>
				);
			})}
		</CRow> 

		<CRow className="m-0 mb-3">
			<CCol md="3" className="px-2" />
			<CCol md="9" className="px-2 classborderbottom" />
		</CRow> 
		<CRow className="my-2">
			<CCol xs="12" md="3" className="px-2 text-md-end">{uBahasaObj.caption_tgllahir||"caption_tgllahir"}</CCol> 
			<CCol md="7" lg="9" className="px-1">
				<div className="d-inline-block px-1">
				<CFormSelect
					size="sm"
					value={uInpLahirTGL}
					onChange={(e)=>setInpLahirTGL(e.target.value)}
					id="inptgl">
				<option value="0">-TGL-</option>
				{cAngkaTgl.map((vItems,vKeys)=>{
					return (<option key={vKeys} value={UFunc.leadZero(vItems,2)}>{UFunc.leadZero(vItems,2)}</option>)
				})}
				</CFormSelect>
				</div>
				<div className="d-inline-block text-center">/</div> 
				<div className="d-inline-block px-1">
				<CFormSelect
					size="sm"
					value={uInpLahirBLN}
					onChange={(e)=>setInpLahirBLN(e.target.value)}
					id="inpbln">
				<option value="0">-BLN-</option>
				{cBulanPendek.map((vItems,vKeys)=>{
					return (<option key={vKeys} value={UFunc.leadZero((vKeys+1),2)}>{vItems.toUpperCase()}</option>)
				})}
				</CFormSelect>
				</div>
				<div className="d-inline-block text-center">/</div> 
				<div className="d-inline-block px-1">
				<CFormSelect
					size="sm"
					value={uInpLahirTHN}
					onChange={(e)=>setInpLahirTHN(e.target.value)}
					id="inpthn">
				<option value="0">-THN-</option>
				{uThnLahirArr.map((vItems,vKeys)=>{
					return (<option key={vKeys} value={UFunc.leadZero(vItems,4)}>{UFunc.leadZero(vItems,4)}</option>)
				})}
				</CFormSelect>
				</div>
				<div className="d-block d-lg-inline px-1 classpetunjuk">
					<small>*) {uBahasaObj.petunjuk_tgllahir||"petunjuk_tgllahir"}</small>
				</div>
			</CCol> 
		</CRow> 
		<CRow className="my-2">
			<CCol md="3" className="px-2 text-md-end">{uBahasaObj.caption_riwayatalergi||"caption_riwayatalergi"}</CCol> 
			<CCol md="9" lg="6" className="px-2">
				<CFormInput
					type="text"
					size="sm"
					maxLength="255"
					className="text-capitalize"
					value={uInpAlergi}
					onChange={(e)=>{setInpAlergi(e.target.value)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
					id="inpalergi"/>
			</CCol> 
		</CRow> 
		</CForm>
		)//>
	}
	const contentDlgfoto = () => {
		return (
		<div className="p-1">
		<CRow className="mx-0">
		<CCol className="py-2 d-flex justify-content-center">
			<div className="classimgcircle" style={{height:150,width:150}}>
			<CImage
				src={uInpImageprofile===""?uDefaultFoto:uInpImageprofile}
				className={"classprofilepic"+(uInpImageprofile===""?" classgrayscale":"")} 
				/>
			</div>
		</CCol>
		</CRow>
		
		<CRow className="mx-0">
		<CCol className="text-warning text-center fst-italic py-3">
			{uBahasaObj.petunjuk_fotopasien||"Pilih Foto Atau Ambil yang Baru"}
		</CCol>
		</CRow>
		
		{uInpImageprofile !== "" && (
		<CRow className="mx-0">
		<CCol
			className="py-3 classborderbottom text-danger fw-bolder">
			<div onClick={()=>hdlKlikHapusfoto()}
			 	className="d-inline-block classcursorpointer">
			<CIcon className="classikon classikonhapus me-2" height={30}/>
			{uBahasaObj.caption_hapusfoto||"caption_hapusfoto"}
			</div>
		</CCol>
		</CRow>
		)}
		
		<CRow className="mx-0">
		<CCol className="py-3 classborderbottom">
			<div onClick={(e)=>hdlKlikUploadfoto(e)}
			 	className="d-inline-block classcursorpointer">
			<CIcon className="classikon classikonupload" height="30"/>{" "}
			{uBahasaObj.word_upload||"word_upload"}
			</div>
			<input type="file"
				accept="image/png, image/jpeg, image/gif, capture=camera"
				ref={refFileImg}
				onChange={(e)=>hdlChangeFotofake(e)}
				className="d-none"
				id="inpfileimg"/>
		</CCol>
		</CRow>
		
		<CRow className="mx-0 d-none">
		<CCol className="py-3 classborderbottom">
			<div onClick={hdlKlikKamerafoto}
			 	className="d-inline-block classcursorpointer">
			<CIcon className="classikon classikonkamera" height="30"/>{" "}
			{uBahasaObj.word_kamera||"word_kamera"}
			</div>
		</CCol>
		</CRow>

		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uIsShowDlgform) return (<></>)//>

		return (
		<CForm>
		<CRow className="mx-0 my-1">
			<CCol md="4" className="px-2 text-md-end">{uBahasaObj.caption_tglvaksin||"caption_tglvaksin"}</CCol>
			<CCol md="8" lg="6" className="px-2">
				<CFormInput type="date" size="sm"
					value={uInpVaksintgl}
					maxLength="8"
					onChange={(e)=>setInpVaksintgl(e.target.value)}
					id="inpvaksintgl"/>
			</CCol>
		</CRow>

		<CRow className="mx-0 my-1">
			<CCol md="4" className="px-2 text-md-end">{uBahasaObj.caption_jenisvaksin||"caption_jenisvaksin"}</CCol>
			<CCol md="8" className="px-2">
				<CFormInput type="text" size="sm"
					maxLength="100"
					className="text-capitalize"
					value={uInpVaksinjenis}
					onChange={(e)=>setInpVaksinjenis(e.target.value)}
					id="inpvaksinjenis"/>
			</CCol>
		</CRow>

		<CRow className="mx-0 my-1">
			<CCol md="4" className="px-2 text-md-end">{uBahasaObj.caption_pemberivaksin||"caption_pemberivaksin"}</CCol>
			<CCol md="8" className="px-2">
				<CFormInput type="text" size="sm"
					maxLength="255"
					className="text-capitalize"
					value={uInpVaksinket}
					onChange={(e)=>setInpVaksinket(e.target.value)}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpvaksinket"/>
			</CCol>
		</CRow>
		</CForm>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiInitform = () => {
		setHTMLError500();
		uFuncName = apiInitform.name;

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiInitform) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		//setCustomerArr([]);
		setDataPasienObj({});
		const vDATAS	= JSON.stringify({
			send_pasienid : uIDPasien,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_init";

		initFormawal();
		setLoading(true);
		setFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncName+") uJenishewanArr : "+JSON.stringify(uJenishewanArr));
			if(output_string.tampil) {
				setFormprocess(false);
				setGenderArr(JSON.parse(output_string.genderobject||"[]"));
				setReproduksiArr(JSON.parse(output_string.reproduksiobject||"[]"));
				
				const vTmpArr	= (JSON.parse(output_string.jenishewanobject||"[]")).concat(uDefaultJenishewanArr);
				setJenishewanArr(vTmpArr);

				if(uIDPasien > 0) {
					setDataPasienObj(JSON.parse(output_string.dataobject || "{}"));
				}
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiSimpanmain = () => {
		uFuncName = apiSimpanmain.name;

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncName+") LEWAT ");
		initFormawal();
		showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
		if(isMobile) document.getElementById("inpnamapasien").focus();

		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uIDPasien,
			send_pasiennama : uInpPasiennama,
			send_signalement : uInpSignalement,
			send_alergi : uInpAlergi,
			send_nochip : uInpNochip,
			send_customerjenis : uInpCustomerJenis,
			send_customerid : uInpCustomerID,
			send_customernama : uInpCustomernama,
			send_customerkeyword : uInpCustomerkeyword,
			send_customeralamat : uInpCustomeralamat,
			send_customertelp : uInpCustomertelp,
			send_lahirtgl : uInpLahirTGL,
			send_lahirbln : uInpLahirBLN,
			send_lahirthn : uInpLahirTHN,
			send_fotobase64 : uInpImageprofile,
			send_jenishewanid : uInpJenishewanID,
			send_kodepasien : uInpKodepasien,
			send_gender : uInpGender,
			send_reproduksi : uInpReproduksi,
			send_vaksinlist : (JSON.stringify(uInpHistorivaksin)),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_simpan";

		setLoading(true);
		setFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setFormprocess(false);
			if(output_string.sukses) {
				if(uIDPasien <= 0) {
					showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
					initFormawal();
					if(!isMobile) document.getElementById("inpnamapasien").focus();
				} else {
					setFormprocess(true);
					uNavigate(-1);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setFormprocess(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setFormprocess(false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncName+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);
		//return function cleanup() {
		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	if(!isMobile && document.getElementById("inpnamapasien")) document.getElementById("inpnamapasien").focus();
    	
    	apiInitform();

    	return () => { 
	    	setHTMLError500();
    		
    		setShowDlgform(false);
			setShowDlgfoto(false);
			setLookupShow(false);

			setDataPasienObj({});
    		setJenishewanArr([]);
    		setReproduksiArr([]);
    		setGenderArr([]);
    		
    		initFormawal();
    	}
	},[]);
	//React.useEffect(()=>{ setLookupDataArr(uCustomerArr); },[uCustomerArr]);
	React.useEffect(()=>{
		const vObjstr = JSON.stringify(uDataPasienObj);
		//console.log("(Pasieninput - [uDataPasienObj]) vObjstr = "+vObjstr);
		if(vObjstr === "{}") return;
		initFormawal();
	},[uDataPasienObj]);
	React.useEffect(()=>{
		const vDataObj	= uInpCustomerJenis==="LAMA" ? uDataPasienObj : {};

		setInpCustomerID(vDataObj.customer_id || "0");
		setInpCustomercaption(vDataObj.customer_caption || "");
		setInpCustomernama(vDataObj.customer_caption || "");
		setInpCustomeralamat(UFunc.br2nl(vDataObj.customer_alamat || ""));
		setInpCustomertelp(vDataObj.customer_telp || "");

		const vEldom = document.getElementById("btncustomerlookup")
		if(uInpCustomerJenis==="LAMA")
			vEldom && (vEldom.disabled = false)
		else {
			vEldom && (vEldom.disabled = true)
		}
	},[uInpCustomerJenis]);
	React.useEffect(()=>{
		if(!uIsLookupShow) {
			vBtnSimpanmain && (vBtnSimpanmain.disabled = false);
			setLookuptrigger(false);
			return;
		}
		vBtnSimpanmain.disabled = true;
	},[uIsLookupShow]);
	React.useEffect(()=>{
		const vStartYear	= parseInt(uStartThnLahir)||0;
		const vDate 		= new Date();
		const vYearNow		= vDate.getFullYear();
		if(vStartYear <= 0) {
			const vYearLahir 	= vYearNow-20;
			setStartThnLahir(vYearLahir); 
			return;
		}
		const vYearArr = [];
		for(let i=vYearNow;i>=vStartYear;i--) vYearArr.push(i);
		setThnLahirArr(vYearArr);
	},[uStartThnLahir]);
	React.useEffect(()=>{
		if(!uIsShowDlgform) { 
			vBtnSimpanmain && (vBtnSimpanmain.disabled = false);
			initDlgformreset(); return; 
		}
		vBtnSimpanmain.disabled = true;

		let vTimeout = setTimeout(()=>{
			const vElsFocus = document.getElementById("inpvaksintgl");
			vElsFocus && (vElsFocus.focus());
			clearTimeout(vTimeout);
		},350);
	},[uIsShowDlgform]);
	//---END EFFECT--/

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_cComponentName
	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid style={{height:64}} className="classheadermain">
			<CHeaderToggler onClick={() => {uNavigate(-1);}} 
				className="px-0 me-2">
			<CIcon icon="cilArrowCircleLeft" className="align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto text-normal classcursorpointer">
				<small><b>{(uBahasaObj.menus_pasien||"menus_pasien")}</b></small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">
			<CContainer xxl className="mb-3">
			{uHTMLError500 ? (
			<MyPage500 content={uHTMLError500} isWithrefresh={true}
				hdlReset={()=>hdlKlikReset()}
				{...props}/>
			) : (
			<CCard className="classcardbg">
				<CCardHeader className="d-flex justify-content-between">
				<div>
				{(uIDPasien <= 0) && (
					<>
					<CIcon className="classikon classikontambah me-1" height="30" />
					<b>
						{(uBahasaObj.caption_inputbaru||"caption_inputbaru")+" "+
						(uBahasaObj.menus_pasien||"menus_pasien").toUpperCase()}
					</b>
					</>
				)}
				{(uIDPasien > 0) && (
					<>
					<CIcon className="classikon classikonedit me-1" height="30" />
					<strong>
						{(uBahasaObj.caption_editdata||"caption_editdata")}
						{": "}
						<span className="text-primary">{(uInpPasienedit||"{UNDF}").toUpperCase()}</span>
					</strong>
					</>
				)}
				</div>

				<div>
					<CTooltip content={uBahasaObj.caption_resetform||"caption_resetform"}>
					<CLink className="classcursorpointer classikon classikonreset" onClick={()=>hdlKlikReset()} />
					</CTooltip>
				</div>
				</CCardHeader>

				<CCardBody>
				{contentMain()}
				</CCardBody>
			</CCard>
			)}
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={(uIsFormprocess || (uHTMLError500||"")!=="")}
				onClick={()=>hdlKlikSimpan()}
				id="btnSimpan">Simpan [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialogview
			options={{centered:true}}
			show={uIsShowDlgfoto} 
			toggle={hdlToggleDlgfoto}
			dialogHeader={uDialogHeader}
			renderContent={contentDlgfoto()}
			{...props}/>

		<MyDialoglookupapi
			show={uIsLookupShow}
			toggle={()=>hdlToggleFLookup()}
			dialogHeader={uDialogHeader||"dialogHeader"}
			inputValue={uInpLookupKwd}
			onKlikPilihItem={hdlKlikPilihLookup}
			targetApi={pjson.homepage+"api/api_customer/ld_lookup"} 
			kdownTrigger={uISLookuptrigger}
			id="iddialoglookupapi"
			{...props}/>

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgSimpan()}
			show={uIsShowDlgform} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}
			{...props}/>
		</>
	)//>
}
	
export default Pasieninput;
