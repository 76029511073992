import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {
	CForm,
	CInputGroup,
	CFormInput,
	CButton,
	CTooltip,
	CLink,
	CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';

const pjson     = require('../../../package.json');
const Headersubjasa = (props) => {
	const uDispatch			= useDispatch();

	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uEfekapp			= useSelector(state => state.effectApp);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uKeyword,setKeyword] = React.useState(uKeywordObj.kwd_jasa || "");

	const hdlKDownkeyword=(_EV)=>{
		if(_EV.which===13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari=()=>{
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_jasa = uKeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView 	= true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikTambah=()=>{
		uHeaderActionObj.isHeaderTambah= true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset=async()=>{
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_jasa = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/

			uHeaderActionObj.isHeaderView 	= true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//prosesReset(); return;
		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}
	const hdlKlikCetak=()=>{
		uHeaderActionObj.isHeaderCetak = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}

	//console.log("("+uComponentname+") Checked..");
	return(
		<>
		<div className="d-none d-lg-flex align-items-center">
			<CImage src={pjson.homepage+"api/images/menus_"+((uActiveroute||"").toLowerCase())+".png"}
				height={30} />
			<small className="ms-1 fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>

		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup 
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CFormInput size="sm" 
					value={uKeyword} 
					onChange={(e) => setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKDownkeyword(e)} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} 
					id="inpkeyword"/>
				<CButton size="sm" color="light" className="border" onClick={()=>hdlKlikCari()} id="btnCari">
				<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
			</CForm>

			<span className="d-none d-md-block ms-1">
			<span className="mx-1 d-none d-lg-inline">&middot;</span>

			<CTooltip content={uBahasaObj.caption_tambahdata || "Tambah Data Jasa"}>
				<CLink className="classikon classikontambah classcursorpointer" 
					onClick={()=>hdlKlikTambah()}/>
			</CTooltip>
		
			<span className="d-none d-lg-inline mx-1">&middot;</span>
			<CTooltip content={uBahasaObj.caption_cetakdata || "caption_cetakdata"}>
				<CLink className="classikon classikoncetak classcursorpointer" onClick={()=>hdlKlikCetak()}/>
			</CTooltip>
		
			<span className="d-none d-lg-inline mx-1">&middot;</span>
			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
			</CTooltip>

			<span className="d-none d-md-inline me-2">&middot;</span>
			<b id="idjmldata">0</b> {uBahasaObj.word_data||"word_data"}
			</span>

			<div className="d-md-none ms-1">
				<CLink className="classikon classikontambah" onClick={()=>hdlKlikTambah()}/>
			</div>
		</div>
		</>
	);
}

export default Headersubjasa;