import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CRow,
	CCol,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CListGroup,
	CListGroupItem,
	CSpinner,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
	MyDialogview,
	MyFormHapus,
	MyPagination,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson		= require('../../package.json');
const Belibarang= (props) => {
	const uComponentname 	= Belibarang.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uHTMLstyleTabel=useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp		= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 	= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted
	const [uFirstload,setFirstload]			= React.useState("YA");
	const uMaxData		= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [isShowForm,setShowForm]			= React.useState(false);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpKethapus,setInpKethapus]		= React.useState("");

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	const [uIsShowDlgview,setShowDlgview]	= React.useState(false);
	const [uHeaderDlgview,setHeaderDlgview]	= React.useState("");

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata	= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//---HANDLE--/
	const hdlToggleDlgview=()=>{ setShowDlgview(false); }
	const hdlToggleForm=()=>{ setShowForm(false); }
	const hdlKlikHapus=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);
		
		setDialogHeader((uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			(UFunc.TglAngka(uDatamainArr[vObjdx].tanggal)+" "+(uDatamainArr[vObjdx].supplier || "")+
				((uDatamainArr[vObjdx].no_nota||"")!==""?" ("+uDatamainArr[vObjdx].no_nota+")" : "")+
				"").toUpperCase());
		setShowForm(true);
	}
	const hdlKlikdetil = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL||0);
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vCaptionUpper	= (
			uDatamainArr[vObjdx].supplier_caption+
			((uDatamainArr[vObjdx].no_nota||"")!==""?" ("+uDatamainArr[vObjdx].no_nota+")":"")
		).toUpperCase();
		setHeaderDlgview(uBahasaObj.caption_lihatdetil+": "+vCaptionUpper);

		const vItemsArr	= uDatamainArr[vObjdx].items_arr || [];
		if(vItemsArr.length > 0) {
			setShowDlgview(true); return
		}

		apiLoaditem(_IDTABEL);
	}
	const hdlKlikSimpanHapus = () => {
		if((uInpKethapus||"").trim() === "") {
			document.getElementById("inpkethapus") &&
				document.getElementById("inpkethapus").focus();
			showToast((uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi")); 
			return;
		}

		apiProseshapus();
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initJmldata=()=>{
		const vKeyword = uKeywordObj.kwd_belibarang || "";
		if (vKeyword.length > 2) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFormAwal=()=>{
		setTabelIDPilih(0);

		setInpKethapus("");
	}

	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_belibarang || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=> 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width="5%" className="px-1">No</th>
		<th className="px-1">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.word_supplier||"word_supplier"}</CCol>
			<CCol md="2" className="text-start">{uBahasaObj.word_nonota||"No.Nota"}</CCol>
			<CCol md="2" className="text-start">{uBahasaObj.caption_akunreklas||"Akun Reklas"}</CCol>
			<CCol md="2" className="">{uBahasaObj.word_total||"word_total"} (Rp)</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
		</th>
		</tr>
		<tr className="d-md-none">
			<th width={30} className="p-0"/>
			<th className="p-0"/>
		</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tanggal,tgl_input,supplier_caption,userinput,terbayar,diskon,b_transport,
				items,no_nota,nilai,init_expired,init_loading,reklas_caption,status_jurnal,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (UFunc.HariAngka(tanggal)+" "+supplier_caption+
				((no_nota||"")!=""?" ("+no_nota+")":"")
				).toUpperCase();

			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tanggal) 
				: (UFunc.TglAngka(tanggal)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vSupplier= vKeyword==="" ? (supplier_caption||"") 
				: (supplier_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vReklascaption= vKeyword==="" ? (reklas_caption||"UNDF") 
				: (reklas_caption||"UNDF").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput= vKeyword==="" ? UFunc.Usercaptionfilter(userinput||"") 
				: UFunc.Usercaptionfilter(userinput||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNonota= vKeyword==="" ? (no_nota||"") 
				: (no_nota||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: (UFunc.WaktuAngka(tgl_input)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			const vColorstatustembak= (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>
				<td className="text-end px-1">	
				<CRow className="mx-0">
				<CCol xs="8" md="2" className="px-1 text-md-start d-none d-md-block">
					<div>
						<span className="">{UFunc.renderHTML(vTanggal)}</span>
						{(uTokenObj.userhak === "FREEROLE") && (
							<span className="classfontsmaller"><small> &middot; ({id})</small></span>
						)}
					</div>

					<div className="classfontsmaller text-danger">
					<small>[
						{((tgl_input||"")!=="") && (
						<>
							{UFunc.renderHTML(vTglInput)}
							<span className="mx-1">&middot;</span>
						</>
						)}	
						{UFunc.renderHTML(vUserinput)}
					]</small>
					</div>
				</CCol>

				<CCol md="3" className="px-1 text-start">
					<div className="d-flex justify-content-between align-items-start">
					<div className="text-info">{UFunc.renderHTML(vSupplier)}</div>

					{(init_loading||false)===true ? (
						<CSpinner color="dark" className="d-md-none" size="sm"/>
					) : (
					<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm" className="p-0"
							color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikdetil(id)}>
							<CIcon className="classikondetil me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_lihatdetil)}
						</CDropdownItem>
						
						{(uIsAllowedhapus && uIsEOMExecuted && (init_expired||"YA")==="TIDAK") && (
						<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_hapusdata)}
						</CDropdownItem>
						)}
						</CDropdownMenu>
					</CDropdown>
					)}
					</div>

					{((status_jurnal||"")!=="" && uTokenObj.userhak==="FREEROLE") && (
					<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
					</div>
					)}
				</CCol>

				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol xs="8" className="px-1 text-md-start d-md-none">
					<span className="">{UFunc.renderHTML(vTanggal)}</span>
					{(uTokenObj.userhak === "FREEROLE") && (
						<span className="classfontsmaller"><small> &middot; ({id})</small></span>
					)}
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal||"-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_nonota}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-start text-uppercase">
					<small>{UFunc.renderHTML(vNonota)}</small>
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_akunreklas||"Akun Reklas"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-start text-uppercase">
					<b>{UFunc.renderHTML(vReklascaption)}</b>
				</CCol>

				<CCol xs="3" className="px-1 text-start d-md-none">{uBahasaObj.caption_inputby||"User"}</CCol>
				<CCol xs="9" className="px-1 d-md-none text-danger">
					<small>
					[{(tgl_input||"")!=="" && (<>UFunc.renderHTML(vTglInput) &middot; </>)}{UFunc.renderHTML(vUserinput)}]
					</small>
				</CCol>
					
				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.word_total||"word_total"} (Rp)</CCol>
				<CCol xs="7" md="2" className="px-1 text-success">
					{UFunc.formatAngka(nilai)}
				</CCol>

				<CCol md="1" className="px-0 text-center d-none d-md-block">
				{(init_loading||false)===true ? (
					<CSpinner color="dark" size="sm"/>
				) : (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_lihatdetil||"caption_lihatdetil")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer classikontabel classikondetil"
						onClick={()=>hdlKlikdetil(id)} />
					</CTooltip>

					{(uIsAllowedhapus) && uIsEOMExecuted && ((init_expired||"YA")==="TIDAK")  && (
					<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer classikontabel classikonhapus"
						onClick={()=>hdlKlikHapus(id)} />
					</CTooltip>
					)}
					</>
				)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsShowDlgview) return (<></>)//>

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if(vObjdx < 0) return (<></>)

		const vDataitemsArr	= uDatamainArr[vObjdx].items_arr||[];
		if(vDataitemsArr.length <= 0) return (<></>)

		let vSubtotal = 0;
		return (
		<>
		<CRow className="mx-0 mx-auto">
		<CCol lg="6">
			<CCard className="classcardbg mb-3 classfontsmaller">
			<CCardHeader className="py-1"><small>Data Nota</small></CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
				<div className="px-1">Tgl Pembelian</div>
				<div className="px-1 text-end">{UFunc.HariAngka(uDatamainArr[vObjdx].tanggal)}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">Supplier</div>
				<div className="px-1 text-end text-info">{uDatamainArr[vObjdx].supplier_caption}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">No.Nota</div>
				<div className="px-1 text-end">{uDatamainArr[vObjdx].no_nota}</div>
				</div>

				<div>&nbsp;</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol lg="6">
			<CCard className="classcardbg mb-3 classfontsmaller">
			<CCardHeader className="py-1"><small>Pembayaran</small></CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
				<div className="px-1">Diskon (Rp)</div>
				<div className="px-1 text-end">{UFunc.formatAngka(uDatamainArr[vObjdx].diskon)}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">B.Transport (Rp)</div>
				<div className="px-1 text-end">{UFunc.formatAngka(uDatamainArr[vObjdx].b_transport)}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">DP &middot; Payment I (Rp)</div>
				<div className="px-1 text-end">{UFunc.formatAngka(uDatamainArr[vObjdx].terbayar)}</div>
				</div>

				<div className="d-flex justify-content-between">
				<div className="px-1">Total (Rp)</div>
				<div className="px-1 text-end text-success">{UFunc.formatAngka(uDatamainArr[vObjdx].nilai)}</div>
				</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CRow className="mx-0">
		<CCol>
			<CCard className="classcardbg classfontsmaller">
			<CCardHeader className="py-1"><small>Item Pembelian</small></CCardHeader>
			<CCardBody className="px-0">
			<table className="table table-border" id="idtabeldetil">
			<thead className="d-none">
			<tr>
				<th width="5%" className="px-1">No</th>
				<th className="px-1">&nbsp;</th>
			</tr>
			</thead>
			<tbody>
			{vDataitemsArr.map((vItems,vKeys)=>{
				const {
					id,barang_caption,no_batch,exp_date,h_satuan,qty,satuan
				} = vItems;

				vSubtotal	+= parseFloat(h_satuan||0) * parseFloat(qty||0);

				return (
				<tr className="align-top text-end" key={vKeys}>
					<td className="px-1 pe-0">{vKeys+1}.</td>
					<td className="px-1">
						<CRow className="mx-1">
						<CCol md="5" className="text-start px-1 text-capitalize">{barang_caption}</CCol>
						
						<CCol xs="12" className="px-1 classborderbottom d-md-none my-2"/>

						<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_batchno}</CCol>
						<CCol xs="8" md="2" className="text-md-center px-1 px-md-0">
							<small>
							{no_batch||""}
							{(exp_date||"")!==""?" ("+UFunc.TglAngka(exp_date)+")":"-"}

							{((no_batch||"")===""&&(exp_date||"")!=="") && (<>-</>)}
							</small>
						</CCol>

						<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_hsatuan}</CCol>
						<CCol xs="8" md="2" className="px-1">{UFunc.formatAngka(h_satuan)}</CCol>

						<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_qty}</CCol>
						<CCol xs="8" md="1" className="px-1 px-md-0 text-md-center">
							<b className="text-primary">{UFunc.formatAngka(qty)}</b>
							<small>{" "}{satuan||"UNIT"}</small>
						</CCol>

						<CCol xs="12" className="px-1 classborderbottom d-md-none my-2"/>
						<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_total}</CCol>
						<CCol xs="8" md="2" className="px-1">
							<span className="text-success">{UFunc.formatAngka(qty * h_satuan)}</span>
						</CCol>
						</CRow>
					</td>
				</tr>
				)
			})}
			</tbody>
			<tfoot>
			<tr className="text-end bg-light">
				<td className="px-1"/>
				<td className="px-1">
				<CRow className="mx-1">
					<CCol className="px-1">JUMLAH</CCol>
					<CCol className="px-1 fw-bolder text-success">
						{UFunc.formatAngka(vSubtotal)}
					</CCol>
				</CRow>
				</td>
			</tr>
			</tfoot>
			</table>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</>
		)//>
	}
	//---END Init_LOAD--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname = apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1;
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_belibarang || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_belibarang/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				setFirstload("TIDAK");
				//console.log("("+uComponentname+"-"+uFuncname+") dataobject : "+JSON.stringify(output_string.dataobject));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL||0);
		if(_IDTABEL <= 0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vObjdx].init_loading	= true;
		setEfekview(!uEfekview);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") _IDTABEL : "+_IDTABEL);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_belibarang/ld_item";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vObjdx].init_loading	= false;

			if(output_string.tampil) {
 				uDatamainArr[vObjdx].items_arr = JSON.parse(output_string.dataitems||"[]");
				setShowDlgview(true);
			} else {
				setEfekview(!uEfekview);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjdx].init_loading	= false;
			setEfekview(!uEfekview);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = () => {
		uFuncname 	= apiProseshapus.name;
		if(uTabelIDPilih <= 0) {showToast("[0] ERROR !"); return;}
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);

		const vElbtnsimpan	= document.getElementById("btnDialogSimpan");
		vElbtnsimpan && (vElbtnsimpan.disabled = true);
		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_kethapus : uInpKethapus,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_belibarang/pr_hapus";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElbtnsimpan && (vElbtnsimpan.disabled = false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				//setEfekview(!uEfekview);
				hdlToggleForm();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtnDlgsimpan.disabled = true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			vElbtnsimpan && (vElbtnsimpan.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();
		
		return()=>{ 
			uDispatch({type: "set", gInitHeaderAction: {}});
			hdlToggleForm(); hdlToggleDlgview();
			setDatamainArr([]); 
		}
	},[]);
	React.useEffect(()=>{
		if(!uElJmldata) return;
		initJmldata()
	},[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;

		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.uHandelTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uIsEOMExecuted) return;

		uNavigate("/subkeuangan/belibarang/formmain");
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!isShowForm) {
			initFormAwal();
			return;
		}

		let vTimeout = setTimeout(function(e){
			const vElFocus = document.getElementById("inpketerangan");
			if(!UFunc.isEmpty(vElFocus)) vElFocus.focus();
			clearTimeout(vTimeout);
		},200);
	},[isShowForm]);
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg mb-3">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_belibarang || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block">{uBahasaObj.caption_rekapinput||"Rekap Input"}</span>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}
				></MyPagination>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowDlgview} 
			dialogHeader={uHeaderDlgview}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>

		<MyFormHapus
			value={uInpKethapus}
			onCHvalue={(e)=>setInpKethapus(e.target.value)}
			onSimpan={()=>hdlKlikSimpanHapus()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}/>
		</>
	)//>
}
	
export default Belibarang;