import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CFooter,
	CRow,
	CCol,
	CForm,
	CFormInput,
	CFormSelect,
	CFormCheck,
	CInputGroup,
	CInputGroupText,
	CButton,
	CLink,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyDialoglookup,
	MyPage500,
	MyButton,
	MyProfileDetil,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     = require('../../../package.json');
const Biayaform = (props) => {
	const uComponentname	= Biayaform.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 
	let uFuncname;

	const uNavigate 		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted) || true;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uKasGLArrObj,setKasGLArrObj]		= React.useState(JSON.parse(localStorage.getItem("kasgllist")||"[]"));
	const [uDataGLArrObj,setDataGLArrObj]	= React.useState([]);
	const [uOperasionalGLArrObj,setOperasionalGLArrObj]= React.useState([]);
	const [uNonOperasionalGLArrObj,setNonOperasionalGLArrObj]= React.useState([]);

	const [uInpStatustembak,setInpStatustembak]= React.useState("REGULER");
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate());
	const [uInpJenisbiaya,setInpJenisbiaya]	= React.useState("OPERASIONAL");
	const [uInpGLID,setInpGLID]				= React.useState("0");
	const [uInpGLcaption,setInpGLcaption]	= React.useState("");
	const [uInpKasID,setInpKasID]			= React.useState("0");
	const [uInpKascaption,setInpKascaption]	= React.useState("");
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");
	const [uInpNoref,setInpNoref]			= React.useState("");
	const [uInpNilai,setInpNilai]			= React.useState(0);
	
	const [uISFormupdated,setFormupdated]	= React.useState(false);

	//---LOOKUP--/
	const [uIsLookupShow,setLookupShow]			= React.useState(false);
	const [uLookupheader,setLookupheader]		= React.useState("");
	const [uInpLookupKwd,setInpLookupKwd]		= React.useState("");
	const [uLookupDataArr,setLookupDataArr]		= React.useState([]);
	const [uLookupjenis,setLookupjenis]			= React.useState("");
	//---END LOOKUP--/

	//---HANDEL--/
	const hdlKlikBack = async() => {
		if(!uISFormupdated) 
			{ uNavigate(-1); return }

		if(await Konfirm("<b>Form Biaya Telah Mengalami Perubahan</b>.."+
			"<p>Data Ada Yang Telah Terisi dan Mengalami Perubahan dari Awalnya..</p>"+
			"<p>Apakah Anda Tetap Akan KEMBALI ?</p>"+
			"")) uNavigate(-1);
	}
	const hdlKlikReset = async() => {
		if(uHTMLError500) {
			apiLoadGL(); return;
		}

		if(!uISFormupdated)
			{ apiLoadGL(); return; }

		if(await Konfirm("<b>Form Biaya Telah Mengalami Perubahan</b>.."+
			"<p>Data Ada Yang Telah Terisi dan Mengalami Perubahan dari Awalnya..</p>"+
			"<p>Apakah Anda Tetap Akan Mereset ?</p>"+
			"")) initFormAwal();
	}
	const hdlToggleLookup=()=>{ setLookupShow(false) }
	const hdlKlikPilihLookup = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM||0);
		if(_IDITEM <= 0) return;

		const vObjidx 	= UFunc.getObjectIndeks(uLookupDataArr,_IDITEM);
		const vValue	= uLookupDataArr[vObjidx].id||"0";
		const vCaption	= uLookupDataArr[vObjidx].caption||"";
		let vElstmp;

		if(uLookupjenis==="BIAYA") {
			vElstmp = document.getElementById("inpkascaption")
			setInpGLID(vValue);
			setInpGLcaption(vCaption);
		} else if(uLookupjenis==="KAS") {
			vElstmp = document.getElementById("inpket")
			setInpKasID(vValue);
			setInpKascaption(vCaption);
		}
		hdlToggleLookup();

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElnextfocus = vElstmp;
			vElnextfocus && vElnextfocus.focus();
		},350)
	}
	const hdlKlikGLlookup = () => {
		//showToast(JSON.stringify(uDataGLArrObj))
		const vTmpArr	= uDataGLArrObj.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setLookupDataArr(vTmpArr);
		setLookupjenis("BIAYA");
		setLookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_akunbiaya||"").toUpperCase());
		setLookupShow(true)
	}
	const hdlKlikKaslookup = () => {
		setLookupjenis("KAS");
		const vTmpArr	= uKasGLArrObj.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setLookupDataArr(vTmpArr);
		setLookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_akunkas||"").toUpperCase());
		setLookupShow(true)
	}
	const hdlKlikSimpan = async() => {

		//---VALIDASI--/
		let vElfocus 	= document.getElementById("inpstatustembak");
		if(uInpStatustembak!=="TEMBAK" && uInpStatustembak!=="REGULER") {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_statustembakjurnal||"Status Jurnal")+" "+uBahasaObj.caption_invalid+"..");
			return;	
		}

		vElfocus 	= document.getElementById("inptanggal")
		if(UFunc.isEmpty(uInpTanggal)) {
			vElfocus && vElfocus.focus();
			showToast(uBahasaObj.caption_tanggal+" "+uBahasaObj.caption_invalid+"..");
			return;
		} else {
			const vTanggalDt 	= new Date(uInpTanggal);

			const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
			if(vDateBeyond) {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
				return;
			}
			const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
			if(vDateExceededBl) {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
				return;
			}
			//alert("("+uComponentname+") "+cFuncname+" vDateExceededBl = "+vDateExceededBl); return;
		}

		//alert("("+uComponentname+" - hdlKlikSimpan)  uInpJenisbiaya = "+uInpJenisbiaya); return;
		vElfocus 	= document.getElementById("inpjenisops")
		if(uInpJenisbiaya!=="OPERASIONAL" && uInpJenisbiaya!=="NONOPERASIONAL") {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_jenispembayaran||"caption_jenispembayaran")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		vElfocus 	= document.getElementById("inpnilai")
		if((parseInt(uInpNilai)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.word_nilai||"word_nilai")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}

		vElfocus 	= document.getElementById("inpglcaption")
		if((parseInt(uInpGLID)||0) === 0) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_akunbiaya||"caption_akunbiaya")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		vElfocus 	= document.getElementById("inpkascaption")
		if((parseInt(uInpKasID)||0) === 0) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		vElfocus 	= document.getElementById("inpket")
		if((uInpKeterangan||"") === "") {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.word_keterangan||"word_keterangan")+ 
				" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+
				"..");
			return;
		}
		//---END VALIDASI--/

		const vEltombol	= document.getElementById("btnSimpan");
		vEltombol && (vEltombol.disabled = true);
		if(await Konfirm("Data Akan Disimpan.."+
			"<br/><b>Apakah Anda Yakin ?</b>"+
			"")) {
			apiProsessimpan();
			return;
		}
		
		vEltombol && (vEltombol.disabled = false);
	}
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			_EV.preventDefault();
			_EV.stopPropagation();

			const vEltombol	= document.getElementById("btnSimpan");
			vEltombol && (vEltombol.click());

			return;
		}
	}
	//---END HANDEL--/

	//--INIT--/
	const initFormAwal=()=>{
		setInpStatustembak("REGULER");
		setInpTanggal(UFunc.DbDate());
		setInpKasID(0);
		setInpKascaption("");
		setInpGLID(0);
		setInpGLcaption("");
		setInpJenisbiaya("OPERASIONAL");
		setInpKeterangan("");
		setInpNoref("");
		setInpNilai(0);
	}

	const contentMainform=()=>{
		if(uHTMLError500) return ""

		return (
			<CForm className="mx-lg-5">
			{uTokenObj.userhak === "FREEROLE" && (
			<>
			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
				</CCol>
				<CCol xs="8" md="4" className="text-end">
					<CFormSelect size="sm"
						onChange={(e)=>setInpStatustembak(e.target.value)}
						value={uInpStatustembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
					</CFormSelect>
				</CCol>
				<CCol md="4" className="d-none d-md-block"/>
				<CCol xs="4" className=""/>
				<CCol className="classpetunjuk">
					*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL
				</CCol>
			</CRow>
			
			<div className="my-3 classborderbottom"/>
			</>
			)}

			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_tanggal||"word_tanggal"}
				</CCol>
				<CCol xs="8" md="5" lg="4" className="text-end">
					<CFormInput size="sm"
						type="date"
						onChange={(e)=>setInpTanggal(e.target.value)}
						value={uInpTanggal}
						name="inptanggal"
						id="inptanggal"/>
				</CCol>
			</CRow>

			<CRow className="m-0 my-2">
				<CCol xs="3" md="4" className="text-start text-md-end text-info">
				<span className="d-none d-md-block">{uBahasaObj.caption_jenispembayaran||"caption_jenispembayaran"}</span>
				<span className="d-md-none">{uBahasaObj.word_jenis||"Jenis"}</span>
				</CCol>

				<CCol md="8" className="d-none d-md-block">
				<CInputGroup size="sm">
				<CInputGroupText className="px-3 py-1 bg-light">
				<CFormCheck type="radio" size="sm"
					className="m-0"
					value="OPERASIONAL"
					onChange={(e)=>setInpJenisbiaya(e.target.value)}
					checked={uInpJenisbiaya==="OPERASIONAL"}
					label={(uBahasaObj.caption_biayaoperasional||"caption_biayaoperasional").toUpperCase()}
					name="inpjenis"
					id="inpjenisops"/>
				</CInputGroupText>
				<CInputGroupText className="px-3 py-1 bg-light">
				<CFormCheck type="radio" size="sm"
					className="m-0"
					value="NONOPERASIONAL"
					onChange={(e)=>setInpJenisbiaya(e.target.value)}
					checked={uInpJenisbiaya!=="OPERASIONAL"}
					label={(uBahasaObj.caption_biayanonoperasional||"caption_biayanonoperasional").toUpperCase()}
					name="inpjenis"
					id="inpjenisnonops"/>
				</CInputGroupText>
				</CInputGroup>
				</CCol>

				<CCol xs="9" className="d-md-none ps-0">
				<CInputGroup size="sm" className="justify-content-end">
				<CInputGroupText className="bg-light px-1">
				<CFormCheck type="radio" size="sm"
					className="m-0 classfontsmaller"
					value="OPERASIONAL"
					onChange={(e)=>setInpJenisbiaya(e.target.value)}
					checked={uInpJenisbiaya==="OPERASIONAL"}
					label={("OPERASIONAL").toUpperCase()}
					name="inpjenismobile"
					id="inpjenisopsmobile"/>
				</CInputGroupText>
				<CInputGroupText className="bg-light px-1">
				<CFormCheck type="radio" size="sm"
					className="m-0 classfontsmaller"
					value="NONOPERASIONAL"
					onChange={(e)=>setInpJenisbiaya(e.target.value)}
					checked={uInpJenisbiaya!=="OPERASIONAL"}
					label={("NON OPERASIONAL").toUpperCase()}
					name="inpjenismobile"
					id="inpjenisnonopsmobile"/>
				</CInputGroupText>
				</CInputGroup>
				</CCol>
			</CRow>

			<CRow className="m-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_nilai||"word_nilai"}
				</CCol>
				<CCol xs="8" md="5" lg="4" className="text-end">
					<NumberFormat 
						value={uInpNilai}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpNilai(values.value)}
						renderText={values => (
							<CFormInput 
								value={values}
								size="sm"
								className="text-end"
								onChange={(e) => setInpNilai(e.target.value)}
								onFocus={(e)=>e.target.select()}
							id="inpnilai"/>
						)} 
						prefix={isMobile?"":"Rp"}/>
				</CCol>
			</CRow>

			<CRow className="m-0 my-2">
				<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.caption_nobukti||"caption_nobukti"}
				<span className="d-none d-md-inline">/{uBahasaObj.word_noref||"word_noref"}</span>
				</CCol>
				<CCol xs="8" md="5" lg="4" className="">
					<CFormInput maxLength="50" 
						size="sm"
						className="text-uppercase"
						type="text"
						onChange={(e)=>setInpNoref(e.target.value)}
						value={uInpNoref}
						name="inpnoref"
						id="inpnoref"/>
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="m-0 my-2">
				<CCol md="4" className="px-2 text-start text-md-end text-info">
				{uBahasaObj.caption_akunbiaya||"caption_akunbiaya"}
				</CCol>
				<CCol md="5" lg="4" className="px-2">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						readOnly={true}
						value={uInpGLcaption}
						onClick={()=>hdlKlikGLlookup()}
						placeholder={"--"+((uBahasaObj.word_pilih)+" "+(uBahasaObj.caption_akunbiaya)).toUpperCase()+".."}
						id="inpglcaption"/>
					<CInputGroupText className="p-0">
					<CButton size="sm"
						color="dark"
						onClick={()=>hdlKlikGLlookup()}
						id="btngllookup">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
					</CInputGroupText>
					</CInputGroup>
				</CCol>
			</CRow>
			
			<CRow className="m-0 my-2">
				<CCol md="4" className="px-2 text-start text-md-end text-info">
				{uBahasaObj.caption_asalkas||"caption_asalkas"}
				</CCol>
				<CCol md="5" lg="4" className="px-2">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						readOnly={true}
						value={uInpKascaption}
						onClick={()=>hdlKlikKaslookup()}
						placeholder={"--"+((uBahasaObj.word_pilih)+" "+(uBahasaObj.caption_asalkas)).toUpperCase()+".."}
						id="inpkascaption"/>
					<CInputGroupText className="p-0">
					<CButton size="sm"
						color="dark"
						onClick={()=>hdlKlikKaslookup()}
						id="btnkaslookup">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
					</CInputGroupText>
					</CInputGroup>
				</CCol>
			</CRow>
			
			<CRow className="m-0 my-3 classborderbottom"/>
			
			<CRow className="m-0 my-2">
				<CCol md="4" className="px-2 text-start text-md-end text-info">
				{uBahasaObj.word_keterangan||"word_keterangan"}
				</CCol>
				<CCol className="px-2">
					<CFormInput maxLength={255} 
						className="text-capitalize"
						size="sm"
						type="text"
						onChange={(e)=>setInpKeterangan(e.target.value)}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
						value={uInpKeterangan}
						name="inpket"
						id="inpket"/>
				</CCol>
			</CRow>
			</CForm>
		)//>
	}
	//--END INIT--/

	//--API--/
	const apiLoadGL = () => {
		uFuncname = apiLoadGL.name;
		setHTMLError500();

		const vEltombol	= document.getElementById("btnSimpan");
		vEltombol && (vEltombol.disabled = true);
		setLoading(true);
		setInpJenisbiaya("");

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_biaya/ld_initgl";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vEltombol && (vEltombol.disabled = false);
			if(output_string.tampil) {
				setOperasionalGLArrObj(JSON.parse(output_string.opsobject || "[]"));
				setNonOperasionalGLArrObj(JSON.parse(output_string.nonopsobject || "[]"));
				setInpJenisbiaya("OPERASIONAL");
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEltombol && (vEltombol.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		const vEltombol	= document.getElementById("btnSimpan");
		vEltombol && (vEltombol.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--//
		initFormAwal();
		showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
		if(!isMobile) document.getElementById("inptanggal").focus();
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_statustembak : uInpStatustembak,
			send_tanggal : uInpTanggal,
			send_glid : uInpGLID,
			send_kasid : uInpKasID,
			send_jenis : uInpJenisbiaya,
			send_keterangan : uInpKeterangan,
			send_noref : uInpNoref,
			send_nilai: uInpNilai,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_biaya/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vEltombol && (vEltombol.disabled = false);

			if(output_string.sukses) {
				let vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				if(output_string.pesankhusus) vPesan = output_string.pesankhusus;
				showToast(vPesan,"SUKSES");
				initFormAwal();

				if(!isMobile) document.getElementById("inptanggal").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			vEltombol && (vEltombol.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		if(!uIsEOMExecuted) uNavigate(-1);

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		
		apiLoadGL();

		if(!isMobile) {
			const vElsTanggal = document.getElementById("inptanggal");
			vElsTanggal && vElsTanggal.focus();
		}

		return ()=>{
			initFormAwal();
			setDataGLArrObj([]);
			setOperasionalGLArrObj([]);
			setLookupDataArr([]);
		}
	},[]);
	React.useEffect(()=>{
		setInpGLID(0);
		setInpGLcaption("");
		if(uInpJenisbiaya === "") return;

		if(uInpJenisbiaya==="OPERASIONAL")
			setDataGLArrObj(uOperasionalGLArrObj);
		else 
			setDataGLArrObj(uNonOperasionalGLArrObj);
	},[uInpJenisbiaya]);
	React.useEffect(()=>{
		if(uInpStatustembak !== "REGULER"
			|| uInpTanggal !== UFunc.DbDate()
			|| uInpKascaption.trim() !== ""
			|| uInpJenisbiaya !== "OPERASIONAL"
			|| uInpGLcaption.trim() !== ""
			|| uInpKeterangan.trim() !== ""
			|| uInpNoref.trim() !== ""
			|| parseInt(uInpKasID) > 0
			|| parseInt(uInpGLID) > 0
			|| parseFloat(uInpNilai) > 0
		) setFormupdated(true);
		else setFormupdated(false);
	},[uInpStatustembak,
	uInpTanggal,
	uInpKasID,
	uInpKascaption,
	uInpGLID,
	uInpJenisbiaya,
	uInpNoref,
	uInpKeterangan,
	uInpNilai,
	]);
	//---END EFFECT---/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="px-3 classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="me-2 align-middle" height={25}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_biaya||"menus_biaya").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-2">
			<CContainer xxl className="px-lg-5">
			<CCard className="mx-lg-5 classcardbg">
			<CCardHeader className="d-flex justify-content-between">
			<b>{uBahasaObj.caption_forminput||"caption_forminput"}</b>
			<CTooltip content={uBahasaObj.resetform||"Reset Form"}>
				<CLink 
					onClick={()=>hdlKlikReset()}
					className="classcursorpointer classikon classikonreset"/>
			</CTooltip>
			</CCardHeader>
			<CCardBody>{contentMainform()}</CCardBody>
			</CCard>
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				id="btnSimpan"
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				onClick={()=>hdlKlikSimpan()}>
			Simpan [F9]
			</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsLookupShow}
			toggle={()=>hdlToggleLookup()}
			dialogHeader={uLookupheader||"dialogHeader"}
			inputValue={uInpLookupKwd}
			dataItemArr={uLookupDataArr} 
			onKlikPilihItem={hdlKlikPilihLookup}
			id="iddialoglookup"/>
		</>
	)//>
}

export default Biayaform;
