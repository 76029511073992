import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {
	CForm,//CFormInput,
	CTooltip,
	CLink,
	CImage,
} from "@coreui/react";
import { UFunc } from "../../helpers/functions";
import { Konfirm } from "../../helpers/onConfirm";
import { 
	MyDatepicker,
} from '../../components/index';
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

let pjson     			= require("../../../package.json");
const Headersubjadwal 	= (props) => {
	const { showToast  } = props; 

	const uActiveroute 		= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uDispatch			= useDispatch();
	//const uNavigate  		= useNavigate();

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uEfekapp			= useSelector(state => state.effectApp);

	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.toDatetime((uKeywordObj.kwd_jadwal||UFunc.DbDate())+" 00:00:00"));
	//const [uChangeTanggal,setChangeTanggal]	= React.useState(false);

	const hdlChangetanggal=(_VALUE)=>{
		//---CEK_TANGGAL--/
		setInpTanggal(_VALUE);
		const vDateNow	= UFunc.toDate(UFunc.DbDate());
		const vDatechk	= _VALUE;

		if(vDatechk < vDateNow) {
			showToast((uBahasaObj.pesan_tglisexpired||"Tanggal Sudah Expired..")+
				//"<br/>"+vDateNow+"<br/>"+vDatechk+
				"<br/>"+(uBahasaObj.caption_mohondiperbaiki||"Mohon Diperbaiki")+" !"+
				"");
			return;
		}
		//---END CEK_TANGGAL--/

		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_jadwal = UFunc.DbDate(_VALUE); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset=async()=>{
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_jadwal = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setInpTanggal(new Date());
			//---END CLEAR_KEYWORD--/
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//prosesReset(); return;
		if(UFunc.DbDate(uInpTanggal) !== UFunc.DbDate()) {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}
	const hdlKlikTambah=()=>{
		uHeaderActionObj.isHeaderTambah = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}

	/*React.useEffect(()=>{
		if(!uChangeTanggal) return
		setChangeTanggal(false);

		hdlKlikCari();
	},[uChangeTanggal]);//-*/

	//console.log("("+uComponentname+") Checked..");
	return(
		<>
		<div className="d-none d-lg-flex align-items-center">
			<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
				className="me-2"
				height={30}/>
			<small><i>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||"List Data"}</i></small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
			<MyDatepicker
				tglValue={uInpTanggal}
				onChangevalue={(value)=>{hdlChangetanggal(value)}}
				id="inptanggal"/>
			</CForm>

			<span className="ms-1">
				<span className="mx-1 d-none d-lg-inline">&middot;</span>
			
				<CTooltip content={uBahasaObj.caption_reservasijadwal || "Reservasi Jadwal Booking"}>
					<CLink className="classikon classikontambah classcursorpointer" onClick={()=>hdlKlikTambah()}/>
				</CTooltip>

				<span className="d-none d-lg-inline me-1">&middot;</span>

				<CTooltip content={uBahasaObj.caption_refreshview || "caption_refreshview"}>
					<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
				</CTooltip>

				<span className="d-none d-md-inline">
					<span className="d-none d-md-inline me-2">&middot;</span>
					<b id="idjmldata">0</b> {uBahasaObj.word_data||"word_data"}
				</span>
			</span>
		</div>
		</>
	);
}

export default Headersubjadwal;