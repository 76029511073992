import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CInputGroup,CInputGroupText,CFormTextarea,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,
	CDropdown,CDropdownToggle,CDropdownHeader,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CTooltip,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyPagination,
	MyPage500,
	MyDatepicker,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 		= require('../../package.json')
const Notelayanan 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uUserSettingObj		= useSelector(state => state.gListUserSetting);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 		= uHeaderActionObj.isHeaderTambah || false;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);

	//--DLG_FORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//--END DLG_FORM--/

	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL<=0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		const vCaptionUpper = UFunc.HariAngka(uDatamainArr[vArridx].tgl_note).toUpperCase();
		setTabelIDPilih(_IDTABEL);
		setTabelRowSelect(vRowidx);

		uDlgformObj.headers 	= "Edit Layanan: "+vCaptionUpper;
		uDlgformObj.tgl_note_dt = UFunc.toDatetime((uDatamainArr[vArridx].tgl_note||UFunc.DbDate())+" 00:00:00");
		uDlgformObj.isi_note	= (uDatamainArr[vArridx].isi_note);
		uDlgformObj.tgl_input	= uDatamainArr[vArridx].tgl_input;
		uDlgformObj.userinput	= uDatamainArr[vArridx].userinput;

		setDlgformshow(true);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL<=0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		const vCaptionUpper = UFunc.HariAngka(uDatamainArr[vArridx].tgl_note).toUpperCase();
		setTabelIDPilih(_IDTABEL);
		setTabelRowSelect(vRowidx);

		if(await Konfirm("Hapus Data: <b>"+vCaptionUpper+"</b>"+
			"<br/>Apakah Anda Yakin ?"+
			"")) apiProseshapus(_IDTABEL);
	}
	const hdlKlikDlgformsimpan = () => {
		let vElfocus = document.getElementById("inptglnote");
		if((uDlgformObj.tgl_note||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Note Tidak Valid !"); return
		}
		if(UFunc.DateIsMundur(uDlgformObj.tgl_note_dt)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Note Sudah Kadaluwarsa !"); return
		}
		const vFilterArr = uDatamainArr.filter(vItems => {
			/*console.log("");
			console.log("(Notelayanan - hdlKlikDlgformsimpan)  vItems.tgl_note => "+(vItems.tgl_note));
			console.log("(Notelayanan - hdlKlikDlgformsimpan)  uDlgformObj.tgl_note => "+(uDlgformObj.tgl_note));
			console.log("(Notelayanan - hdlKlikDlgformsimpan)  vItems.id => "+(vItems.id));
			console.log("(Notelayanan - hdlKlikDlgformsimpan)  uTabelIDPilih => "+(uTabelIDPilih));
			console.log("(Notelayanan - hdlKlikDlgformsimpan) status => "+(
				vItems.tgl_note === uDlgformObj.tgl_note && 
					(vItems.id||0).toString === (uTabelIDPilih||0).toString())
			);//-*/

			if((vItems.id||0).toString() !== (uTabelIDPilih||0).toString())
				return (vItems.tgl_note === uDlgformObj.tgl_note);
		});

		//console.log("(Notelayanan - hdlKlikDlgformsimpan)  vFilterArr.length => "+(vFilterArr.length));
		if(vFilterArr.length > 0) {
			vElfocus && vElfocus.focus();
			showToast("<b>Tanggal Tersebut Sudah Ada</b>.."+vFilterArr.length+
				"<br/>Silahkan Ganti Tanggal Note Anda Atau Update Data yang Sudah Ada !"+
				""); return
		}

		vElfocus = document.getElementById("inpisinote");
		if((uDlgformObj.isi_note||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("Isi Note Tidak Valid !"); return
		}

		apiProsessimpan(uTabelIDPilih);
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_notelayanan || "";
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} 

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_notelayanan || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr		= uDatamainArr.slice(vMin,(vMax));

		//console.log("(Jadwal-contentMain) uDatamainArr => "+JSON.stringify(uDatamainArr));
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>

			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="3" className="text-start">{uBahasaObj.caption_tanggalnote||"Tanggal Note"}</CCol>
			<CCol md="8" className="text-start">{uBahasaObj.caption_isinote||"Isi Pesan"}</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tgl_note,isi_note,tgl_input,userinput
			} = vItems;

			//if(vKeys > 1) return;
			vNumber++;
			const vCaptionUpper	= UFunc.HariAngka(tgl_note||"").toUpperCase();

			const vTglNote	= vKeyword==="" ? UFunc.TglAngka(tgl_note||"") 
				: UFunc.TglAngka(tgl_note||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vIsinote	= vKeyword==="" ? (isi_note||"-") 
				: (isi_note||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter(userinput||"") 
				: UFunc.Usercaptionfilter(userinput||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+((uTabelRowSelect===vKeys)?"classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>
				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="3" className="px-1 text-start">
					<div className="d-flex justify-content-between align-items-start">
					<div>{UFunc.renderHTML(vTglNote)}</div>

					<div className="d-md-none">
					<CDropdown autoclose={true}>
					<CDropdownToggle color="transparent" className="p-0 m-0" 
						caret={false}>
						<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem 
							onClick={()=>hdlKlikEdit(id)}>
							<CIcon className="classikontabel classikonedit me-2" height={25}/>
							<span>{uBahasaObj.caption_editdata}</span>
						</CDropdownItem>
						<CDropdownItem 
							onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikontabel classikonhapus me-2" height={25}/>
							<span>{uBahasaObj.caption_hapusdata}</span>
						</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
					</div>
					</div>

					<div className="classfontsmaller text-danger d-none d-md-block">
					<small>
						[{UFunc.WaktuAngka(tgl_input)}
						<span className="mx-1">&middot;</span>
						{UFunc.renderHTML(vUserinput)}]
					</small>
					</div>
				</CCol>
				
				<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>
				
				<CCol xs="12" md="8" className="px-1 text-start text-primary">
					<i>{UFunc.renderHTML(vIsinote)}</i>
				</CCol>

				<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

				<CCol xs="4" className="px-1 text-start d-md-none">Ordered by</CCol>
				<CCol xs="8" className="px-1 d-md-none text-danger fst-italic">
					<small>[
						{UFunc.WaktuAngka(tgl_input)}
						<span className="mx-1">&middot;</span>
						{UFunc.renderHTML(vUserinput)}
					]</small>
				</CCol>

				<CCol md="1" className="px-0 text-md-center d-none d-md-block">
					<CTooltip content={"--Edit Data: "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="classcursorpointer classikontabel classikonedit"/>
					</CTooltip>

					<CTooltip content={"--Hapus Data: "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikHapus(id)} className="classcursorpointer classikontabel classikonhapus"/>
					</CTooltip>
				</CCol>
				</CRow>
				</td>
			</tr>
			);
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)

		return (
		<CForm style={{minHeight: isMobile ? "auto" : window.innerHeight - 230}}>
		<CRow className="mx-0 my-2">
			<CCol xs="5">{uBahasaObj.caption_tanggalnote || "Tanggal Note"}</CCol>
			<CCol xs="7" className="bg-light py-1">
				<MyDatepicker
					tglValue={uDlgformObj.tgl_note_dt}
					onChangevalue={(date)=>{uDlgformObj.tgl_note_dt = date; setEfekview(!uEfekview)}}
					id="inptglnote"/>
			</CCol>
		</CRow>

		<CRow className="mx-0 my-2">
			<CCol md="12">{uBahasaObj.caption_isinote||"Isi Note"}</CCol>
			<CCol className="text-start">
				<CFormInput size="sm"
					maxLength={255}
					value={(uDlgformObj.isi_note||"")}
					onChange={(e)=>{uDlgformObj.isi_note= (e.target.value);setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpisinote"/>
				<div className="classpetunjuk d-none">*) {uBahasaObj.petunjuk_textarea}</div>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="mx-0 my-3">
			<CCol xs="4">{uBahasaObj.caption_orderedby || "Ordered By"}</CCol>
			<CCol xs="8" className="text-end fst-italic">
			<small>
				{UFunc.WaktuAngka(uDlgformObj.tgl_input)}
				<span className="mx-1">&middot;</span>
				<span className="text-danger">[{UFunc.Usercaptionfilter(uDlgformObj.userinput)}]</span>
			</small>
			</CCol>
		</CRow>
		</CForm>
		)
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = () => {
		setHTMLError500();
		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		const vKeyword = uKeywordObj.kwd_notelayanan || "";

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_notelayanan/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Notelayanan-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vDatamainArr = JSON.parse(output_string.dataobject || "[]");
				setDatamainArr(vDatamainArr);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Notelayanan-apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Notelayanan-apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; 
		const vElbutton	= document.getElementById("btnDialogSimpan");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid: _IDTABEL,
			send_datajson : JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_notelayanan/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			vElbutton && (vElbutton.disabled = false);
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Notelayanan-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				if((parseInt(_IDTABEL)) <= 0) {
					const vTmpObj	= {
						id:output_string.tabelid||0,
						tgl_note : uDlgformObj.tgl_note,
						isi_note : UFunc.nl2br(uDlgformObj.isi_note),
						tgl_input : uDlgformObj.tgl_input,
						userinput : uDlgformObj.userinput,
					}
					setDatamainArr([vTmpObj,...uDatamainArr]);
					showToast((uBahasaObj.pesan_sukses||"Berhasil !"),"SUKSES");
				} else {
					const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
					uDatamainArr[vArridx].tgl_note = uDlgformObj.tgl_note;
					uDatamainArr[vArridx].isi_note = UFunc.nl2br(uDlgformObj.isi_note);
				}
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Notelayanan - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Notelayanan - apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid: _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_notelayanan/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Notelayanan-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
				uDatamainArr.splice(vArridx,1);
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Notelayanan - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Notelayanan - apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoaddata();
		return () => {
			setDlgformshow(false);
			setDatamainArr([]);
			uDispatch({type: "set", gInitHeaderAction: {}});
		}
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		setEfekview(!uEfekview);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		setEfekview(!uEfekview);

		if(uHTMLError500) return;

		setTabelIDPilih(0);
		uDlgformObj.headers = "Tambah Data Note Layanan";
		setDlgformshow(true);
	},[uHandelTambah]);
	React.useEffect(()=>{ setJmlData(uDatamainArr.length); },[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!uISDlgformshow) { setDlgformObj({}); return }

		if(uDlgformObj.tgl_note_dt === undefined)
			uDlgformObj.tgl_note_dt = UFunc.toDatetime(UFunc.DbDate()+" 00:00:00");

		if(uDlgformObj.userinput === undefined)
			uDlgformObj.userinput = (uTokenObj.usercaption);
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(uDlgformObj.tgl_note_dt === undefined) return;

		uDlgformObj.tgl_note = UFunc.DbDate(uDlgformObj.tgl_note_dt);
	},[uDlgformObj,uDlgformObj.tgl_note_dt]);
	
	//console.log("Notelayanan - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_notelayanan || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			<div className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.headers}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
		</>
	)
}	

export default Notelayanan