import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CForm,
	CFormInput,CInputGroup,CInputGroupText,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPagination,
	MyPage500,
	MyDialogform,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     = require('../../package.json');
const Asetjenis = (props) => {
	const uComponentname 	= Asetjenis.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uISallowedhapus	= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN")
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uEfekview2,setEfekview2] = React.useState(false);

	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uDataArr,setDataArr]				= React.useState([]);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);

	//---FORMDIALOG--/
	const [uIsShowForm,setShowForm]			= React.useState(false);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uInpJmlsusut,setInpJmlsusut]		= React.useState(0);
	const [uInpNamajenis,setInpNamajenis] 	= React.useState("");
	//---END FORMDIALOG--/

	const [uElBtnsimpan,setElBtnsimpan]		= React.useState(document.getElementById("btnDialogSimpan"));
	const vElJmlData	= document.getElementById("idjmldata");
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleForm=()=>{setShowForm(false)}
	const hdlKlikFormSimpan=()=>{
		const vNamaaset	= (uInpNamajenis||"").trim();
		if(vNamaaset === "") {
			document.getElementById("inpnamajenis").focus();
			showToast((uBahasaObj.caption_jenisaset||"caption_jenisaset")+" "+
				(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		const vPenyusutan	= parseInt(uInpJmlsusut) || 0;
		if(vPenyusutan <= 0) {
			document.getElementById("inppenyusutan").focus();
			showToast((uBahasaObj.word_penyusutan||"word_penyusutan")+" "+
				(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}

		apiProsessimpan();
	}

	const hdlKlikEdit=async(_IDTABEL)=>{
		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		const vObjidx	= UFunc.getObjectIndeks(uDataArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setInpNamajenis(uDataArr[vObjidx].nama_jenis_aset);
		setInpJmlsusut(uDataArr[vObjidx].penyusutan);
		setDialogHeader((uBahasaObj.caption_editdata||"caption_editdata")+": "+
			(uDataArr[vObjidx].nama_jenis_aset || "").toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}
	const hdlKlikHapus=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		const vObjidx	= UFunc.getObjectIndeks(uDataArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>"+
			uDataArr[vObjidx].nama_jenis_aset.toUpperCase()+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiProseshapus(_IDTABEL);
	}
	const hdlKlikUrutan=(_IDTABEL,_ACTIONS)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		_ACTIONS = (_ACTIONS||"").toUpperCase();

		if(_IDTABEL <= 0) return;
		if(_ACTIONS !== "UP" && _ACTIONS !== "DOWN") return;

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		apiProsesurutan(_IDTABEL,_ACTIONS);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initFormAwal=()=>{
		setInpNamajenis("");
		setInpJmlsusut(0);
		setTabelIDPilih(0);
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ( UFunc.renderHTML(uHTMLError500) );

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_asetjenis || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataViewObj	= uDataArr.slice(vMin,(vMax+1));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="7" className="px-1 text-start">{uBahasaObj.caption_jenisaset||"caption_jenisaset"}</CCol>
				<CCol md="2" className="px-1">{uBahasaObj.word_penyusutan||"word_penyusutan"}</CCol>
				<CCol md="3" className="px-1">&nbsp;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none classfontsmaller">
			<th width={27} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataViewObj.map((vItems,vKeys)=>{
			const {
				id,nama_jenis_aset,penyusutan,urutan,isloading
			} = vItems;

			vNumber++;
			const vCaptionUpper	= ((nama_jenis_aset||"")).toUpperCase();

			const vNamaaset  = vKeyword==="" ? (nama_jenis_aset||"") 
				: (nama_jenis_aset||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUrutan 	= parseInt(urutan) || 1;

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td align="right" className="px-1 pe-0">{vNumber}.</td>
				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="7" className="px-1 text-start d-flex justify-content-between">
					<div className="text-info">{UFunc.renderHTML(vNamaaset)}</div>

					<div className="d-md-none">
					{uISallowedhapus && (
					<>
					{(isloading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
						<>
						<CLink onClick={()=>hdlKlikUrutan(id,"UP")} className={(vUrutan<=1?"d-none ":"")+"classikontabel classikonup classcursorpointer"}/>
						<CLink onClick={()=>hdlKlikUrutan(id,"DOWN")} className={(vUrutan>=uJmlData?"d-none ":"")+"classikontabel classikondown classcursorpointer"}/>
						<CDropdown autoclose={true}>
							<CDropdownToggle caret={false} color="transparent" className="p-0 m-0">
							<CIcon icon="cilChevronBottom" className="classikoncoreui" height={23}/>
							</CDropdownToggle>

							<CDropdownMenu>
							<CDropdownItem
								onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikonedit align-middle me-2" height={25}/>
								{(uBahasaObj.caption_editdata||"caption_editdata")}
							</CDropdownItem>
							<CDropdownItem
								onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus align-middle me-2" height={25}/>
								{(uBahasaObj.caption_hapusdata||"caption_hapusdata")}
							</CDropdownItem>
							</CDropdownMenu>
						</CDropdown>
						</>
					)}
					</>
					)}
					</div>
				</CCol>

				<CCol xs="12" className="d-md-none my-2 classborderbottom" />

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_penyusutan||"word_penyusutan"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-center">
					<b>{UFunc.formatAngka(penyusutan||"0")}</b> {uBahasaObj.word_bulan||"word_bulan"}
				</CCol>

				<CCol md="3" className="px-1 d-none d-md-block">
					{uISallowedhapus && (
					<>
					{(isloading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
						<>
						<CTooltip content={"--"+(uBahasaObj.petunjuk_ubahurutankeatas || "petunjuk_ubahurutankeatas")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikUrutan(id,"UP")} className={(vUrutan<=1?"d-none ":"")+"classikontabel classikonup classcursorpointer"}/>
						</CTooltip>

						<CTooltip content={"--"+(uBahasaObj.petunjuk_ubahurutankebawah || "petunjuk_ubahurutankebawah")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikUrutan(id,"DOWN")} className={(vUrutan>=uJmlData?"d-none ":"")+"classikontabel classikondown classcursorpointer"}/>
						</CTooltip>

						<CDropdown autoclose={true}>
							<CDropdownToggle caret={false} color="transparent" className="p-0 m-0">
							<CIcon icon="cilChevronBottom" className="classikoncoreui" height={23}/>
							</CDropdownToggle>

							<CDropdownMenu>
							<CDropdownItem
								onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikonedit align-middle me-2" height={25}/>
								{(uBahasaObj.caption_editdata||"caption_editdata")}
							</CDropdownItem>
							<CDropdownItem
								onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus align-middle me-2" height={25}/>
								{(uBahasaObj.caption_hapusdata||"caption_hapusdata")}
							</CDropdownItem>
							</CDropdownMenu>
						</CDropdown>
						</>
					)}
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const loadContentForm=()=>{
		return (
		<CForm className="mx-auto mx-lg-1">
		<CRow className="my-2">
			<CCol xs="3" md="4" className="text-md-end">
				<small className="d-md-none">{uBahasaObj.caption_jenisaset||"caption_jenisaset"}</small>
				<span className="d-none d-md-block">{uBahasaObj.caption_jenisaset||"caption_jenisaset"}</span>
			</CCol>
			<CCol className="">
				<CFormInput maxLength={100}
					size="sm"
					className="form-control text-capitalize"
					value={uInpNamajenis}
					onChange={(e) => setInpNamajenis(e.target.value)}
					id="inpnamajenis"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="3" md="4" className="text-md-end classbordertmp">
				<small className="d-md-none">{uBahasaObj.word_penyusutan||"word_penyusutan"}</small>
				<span className="d-none d-md-block">{uBahasaObj.word_penyusutan||"word_penyusutan"}</span>
			</CCol>
			<CCol xs="5" lg="4" className="classbordertmp">
				<CInputGroup size="sm">
				<CFormInput
					type="number"
					size="sm"
					step={10}
					min={0}
					className="text-end"
					value={uInpJmlsusut}
					onFocus={(e)=>e.target.select()}
					onKeyDown={(e)=> UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					onChange={(e) => setInpJmlsusut(e.target.value)}
					id="inppenyusutan"/>
				<CInputGroupText>{uBahasaObj.word_bulan||"word_bulan"}</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>
		</CForm>
		)
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoadata = () => {
		uFuncname = apiLoadata.name;
		setHTMLError500("");

		const vKeyword = uKeywordObj.kwd_asetjenis || "";
		setJmlData(0);
		setDataArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_asetjenis/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject || "[]");
				setDataArr(vTmpObj);
				setJmlData(vTmpObj.length);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		setLoading(true);
		uElBtnsimpan.disabled = true;

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/
		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_namajenis : uInpNamajenis,
			send_jmlsusut : uInpJmlsusut,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_asetjenis/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uElBtnsimpan.disabled = false;
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				if(uTabelIDPilih <= 0) {
					const vTmpObject = {
						id 				: output_string.tabelid || "255",
						nama_jenis_aset : UFunc.capitalize(uInpNamajenis.trim()),
						penyusutan 		: uInpJmlsusut,
						urutan			: (uJmlData+1),
					}
					setDataArr([...uDataArr,vTmpObject]);
					setJmlData((uJmlData + 1));
					setTabelRowSelect(-1);
					initFormAwal();
					setPageActive(1);

					showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
					if(!isMobile) document.getElementById("inpnamajenis").focus();
				} else {
					const vObjdx				= UFunc.getObjectIndeks(uDataArr,uTabelIDPilih);
					uDataArr[vObjdx].nama_jenis_aset = UFunc.capitalize(uInpNamajenis.trim());
					uDataArr[vObjdx].penyusutan = uInpJmlsusut;
					hdlToggleForm();
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				let vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnsimpan.disabled = false;
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		uFuncname 	= apiProseshapus.name;
		_IDTABEL	= parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		const vObjidx	= UFunc.getObjectIndeks(uDataArr,_IDTABEL);
		uDataArr[vObjidx].isloading	= true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_asetjenis/pr_hapus";

		uDataArr[vObjidx].isloading	= true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDataArr[vObjidx].isloading	= false;
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDataArr.splice(vObjidx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDataArr.length)||0));
				//---UBAH_URUTAN_NEXT--/
				uDataArr.forEach((vItem,vKey)=>{
					if(vKey >= vObjidx) {
						vItem.urutan = (parseInt(vItem.urutan)||1) - 1;
					}
				})
				//---END UBAH_URUTAN_NEXT--/
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				let vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			uDataArr[vObjidx].isloading	= false;
			setEfekview(!uEfekview);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesurutan = (_IDTABEL,_ACTIONS) => {
		uFuncname 	= apiProsesurutan.name;
		_IDTABEL	= parseInt(_IDTABEL) || 0;
		_ACTIONS 	= (_ACTIONS||"").toUpperCase();

		if(_IDTABEL <= 0) return;

		const vObjidx		= UFunc.getObjectIndeks(uDataArr,_IDTABEL);
		const vUrutanBefore	= parseInt(uDataArr[vObjidx].urutan);
		setTabelIDPilih(0);
		uDataArr[vObjidx].isloading	= true;
		setEfekview2(!uEfekview2);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_actions : _ACTIONS,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_asetjenis/pr_urutan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDataArr[vObjidx].isloading	= false;
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				let vUrutanNew;
				let vIdxAffected	= -1;
				if(_ACTIONS=="UP") {
					vUrutanNew		= vUrutanBefore - 1;
					vIdxAffected	= vObjidx - 1;
				}else {
					vUrutanNew		= vUrutanBefore + 1;
					vIdxAffected	= vObjidx + 1;
				}
				uDataArr[vIdxAffected].urutan 	= vUrutanBefore;
				uDataArr[vObjidx].urutan 		= vUrutanNew;

				const vNewsortedArr = uDataArr.sort((a, b) => a["urutan"] - b["urutan"]);
				setDataArr(vNewsortedArr);

				const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
				setTabelRowSelect(vRowIdx);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			uDataArr[vObjidx].isloading	= false;
			setEfekview(!uEfekview);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		if(vElJmlData) {
			if(uIsShowForm) return;

			if(vElJmlData.innerHTML !== UFunc.formatAngka(uJmlData)) {
				vElJmlData.innerHTML = UFunc.formatAngka(uJmlData);
			}
		}
	});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		setShowForm(false);
		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoadata();
    	return()=>{ setDataArr([]); }
	},[]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		setPageActive(uPageActive<=0?1:uPageActive);
	},[uJmlData]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	/*React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoadata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;

		setDialogHeader((uBahasaObj.word_tambah||"word_tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setShowForm(true);
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!uIsShowForm) return;

		//console.log("("+cComponentName+"[uIsShowForm]) uIsShowForm 1: "+uIsShowForm);
		let vTimeout = setTimeout(function(e){
			const vElFocus = document.getElementById("inpnamajenis");
			vElFocus && vElFocus.focus();
			clearTimeout(vTimeout);
		},200);//-*/
	},[uIsShowForm]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>);
	
	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_asetjenis || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			<small className="text-muted classfontsmaller">{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</CCardHeader>

			<CCardBody className="px-0 px-lg-5">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"",centered:false}}
			onSimpan={()=>hdlKlikFormSimpan()}
			show={uIsShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={loadContentForm()}/>
		</>
	)
}
	
export default Asetjenis;
