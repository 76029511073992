import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CFooter,
	CRow,
	CCol,
	CForm,
	CFormInput,
	CButton,
	CInputGroup,
	CInputGroupText,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyDialoglookup,
	MyPage500,
	MyProfileDetil,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

let pjson     			= require('../../../package.json');
const Pakaikliniktambah = (props) => {
	const uComponentname	= Pakaikliniktambah.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 
	let uFuncname;

	const uNavigate			= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uDefaultSetting	= useSelector(state => state.gListDefaultSetting);
	const uEfekapp			= useSelector(state => state.effectApp);

	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const [uInpNamabarang,setInpNamabarang]		= React.useState("");
	const [InpBarangID,setInpBarangID]			= React.useState(0);
	const [uInpStokSisa,setInpStokSisa]			= React.useState(0);
	const [uInpSatuan,setInpSatuan]				= React.useState("");
	const [uInpJmlpakai,setInpJmlpakai]			= React.useState(0);

	const [uIsFormprocess,setIsFormprocess]		= React.useState(false);
	const [uIsBarangloading,setBarangloading]	= React.useState(false);
	const [uIsKecilloading,setKecilloading]		= React.useState(false);

	//--LOOKUP--/
	const [uIsLookupShow,setLookupShow]			= React.useState(false);
	const [uInpLookupInput,setInpLookupInput]	= React.useState("");
	const [uLookupItemArr,setLookupItemArr]		= React.useState([]);//-->HARUS_ADA_ID_DAN_CAPTION
	const [uLookupinit,setLookupinit]			= React.useState("");
	const [uLookupHeader,setLookupHeader]		= React.useState("");
	//--END LOOKUP--/

	//---HANDEL--/
	const hdlKlikReset=async()=>{

		const vFunction	= () =>{ 
			setInpNamabarang("");

			initAwalMain();
			//apiLoadAwal();
		}

		const vInitChange = (uInpNamabarang !== "" || (parseInt(InpBarangID) > 0) 
			|| (parseInt(uInpJmlpakai) > 0)
			) 
			? true : false

		const vConfirm = uBahasaObj.confirm_reset||"confirm_reset";
		if(vInitChange)
			{if(await Konfirm(vConfirm)) vFunction(); }
		else apiLoadAwal();
	}

	const hdlToggleLookup=()=>{setLookupShow(false);}
	const hdlKeyDownNamaBarang=(_EV)=>{
		if(_EV.keyCode !== 13) return;
		if(uInpNamabarang.trim()==="") {
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uLookupItemArr.filter((vItem)=>
			vItem.caption.toUpperCase().includes(uInpNamabarang.toUpperCase()));
		
		if (vArrCariMaster.length === 1) {

			apiPilihBarang(vArrCariMaster[0].id);
		} else {
			hdlKlikBaranglookup();
		}
	}
	const hdlKlikBaranglookup=()=>{
		setInpLookupInput(uInpNamabarang);
		setLookupHeader((uBahasaObj.caption_lookupitem||"caption_lookupitem")+": "+
			(uBahasaObj.word_barang||"word_barang").toUpperCase()
			);
		setLookupShow(true);
	}
	const hdlKlikPilihLookup=(_IDITEM)=>{
		const vIdx = UFunc.getObjectIndeks(uLookupItemArr,_IDITEM,"id");
		if(vIdx < 0) return;

		apiPilihBarang(_IDITEM);
	}

	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			_EV.preventDefault();
			hdlKlikSimpan();
		}
	}
	const hdlKlikSimpan=()=>{
		//---VALIDASI--/
		if((parseInt(InpBarangID)||0) <= 0) {
			document.getElementById("inpnamabarang").focus();
			showToast((uBahasaObj.caption_barangbox||"caption_barangbox")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		if((parseInt(uInpJmlpakai)||0) <= 0) {
			document.getElementById("inpjmlpakai").focus();
			showToast((uBahasaObj.caption_jmlpakai||"caption_jmlpakai")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		//---END VALIDASI--/

		apiSimpan();
	}
	//---END HANDEL--/

	//--INIT--/
	const initResetNamabarang=()=>{
		setInpJmlpakai(0);
		setInpBarangID(0);
		setInpStokSisa(0);
		setInpSatuan("")
	}
	const initAwalMain=()=>{
		initResetNamabarang();
		setInpNamabarang("");
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";
		
		return (
			<CForm className="mx-lg-5 p-lg-5">
			<CRow className="my-2">
				<CCol md="4" className="text-md-end text-info">
				<strong>{uBahasaObj.caption_namabarang||"caption_namabarang"}</strong>
				</CCol>
				<CCol md="6" lg="5" className="">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						value={uInpNamabarang}
						onKeyDown={(e)=>hdlKeyDownNamaBarang(e)}
						onChange={(e)=>{setInpNamabarang(e.target.value);initResetNamabarang()}}
						id="inpnamabarang"/>
						<CButton size="sm"
							color="dark"
							onClick={()=>hdlKlikBaranglookup()}>
							<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
					{uIsBarangloading && (
					<CInputGroupText className="border-0 bg-transparent">
						<CSpinner color="danger" size="sm" />
					</CInputGroupText>
					)}
					</CInputGroup>
					{(parseInt(InpBarangID)>0) && (
					<div className="classborderbox classpetunjuk my-2 p-2">
						<div>{uBahasaObj.caption_stoksisa||"caption_stoksisa"}: <strong>{UFunc.formatAngka(uInpStokSisa)}</strong> {uInpSatuan}</div>
					</div>
					)}
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
				<CCol xs="6" md="4" className="text-md-end text-info">
				{uBahasaObj.caption_jmlpakai||"caption_jmlpakai"}
				</CCol>
				<CCol xs="6" md="3" lg="2" className="text-end text-md-start">
					<CInputGroup size="sm">
					<CFormInput type="number"
						value={uInpJmlpakai}
						size="sm"
						className="text-end"
						onChange={(e) => setInpJmlpakai(e.target.value)}
						onFocus={(e)=>e.target.select()}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
						id="inpjmlpakai"/>
					<CInputGroupText className="py-0 fw-bolder">
					{uInpSatuan||"UNT"}
					</CInputGroupText>
					</CInputGroup>
				</CCol>
			</CRow>
			</CForm>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoadAwal = () => {
		uFuncname = apiLoadAwal.name;
		setHTMLError500("");

		/*//--TESTING_FRONTEND--/
		setLoading(true);
		setLookupItemArr(vTmpObj);
		let vTimeout = setTimeout(function(){setLoading(false);clearTimeout(vTimeout)},2500);
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_init/ld_baranglist";
		//const vURLs	= pjson.homepage+"api/api_pakaiklinik/ld_dataform";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				if(output_string.databarang) {
					setLookupItemArr(JSON.parse(output_string.databarang))
				}
				setIsFormprocess(false);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
				//showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
				//showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiPilihBarang = (_IDITEM) => {
		uFuncname = apiPilihBarang.name;
		setLookupShow(false);

		const vIdx = UFunc.getObjectIndeks(uLookupItemArr,_IDITEM,"id");
		if(vIdx < 0) return;
		initResetNamabarang();
		setInpNamabarang(uLookupItemArr[vIdx].caption);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_barangid : _IDITEM,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_init/ld_initbarang";

		setIsFormprocess(true);
		setBarangloading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setIsFormprocess(false);
			setBarangloading(false);
			if(output_string.tampil) {
				setInpBarangID(uLookupItemArr[vIdx].id);
				setInpSatuan(uLookupItemArr[vIdx].satuan);

				if(output_string.dataobject) {
					const vTmpObj = JSON.parse(output_string.dataobject);
					setInpStokSisa(vTmpObj.sisastok || 0);
				}

				document.getElementById("inpjmlpakai").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setInpNamabarang("");
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setInpNamabarang("");
			setIsFormprocess(false);
			setBarangloading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpan = () => {
		uFuncname = apiSimpan.name;

		/*//--TESTING_FRONTEND--//
		showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
		if(!isMobile) document.getElementById("inpnamabarang").focus();
		initAwalMain();
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_barangid	: InpBarangID,
			send_barangjml	: uInpJmlpakai,
			send_tokenauth 	: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pakaiklinik/pr_simpaninput";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setIsFormprocess(false);

			if(output_string.sukses) {
				const vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				showToast(vPesan,"SUKSES");
				initAwalMain();
				if(!isMobile) document.getElementById("inpnamabarang").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setIsFormprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);

		return function cleanup() {
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		window.scrollTo({top: 0});
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadAwal();
		
		if(!isMobile) {
			const vEls = document.getElementById("inpnamabarang");
			if(!UFunc.isEmpty(vEls)) vEls.focus();
		}

		return () => {
			setLookupShow(false);
		}
	},[]);
	React.useEffect(()=>{
		if((parseInt(uInpJmlpakai)||0) < 0) {
			setInpJmlpakai((uInpJmlpakai * -1)); return;
		}
		if((parseInt(uInpJmlpakai)||0) > (parseInt(uInpStokSisa)||0)) {
			setInpJmlpakai(uInpStokSisa); return;
		}
	},[uInpJmlpakai]);
	//---END EFFECT---/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="classheadermain" style={{minHeight:64}}>
			<CHeaderToggler className="px-0" onClick={() => {uNavigate(-1);}}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-1" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_pakaiklinik||"menus_pakaiklinik")}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
		<CContainer xxl className="px-0">
			<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
				<div>
				<CIcon className="classikon classikontambah" height={30}/>
				{" "}{uBahasaObj.caption_inputbaru||"caption_inputbaru"}: <strong>{UFunc.HariAngka()}</strong>
				</div>
				<CTooltip content={uBahasaObj.caption_resetform||"caption_resetform"}>
					<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
				</CTooltip>
			</CCardHeader>
			<CCardBody>
				{uHTMLError500 ? UFunc.renderHTML(uHTMLError500) : (<>{contentMain()}</>)}
			</CCardBody>
			</CCard>
		</CContainer>
		</div>


		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uHTMLError500 || uIsFormprocess}
				onClick={()=>hdlKlikSimpan()}
				id="btnSimpan">Simpan [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsLookupShow}
			toggle={()=>hdlToggleLookup()}
			dialogHeader={uLookupHeader||"uLookupHeader"}
			inputValue={uInpLookupInput}
			dataItemArr={uLookupItemArr} 
			onKlikPilihItem={hdlKlikPilihLookup}
			id="iddialoglookup"/>
		</>
	)
}

export default Pakaikliniktambah;
