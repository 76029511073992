import React from 'react';
//import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CForm,
	CInputGroup,
	CFormInput,
	CButton,
	CTooltip,
	CLink,
	CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';

let pjson     = require('../../../package.json');
const Headersubstokopname = (props) => {
	//const cComponentName = Headersubstokopname.name;
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uBahasaObj	  	= useSelector(state => state.listBahasa);
	//const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	//const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp			= useSelector(state => state.effectApp);

	const [uKeyword,setKeyword] = React.useState(uKeywordObj.kwd_stokopname || "");

	const hdlKeydown=(_EV)=>{
		if(_EV.which===13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari=()=>{
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_stokopname = uKeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/
		//setHeaderView(true);

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset=async()=>{
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_stokopname = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//prosesReset(); return;
		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}
	const hdlKlikExcel=()=>{
		uHeaderActionObj.isHeaderExcel = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}

	//console.log("("+cComponentName+") Checked..");
	return(
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
				className="align-middle me-1"
				height={30}/>
			<small className="fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
			<CInputGroup 
				style={{minWidth:295,width:"auto"}} 
				size="sm">
				<CFormInput size="sm" 
					value={uKeyword} 
					onChange={(e) => setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKeydown(e)} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."}
					id="inpkeyword"/>
				<CButton size="sm" color="light" onClick={()=>hdlKlikCari()} 
					className="border"
					id="btnCari">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
			</CInputGroup>
			</CForm>
		
			<span className="d-none d-md-block ms-1">
			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			
			{(uIsEOMExecuted) ? (
			<>
				<CTooltip className="d-none d-lg-block" content={uBahasaObj.caption_exportexcel||"Export-2-Excel"}>
					<CLink className="classcursorpointer classikon classikonexcel"
						 onClick={()=>hdlKlikExcel()}/>
				</CTooltip>
				<span className=" d-none d-lg-inline mx-1">&middot;</span>
			</>
			) : (
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui classblinking" height={30}/>
			)}
			
			<CTooltip className="d-none d-lg-block" content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink className="classcursorpointer classikon classikonreset"
					 onClick={()=>hdlKlikReset()}/>
			</CTooltip>
			<span className="me-1">&middot;</span>
			
			<b id="idjmldata">0</b> {uBahasaObj.word_data||"word_data"}
			</span>

			<div className="d-md-none ms-1">
			{(uIsEOMExecuted) ? (
				<CLink className="classcursorpointer classikon classikoncetak" onClick={()=>hdlKlikExcel()}/>
			) : (
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui classblinking" height={30}/>
			)}
			</div>
		</div>
		</>
	)//>
}

export default Headersubstokopname;