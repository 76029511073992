import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CForm,
	CFormGroup,
	CFormInput,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPage500,
	MyPagination,
	MyFormHapus,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
let pjson     = require('../../package.json');

const Baranged = (props) => {
	const uComponentname 	= Baranged.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview]	= React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uIsInitHapus		= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted 
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uFirstload,setFirstload]		= React.useState("YA");

	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uTimeElapsed,setTimeElapsed]	= React.useState(0);

	//---FORMDIALOG--/
	const [isFormhapusShow,setFormhapusShow]= React.useState(false);
	const [uFormhapusHeader,setFormhapusHeader]= React.useState("");
	const [uInpKethapus,setInpKethapus]	= React.useState("");
	//---END FORMDIALOG--/

	const uElJmldata	= document.getElementById("idjmldata");
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleFormhapus=()=>{ setFormhapusShow(false); }
	const hdlKlikFormhapus=()=>{
		//--VALIDASI--/
		if((uInpKethapus||"").trim() === "") {
			showToast((uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi")); 
			document.getElementById("inpketerangan").focus();
			return;
		}
		//--END VALIDASI--/
		apiProseshapus(uTabelIDPilih);
	}
	const hdlKlikHapus=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vIdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);

		setFormhapusHeader(
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			(UFunc.TglAngka(uDatamainArr[vIdx].tanggal))+" - "+
			(uDatamainArr[vIdx].nama_barang).toUpperCase()+
			"");
		setFormhapusShow(true);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_baranged || "";

		if (vKeyword.length >= 3)
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_baranged || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataViewArr	= uDatamainArr.filter(vItems =>
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width={30} className="px-1">No</th>
		<th className="px-1">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.caption_tglproses||"caption_tglproses"}</CCol>
			<CCol md="4" className="text-start">{uBahasaObj.caption_namabarang||"caption_namabarang"}</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_jmlrusaked||"caption_jmlrusaked"}</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
		</th>
		</tr>

		<tr className="d-md-none">
			<th width={30} className="p-0"/>
			<th className="p-0"/>
		</tr>
		</thead>

		<tbody>
		{vDataViewArr.map((vItems,vKeys)=>{
			const {
				id,tanggal,nama_barang,satuan,jml_pakai,username,tgl_input,keterangan,
				init_expired,status_jurnal,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= UFunc.HariAngka(tanggal)+" "+((nama_barang||"")).toUpperCase();

			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tanggal) 
				: (UFunc.TglAngka(tanggal)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input||"") 
				: (UFunc.WaktuAngka(tgl_input||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNamaBarang= vKeyword==="" ? UFunc.capitalize(nama_barang||"") 
				: (UFunc.capitalize(nama_barang||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vSatuan	= vKeyword==="" ? (satuan||"").toUpperCase()
				: ((satuan||"").toUpperCase()).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKeterangan	= vKeyword==="" ? (keterangan||"-")
				: ((keterangan||"-")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUsername = vKeyword==="" ? (username||"undf").toUpperCase()
				: ((username||"undf").toUpperCase()).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			const vColorstatustembak = (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";

			return (
			<tr id={"idtr"+id} className={"align-top"+(uTabelRowSelect===vKeys?" classrowselect":"")}
				 key={vKeys}>
			<td className="px-1 align-top text-end">{(vNumber)}.</td>
			<td className="px-1 align-top text-end">
			<CRow className="mx-0">
				<CCol xs="12" md="2" className="px-1 text-start d-flex justify-content-between">
					<div>
					{UFunc.renderHTML(vTanggal)}
					{uTokenObj.userhak==="FREEROLE" && (
						<span className="classfontsmaller"> &middot; <small>({id})</small></span>
					)}

					<div className="d-none d-md-block classfontsmaller text-danger">
						<small>[
						{UFunc.TglAngka(tanggal||"")!==UFunc.TglAngka(tgl_input||"") && (
						<>
						{UFunc.renderHTML(vTglInput)}
						<span className="mx-1">&middot;</span>
						</>
						)}
						<span className="text-uppercase">{UFunc.renderHTML(vUsername)}</span>
						]</small>
					</div>
					</div>

					{(uIsInitHapus && uIsEOMExecuted && (init_expired||"TIDAK")!=="YA") && (
					<div className="d-md-none">
					<CLink 
						className="classikontabel classikonhapus classcursorpointer"
						onClick={()=>hdlKlikHapus(id)}/>
					</div>
					)}
				</CCol>
				
				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom" />
				
				<CCol xs="4" className="px-1 text-start d-md-none">
					<small>{uBahasaObj.caption_namabarang||"caption_namabarang"}</small>
				</CCol>
				<CCol xs="8" md="4" className="px-1 text-md-start">
					<div className="text-info">{UFunc.renderHTML(vNamaBarang)}</div>

					{(uTokenObj.userhak==="FREEROLE" && (status_jurnal||"")!=="") && (
						<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
						</div>
					)}
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none"><small>{uBahasaObj.word_keterangan||"word_keterangan"}</small></CCol>
				<CCol xs="8" md="3" className="px-1 text-md-start">
					<small className="fst-italic">{UFunc.renderHTML(vKeterangan)}</small>
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_jmlrusaked||"caption_jmlrusaked"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-center">
					<b className="text-primary me-1">{UFunc.formatAngka(jml_pakai)}</b>
					<small>{UFunc.renderHTML(vSatuan)}</small>
				</CCol>
				
				<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom" />

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_sjurnal||"S.Jurnal"}</CCol>
				<CCol xs="8" md="4" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal || "-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="4" className="px-1 text-start d-md-none"><small>{uBahasaObj.word_inputby||"Inputby"}</small></CCol>
				<CCol xs="8" className="px-1 d-md-none text-danger classfontsmaller">
					<small>[
						{UFunc.TglAngka(tanggal||"")!==UFunc.TglAngka(tgl_input||"") && (
						<>
						{UFunc.renderHTML(vTglInput)}
						<span className="mx-1">&middot;</span>
						</>
						)}
						<span className="text-uppercase">{UFunc.renderHTML(vUsername)}</span>
					]</small>
				</CCol>

				<CCol md="1" className="px-1 text-center d-none d-md-block">
				{(uIsInitHapus && uIsEOMExecuted && (init_expired||"TIDAK")!=="YA") && (
				<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
					<CLink 
						className="classikontabel classikonhapus classcursorpointer"
						onClick={()=>hdlKlikHapus(id)}/>
				</CTooltip>
				)}
				</CCol>
			</CRow>
			</td>
			</tr>
			);
		})}
		</tbody>
		</table>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname	= apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1;
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_baranged || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoaddata) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			setLoading(false);
		},3000);return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_baranged/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vLoadArr = JSON.parse(output_string.dataobject||"[]");
				vLoadArr.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<span class='text-danger'>"+vMsg+"</span>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		uFuncname 	= apiProseshapus.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		const vIdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_keterangan: uInpKethapus,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_baranged/pr_hapus";

		document.getElementById("btnDialogSimpan").disabled = true;
		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			document.getElementById("btnDialogSimpan").disabled = false;

			if(output_string.sukses) {
				uDatamainArr.splice(vIdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				hdlToggleFormhapus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				document.getElementById("btnDialogSimpan").disabled = true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			document.getElementById("btnDialogSimpan").disabled = false;
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return () => { 
			uDispatch({type: "set", gInitHeaderAction: {}});
			setFormhapusShow(false);
    		setDatamainArr([]); setFirstload("YA");
    	}
	},[]);
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uIsEOMExecuted) return;
		
		uNavigate("/substok/baranged/forminput");
	},[uHandelTambah]);
	React.useEffect(()=>{ uElJmldata && initJmldata() },[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);
	React.useEffect(()=>{});
	React.useEffect(()=>{
		if(!isFormhapusShow) { setInpKethapus(""); return;}

		let vTimeout = setTimeout(function(){
			document.getElementById("inpketerangan") && document.getElementById("inpketerangan").focus();
			clearTimeout(vTimeout);
		},350);
	},[isFormhapusShow]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg mb3">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="">Rekap Input</span>
				<span className="d-md-none fw-bolder">
				<span className="mx-1">&middot;</span>
				{(()=>{
					const vKeyword = uKeywordObj.kwd_baranged || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>

			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyFormHapus
			value={uInpKethapus}
			onCHvalue={(e)=>setInpKethapus(e.target.value)}
			onSimpan={()=>hdlKlikFormhapus()}
			show={isFormhapusShow} 
			dialogHeader={uFormhapusHeader}
			toggle={hdlToggleFormhapus}/>
		</>
	)//>
}
	
export default Baranged;
