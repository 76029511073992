import React from 'react'
import { confirmable } from 'react-confirm'
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { UFunc } from "../helpers/functions"

const MyDialogconfirm = props => {
	//const cComponentname	= MyDialogconfirm.name;
	const { show, proceed, dialogContent, dialogHeader,options,...rest } = props;

	let vBtnExecute	= options.tipe==="KONFIRM" ? "YA" : "Simpan";
	let vTeksHeader	= (dialogHeader==="" || options.tipe==="KONFIRM") ? "Konfirmasi" : dialogHeader;
	let vTeksIsi	= dialogContent==="" ? "{UNDEFINED}" : dialogContent;
	const [uInitConfirmLoad,setInitConfirmLoad] = React.useState(true);

	/*React.useEffect(()=>{
		if(uInitConfirmLoad) { setInitConfirmLoad(!uInitConfirmLoad); } 
	},[uInitConfirmLoad]);//-*/
	React.useEffect(()=>{
		if(!show) return;

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			const vElBtnOK	= document.getElementById("btnconfirmok");
			vElBtnOK && vElBtnOK.focus();
		},300);	
	},[show]);//-*/

	const HdlClose=()=>{ proceed(false); }

	const renderHTML = (escapedHTML: string) => 
		React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

	return (
		<CModal color={options.tipe==="KONFIRM"? "warning" : "info"} 
			alignment={"top"}
			visible={show}
			focus="true"
			scrollable
			{...rest}>
		<CModalHeader 
			className={""+(options.tipe==="KONFIRM"?"text-warning":"text-info")}
			closeButton>
			<CModalTitle>{vTeksHeader}</CModalTitle>
		</CModalHeader>

		<CModalBody>{renderHTML(vTeksIsi)}</CModalBody>
		<CModalFooter>
		<CButton color={options.tipe==="KONFIRM"? "warning" : "info"} 
			id="btnconfirmok"
			onClick={()=>proceed(true)}
			className="fw-bolder">{vBtnExecute}</CButton>{' '}
		<CButton color="secondary" onClick={HdlClose}>Tidak</CButton>
		</CModalFooter>
		</CModal>
	);
}
MyDialogconfirm.defaultProps = {
	dialogContent: "{UNDEFINED}..",
	dialogHeader: "Konfirmasi",
	options: {tipe:"KONFIRM"},
};
MyDialogconfirm.propTypes = {
	show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
	proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
	dialogContent: PropTypes.string,  // arguments of your confirm function
	dialogHeader: PropTypes.string,  // arguments of your confirm function
	options: PropTypes.object        // arguments of your confirm function
}

export default confirmable(MyDialogconfirm);