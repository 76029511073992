import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	//CForm,CFormInput,CInputGroup,CInputGroupText,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
	CTooltip,
	CLink,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyPagination,
	MyPage500,
	MyFormHapus,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Ruparupa 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uUserSettingObj 		= useSelector(state => state.gListUserSetting);
	const uIsEOMExecuted		= useSelector(state => state.gIsEOMExecuted);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uUserSettingObj.jml_mobile || 30) : (uUserSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderactionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderactionObj.isHeaderView || false;
	const uHeaderTambah 		= uHeaderactionObj.isHeaderTambah || false;

	//---PAGINATION_DIATUR_BACKEND--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//---END PAGINATION_DIATUR_BACKEND--/

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);

	//--DLG_HAPUS--/
	const [uDlghapusObj,setDlghapusObj]		= React.useState({});
	const [uISDlghapusshow,setDlghapusshow]	= React.useState(false);
	//--END DLG_HAPUS--/

	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlKlikHapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		initSelectrow(_IDTABEL);
		setTabelIDPilih(_IDTABEL);
		
		uDlghapusObj.headers = ((uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			(UFunc.TglAngka(uDatamainArr[vArridx].tanggal)+" "+
				(uDatamainArr[vArridx].gl_caption_debet || "")).toUpperCase());
		setDlghapusshow(true);
	}
	const hdlKlikDlghapussimpan = () => {
		if(parseInt(uTabelIDPilih) <= 0) return;

		if((uDlghapusObj.inputvalue||"").trim() === "") {
			document.getElementById("inpkethapus") && 
				document.getElementById("inpkethapus").focus();
			showToast((uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi")); 
			return;
		}

		apiProseshapus();
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_ruparupa || "";
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} 

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setEfekview(!uEfekview)
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_ruparupa || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=> 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.caption_akundebet||"Akun GL DEBET"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.caption_akunkredit||"Akun GL Kredit"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" className="">{uBahasaObj.word_nilai||"Nilai"}<small className="ms-1">(Rp)</small></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none classfontsmaller">
				<th width={30} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				tanggal,tgl_input,gl_caption_debet,username,
				keterangan,noref,nilai,init_expired,gl_caption_kredit,
				status_jurnal,
				id
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (UFunc.HariAngka(tanggal)+" "+gl_caption_debet).toUpperCase();

			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tanggal) 
				: (UFunc.TglAngka(tanggal)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vGLDebet= vKeyword==="" ? (gl_caption_debet||"Undf Debet") 
				: (gl_caption_debet||"Undf").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vGLKredit= vKeyword==="" ? (gl_caption_kredit||"Undf Kredit") 
				: (gl_caption_kredit||"UNDF").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUsername= vKeyword==="" ? UFunc.Usercaptionfilter((username||""),1) 
				: UFunc.Usercaptionfilter((username||""),1).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKeterangan= vKeyword==="" ? (keterangan||"-") 
				: (keterangan||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNoref= vKeyword==="" ? (noref||"-") 
				: (noref||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: (UFunc.WaktuAngka(tgl_input)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			const vISInputexpired = (init_expired||"YA")==="YA";
			const vColorstatustembak = (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-1">
				<CCol md="2" className="text-start px-1 d-flex justify-content-between">
					<div>
					{UFunc.renderHTML(vTanggal)}
					{(uTokenObj.userhak === "FREEROLE") && (
						<span className="classfontsmaller"> &middot; <small>({id})</small></span>
					)}

					<div className="classfontsmaller text-danger d-none d-md-block">
					<small>[
						{(tgl_input||"")!=="" && (
						<>
						{UFunc.renderHTML(vTglInput)}
						<span className="mx-1">&middot;</span>
						</>
						)}
						{UFunc.renderHTML(vUsername.toUpperCase())}
					]</small>
					</div>
					</div>

					{(uIsAllowedhapus && uIsEOMExecuted && !vISInputexpired) && (
					<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm" className="p-0"
							color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_hapusdata)}
						</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>
					)}
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_akundebet||"Akun GL Debet"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-start">
					<small className="text-info">{UFunc.renderHTML(vGLDebet)}</small>

					{((status_jurnal||"")!=="" && uTokenObj.userhak==="FREEROLE") && (
					<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
					</div>
					)}
				</CCol>

				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_akunkredit||"Akun GL Kredit"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-start">
					<small className="d-block">{UFunc.renderHTML(vGLKredit)}</small>
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal||"-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="3" className="d-md-none px-1 text-start">{uBahasaObj.word_keterangan}</CCol>
				<CCol xs="9" className="px-1 d-md-none">
					<small className="fst-italic text-capitalize d-block">{UFunc.renderHTML(vKeterangan)}</small>
				</CCol>

				<CCol xs="4" className="d-md-none px-1 text-start">No.Nota (Ref)</CCol>
				<CCol xs="8" md="3" className="text-md-start px-1">
					{(keterangan||"").trim() !=="" && (
					<>
					<small className="fst-italic text-capitalize d-none d-md-block">
						{UFunc.renderHTML(vKeterangan)}
					</small>
					</>
					)}

					{(noref||"").trim() !== "" && (
					<div className="d-none d-md-block">
						<span className="me-1">No.Ref:</span>
						<b className="text-uppercase">{UFunc.renderHTML(vNoref)}</b>
					</div>
					)}
					<span className="text-uppercase d-md-none">{UFunc.renderHTML(vNoref)}</span>
					
					{((keterangan||"").trim()==="" && (noref||"").trim()=== "") && (
						<span className="d-none d-md-block">-</span>
					)}
				</CCol>

				<CCol xs="4" className="d-md-none px-1 text-start">Inputby</CCol>
				<CCol xs="8" className="px-1 d-md-none classfontsmaller text-danger">
					<small>[
						{(tgl_input||"")!=="" && (
						<>
						{UFunc.renderHTML(vTglInput)}
						<span className="mx-1">&middot;</span>
						</>
						)}
						{UFunc.renderHTML(vUsername.toUpperCase())}
					]</small>
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.word_nilai} (Rp)</CCol>
				<CCol xs="8" md="2" className="px-1 text-success">
					<span className="d-md-none d-lg-block">{UFunc.formatAngka(nilai)}</span>
					<small className="d-none d-md-block d-lg-none">{UFunc.formatAngka(nilai)}</small>
				</CCol>

				<CCol md="1" className="text-md-center d-none d-md-block">
				{(uIsAllowedhapus && uIsEOMExecuted && !vISInputexpired) ? (
					<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
					<CLink 
						className="classikontabel classikonhapus classcursorpointer"
						onClick={()=>hdlKlikHapus(id)} />
					</CTooltip>
				) : (<>&middot;</>)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = (_PAGE) => {
		setHTMLError500();
		_PAGE		= parseInt(_PAGE)||1;

		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_ruparupa || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ruparupa/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Ruparupa - apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				vLoadArr.map(vItems=>vItems.pg = _PAGE);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Ruparupa - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Ruparupa-apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProseshapus = () => {
		if(uTabelIDPilih <= 0) {showToast("[0] ERROR !"); return;}
		const vArridx= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);

		const vElBtnsimpan = document.getElementById("btnDialogSimpan");
		vElBtnsimpan && (vElBtnsimpan.disabled = true);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		console.log("("+cComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		console.log("("+cComponentname+"-"+uFuncname+") vArridx : "+vArridx);

		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_kethapus : uDlghapusObj.inputvalue||"",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ruparupa/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElBtnsimpan && (vElBtnsimpan.disabled = false);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setTabelRowSelect(-1);
				setJmlData(uJmlData-1);
				setDlghapusshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Ruparupa - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElBtnsimpan && (vElBtnsimpan.disabled = false);
			setLoading(false);
			console.log("(Ruparupa - apiProseshapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) apiLoaddata();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]);
			setDlghapusshow(false);
		}
	},[])
	//--PAGINATION_DIATUR_BACKEND--/
	React.useEffect(()=>{
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	/*React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Ruparupa - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);//-*/
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]); 
	//--END PAGINATION_DIATUR_BACKEND--*/
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderactionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHeaderTambah) return;

		uHeaderactionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		if(uHTMLError500) return;

		uNavigate("/subkeuangan/ruparupa/forminput");
	},[uHeaderTambah]);
	React.useEffect(()=>{
		if(!uISDlghapusshow)
			{setDlghapusObj({}); return}
	},[uISDlghapusshow]);
	
	//console.log("(Ruparupa) uActiveroute => "+(uActiveroute));
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )//>

	return (
	<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_ruparupa || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block">{uBahasaObj.caption_rekapinput||"Rekap Input"}</span>
			</small>
			
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyFormHapus
			value={uDlghapusObj.inputvalue||""}
			onCHvalue={(e)=>{uDlghapusObj.inputvalue=(e.target.value);setEfekview(!uEfekview)}}
			onSimpan={()=>hdlKlikDlghapussimpan()}
			show={uISDlghapusshow} 
			dialogHeader={uDlghapusObj.headers||""}
			toggle={()=>setDlghapusshow(false)}/>
	</>
	)//>
}	

export default Ruparupa