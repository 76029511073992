import React from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderBrand,
	CHeaderToggler,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CForm,
	CFormSelect,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CLink,
	CTooltip,
	CButton,
	CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPagination,
	MyDialoglookup,
	MyButton,
	MyPage500,
	MyProfileDetil,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson 			= require('../../../package.json')
const Titipandprefund	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 
	const { id } 			= useParams(); 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);

	//--GLOBAL_VARS--/
	const [uHTMLError500,setHTMLError500]		= React.useState(null);

	const uCustomerID 							= id;
	const [uCustomercaption,setCustomercaption]	= React.useState("");
	const [uCustomerSaldoDP,setCustomerSaldoDP]	= React.useState(0);

	const [uInpStatustembak,setInpStatustembak]	= React.useState("REGULER");
	const [uInpTglrefund,setInpTglrefund]		= React.useState(UFunc.DbDate());
	const [uInpNilairefund,setInpNilairefund]	= React.useState(0);
	const [uInpKeterangan,setInpKeterangan]		= React.useState("");
	const [uInpKasID,setInpKasID]				= React.useState(0);
	const [uInpPaymentID,setInpPaymentID]			= React.useState(0);
	const [uInpPaymentcaption,setInpPaymentcaption]	= React.useState("");
	const [uInpPaymentket,setInpPaymentket]			= React.useState("");

	//---FORMFIELD--/
	const [uIsFormprocess,setFormprocess]		= React.useState(false);
	const ElBtnSimpan							= document.getElementById("btnDialogSimpan");
	//---END FORMFIELD--/

	const [uDatapaymentArr,setDatapaymentArr]		= React.useState([]);
	const [uIsLookupShow,setLookupShow]				= React.useState(false);
	const [uDialogHeader,setDialogHeader]			= React.useState("");
	const [uInpLookupKwd,setInpLookupKwd]			= React.useState("");
	const [uDatalookupArr,setDatalookupArr]			= React.useState([]);
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleLookup=()=>{setLookupShow(false)}
	const hdlKlikback=()=>{ uNavigate(-1); }
	const hdlKlikPilihLookup=(_IDITEM)=>{
		const vIdx = UFunc.getObjectIndeks(uDatalookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		const vCaptiontmp	= uDatalookupArr[vIdx].caption||"";
		setInpPaymentID(uDatalookupArr[vIdx].id||"0");
		setInpPaymentcaption(vCaptiontmp);

		hdlToggleLookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);

			const vElsFocus	= vCaptiontmp.toUpperCase()==="TUNAI" 
				? document.getElementById("inpketerangan")
				: document.getElementById("inppaymentket");

			vElsFocus && vElsFocus.focus();
		},350);
	}
	const hdlKlikLookuppayment=()=>{
		setInpPaymentcaption("");setInpPaymentID("0")
		setDatalookupArr(uDatapaymentArr);
		setDialogHeader((uBahasaObj.word_pilih||"word_pilih")+": "+(uBahasaObj.caption_paymentmethod||"caption_paymentmethod"));
		setLookupShow(true);
	}
	const hdlKlikSimpan = () => {
		if(uIsFormprocess) return;

		//---VALIDASI--/
		if((parseFloat(uInpNilairefund)||0) <= 0) {
			document.getElementById("inpnilairefund").focus();
			showToast((uBahasaObj.caption_nilairefund||"caption_nilairefund")+" "+
				(uBahasaObj.caption_invalid||"caption_invalid")+".."+
				"<br/>Kemungkinan Anda memasukkan Nilai Selain Angka."+
				"<br/>(Nilai = "+uInpNilairefund+")"+
				"<br/><br/><b>Mohon Ulangi untuk Menginput ANGKA dengan BENAR</b> !"+
				"");
			return;
		}//-*/
		if((parseFloat(uInpNilairefund)||0) > (parseFloat(uCustomerSaldoDP)||0)) {
			document.getElementById("inpnilairefund").focus();
			showToast((uBahasaObj.pesan_jmlrefundexceeded||"pesan_jmlrefundexceeded")+"");
			return;
		}
		if(parseInt(uInpPaymentID) <= 0) {
			document.getElementById("uInpPaymentcaption").focus();
			showToast((uBahasaObj.caption_paymentmethod||"caption_paymentmethod")+" "+uBahasaObj.caption_mustchoose+"..");
			return;
		}
		//---END VALIDASI--/

		//showToast("uInpNilairefund => "+parseFloat(uInpNilairefund)); return
		apiProsessimpan();
	}
	//---END HANDLE--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		return (
		<CForm className="mx-lg-5 px-lg-5">

		{uTokenObj.userhak === "FREEROLE" && (
		<>
		<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
			</CCol>
			<CCol xs="8" lg="4">
				<CFormSelect size="sm"
						onChange={(e)=>setInpStatustembak(e.target.value)}
						value={uInpStatustembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
				</CFormSelect>

				<div className="classpetunjuk">
					<small>*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL</small>
				</div>
			</CCol>
		</CRow>

		<div className="mx-0 my-3 classborderbottom"/>
		</>
		)}

		<CRow className="my-2">
			<CCol xs="4" className="text-info text-md-end">{uBahasaObj.caption_tglrefund||"caption_tglrefund"}</CCol>
			<CCol xs="8" md="5" lg="4" className="">
			<CFormInput size="sm"
				type="date"
				value={uInpTglrefund}
				onChange={(e)=>setInpTglrefund(e.target.value)}
				id="inptglrefund"/>
			</CCol>
		</CRow>


		<CRow className="my-2">
			<CCol xs="4" className="text-info text-md-end">{uBahasaObj.caption_nilairefund||"caption_nilairefund"}</CCol>
			<CCol xs="8" md="5" lg="4" className="">
				<NumberFormat 
					value={uInpNilairefund || 0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => setInpNilairefund(values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>setInpNilairefund((e.target.value))}
							onFocus={(e)=>e.target.select()}
						id="inpnilairefund"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-info text-md-end">
				<span className="d-md-none"><small>{uBahasaObj.caption_metoderefund||"Metode Refund"}</small></span>
				<span className="d-none d-md-block">{uBahasaObj.caption_metoderefund||"Metode Refund"}</span>
			</CCol>
			<CCol xs="8" md="5" lg="4" className="">
			<CInputGroup size="sm">
			<CFormInput size="sm"
				placeholder={"--"+(uBahasaObj.word_pilih)+" "+(uBahasaObj.caption_metoderefund||"Metode Refund")+".."}
				readOnly={true}
				value={uInpPaymentcaption}
				onClick={()=>hdlKlikLookuppayment()} 
				id="inppaymentcaption"/>
				<CButton size="sm"
					color="dark"
					onClick={()=>hdlKlikLookuppayment()} 
					id="btnpaymentlookup">
				<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
			</CInputGroup>
			</CCol>
		</CRow>

		{((uInpPaymentcaption||"")!=="" && (uInpPaymentcaption||"").toUpperCase()!=="TUNAI"
		) && (
		<CRow className="my-2">
			<CCol md="4" className="text-md-end d-none d-md-block">&nbsp;</CCol>
			<CCol md="8" className="">
			<CFormInput size="sm"
				maxLength={255}
				placeholder={"--"+(uBahasaObj.caption_ketnontunai||"caption_ketnontunai")}
				value={uInpPaymentket}
				onChange={(e)=>setInpPaymentket(e.target.value)}
				id="inppaymentket"/>
			</CCol>
		</CRow>
		)}

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol md="4" className="text-md-end">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
			<CCol md="8" className="">
			<CFormInput size="sm"
				maxLength={255}
				value={uInpKeterangan}
				onChange={(e)=>setInpKeterangan(e.target.value)}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
				id="inpketerangan"/>
			</CCol>
		</CRow>
		</CForm>
		)
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiInitawal = () => {
		uFuncname = apiInitawal.name;
		setHTMLError500();
		setLoading(true);
		setFormprocess(true);
		setDatapaymentArr([])

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiInitawal) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_customerid: uCustomerID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_titipandp/ld_initrefund";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("(Titipandprefund - _FUNC) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setFormprocess(false);
				const vTmpObj	= JSON.parse(output_string.dataobject||"{}");
				setCustomercaption(vTmpObj.caption||"{UNDF}");
				setCustomerSaldoDP(vTmpObj.saldo_dp||0);

				setDatapaymentArr(JSON.parse(output_string.paymentlist||"[]"))
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Titipandprefund - apiInitawal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Titipandprefund - apiInitawal) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		/*//--TESTING_FRONTEND--/
		hdlKlikback();
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_statusjurnal : uInpStatustembak,
			send_tglrefund : uInpTglrefund,
			send_customerid : uCustomerID,
			send_customercaption : uCustomercaption,
			send_nilairefund : (uInpNilairefund),
			send_keterangan : uInpKeterangan,
			send_paymentid: uInpPaymentID,
			send_paymentcaption: uInpPaymentcaption,
			send_paymentket: uInpPaymentket,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_titipandp/pr_refund";

		setLoading(true);
		setFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setFormprocess(false);
			if(output_string.sukses) {
				hdlKlikback();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Titipandprefund - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setFormprocess(false);
			setLoading(false);
			console.log("(Titipandprefund - apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		if((parseInt(uCustomerID||0) <= 0)
			|| (!uIsEOMExecuted)
			) { hdlKlikback(); return; }
    	
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiInitawal();

    	if(!isMobile) {
    		document.getElementById("inpnilairefund") &&
    			document.getElementById("inpnilairefund").focus();
    	}

    	return ()=>{
			hdlToggleLookup()
    		setDatalookupArr([])
    		setDatapaymentArr([])
    	}
	},[]);
	React.useEffect(()=>{
		if(!uIsLookupShow) {
			setDatalookupArr([]); setInpLookupKwd("")
			return
		}
	},[uIsLookupShow]);
	React.useEffect(()=>{
		if((parseFloat(uInpNilairefund)) <= 0)
			{setInpNilairefund(-1 * (parseFloat(uInpNilairefund)||0)); return }
	},[uInpNilairefund]);
	//---END EFFECT--/

	//console.log("(Titipandprefund) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="classheadermain" style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() => hdlKlikback()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">
					{uBahasaObj.caption_refunddp||"caption_refunddp"}
				</small>
			</CHeaderBrand>

			<CHeaderNav>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
			<CContainer xxl className="p-0">
				{(uHTMLError500) ? (
					<MyPage500 content={uHTMLError500}
						isWithrefresh={true}
						hdlReset={()=>apiInitawal()}
						{...props}/>
				) : (
				<CCard className="classcardbg mx-lg-5">
					<CCardHeader className="d-flex justify-content-between align-items-middle">
						<div className="">
							<big className="text-primary"><strong>{uCustomercaption}</strong></big>
						</div>

						<div className="">
						<small>
							<span className="d-none d-md-inline">{uBahasaObj.caption_saldodp||"caption_saldodp"}: </span>
							<b className="text-success">Rp{UFunc.formatAngka(uCustomerSaldoDP)}</b>
						</small>
						</div>
					</CCardHeader>

					<CCardBody>{contentMain()}</CCardBody>
					
					<CCardFooter className="d-flex justify-content-center">
					<MyButton
						iconname="cilCheckAlt"
						disabled={uIsFormprocess}
						onClick={()=>hdlKlikSimpan()}
						id="btnSimpan">{uBahasaObj.word_simpan||"word_simpan"} [F9]</MyButton>
					</CCardFooter>
				</CCard>
				)}
			</CContainer>
		</div>
		</div>

		<MyDialoglookup
			show={uIsLookupShow}
			toggle={()=>hdlToggleLookup()}
			dialogHeader={uDialogHeader||"dialogHeader"}
			inputValue={uInpLookupKwd}
			dataItemArr={uDatalookupArr} 
			onKlikPilihItem={hdlKlikPilihLookup}
			id="iddialoglookup"/>
		</>
	) 
}	

export default Titipandprefund