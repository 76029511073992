import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CFooter,
	CForm,
	CRow,
	CCol,
	CFormInput,
	CFormSelect,
	CButton,
	CInputGroup,
	CInputGroupText,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyDialoglookup,
	MyPage500,
	MyProfileDetil,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     = require('../../../package.json');
const Kastransferform = (props) => {
	const uComponentname	= Kastransferform.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif,
	} = props; 

	let uFuncname;
	const uNavigate 		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	//const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uKasGLArrObj,setKasGLArrObj]		= React.useState([]);

	const [uInpStatustembak,setInpStatustembak]= React.useState("REGULER");
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate());
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");
	const [uInpNoref,setInpNoref]			= React.useState("");
	const [uInpNilai,setInpNilai]			= React.useState(0);
	const [uInpKasasalID,setInpKasasalID]	= React.useState("0");
	const [uInpKasasalcaption,setInpKasasalcaption]	= React.useState("");
	const [uInpKastujuanID,setInpKastujuanID]= React.useState("0");
	const [uInpKastujuancaption,setInpKastujuancaption]	= React.useState("");
	const [uIsFormprocess,setIsFormprocess]	= React.useState(false);

	//---LOOKUP--/
	const [uIsLookupShow,setLookupShow]				= React.useState(false);
	const [uLookupheader,setLookupheader]			= React.useState("");
	const [uInpLookupKwd,setInpLookupKwd]			= React.useState("");
	const [uLookupDataArr,setLookupDataArr]			= React.useState([]);
	const [uLookupjenis,setLookupjenis]				= React.useState("");
	//---END LOOKUP--/

	//---HANDEL--/
	const hdlToggleLookup=() => { setLookupShow(false) }
	const hdlKlikPilihLookup = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM||0);
		if(_IDITEM <= 0) return;

		const vObjidx 	= UFunc.getObjectIndeks(uLookupDataArr,_IDITEM);
		const vValue	= uLookupDataArr[vObjidx].id||"0";
		const vCaption	= uLookupDataArr[vObjidx].caption||"";
		let vElstmp; 
		if(uLookupjenis==="ASAL") {
			vElstmp = document.getElementById("inpkastujuancaption")
			if(vValue.toString() === uInpKastujuanID.toString()) {
				showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+ 
					" - "+(uBahasaObj.caption_tujuankas||"caption_tujuankas")+
					" "+(uBahasaObj.caption_cannotsame||"caption_cannotsame")+
					"..");
				return;
			}
			setInpKasasalID(vValue);
			setInpKasasalcaption(vCaption);
		} else if(uLookupjenis==="TUJUAN") {
			vElstmp = document.getElementById("inpket")
			if(vValue.toString() === uInpKasasalID.toString()) {
				showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+ 
					" - "+(uBahasaObj.caption_tujuankas||"caption_tujuankas")+
					" "+(uBahasaObj.caption_cannotsame||"caption_cannotsame")+
					"..");
				return;
			}
			setInpKastujuanID(vValue);
			setInpKastujuancaption(vCaption);
		}
		hdlToggleLookup();

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElnextfocus = vElstmp;
			vElnextfocus && vElnextfocus.focus();
		},350)
	}
	const hdlKlikKasasallookup = () => {
		setLookupjenis("ASAL")
		setLookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_asalkas||"").toUpperCase());
		setLookupShow(true)
	}
	const hdlKlikKastujuanlookup = () => {
		setLookupjenis("TUJUAN")
		setLookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_tujuankas||"").toUpperCase());
		setLookupShow(true)
	}
	const hdlKlikSimpan = async() => {
		//---VALIDASI--/
		let vELfocus 	= document.getElementById("inpstatustembak");
		if(uInpStatustembak!=="TEMBAK" && uInpStatustembak!=="REGULER") {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.caption_statustembakjurnal||"Status Jurnal")+" "+uBahasaObj.caption_invalid+"..");
			return;	
		}
		vELfocus 	= document.getElementById("inptanggal");
		if(UFunc.isEmpty(uInpTanggal)) {
			vELfocus && vELfocus.focus();
			showToast(uBahasaObj.caption_tanggal+" "+uBahasaObj.caption_invalid+"..");
			return;
		} else {
			const vTanggalDt 	= new Date(uInpTanggal);

			const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
			if(vDateBeyond) {
				vELfocus && vELfocus.focus();
				showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
				return;
			}
			const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
			if(vDateExceededBl) {
				vELfocus && vELfocus.focus();
				showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
				return;
			}
			//alert("("+uComponentname+") "+cFuncname+" vDateExceededBl = "+vDateExceededBl); return;
		}
		vELfocus 	= document.getElementById("inpnilai");
		if((parseFloat(uInpNilai)||0) <= 0) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.word_nilai||"word_nilai")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		vELfocus 	= document.getElementById("inpasalkasid");
		if((parseInt(uInpKasasalID)||0) === 0) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		vELfocus 	= document.getElementById("inptujuankasid");
		if((parseInt(uInpKastujuanID)||0) === 0) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.caption_tujuankas||"caption_tujuankas")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		vELfocus 	= document.getElementById("inpasalkasid");
		if((parseInt(uInpKasasalID)||0) === (parseInt(uInpKastujuanID)||0)) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+ 
				" - "+(uBahasaObj.caption_tujuankas||"caption_tujuankas")+
				" "+(uBahasaObj.caption_cannotsame||"caption_cannotsame")+
				"..");
			return;
		}
		vELfocus 	= document.getElementById("inpket");
		if((uInpKeterangan||"").trim() === "") {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.word_keterangan||"word_keterangan")+ 
				" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+
				"..");
			return;
		}
		//---END VALIDASI--/

		setIsFormprocess(true);
		if(await Konfirm("Apakah Anda Yakin ?")) {
			apiProsessimpan();
			return;
		}

		setIsFormprocess(false);
	}
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			_EV.preventDefault();
			_EV.stopPropagation();

			const vEltombol = document.getElementById("btnSimpan");
			vEltombol && (vEltombol.click());
		}
	}
	//---END HANDEL--/

	//--API--/
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		/*//--TESTING_FRONTEND--//
		initFormAwal();
		showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
		if(!isMobile) document.getElementById("inptanggal").focus();
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_statustembak : uInpStatustembak,
			send_tanggal : uInpTanggal,
			send_kasasalid : uInpKasasalID,
			send_kastujuanid : uInpKastujuanID,
			send_keterangan : uInpKeterangan,
			send_noref : uInpNoref,
			send_nilai: uInpNilai,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kastransfer/pr_simpan";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setIsFormprocess(false);

			if(output_string.sukses) {
				let vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				if(output_string.pesankhusus) vPesan = output_string.pesankhusus;
				showToast(vPesan,"SUKSES");
				initFormAwal();

				if(!isMobile) document.getElementById("inptanggal").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setIsFormprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//--INIT--/
	const initFormAwal=()=>{
		setInpStatustembak("REGULER");
		setInpTanggal(UFunc.DbDate());
		setInpKasasalID(0);
		setInpKastujuanID(0);
		setInpKasasalcaption("");
		setInpKastujuancaption("");
		setInpKeterangan("");
		setInpNoref("");
		setInpNilai(0);
	}
	const contentMainform=()=>{
		if(uHTMLError500) return "";

		return (
			<CCard className="mx-lg-5 classcardbg">
			<CCardHeader>
				<small>{uBahasaObj.caption_forminput||"caption_forminput"}</small>
			</CCardHeader>
			<CCardBody>
			
			<CForm>
			{uTokenObj.userhak === "FREEROLE" && (
			<>
			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
				</CCol>
				<CCol xs="8" md="4" className="text-end">
					<CFormSelect size="sm"
						onChange={(e)=>setInpStatustembak(e.target.value)}
						value={uInpStatustembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
					</CFormSelect>
				</CCol>
				<CCol md="4" className="d-none d-md-block"/>
				<CCol xs="4" className=""/>
				<CCol className="classpetunjuk">
					*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL
				</CCol>
			</CRow>
			
			<div className="mx-0 my-3 classborderbottom"/>
			</>
			)}
			
			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_tanggal||"word_tanggal"}
				</CCol>
				<CCol xs="8" md="4" className="text-end">
					<CFormInput size="sm"
						type="date"
						onChange={(e)=>setInpTanggal(e.target.value)}
						value={uInpTanggal}
						name="inptanggal"
						id="inptanggal"/>
				</CCol>
			</CRow>

			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_nilai||"word_nilai"}
				</CCol>
				<CCol xs="8" md="4" className="text-end">
					<NumberFormat 
						value={uInpNilai}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpNilai(values.value)}
						renderText={values => (
							<CFormInput 
								value={values}
								size="sm"
								className="text-end"
								onChange={(e) => setInpNilai(e.target.value)}
								onFocus={(e)=>e.target.select()}
							id="inpnilai"/>
						)} 
						prefix={isMobile?"":"Rp"}/>
				</CCol>
			</CRow>
			
			<div className="mx-0 my-3 classborderbottom"/>

			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_asalkas||"caption_asalkas"}
				</CCol>
				<CCol xs="8" md="4" className="">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						readOnly={true}
						value={uInpKasasalcaption}
						onClick={()=>hdlKlikKasasallookup()}
						placeholder={"--"+((uBahasaObj.word_pilih)+" "+(uBahasaObj.caption_asalkas)).toUpperCase()+".."}
						className="classbgdisabled"
						id="inpkasasalcaption"/>
					<CButton size="sm"
						color="dark"
						className="border"
						onClick={()=>hdlKlikKasasallookup()}
						id="btnkasasalloookup">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
					</CInputGroup>
				</CCol>
			</CRow>
			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_tujuankas||"caption_tujuankas"}
				</CCol>
				<CCol xs="8" md="4" className="">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						readOnly={true}
						value={uInpKastujuancaption}
						onClick={()=>hdlKlikKastujuanlookup()}
						placeholder={"--"+((uBahasaObj.word_pilih)+" "+(uBahasaObj.caption_tujuankas)).toUpperCase()+".."}
						className="classbgdisabled"
						id="inpkastujuancaption"/>
					<CButton size="sm"
						className="border"
						onClick={()=>hdlKlikKastujuanlookup()}
						color="dark"
						id="btnkastujuanloookup">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
					</CInputGroup>
				</CCol>
			</CRow>
			
			<div className="mx-0 my-3 classborderbottom"/>

			<CRow className="mx-0 my-2">
				<CCol md="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_keterangan||"word_keterangan"}
				</CCol>
				<CCol className="">
					<CFormInput maxLength="255" 
						size="sm"
						type="text"
						onChange={(e)=>setInpKeterangan(e.target.value)}
						value={uInpKeterangan}
						name="inpket"
						id="inpket"/>
				</CCol>
			</CRow>

			<div className="mx-0 my-3 classborderbottom d-md-none"/>

			<CRow className="m-0 my-2">
				<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.caption_nobukti||"caption_nobukti"}
				<span className="d-none d-md-inline">/{uBahasaObj.word_noref||"word_noref"}</span>
				</CCol>
				<CCol xs="8" md="4" className="">
					<CFormInput maxLength="50" 
						size="sm"
						className="text-uppercase"
						type="text"
						onChange={(e)=>setInpNoref(e.target.value)}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
						value={uInpNoref}
						name="inpnoref"
						id="inpnoref"/>
				</CCol>
			</CRow>
			</CForm>
			</CCardBody>
			</CCard>
		)
	}
	//--END INIT--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);
		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		setLookupShow(false);
		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		setKasGLArrObj(JSON.parse(localStorage.getItem("kasgllist")||"[]"));

		if(!isMobile) {
			const vElsTanggal = document.getElementById("inptanggal");
			vElsTanggal && vElsTanggal.focus();
		}
		return ()=>{ setKasGLArrObj([]); setLookupDataArr([]) }
	},[]);
	React.useEffect(()=>{
		if(uKasGLArrObj.length <= 0) return;

		let vTmpArr	= uKasGLArrObj.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}	
		})
		setLookupDataArr(vTmpArr)
	},[uKasGLArrObj]);
	//---END EFFECT---/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="classheadermain"
			style={{height:64}}>
			<CHeaderToggler className="px-0 me-1"
				onClick={() => {uNavigate(-1)}}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<span className="fw-bolder">Input Transfer Kas</span>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
			<CContainer xxl className="px-0">
			{(uHTMLError500) ? (
				<MyPage500 content={uHTMLError500} {...props}/>
			) : (<>{contentMainform()}</>)}
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
		<MyButton
			style={{paddingTop: 3,paddingBottom: 3}}
			centered={true}
			iconname="cilCheckAlt"
			disabled={uIsFormprocess}
			onClick={()=>hdlKlikSimpan()}
			id="btnSimpan">Simpan [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsLookupShow}
			toggle={()=>hdlToggleLookup()}
			dialogHeader={uLookupheader||"dialogHeader"}
			inputValue={uInpLookupKwd}
			dataItemArr={uLookupDataArr} 
			onKlikPilihItem={hdlKlikPilihLookup}
			id="iddialoglookup"/>
		</>
	)
}

export default Kastransferform;
