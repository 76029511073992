import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CCardHeader,
	CCard,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CFormTextarea,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { isMobile } from "react-device-detect";
import { 
	MyPagination,
	MyPage500,
	MyDialogform,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';

const pjson     = require('../../package.json');
const Supplier 	= (props) => {
	const uComponentname 	= Supplier.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;//-->hanya untuk API
	const uNavigate  			= useNavigate();
	const uDispatch				= useDispatch();
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel		=useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj				= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uEfekapp				= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 		= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus	= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData			= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uFirstload,setFirstload]			= React.useState("YA");

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpSuppliernama,setInpSuppliernama] 	= React.useState("");
	const [uInpTelphp,setInpTelphp]			= React.useState("");
	const [uInpAlamat,setInpAlamat]			= React.useState("");
	const [uInpFax,setInpFax]				= React.useState("");
	const [uInpNorek,setInpNorek]			= React.useState("");

	//---END VAR_GLOBALS--/

	//console.log("("+uComponentname+") uHeaderActionObj.isHeaderView: "+uHeaderActionObj.isHeaderView);
	//console.log("("+uComponentname+") uHandelView: "+uHandelView);


	//--DOM--/
	const [ElBtnSimpan,setElBtnSimpan]		= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata			= document.getElementById("idjmldata");
	//--END DOM--/

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowForm(false); }
	const hdlKlikSimpan=()=>{
		//--VALIDASI_SIMPAN--/
		if(uInpSuppliernama.trim() === "") {
			showToast((uBahasaObj.caption_namasupplier||"caption_namasupplier")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
			document.getElementById("inpsuppliernama").focus();
			return;
		}
		//--END VALIDASI_SIMPAN--/

		apiProses();
	}
	const hdlKlikEdit = async(_IDTABEL) => {

		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setInpSuppliernama(uDatamainArr[vObjdx].nama);
		setInpTelphp(uDatamainArr[vObjdx].telp_hp||"");
		setInpFax(uDatamainArr[vObjdx].fax||"");
		setInpAlamat(UFunc.br2nl(uDatamainArr[vObjdx].alamat||""));
		setInpNorek(uDatamainArr[vObjdx].no_rek||"");
		setDialogHeader((uBahasaObj.caption_editdata||"caption_editdata")+": "+(uDatamainArr[vObjdx].nama || "").toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>"+uDatamainArr[vObjdx].nama.toUpperCase()+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiHapus(_IDTABEL);
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_supplier || "";
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} 

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initFormAwal=()=>{
		setTabelIDPilih(0);

		setInpTelphp("");
		setInpSuppliernama("");
		setInpAlamat("");
		setInpFax("");
		setInpNorek("");
	}
	//---END Init_LOAD--/

	//--CONTENT--/
	const loadTabelBody=()=>{
		if(uHTMLError500) return ""
		uFuncname	= loadTabelBody.name;

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= isMobile ? 0 : (vMin);
		let vIdxRow			= -1;
		let vCaptionUpper	= "";
		const vKeyword 		= uKeywordObj.kwd_supplier || "";
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataViewObj	= isMobile ? uDatamainArr.slice(0,(vMax)) : uDatamainArr.slice(vMin,(vMax));
		
		//console.log("("+uComponentname+"-"+uFuncname+")");

		return 
	}
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_supplier || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=> parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="4" className="text-start">{uBahasaObj.caption_namasupplier||"caption_namasupplier"}</CCol>
				<CCol md="4" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_telphp||"caption_telphp"}</CCol>
				<CCol md="2">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none classfontsmaller">
				<th width={27} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,telp_hp,kode,nama,fax,no_rek,alamat
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (nama||"").toUpperCase();

			const vSuppliernama	= vKeyword==="" ? (kode+" &middot; "+nama) 
				: (kode+" &middot; "+nama).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vTelphp= vKeyword==="" ? (telp_hp||"-") 
				: (telp_hp||"-").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vAlamat= vKeyword==="" ? (alamat||"") 
				: (alamat||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vFax= vKeyword==="" ? (fax||"") 
				: (fax||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vNorek= vKeyword==="" ? (no_rek||"") 
				: (no_rek||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
				
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td align="right" className="px-1 pe-0">{vNumber}.</td>
				<td className="px-1">
				<CRow className="mx-0">

				<CCol md="4" className="px-1 text-start d-flex justify-content-between">
					<div className="text-info">{UFunc.renderHTML(vSuppliernama)}</div>

					<CDropdown className="d-md-none">
					<CDropdownToggle caret={false} size="sm" className="p-0"
						color="transparent">
						<CIcon icon="cilOptions" className="classikoncoreui ms-0 me-2" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu className="px-0">
					<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
						<CIcon className="classikonedit ms-0 me-2 align-middle" height={25}/>
						{uBahasaObj.caption_editdata||"Edit Data"}
					</CDropdownItem>

					{uIsAllowedhapus && (
					<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
						<CIcon className="classikonhapus ms-0 me-2 align-middle" height={25}/>
						{uBahasaObj.caption_hapusdata||"Hapus Data"}
					</CDropdownItem>
					)}
					</CDropdownMenu>
					</CDropdown>
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="3" className="px-1 d-md-none text-start classfontsmaller">Alamat</CCol>
				<CCol xs="9" md="4" className="px-1 text-md-start">
					{((alamat||"")==="" && (fax||"")==="" && (no_rek||"")==="") ? (<>-</>)
					: (
					<>
						<small className="fst-italic d-block">{UFunc.renderHTML(vAlamat)}</small>
						{(fax||"")!=="" && (
						<small className="d-none d-md-block">Fax: {UFunc.renderHTML(vFax)}</small>
						)}
		
						{(no_rek||"")!=="" && (
						<small className="d-none d-md-block text-primary">No.Rek: {UFunc.renderHTML(vNorek)}</small>
						)}
					</>
					)}
				</CCol>

				{(no_rek||"")!=="" && (
				<>
				<CCol xs="5" className="px-1 d-md-none text-start classfontsmaller">No.Rek</CCol>
				<CCol xs="7" className="px-1 d-md-none text-primary classfontsmaller">{UFunc.renderHTML(vNorek)}</CCol>
				</>
				)}

				{(fax||"")!=="" && (
				<>
				<CCol xs="5" className="px-1 d-md-none text-start">Fax</CCol>
				<CCol xs="7" className="px-1 d-md-none">{UFunc.renderHTML(vFax)}</CCol>
				</>
				)}

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_telphp||"caption_telphp"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-center">
					<span className="text-success">{UFunc.renderHTML(vTelphp.toUpperCase())}</span>
				</CCol>
				
				<CCol md="2" className="text-center d-none d-md-block">
					<CTooltip content={"--"+(uBahasaObj.caption_editdata || "caption_editdata")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="classcursorpointer classikontabel classikonedit"/>
					</CTooltip>

					{uIsAllowedhapus && (
					<>
						<span className="d-md-none"> &middot; </span>
						<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
						<CLink 
							className={"classcursorpointer classikontabel classikonhapus"}
							onClick={()=>hdlKlikHapus(id)} />
						</CTooltip>
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform=()=>{
		if(!isShowForm) return ""

		return (
			<>
			<CForm className="mx-auto mx-lg-5">
			<CRow className="my-2">
				<CCol md="4" className="text-md-end">
					{uBahasaObj.caption_namasupplier||"caption_namasupplier"}
				</CCol>
				<CCol md="8">
					<CFormInput maxLength="100"
						type="text"
						size="sm"
						className="form-control text-capitalize"
						value={uInpSuppliernama}
						onChange={(e) => setInpSuppliernama(e.target.value)}
						id="inpsuppliernama"/>
				</CCol>
			</CRow>
			<CRow className="my-2">
				<CCol md="4" className="text-md-end">
					{uBahasaObj.caption_telphp||"caption_telphp"}
				</CCol>
				<CCol md="8">
				<CFormInput maxLength="50"
					type="text"
					size="sm"
					className="form-control text-uppercase"
					value={uInpTelphp}
					onChange={(e) => setInpTelphp(e.target.value)}
					id="inptelphp" />
				</CCol>
			</CRow>
			<CRow className="my-2">
				<CCol md="4" className="text-md-end">
					{uBahasaObj.word_alamat||"word_alamat"}
				</CCol>
				<CCol md="8">
					<CFormTextarea
						size="sm"
						className="form-control"
						value={uInpAlamat}
						onChange={(e) => setInpAlamat(e.target.value)}
						id="inpalamat" />
					<div className="classpetunjuk">*) {uBahasaObj.petunjuk_textarea||"petunjuk_textarea"}</div>
				</CCol>
			</CRow>
			<CRow className="my-2">
				<CCol md="4" className="text-md-end">
					{uBahasaObj.word_fax||"word_fax"}
				</CCol>
				<CCol md="6" lg="4">
				<CFormInput maxLength="50"
					type="text"
					size="sm"
					className="form-control text-uppercase"
					value={uInpFax}
					onChange={(e) => setInpFax(e.target.value)}
					id="inpfax" />
				</CCol>
			</CRow>
			<CRow className="my-2">
				<CCol md="4" className="text-md-end">
					{uBahasaObj.word_norek||"word_norek"}
				</CCol>
				<CCol md="8">
					<CFormInput maxLength="255"
						type="text"
						size="sm"
						className="form-control"
						value={uInpNorek}
						onChange={(e) => setInpNorek(e.target.value)}
						onKeyDown={(e) => UFunc.hdlKeyDown(e,"btnDialogSimpan")}
						id="inpnorek" />
					<div className="classpetunjuk">*) {uBahasaObj.petunjuk_norek||"petunjuk_norek"}</div>
				</CCol>
			</CRow>
			</CForm>
			</>
		)
	}
	//--CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname 	= apiLoaddata.name;
		_PAGE		= parseInt(_PAGE)||1;
		setHTMLError500();

		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_supplier || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE,
			send_maxdata	: uMaxData,
			send_firstload	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_supplier/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string));
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				vLoadArr.map(vItems=>vItems.pg = _PAGE);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProses = () => {
		uFuncname = apiProses.name;

		/*//--TESTING_FRONTEND--//
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_suppliernama : uInpSuppliernama,
			send_telphp : uInpTelphp,
			send_alamat : uInpAlamat,
			send_fax : uInpFax,
			send_norek : uInpNorek,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_supplier/pr_simpan";

		setLoading(true);
		ElBtnSimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			ElBtnSimpan.disabled = false;

			if(output_string.sukses) {
				let vDatamainArrNew;
				if(uTabelIDPilih === 0) {
					const vTmpObject	= {
						id 		: output_string.tabelid || "255",
						kode 	: UFunc.leadZero((output_string.tabelid || "255"),5),
						telp_hp : (uInpTelphp||"").trim(),
						nama 	: UFunc.capitalize((uInpSuppliernama||"").trim()),
						fax 	: (uInpFax||"").trim(),
						alamat 	: UFunc.nl2br(UFunc.capitalize((uInpAlamat||"").trim())),
						no_rek 	: (uInpNorek||"").trim(),				
					}
					vDatamainArrNew = [vTmpObject].concat(uDatamainArr);
				} else {
					vDatamainArrNew = uDatamainArr;
					const vObjdx				= UFunc.getObjectIndeks(vDatamainArrNew,uTabelIDPilih);
					vDatamainArrNew[vObjdx].nama 	= UFunc.capitalize(uInpSuppliernama.trim());
					vDatamainArrNew[vObjdx].telp_hp = (uInpTelphp||"").trim();
					vDatamainArrNew[vObjdx].fax 	= (uInpFax||"").trim();
					vDatamainArrNew[vObjdx].alamat 	= UFunc.nl2br(UFunc.capitalize((uInpAlamat||"").trim()));
					vDatamainArrNew[vObjdx].no_rek 	= (uInpNorek||"").trim();
				}

				setDatamainArr(vDatamainArrNew);
				initFormAwal();

				if(uTabelIDPilih > 0) {
					setEfekview(!uEfekview);
					hdlToggleForm();
				}  else {
					setTabelRowSelect(-1);
					setJmlData((parseInt(vDatamainArrNew.length)||0));
					showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
					setPageActive(1);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				ElBtnSimpan.disabled = true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			ElBtnSimpan.disabled = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiHapus = (_IDTABEL) => {
		uFuncname 	= apiHapus.name;
		_IDTABEL 	= parseInt(_IDTABEL) || 0;
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_supplier/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return ()=>{ 
			uDispatch({type: "set", gInitHeaderAction: {}});
			setShowForm(false);
    		setDatamainArr([]);
			setJmlData(0);
			setTabelIDPilih(0);
			setTabelRowSelect(-1);
			setPageActive(1);
			setFirstload("YA");
    	}
	},[]);
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;

		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;

		setDialogHeader((uBahasaObj.word_tambah||"word_tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setShowForm(true);
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return; }
		//console.log("("+uComponentname+"[isShowForm]) isShowForm 1: "+isShowForm);

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnSimpan(document.getElementById("btnDialogSimpan"));

			const vElFocus = document.getElementById("inpsuppliernama");
			vElFocus && vElFocus.focus();
		},350);
	},[isShowForm]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj.userinit)) { prosesExpired(); return; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>);

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_supplier || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0 px-lg-5">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikSimpan()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={contentDlgform()}/>
		<iframe id="ifmcontentstoprint" className="d-none" />
		</>
	)
}
	
export default Supplier;
