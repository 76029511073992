import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CHeaderBrand,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormTextarea,CInputGroup,CInputGroupText,
	CFormSelect,CFormCheck,CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import NumberFormat from 'react-number-format';
import {
	MyButton,
	MyDialoglookup,
	MyProfileDetil,
	MyPage500,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc,cKeycodebypass } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Ruparupaform = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	const { id } 			= useParams(); 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uIsEOMExecuted			= useSelector(state => state.gIsEOMExecuted);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uTimeelapsed,setTimeelapsed]		= React.useState(0); 

	const [uInpKeyword,setInpKeyword]		= React.useState("");
	const [uDataformObj,setDataformObj]		= React.useState({});
	const [uDataaktivaArr,setDataaktivaArr]	= React.useState([]);
	const [uDatapasivaArr,setDatapasivaArr]	= React.useState([]);
	const [uISFormupdated,setFormupdated]	= React.useState(false);
	const [uJmlData,setJmlData]				= React.useState(0);
	const uDataGLKasArr						= JSON.parse(localStorage.getItem("kasgllist")||"[]");

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false)
	const [uDlglookupObj,setDlglookupObj]		= React.useState({})
	const uDataGLArr						= uDataformObj.jenis==="RRAKAS"
		? (uDataaktivaArr||[]) : (uDatapasivaArr||[]);
	const uDatalookupArr					= [...uDataGLArr.map(vItems=>
		({id:vItems.value,caption:vItems.caption}) ),
		...uDataGLKasArr.map(vItems=>
			({id:vItems.value,caption:vItems.caption}) )];
	//--END DLG_LOOKUP--/

	//--DOM--/
	const uELBtnsimpanmain		= document.getElementById("btnSimpan");
	//--END DOM--/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {
		//console.log("(Ruparupaform - hdlKDownDocument) _EV.keyCode = "+_EV.keyCode)
		if(_EV.which === 120) { 
			_EV.preventDefault();
			_EV.stopPropagation();

			uELBtnsimpanmain && uELBtnsimpanmain.click(); 
			return
		}
	}
	const hdlKlikReset = async() => {
		if(uISFormupdated) {
			if(await Konfirm(
				"<b>Field Form Sudah Ada yang Terisi</b>.."+
				"<br/>Data Anda Dibatalkan DAN Tidak Akan Tersimpan."+
				"<br/><br/>Apakah Anda Yakin ?"+
				"")) setDataformObj({});
			return;
		}
		apiLoadinit();
	}
	const hdlKlikBack = async() => {
		if(uISFormupdated) {
			if(await Konfirm(
				"<b>Field Form Sudah Ada yang Terisi</b>.."+
				"<br/>Data Anda Tidak Akan Tersimpan, dan Tidak Dapat Dipanggil Kembali."+
				"<br/><br/>Apakah Anda Yakin ?"+
				"")) uNavigate(-1);
			return;
		}

		uNavigate(-1);
	}

	const hdlKlikGLDebetlookup = () => {
		uDlglookupObj.data_arr	= uDatalookupArr;
		uDlglookupObj.headers	= "Lookup: Debet RRA-RRP";
		uDlglookupObj.inputvalue= uDataformObj.debet_caption||"";
		uDlglookupObj.jenis 	= "DEBET";
		setDlglookupshow(true);
	}
	const hdlKDownGLDebetcaption = (_EV) => {
		if(!cKeycodebypass.includes(_EV.keyCode)) {
			uDataformObj.debet_id 		= undefined;
		}

		if(_EV.keyCode !== 13) { setEfekview(!uEfekview); return; }
		if((uDataformObj.debet_caption||"").trim()==="") { setEfekview(!uEfekview); return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatalookupArr.filter((vItems)=>
			(vItems.caption||"").toUpperCase().includes((uDataformObj.debet_caption||"").toUpperCase())
		);
		
		//console.log("vArrCariMaster =>"+JSON.stringify(vArrCariMaster));
		if (vArrCariMaster.length === 1) {
			uDataformObj.debet_id		= vArrCariMaster[0].id;
			uDataformObj.debet_caption	= vArrCariMaster[0].caption;
			setEfekview(!uEfekview);

			let vElfocus = document.getElementById("inpglkreditcaption");

			let vTimeout = setTimeout(function(){
				clearTimeout(vTimeout);
				vElfocus && vElfocus.focus();
			},350);

			return;
		} 

		hdlKlikGLDebetlookup();
	}
	const hdlKlikGLKreditlookup = () => {
		uDlglookupObj.data_arr	= uDatalookupArr;
		uDlglookupObj.headers	= "Lookup: Kredit RRA-RRP";
		uDlglookupObj.inputvalue= uDataformObj.kredit_caption||"";
		uDlglookupObj.jenis 	= "KREDIT";
		setDlglookupshow(true);
	}
	const hdlKDownGLKreditcaption = (_EV) => {
		if(!cKeycodebypass.includes(_EV.keyCode)) {
			uDataformObj.kredit_id 		= undefined;
		}

		if(_EV.keyCode !== 13) { setEfekview(!uEfekview); return; }
		if((uDataformObj.kredit_caption||"").trim()==="") { setEfekview(!uEfekview); return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatalookupArr.filter((vItems)=>
			(vItems.caption||"").toUpperCase().includes((uDataformObj.kredit_caption||"").toUpperCase())
		);
		
		//console.log("vArrCariMaster =>"+JSON.stringify(vArrCariMaster));
		if (vArrCariMaster.length === 1) {
			uDataformObj.kredit_id		= vArrCariMaster[0].id;
			uDataformObj.kredit_caption	= vArrCariMaster[0].caption;
			setEfekview(!uEfekview);

			let vElfocus = document.getElementById("inpnilai");

			let vTimeout = setTimeout(function(){
				clearTimeout(vTimeout);
				vElfocus && vElfocus.focus();
			},350);

			return;
		} 

		hdlKlikGLKreditlookup();
	}

	const hdlKlikDlglookuppilih = (_IDITEM) => {
		const vObjidx = UFunc.getObjectIndeks(uDatalookupArr,_IDITEM);
		if(vObjidx < 0) return;

		let vElfocus;
		if(uDlglookupObj.jenis === "DEBET") {
			vElfocus = document.getElementById("inpglkreditcaption");
			uDataformObj.debet_id		= parseInt(uDatalookupArr[vObjidx].id)||0;
			uDataformObj.debet_caption	= (uDatalookupArr[vObjidx].caption);
		} else if(uDlglookupObj.jenis === "KREDIT") {
			vElfocus = document.getElementById("inpnilai");
			uDataformObj.kredit_id		= parseInt(uDatalookupArr[vObjidx].id)||0;
			uDataformObj.kredit_caption	= (uDatalookupArr[vObjidx].caption);
		}

		setDlglookupshow(false)
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElfocus && vElfocus.focus();
		},350)
	}
	const hdlKlikSimpanmain = async() => {
		let vElfocus = document.getElementById("inptanggal");
		if(uDataformObj.tanggal === "") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Transaksi Tidak Valid !"); return
		}
		if(UFunc.DateIsBeyond(UFunc.toDate(uDataformObj.tanggal))) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Transaksi Melewati Tanggal Hari Ini !"); return
		}
		vElfocus = document.getElementById("inpgldebetcaption");
		if((parseInt(uDataformObj.debet_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Akun GL Debet Harus Dipilih !"); return
		}
		vElfocus = document.getElementById("inpglkreditcaption");
		if((parseInt(uDataformObj.kredit_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Akun GL Kredit Harus Dipilih !"); return
		}
		if((parseInt(uDataformObj.kredit_id)||0) === (parseInt(uDataformObj.debet_id)||0)) {
			vElfocus && vElfocus.focus();
			showToast("Akun GL Debet dan Kredit Tidak Boleh Sama !"); return
		}
		vElfocus = document.getElementById("inpnilai");
		if((parseFloat(uDataformObj.nilai)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("NILAI Transaksi Tidak Valid !"+
				"<br/><b>Kemungkinan Anda memasukkan Nilai Selain Angka.</b>"+
				"<br/>(Nilai = "+uDataformObj.nilai+")"+
				"<br/><br/>Mohon Ulangi untuk Menginput ANGKA dengan BENAR !"+
				""); return
		}
		vElfocus = document.getElementById("inpket");
		if((uDataformObj.keterangan||"").trim().length < 3 ) {
			vElfocus && vElfocus.focus();
			showToast("Keterangan Harus Diisi.."+
				"<br/><b>Mohon Diisi Dengan BENAR dan Sesuai</b> !"+
				""); return
		}

		uELBtnsimpanmain && (uELBtnsimpanmain.disabled=true);
		if(await Konfirm(
			"<b>Simpan Transaksi "+uDataformObj.jenis+"</b>"+
			"<div class='my-3 classborderbottom'></div>"+
			"<div class='d-flex justify-content-between my-2 mx-3'>"+
				"<div>Tanggal</div>"+
				"<div class='fw-bolder'>"+UFunc.TglAngka(uDataformObj.tanggal)+"</div>"+
			"</div>"+
			"<div class='d-flex justify-content-between my-2 mx-3'>"+
				"<div>Akun DEBET</div>"+
				"<div class='fw-bolder'>"+(uDataformObj.debet_caption)+"</div>"+
			"</div>"+
			"<div class='d-flex justify-content-between my-2 mx-3'>"+
				"<div>Akun KREDIT</div>"+
				"<div class='fw-bolder'>"+(uDataformObj.kredit_caption)+"</div>"+
			"</div>"+
			"<div class='d-flex justify-content-between my-2 mx-3'>"+
				"<div>Nilai Transaksi (Rp)</div>"+
				"<div class='fw-bolder text-success'>"+UFunc.formatAngka(uDataformObj.nilai)+"</div>"+
			"</div>"+
			"<div class='my-3'></div>"+
			"<div class='text-center'>Apakah Anda Yakin ?</div>"+
			"")) {
			apiProsessimpan(); return
		}
		uELBtnsimpanmain && (uELBtnsimpanmain.disabled=false);
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentFormmain = () => {
		if(uHTMLError500) return "";

		return (
		<CForm className="mx-lg-5 px-lg-5">
			{uTokenObj.userhak === "FREEROLE" && (
			<>
			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
				</CCol>
				<CCol xs="8" md="4" className="text-start">
					<CFormSelect size="sm"
						value={uDataformObj.status_jurnal||"REGULER"}
						onChange={(e)=>{uDataformObj.status_jurnal=(e.target.value);setEfekview(!uEfekview)}}
						id="inpstatusjurnal">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
					</CFormSelect>
				</CCol>
				<CCol md="4" className="d-none d-md-block"/>
				<CCol xs="4" className=""/>
				<CCol className="classpetunjuk">
					*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL
				</CCol>
			</CRow>
			
			<div className="my-3 classborderbottom"/>
			</>
			)}

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_jenisrrprra||"Jenis RRA-RRP"}
				</CCol>
				<CCol xs="8" className="text-start">
					<CFormCheck button={{ color: "secondary", variant: "outline" }}
						size="sm" inline={true} 
						type="radio" name="inpjenis"
						autoComplete="off"
						checked={uDataformObj.jenis==="RRAKAS"}
						onChange={(e)=>{
							if(e.target.checked) {
								uDataformObj.jenis = e.target.value;
								setEfekview(!uEfekview);
							}
						}}
						value="RRAKAS"
						label="Rupa-rupa AKTIVA"
						id="inpjenisrra"/>
					<div className="mx-md-1 my-1 my-md-0 d-md-inline"/>
					<CFormCheck button={{ color: "secondary", variant: "outline" }}
						size="sm" inline={true} 
						type="radio" name="inpjenis"
						autoComplete="off"
						checked={uDataformObj.jenis==="RRPKAS"}
						onChange={(e)=>{
							if(e.target.checked) {
								uDataformObj.jenis = e.target.value;
								setEfekview(!uEfekview);
							}
						}}
						value="RRPKAS"
						label="Rupa-rupa PASIVA"
						id="inpjenisrrp"/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_tanggal||"word_tanggal"}
				</CCol>
				<CCol xs="8" md="5" lg="4" className="text-start">
					<CFormInput size="sm"
						type="date"
						onChange={(e)=>{uDataformObj.tanggal=(e.target.value);setEfekview(!uEfekview)}}
						value={uDataformObj.tanggal}
						name="inptanggal"
						id="inptanggal"/>
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_akundebet||"Akun Debet"}
				</CCol>
				<CCol xs="8" md="5" lg="4" className="text-start">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						value={uDataformObj.debet_caption||""}
						onChange={(e)=>{uDataformObj.debet_caption=e.target.value;setEfekview(!uEfekview)}}
						onKeyDown={(e)=>hdlKDownGLDebetcaption(e)}
						placeholder={"--"+((uBahasaObj.word_pilih)+" "+(uBahasaObj.caption_akundebet||"Akun Debet")).toUpperCase()+".."}
						id="inpgldebetcaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>{
							uDataformObj.debet_id = undefined;
							uDataformObj.debet_caption = undefined;
							setEfekview(!uEfekview);
							hdlKlikGLDebetlookup();
						}}
						id="btngldebetlookup">
						<CIcon icon="cilSearch"/>
					</CButton>
					</CInputGroup>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-end text-info">
				{uBahasaObj.caption_akunkredit||"Akun Kredit"}
				</CCol>
				<CCol md="2" lg="1" className="d-none d-md-block"/>
				<CCol xs="8" md="5" lg="4" className="text-end">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						value={uDataformObj.kredit_caption||""}
						onChange={(e)=>{uDataformObj.kredit_caption=e.target.value;setEfekview(!uEfekview)}}
						onKeyDown={(e)=>hdlKDownGLKreditcaption(e)}
						placeholder={"--"+((uBahasaObj.word_pilih)+" "+(uBahasaObj.caption_akunkredit||"Akun Kredit")).toUpperCase()+".."}
						id="inpglkreditcaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>{
							uDataformObj.kredit_id = undefined;
							uDataformObj.kredit_caption = undefined;
							setEfekview(!uEfekview);
							hdlKlikGLKreditlookup();
						}}
						id="btnglkreditlookup">
						<CIcon icon="cilSearch"/>
					</CButton>
					</CInputGroup>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_nilai||"word_nilai"}
				</CCol>
				<CCol xs="8" md="5" lg="4" className="text-end">
					<NumberFormat 
						value={uDataformObj.nilai || 0}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						allowNegative={false}
						onValueChange={(values) => uDataformObj.nilai=(values.value)}
						renderText={values => (
							<CFormInput 
								value={values}
								size="sm"
								className="text-end"
								onChange={(e) =>{ uDataformObj.nilai=(e.target.value);setEfekview(!uEfekview) }}
								onFocus={(e)=>e.target.select()}
							id="inpnilai"/>
						)} 
						prefix={isMobile?"":"Rp"}/>
				</CCol>
			</CRow>
			
			<div className="my-3 classborderbottom"/>
			
			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end">
					<small>{uBahasaObj.word_noref||"No.Ref"}/{uBahasaObj.word_nobukti||"No.Bukti"}</small>
				</CCol>
				<CCol xs="8" md="5" lg="4" className="">
					<CFormInput maxLength={50} 
						size="sm"
						className="text-uppercase"
						autoComplete="off"
						onChange={(e)=>{ uDataformObj.noref=(e.target.value);setEfekview(!uEfekview); }}
						value={uDataformObj.noref||""}
						id="inpnoref"/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_keterangan||"word_keterangan"}
				</CCol>
				<CCol className="">
					<CFormInput maxLength={255} 
						size="sm"
						className="text-capitalize"
						autoComplete="off"
						onChange={(e)=>{ uDataformObj.keterangan=(e.target.value);setEfekview(!uEfekview); }}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
						value={uDataformObj.keterangan||""}
						id="inpket"/>
				</CCol>
			</CRow>
		</CForm>
		)//>
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoadinit = () => {
		setHTMLError500();
		setDatapasivaArr([]);setDataaktivaArr([]);
		uELBtnsimpanmain && (uELBtnsimpanmain.disabled=true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ruparupa/ld_form";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uELBtnsimpanmain && (uELBtnsimpanmain.disabled=false);
			setLoading(false);
			if(output_string.tampil) {
				setDataaktivaArr(JSON.parse(output_string.dataaktiva||"[]"));
				setDatapasivaArr(JSON.parse(output_string.datapasiva||"[]"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Ruparupaform - apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			uELBtnsimpanmain && (uELBtnsimpanmain.disabled=false);
			setLoading(false);
			console.log("(Ruparupaform - apiLoadinit) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		uELBtnsimpanmain && (uELBtnsimpanmain.disabled = true); 
		setLoading(true);

		const vDATAS	= JSON.stringify({
			send_parsdata: JSON.stringify(uDataformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ruparupa/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uELBtnsimpanmain && (uELBtnsimpanmain.disabled = false); 
			setLoading(false);
			if(output_string.sukses) {
				const vPesan = output_string.pesankhusus || (uBahasaObj.pesan_sukses || "Berhasil");
				setDataformObj({});
				showToast(vPesan,"SUKSES");

				if(!isMobile) document.getElementById("inptanggal").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Ruparupaform - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uELBtnsimpanmain && (uELBtnsimpanmain.disabled = false); 
			setLoading(false)	;
			console.log("(Ruparupaform - apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		if(!uIsEOMExecuted) { uNavigate(-1); return; }

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadinit();

		if(!isMobile)
			document.getElementById("inptanggal") && 
				document.getElementById("inptanggal").focus();

    	return () => {
    		setDataformObj({}); setDatapasivaArr([]);setDataaktivaArr([]);
    		setDlglookupshow(false)
    	}
	},[])
	React.useEffect(()=>{
		if(uDataformObj.status_jurnal === undefined)
			uDataformObj.status_jurnal = "REGULER";
		if(uDataformObj.tanggal === undefined)
			uDataformObj.tanggal = UFunc.DbDate();
		if(uDataformObj.jenis === undefined)
			uDataformObj.jenis = "RRAKAS";
		else {
			uDataformObj.debet_id = undefined;
			uDataformObj.debet_caption = undefined;
			uDataformObj.kredit_id = undefined;
			uDataformObj.kredit_caption = undefined;
			setEfekview(!uEfekview);
		}
	},[uDataformObj,
	uDataformObj.status_jurnal,
	uDataformObj.tanggal,
	uDataformObj.jenis
	])
	React.useEffect(()=>{
		if(uDataformObj.status_jurnal !== "REGULER"
			|| uDataformObj.tanggal !== UFunc.DbDate()
			|| uDataformObj.jenis !== "RRAKAS"
			|| (parseInt(uDataformObj.debet_id)||0) > 0
			|| (parseInt(uDataformObj.kredit_id)||0) > 0
			|| (parseFloat(uDataformObj.nilai)||0) > 0
			|| (uDataformObj.keterangan||"") !== ""
			|| (uDataformObj.noref||"") !== ""
			) setFormupdated(true)
		else setFormupdated(false);
	},[uDataformObj,
	uDataformObj.status_jurnal,
	uDataformObj.tanggal,
	uDataformObj.jenis,
	uDataformObj.debet_id,
	uDataformObj.kredit_id,
	uDataformObj.nilai,
	uDataformObj.keterangan,
	uDataformObj.noref,
	])
	React.useEffect(()=>{
		if(!uISDlglookupshow) 
			{ setDlglookupObj({});return }
	},[uISDlglookupshow])
	//---END EFFECT--/

	//console.log("(Ruparupaform) uHTMLError500 => "+uHTMLError500);
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-3 p-0">
		<CContainer fluid className="classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={()=>hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{("Input").toUpperCase()}</small>
				<small className="ms-1 fw-bolder d-md-none">RRA/RRP</small>
				<small className="ms-1 fw-bolder d-none d-md-inline">Rupa-rupa Aktiva/Pasiva</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">
		<CContainer xxl className="p-0 px-lg-5">
			{(uHTMLError500) ? (
				<MyPage500 content={uHTMLError500} isWithrefresh={true} 
					hdlReset={hdlKlikReset} {...props}/>
			) : (
			<CCard className="classcardbg mx-lg-5 mb-3">
			<CCardHeader className="fw-bolder d-flex justify-content-between">
				<small className="text-primary">FORM INPUT</small>
				<CLink className="classcursorpointer classikon classikonreset"
					onClick={hdlKlikReset}/>
			</CCardHeader>
			<CCardBody>{contentFormmain()}</CCardBody>
			</CCard>
			)}
		</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light classcardbg">
			<MyButton
				centered={true}
				iconname="cilCheckAlt"
				disabled={uHTMLError500!==undefined}
				onClick={hdlKlikSimpanmain}
				id="btnSimpan">
				Simpan [F9]
			</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.headers}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.data_arr || []} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>
		</>
	) 
}	

export default Ruparupaform