import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CForm,CFormInput,CFormSelect,CButton,CInputGroup,
	CImage,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyDialogform,
	MyPagination,
	MyPage500,
	MyFormHadir,
	MyFormbooking,
	MyDialoglookup,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson	= require('../../package.json');
const Jadwal= (props) => {
	const uComponentname 	= Jadwal.name;
	const {
		setLoading, showToast, prosesExpired, setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uRoutebefore		= useSelector(state => state.gUrlbefore);
	const uLayananselect	= useSelector(state => state.gLayananselect) || "0";

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uMaxData			= isMobile ? 10: 30;
	const [uHTMLError500,setHTMLError500]	= React.useState();

	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [uInitfirstload,setInitfirstload]	= React.useState("YA");
	const [uInpLayanan,setInpLayanan]		= React.useState("0");
	const [uJmlLayanan,setJmlLayanan]		= React.useState(0);
	const [uNoteLayanan,setNoteLayanan]		= React.useState("");

	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const uDatalayananArr					= JSON.parse(localStorage.getItem("layananlist")||"[]");

	//---FORMDIALOG--/
	const [uJenishewanArr,setJenishewanArr]	= React.useState([]);
	const [uGenderArr,setGenderArr]			= React.useState([]);
	//const [uDatapasienObj,setDatapasienObj]	= React.useState({});//--TIDAK_DIPAKAI--/

	const [uDlgformshow,setDlgformshow]		= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	const [uDlgformbookingsimpan,setDlgformbookingsimpan]= React.useState(false);
	const [uDlgformbookingdone,setDlgformbookingdone]= React.useState(false);
	//---END FORMDIALOG--/

	//---LOOKUP_DIALOG--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//---END LOOKUP_DIALOG--/

	//---DIALOG_BOOKING--/
	//---END DIALOG_BOOKING--/

	//--DOMELS--/
	const uDOMisimain		= document.getElementById("idmainisi");
	const uDOMcardmain		= document.getElementById("idcardmain");
	const uElJmldata		= document.getElementById("idjmldata");
	//console.log("uWidthnotelayanan => "+uWidthnotelayanan);
	//--END DOMELS--/

	const [uElBtndlgsimpan,setElBtndlgsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const uTanggalNowDT							= UFunc.toDate();
	const [uWidthnotelayanan,setWidthnotelayanan]= React.useState("auto");
	const [uColorlayanan,setColorlayanan]		= React.useState("initial");
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlKlikDlgformsimpan = () => {
		if(uDlgformObj.jenis === "KEHADIRAN") prosesKlikhadir();
		else if(uDlgformObj.jenis === "REGISTER") prosesKlikpasien();
		else if(uDlgformObj.jenis === "EDITBOOKING") prosesKlikbooking();
	}
	const hdlKlikEditbooking = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		const vHAKuser	= (uTokenObj.userhak||"").toUpperCase();
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vPasienID	= uDatamainArr[vObjdx].pasien_id || "255";
		const vCaptionUpper = (UFunc.leadZero(uDatamainArr[vObjdx].id,6)+" - "+(uDatamainArr[vObjdx].pasien_nama||"")).toUpperCase();
		//alert(vPasienID); return;

		setDlgformObj({
			id_booking: _IDTABEL,
			id_pasien: vPasienID,
			tgl_booking: uDatamainArr[vObjdx].tgl_booking,
			tgl_booking_dt: UFunc.toDatetime(uDatamainArr[vObjdx].tgl_booking+" 00:00:00"),
			layanan_id:uDatamainArr[vObjdx].layanan_id,
			layanan_value:uDatamainArr[vObjdx].layanan_value,
			status_antar:uDatamainArr[vObjdx].status_antar,
			keterangan:uDatamainArr[vObjdx].keterangan,
			jenis: "EDITBOOKING",
			header: (uBahasaObj.caption_editbooking||"Edit Booking")+": <B>"+vCaptionUpper+"</B>",
		})
		setDlgformshow(true);
	}
	const hdlKlikArrive = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionUpper = (UFunc.leadZero(uDatamainArr[vObjdx].id,6)+" - "+(uDatamainArr[vObjdx].pasien_nama||"")).toUpperCase();
		setTabelIDPilih(_IDTABEL);

		setDlgformObj({
			value_layanan: uDatamainArr[vObjdx].layanan_value,
			id_booking: uDatamainArr[vObjdx].id,
			jenis : "KEHADIRAN",
			header : (uBahasaObj.caption_setkedatangan||"caption_setkedatangan")+": <B>"+vCaptionUpper+"</B>",
		})

		setDlgformshow(true);
	}
	const hdlKlikPasienregister = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionUpper = (UFunc.leadZero(uDatamainArr[vObjdx].id,6)+" - "+(uDatamainArr[vObjdx].pasien_nama||"")).toUpperCase();

		uDlgformObj.jenis = "REGISTER";
		setTabelIDPilih(_IDTABEL);

		uDlgformObj.id_jenis 		= "0";
		uDlgformObj.pemilik_id 		= uDatamainArr[vObjdx].customer_id || 0;
		uDlgformObj.pemilik_nama 	= uDatamainArr[vObjdx].customer_nama || "Undf";
		uDlgformObj.sex 			= uGenderArr.length > 0 ? uGenderArr[0].value : "Lainnya";
		uDlgformObj.toggle_pasien 	= "NEW";

		uDlgformObj.header = (uBahasaObj.word_register||"Register")+" "+
			(uBahasaObj.word_pasien||"Pasien")+
			": <B>"+vCaptionUpper+"</B>"+
		"";

		setDlgformshow(true);
	}
	const hdlKDownPasienlookup = (_EV) => {
		if(_EV.keyCode !== 13) {
			uDlgformObj.id_pasien 	= 0;
			uDlgformObj.id_jenis 	= "0";
			uDlgformObj.sex 		= uGenderArr.length > 0 ? uGenderArr[0].value : "Lainnya";
			setEfekview(!uEfekview);
			return;
		}
		if((uDlgformObj.nama_pasien||"")==="") return;

		_EV.preventDefault();
		_EV.stopPropagation();

		hdlKlikPasienlookup();
	}
	const hdlKlikPasienlookup = () => {
		uDlglookupObj.dlg_header	= "Lookup: "+(uBahasaObj.word_pasien||"Pasien");
		//uDlglookupObj.dlg_keyword	= uDlgformObj.nama_pasien||"";
		uDlglookupObj.data_arr 		= (uDlgformObj.pasien_arr||[]).map(vItems => {
			return {
				id:vItems.id,
				caption:vItems.nama_pasien,
			}
		});

		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		const vArridx = UFunc.getObjectIndeks((uDlgformObj.pasien_arr||[]),_IDITEM);
		if(vArridx < 0) return;

		let vElsFocus	= document.getElementById("btnDialogSimpan"); 
		uDlgformObj.id_pasien 		= (uDlgformObj.pasien_arr||[])[vArridx].id || "";
		uDlgformObj.nama_pasien 	= (uDlgformObj.pasien_arr||[])[vArridx].nama_pasien || "";
		uDlgformObj.signalement 	= (uDlgformObj.pasien_arr||[])[vArridx].signalement || "";
		uDlgformObj.sex 			= (uDlgformObj.pasien_arr||[])[vArridx].gender || "Lainnya";

		setDlglookupshow(false);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			vElsFocus && vElsFocus.focus();
		},350);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionUpper = (UFunc.leadZero(uDatamainArr[vObjdx].id,6)+" - "+(uDatamainArr[vObjdx].pasien_nama||"")).toUpperCase();

		const vConfirm	= 
			(uBahasaObj.caption_hapusbooking||"caption_hapusbooking")+": <B>"+
			vCaptionUpper+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiHapusdata(_IDTABEL);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
	}
	const prosesKlikpasien = () => {
		let vElFocus = document.getElementById("inppasiennama");
		if((uDlgformObj.nama_pasien||"").trim() === "") {
			vElFocus && vElFocus.focus();
			showToast((uBahasaObj.caption_namapasien)+" "+(uBahasaObj.caption_mustfilled)+" !"); return;
		}
		if((uDlgformObj.nama_pasien||"").length < 3) {
			vElFocus && vElFocus.focus();
			showToast((uBahasaObj.caption_namapasien)+" "+(uBahasaObj.caption_invalid)+" !"); return;
		}
		vElFocus = document.getElementById("inppasiensign");
		if((uDlgformObj.signalement||"").trim() === "") {
			vElFocus && vElFocus.focus();
			showToast((uBahasaObj.word_signalement)+" "+(uBahasaObj.caption_mustfilled)+" !"); return;
		}
		if((uDlgformObj.signalement||"").length < 3) {
			vElFocus && vElFocus.focus();
			showToast((uBahasaObj.word_signalement)+" "+(uBahasaObj.caption_invalid)+" !"); return;
		}
		vElFocus = document.getElementById("inppasienjenisid");
		if(parseInt(uDlgformObj.id_jenis||"0") < 0) {
			vElFocus && vElFocus.focus();
			showToast((uBahasaObj.caption_jenispasien)+" "+(uBahasaObj.caption_mustchoose)+" !"); return;
		}

		apiRegisterpasien(uTabelIDPilih)
	}
	const prosesKlikhadir = () => {
		let vElFocus = document.getElementById("inppaymentcaption");
		if(parseInt(uDlgformObj.nilai_dp||"0") > 0)
			if(parseInt(uDlgformObj.payment_id||"0") <= 0) {
				vElFocus && vElFocus.focus();
				showToast((uBahasaObj.caption_metodebayar||"caption_metodebayar")+" "+(uBahasaObj.caption_mustchoose||"caption_mustfilled")+"..");
				return;
			}

		//alert("apiProseshadir"); return;
		//console.log("(Jadwal - hdlKlikDlgformsimpan) uDlgformObj => "+JSON.stringify(uDlgformObj)); return;
		apiProseshadir();
	}
	const prosesKlikbooking = () => { setDlgformbookingsimpan(true) }
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain=()=>{
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= "";//(uKeywordObj.kwd_jadwal||{}).keyword || "";
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataViewSelectArr	= uInpLayanan==="0" ? uDatamainArr 
			: uDatamainArr.filter((vItems,vKeys)=> (vItems.layanan_value||"0").toUpperCase() === (uInpLayanan||"0").toUpperCase());

		const vDataViewObj		= vDataViewSelectArr.slice(vMin,(vMax));

		const vTanggalSelect	= (uKeywordObj.kwd_jadwal||UFunc.DbDate());
		const vTanggalnow	 	= UFunc.DbDate();

		const vInitKedatangan	= vTanggalnow===vTanggalSelect ? true : false;

		//console.log("(Jadwal-contentMain) uDatamainArr => "+JSON.stringify(uDatamainArr));
		return (
		<table className="table table-borderless" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="3" className="text-start">{uBahasaObj.word_pasien||"word_pasien"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.word_pemilik||"word_pemilik"}</CCol>
				<CCol md="2"  className="text-start">{uBahasaObj.caption_statusantar||"caption_statusantar"}</CCol>
				<CCol md="3"  className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
		</tr>
		</thead>
		<tbody>
		{vDataViewObj.map((vItems,vKeys)=>{
			const {
				id,pasien_id,pasien_nama,customer_nama,customer_telp,
				status_antar,layanan_caption,layanan_value,keterangan,
				ISDetilprocess
			} = vItems;

			//if(vKeys > 1) return;
			vNumber++;
			const vCaptionUpper	= (pasien_nama||"").toUpperCase();

			const vPasiennama	= vKeyword==="" ? (pasien_nama||"") 
				: (pasien_nama||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKliennama	= vKeyword==="" ? (customer_nama||"") 
				: (customer_nama||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKlientelp	= vKeyword==="" ? (customer_telp||"") 
				: (customer_telp||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vLayananjenis	= vKeyword==="" ? (layanan_caption||"").toUpperCase() 
				: (layanan_caption||"").toUpperCase().replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKeterangan	= vKeyword==="" ? (keterangan||"") 
				: (keterangan||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			const vStatusantar	= (status_antar||"UNDF");
			const vArrSelect	= uDatalayananArr.filter(vItems=>
				(vItems.value_layanan||"").toUpperCase() === (layanan_value||"").toUpperCase()
				);
			const vBgColor 		= vArrSelect.length > 0 ? vArrSelect[0].color_layanan : "DCDCDC";
			//console.log("(Jadwal-contentMain) layanan_value => "+(layanan_value));

			const vActionmenu=()=>{
				return (
				<>
				{(ISDetilprocess||false)===true ? (
					<CIcon className="classikontabel classikonloading"/>
				) : (
				<CDropdown autoclose="true">
					<CDropdownToggle color="transparent" className="p-0 m-0"
						caret={false}>
						<CIcon icon="cilChevronBottom" className="d-none d-md-block classikoncoreui mx-0" height={23}/>
						<CIcon icon="cilOptions" className="d-md-none"/>
					</CDropdownToggle>
					<CDropdownMenu>
						{parseInt(pasien_id||"0") > 0 ? (
						<CDropdownItem 
							className="classcursorpointer d-md-none"
							onClick={()=>hdlKlikArrive(id)}>
							<CIcon className="classikontabel classikonarrived me-2" height={25}/>
							<span>{uBahasaObj.caption_setkedatangan}</span>
						</CDropdownItem>
						) : (
						<CDropdownItem 
							className="classcursorpointer d-md-none"
							onClick={()=>hdlKlikPasienregister(id)}>
							<CImage src={pjson.homepage+"api/images/menus_pasien.png"} className="classikoncoreui me-2" height={25}/>
							<span>{(uBahasaObj.word_register||"Register")+" "+uBahasaObj.word_pasien}</span>
						</CDropdownItem>
						)}
						<CDropdownItem 
							className="classcursorpointer"
							onClick={()=>hdlKlikEditbooking(id)}>
							<CIcon className="classikontabel classikonedit me-2" height={25}/>
							<span>{uBahasaObj.caption_editbooking||"Edit Booking"}</span>
						</CDropdownItem>
						<CDropdownItem 
							className="classcursorpointer"
							onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikontabel classikonhapus me-2" height={25}/>
							<span>{uBahasaObj.caption_hapusbooking}</span>
						</CDropdownItem>
					</CDropdownMenu>
				</CDropdown>
				)}
				</>
				);
			}

			return (
			<tr id={"idtr"+id} key={vKeys} 
				style={{backgroundColor:"#"+(vBgColor)+"11"}}
				className={""+
				((uTabelRowSelect===vKeys && !isMobile)?"border border-warning":"classborderbottom classborderdotted")}>
				<td align="right" className="px-1 pe-0">{vNumber}.</td>
				<td className="px-1 text-end">
				<CRow className="mx-0">
				<CCol md="3" className="px-1 text-start">
					<div className="d-flex justify-content-between align-items-start">
					<span className={"text-capitalize "+(parseInt(pasien_id)<=0?"text-muted":"text-info")}>{UFunc.renderHTML(vPasiennama)}</span>
					<div className="d-md-none">{vActionmenu()}</div>
					</div>
					{parseInt(uInpLayanan||"0")===0 && (
					<small className="classfontsmaller d-none">
						<span style={{color:"#"+vBgColor}}>{UFunc.renderHTML(vLayananjenis)}</span>
						<span className="mx-1">&middot;</span>
						{UFunc.leadZero(id,6)}
					</small>
					)}
				</CCol>
				
				<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>
				
				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_pemilik||"word_pemilik"}</CCol>
				<CCol xs="8" md="3" className="px-1 text-md-start">
					<div className="fw-bolder">{UFunc.renderHTML(vKliennama)}</div>
					{(customer_telp||"")!=="" && (
					<small className="d-none d-md-block classfontsmaller">
					{uBahasaObj.caption_telphp}: <span className="text-primary ">{UFunc.renderHTML(vKlientelp)}</span>
					</small>
					)}
				</CCol>
				{(customer_telp||"")!=="" && (
				<>
				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_telphp||"caption_telphp"}</CCol>
				<CCol xs="7" className="px-1 d-md-none">
					<span className="text-primary">{UFunc.renderHTML(vKlientelp)}</span>
				</CCol>
				</>
				)}

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_statusantar||"Status Antar"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-start">
					<small className="text-danger">{vStatusantar}</small>
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol xs="8" md="3" className="px-1 text-md-start fst-italic">
					{UFunc.renderHTML(vKeterangan)}
				</CCol>

				<CCol md="1" className="px-0 text-center d-none d-md-block">
				{parseInt(pasien_id||"0") > 0 ? (
				<>
				{vInitKedatangan && (
					<CTooltip content={"--"+(uBahasaObj.caption_setkedatangan||"caption_setkedatangan")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer d-none d-md-inline-block"
						onClick={()=>hdlKlikArrive(id)}> 
						<CIcon className="classikoncoreui classikonarrived" height={25}/>
					</CLink>
					</CTooltip>
				)}
				</>
				) : (
					<CTooltip content={"--"+(uBahasaObj.word_register||"Register")+" "+
					(uBahasaObj.word_pasien||"Pasien")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer d-none d-md-inline-block "
						onClick={()=>hdlKlikPasienregister(id)}> 
						<CImage src={pjson.homepage+"api/images/menus_pasien.png"} className="classikoncoreui" height={25}/>
					</CLink>
					</CTooltip>
				)}
				{vActionmenu()}
				</CCol>
				</CRow>
				</td>
			</tr>
			);
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgform = () => {
		if(!uDlgformshow) return (<></>)//>

		if(uDlgformObj.jenis === "KEHADIRAN") return contentFormhadir();
		else if(uDlgformObj.jenis === "REGISTER") return contentFormregister();
		else if(uDlgformObj.jenis === "EDITBOOKING") return contentFormbooking();
		else return (<></>)//>
	}
	const contentFormhadir = () => {
		if(uDlgformObj.jenis !== "KEHADIRAN") return (<></>);

		return (
			<MyFormHadir
				objDataparse={uDlgformObj}
				{...props}/>
		)//>
	}
	const contentFormregister = () => {
		if(uDlgformObj.jenis !== "REGISTER") return (<></>)//>

		if((uDlgformObj.is_loading||false) === true) return (
			<div className="my-5 py-md-5 text-center">
			<CSpinner color="primary"/>
			</div>
		)//>

		return (
		<CForm className="mx-lg-5 px-lg-5">
		<div className="my-3 classborderbottom d-none">{JSON.stringify(uDlgformObj.pasien_arr)}</div>
		<CRow className="my-2">
		<CCol className="fw-bolder">{uBahasaObj.caption_namapasien}</CCol>
		<CCol md="7">
			{(uDlgformObj.toggle_pasien||"NEW")==="NEW" ? (
			<CInputGroup size="sm">
			<CFormInput size="sm" maxLength={100}
				className="text-capitalize"
				placeholder="--Nama Pasien BARU.."
				value={uDlgformObj.nama_pasien||""}
				onChange={(e)=>{uDlgformObj.nama_pasien=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"inppasiensign")}
				id="inppasiennama"/>
			<CTooltip className="d-none d-lg-block" content="--Toggle Pasien Baru/ LAMA..">
			<CButton size="sm" color="light"
				onClick={()=>{ uDlgformObj.toggle_pasien="OLD";setEfekview(!uEfekview); }}
				id="btnToggle2old">
				<CIcon icon="cilTouchApp" className="text-danger"/>
			</CButton>
			</CTooltip>
			</CInputGroup>
			) : (
			<CInputGroup size="sm">
			<CFormInput size="sm" maxLength={100}
				placeholder="--Masukkan Keyword Pasien LAMA.."
				className="text-capitalize"
				value={uDlgformObj.nama_pasien||""}
				onChange={(e)=>{uDlgformObj.nama_pasien=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>hdlKDownPasienlookup(e)}
				id="inppasiencaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikPasienlookup()}
				id="btnPasienlookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			&nbsp;
			<CTooltip className="d-none d-lg-block" content="--Toggle Pasien BARU /Lama..">
			<CButton size="sm" color="secondary"
				onClick={()=>{ uDlgformObj.toggle_pasien="NEW";setEfekview(!uEfekview); }}
				id="btnToggle2new">
				<CIcon icon="cibAddthis" className="text-black"/>
			</CButton>
			</CTooltip>
			</CInputGroup>
			)}
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>
		
		<CRow className="my-3">
		<CCol className="fw-bolder">{uBahasaObj.word_pemilik || "Pemilik"}</CCol>
		<CCol md="7"><b>{uDlgformObj.pemilik_nama||""}</b></CCol>
		</CRow>

		<CRow className="my-2">
		<CCol className="fw-bolder">{uBahasaObj.word_signalement}</CCol>
		<CCol md="7">
			<CFormInput size="sm" maxLength={200}
				readOnly={(uDlgformObj.toggle_pasien||"NEW")==="OLD"}
				value={uDlgformObj.signalement||""}
				onChange={(e)=>{uDlgformObj.signalement=e.target.value;setEfekview(!uEfekview)}}
				id="inppasiensign"/>
		</CCol>
		</CRow>

		{(uDlgformObj.toggle_pasien||"NEW")==="NEW" && (
		<CRow className="my-2">
		<CCol>{uBahasaObj.caption_jenispasien || "Jenis Pasien"}</CCol>
		<CCol md="7">
			<CFormSelect size="sm"
				disabled={(uDlgformObj.toggle_pasien||"NEW")==="OLD"}
				value={uDlgformObj.id_jenis||"0"}
				onChange={(e)=>{uDlgformObj.id_jenis=e.target.value;setEfekview(!uEfekview)}}
				id="inppasienjenisid">
			{uJenishewanArr.map((vItems,vKeys)=>{
				return (
				<option value={vItems.id} key={vKeys}>{(vItems.caption||"").toUpperCase()}</option>
				);

			})}
			<option value="0">{(uBahasaObj.word_lainnya||"Lainnya").toUpperCase()}</option>
			</CFormSelect>
		</CCol>
		</CRow>
		)}

		<CRow className="my-2">
		<CCol>{uBahasaObj.caption_jeniskelamin || "caption_jeniskelamin"}</CCol>
		<CCol md="7">
			<CFormSelect size="sm"
				disabled={(uDlgformObj.toggle_pasien||"NEW")==="OLD"}
				value={uDlgformObj.sex}
				onChange={(e)=>{uDlgformObj.sex=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inppasiensex">
			{uGenderArr.map((vItems,vKeys)=>{
				return (
				<option value={vItems.value} key={vKeys}>{(vItems.caption||"").toUpperCase()}</option>
				);
			})}
			</CFormSelect>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentFormbooking = () => {
		if(uDlgformObj.jenis !== "EDITBOOKING") return (<></>);

		return (
			<MyFormbooking
				objDataparse={uDlgformObj}
				isSimpan={uDlgformbookingsimpan}
				toggleSimpan={()=>setDlgformbookingsimpan(false)}
				funcDlgclose={()=>setDlgformbookingdone(!uDlgformbookingdone)}
				{...props}/>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoadinit = () => {
		setHTMLError500();
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_init";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setGenderArr(JSON.parse(output_string.genderobject||"[]"));
				setJenishewanArr(JSON.parse(output_string.jenishewanobject||"[]"));

				apiLoaddata();
			} else {
				setSessionaktif(true);
				if(output_string.info) {
					setHTMLError500(output_string.info);
				} else if(output_string.errors) {
					console.log("(Jadwal - apiLoadinit) output_string.errors : "+output_string.errors);
					const vMsg 	= (pjson.mydefault.environment==="development")
						? output_string.errors :pjson.mydefault.msgFetchError;
					setHTMLError500("<font color='red'>"+vMsg+"</font>");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Jadwal - apiLoadinit) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddata = () => {
		uFuncname = apiLoaddata.name;
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_jadwal || UFunc.DbDate();
		setNoteLayanan("");
		setDatamainArr([]);
		setPageActive(1);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tanggal : vKeyword,
			send_firstload: uInitfirstload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jadwal/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				//console.log("("+uComponentname+"-"+uFuncname+") uDatalayananArr : "+(output_string.layananlist));
				const vDatamainArr = JSON.parse(output_string.dataobject || "[]");
				setDatamainArr(vDatamainArr);
				setNoteLayanan(output_string.datanotelayanan || "");

				//console.log("("+uComponentname+"-"+uFuncname+") uLayananselect : "+(uLayananselect));

				setInitfirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors :pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshadir = () => {
		uFuncname 	= apiProseshadir.name;
		if((parseInt(uTabelIDPilih)||0) <= 0) return;

		const vObjdx			= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		const vLayananSelected 	= uInpLayanan;
		uElBtndlgsimpan.disabled= true;
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_berat : uDlgformObj.berat||"0",
			send_keluhan : uDlgformObj.keluhan||"",
			send_dokterid : uDlgformObj.id_dokter||"0",
			send_tglkeluar : uDlgformObj.tgl_keluar||"",
			send_keterangan : uDlgformObj.keterangan||"",
			send_fotolist : JSON.stringify(uDlgformObj.foto_arr||[]),
			send_nilaidp : uDlgformObj.nilai_dp||"0",
			send_paymentid : uDlgformObj.payment_id||"0",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jadwal/pr_hadir";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtndlgsimpan.disabled	= false;
			if(output_string.sukses) {
				setInpLayanan("");
				setTabelRowSelect(-1);

				uDatamainArr.splice(vObjdx,1);
				setInpLayanan(vLayananSelected);
				//setEfekview(!uEfekview);

				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors :pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			uElBtndlgsimpan.disabled	= false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiHapusdata = (_IDTABEL) => {
		uFuncname 	= apiHapusdata.name;
		_IDTABEL 	= parseInt(_IDTABEL) || 0;

		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vLayananSelected = uInpLayanan;
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		let vTimeout = setTimeout(function(){
			setInpLayanan("");
		},2500); return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jadwal/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				setInpLayanan("");
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setInpLayanan(vLayananSelected);
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors :pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiRegisterpasien = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		const vBtndisabled = document.getElementById("btnDialogSimpan");
		vBtndisabled && (vBtndisabled.disabled = true);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		let vTimeout = setTimeout(() => {
			setLoading(false);
			clearTimeout(vTimeout);
		},2500);return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_datapasien: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jadwal/pr_registerpasien";
		//const vURLs	= pjson.homepage+"api/api_pasien/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			vBtndisabled && (vBtndisabled.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vObjdx].pasien_id 		= output_string.pasien_id || uDlgformObj.id_pasien;
				uDatamainArr[vObjdx].pasien_nama 	= uDlgformObj.nama_pasien;
				setDlgformshow(false)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Jadwal - apiRegisterpasien) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors :pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			vBtndisabled && (vBtndisabled.disabled = false);
			setLoading(false);
			console.log("(Jadwal - apiRegisterpasien) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadpasien = (_IDCUSTOMER) => {
		_IDCUSTOMER		= parseInt(_IDCUSTOMER) || 0;
		uDlgformObj.is_loading = true;
		setEfekview(!uEfekview);

		/*//---TESTING_FRONTEND--/
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_customerid : _IDCUSTOMER,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_family";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uDlgformObj.is_loading = false;
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				uDlgformObj.pasien_arr 		= JSON.parse(output_string.datapasien || "[]");

				if(!isMobile) {
					const vElFocus = document.getElementById("inppasiennama");
					vElFocus && vElFocus.focus();
				}
			} else {
				setDlgformshow(false)
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg 	= (pjson.mydefault.environment==="development")
						? output_string.errors :pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDlgformObj.is_loading = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
			setDlgformshow(false);
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		uDispatch({type: "set", gStatePasien: ""});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		//localStorage.setItem("lasturl",window.location.pathname + window.location.search);

		setInpLayanan(uLayananselect);
		apiLoadinit();

		return ()=>{
			setDlglookupshow(false);
			setDlgformshow(false);
			setJenishewanArr([]); 
			setGenderArr([]); 
		}
	},[]);
	React.useEffect(()=>{
 		if(!uDOMcardmain) return;

 		setWidthnotelayanan(
			(document.getElementById("idcardmain").offsetWidth) -
			(document.getElementById("inplayanan").offsetWidth) - 
			(document.getElementById("idtimeelapse").offsetWidth) - 
		103);
	},[uDOMcardmain,
	uDOMisimain && uDOMisimain.offsetWidth,
	uNoteLayanan]);
	React.useEffect(()=>{ setJmlData(uDatamainArr.length) },[uDatamainArr,uDatamainArr.length]);
	React.useEffect(()=>{
		uElJmldata && 
			(uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uHandelView) return;
		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;
		uNavigate("/klinik/pasien/formbooking");
	},[uHandelTambah]);
	React.useEffect(()=>{
		setPageActive(1);
		setTabelRowSelect(-1);
		uDispatch({type: "set", gLayananselect: uInpLayanan});
		if(parseInt(uInpLayanan||"0")===0) {
			setJmlLayanan(uDatamainArr.length);
			setColorlayanan("initial");
			return;
		}
		const vDataselectedArr = 
			uDatamainArr.filter((vItems,vKeys)=> (vItems.layanan_value||"0").toUpperCase() === (uInpLayanan||"0").toUpperCase());
		setJmlLayanan(vDataselectedArr.length);

		const vArridx = UFunc.getObjectIndeks(uDatalayananArr,uInpLayanan,"value_layanan");
		setColorlayanan("#"+uDatalayananArr[vArridx].color_layanan);

		/*const vLayananSelectedArr = 
			uDatalayananArr.filter((vItems,vKeys)=> (vItems.value||"0").toUpperCase() === uInpLayanan.toUpperCase());
		setNoteLayanan(vLayananSelectedArr.length>0?(vLayananSelectedArr[0].note||""):"");
		setEfekview(!uEfekview)//-*/
	},[uDatamainArr,uInpLayanan]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlLayanan / uMaxData);
		setJmlHal(vJmlHal);//-*/

		setPageActive(uPageActive<=0?1:uPageActive);
	},[uJmlLayanan]);
	React.useEffect(()=>{
		if(!uDlgformshow) { setDlgformObj({}); return}

		if(uDlgformObj.jenis === "REGISTER") {
			//--LOAD_LOOKUP--/
			apiLoadpasien(uDlgformObj.pemilik_id);
			//--END LOAD_LOOKUP--/
			return;
		}

		//uElBtndlgsimpan.disabled	= false;
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			setElBtndlgsimpan(document.getElementById("btnDialogSimpan"))

			let vElFocus;
			if(uDlgformObj.jenis==="KEHADIRAN") {
				vElFocus	= document.getElementById("inptgltitipexit")
				if(!vElFocus) {
					vElFocus	= document.getElementById("inpberat")
				}
			} else if(uDlgformObj.jenis==="EDITBOOKING") {
				vElFocus = document.getElementById("btntglbooking");
			} else {
				vElFocus = document.getElementById("inppasiennama");
			}

			if(!isMobile) vElFocus && vElFocus.focus();
		},350);
	},[uDlgformshow]);
	React.useEffect(()=>{
		if(uDlgformObj.toggle_pasien===undefined) return;

		uDlgformObj.nama_pasien = "";
		uDlgformObj.signalement = "";
		uDlgformObj.id_jenis 	= "0";
		uDlgformObj.sex 		= uGenderArr.length > 0 ? uGenderArr[0].value : "Lainnya";

		let vElFocus;
		if(uDlgformObj.toggle_pasien==="NEW")
			vElFocus = document.getElementById("inppasiennama")
		else
			vElFocus = document.getElementById("inppasiencaption");

		if(!isMobile) vElFocus && vElFocus.focus();
	},[uDlgformObj,uDlgformObj.toggle_pasien]);
	React.useEffect(()=>{ 
		if(!uDlgformshow) return;

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uDlgformObj.id_booking);
		if(vArridx < 0) return;
		
		uDatamainArr[vArridx].tgl_booking 	= uDlgformObj.tgl_booking;
		uDatamainArr[vArridx].layanan_id 	= uDlgformObj.layanan_id;
		uDatamainArr[vArridx].layanan_value = uDlgformObj.layanan_value;
		uDatamainArr[vArridx].status_antar 	= uDlgformObj.status_antar;
		uDatamainArr[vArridx].keterangan	= uDlgformObj.keterangan;

		//console.log("uDlgformObj.layanan_value -> "+uDlgformObj.layanan_value)

		setDlgformshow(false) 
	},[uDlgformbookingdone]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return (<></>); }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>);

	return (
		<>
		<CCard className="classcardbg" id="idcardmain">
		<CCardHeader className="py-1 px-2">
		<div className="d-flex justify-content-between align-items-center">
			<div className="pe-2">
			<CFormSelect size="sm" 
				className="d-inline-block"
				style={{minWidth:125,width:"auto"}}
				value={uInpLayanan}
				onChange={(e)=>setInpLayanan(e.target.value)}
				id="inplayanan">
				<option value="0">--{(uBahasaObj.caption_semualayanan||"caption_semualayanan").toUpperCase()}</option>
				{uDatalayananArr.map((vItems,vKeys)=>{
					return (
						<option value={vItems.value_layanan}
							style={{color:"#"+(vItems.color_layanan||"000000")}}
							key={vKeys}>
							{(vItems.caption_layanan||"").toUpperCase()}
						</option>
					);
				})}
			</CFormSelect>

			<span className="mx-1">&middot;</span>
			<small style={{color:uColorlayanan}}>(<b>{uJmlLayanan}</b>)</small>
			
			{(uNoteLayanan!=="") && (
			<>
			<span className="d-none d-md-inline-block mx-1">&middot;</span>
			<span style={{border:"1px dashed red",borderRadius:3,width:uWidthnotelayanan }} 
				className="d-none d-md-inline-block text-end align-middle">
				<marquee className="text-danger">
				<small className="fst-italic text-capitalize">&middot;&middot; {uNoteLayanan} &middot;&middot;</small>
				</marquee>
			</span>
			</>
			)}
			
			</div>

			<div className="text-muted classfontsmaller" id="idtimeelapse">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</div>
		</div>

		{(uNoteLayanan!=="") && (
		<>
		<div className="d-md-none classborderbottom my-2"/>
		<div style={{border:"1px dashed red",borderRadius:3}} className="d-md-none text-center">
			<small className="fst-italic text-capitalize text-danger classblinking">&middot; {uNoteLayanan} &middot;</small>
		</div>
		</>
		)}
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>
		{(uJmlLayanan > uMaxData) && (
		<CCardFooter>
			<MyPagination
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i) => setPageActive(i)}/>
		</CCardFooter>
		)}
		</CCard>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.dlg_header||""}
			inputValue={uDlglookupObj.dlg_keyword||""}
			dataItemArr={uDlglookupObj.data_arr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>

		<MyDialogform
			options={{size:"lg",centered:false}}
			show={uDlgformshow} 
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
		</>
	)//>
}
	
export default Jadwal;
