import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {
	CForm,
	CInputGroup,
	CFormInput,
	CButton,
	CTooltip,
	CLink,
	CImage,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';

const pjson     		= require('../../../package.json');
const Headersubkasbon 	= (props) => {
	const uDispatch			= useDispatch();

	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp			= useSelector(state => state.effectApp);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uKeyword,setKeyword] = React.useState(uKeywordObj.kwd_kasbon || "");

	const hdlKeydown = (_EV) => {
		if(_EV.which === 13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_kasbon = uKeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/
		//setHeaderView(true);

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikTambah = () => {
		uHeaderActionObj.isHeaderTambah = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikEdit = () => {
		uHeaderActionObj.isHeaderEdit = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_kasbon = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//prosesReset(); return;
		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}

	//console.log("("+uComponentname+") Checked..");
	return(
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+uActiveroute+".png"}
				className="me-2 align-middle" 
				height={30} />
			<small className="fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup 
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CFormInput size="sm"
					value={uKeyword} 
					onChange={(e) => setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKeydown(e)} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."}
					id="inpkeyword" name="inpkeyword"/>
					<CButton size="sm" color="light" className="border" 
						onClick={()=>hdlKlikCari()} 
						id="btnCari">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
				</CInputGroup>
			</CForm>

			<div className="d-none d-md-block ms-1">
			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			{(uIsEOMExecuted) ? (
			<>
			<CTooltip content={(uBahasaObj.caption_tambahdata)+" "+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"").toUpperCase()}>
				<CLink onClick={()=>hdlKlikTambah()} className="classikon classikontambah classcursorpointer"/>
			</CTooltip>
			<span className="me-1 d-none d-lg-inline">&middot;</span>

			<CTooltip content={(uBahasaObj.caption_editinput || "caption_editinput")+" "+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"").toUpperCase()}>
				<CLink onClick={()=>hdlKlikEdit()} className="classikon classikonedit classcursorpointer"/>
			</CTooltip>
			</>
			) : (
			<span className="classblinking d-md-none ms-1">
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui" height={30}/>
			</span>
			)}

			<span className="me-1 d-none d-lg-inline">&middot;</span>
			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
			</CTooltip>

			<span className="me-1">&middot;</span>

			<b id="idjmldata">0</b> {uBahasaObj.word_data||"word_data"}
			</div>

			{(uIsEOMExecuted) ? (
			<CDropdown className="d-md-none ms-1">
				<CDropdownToggle caret={false} size="sm" className="p-0"
					color="transparent">
					<CIcon icon="cilChevronBottom" className="classikoncoreui" height={30}/>
				</CDropdownToggle>
				<CDropdownMenu className="py-0">
				<CDropdownItem onClick={()=>hdlKlikTambah()}>
					<CIcon className="classikontambah ms-0 me-2 align-middle" height={25}/>
					{(uBahasaObj.word_input || "Input")} Kas Bon Baru
				</CDropdownItem>
				<CDropdownItem onClick={()=>hdlKlikEdit()}>
					<CIcon className="classikonedit ms-0 me-2 align-middle" height={25}/>
					Edit Input Kasbon
				</CDropdownItem>
				</CDropdownMenu>
			</CDropdown>
			) : (
			<span className=" d-md-none ms-1">
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui classblinking" height={30}/>
			</span>
			)}
		</div>
		</>
	);
}

export default Headersubkasbon;