import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CRow,
	CCol,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
	MyPagination,
	MyFormHapus,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../package.json');
const Kasselisih 	= (props) => {
	const uComponentname 	= Kasselisih.name;
	const {
		prosesExpired, setLoading, showToast, setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uEfekapp		= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uHTMLstyleTabel= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom= useSelector(state => state.gIsScrollBottom);
	const uTabelSelectObj= useSelector(state => state.gInitTabelSelected);
	const uIsEOMExecuted= useSelector(state => state.gIsEOMExecuted);

	//---VAR_GLOBALS--/
	const uSettingObj 	= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted
	const uIsInitbefore = ((JSON.stringify(uTabelSelectObj)||"{}")!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);

	const uMaxData							= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uFirstload,setFirstload]			= React.useState("YA");

	//---DLG_HAPUS--/
	const [uIsShowDlghapus,setShowDlghapus]	= React.useState(false);
	const [uDlghapusheader,setDlghapusheader]= React.useState("");
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpKethapus,setInpKethapus]		= React.useState("");
	//---END DLG_HAPUS--/

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//---LOOKUP--/
	//---END LOOKUP--/

	const [uElBtndlgsimpan,setElBtndlgsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata	= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//---HANDLE--/
	const hdlToggleDlghapus=()=>{ setShowDlghapus(false); }
	const hdlKlikHapus=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);
		
		setDlghapusheader((uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			(UFunc.HariAngka(uDatamainArr[vObjdx].tanggal)+" "+(uDatamainArr[vObjdx].jenis || "")).toUpperCase());
		setShowDlghapus(true);
	}
	const hdlKlikSimpanDlghapus=()=>{
		if((uInpKethapus||"").trim() === "") {
			showToast((uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi")); 
			document.getElementById("inpketerangan").focus();
			return;
		}

		apiProseshapus();
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initViewjml = () => {
		const vKeyword = uKeywordObj.kwd_kasselisih || "";
		if (vKeyword.length > 2) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFormAwal = () => {
		setTabelIDPilih(0);

		setInpKethapus("");
		setDlghapusheader("");
	}
	//---END INIT_FUNCTION--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_kasselisih || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width={30} className="px-1">No</th>
		<th className="px-1">
			<CRow className="mx-0">
			<CCol md="3" lg="2" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.caption_kasselisih||"Kas Selisih"}</CCol>
			<CCol md="4" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
			<CCol md="1" lg="2" className="">{uBahasaObj.word_nilai||"word_nilai"} (Rp)</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
		</th>
		</tr>

		<tr className="d-md-none classfontsmaller">
		<th width={30} className="p-0"/>
		<th className="p-0"/>
		</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tanggal,tgl_input,jenis,username,status_jurnal,
				keterangan,noref,nilai,kas_caption
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (UFunc.HariAngka(tanggal)+" "+jenis).toUpperCase();

			const vTanggal	= vKeyword==="" ? UFunc.HariAngka(tanggal) 
				: (UFunc.HariAngka(tanggal)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vJenis= vKeyword==="" ? (jenis||"") 
				: (jenis||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUsername= vKeyword==="" ? (username||"") 
				: (username||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKascaption= vKeyword==="" ? (kas_caption||"UNDF") 
				: (kas_caption||"UNDF").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			let vKeterangan= vKeyword==="" ? (keterangan||"") 
				: (keterangan||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			let vNoref= vKeyword==="" ? (noref||"") 
				: (noref||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: (UFunc.WaktuAngka(tgl_input)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			const vColorstatustembak = (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";
			vKeterangan		= vKeterangan===""&&vNoref==="" ? "-" : vKeterangan;
			vNoref 			= vNoref==="" ? "" 
				: (uBahasaObj.word_noref||"word_noref")+": <strong>"+vNoref+"</strong>";

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>
				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="3" lg="2" className="text-start px-1">
					<div className="d-flex justify-content-between d-md-block">
					<div>
						<span className="d-md-none text-info">{UFunc.renderHTML(vTanggal)}</span>
						<span className="d-none d-md-inline">{UFunc.renderHTML(vTanggal)}</span>
						{uTokenObj.userhak === "FREEROLE" && (
						<>
							<span className="mx-1">&middot;</span>
							<span className="classfontsmaller"><small>({id})</small></span>
						</>
						)}
					</div>

					{(uIsAllowedhapus && uIsEOMExecuted) && (
					<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm" className="p-0"
							color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_hapusdata)}
						</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>
					)}
					</div>

					<div className="classfontsmaller text-danger d-none d-md-block">
					<small>[
						{(tgl_input||"")!=="" && (
						<>
						{UFunc.renderHTML(vTglInput)}
						<span className="mx-1">&middot;</span>
						</>
						)}
						{UFunc.renderHTML(vUsername.toUpperCase())}
					]</small>
					</div>
				</CCol>

				<CCol xs="12" className="d-md-none px-1 classborderbottom my-2"/>

				<CCol xs="5" className="d-md-none px-1 text-start">{uBahasaObj.caption_kasselisih||"Kas Selisih	"}</CCol>
				<CCol xs="7" md="3" className="px-1 text-md-start">
					<div className="text-info">{UFunc.renderHTML(vKascaption)}</div>

					{(uTokenObj.userhak==="FREEROLE" && (status_jurnal||"")!=="") && (
						<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
						</div>
					)}
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_sjurnal||"S.Jurnal"}</CCol>
				<CCol xs="8" md="4" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal || "-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="12" className="d-md-none px-1 classborderbottom my-2"/>

				<CCol xs="3" className="d-md-none px-1 text-start classfontsmaller">{uBahasaObj.word_keterangan}</CCol>
				<CCol xs="9" md="4" className="px-1 text-md-start">
					<small className="d-block fst-italic">{UFunc.renderHTML(vKeterangan)}</small>
					<div className="classfontsmaller d-none d-md-block">{UFunc.renderHTML(vNoref.toUpperCase())}</div>
				</CCol>

				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.word_noref}</CCol>
				<CCol xs="8" md="4" className="px-1 d-md-none classfontsmaller">
					<small>{UFunc.renderHTML(vNoref.toUpperCase())}</small>
				</CCol>

				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.word_inputby || "Inputby"}</CCol>
				<CCol xs="8" md="4" className="px-1 d-md-none classfontsmaller text-danger">
					<small>[
						{(tgl_input||"")!=="" && (
						<>
						{UFunc.renderHTML(vTglInput)}
						<span className="mx-1">&middot;</span>
						</>
						)}
						{UFunc.renderHTML(vUsername.toUpperCase())}
					]</small>
				</CCol>

				<CCol xs="12" className="d-md-none px-1 classborderbottom my-2"/>

				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.word_nilai} (Rp)</CCol>
				<CCol xs="8" md="1" lg="2" className="px-1">
					<b className="text-success d-md-none d-lg-block">{UFunc.formatAngka(nilai)}</b>
					<small className="text-success d-none d-md-block d-lg-none">{UFunc.formatAngka(nilai)}</small>
				</CCol>

				<CCol md="1" className="px-1 text-md-center d-none d-md-block">
					{(uIsAllowedhapus && uIsEOMExecuted) ? (
					<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer classikontabel classikonhapus"
						onClick={()=>hdlKlikHapus(id)} />
					</CTooltip>
					) : (<>&middot;</>)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	//--END CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname 	= apiLoaddata.name;
		_PAGE		= parseInt(_PAGE)||1;
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_kasselisih || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasselisih/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}
				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProseshapus = () => {
		uFuncname 	= apiProseshapus.name;
		if(uTabelIDPilih <= 0) {showToast("[0] ERROR !"); return;}
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);

		setLoading(true);
		uElBtndlgsimpan.disabled = true;

		/*//---TESTING_FRONTEND--/
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_kethapus : uInpKethapus,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasselisih/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtndlgsimpan.disabled = false;

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
				hdlToggleDlghapus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtndlgsimpan.disabled = true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtndlgsimpan.disabled = false;
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{//--JIKA_PAGINATION_DIATUR_BACKEND--/
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);	
	React.useEffect(()=>{
		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) { apiLoaddata(); } 

		return()=>{ setDatamainArr([]); }
	},[]);
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= uTabelSelectObj.initID;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+vInitID);
		setTabelRowSelect(vRowIdx);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]); //-->JIKA_PAGINATION_DIATUR_BACKEND 
	React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;

		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		
		setJmlData(0);
		setDatamainArr([]);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;

		uNavigate("/subkeuangan/kasselisih/forminput");
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!uIsShowDlghapus) { initFormAwal(); return; }

		let vTimeout = setTimeout(() => {
			clearTimeout(vTimeout);
			setElBtndlgsimpan(document.getElementById("btnDialogSimpan"));
		},350);
	},[uIsShowDlghapus]);
	React.useEffect(()=>{
		if(!uElJmldata) return
		initViewjml();
	},[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initViewjml();
	},[uJmlData]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<div className="d-none">uIsEOMExecuted = {uIsEOMExecuted.toString()}</div>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_kasselisih || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block">{uBahasaObj.caption_rekapinput||"Rekap Input"}</span>
			</small>

			<div className="d-lg-none"/>
			<div  className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyFormHapus
			value={uInpKethapus}
			onCHvalue={(e)=>setInpKethapus(e.target.value)}
			onSimpan={()=>hdlKlikSimpanDlghapus()}
			show={uIsShowDlghapus} 
			dialogHeader={uDlghapusheader}
			toggle={hdlToggleDlghapus}/>
		</>
	)//>
}
	
export default Kasselisih;
