import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CRow,
	CCol,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CListGroup,
	CListGroupItem,
	CSpinner,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
	MyPagination,
	//MyDialogform,
	MyDialogview,
	MyFormHapus,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     = require('../../package.json');
const Konsinyasi = (props) => {
	const uComponentname 	= Konsinyasi.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uHTMLstyleTabel=useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp				= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const [uEfekview2,setEfekview2] = React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 	= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted
	const [uFirstload,setFirstload]			= React.useState("YA");

	const uMaxData							= 4//isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [isShowForm,setShowForm]			= React.useState(false);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpKethapus,setInpKethapus]		= React.useState("");

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--DLG_VIEW--/
	const [uDlgviewDataObj,setDlgviewDataObj]	= React.useState({});
	const [uISDlgviewshow,setDlgviewshow]		= React.useState(false);
	//--END DLG_VIEW--/

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata	= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowForm(false); }
	const hdlKlikDetil = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);

		if((uDatamainArr[vObjdx].items_arr||[]).length > 0) {
			uDlgviewDataObj.dataobj		= uDatamainArr[vObjdx] || {};
			uDlgviewDataObj.header 		= "Detil: "+(uDatamainArr[vObjdx].supplier||"").toUpperCase();

			setDlgviewshow(true); return;
		}

		apiLoaditem(_IDTABEL);
	}
	const hdlKlikHapus=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);
		
		setDialogHeader((uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			(UFunc.HariAngka(uDatamainArr[vObjdx].tanggal)+" "+(uDatamainArr[vObjdx].supplier || "")+
				(uDatamainArr[vObjdx].nonota!==""?" ("+uDatamainArr[vObjdx].nonota+")" : "")+
				"").toUpperCase());
		setShowForm(true);
	}
	const hdlKlikSimpanHapus=()=>{
		if((uInpKethapus||"").trim() === "") {
			document.getElementById("inpkethapus") 
				&& document.getElementById("inpkethapus").focus();

			showToast((uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi")); 
			return;
		}

		apiProseshapus();
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initJmldata=()=>{
		const vKeyword = uKeywordObj.kwd_konsinyasi || "";
		if (vKeyword.length > 2) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFormAwal=()=>{
		setTabelIDPilih(0);
		setInpKethapus("");
	}

	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_konsinyasi || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		//console.log("(loadTabelBody) = vDataviewArr = "+JSON.stringify(vDataviewArr));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width={30} className="px-1"><small>No</small></th>
		<th className="px-1">
			<small>
			<CRow className="mx-0 p-0">
			<CCol md="3" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
			<CCol md="4" className="text-start">{uBahasaObj.word_supplier||"word_supplier"}</CCol>
			<CCol md="2" className="text-start">{uBahasaObj.word_nonota||"word_nonota"}</CCol>
			<CCol md="2" className="">{uBahasaObj.word_total||"word_total"} (Rp)</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
			</small>
		</th>
		</tr>
		<tr className="d-md-none classfontsmaller">
			<th width={30} className="p-0"/>
			<th className="p-0"/>
		</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tanggal,tgl_input,supplier,username,terbayar,status_jurnal,
				diskon,b_transport,nonota,nilai,init_expired,
				is_expand,is_loading
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (UFunc.HariAngka(tanggal)+" "+supplier+
				((nonota||"")!=""?" ("+nonota+")":"")
				).toUpperCase();

			const vTanggal	= vKeyword==="" ? UFunc.HariAngka(tanggal) 
				: (UFunc.HariAngka(tanggal)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vSupplier= vKeyword==="" ? (supplier||"") 
				: (supplier||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUsername= vKeyword==="" ? UFunc.Usercaptionfilter(username||"") 
				: UFunc.Usercaptionfilter(username||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNonota= vKeyword==="" ? (nonota||"") 
				: (nonota||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: (UFunc.WaktuAngka(tgl_input)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			const vColorstatustembak= (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{(vNumber)+"."}</td>
				<td className="px-1">	
				<CRow className="mx-0">
				<CCol md="3" className="px-1 text-start d-none d-md-block">
					<div>
						{UFunc.renderHTML(vTanggal)}
						{(uTokenObj.userhak === "FREEROLE") && (
							<span className="classfontsmaller"><small> &middot; ({id})</small></span>
						)}
					</div>

					<div className="classfontsmaller text-danger">
					<small>[
						{(tgl_input||"")!=="" && (
						<>
							{UFunc.renderHTML(vTglInput)}
							<span className="mx-1">&middot;</span>
						</>
						)}			
						{UFunc.renderHTML(vUsername.toUpperCase())}
					]</small>
					</div>
				</CCol>

				<CCol xs="12" md="4" className="px-1 text-start">
					<div className="d-flex justify-content-between">
					<div className="text-info">{UFunc.renderHTML(vSupplier)}</div>

					{(is_loading||false)===true ? (
						<CSpinner color="dark" className="d-md-none" size="sm"/>
					) : (
					<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm" className="p-0"
							color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikDetil(id)}>
							<CIcon className="classikondetil me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_lihatdetil)}
						</CDropdownItem>
						
						{(uIsAllowedhapus && uIsEOMExecuted && (init_expired||"YA")==="TIDAK") && (
						<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_hapusdata)}
						</CDropdownItem>
						)}
						</CDropdownMenu>
					</CDropdown>
					)}
					</div>

					{((status_jurnal||"")!=="" && uTokenObj.userhak==="FREEROLE") && (
					<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
					</div>
					)}
				</CCol>

				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_tanggal}</CCol>
				<CCol xs="8" md="2" className="px-1 d-md-none">
					{UFunc.renderHTML(vTanggal)}
					{(uTokenObj.userhak === "FREEROLE") && (
						<span className="classfontsmaller"><small> &middot; ({id})</small></span>
					)}
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal||"-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_nonota}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-start">
					<b>{UFunc.renderHTML(vNonota)}</b>
				</CCol>
				
				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_total}</CCol>
				<CCol xs="8" md="2" className="px-1 text-success">
					<span className="d-md-none">Rp</span>{UFunc.formatAngka(nilai)}
				</CCol>

				<CCol md="1" className="px-1 text-center d-none d-md-block">
					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
					<CLink className="classcursorpointer classikontabel classikondetil"
						onClick={()=>hdlKlikDetil(id)}/>
					{((uIsAllowedhapus) && (init_expired||"TIDAK")==="TIDAK"  && uIsEOMExecuted)
						&& (
					<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
					<CLink 
						className="classikontabel classikonhapus classcursorpointer"
						onClick={()=>hdlKlikHapus(id)}/>
					</CTooltip>
					)}
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>
		if(UFunc.isEmpty(uDlgviewDataObj)) return (<></>)//>

		const vDataviewObj 	= uDlgviewDataObj.dataobj || {};
		let vSubtotal		= 0;
		return (
		<small>
		<CRow>
		<CCol lg="6">
			<CCard className="classcardbg mb-3">
			<CCardHeader className="py-1">Data Konsinyasi</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
				<div className="px-1">Tgl Konsinyasi</div>
				<div className="px-1 text-end">{UFunc.HariAngka(vDataviewObj.tanggal)}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">Supplier</div>
				<div className="px-1 text-end text-info">{vDataviewObj.supplier}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">No.Nota</div>
				<div className="px-1 text-end fw-bolder">{vDataviewObj.nonota}</div>
				</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CRow>
		<CCol>
			<CCard className="classcardbg mb-3">
			<CCardHeader className="py-1">Data Item</CCardHeader>
			<CCardBody className="px-0">
			<table className="table table-border" id="idtabeldetil">
			<thead className="d-none">
			<tr>
				<th width="5%" className="px-1">No</th>
				<th className="px-1">&nbsp;</th>
			</tr>
			</thead>
			<tbody>
			{(vDataviewObj.items_arr||[]).map((vItems,vKeys)=>{
				const {
					item_id,nama_barang,hpp_sat,h_jual_sat,qty,satuan
				} = vItems;

				vSubtotal	+= parseFloat(hpp_sat||0) * parseFloat(qty||0);

				return (
				<tr className="align-top text-end" key={vKeys}>
					<td className="px-1 pe-0">{vKeys+1}.</td>
					<td className="px-1">
						<CRow className="mx-1">
						<CCol md="5" className="text-start px-1 text-capitalize">{nama_barang}</CCol>
						
						<CCol xs="12" className="px-1 classborderbottom d-md-none my-2"/>

						<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_qty}</CCol>
						<CCol xs="8" md="1" className="px-1 px-md-0 text-md-center">
							<b className="text-primary">{UFunc.formatAngka(qty)}</b>
							<small>{" "}{satuan||"UNIT"}</small>
						</CCol>

						<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_hargajual}</CCol>
						<CCol xs="8" md="2" className="px-1">{UFunc.formatAngka(h_jual_sat)}</CCol>

						<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_nilaikonsi}</CCol>
						<CCol xs="8" md="2" className="px-1 fw-bolder">{UFunc.formatAngka(hpp_sat)}</CCol>

						<CCol xs="12" className="px-1 classborderbottom d-md-none my-2"/>
						<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_total}</CCol>
						<CCol xs="8" md="2" className="px-1">
							<span className="text-success">{UFunc.formatAngka(qty * hpp_sat)}</span>
						</CCol>
						</CRow>
					</td>
				</tr>
				)
			})}
			</tbody>
			<tfoot>
			<tr className="text-end bg-light">
				<td className="px-1"/>
				<td className="px-1">
				<CRow className="mx-1">
					<CCol className="px-1">JUMLAH</CCol>
					<CCol className="px-1 fw-bolder text-success">
						{UFunc.formatAngka(vSubtotal)}
					</CCol>
				</CRow>
				</td>
			</tr>
			</tfoot>
			</table>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</small>
		)//>
	}
	//---END Init_LOAD--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname = apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1;
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_konsinyasi || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konsinyasi/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		uFuncname = apiLoaddata.name;
		_IDTABEL		= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_loading = true;
		setEfekview2(!uEfekview2)

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});

		const vURLs	= pjson.homepage+"api/api_konsinyasi/ld_detil";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_loading = false;
			if(output_string.tampil) {
				uDatamainArr[vArridx].items_arr 	= JSON.parse(output_string.datadetil||"[]");

				uDlgviewDataObj.dataobj		= uDatamainArr[vArridx] || {};
				uDlgviewDataObj.header 		= "Detil: "+(uDatamainArr[vArridx].supplier||"").toUpperCase();

				setDlgviewshow(true);
			} else {
				setEfekview(!uEfekview);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_loading = false;
			setEfekview(!uEfekview);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = () => {
		uFuncname 	= apiProseshapus.name;
		if(uTabelIDPilih <= 0) {showToast("[0] ERROR !"); return;}
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_kethapus : uInpKethapus,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konsinyasi/pr_hapus";

		setLoading(true);
		uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = true)
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false)

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
				hdlToggleForm();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtnDlgsimpan.disabled = true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false)
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();
		
		return()=>{ 
			hdlToggleForm(); 
			setDatamainArr([]); 
		}
	},[]);
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;

		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uIsEOMExecuted) return

		uNavigate("/subkeuangan/konsinyasi/formmain");
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return; }

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			const vElFocus = document.getElementById("inpketerangan");
			vElFocus && vElFocus.focus();
		},350);
	},[isShowForm]);
	React.useEffect(()=>{
		if(!uISDlgviewshow) {
			setDlgviewDataObj({}); return;
		}
	},[uISDlgviewshow]);
	React.useEffect(()=>{
		if(!uElJmldata) return
		initJmldata()
	},[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)
	
	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_konsinyasi || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block">{uBahasaObj.caption_rekapinput||"Rekap Input"}</span>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyFormHapus
			value={uInpKethapus}
			onCHvalue={(e)=>setInpKethapus(e.target.value)}
			onSimpan={()=>hdlKlikSimpanHapus()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}/>

		<MyDialogview
			options={{size:"lg"}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewDataObj.header}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>
		</>
	)//>
}
	
export default Konsinyasi;
