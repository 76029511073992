import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderBrand,
	CHeaderToggler,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CFooter,
	CRow,
	CCol,
	CForm,
	CFormInput,
	CFormSelect,
	CFormCheck,
	CInputGroup,
	CInputGroupText,
	CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyDialoglookup,
	MyProfileDetil,
	MyPage500,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
let pjson     = require('../../../package.json');

const Kasselisihform = (props) => {
	const uComponentname	= Kasselisihform.name;
	const { 
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate 		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted) || true;

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uKasGLArrObj,setKasGLArrObj]		= React.useState(JSON.parse(localStorage.getItem("kasgllist")));

	const [uInpStatustembak,setInpStatustembak]= React.useState("REGULER");
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate());
	const [uInpKasID,setInpKasID]			= React.useState("0");
	const [uInpKascaption,setInpKascaption]	= React.useState("");
	const [uInpJenisselisih,setInpJenisselisih]= React.useState("LEBIH");
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");
	const [uInpNoref,setInpNoref]			= React.useState("");
	const [uInpNilai,setInpNilai]			= React.useState(0);
	const [uIsFormprocess,setIsFormprocess]	= React.useState(false);

	//---LOOKUP--/
	const [uIsDlglookupshow,setDlglookupshow]		= React.useState(false);
	const [uDlglookupheader,setDlglookupheader]		= React.useState("");
	const [uInpLookupKwd,setInpLookupKwd]			= React.useState("");
	const [uDatalookupArr,setDatalookupArr]			= React.useState([]);
	//---END LOOKUP--/

	//---HANDEL--/
	const hdlToggleLookup=()=>{ setDlglookupshow(false) }
	const hdlKlikPilihLookup = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM||0);
		if(_IDITEM <= 0) return;

		const vObjidx 	= UFunc.getObjectIndeks(uDatalookupArr,_IDITEM);
		const vValue	= uDatalookupArr[vObjidx].id||"0";
		const vCaption	= uDatalookupArr[vObjidx].caption||"";
		const vElstmp = document.getElementById("inpket")
			
		setInpKasID(vValue);
		setInpKascaption(vCaption);
		hdlToggleLookup();

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElnextfocus = vElstmp;
			vElnextfocus && vElnextfocus.focus();
		},350)
	}
	const hdlKlikKaslookup = () => {
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_akunkas||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKlikSimpan = async() => {
		//---VALIDASI--/
		let vELfocus 	= document.getElementById("inpstatustembak");
		if(uInpStatustembak!=="REGULER" && uInpStatustembak!=="TEMBAK") {
			vELfocus && vELfocus.focus();
			showToast(uBahasaObj.caption_tanggal+" "+uBahasaObj.caption_invalid+"..");
			return;
		}
		vELfocus 	= document.getElementById("inptanggal");
		if(UFunc.isEmpty(uInpTanggal)) {
			vELfocus && vELfocus.focus();
			showToast(uBahasaObj.caption_tanggal+" "+uBahasaObj.caption_invalid+"..");
			return;
		} else {
			const vTanggalDt 	= new Date(uInpTanggal);

			const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
			if(vDateBeyond) {
				vELfocus && vELfocus.focus();
				showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
				return;
			}
			const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
			if(vDateExceededBl) {
				vELfocus && vELfocus.focus();
				showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
				return;
			}
			//alert("("+uComponentname+") "+cFuncname+" vDateExceededBl = "+vDateExceededBl); return;
		}

		vELfocus 	= document.getElementById("inpnilai");
		if((parseInt(uInpNilai)||0) <= 0) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.word_nilai||"word_nilai")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		vELfocus 	= document.getElementById("inpkasid");
		if((parseInt(uInpKasID)||0) === 0) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		vELfocus 	= document.getElementById("inpket");
		if((uInpKeterangan||"") === "") {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.word_keterangan||"word_keterangan")+ 
				" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+
				"..");
			return;
		}
		//---END VALIDASI--/

		setIsFormprocess(true);
		if(await Konfirm("<b>SIMPAN DATA</b>"+
			"<br/>Apakah Anda Yakin ?")) {
			apiProsessimpan(); return;
		}
		setIsFormprocess(false);
	}
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			_EV.preventDefault();
			_EV.stopPropagation();

			const vEltombol = document.getElementById("btnSimpan");
			vEltombol && (vEltombol.click());
		}
	}
	//---END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		setInpStatustembak("REGULER");
		setInpTanggal(UFunc.DbDate());
		setInpKasID(0);
		setInpKascaption("");
		setInpJenisselisih("LEBIH");
		setInpKeterangan("");
		setInpNoref("");
		setInpNilai(0);
	}
	const contentFormmain=()=>{
		if(uHTMLError500) return "";

		return (
			<CForm>
			{uTokenObj.userhak === "FREEROLE" && (
			<>
			<CRow className="mx-md-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
				</CCol>
				<CCol xs="8" md="4" className="text-end">
					<CFormSelect size="sm"
						onChange={(e)=>setInpStatustembak(e.target.value)}
						value={uInpStatustembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
					</CFormSelect>
				</CCol>
				<CCol md="4" className="d-none d-md-block"/>
				<CCol xs="4" className=""/>
				<CCol className="classpetunjuk">
					*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL
				</CCol>
			</CRow>
			
			<div className="mx-0 my-3 classborderbottom"/>
			</>
			)}

			<CRow className="mx-md-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_tanggal||"word_tanggal"}
				</CCol>
				<CCol xs="8" md="4" className="">
					<CFormInput 
						size="sm"
						type="date"
						onChange={(e)=>setInpTanggal(e.target.value)}
						value={uInpTanggal}
						name="inptanggal"
						id="inptanggal"/>
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="mx-md-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_jenisselisih||"caption_jenisselisih"}
				</CCol>
				<CCol xs="8" className="text-end pe-0">
					<CInputGroup size="sm">
					<CInputGroupText className="bg-light px-lg-3">
					<CFormCheck size="sm" type="radio"
						className="m-0"
						value="LEBIH"
						onClick={(e)=>setInpJenisselisih(e.target.value)}
						checked={uInpJenisselisih==="LEBIH"}
						label={(uBahasaObj.caption_selisihlebih||"caption_selisihlebih")}
						name="inpjenis"
						id="inpjenislebih"/>
					</CInputGroupText>
					<CInputGroupText className="bg-light px-lg-3">
					<CFormCheck size="sm" type="radio"
						className="m-0"
						value="KURANG"
						onClick={(e)=>setInpJenisselisih(e.target.value)}
						checked={uInpJenisselisih!=="LEBIH"}
						label={(uBahasaObj.caption_selisihkurang||"caption_selisihkurang")}
						name="inpjenis"
						id="inpjeniskurang"/>
					</CInputGroupText>
					</CInputGroup>
				</CCol>
			</CRow>
			
			<CRow className="mx-md-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_nilai||"word_nilai"}
				</CCol>
				<CCol xs="8" md="4" className="text-end">
					<NumberFormat 
						value={uInpNilai}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpNilai(values.value)}
						renderText={values => (
							<CFormInput 
								value={values}
								size="sm"
								className="text-end"
								onChange={(e) => setInpNilai(e.target.value)}
								onFocus={(e)=>e.target.select()}
							id="inpnilai"/>
						)} 
						prefix={isMobile?"":"Rp"}/>
				</CCol>
			</CRow>
			<CRow className="mx-md-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_kasselisih||"caption_kasselisih"}
				</CCol>
				<CCol xs="8" md="4" className="text-end">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						readOnly={true}
						value={uInpKascaption}
						onClick={()=>hdlKlikKaslookup()}
						placeholder={"--"+((uBahasaObj.word_pilih)+" "+(uBahasaObj.caption_kasselisih)).toUpperCase()+".."}
						className="classbgdisabled"
						id="inpkascaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikKaslookup()}
						id="btnkasloookup">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
					</CInputGroup>
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="mx-md-0 my-2">
				<CCol md="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_keterangan||"word_keterangan"}
				</CCol>
				<CCol xs="12" md="8" lg="7" className="text-end">
					<CFormInput maxLength="255" 
						size="sm"
						type="text"
						onChange={(e)=>setInpKeterangan(e.target.value)}
						value={uInpKeterangan}
						name="inpket"
						id="inpket"/>
				</CCol>
			</CRow>

			<div className="my-3 d-md-none classborderbottom"/>
			
			<CRow className="mx-md-0 my-2">
				<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.caption_nobukti||"caption_nobukti"}
				</CCol>
				<CCol xs="8" md="4" className="text-end">
					<CFormInput maxLength={50} 
						size="sm"
						className="text-uppercase"
						type="text"
						onChange={(e)=>setInpNoref(e.target.value)}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
						value={uInpNoref}
						name="inpket"
						id="inpket"/>
				</CCol>
			</CRow>
			</CForm>
		)
	}
	//--END INIT--/

	//--API--/
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		/*//--TESTING_FRONTEND--//
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_statustembak : uInpStatustembak,
			send_tanggal : uInpTanggal,
			send_kasid : uInpKasID,
			send_jenis : uInpJenisselisih,
			send_keterangan : uInpKeterangan,
			send_noref : uInpNoref,
			send_nilai: uInpNilai,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasselisih/pr_simpan";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setIsFormprocess(false);

			if(output_string.sukses) {
				let vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				if(output_string.pesankhusus) vPesan = output_string.pesankhusus;
				showToast(vPesan,"SUKSES");
				initFormAwal();

				if(!isMobile) document.getElementById("inptanggal").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const  vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setIsFormprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		if(!uIsEOMExecuted) uNavigate(-1);

		setKasGLArrObj(JSON.parse(localStorage.getItem("kasgllist")||"[]"));

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		
		if(!isMobile) {
			const vElsTanggal = document.getElementById("inptanggal");
			vElsTanggal && vElsTanggal.focus();
		}

		return ()=>{
			setKasGLArrObj([]);
			setDatalookupArr([]);
			initFormAwal();
		}
	},[]);
	React.useEffect(()=>{
		if(uKasGLArrObj.length <= 0) return;

		let vTmpArr	= uKasGLArrObj.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}	
		})
		setDatalookupArr(vTmpArr)
	},[uKasGLArrObj]);
	//---END EFFECT---/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="classheadermain"
			style={{height:64}}>
			<CHeaderToggler className="px-0 me-2"
				onClick={() => {uNavigate(-1);}}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_kasselisih||"menus_kasselisih").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
			<CContainer xxl className={"px-0"}>
				<CCard className="classcardbg">
				<CCardHeader>
				<small>{uBahasaObj.caption_forminput||"caption_forminput"}</small>
				</CCardHeader>

				<CCardBody>{contentFormmain()}</CCardBody>
				</CCard>
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uIsFormprocess}
				onClick={()=>hdlKlikSimpan()}
				id="btnSimpan">Simpan [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleLookup()}
			dialogHeader={uDlglookupheader||"dialogHeader"}
			inputValue={uInpLookupKwd}
			dataItemArr={uDatalookupArr} 
			onKlikPilihItem={hdlKlikPilihLookup}
			id="iddialoglookup"/>
		</>
	)
}

export default Kasselisihform;
