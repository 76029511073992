import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CFooter,
	CRow,
	CCol,
	CForm,
	CFormInput,
	CFormSelect,
	CFormCheck,
	CButton,
	CInputGroup,
	CInputGroupText,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyDialoglookup,
	MyDialogform,
	MyProfileDetil,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
let pjson     = require('../../../package.json');

const Pecahstoktambah = (props) => {
	const uComponentname	= Pecahstoktambah.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 
	let uFuncname;

	const uNavigate 		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uDefaultSetting	= useSelector(state => state.gListDefaultSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview]			= React.useState(false);

	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const [uInpNamabesar,setInpNamabesar]		= React.useState("");
	const [uInpBesarID,setInpBesarID]			= React.useState(0);
	const [uInpBesarSisa,setInpBesarSisa]		= React.useState(0);
	const [uInpBesarSatuan,setInpBesarSatuan]	= React.useState("");
	const [uInpJmlbesar,setInpJmlbesar]			= React.useState(0);
	const [uInpBesarhpp,setInpBesarhpp]			= React.useState(0);

	const [uInpNamakecil,setInpNamakecil]= React.useState("");
	const [uInpKecilID,setInpKecilID]			= React.useState(0);
	const [uInpKecilhjuallast,setInpKecilhjuallast]	= React.useState(0);
	const [uInpKecilhjual,setInpKecilhjual]		= React.useState(0);
	const [uInpKecilSatuan,setInpKecilSatuan]	= React.useState("");
	const [uInpJmlkecil,setInpJmlkecil]			= React.useState(0);
	const [uInpKecilperbox,setInpKecilperbox]	= React.useState(0);

	const [uIsFormprocess,setIsFormprocess]		= React.useState(false);
	const [uIsBesarloading,setBesarloading]		= React.useState(false);
	const [uIsKecilloading,setKecilloading]		= React.useState(false);

	//--LOOKUP--/
	const [uIsLookupShow,setLookupShow]			= React.useState(false);
	const [uInpLookupInput,setInpLookupInput]	= React.useState("");
	const [uLookupItemArr,setLookupItemArr]		= React.useState([]);//-->HARUS_ADA_ID_DAN_CAPTION
	const [uLookupinit,setLookupinit]			= React.useState("");
	const [uLookupHeader,setLookupHeader]		= React.useState("");
	//--END LOOKUP--/

	//---REGISTER_BARANG--/
	const [uIsShowForm,setShowForm]				= React.useState(false);
	const [uIsRegisterDone,setRegisterDone]		= React.useState(false);
	const [uFormHeader,setFormHeader]			= React.useState("");
	const [uBarangjenisArr,setBarangjenisArr]	= React.useState([{value:"0",caption:"UNDF"}]);

	const [uInpFormBarangkode,setInpFormBarangkode]		= React.useState("");
	const [uInpFormBarangnama,setInpFormBarangnama]		= React.useState("");
	const [uInpFormBarangjenisID,setInpFormBarangjenisID]= React.useState("0");
	const [uInpFormBarangjenis,setInpFormBarangjenis]	= React.useState("");
	const [uInpFormBarangSatuan,setInpFormBarangSatuan]= React.useState("PCS");
	//---END REGISTER_BARANG--/

	//---HANDEL--/
	const hdlKlikReset=async()=>{
		const vFunction	= () =>{ 
			setInpNamabesar("");
			setInpNamakecil("");

			initAwalMain();
			//apiLoadAwal();
		}

		const vInitChange = (uInpNamabesar !== "" || (parseInt(uInpBesarID) > 0) 
			|| (parseInt(uInpJmlbesar) > 0) || uInpNamakecil !== "" 
			|| (parseInt(uInpKecilID) > 0)  || (parseInt(uInpJmlkecil) > 0) 
			|| (parseInt(uInpKecilhjual) > 0) 
			) 
			? true : false

		const vConfirm = uBahasaObj.confirm_reset||"confirm_reset";
		if(vInitChange)
			{if(await Konfirm(vConfirm)) vFunction(); }
		else apiLoadAwal();
	}

	const hdlKlikBarangRegister=()=>{
		setFormHeader((uBahasaObj.caption_tambahmaster||"caption_tambahmaster")+
			": "+(uBahasaObj.word_barang||"word_barang").toUpperCase());
		setShowForm(true);
	}
	const hdlToggleForm=()=>{setShowForm(false);}
	const hdlKlikFormSimpan=()=>{
		//---VALIDASI--/
		if(uInpFormBarangkode.trim() === "") {
			document.getElementById("inpformbarangkode").focus();
			showToast((uBahasaObj.caption_kodebarang||"caption_kodebarang")+
				" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
			return;
		}
		if(uInpFormBarangnama.trim() === "") {
			document.getElementById("inpformbarangnama").focus();
			showToast((uBahasaObj.caption_namabarang||"caption_namabarang")+
				" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
			return;
		}
		if((parseInt(uInpFormBarangjenisID)||0) <= 0) {
			document.getElementById("inpformbarangjenis").focus();
			showToast((uBahasaObj.caption_jenisbarang||"caption_jenisbarang")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		if(uInpFormBarangSatuan.trim() === "") {
			document.getElementById("inpformbarangnama").focus();
			showToast((uBahasaObj.word_satuan||"word_satuan")+
				" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
			return;
		}
		//---END VALIDASI--/

		apiSimpanRegister();
	}

	const hdlToggleLookup=()=>{setLookupShow(false);}
	const hdlKeyDownNamaBesar=(_EV)=>{
		if(_EV.keyCode !== 13) return;
		if(uInpNamabesar.trim()==="") {
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uLookupItemArr.filter((vItem)=>
			vItem.caption.toUpperCase().includes(uInpNamabesar.toUpperCase()));
		
		if (vArrCariMaster.length === 1) {

			apiPilihBesar(vArrCariMaster[0].id);
		} else {
			hdlKlikBesarLookUp();
		}
	}
	const hdlKeyDownNamaKecil=(_EV)=>{
		if(_EV.keyCode !== 13) return;
		if(uInpNamakecil.trim()==="") {
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uLookupItemArr.filter((vItem)=>
			vItem.caption.toUpperCase().includes(uInpNamakecil.toUpperCase()));
		
		if (vArrCariMaster.length === 1) {
			apiPilihKecil(vArrCariMaster[0].id);
		} else {
			hdlKlikKecilLookUp();
		}
	}
	const hdlKlikBesarLookUp=()=>{
		setLookupinit("BESAR");
		setInpLookupInput(uInpNamabesar);
		setLookupHeader((uBahasaObj.caption_lookupitem||"caption_lookupitem")+": "+
			(uBahasaObj.caption_barangbox||"caption_barangbox").toUpperCase()
			);
		setLookupShow(true);
	}
	const hdlKlikKecilLookUp=()=>{
		if((parseInt(uInpBesarID)||0) <= 0) {
			setInpNamakecil("");
			showToast((uBahasaObj.caption_barangbox||"caption_barangbox")+" "+
				(uBahasaObj.caption_mustchoose||"caption_mustchoose")+" !"
				);
			return;
		}

		setInpLookupInput(uInpNamakecil);
		setLookupinit("KECIL");
		setLookupHeader((uBahasaObj.caption_lookupitem||"caption_lookupitem")+": "+
			(uBahasaObj.caption_barangpecah||"caption_barangpecah").toUpperCase()
			);
		setLookupShow(true);
	}
	const hdlKlikPilihLookup=(_IDITEM)=>{
		const vIdx = UFunc.getObjectIndeks(uLookupItemArr,_IDITEM,"id");
		if(vIdx < 0) return;
		if(uLookupinit === "") return;

		if(uLookupinit==="BESAR") apiPilihBesar(_IDITEM);
		else apiPilihKecil(_IDITEM);
	}

	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			_EV.preventDefault();
			hdlKlikSimpan();
		}
	}
	const hdlKlikSimpan=()=>{
		//---VALIDASI--/
		if((parseInt(uInpBesarID)||0) <= 0) {
			document.getElementById("inpnamabesar").focus();
			showToast((uBahasaObj.caption_barangbox||"caption_barangbox")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		if((parseInt(uInpJmlbesar)||0) <= 0) {
			document.getElementById("inpjmlbesar").focus();
			showToast((uBahasaObj.caption_jmlbox||"caption_jmlbox")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		if((parseInt(uInpKecilID)||0) <= 0) {
			document.getElementById("inpnamakecil").focus();
			showToast((uBahasaObj.caption_barangpecah||"caption_barangpecah")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		if((parseInt(uInpJmlkecil)||0) <= 0) {
			document.getElementById("inpjmlkecil").focus();
			showToast((uBahasaObj.caption_totalpecahan||"caption_totalpecahan")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+" (1)..");
			return;
		}
		if((parseInt(uInpJmlkecil)||0) <= (parseInt(uInpJmlbesar)||0)) {
			document.getElementById("inpjmlkecil").focus();
			showToast((uBahasaObj.caption_totalpecahan||"caption_totalpecahan")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+" (2)..");
			return;
		}
		const vModPecahan	= (parseInt(uInpJmlkecil)||0) % (parseInt(uInpJmlbesar)||0);
		if(vModPecahan > 0) {
			document.getElementById("inpjmlkecil").focus();
			showToast((uBahasaObj.pesan_pecahboxjmlbersisa||"pesan_pecahboxjmlbersisa")+
				"");
			return;
		}
		//---END VALIDASI--/

		apiSimpan();
	}
	//---END HANDEL--/

	//--INIT--/
	const initResetNamaBesar=()=>{
		setInpJmlbesar(0);
		setInpBesarID(0);
		setInpBesarSisa(0);
		setInpBesarSatuan("")
		setInpBesarhpp(0);
	}
	const initResetNamaKecil=()=>{
		setInpJmlkecil(0);
		setInpKecilID(0);
		setInpKecilhjuallast(0);
		setInpKecilhjual(0);
		setInpKecilperbox(0);
		setInpKecilSatuan("")
	}
	const initAwalMain=()=>{
		initResetNamaBesar();
		initResetNamaKecil();
		setLookupinit("");
		setInpNamabesar("")
		setInpNamakecil("")
	}
	const initAwalForm=()=>{
		setInpFormBarangkode("");
		setInpFormBarangnama("");
		setInpFormBarangjenisID(uBarangjenisArr[0].value || "0");
		setInpFormBarangSatuan("PCS");
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		return (
			<CForm className="mx-lg-5 px-lg-5">
			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info pe-0">
					<b className="d-none d-md-block">{uBahasaObj.caption_barangbox||"caption_barangbox"}</b>
					<b className="classfontsmaller"><small className="d-md-none">{uBahasaObj.caption_barangbox||"caption_barangbox"}</small></b>
				</CCol>
				<CCol xs="8" md="5" className="">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						value={uInpNamabesar}
						onKeyDown={(e)=>hdlKeyDownNamaBesar(e)}
						onChange={(e)=>{setInpNamabesar(e.target.value);initResetNamaBesar()}}
						id="inpnamabesar"/>
						<CButton size="sm" color="dark" 
							onClick={hdlKlikBesarLookUp}
							id="btnNamabesarlookup">
							<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
						{uIsBesarloading && (
						<CInputGroupText className="bg-transparent border-0 py-0">
							<CSpinner color="danger" size="sm"/>
						</CInputGroupText>
						)}
					</CInputGroup>
				</CCol>
			</CRow>

			{(parseInt(uInpBesarID)>0) && (
			<CRow className="my-2">
				<CCol xs="4" className=""/>
				<CCol xs="8" md="5" className="">
					<div className="p-2 classborderbox classpetunjuk">
						<div>{uBahasaObj.caption_stoksisa||"caption_stoksisa"}: <strong>{UFunc.formatAngka(uInpBesarSisa)}</strong> {uInpBesarSatuan}</div>
						<div>{uBahasaObj.caption_lasthpp||"caption_lasthpp"}: Rp <strong>{UFunc.formatAngka(uInpBesarhpp)}</strong></div>
					</div>
				</CCol>
			</CRow>
			)}

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_jmlbox||"caption_jmlbox"}
				</CCol>
				<CCol xs="4" md="3" lg="2" className="">
					<CInputGroup size="sm">
					<CFormInput size="sm" type="number"
						value={uInpJmlbesar}
						className="text-end"
						onChange={(e) => setInpJmlbesar(e.target.value)}
						onFocus={(e) => e.target.select()}
					id="inpjmlbesar"/>
					<CInputGroupText className="py-0 fw-bolder">{uInpBesarSatuan||"UNT"}</CInputGroupText>
					</CInputGroup>
				</CCol>
			</CRow>
				
			<div className="my-3 classborderbottom" />
			
			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
					<b className="d-none d-md-block">{uBahasaObj.caption_barangpecah||"caption_barangpecah"}</b>
					<b className="classfontsmaller"><small className="d-md-none">{uBahasaObj.caption_barangpecah||"caption_barangpecah"}</small></b>
				</CCol>
				<CCol xs="8" md="5">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						value={uInpNamakecil}
						onKeyDown={(e)=>hdlKeyDownNamaKecil(e)}
						onChange={(e)=>{setInpNamakecil(e.target.value);initResetNamaKecil()}}
						id="inpnamakecil"/>
						<CButton size="sm" color="dark"
							onClick={hdlKlikKecilLookUp}>
							<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
						
						{uIsKecilloading && (
						<CInputGroupText className="bg-transparent border-0 py-0">
							<CSpinner color="danger" size="sm"/>
						</CInputGroupText>
						)}
					</CInputGroup>
						<CTooltip content={(uBahasaObj.caption_tambahmaster||"caption_tambahmaster")+": "+(uBahasaObj.word_barang||"word_barang").toUpperCase()}>
							<CButton size="sm" color="danger" className="d-none" onClick={hdlKlikBarangRegister}>
								<CIcon icon="cibAddthis"/>
							</CButton>
						</CTooltip>
				</CCol>
			</CRow>

			{(parseInt(uInpKecilID)>0) && (
			<CRow className="my-2 mt-1">
				<CCol xs="4" className=""/>
				<CCol xs="8" md="5" className="">
				<div className="p-2 classborderbox classpetunjuk">
					<div>{uBahasaObj.caption_jmlperbox||"caption_jmlperbox"}:{" "}
						<strong>{UFunc.formatAngka(uInpKecilperbox)} {uInpKecilSatuan} per {uInpBesarSatuan}</strong></div>
					<div>{uBahasaObj.caption_hjualsatuan||"caption_hjualsatuan"}: Rp <strong>{UFunc.formatAngka(uInpKecilhjuallast)}</strong></div>
				</div>
				</CCol>
			</CRow>
			)}

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_totalpecahan||"caption_totalpecahan"}
				</CCol>
				<CCol xs="4" md="3" lg="2" className="">
					<CInputGroup size="sm">
						<CFormInput 
							value={uInpJmlkecil}
							size="sm"
							className="text-end"
							onChange={(e) => setInpJmlkecil(e.target.value)}
							onFocus={(e)=>e.target.select()}
							id="inpjmlkecil"/>
					<CInputGroupText className="py-0 fw-bolder">
						{uInpKecilSatuan||"UNT"}
					</CInputGroupText>
					</CInputGroup>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_hargajual||"caption_hargajual"}
				</CCol>
				<CCol xs="8" md="5" className="">
					<NumberFormat 
						value={uInpKecilhjual}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpKecilhjual(values.value)}
						renderText={values => (
							<CFormInput 
								value={values}
								size="sm"
								className="text-end"
								onChange={(e) => setInpKecilhjual(e.target.value)}
								onFocus={(e)=>e.target.select()}
								onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
							id="inpkecilhjual"/>
						)} 
						prefix={isMobile?"":"Rp"}/>
				</CCol>
			</CRow>
			</CForm>
		)
	}
	const contentDlgform = () => {
		if(!uIsShowForm) return (<></>);

		return (
			<CForm>
			<CRow className="mx-0 my-2">
				<CCol xs="4" className="px-1">{uBahasaObj.caption_kodebarang||"caption_kodebarang"}</CCol>
				<CCol className="px-1">
					<CFormInput maxLength="15"
						size="sm"
						className="text-uppercase"
						value={uInpFormBarangkode}
						onChange={(e)=>setInpFormBarangkode(e.target.value)}
						id="inpformbarangkode"/>
				</CCol>
			</CRow>
			<CRow className="mx-0 my-2">
				<CCol xs="4" className="px-1">{uBahasaObj.caption_namabarang||"caption_namabarang"}</CCol>
				<CCol className="px-1">
					<CFormInput maxLength="50"
						size="sm"
						className="text-capitalize"
						value={uInpFormBarangnama}
						onChange={(e)=>setInpFormBarangnama(e.target.value)}
						id="inpformbarangnama"/>
				</CCol>
			</CRow>
			<CRow className="mx-0 my-2">
				<CCol xs="4" className="px-1">{uBahasaObj.caption_jenisbarang||"caption_jenisbarang"}</CCol>
				<CCol className="px-1">
					<CFormSelect custom
						size="sm"
						onChange={(e)=>setInpFormBarangjenisID(e.target.value)}
						id="inpformbarangjenis">
					{uBarangjenisArr.map((vItem,vKey)=>{
						return (
						<option value={vItem.value}
							selected={vItem.value.toString()===(uInpFormBarangjenisID||"0").toString()}
							key={vKey}>
							{vItem.caption}</option>
						)
					})}
					</CFormSelect>
				</CCol>
			</CRow>
			<CRow className="mx-0 my-2">
				<CCol xs="4" className="px-1">{uBahasaObj.word_satuan||"word_satuan"}</CCol>
				<CCol xs="4" className="px-1">
					<CFormInput maxLength="15"
						size="sm"
						className="text-uppercase"
						value={uInpFormBarangSatuan}
						onChange={(e)=>setInpFormBarangSatuan(e.target.value)}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpformbarangsatuan"/>
				</CCol>
			</CRow>
			</CForm>
		);
	}
	//--END CONTENT--/

	//--API--/
	const apiLoadAwal = () => {
		uFuncname = apiLoadAwal.name;
		setHTMLError500("");

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(function(){setLoading(false);clearTimeout(vTimeout)},2500);
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pecahstok/ld_dataform";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				if(output_string.databarang) {
					setLookupItemArr(JSON.parse(output_string.databarang))
				}
				if(output_string.jenisbarang) {
					setBarangjenisArr(JSON.parse(output_string.jenisbarang))
				}
				setIsFormprocess(false);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
				//showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
				//showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiPilihBesar = (_IDITEM) => {
		uFuncname = apiPilihBesar.name;
		setLookupShow(false);

		const vIdx = UFunc.getObjectIndeks(uLookupItemArr,_IDITEM,"id");
		if(vIdx < 0) return;
		initResetNamaBesar();
		setInpNamakecil("");
		initResetNamaKecil();
		setInpNamabesar(uLookupItemArr[vIdx].caption);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(function(){
			setIsFormprocess(false);
			setBesarloading(false);
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_barangid : _IDITEM,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pecahstok/ld_initbesar";

		setIsFormprocess(true);
		setBesarloading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setIsFormprocess(false);
			setBesarloading(false);
			if(output_string.tampil) {
				setInpBesarID(uLookupItemArr[vIdx].id);
				setInpBesarSatuan(uLookupItemArr[vIdx].satuan);

				if(output_string.dataobject) {
					const vTmpObj = JSON.parse(output_string.dataobject);
					setInpBesarSisa(vTmpObj.sisastok || 0);
					setInpBesarhpp(vTmpObj.hpp_sat || 0);
				}

				document.getElementById("inpjmlbesar").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setInpNamabesar("");
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setInpNamabesar("");
			setIsFormprocess(false);
			setBesarloading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiPilihKecil = (_IDITEM) => {
		uFuncname = apiPilihKecil.name;
		setLookupShow(false);

		const vIdx = UFunc.getObjectIndeks(uLookupItemArr,_IDITEM,"id");
		if(vIdx < 0) return;
		initResetNamaKecil();

		const vKecilID = parseInt(uLookupItemArr[vIdx].id) || 0;
		if((parseInt(uInpBesarID)||0) <= 0) {
			setInpNamakecil("");
			showToast((uBahasaObj.caption_barangbox||"caption_barangbox")+" "+
				(uBahasaObj.caption_mustchoose||"caption_mustchoose")+" !"
				);
			return;
		}
		if(vKecilID === (parseInt(uInpBesarID)||0)) {
			setInpNamakecil("");
			showToast((uBahasaObj.pesan_boxpecahancannotsame||"pesan_boxpecahancannotsame"));
			return;
		}

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uLookupItemArr : "+JSON.stringify(uLookupItemArr));
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_barangid : _IDITEM,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pecahstok/ld_initkecil";

		setIsFormprocess(true);
		setKecilloading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setIsFormprocess(false);
			setKecilloading(false);
			setRegisterDone(false);
			if(output_string.tampil) {
				setInpKecilID(uLookupItemArr[vIdx].id);
				setInpNamakecil(uLookupItemArr[vIdx].caption);
				setInpKecilSatuan(uLookupItemArr[vIdx].satuan);

				if(output_string.dataobject) {
					const vTmpObj = JSON.parse(output_string.dataobject);
					setInpKecilperbox(vTmpObj.jmlperbox || 0);
					setInpKecilhjuallast(vTmpObj.hargajual || 0);
					setInpKecilhjual(vTmpObj.hargajual || 0);
				}

				document.getElementById("inpjmlkecil").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setInpNamakecil("");
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setInpNamakecil("");
			setKecilloading(false);
			setIsFormprocess(false);
			setRegisterDone(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanRegister = () => {
		uFuncname = apiSimpanRegister.name;

		/*//--TESTING_FRONTEND--//
		return;
		//--END TESTING_FRONTEND--//*/

		const vApiURL = "api_barang/pr_simpan";
		const vDATAS	= JSON.stringify({
			send_barangkode : uInpFormBarangkode,
			send_barangnama : uInpFormBarangnama,
			send_jenisid : uInpFormBarangjenisID,
			send_satuan : uInpFormBarangSatuan,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/"+vApiURL;//"api_belibarang/pr_simpan";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setIsFormprocess(false);

			if(output_string.sukses) {
				const vNewID		= output_string.tabelid || "255";
				const vNewCaption 	= UFunc.capitalize(uInpFormBarangnama)+
						" ["+(uInpFormBarangjenis||"").toUpperCase()+"]"+
						""+((uInpFormBarangkode||"")!==""?" - "+uInpFormBarangkode:"").toUpperCase();

				setLookupItemArr([
					{id:vNewID,caption:vNewCaption,satuan:uInpFormBarangSatuan,},
					...uLookupItemArr]);
				setInpKecilID(vNewID);
				setShowForm(false);
				setRegisterDone(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setIsFormprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpan = () => {
		uFuncname = apiSimpan.name;

		/*
		//--TESTING_FRONTEND--//
		showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
		if(!isMobile) document.getElementById("inpnamabesar").focus();
		initAwalMain();
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_besarid	: uInpBesarID,
			send_besarjml	: uInpJmlbesar,
			send_kecilid	: uInpKecilID,
			send_keciljml	: uInpJmlkecil,
			send_kecilhjual	: uInpKecilhjual,
			send_tokenauth 	: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pecahstok/pr_simpaninput";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setIsFormprocess(false);

			if(output_string.sukses) {
				const vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				showToast(vPesan,"SUKSES");
				initAwalMain();
				if(!isMobile) document.getElementById("inpnamabesar").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg  = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setIsFormprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);

		return () => {
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});

		apiLoadAwal();

		if(!isMobile) {
			const vEls = document.getElementById("inpnamabesar");
			vEls && vEls.focus();
		}

		return () => {
			setShowForm(false);
			setLookupShow(false);
			setBarangjenisArr([]) 
		}
	},[]);
	React.useEffect(()=>{
		if((parseInt(uInpJmlbesar)||0) < 0) {
			setInpJmlbesar((uInpJmlbesar * -1)); return;
		}
		if((parseInt(uInpJmlbesar)||0) > (parseInt(uInpBesarSisa)||0)) {
			setInpJmlbesar(uInpBesarSisa); return;
		}
	},[uInpJmlbesar]);
	React.useEffect(()=>{
		if((parseInt(uInpJmlkecil)||0) < 0) {
			setInpJmlkecil((uInpJmlkecil * -1)); return;
		}
	},[uInpJmlkecil]);
	React.useEffect(()=>{
		if((parseInt(uInpKecilhjual)||0) < 0) {
			setInpKecilhjual((uInpKecilhjual * -1)); return;
		}
	},[uInpKecilhjual]);
	React.useEffect(()=>{
		if(!uIsShowForm) 
			{ initAwalForm(); return; }

		let vTimeout = setTimeout(function(){ 
			document.getElementById("inpformbarangkode") && document.getElementById("inpformbarangkode").focus();
			clearTimeout(vTimeout);},350);
	},[uIsShowForm]);
	React.useEffect(()=>{ 
		if(uBarangjenisArr.length <= 0) return;
		setInpFormBarangjenis(uBarangjenisArr[0].value || "0") 
	},[uBarangjenisArr]);
	React.useEffect(()=>{
		if(uBarangjenisArr.length <= 0) return;

		const vIdx = UFunc.getObjectIndeks(uBarangjenisArr,uInpFormBarangjenisID,"value");
		if(vIdx < 0) {
			setInpFormBarangjenis(uBarangjenisArr[0].caption);
			return;
		}
		setInpFormBarangjenis(uBarangjenisArr[vIdx].caption);
	},[uInpFormBarangjenisID]);
	React.useEffect(()=>{
		if(!uIsRegisterDone) return;

		apiPilihKecil(uInpKecilID);
	},[uIsRegisterDone]);
	//---END EFFECT---/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="classheadermain border-bottom" style={{minHeight:64}}>
			<CHeaderToggler className="px-0" onClick={() => {uNavigate(-1);}}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small><b>{(uBahasaObj.menus_pecahstok||"menus_pecahstok").toUpperCase()}</b></small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
		<CContainer xxl className="p-0">
			<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
				<div>
				<CIcon className="classikon classikontambah" height={30}/>
				{" "}{uBahasaObj.caption_inputbaru||"caption_inputbaru"}: <strong>{UFunc.HariAngka()}</strong>
				</div>
				<CTooltip content={uBahasaObj.caption_resetform||"caption_resetform"}>
					<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
				</CTooltip>
			</CCardHeader>

			<CCardBody>
				{uHTMLError500 ? UFunc.renderHTML(uHTMLError500) : (<>{contentMain()}</>)}
			</CCardBody>
			</CCard>
		</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uHTMLError500 || uIsFormprocess}
				onClick={()=>hdlKlikSimpan()}
				id="btnSimpan">Simpan [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsLookupShow}
			toggle={()=>hdlToggleLookup()}
			dialogHeader={uLookupHeader||"dialogHeader"}
			inputValue={uInpLookupInput}
			dataItemArr={uLookupItemArr} 
			onKlikPilihItem={hdlKlikPilihLookup}
			id="iddialoglookup"/>

		<MyDialogform
			options={{size:""}}
			onSimpan={()=>hdlKlikFormSimpan()}
			show={uIsShowForm} 
			dialogHeader={uFormHeader||"uFormHeader"}
			toggle={hdlToggleForm}
			renderContent={contentDlgform()}/>
		</>
	)
}

export default Pecahstoktambah;
