import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CFormSelect,CInputGroup,CInputGroupText,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,
	CNav,CNavItem,CNavLink,
	CTabContent,CTabPane,
	CSpinner,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
	CTooltip,
	CBadge,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Gledger 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uUserSettingObj 		= useSelector(state => state.gListUserSetting);
	const uIsEOMExecuted		= useSelector(state => state.gIsEOMExecuted);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uUserSettingObj.jml_mobile || 30) : (uUserSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderactionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderactionObj.isHeaderView || false;
	const uHandelTambah 		= uHeaderactionObj.isHeaderTambah || false;

	//---PAGINATION_DIATUR_BACKEND--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//---END PAGINATION_DIATUR_BACKEND--/

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [uActivetab,setActivetab]			= React.useState("NERACA");
	const [uEfekview2,setEfekview2]			= React.useState(false);

	//--DLG_FORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//--END DLG_FORM--/

	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlKlikUp = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vArridx].kode_akun+"."+
			uDatamainArr[vArridx].kode_main+"."+
			uDatamainArr[vArridx].kode_sub+" - "+
			uDatamainArr[vArridx].nama_gl).toUpperCase();

		if(await Konfirm(
			"<b>Posisi/Urutan Akan DINAIKKAN</b>.."+
			"<br/>Apakah Anda Yakin ?"+
			"")) apiProsesurutansub(_IDTABEL,"UP");
	}
	const hdlKlikDown = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vArridx].kode_akun+"."+
			uDatamainArr[vArridx].kode_main+"."+
			uDatamainArr[vArridx].kode_sub+" - "+
			uDatamainArr[vArridx].nama_gl).toUpperCase();

		if(await Konfirm(
			"<b>Posisi/Urutan Akan DITURUNKAN</b>.."+
			"<br/>Apakah Anda Yakin ?"+
			"")) apiProsesurutansub(_IDTABEL,"DOWN");
	}
	const hdlKlikTambahsub = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		uDlgformObj.header 		= "Tambah SUB Pos Akun";
		uDlgformObj.input_form 	= "SUBPOS";
		uDlgformObj.kategori	= uDatamainArr[vArridx].kategori;
		uDlgformObj.jenis		= uActivetab;
		uDlgformObj.levels		= 2;

		uDlgformObj.namamaingl	= uDatamainArr[vArridx].kode_akun+"."+
			uDatamainArr[vArridx].kode_main+"."+
			uDatamainArr[vArridx].kode_sub+" - "+
			uDatamainArr[vArridx].nama_gl;
		uDlgformObj.kode_akun	= uDatamainArr[vArridx].kode_akun;
		uDlgformObj.kode_main	= uDatamainArr[vArridx].kode_main;

		uDlgformObj.akunlevel_arr = uDatamainArr.filter(vItems =>
			parseInt(vItems.levels) > 1 && 
				vItems.kode_akun === uDatamainArr[vArridx].kode_akun && 
				vItems.kode_main === uDatamainArr[vArridx].kode_main 
			);

		uDlgformObj.urutan = (uDlgformObj.akunlevel_arr||[]).length + 1;
		setDlgformshow(true);
	}
	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		const vGLcaption		= uDatamainArr[vArridx].kode_akun+"."+
			uDatamainArr[vArridx].kode_main+"."+
			uDatamainArr[vArridx].kode_sub+" - "+
			uDatamainArr[vArridx].nama_gl;
		const vGLLevels			= parseInt(uDatamainArr[vArridx].levels)||1;
		const vGLkodesub		= uDatamainArr[vArridx].kode_sub||"00";
		uDlgformObj.input_form 	= vGLLevels <= 1 ? "MAINPOS" : "SUBPOS";
		uDlgformObj.levels		= vGLLevels;
		uDlgformObj.jenis		= uActivetab;

		uDlgformObj.tabelid		= uDatamainArr[vArridx].id;
		uDlgformObj.nama_gl		= uDatamainArr[vArridx].nama_gl;
		uDlgformObj.kategori 	= uDatamainArr[vArridx].kategori;
		uDlgformObj.rule 		= uDatamainArr[vArridx].rule;
		uDlgformObj.saldo 		= uDatamainArr[vArridx].saldo;
		uDlgformObj.kode_akun	= uDatamainArr[vArridx].kode_akun;
		uDlgformObj.kode_main	= uDatamainArr[vArridx].kode_main;//-*/
		uDlgformObj.kode_sub	= uDatamainArr[vArridx].kode_sub;
		
		if(vGLLevels > 1) {
			uDlgformObj.header 		= "Edit SUB Pos Akun: "+vGLcaption.toUpperCase();

			const vMainGLObj		= uDatamainArr.filter(vItems =>
				parseInt(vItems.levels) <= 1 && 
					vItems.kode_akun === uDatamainArr[vArridx].kode_akun && 
					vItems.kode_main === uDatamainArr[vArridx].kode_main 
				)[0];

			uDlgformObj.urutan_lama	= parseInt(vGLkodesub);
			uDlgformObj.urutan		= parseInt(vGLkodesub);
			uDlgformObj.namamaingl	= vMainGLObj.kode_akun+"."+
				vMainGLObj.kode_main+"."+
				vMainGLObj.kode_sub+" - "+
				vMainGLObj.nama_gl;

			uDlgformObj.akunlevel_arr = uDatamainArr.filter(vItems =>
				//parseInt(vItems.id) !== parseInt(uDatamainArr[vArridx].id) &&
				parseInt(vItems.levels) > 1 && 
				vItems.kode_akun === uDatamainArr[vArridx].kode_akun && 
				vItems.kode_main === uDatamainArr[vArridx].kode_main
			);
		} else {
			uDlgformObj.header 		= "Edit MAIN Akun: "+vGLcaption.toUpperCase();
			//uDlgformObj.kode_sub	= "00";

			const vAkunlevelArr = uDatamainArr.filter(vItems =>
				//parseInt(vItems.id) !== parseInt(uDatamainArr[vArridx].id) &&
				parseInt(vItems.levels) <= 1 && 
				(vItems.jenis||"").toUpperCase() === (uDatamainArr[vArridx].jenis||"").toUpperCase() &&
				parseInt(vItems.kode_akun||"00") === parseInt(uDatamainArr[vArridx].kode_akun||"00") &&
				(vItems.kategori||"AKTIVA").toUpperCase()===(uDatamainArr[vArridx].kategori||"").toUpperCase()
			);

			uDlgformObj.akunlevel_arr = vAkunlevelArr || [];
			const vMainidx 			= UFunc.getObjectIndeks(vAkunlevelArr,uDatamainArr[vArridx].id);
			uDlgformObj.urutan_lama	= vMainidx + 1;
			uDlgformObj.urutan 		= vMainidx + 1;
		}

		//console.log("(hdlKlikEdit) uDlgformObj => "+JSON.stringify(uDlgformObj));
		setDlgformshow(true);
	}
	const hdlKlikPindahmain = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		if(parseInt(uDatamainArr[vArridx].levels) <= 1) return;

		const vGLcaption		= uDatamainArr[vArridx].kode_akun+"."+
			uDatamainArr[vArridx].kode_main+"."+
			uDatamainArr[vArridx].kode_sub+" - "+
			uDatamainArr[vArridx].nama_gl;

		uDlgformObj.input_form	= "PINDAHMAIN";
		uDlgformObj.header 		= "Pindah MAIN POS: "+vGLcaption.toUpperCase();
		uDlgformObj.tabelid		= uDatamainArr[vArridx].id;
		uDlgformObj.kategori	= uDatamainArr[vArridx].kategori;
		uDlgformObj.kode_akun	= uDatamainArr[vArridx].kode_akun;
		uDlgformObj.kode_main	= uDatamainArr[vArridx].kode_main;
		uDlgformObj.kode_sub	= uDatamainArr[vArridx].kode_sub;

		//--LOAD_MAINPOS--/
		uDlgformObj.main_arr	= uDatamainArr.filter(vItems => 
			vItems.levels <= 1 &&
			vItems.jenis === uActivetab
			);
		const vMainidx		= uDatamainArr.findIndex(vItems =>
			vItems.levels <= 1 &&
			vItems.kode_akun === uDatamainArr[vArridx].kode_akun &&
			vItems.kode_main === uDatamainArr[vArridx].kode_main
		); 
		uDlgformObj.main_id_lama= uDatamainArr[vMainidx].id;
		uDlgformObj.main_id		= uDatamainArr[vMainidx].id;
		//--END LOAD_MAINPOS--/

		setDlgformshow(true);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper		= uDatamainArr[vArridx].kode_akun+"."+
			uDatamainArr[vArridx].kode_main+"."+
			uDatamainArr[vArridx].kode_sub+" - "+
			uDatamainArr[vArridx].nama_gl;

		//--CEK_RICEK--/
		if(parseFloat(uDatamainArr[vArridx].saldo||"0") > 0) {
			showToast("Akun GL Tersebut Masih Memiliki Sisa Saldo.."+
				"<br/>Saldo Tersisa: <b>"+UFunc.formatAngka(uDatamainArr[vArridx].saldo)+"</b>"+
				"<br/><br/>Mohon Diperbaiki !"+
				""); return;
		}

		if(parseInt(uDatamainArr[vArridx].levels) <= 1) {
			const vSubmainArr = uDatamainArr.filter(vItems => 
				parseInt(vItems.levels) > 1 &&
				vItems.kode_akun === uDatamainArr[vArridx].kode_akun &&
				vItems.kode_main === uDatamainArr[vArridx].kode_main &&
				parseInt(vItems.kode_sub||"00") > 0
			);
			if(vSubmainArr.length > 0) {
				showToast("<b>Akun GL Masih Memiliki SUB AKUN</b>.."+
					"<br/>Mohon Seluruh Sub Akun Dihapus terlebih Dahulu !"+
					""); return;
			}
		}
		//--END CEK_RICEK--/

		if(await Konfirm("Hapus Akun: <b>"+vCaptionupper+"</b>"+
			"<br/>Apakah Anda Yakin ?"+
			"")) apiProseshapus(_IDTABEL);
	}
	const hdlKlikDlgformsimpan = () => {
		if(uDlgformObj.input_form === "MAINPOS" ||
			uDlgformObj.input_form === "SUBPOS") {
			prosesFormakun(); return
		}
		if(uDlgformObj.input_form === "PINDAHMAIN")
			{ prosesFormpindah(); return }
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_gledger || "";
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} 

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
	}
	const initResortdata = () => {
		uDatamainArr.sort((a,b) => a.jenis - b.jenis ||
			a.kode_akun - b.kode_akun ||
			a.kode_main - b.kode_main ||
			a.kode_sub - b.kode_sub
		);
	}
	const prosesFormakun = () => {
		let vElfocus = document.getElementById("inpurutan");
		if(parseInt(uDlgformObj.urutan) < 1 && parseInt(uDlgformObj.urutan)> (uDlgformObj.akunlevel_arr||[]).length ) {
			vElfocus && vElfocus.focus();
			showToast("Urutan Tidak Valid !"); return;
		}
		vElfocus = document.getElementById("inpnamagl");
		if((uDlgformObj.nama_gl||"").length < 3) {
			vElfocus && vElfocus.focus();
			showToast("Nama GL Tidak Valid.."+
				"<br/>Nama GL Harus Diisi dengan Minimal 3 Karakter !"+
				""); return;
		}

		apiProsessimpan();
	}
	const prosesFormpindah = () => {
		if(parseInt(uDlgformObj.main_id_lama) === parseInt(uDlgformObj.main_id))
			{ setDlgformshow(false); return }

		apiProsespindah()
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		return (
		<>
			<CNav variant="tabs" className="px-md-2">
			<CNavItem active={uActivetab==="NERACA"}>
				<CNavLink className={"px-lg-5 classcursorpointer border"+(uActivetab==="NERACA"?" bg-light fw-bolder text-success":" text-muted")}
					onClick={()=>setActivetab("NERACA")}
					>Neraca
				</CNavLink>
			</CNavItem>

			<CNavItem active={uActivetab==="RL"}>
				<CNavLink className={"px-lg-5 classcursorpointer border"+(uActivetab!=="NERACA"?" bg-light fw-bolder text-success":" text-muted")}
					onClick={()=>setActivetab("RL")}>
					LABA / Rugi
				</CNavLink>
			</CNavItem>
			</CNav>

			<CTabContent className="py-md-3">
				<CTabPane role="tabpanel" aria-labelledby="neraca_tab" visible={uActivetab === "NERACA"}>
				{contentAkuntabel()}
				</CTabPane>
				<CTabPane role="tabpanel" aria-labelledby="lr_tab" visible={uActivetab === "RL"}>
				{contentAkuntabel()}
				</CTabPane>
			</CTabContent>
		</>
		)
	}
	const contentAkuntabel = () => {
		if(uHTMLError500) return "";
		//if(uActivetab !== "NERACA") return "";

		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_gledger || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		/*//--PAGINATION_DIATUR_BACKEND--/
		const vDataviewArr	= uDatamainArr.filter(vItems =>
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		//--END PAGINATION_DIATUR_BACKEND--*/

		//--PAGINATION_DIATUR_FRONTEND--/
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			(vItems.jenis||"NERACA") === uActivetab);
		//--END PAGINATION_DIATUR_FRONTEND--*/

		let vKodeGLmain = "";
		return (
		<table className="table table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="2%" className="px-1"/>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="2" lg="1" className="text-start">{uBahasaObj.caption_kodegl||"Kode GL"}</CCol>
				<CCol md="1" className="">&nbsp;</CCol>
				<CCol md="5" lg="6" className="text-start">{uBahasaObj.caption_namagl||"Nama GL (General Ledger)"}</CCol>
				<CCol md="2" className="">Jenis Akun</CCol>
				<CCol md="2" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none classfontsmaller">
			<th width={1} className="px-0"/>
			<th className="px-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,kode_akun,kode_main,kode_sub,nama_gl,kategori,saldo,levels,rule,
				is_loading,
			} = vItems;

			vKodeGLmain = parseInt(kode_sub||"00")===0 ? kode_akun+"."+kode_main : vKodeGLmain;

			const vJmlSub = vDataviewArr.filter(vItemfilter =>
				(vItemfilter.kode_akun+"."+vItemfilter.kode_main)===vKodeGLmain && 
					vItemfilter.levels > 1
				).length;

			const vCaptionupper = (kode_akun||"00")+"."+(kode_main||"00")+"."+(kode_sub||"00")+
				"-"+(nama_gl||"Undf").toUpperCase()+
				"";

			const vNamaGL	= vKeyword==="" ? (nama_gl||"Undf") 
				: ((nama_gl||"")).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");

			return (
			<tr id={"idtr"+id} 
				className={"align-top"+(uTabelRowSelect===vKeys?" border border-2 border-warning":"")+
					((parseInt(levels)||0)<=1?" bg-secondary text-light":"")
					}
				key={vKeys}>
			<td className="px-1"/>
			<td className="px-1">
			<CRow className={"mx-0"+
				((parseInt(levels)||0)<=1?" fw-bolder text-uppercase":" text-capitalize")}>
				<CCol md="2" lg="1" className={"d-none d-md-block px-1"+((parseInt(levels)||2)<=1?" text-start":" text-center")}>
					{(kode_akun||"00")}.{(kode_main||"00")}.{(kode_sub||"00")}
				</CCol>
				
				<CCol md="1" className="px-1 text-center">
					{(rule||"FIXED")==="FIXED" ? (
						<small><CBadge color="warning">{rule}</CBadge></small>
					) : (<>&nbsp;</>)}
				</CCol>

				<CCol xs="12" md="5" lg="6" className="px-1 d-flex justify-content-between">
					<div>
					<span className="d-md-none">
					{(kode_akun||"00")}.{(kode_main||"00")}.{(kode_sub||"00")}
					</span>
					<span className="d-md-none mx-1">&middot;</span>
					{UFunc.renderHTML(vNamaGL)} &middot; <small className="text-primary">({id})</small>
					</div>

					{(is_loading||false)===true ? (
						<CSpinner color="primary" className="d-md-none" size="sm"/>
					) : (
					<CDropdown className="d-md-none text-capitalize">
					<CDropdownToggle caret={false} color="none" className="p-0">
						<CIcon icon="cilOptions" className="classikoncoreui" height={23}/>
					</CDropdownToggle>
					<CDropdownMenu className="p-0">
						<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
						<CIcon className="classikonedit mx-0 me-2 align-middle" height={25}/>
						Edit Data
						</CDropdownItem>

						<CDropdownItem onClick={()=>hdlKlikPindahmain(id)}>
						<CIcon icon="cilSwapVertical" className="classikoncoreui mx-0 me-2 align-middle" height={25}/>
						Pindah Main POS
						</CDropdownItem>

						{((rule||"")!=="FIXED" && uIsAllowedhapus) && (
						<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
						<CIcon className="classikonhapus mx-0 me-2 align-middle" height={25}/>
						Hapus Data
						</CDropdownItem>
						)}
					</CDropdownMenu>
					</CDropdown>
					)}
				</CCol>

				<CCol xs="12" md="2" className="px-1 text-start text-md-center">
					{(kategori||"-")}
				</CCol>

				<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

				<CCol xs="12" md="2" className="px-1 px-md-0 text-center">
					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
						{(parseInt(levels)||2) > 1 ? (
						<>
							{(parseInt(kode_sub||"00") > 1) && (
							<CTooltip content={"Naikkan Urutan: "+vCaptionupper}>
							<CLink onClick={()=>hdlKlikUp(id)} className="classcursorpointer classikontabel classikonup"/>
							</CTooltip>
							)}

							{parseInt(kode_sub||"00") < vJmlSub && (
							<CTooltip content={"Turunkan Urutan: "+vCaptionupper}>
							<CLink onClick={()=>hdlKlikDown(id)} className="classcursorpointer classikontabel classikondown"/>
							</CTooltip>
							)}

							<CDropdown className="d-none d-md-inline">
							<CDropdownToggle caret={false} color="none" className="p-0">
								<CIcon icon="cilChevronBottom" className="classikoncoreui" height={25}/>
							</CDropdownToggle>
							<CDropdownMenu className="p-0">
								<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikonedit mx-0 me-2 align-middle" height={25}/>
								Edit Data
								</CDropdownItem>

								<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikPindahmain(id)}>
								<CIcon icon="cilSwapVertical" className="classikoncoreui mx-0 me-2 align-middle" height={25}/>
								Pindah Main POS
								</CDropdownItem>

								{((rule||"")!=="FIXED" && uIsAllowedhapus) && (
								<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus mx-0 me-2 align-middle" height={25}/>
								Hapus Data
								</CDropdownItem>
								)}
							</CDropdownMenu>
							</CDropdown>
						</>
						) : (
						<>
							<CTooltip content={(uBahasaObj.caption_tambahsuppos||"Tambah Sub Pos Akun")+": "+vCaptionupper}>
							<CLink onClick={()=>hdlKlikTambahsub(id)} className="classcursorpointer classikontabel classikontambah"/>
							</CTooltip>

							<CTooltip content={uBahasaObj.caption_editdata+": "+vCaptionupper}>
							<CLink onClick={()=>hdlKlikEdit(id)} className="classcursorpointer classikontabel classikonedit"/>
							</CTooltip>

							{((rule||"")!=="FIXED" && uIsAllowedhapus) && (
							<CTooltip content={uBahasaObj.caption_hapusdata+": "+vCaptionupper}>
							<CLink onClick={()=>hdlKlikHapus(id)} className="classcursorpointer classikontabel classikonhapus"/>
							</CTooltip>
							)}
						</>
						)}
					</>
					)}
				</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)

		if(uDlgformObj.input_form==="MAINPOS") return contentFormmain();
		if(uDlgformObj.input_form==="SUBPOS") return contentFormsub();
		if(uDlgformObj.input_form==="PINDAHMAIN") return contentFormpindahmain();

		return (<></>)
	}
	const contentFormmain = () => {
		if(uDlgformObj.input_form!=="MAINPOS") return (<></>);

		const vDataakunsamaArr = (uDlgformObj.akunlevel_arr || []).filter(vItems =>
			parseInt(vItems.id) !== parseInt(uDlgformObj.tabelid||"0"));

		return (
		<CForm>
		<CRow className="my-2">
		<CCol xs="3" md="4">
			<small className="d-md-none">Kategori</small>
			<span className="d-none d-md-block">Kategori Akun</span>
		</CCol>
		<CCol className="text-end text-md-start">
			{parseInt(uDlgformObj.tabelid||"0") <= 0 ? (
			<CFormSelect size="sm"
				value={uDlgformObj.kategori}
				onChange={(e)=>{uDlgformObj.kategori=e.target.value;setEfekview(!uEfekview);}}
				id="inpkategori">
			{uActivetab==="NERACA" ? (
			<>
				<option value="AKTIVA">AKTIVA</option>
				<option value="PASIVA">PASIVA</option>
			</>
			): (
			<>
				<option value="PENDAPATAN">PENDAPATAN</option>
				<option value="BIAYA">BIAYA</option>
			</>
			)}
			</CFormSelect>
			) :(
				<span className="fw-bolder text-uppercase">{uDlgformObj.kategori}</span>
			)}
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4" className="pe-0">
			<small className="d-md-none">Posisi/Urutan</small>
			<span className="d-none d-md-block">Posisi/Urutan</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<CFormSelect size="sm"
				value={uDlgformObj.urutan}
				onChange={(e)=>{uDlgformObj.urutan=e.target.value;setEfekview(!uEfekview);}}
				id="inpurutan">
			<option value={1}>Urutan PERTAMA</option>
			{vDataakunsamaArr.map((vItems,vKeys)=>{
				return (
				<option value={vKeys+2} key={vKeys}>Setelah {(vItems.nama_gl||"").toUpperCase()}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="3" md="4">
			<small className="d-md-none">Kode GL</small>
			<span className="d-none d-md-block">Kode GL</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<CInputGroup size="sm">
			<CFormInput size="sm"
				value={uDlgformObj.kode_akun}
				readOnly={true}
				id="inpkodeakun"/>
			<span className="mx-1">-</span>
			<CFormInput size="sm"
				value={uDlgformObj.kode_main}
				readOnly={true}
				id="inpkodemain"/>
			<span className="mx-1">-</span>
			<CFormInput size="sm"
				value={uDlgformObj.kode_sub}
				readOnly={true}
				id="inpkodesub"/>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4">
			<small className="d-md-none">Nama GL</small>
			<span className="d-none d-md-block">Nama GL</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<CFormInput size="sm" maxLength={100}
				className="text-capitalize"
				value={uDlgformObj.nama_gl}
				onChange={(e)=>{uDlgformObj.nama_gl=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpnamagl"/>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentFormsub = () => {
		if(uDlgformObj.input_form!=="SUBPOS") return (<></>);

		//const vDataakunsamaArr = uDlgformObj.akunlevel_arr || [];
		const vDataakunsamaArr = (uDlgformObj.akunlevel_arr || []).filter(vItems =>
			parseInt(vItems.id) !== parseInt(uDlgformObj.tabelid||"0"));

		return (
		<CForm>
		<CRow className="my-2">
		<CCol xs="3" md="4" className="pe-0">
			<small className="d-md-none">Main POS</small>
			<span className="d-none d-md-block">Main POS GL</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<span className="fw-bolder text-primary text-uppercase">{uDlgformObj.namamaingl}</span>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4" className="pe-0">
			<small className="d-md-none">Posisi/Urutan</small>
			<span className="d-none d-md-block">Posisi/Urutan</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<CFormSelect size="sm"
				value={uDlgformObj.urutan}
				onChange={(e)=>{uDlgformObj.urutan=e.target.value;setEfekview(!uEfekview);}}
				id="inpurutan">
			<option value={1}>Urutan PERTAMA</option>
			{vDataakunsamaArr.map((vItems,vKeys)=>{
				return (
				<option value={vKeys+2} key={vKeys}>Setelah {(vItems.nama_gl||"").toUpperCase()}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="3" md="4">
			<small className="d-md-none">Kode GL</small>
			<span className="d-none d-md-block">Kode GL</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<CInputGroup size="sm">
			<CFormInput size="sm"
				value={uDlgformObj.kode_akun}
				readOnly={true}
				id="inpkodeakun"/>
			<span className="mx-1">-</span>
			<CFormInput size="sm"
				value={uDlgformObj.kode_main}
				readOnly={true}
				id="inpkodemain"/>
			<span className="mx-1">-</span>
			<CFormInput size="sm"
				value={uDlgformObj.kode_sub}
				readOnly={true}
				id="inpkodesub"/>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4">
			<small className="d-md-none">Nama GL</small>
			<span className="d-none d-md-block">Nama GL</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<CFormInput size="sm" maxLength={100}
				className="text-capitalize"
				value={uDlgformObj.nama_gl}
				onChange={(e)=>{uDlgformObj.nama_gl=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpnamagl"/>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentFormpindahmain = () => {
		if(uDlgformObj.input_form!=="PINDAHMAIN") return (<></>);

		const vDataakunsamaArr = (uDlgformObj.akunlevel_arr || []).filter(vItems =>
			parseInt(vItems.id) !== parseInt(uDlgformObj.tabelid||"0"));

		return (
		<CForm>
		<CRow className="my-2">
		<CCol xs="3" md="4">
			<small className="d-md-none">Jenis Akun</small>
			<span className="d-none d-md-block">Jenis Akun</span>
		</CCol>
		<CCol className="text-end text-md-start fw-bolder">{uActivetab}</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="3" md="4">
			<small className="d-md-none">Pilih Main</small>
			<span className="d-none d-md-block">Pilih Main Akun</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<CFormSelect size="sm"
				value={uDlgformObj.main_id}
				onChange={(e)=>{uDlgformObj.main_id=e.target.value;setEfekview(!uEfekview);}}
				id="inpmainid">
			{(uDlgformObj.main_arr||[]).map((vItems,vKeys)=>{
				return (
				<option value={vItems.id} key={vKeys}>
				{vItems.kode_akun}.{vItems.kode_main}.{vItems.kode_sub} - {(vItems.nama_gl||"").toUpperCase()}
				</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4">
			<small className="d-md-none">Kode GL</small>
			<span className="d-none d-md-block">Kode GL</span>
		</CCol>
		<CCol className="text-end text-md-start">
			<CInputGroup size="sm">
			<CFormInput size="sm"
				value={uDlgformObj.kode_akun}
				readOnly={true}
				id="inpkodeakun"/>
			<span className="mx-1">-</span>
			<CFormInput size="sm"
				value={uDlgformObj.kode_main}
				readOnly={true}
				id="inpkodemain"/>
			<span className="mx-1">-</span>
			<CFormInput size="sm"
				value={uDlgformObj.kode_sub}
				readOnly={true}
				id="inpkodesub"/>
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = () => {
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_gledger || "";

		/*//--PAGINATION_DIATUR_BACKEND--/
		_PAGE		= parseInt(_PAGE)||1;
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		//--END PAGINATION_DIATUR_BACKEND--*/

		const vElButton	= document.getElementById("btnCari");
		vElButton && (vElButton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			//send_pg	: _PAGE,//--PAGINATION_DIATUR_BACKEND-
			//send_maxdata	: uMaxData,//--PAGINATION_DIATUR_BACKEND-
			//send_firstload	: vHandelview,//--PAGINATION_DIATUR_BACKEND-
			send_firstload	: uFirstload,//--PAGINATION_DIATUR_FRONTEND
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_gledger/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElButton && (vElButton.disabled = false);
			setLoading(false);
			//console.log("(Gledger - apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.dataobject));
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				/*//--PAGINATION_DIATUR_BACKEND--/
				vLoadArr.map(vItems=>vItems.pg = _PAGE);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}
				//--END PAGINATION_DIATUR_BACKEND--*/

				//--PAGINATION_DIATUR_FRONTEND--/
				setDatamainArr(vLoadArr);
				//--END PAGINATION_DIATUR_FRONTEND--/

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Gledger - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElButton && (vElButton.disabled = false);
			setLoading(false);
			console.log("(Gledger - apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		const vElButton		= document.getElementById("btnDialogSimpan");
		vElButton && (vElButton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout2 = setTimeout(()=>{
			clearTimeout(vTimeout2);
			vElButton && (vElButton.disabled = false);
			setLoading(false);

			setDlgformshow(false);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datajson : JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_gledger/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElButton && (vElButton.disabled = false);
			setLoading(false);
			//console.log("(Gledger - apiProsessimpan) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				const vTabelid = parseInt(uDlgformObj.tabelid || "0");
				const vNewObj	= {
					jenis: uDlgformObj.jenis,
					kode_akun: uDlgformObj.kode_akun,
					kode_main: uDlgformObj.kode_main,
					kode_sub: uDlgformObj.kode_sub,
					nama_gl: uDlgformObj.nama_gl,// +" -> " + JSON.stringify(uDlgformObj),
					kategori: uDlgformObj.kategori,
					saldo: uDlgformObj.saldo||0,
					rule: uDlgformObj.rule||"FLEXIBLE",
					levels: uDlgformObj.levels,
				}

				if(vTabelid <= 0) {
					vNewObj.id 		= output_string.tabelid || 255;

					if(uDlgformObj.input_form === "MAINPOS") {
						uDatamainArr.filter(vItems => 
							vItems.kode_akun === uDlgformObj.kode_akun &&
							parseInt(vItems.kode_main||"00") >= parseInt(uDlgformObj.kode_main)
						).map(vItems => 
							vItems.kode_main = UFunc.leadZero(parseInt(vItems.kode_main||"00")+1)
						);
					} else {
						uDatamainArr.filter(vItems => 
							vItems.kode_akun === uDlgformObj.kode_akun &&
							vItems.kode_main === uDlgformObj.kode_main &&
							parseInt(vItems.kode_sub||"00") >= parseInt(uDlgformObj.kode_sub)
						).map(vItems => 
							vItems.kode_sub = UFunc.leadZero(parseInt(vItems.kode_sub||"00")+1)
						);
					}

					setDatamainArr([...uDatamainArr,vNewObj])
				} else {
					vNewObj.id 		= vTabelid;
					const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,vTabelid);
					const vOldObj	= uDatamainArr[vArridx];

					if(parseInt(uDlgformObj.urutan_lama||"0") < parseInt(uDlgformObj.urutan)) {
						if(uDlgformObj.input_form === "MAINPOS") {
							const vChildIDoldArr = uDatamainArr.filter(vItems => 
								vItems.kode_akun === uDlgformObj.kode_akun &&
								parseInt(vItems.kode_main||"00") === parseInt(vOldObj.kode_main) &&
								parseInt(vItems.kode_sub||"00") > 0
							).map(vItems => vItems.id);

							uDatamainArr.filter(vItems => 
								vItems.kode_akun === uDlgformObj.kode_akun &&
								parseInt(vItems.kode_main||"00") > parseInt(vOldObj.kode_main) &&
								parseInt(vItems.kode_main||"00") <= parseInt(uDlgformObj.kode_main)
							).map(vItems => 
								vItems.kode_main = UFunc.leadZero(parseInt(vItems.kode_main||"00")-1)
							);

							uDatamainArr.filter(vItems => vChildIDoldArr.includes(vItems.id)).
								map(vItems => vItems.kode_main = uDlgformObj.kode_main);
						} else {
							uDatamainArr.filter(vItems => 
								vItems.kode_akun === uDlgformObj.kode_akun &&
								vItems.kode_main === uDlgformObj.kode_main &&
								parseInt(vItems.kode_sub||"00") > parseInt(vOldObj.kode_sub) && 
								parseInt(vItems.kode_sub||"00") <= parseInt(uDlgformObj.kode_sub)
							).map(vItems => 
								vItems.kode_sub = UFunc.leadZero(parseInt(vItems.kode_sub||"00")-1)
							);
						}
					} else if(parseInt(uDlgformObj.urutan_lama||"0") > parseInt(uDlgformObj.urutan)) { 
						if(uDlgformObj.input_form === "MAINPOS") {
							const vChildIDoldArr = uDatamainArr.filter(vItems => 
								vItems.kode_akun === uDlgformObj.kode_akun &&
								parseInt(vItems.kode_main||"00") === parseInt(vOldObj.kode_main) &&
								parseInt(vItems.kode_sub||"00") > 0
							).map(vItems => vItems.id);

							uDatamainArr.filter(vItems => 
								vItems.kode_akun === uDlgformObj.kode_akun &&
								parseInt(vItems.kode_main||"00") >= parseInt(uDlgformObj.kode_main) &&
								parseInt(vItems.kode_main||"00") < parseInt(vOldObj.kode_main)
							).map(vItems => 
								vItems.kode_main = UFunc.leadZero(parseInt(vItems.kode_main||"00")+1)
							);

							uDatamainArr.filter(vItems => vChildIDoldArr.includes(vItems.id)).
								map(vItems => vItems.kode_main = uDlgformObj.kode_main);
						} else {
							uDatamainArr.filter(vItems => 
								vItems.kode_akun === uDlgformObj.kode_akun &&
								vItems.kode_main === uDlgformObj.kode_main &&
								parseInt(vItems.kode_sub||"00") >= parseInt(uDlgformObj.kode_sub) && 
								parseInt(vItems.kode_sub||"00") < parseInt(vOldObj.kode_sub)
							).map(vItems => 
								vItems.kode_sub = UFunc.leadZero(parseInt(vItems.kode_sub||"00")+1)
							);
						}
					}

					uDatamainArr[vArridx] = vNewObj;
					initResortdata(); 
				}

				setDlgformshow(false);

				let vTimeout = setTimeout(()=>{
					clearTimeout(vTimeout);
					initSelectrow(vTabelid);
				},350);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Gledger - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElButton && (vElButton.disabled = false);
			setLoading(false);
			console.log("(Gledger - apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesurutansub = (_IDTABEL,_JENISPROSES) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		if(vArridx < 0) return;

		const vKonstanmove  = (_JENISPROSES==="UP") ? -1  : 1;
		const vNewindex  	= vArridx + vKonstanmove;
		const vGLkodesub 	= parseInt(uDatamainArr[vArridx].kode_sub||"00");

		uDatamainArr[vArridx].is_loading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeoutx = setTimeout(()=>{
			clearTimeout(vTimeoutx);
			uDatamainArr[vArridx].is_loading = false;
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid: _IDTABEL,
			send_action: _JENISPROSES,//-->UP - DOWN
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_gledger/pr_urutan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			uDatamainArr[vArridx].is_loading = false;
			//console.log("(Gledger - apiProsessimpan) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr[vNewindex].kode_sub = UFunc.leadZero(parseInt(uDatamainArr[vNewindex].kode_sub) - vKonstanmove);
				uDatamainArr[vArridx].kode_sub 	 = UFunc.leadZero(parseInt(uDatamainArr[vArridx].kode_sub) + vKonstanmove);
				
				initResortdata();setEfekview2(!uEfekview2);
			
				let vTimeout = setTimeout(()=>{
					clearTimeout(vTimeout);
					initSelectrow(_IDTABEL);
				},350);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Gledger - apiProsesurutansub) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_loading = false;
			setLoading(false);
			console.log("(Gledger - apiProsesurutansub) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsespindah = () => {
		const vTabelid	= parseInt(uDlgformObj.tabelid);
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,vTabelid);
		if(vArridx < 0) return;

		const vMovingObj	= uDatamainArr[vArridx];
		const vElButton		= document.getElementById("btnDialogSimpan");
		vElButton && (vElButton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout2 = setTimeout(()=>{
			clearTimeout(vTimeout2);
			vElButton && (vElButton.disabled = false);
			setLoading(false);

				let vTimeout = setTimeout(()=>{
					clearTimeout(vTimeout);
					initSelectrow(vTabelid);
				},350);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datajson : JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_gledger/pr_pindahmain";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElButton && (vElButton.disabled = false);
			setLoading(false);
			//console.log("(Gledger - apiProsessimpan) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr.filter(vItems =>
					vItems.kode_akun === vMovingObj.kode_akun &&
					vItems.kode_main === vMovingObj.kode_main &&
					parseInt(vItems.kode_sub||"00") > parseInt(vMovingObj.kode_sub||"00") &&
					parseInt(vItems.levels) > 1
	 			).map(vItems => 
	 				vItems.kode_sub = UFunc.leadZero(parseInt(vItems.kode_sub||"00")-1)
	 			);

	 			vMovingObj.kode_akun	= uDlgformObj.kode_akun;
	 			vMovingObj.kode_main	= uDlgformObj.kode_main;
	 			vMovingObj.kode_sub		= uDlgformObj.kode_sub;

	 			uDatamainArr[vArridx]	= vMovingObj;
	 			initResortdata();

				setDlgformshow(false);
			
				let vTimeout = setTimeout(()=>{
					clearTimeout(vTimeout);
					initSelectrow(vTabelid);
				},350);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Gledger - apiProsespindah) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElButton && (vElButton.disabled = false);
			setLoading(false);
			console.log("(Gledger - apiProsespindah) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		const vTabelid	= parseInt(_IDTABEL);
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,vTabelid);
		if(vArridx < 0) return;

		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout2 = setTimeout(()=>{
			clearTimeout(vTimeout2);
			setLoading(false);

				let vTimeout = setTimeout(()=>{
					clearTimeout(vTimeout);
					initSelectrow(vTabelid);
				},350);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_gledger/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Gledger - apiProsessimpan) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				if(parseInt(uDatamainArr[vArridx].levels) > 1) {
					uDatamainArr.filter(vItems =>
						vItems.kode_akun === uDatamainArr[vArridx].kode_akun &&
						vItems.kode_main === uDatamainArr[vArridx].kode_main &&
						parseInt(vItems.kode_sub||"00") > parseInt(uDatamainArr[vArridx].kode_sub||"00") &&
						parseInt(vItems.levels) > 1
		 			).map(vItems => 
		 				vItems.kode_sub = UFunc.leadZero(parseInt(vItems.kode_sub||"00")-1)
		 			);	
				} else {
					uDatamainArr.filter(vItems =>
						vItems.kode_akun === uDatamainArr[vArridx].kode_akun &&
						parseInt(vItems.kode_main||"00") > parseInt(uDatamainArr[vArridx].kode_main||"00")
		 			).map(vItems => 
		 				vItems.kode_main = UFunc.leadZero(parseInt(vItems.kode_main||"00")-1)
		 			);	
				}

	 			uDatamainArr.splice(vArridx,1);
			
				let vTimeout = setTimeout(()=>{
					clearTimeout(vTimeout);
					initSelectrow(vTabelid);
				},350);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Gledger - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Gledger - apiProseshapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		//if(!uIsInitbefore) //-->PAGINATION_DIATUR_BACKEND--/
			apiLoaddata();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]); setDlgformshow(false);
			setFirstload("YA");
		}
	},[])
	/*//--PAGINATION_DIATUR_BACKEND--/
	React.useEffect(()=>{
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Gledger - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);//-*/
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]); 
	//--END PAGINATION_DIATUR_BACKEND--*/
	//--PAGINATION_DIATUR_FRONTEND--/
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		initResortdata(); 
		setJmlData(uDatamainArr.length);
	},[uDatamainArr,uDatamainArr.length])
	//--END PAGINATION_DIATUR_FRONTEND--*/
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelRowSelect(-1);
		setTabelIDPilih(0);
		setPageActive(1);
	},[uActivetab]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderactionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderactionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		if(uHTMLError500) return;

		uDlgformObj.header 		= "Tambah MAIN Pos Akun";
		uDlgformObj.input_form 	= "MAINPOS";
		uDlgformObj.jenis 		= uActivetab;
		uDlgformObj.levels		= 1;

		//console.log("uActivetab => "+uActivetab);
		if(uDlgformObj.kategori===undefined)
			if(uActivetab==="NERACA") 
				uDlgformObj.kategori = "AKTIVA";
			else
				uDlgformObj.kategori = "PENDAPATAN";

		setDlgformshow(true);
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!uISDlgformshow)
			{ setDlgformObj({}); return}
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(uDlgformObj.input_form!=="MAINPOS") return;
		if(uDlgformObj.kategori===undefined) return;
		if(parseInt(uDlgformObj.tabelid||"0") > 0) return;

		uDlgformObj.akunlevel_arr = uDatamainArr.filter(vItems =>
			parseInt(vItems.levels) <= 1 && 
			(vItems.jenis||"").toUpperCase() === (uDlgformObj.jenis||"").toUpperCase() &&
			parseInt(vItems.kode_akun||"00") !== 3 &&
			parseInt(vItems.kode_akun||"00") !== 7 &&
			(vItems.kategori||"AKTIVA").toUpperCase()===(uDlgformObj.kategori||"").toUpperCase()
		);

		uDlgformObj.urutan = (uDlgformObj.akunlevel_arr||[]).length + 1;
	},[uDlgformObj,uDlgformObj.kategori]);
	React.useEffect(()=>{
		if(uDlgformObj.urutan===undefined) return;

		const vArridx 	= (parseInt(uDlgformObj.urutan)||1) - 1;
		let vAkunObj	= (uDlgformObj.akunlevel_arr||[])[vArridx] || {};

		//console.log("[uDlgformObj.urutan] vAkunObj => "+JSON.stringify(vAkunObj))
		if(UFunc.isEmpty(vAkunObj)) {
			vAkunObj	= (uDlgformObj.akunlevel_arr||[])[vArridx-1] || {
				kode_akun : uDlgformObj.kode_akun || "1",
				kode_main : uDlgformObj.kode_main || "01",
				kode_sub : uDlgformObj.kode_sub || "00",
			};
			uDlgformObj.kode_akun 	= vAkunObj.kode_akun;

			if(uDlgformObj.input_form === "MAINPOS") {
				uDlgformObj.kode_main 	= parseInt(vAkunObj.kode_main)+1;
				uDlgformObj.kode_sub	= "00";
			} else {
				uDlgformObj.kode_main 	= vAkunObj.kode_main;
				uDlgformObj.kode_sub 	= UFunc.leadZero(parseInt(vAkunObj.kode_sub||"0")+1);
			}
			setEfekview(!uEfekview);
			return;
		}

		uDlgformObj.kode_akun = vAkunObj.kode_akun;
		uDlgformObj.kode_main = vAkunObj.kode_main;
		uDlgformObj.kode_sub  = vAkunObj.kode_sub;
		setEfekview(!uEfekview);
	},[uDlgformObj,uDlgformObj.urutan]);
	React.useEffect(()=>{
		if(uDlgformObj.input_form!=="PINDAHMAIN") return;
		if(uDlgformObj.main_id === undefined) return;
		if(parseInt(uDlgformObj.main_id) === parseInt(uDlgformObj.main_id_lama)) {
			const vArridx			= UFunc.getObjectIndeks(uDatamainArr,uDlgformObj.tabelid);

			uDlgformObj.kategori	= uDatamainArr[vArridx].kategori;
			uDlgformObj.kode_akun	= uDatamainArr[vArridx].kode_akun;
			uDlgformObj.kode_main	= uDatamainArr[vArridx].kode_main;
			uDlgformObj.kode_sub	= uDatamainArr[vArridx].kode_sub;
			
			setEfekview(!uEfekview);
			return;
		}

		const vMainidnew	= parseInt(uDlgformObj.main_id);
		const vMainidx		= UFunc.getObjectIndeks(uDatamainArr,vMainidnew);
		const vLastsubObj	= uDatamainArr.filter(vItems =>
			vItems.kode_akun === uDatamainArr[vMainidx].kode_akun &&
			vItems.kode_main === uDatamainArr[vMainidx].kode_main &&
			parseInt(vItems.levels) > 1
		).sort((a,b) => b.kode_sub - a.kode_sub)[0] || uDatamainArr[vMainidx];

		uDlgformObj.kategori = uDatamainArr[vMainidx].kategori;
		uDlgformObj.kode_akun= vLastsubObj.kode_akun;
		uDlgformObj.kode_main= vLastsubObj.kode_main;
		uDlgformObj.kode_sub = UFunc.leadZero(parseInt(vLastsubObj.kode_sub||"00")+1);
		setEfekview(!uEfekview);
	},[uDlgformObj,uDlgformObj.main_id]);
	
	//console.log("Gledger - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )

	return (
	<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder"/>
			
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>
		</CCard>

		<MyDialogform
			options={{centered:false}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header||""}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
	</>
	)
}	

export default Gledger