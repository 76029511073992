import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	CForm,
	CFormCheck,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow
} from '@coreui/react';
import { MyButton } from '../components/index';
import { UFunc } from "../helpers/functions";
var pjson     = require('../../package.json');

const A1langs = (props) => {
	const {setLoading,showToast,prosesExpired}	= props;
	
	const uComponentname 	= A1langs.name;
	const uNavigate			= useNavigate();
	const uDispatch			= useDispatch();

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	const [vBtnSimpanDisabled,setBtnSimpanDisabled]	= React.useState(false);
	const [uInpBahasaset,setInpBahasaset]	= React.useState("BAHASA");
	const [uBahasaselect,setBahasaselect]	= React.useState("BAHASA");
	const [uBahasaArr,setBahasaArr]			= React.useState([]);
	const [uHTMLError500,setHTMLError500]	= React.useState("");

	const hdlKlikSimpan = () => {
		//--VALIDASI--/
		if(uInpBahasaset.toUpperCase() === uBahasaselect.toUpperCase()) {
			return;
		}
		//--END VALIDASI--*/

		apiProsessimpan();
	}

	const apiLoaddata = () => {
		var vFuncName = apiLoaddata.name;
		setHTMLError500("");

		/*//--DATA_UNTUK_TESTING--/
		setInpBahasaset("bahasax"); setBahasaselect("bahasax");
		let vTmpObj	= [
			{value: "bahasa",caption: "BAHASA INDONESIA"},
			{value: "english",caption: "BAHASA INGGRIS"},
			{value: "bahasax",caption: "BAHASA INDONESIA X"},
			{value: "englishx",caption: "BAHASA INGGRIS X"}
		]
		setBahasaArr(vTmpObj);
		return;//-*/

		var vDATAS    = JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		var vURLs     = pjson.homepage+"api/api_langs/ld_data";

		setLoading(true);
		setBtnSimpanDisabled(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+uComponentname+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setBtnSimpanDisabled(false);
			setLoading(false);
			if(output_string.tampil) {
				if(output_string.bahasaset) {
					setInpBahasaset(output_string.bahasaset);
					setBahasaselect(output_string.bahasaset);
				}
				if(output_string.listbahasa) {
					setBahasaArr(JSON.parse(output_string.listbahasa));
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(uNavigate);
			}
		}).catch((error) =>{
			setBtnSimpanDisabled(false);
			setLoading(false);
			console.log("("+uComponentname+"-"+vFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//uNavigate.push("/error500");
		});
	}
	const apiProsessimpan = () => {
		var vFuncName = apiProsessimpan.name;

		var vDATAS    = JSON.stringify({
			send_bahasaselect : uInpBahasaset,
			send_tokenauth : uTokenObj.userinit
		});
		var vURLs     = pjson.homepage+"api/api_langs/pr_simpan";

		setLoading(true);
		setBtnSimpanDisabled(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setBtnSimpanDisabled(false);
			setLoading(false);
			if(output_string.sukses) {
				uDispatch({type: "set", listBahasa: JSON.parse(output_string.langs||"[]")});
				setBahasaselect(uInpBahasaset);
				
				showToast(uBahasaObj.pesan_sukses,"SUKSES");
				setTimeout(function(e){ 
					//const vUrl = window.location.href;
					const vUrl = "/";//pjson.homepage;
					//uNavigate.go("/?")
					//console.log("("+uComponentname+"-"+vFuncName+") vUrl : "+vUrl);
					window.location.assign(vUrl);
				},300);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(uNavigate);
			}
		}).catch((error) =>{
			setBtnSimpanDisabled(false);
			setLoading(false);
			console.log("("+uComponentname+"-"+vFuncName+") catch-error: "+error);
			uNavigate.push("/error500");
		});
	}

	React.useEffect(()=>{
		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
		//---LOAD_LIST_BAHASA--/
		apiLoaddata();
		//---END LOAD_LIST_BAHASA--/
	},[]);

	const initContent=()=>{
		if(uHTMLError500)
			return UFunc.renderHTML(uHTMLError500);

		console.log("(A1langs - initContent) uBahasaArr => "+JSON.stringify(uBahasaArr))
		return (
			<CForm className="mx-2 mx-lg-5 px-lg-5">
			{uBahasaArr.map((vItems,vKeys)=>{
				return (
					<div className="my-2 p-2 px-3 border border-radius-3 bg-light">
					<CFormCheck type="radio" size="sm" key={vKeys}
						onChange={(e)=>setInpBahasaset(e.target.value)}
						label={vItems.caption}
						value={vItems.value.toUpperCase()} 
						defaultChecked={(uInpBahasaset||"BAHASA").toUpperCase() === (vItems.value||"").toUpperCase()}
						id={"inpbahasa"+vItems.value.toLowerCase()} 
						name="inpbahasa"/>
					</div>
				)
			})}
			</CForm>
		)
	}

	return (
		<CCard className="mx-md-2 mx-lg-5">
			<CCardHeader className="fw-bolder">{uBahasaObj.caption_ubahbahasa}</CCardHeader>
			<CCardBody>
				{initContent()}
			</CCardBody>

			<CCardFooter className="text-center">
				<MyButton 
					onClick={()=>hdlKlikSimpan()}
					disabled={vBtnSimpanDisabled}
					iconname="cilCheckAlt">
					{uBahasaObj.word_simpan || "Simpan"}
				</MyButton>
			</CCardFooter>
		</CCard>
	)
}

export default A1langs;