import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CInputGroup,CInputGroupText,CFormSelect,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,
} from '@coreui/react';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { UFunc,cBulanPanjang,cBulanPendek } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 		= require('../../package.json')
const Laplabarugi	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uSettinguserObj		= useSelector(state => state.gListUserSetting);
	const uSettingsystemObj		= useSelector(state => state.gSystemsetting);
	const uHTMLstyleTabel		= useSelector(state => state.gHTMLstyleLaporan);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uSettinguserObj.jml_mobile || 20) : (uSettinguserObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelReset	 		= uHeaderActionObj.isHeaderReset || false;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatavieworiArr,setDatavieworiArr]= React.useState([]);
	const [uDataviewArr,setDataviewArr]		= React.useState([]);
	const [uSumtotalObj,setSumtotalObj]		= React.useState({});
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDataloadedObj,setDataloadedObj]	= React.useState();
	const [uTitelObj,setTitelObj]			= React.useState({});
	const [uDataidentitasObj,setDataidentitasObj]	= React.useState({});
	const [uSortedObj,setSortedObj]			= React.useState({});

	//---DLGFORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//---END DLGFORM--/

	//--DOM_ELEMENTS--/
	const uElJmldata	= document.getElementById("idjmldata");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_ELEMENTS--/

	//---HANDLE--/
	const hdlDlgformToggle = () => { setDlgformshow(false) }
	const hdlKlikDlgformsimpan = () => {
		let vElfocus	= document.getElementById("inpbulanid");
		if((parseInt(uDlgformObj.bln_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inptahun");
		if((parseInt(uDlgformObj.tahun)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Tahun Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inpbulanid");
		const vTanggalpilihDT = UFunc.toDate(uDlgformObj.tahun+"-"+UFunc.leadZero(uDlgformObj.bln_id)+"-01");
		if(UFunc.DateIsBeyond(vTanggalpilihDT)) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan/Tahun Tidak Valid !"); return
		}
		/*vElfocus	= document.getElementById("inpbiayaid");
		if((parseInt(uDlgformObj.jenis_laporan)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Jenis Akun Biaya yang Dipilih Tidak Valid !"); return
		}//-*/

		apiLoadview();
	}
	const hdlKlikCetak = () => {
		if(uJmlData <= 0) return;

		let vContent 	= contentHTMLcetak();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;

		const vBulanID	= parseInt(uDataloadedObj.bln_id)||"0";
		const vTahun	= parseInt(uDataloadedObj.tahun)||"0";

		const vDatapendapatanArr= uDataviewArr.filter(vItems => (vItems.kategori||"").toUpperCase() === "PENDAPATAN")
		const vDatabiayaArr= uDataviewArr.filter(vItems => (vItems.kategori||"").toUpperCase() === "BIAYA")
		const vDatapajakArr= uDataviewArr.filter(vItems => (vItems.kategori||"").toUpperCase() === "PAJAK")

		const vColspan 	= 6;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 10;

		let vTmpObj	= {
			gl_kode : "",
			gl_caption: "",
			nilai_awal: "",
			nilai_debet: "",
			nilai_kredit: "",
			nilai_akhir: "",
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;

		vTmpObj	= {
			gl_kode : {v: "PENDAPATAN",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} } },
			gl_caption: "",
			nilai_awal: "",
			nilai_debet: "",
			nilai_kredit: "",
			nilai_akhir: "",
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		const vRowpendapatan = vRowcount;

		vDatapendapatanArr.map((vItems,vKeys)=>{
			const {
				id,gl_kode,gl_caption,kategori,kode_main,kode_sub,levels,
				nilai_awal,nilai_debet,nilai_kredit,nilai_akhir
			} = vItems;

			vColposwidth = (gl_caption||"").length > vColposwidth 
				? (gl_caption||"").length : vColposwidth; 

			vTmpObj	= {
				gl_kode : {v:gl_kode,t:"s",s:{alignment:{horizontal:(parseInt(levels)===1?"left":"right")},font:{bold: parseInt(levels)===1 }}},
				gl_caption : {v:gl_caption,t:"s",s:{alignment:{horizontal:"left"},font:{bold: parseInt(levels)===1 }}},
				nilai_awal: { v:parseFloat(nilai_awal), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
				nilai_debet: { v:parseFloat(nilai_debet), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
				nilai_kredit: { v:parseFloat(nilai_kredit), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
				nilai_akhir: { v:parseFloat(nilai_akhir), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
			}; 
			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		//--SUB_TOTAL_PENDAPATAN--/
		vTmpObj	= {
			gl_kode : "",
			gl_caption : {v: "SUB TOTAL",t:"s",s:{alignment:{horizontal:"right"},font:{bold:true} } },
			nilai_awal: { v:parseFloat(uSumtotalObj.pendapatan_awal||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_debet: { v:parseFloat(uSumtotalObj.pendapatan_debet||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_kredit: { v:parseFloat(uSumtotalObj.pendapatan_kredit||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_akhir: { v:parseFloat(uSumtotalObj.pendapatan_akhir||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;

		vTmpObj	= {
			gl_kode : "",
			gl_caption: "",
			nilai_awal: "",
			nilai_debet: "",
			nilai_kredit: "",
			nilai_akhir: "",
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUB_TOTAL_PENDAPATAN--/

		vTmpObj	= {
			gl_kode : {v: "BIAYA",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} } },
			gl_caption: "",
			nilai_awal: "",
			nilai_debet: "",
			nilai_kredit: "",
			nilai_akhir: "",
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		const vRowbiaya = vRowcount;

		vDatabiayaArr.map((vItems,vKeys)=>{
			const {
				id,gl_kode,gl_caption,kategori,kode_main,kode_sub,levels,
				nilai_awal,nilai_debet,nilai_kredit,nilai_akhir
			} = vItems;

			vColposwidth = (gl_caption||"").length > vColposwidth 
				? (gl_caption||"").length : vColposwidth; 

			vTmpObj	= {
				gl_kode : {v:gl_kode,t:"s",s:{alignment:{horizontal:(parseInt(levels)===1?"left":"right")},font:{bold: parseInt(levels)===1 }}},
				gl_caption : {v:gl_caption,t:"s",s:{alignment:{horizontal:"left"},font:{bold: parseInt(levels)===1 }}},
				nilai_awal: { v:parseFloat(nilai_awal), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
				nilai_debet: { v:parseFloat(nilai_debet), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
				nilai_kredit: { v:parseFloat(nilai_kredit), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
				nilai_akhir: { v:parseFloat(nilai_akhir), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
			}; 
			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		//--SUB_TOTAL_BIAYA--/
		vTmpObj	= {
			gl_kode : "",
			gl_caption : {v: "SUB TOTAL",t:"s",s:{alignment:{horizontal:"right"},font:{bold:true} } },
			nilai_awal: { v:parseFloat(uSumtotalObj.biaya_awal||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_debet: { v:parseFloat(uSumtotalObj.biaya_debet||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_kredit: { v:parseFloat(uSumtotalObj.biaya_kredit||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_akhir: { v:parseFloat(uSumtotalObj.biaya_akhir||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		
		vTmpObj	= {
			gl_kode : "",
			gl_caption: "",
			nilai_awal: "",
			nilai_debet: "",
			nilai_kredit: "",
			nilai_akhir: "",
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUB_TOTAL_BIAYA--/

		vTmpObj	= {
			gl_kode : {v: "PAJAK",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} } },
			gl_caption: "",
			nilai_awal: "",
			nilai_debet: "",
			nilai_kredit: "",
			nilai_akhir: "",
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		const vRowpajak = vRowcount;

		vDatapajakArr.map((vItems,vKeys)=>{
			const {
				id,gl_kode,gl_caption,kategori,kode_main,kode_sub,levels,
				nilai_awal,nilai_debet,nilai_kredit,nilai_akhir
			} = vItems;

			vColposwidth = (gl_caption||"").length > vColposwidth 
				? (gl_caption||"").length : vColposwidth; 

			vTmpObj	= {
				gl_kode : {v:gl_kode,t:"s",s:{alignment:{horizontal:(parseInt(levels)===1?"left":"right")},font:{bold: parseInt(levels)===1 }}},
				gl_caption : {v:gl_caption,t:"s",s:{alignment:{horizontal:"left"},font:{bold: parseInt(levels)===1 }}},
				nilai_awal: { v:parseFloat(nilai_awal), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
				nilai_debet: { v:parseFloat(nilai_debet), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
				nilai_kredit: { v:parseFloat(nilai_kredit), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
				nilai_akhir: { v:parseFloat(nilai_akhir), t:"n", s:{numFmt: "#,##0",font:{bold: parseInt(levels)===1 }} },
			}; 
			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		//--SUB_TOTAL_PAJAK--/
		vTmpObj	= {
			gl_kode : "",
			gl_caption : {v: "SUB TOTAL",t:"s",s:{alignment:{horizontal:"right"},font:{bold:true} } },
			nilai_awal: { v:parseFloat(uSumtotalObj.biaya_awal||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_debet: { v:parseFloat(uSumtotalObj.biaya_debet||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_kredit: { v:parseFloat(uSumtotalObj.biaya_kredit||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_akhir: { v:parseFloat(uSumtotalObj.biaya_akhir||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		
		vTmpObj	= {
			gl_kode : "",
			gl_caption: "",
			nilai_awal: "",
			nilai_debet: "",
			nilai_kredit: "",
			nilai_akhir: "",
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUB_TOTAL_PAJAK--/

		//--LR_BEFORE_PAJAK--/
		vTmpObj	= {
			gl_kode : "",
			gl_caption : {v:"L/R Sebelum PAJAK",t:"s",s:{alignment:{horizontal:"right"},font:{bold:true,} } },
			nilai_awal: { v:parseFloat(uSumtotalObj.kotor_awal||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_debet: { v:parseFloat(uSumtotalObj.kotor_debet||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_kredit: "",
			nilai_akhir: { v:parseFloat(uSumtotalObj.kotor_akhir||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END LR_BEFORE_PAJAK--/

		//--LR_BEFORE_PAJAK--/
		vTmpObj	= {
			gl_kode : "",
			gl_caption : {v:"L/R SETELAH PAJAK",t:"s",s:{alignment:{horizontal:"right"},font:{bold:true} } },
			nilai_awal: { v:parseFloat(uSumtotalObj.bersih_awal||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_debet: { v:parseFloat(uSumtotalObj.bersih_debet||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_kredit: "",
			nilai_akhir: { v:parseFloat(uSumtotalObj.bersih_akhir||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END LR_BEFORE_PAJAK--/

		//--BALANCE_SALDO--/
		vTmpObj	= {
			gl_kode : "",
			gl_caption : {v: "BALANCE SALDO",t:"s",s:{alignment:{horizontal:"right"},font:{bold:true} } },
			nilai_awal: { v:parseFloat(uSumtotalObj.balance_awal||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_debet: { v:parseFloat(uSumtotalObj.balance_debet||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_kredit: { v:parseFloat(uSumtotalObj.balance_kredit||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_akhir: { v:parseFloat(uSumtotalObj.balance_akhir||0), t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END BALANCE_SALDO--/

		const wsrows	= [{hpt:33}]
		const wscols	= [
			{wpx:75},
			{wch:vColposwidth},
			{wpx:90},
			{wpx:90},
			{wpx:90},
			{wpx:90},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowpendapatan,c:0},e:{r:vRowpendapatan,c:vColspan - 1}},
			{s:{r:vRowbiaya,c:0},e:{r:vRowbiaya,c:vColspan - 1}},
			{s:{r:vRowpajak,c:0},e:{r:vRowpajak,c:vColspan - 1}},
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_Laplabarugi_"+
			(cBulanPendek[vBulanID-1]).toUpperCase()+"_"+vTahun+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+uTitelObj.periode+
		"";
		const vHeaderArr = [
			{v:"Kode GL",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Nama GL",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"S.Awal (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Debet (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Kredit (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"S.Akhir (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		if(uJmlData <= 0) return (
		<div className="text-center my-2 my-lg-5">
			<big className="fst-italic text-primary">
			{uBahasaObj.pesan_dataempty||"Data dengan Filter yang Dipilih Tidak Ditemukan.."}
			</big>
		</div>
		)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;

		const vColspan		= 6;
		
		const vDataviewArr	= uDataviewArr.slice(vMin,(vMax));
		const vDatapendapatanArr= vDataviewArr.filter(vItems => (vItems.kategori||"").toUpperCase() === "PENDAPATAN")
		const vDatabiayaArr= vDataviewArr.filter(vItems => (vItems.kategori||"").toUpperCase() === "BIAYA")
		const vDatapajakArr= vDataviewArr.filter(vItems => (vItems.kategori||"").toUpperCase() === "PAJAK")

		const vStylekolfixed	= {
			position: "sticky",
			left: 0,
			backgroundColor:"#f0f0f0",
			zIndex: 1,
		}

		return (
		<div style={{overflowX: "auto"}}>
			<table style={{minWidth:800,width:"100%"}} 
				className="table table-borderless table-hover" id="idtabeldata">
			<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={85} className="border" style={vStylekolfixed}>Kode GL</th>
			<th className="text-start border">{uBahasaObj.caption_namagl||"Nama GL"}</th>
			<th width="15%" className=" border">{uBahasaObj.caption_saldoawal||"S.Awal"} (Rp)</th>
			<th width="13%" className=" border">{uBahasaObj.word_debet||"Debet"} (Rp)</th>
			<th width="13%" className=" border">{uBahasaObj.word_kredit||"Kredit"} (Rp)</th>
			<th width="15%" className=" border">{uBahasaObj.caption_saldoakhir||"S.Akhir"} (Rp)</th>
			</tr>
			</thead>
			<tbody>
			{vDatapendapatanArr.length > 0 && (
				<tr className="fw-bolder text-uppercase text-primary classborderbottom">
				<td className="" style={vStylekolfixed}>&nbsp;</td>
				<td colSpan={vColspan-1} className="">PENDAPATAN</td>
				</tr>
			)}
			{vDatapendapatanArr.map((vItems,vKeys) => {
				const {
					id,gl_kode,gl_caption,kategori,kode_main,kode_sub,levels,
					nilai_awal,nilai_debet,nilai_kredit,nilai_akhir
				} = vItems;

				vNumber++;

				const vStylecaption	= parseInt(levels) <= 1 ? "fw-bolder" : "ps-3";
				const vStylerow		= parseInt(levels) <= 1 ? "fw-bolder" : "";

				const vNilaiawal	= parseFloat(nilai_awal) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_awal))+")</span>"
					: UFunc.formatAngka(nilai_awal);
				const vNilaidebet	= parseFloat(nilai_debet) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_debet))+")</span>"
					: UFunc.formatAngka(nilai_debet);
				const vNilaikredit	= parseFloat(nilai_kredit) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_kredit))+")</span>"
					: UFunc.formatAngka(nilai_kredit);
				const vNilaiakhir	= parseFloat(nilai_akhir) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_akhir))+")</span>"
					: UFunc.formatAngka(nilai_akhir);

				return (
				<tr className="align-top border-bottom border-bottom-light" key={vKeys}>
				<td className={"text-start px-1 "+vStylecaption} style={vStylekolfixed}>{gl_kode||"-"}</td>
				<td className={"text-start px-1 "+vStylecaption}>{(gl_caption||"-")}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaiawal)}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaidebet)}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaikredit)}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaiakhir)}</td>
				</tr>
				)
			})}
			{vDatapendapatanArr.length > 0 && (
				<>
				<tr className="fw-bolder text-uppercase border-top bg-light">
				<td className="" style={vStylekolfixed}>&nbsp;</td>
				<td className="text-end">Sub Total</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.pendapatan_awal)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.pendapatan_debet)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.pendapatan_kredit)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.pendapatan_akhir)}</td>
				</tr>
				<tr><td colSpan={vColspan} className="bg-dark"/></tr>
				</>
			)}

			{vDatabiayaArr.length > 0 && (
				<tr className="py-3 fw-bolder text-primary text-uppercase border-top classborderbottom">
				<td className="" style={vStylekolfixed}>&nbsp;</td>
				<td colSpan={vColspan-1} className="">BIAYA</td>
				</tr>
			)}
			{vDatabiayaArr.map((vItems,vKeys) => {
				const {
					id,gl_kode,gl_caption,kategori,kode_main,kode_sub,levels,
					nilai_awal,nilai_debet,nilai_kredit,nilai_akhir
				} = vItems;

				vNumber++;

				const vStylecaption	= parseInt(levels) <= 1 ? "fw-bolder" : "ps-3";
				const vStylerow		= parseInt(levels) <= 1 ? "fw-bolder" : "";

				const vNilaiawal	= parseFloat(nilai_awal) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_awal))+")</span>"
					: UFunc.formatAngka(nilai_awal);
				const vNilaidebet	= parseFloat(nilai_debet) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_debet))+")</span>"
					: UFunc.formatAngka(nilai_debet);
				const vNilaikredit	= parseFloat(nilai_kredit) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_kredit))+")</span>"
					: UFunc.formatAngka(nilai_kredit);
				const vNilaiakhir	= parseFloat(nilai_akhir) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_akhir))+")</span>"
					: UFunc.formatAngka(nilai_akhir);

				return (
				<tr className="align-top border-bottom border-bottom-light" key={vKeys}>
				<td className={"text-start px-1 "+vStylecaption} style={vStylekolfixed}>{gl_kode||"-"}</td>
				<td className={"text-start px-1 "+vStylecaption}>{(gl_caption||"-")}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaiawal)}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaidebet)}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaikredit)}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaiakhir)}</td>
				</tr>
				)
			})}
			{vDatabiayaArr.length > 0 && (
				<>
				<tr className="fw-bolder text-uppercase border-top bg-light">
				<td className="" style={vStylekolfixed}>&nbsp;</td>
				<td className="text-end">Sub Total</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.biaya_awal)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.biaya_debet)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.biaya_kredit)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.biaya_akhir)}</td>
				</tr>
				<tr><td colSpan={vColspan} className="bg-dark"/></tr>
				</>
			)}

			{vDatapajakArr.length > 0 && (
				<tr className="py-3 fw-bolder text-primary text-uppercase border-top classborderbottom">
				<td className="" style={vStylekolfixed}>&nbsp;</td>
				<td colSpan={vColspan-1} className="">PAJAK BADAN</td>
				</tr>
			)}
			{vDatapajakArr.map((vItems,vKeys) => {
				const {
					id,gl_kode,gl_caption,kategori,kode_main,kode_sub,levels,
					nilai_awal,nilai_debet,nilai_kredit,nilai_akhir
				} = vItems;

				vNumber++;

				const vStylecaption	= parseInt(levels) <= 1 ? "fw-bolder" : "ps-3";
				const vStylerow		= parseInt(levels) <= 1 ? "fw-bolder" : "";

				const vNilaiawal	= parseFloat(nilai_awal) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_awal))+")</span>"
					: UFunc.formatAngka(nilai_awal);
				const vNilaidebet	= parseFloat(nilai_debet) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_debet))+")</span>"
					: UFunc.formatAngka(nilai_debet);
				const vNilaikredit	= parseFloat(nilai_kredit) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_kredit))+")</span>"
					: UFunc.formatAngka(nilai_kredit);
				const vNilaiakhir	= parseFloat(nilai_akhir) < 0 
					? "<span class='text-danger'>("+UFunc.formatAngka(Math.abs(nilai_akhir))+")</span>"
					: UFunc.formatAngka(nilai_akhir);

				return (
				<tr className="align-top border-bottom border-bottom-light" key={vKeys}>
				<td className={"text-start px-1 "+vStylecaption} style={vStylekolfixed}>{gl_kode||"-"}</td>
				<td className={"text-start px-1 "+vStylecaption}>{(gl_caption||"-")}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaiawal)}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaidebet)}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaikredit)}</td>
				<td className={"text-end px-1 "+vStylerow}>{UFunc.renderHTML(vNilaiakhir)}</td>
				</tr>
				)
			})}
			{vDatapajakArr.length > 0 && (
				<tr className="fw-bolder text-uppercase border-top bg-light">
				<td className="" style={vStylekolfixed}>&nbsp;</td>
				<td className="text-end">Sub Total</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.pajak_awal)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.pajak_debet)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.pajak_kredit)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(uSumtotalObj.pajak_akhir)}</td>
				</tr>
			)}

			<tr><td colSpan={vColspan} className="bg-dark"/></tr>
			<tr className="fw-bolder text-uppercase border-bottom text-secondary">
				<td className="" style={vStylekolfixed}>&nbsp;</td>
				<td className="text-end">Laba Rugi Sebelum Pajak</td>
				<td className="text-end px-1">{(()=>{
					return parseFloat(uSumtotalObj.kotor_awal||0) < 0 
						? UFunc.renderHTML("<span class='text-danger'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.kotor_awal))+")</span>")
						: UFunc.formatAngka(uSumtotalObj.kotor_awal)
					})()}</td>
				<td className="text-end px-1">{(()=>{
					return parseFloat(uSumtotalObj.kotor_debet||0) < 0 
						? UFunc.renderHTML("<span class='text-danger'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.kotor_debet))+")</span>")
						: UFunc.formatAngka(uSumtotalObj.kotor_debet)
					})()}</td>
				<td className="text-end px-1">&nbsp;</td>
				<td className="text-end px-1">{(()=>{
					return parseFloat(uSumtotalObj.kotor_akhir||0) < 0 
						? UFunc.renderHTML("<span class='text-danger'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.kotor_akhir))+")</span>")
						: UFunc.formatAngka(uSumtotalObj.kotor_akhir)
					})()}</td>
			</tr>
			</tbody>

			<tfoot>

			<tr className="fw-bolder text-uppercase border-bottom text-primary">
				<td className="" style={vStylekolfixed}>&nbsp;</td>
				<td className="text-end">Laba Rugi Setelah Pajak</td>
				<td className="text-end px-1">{(()=>{
					return parseFloat(uSumtotalObj.bersih_awal||0) < 0 
						? UFunc.renderHTML("<span class='text-danger'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.bersih_awal))+")</span>")
						: UFunc.formatAngka(uSumtotalObj.bersih_awal)
					})()}</td>
				<td className="text-end px-1">{(()=>{
					return parseFloat(uSumtotalObj.bersih_debet||0) < 0 
						? UFunc.renderHTML("<span class='text-danger'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.bersih_debet))+")</span>")
						: UFunc.formatAngka(uSumtotalObj.bersih_debet)
					})()}</td>
				<td className="text-end px-1">&nbsp;</td>
				<td className="text-end px-1">{(()=>{
					return parseFloat(uSumtotalObj.bersih_akhir||0) < 0 
						? UFunc.renderHTML("<span class='text-danger'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.bersih_akhir))+")</span>")
						: UFunc.formatAngka(uSumtotalObj.bersih_akhir)
					})()}</td>
			</tr>

			<tr className="fw-bolder text-white bg-dark border-top border-top-2 border-top-secondary">
			<td className="">&nbsp;</td>
			<td className="text-end">BALANCE SALDO</td>
			<td className="text-end">{UFunc.formatAngka(uSumtotalObj.balance_awal)}</td>
			<td className="text-end">{
				uDataloadedObj.jenis_laporan==="SATUBULAN" 
					? "" : UFunc.formatAngka(uSumtotalObj.balance_debet)
			}</td>
			<td className="text-end">{UFunc.formatAngka(uSumtotalObj.balance_kredit)}</td>
			<td className="text-end">{UFunc.formatAngka(uSumtotalObj.balance_akhir)}</td>
			</tr>
			</tfoot>
			</table>
		</div>
		)
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSettingsystemObj.jml_tahun_laporan)||5;

		return (
		<CForm>
		<CRow className="my-2 mx-0">
		<CCol>Periode</CCol>
		<CCol md="8">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.bln_id}
				onChange={(e)=>{uDlgformObj.bln_id=e.target.value; setEfekview(!uEfekview)}}
				id="inpbulanid">
			{cBulanPanjang.map((vItems,vKeys)=>{
				return (
				<option value={vKeys+1} key={vKeys}>{vItems.toUpperCase()}</option>
				)
			})}
			</CFormSelect>
			
			<span className="mx-1">&nbsp;</span>

			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.tahun}
				onChange={(e)=>{uDlgformObj.tahun=e.target.value; setEfekview(!uEfekview)}}
				id="inptahun">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2 mx-0">
		<CCol>Jenis Laporan</CCol>
		<CCol md="8">
			<CFormSelect size="sm"
				value={uDlgformObj.jenis_laporan}
				onChange={(e)=>{uDlgformObj.jenis_laporan=e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpjenislaporan">
			<option value="SATUBULAN">Per SATU BULAN</option>
			<option value="BULANKUMULATIF">Per Bulan KUMULATIF</option>
			</CFormSelect>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentHTMLcetak = () => {
		let vHTMLs	= uHTMLstyleTabel;

		//console.log("(Laplabarugi - contentHTMLcetak) uDataidentitasObj => "+(uDataidentitasObj));

		const vColspan 		= 6;
		const vDataviewArr	= uDataviewArr;
		const vDatapendapatanArr= vDataviewArr.filter(vItems => (vItems.kategori||"").toUpperCase() === "PENDAPATAN")
		const vDatabiayaArr	= vDataviewArr.filter(vItems => (vItems.kategori||"").toUpperCase() === "BIAYA")
		const vDatapajakArr	= vDataviewArr.filter(vItems => (vItems.kategori||"").toUpperCase() === "PAJAK")

		vHTMLs	+= UFunc.RenderTabel(uDataidentitasObj,vColspan,false);
		
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			`<div>`+uTitelObj.subtitel+`</div>`;
		const vJudulsub		= `
			<div>Periode <b>`+uTitelObj.periode+`</b></div>
		`;

		const vTabelheader	= `
			<th width="10%" align="left">`+(uBahasaObj.caption_kodegl||"Kode GL")+`</th>
			<th align="left">`+(uBahasaObj.caption_namagl||"Nama GL")+`</th>
			<th width="13%">S.Awal (Rp)</th>
			<th width="13%">Debet (Rp)</th>
			<th width="13%">Kredit (Rp)</th>
			<th width="13%">S.Akhir (Rp)</th>
			</tr>
		`;
		
		let vTabelbody	= "";
		let vNumber		= 0;

		vTabelbody 		+= `
			<tr>
			<td style="font-weight:bolder;border-bottom:1px solid black"
				align="left" colspan="`+vColspan+`">PENDAPATAN</td>
			</tr>
		`;
		vTabelbody 		+= vDatapendapatanArr.map((vItems,vKeys) => {
			const {
				id,gl_kode,gl_caption,kategori,kode_main,kode_sub,levels,
				nilai_awal,nilai_debet,nilai_kredit,nilai_akhir
			} = vItems;
			vNumber++;

			const vStylecaption	= parseInt(levels) <= 1 ? "" : "padding-left:5px;";
			const vStylerow		= parseInt(levels) <= 1 ? "font-weight:bold" : "";

			const vNilaiawal	= parseFloat(nilai_awal) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_awal))+")</font>"
				: UFunc.formatAngka(nilai_awal);
			const vNilaidebet	= parseFloat(nilai_debet) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_debet))+")</font>"
				: UFunc.formatAngka(nilai_debet);
			const vNilaikredit	= parseFloat(nilai_kredit) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_kredit))+")</font>"
				: UFunc.formatAngka(nilai_kredit);
			const vNilaiakhir	= parseFloat(nilai_akhir) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_akhir))+")</font>"
				: UFunc.formatAngka(nilai_akhir);
			return (`
			<tr valign="top" key={vKeys} style="`+vStylerow+`">
				<td align="left"><span style="`+vStylecaption+`">`+(gl_kode||"-")+`</span></td>
				<td align="left"><span style="`+vStylecaption+`">`+(gl_caption||"-")+`</span></td>
				<td align="right">`+vNilaiawal+`</td>
				<td align="right">`+vNilaidebet+`</td>
				<td align="right">`+vNilaikredit+`</td>
				<td align="right">`+vNilaiakhir+`</td>
			</tr>
			`)
		}).join("");

		vTabelbody += `
			<tr style="font-weight:bolder">
			<td align="left" style="border-top:1px solid black;border-bottom:1px solid black;">&nbsp;</td>
			<td align="left" style="border-top:1px solid black;border-bottom:1px solid black;">Sub Total</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.pendapatan_awal)+`</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.pendapatan_debet)+`</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.pendapatan_kredit)+`</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.pendapatan_akhir)+`</td>
			</tr>
			<tr><td colspan="`+vColspan+`">&nbsp;</td></tr>
		`;

		vTabelbody 		+= `
			<tr>
			<td style="font-weight:bolder;border-bottom:1px solid black"
				align="left" colspan="`+vColspan+`">BIAYA</td>
			</tr>
		`;
		vTabelbody 		+= vDatabiayaArr.map((vItems,vKeys) => {
			const {
				id,gl_kode,gl_caption,kategori,kode_main,kode_sub,levels,
				nilai_awal,nilai_debet,nilai_kredit,nilai_akhir
			} = vItems;
			vNumber++;

			const vStylecaption	= parseInt(levels) <= 1 ? "" : "padding-left:5px;";
			const vStylerow		= parseInt(levels) <= 1 ? "font-weight:bold" : "";

			const vNilaiawal	= parseFloat(nilai_awal) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_awal))+")</font>"
				: UFunc.formatAngka(nilai_awal);
			const vNilaidebet	= parseFloat(nilai_debet) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_debet))+")</font>"
				: UFunc.formatAngka(nilai_debet);
			const vNilaikredit	= parseFloat(nilai_kredit) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_kredit))+")</font>"
				: UFunc.formatAngka(nilai_kredit);
			const vNilaiakhir	= parseFloat(nilai_akhir) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_akhir))+")</font>"
				: UFunc.formatAngka(nilai_akhir);

			return (`
			<tr valign="top" key={vKeys} style="`+vStylerow+`">
				<td align="left"><span style="`+vStylecaption+`">`+(gl_kode||"-")+`</span></td>
				<td align="left"><span style="`+vStylecaption+`">`+(gl_caption||"-")+`</span></td>
				<td align="right">`+vNilaiawal+`</td>
				<td align="right">`+vNilaidebet+`</td>
				<td align="right">`+vNilaikredit+`</td>
				<td align="right">`+vNilaiakhir+`</td>
			</tr>
			`)
		}).join("");

		vTabelbody += `
			<tr style="font-weight:bolder;">
			<td align="left" style="border-top:1px solid black;border-bottom:1px solid black;">&nbsp;</td>
			<td align="left" style="border-top:1px solid black;border-bottom:1px solid black;">Sub Total</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.biaya_awal)+`</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.biaya_debet)+`</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.biaya_kredit)+`</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.biaya_akhir)+`</td>
			</tr>
			<tr><td colspan="`+vColspan+`">&nbsp;</td></tr>
		`;

		vTabelbody 		+= `
			<tr>
			<td style="font-weight:bolder;border-bottom:1px solid black"
				align="left" colspan="`+vColspan+`">PAJAK BADAN</td>
			</tr>
		`;
		vTabelbody 		+= vDatapajakArr.map((vItems,vKeys) => {
			const {
				id,gl_kode,gl_caption,kategori,kode_main,kode_sub,levels,
				nilai_awal,nilai_debet,nilai_kredit,nilai_akhir
			} = vItems;
			vNumber++;

			const vStylecaption	= parseInt(levels) <= 1 ? "" : "padding-left:5px;";
			const vStylerow		= parseInt(levels) <= 1 ? "font-weight:bold" : "";

			const vNilaiawal	= parseFloat(nilai_awal) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_awal))+")</font>"
				: UFunc.formatAngka(nilai_awal);
			const vNilaidebet	= parseFloat(nilai_debet) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_debet))+")</font>"
				: UFunc.formatAngka(nilai_debet);
			const vNilaikredit	= parseFloat(nilai_kredit) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_kredit))+")</font>"
				: UFunc.formatAngka(nilai_kredit);
			const vNilaiakhir	= parseFloat(nilai_akhir) < 0 
				? "<font color='red'>("+UFunc.formatAngka(Math.abs(nilai_akhir))+")</font>"
				: UFunc.formatAngka(nilai_akhir);

			return (`
			<tr valign="top" key={vKeys} style="`+vStylerow+`">
				<td align="left"><span style="`+vStylecaption+`">`+(gl_kode||"-")+`</span></td>
				<td align="left"><span style="`+vStylecaption+`">`+(gl_caption||"-")+`</span></td>
				<td align="right">`+vNilaiawal+`</td>
				<td align="right">`+vNilaidebet+`</td>
				<td align="right">`+vNilaikredit+`</td>
				<td align="right">`+vNilaiakhir+`</td>
			</tr>
			`)
		}).join("");

		vTabelbody += `
			<tr style="font-weight:bolder;">
			<td align="left" style="border-top:1px solid black;border-bottom:1px solid black;">&nbsp;</td>
			<td align="left" style="border-top:1px solid black;border-bottom:1px solid black;">Sub Total</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.pajak_awal)+`</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.pajak_debet)+`</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.pajak_kredit)+`</td>
			<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">`+UFunc.formatAngka(uSumtotalObj.pajak_akhir)+`</td>
			</tr>
			<tr><td colspan="`+vColspan+`">&nbsp;</td></tr>
		`;

		let vFootawal	= parseFloat(uSumtotalObj.kotor_awal) < 0 
			? "<font color='red'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.kotor_awal))+")</font>"
			: UFunc.formatAngka(uSumtotalObj.kotor_awal);
		let vFootdebet	= parseFloat(uSumtotalObj.kotor_debet) < 0 
			? "<font color='red'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.kotor_debet))+")</font>"
			: UFunc.formatAngka(uSumtotalObj.kotor_debet);
		let vFootkredit	= "&nbsp;";
		let vFootakhir	= parseFloat(uSumtotalObj.kotor_akhir) < 0 
			? "<font color='red'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.kotor_akhir))+")</font>"
			: UFunc.formatAngka(uSumtotalObj.kotor_akhir);

		let vTdstyle	= "border-top:1px dashed gray;border-bottom:none;";
		vTabelbody += `
		<tr style="color:#DCDCDC;">
			<td align="right" style="`+vTdstyle+`">&nbsp;</td>
			<td align="left" style="`+vTdstyle+`">Laba / Rugi Sebelum Pajak</td>
			<td align="right" style="`+vTdstyle+`">`+vFootawal+`</td>
			<td align="right" style="`+vTdstyle+`">`+vFootdebet+`</td>
			<td align="right" style="`+vTdstyle+`">`+vFootkredit+`</td>
			<td align="right" style="`+vTdstyle+`">`+vFootakhir+`</td>
		</tr>
		`;

		vFootawal	= parseFloat(uSumtotalObj.bersih_awal) < 0 
			? "<font color='red'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.bersih_awal))+")</font>"
			: UFunc.formatAngka(uSumtotalObj.bersih_awal);
		vFootdebet	= parseFloat(uSumtotalObj.bersih_debet) < 0 
			? "<font color='red'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.bersih_debet))+")</font>"
			: UFunc.formatAngka(uSumtotalObj.bersih_debet);
		vFootkredit	= "&nbsp;";
		vFootakhir	= parseFloat(uSumtotalObj.bersih_akhir) < 0 
			? "<font color='red'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.bersih_akhir))+")</font>"
			: UFunc.formatAngka(uSumtotalObj.bersih_akhir);

		vTdstyle	= "border-top:1px solid black;border-bottom:none;";
		vTabelbody += `
			<tr style="font-weight:bolder;color:blue;">
			<td align="right" style="`+vTdstyle+`">&nbsp;</td>
			<td align="left" style="`+vTdstyle+`">Laba / Rugi Setelah Pajak</td>
			<td align="right" style="`+vTdstyle+`">`+vFootawal+`</td>
			<td align="right" style="`+vTdstyle+`">`+vFootdebet+`</td>
			<td align="right" style="`+vTdstyle+`">`+vFootkredit+`</td>
			<td align="right" style="`+vTdstyle+`">`+vFootakhir+`</td>
			</tr>
		`;

		vFootawal	= parseFloat(uSumtotalObj.balance_awal) < 0 
			? "<font color='red'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.balance_awal))+")</font>"
			: UFunc.formatAngka(uSumtotalObj.balance_awal);
		vFootdebet	= parseFloat(uSumtotalObj.balance_debet) < 0 
			? "<font color='red'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.balance_debet))+")</font>"
			: UFunc.formatAngka(uSumtotalObj.balance_debet);
		vFootkredit	= parseFloat(uSumtotalObj.balance_kredit) < 0 
			? "<font color='red'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.balance_kredit))+")</font>"
			: UFunc.formatAngka(uSumtotalObj.balance_kredit);
		vFootakhir	= parseFloat(uSumtotalObj.balance_akhir) < 0 
			? "<font color='red'>("+UFunc.formatAngka(Math.abs(uSumtotalObj.balance_akhir))+")</font>"
			: UFunc.formatAngka(uSumtotalObj.balance_akhir);

		vTdstyle	= "border-bottom:1px solid black;border-top:3px double black;";
		vTabelbody += `
			<tr style="font-weight:bolder">
			<td align="right" style="`+vTdstyle+`">&nbsp;</td>
			<td align="left" style="`+vTdstyle+`">BALANCE SALDO</td>
			<td align="right" style="`+vTdstyle+`">`+vFootawal+`</td>
			<td align="right" style="`+vTdstyle+`">`+vFootdebet+`</td>
			<td align="right" style="`+vTdstyle+`">`+vFootkredit+`</td>
			<td align="right" style="`+vTdstyle+`">`+vFootakhir+`</td>
			</tr>
		`;

		const vTabelfooter = ``;

		vHTMLs 		= vHTMLs.replace(/_TITELSUB_/g,vJudulsub);
		vHTMLs 		= vHTMLs.replace(/_JUDULLAPORAN_/g,vJudullaporan);
		vHTMLs 		= vHTMLs.replace(/_TABELHEADER_/g,vTabelheader);
		vHTMLs 		= vHTMLs.replace(/_TABELBODY_/g,vTabelbody);
		vHTMLs 		= vHTMLs.replace(/_TABELFOOTER_/g,vTabelfooter);

		return vHTMLs;
	}
	//---END CONTENT--/

	//---API--/
	const apiLoadinit = () => {
		setHTMLError500();
		setDataloadedObj();
		setSortedObj({});
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_laplabarugi/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Laplabarugi-apiLoadinit) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				//console.log("(Laplabarugi-apiLoadinit) output_string.databiaya : "+(output_string.databiaya));
				setDataidentitasObj(JSON.parse(output_string.dataidentitas || "{}"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Laplabarugi-apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Laplabarugi-apiLoadinit) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadview = () => {
		const vElsimpan	= document.getElementById("btnDialogSimpan");
		setDataloadedObj();
		setPageActive(1);
		setDatavieworiArr([]);
		setSortedObj({});
		
		setPageActive(1);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);

		vElsimpan && (vElsimpan.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parjson: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_laplabarugi/ld_view";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			//console.log("(Laplabarugi-apiLoadview) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = (JSON.parse(output_string.datalaporan || "[]"));
				setDatavieworiArr([{firstrow:1},...vTmpArr]);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);

				setDataloadedObj(uDlgformObj);
				hdlDlgformToggle();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Laplabarugi-apiLoadview) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Laplabarugi - apiLoadview) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadinit();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			hdlDlgformToggle();
			setDataloadedObj(false);
			setDatavieworiArr([]);
			setSortedObj({});
			setDataidentitasObj({});
			setJmlData(0);setJmlHal(0);
		}
	},[])
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		const vDatadefaultArr = uDatavieworiArr.slice(1);
		setDataviewArr(vDatadefaultArr);
	},[uDatavieworiArr,uDatavieworiArr.length])
	React.useEffect(()=>{
		setJmlData(uDataviewArr.length);
		if(uDataviewArr.length <= 0) {
			setSumtotalObj({}); return;
		}

		uDataviewArr.map(vItems => {
			if(parseInt(vItems.levels)===1) {
				const vDatasubArr = uDataviewArr.filter(vItemsub =>
					parseInt(vItemsub.levels) > 1 
					&& vItemsub.kode_akun === vItems.kode_akun
					&& vItemsub.kode_main === vItems.kode_main
				);

				vItems.nilai_awal = vDatasubArr.reduce((vNilaistored,vItemsub)=>{
					return (parseFloat(vNilaistored||0) + parseFloat(vItemsub.nilai_awal))
				}, 0);
				vItems.nilai_debet = vDatasubArr.reduce((vNilaistored,vItemsub)=>{
					return (parseFloat(vNilaistored||0) + parseFloat(vItemsub.nilai_debet))
				}, 0);
				vItems.nilai_kredit = vDatasubArr.reduce((vNilaistored,vItemsub)=>{
					return (parseFloat(vNilaistored||0) + parseFloat(vItemsub.nilai_kredit))
				}, 0);
				vItems.nilai_akhir = vDatasubArr.reduce((vNilaistored,vItemsub)=>{
					return (parseFloat(vNilaistored||0) + parseFloat(vItemsub.nilai_akhir))
				}, 0);
			}
		});

		uSumtotalObj.pendapatan_awal = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="PENDAPATAN").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_awal))
			},0);
		uSumtotalObj.pendapatan_debet = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="PENDAPATAN").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_debet))
			},0);
		uSumtotalObj.pendapatan_kredit = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="PENDAPATAN").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_kredit))
			},0);
		uSumtotalObj.pendapatan_akhir = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="PENDAPATAN").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_akhir))
			},0);

		uSumtotalObj.biaya_awal = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="BIAYA").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_awal))
			}, 0);
		uSumtotalObj.biaya_debet = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="BIAYA").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_debet))
			}, 0);
		uSumtotalObj.biaya_kredit = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="BIAYA").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_kredit))
			}, 0);
		uSumtotalObj.biaya_akhir = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="BIAYA").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_akhir))
			}, 0);//-*/

		uSumtotalObj.pajak_awal = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="PAJAK").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_awal))
			}, 0);
		uSumtotalObj.pajak_debet = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="PAJAK").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_debet))
			}, 0);
		uSumtotalObj.pajak_kredit = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="PAJAK").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_kredit))
			}, 0);
		uSumtotalObj.pajak_akhir = uDataviewArr.filter(vItems => 
			parseInt(vItems.levels) > 1 
			&& (vItems.kategori||"").toUpperCase()==="PAJAK").
			reduce((vNilaistored,vItemsub)=>{
				return (vNilaistored + parseFloat(vItemsub.nilai_akhir))
			}, 0);

		uSumtotalObj.kotor_awal	= uSumtotalObj.pendapatan_awal - uSumtotalObj.biaya_awal;
		uSumtotalObj.kotor_debet= (uSumtotalObj.pendapatan_akhir - uSumtotalObj.biaya_akhir) -
			(uSumtotalObj.pendapatan_awal - uSumtotalObj.biaya_awal);
		uSumtotalObj.kotor_akhir= uSumtotalObj.pendapatan_akhir - uSumtotalObj.biaya_akhir;

		uSumtotalObj.bersih_awal= uSumtotalObj.pendapatan_awal - uSumtotalObj.biaya_awal - uSumtotalObj.pajak_awal;
		uSumtotalObj.bersih_debet= (uSumtotalObj.pendapatan_akhir - uSumtotalObj.biaya_akhir - uSumtotalObj.pajak_akhir) -
			(uSumtotalObj.pendapatan_awal - uSumtotalObj.biaya_awal - uSumtotalObj.pajak_awal);
		uSumtotalObj.bersih_akhir= uSumtotalObj.pendapatan_akhir - uSumtotalObj.biaya_akhir - uSumtotalObj.pajak_akhir;

		uSumtotalObj.balance_awal	= uSumtotalObj.pendapatan_awal;
		uSumtotalObj.balance_debet 	= (uSumtotalObj.pendapatan_debet + 
			uSumtotalObj.biaya_debet) + 
			(uSumtotalObj.kotor_debet);
		uSumtotalObj.balance_kredit = uSumtotalObj.pendapatan_kredit + 
			uSumtotalObj.biaya_kredit +
			uSumtotalObj.pajak_kredit;
		uSumtotalObj.balance_akhir = uSumtotalObj.pendapatan_akhir;
	},[uDataviewArr,uDataviewArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		console.log("(Laplabarugi) uHTMLError500 => "+uHTMLError500)
		if(uHTMLError500) return;

		const vTanggalnowDT	= new Date();
		if(uDlgformObj.bln_id === undefined)
			uDlgformObj.bln_id = uDataloadedObj ? uDataloadedObj.bln_id : vTanggalnowDT.getMonth()+1;
		if(uDlgformObj.tahun === undefined)
			uDlgformObj.tahun = uDataloadedObj ? uDataloadedObj.tahun : vTanggalnowDT.getFullYear();
		if(uDlgformObj.jenis_laporan === undefined)
			uDlgformObj.jenis_laporan = uDataloadedObj ? uDataloadedObj.jenis_laporan : "SATUBULAN";

		uDlgformObj.header = "Pilih Filter Laporan";
		setDlgformshow(true);
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderReset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uDataloadedObj) apiLoadinit()
		else { setDatavieworiArr([]);setDataviewArr([]); setDataloadedObj(); }
	},[uHandelReset]);
	React.useEffect(()=>{
		const vElfilter	= document.getElementById("btnFilter");
		if(!uISDlgformshow) { 
			vElfilter && (vElfilter.disabled = false)
			setDlgformObj({}); return 
		}

		vElfilter && (vElfilter.disabled = true)
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(isMobile) return;

			const vElfocus = document.getElementById("inpbulanid");
			vElfocus && vElfocus.focus();
		},350);
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uDataloadedObj) { setTitelObj({}); return }

		uTitelObj.periode 	= cBulanPanjang[parseInt(uDataloadedObj.bln_id)-1]+" "+uDataloadedObj.tahun;
		uTitelObj.subtitel  = uDataloadedObj.jenis_laporan==="SATUBULAN" ? "Per SATU Bulan" : "Per Bulan KUMULATIF";
	},[uDataloadedObj]);
	/*React.useEffect(()=>{
		if(uDatavieworiArr.length <= 0) return;

		if(uPageActive > 1) setPageActive(1);
		else { setTabelRowSelect(-1); setTabelIDPilih(0); }

		if(UFunc.isEmpty(uSortedObj)) { 
			const vDatadefaultArr = uDatavieworiArr.slice(1);
			setDataviewArr(vDatadefaultArr);
			return
		}

		if(uSortedObj.mode==="ASC") {
			uDataviewArr.sort((a,b)=> a[uSortedObj.field] - b[uSortedObj.field])
		} else if(uSortedObj.mode==="DESC") {
			uDataviewArr.sort((a,b)=> b[uSortedObj.field] - a[uSortedObj.field])
		}
	},[uSortedObj,
	uSortedObj.field,
	uSortedObj.mode
	]);//-*/
	
	//console.log("Laplabarugi - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )//>

	return (
	<>
		{(!uDataloadedObj) ? (
		<CCard className="classcardbg">
			<CCardHeader className="">
			<small className="fw-bolder">Pilih Filter Laporan</small>
			</CCardHeader>

			<CCardBody className="d-flex justify-content-center align-items-center" style={{minHeight:200}}>
			<div className="classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between">
			<div>
			<div className="">Periode <b>{uTitelObj.periode}</b></div>
			<small className="text-primary">{uTitelObj.subtitel}</small>
			</div>

			<div>
				<CLink onClick={()=>hdlKlikCetak()} className="classcursorpointer classikon classikoncetak"/>
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller">
					<small className="text-muted">
						{UFunc.formatAngka(uTimeElapsed)}ms
					</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={hdlDlgformToggle}
			renderContent={contentDlgform()}/>
		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
	</>
	)//>
}	

export default Laplabarugi