import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CCardHeader,
	CCard,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CFormTextarea,
	CFormSelect,
	CButton,
	CBadge,
	CSpinner,
	CInputGroup,
	CInputGroupText,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { isMobile } from "react-device-detect";
import { 
	MyPagination,
	MyDialogform,
	MyPage500,
	MyDialoglookup,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';

const pjson     = require('../../package.json');
const Aset 		= (props) => {
	const uComponentname 	= Aset.name;
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;//-->hanya untuk API
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uHTMLstyleTabel=useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp		= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 		= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted

	const uMaxData							= isMobile ? (uSettingObj.jml_mobile || 30) : (uSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uFirstload,setFirstload]			= React.useState("YA");

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;

	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uKasGLArrObj,setKasGLArrObj]		= React.useState(JSON.parse(localStorage.getItem("kasgllist")));
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [isShowForm,setShowForm]			= React.useState(false);
	const [uDialogHeader,setDialogHeader]	= React.useState("");

	const [uInpAsetnama,setInpAsetnama] 	= React.useState("");
	const [uInpKasID,setInpKasID]			= React.useState("0");
	const [uInpKascaption,setInpKascaption]	= React.useState("");
	const [uInpHargabeli,setInpHargabeli]	= React.useState(0);
	const [uInpNilaisusut,setInpNilaisusut]	= React.useState(0);
	const [uInpHargajual,setInpHargajual]	= React.useState(0);
	const [uInpNonota,setInpNonota]			= React.useState("");
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");

	const [uIsProsesrusak,setIsProsesrusak]	= React.useState(false);

	const [uElBtnDlgsimpan,setBtnDlgsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata		= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//console.log("("+uComponentname+") uHeaderActionObj.isHeaderView: "+uHeaderActionObj.isHeaderView);
	//console.log("("+uComponentname+") uHandelView: "+uHandelView);

	//--LOOKUP_DIALOG--/
	const [uDlglookupjenis,setDlglookupjenis] 	= React.useState("");
	const [uInpDlglookup,setInpDlglookup] 		= React.useState("");
	const [uDlglookupheader,setDlglookupheader] = React.useState("");
	const [uIsDlglookupshow,setDlglookupshow] 	= React.useState(false);
	const [uDataDlglookupArr,setDataDlglookupArr] = React.useState([]);
	//--END LOOKUP_DIALOG--/

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowForm(false); }
	const hdlToggleDlglookup=()=>{ setDlglookupshow(false); }
	const hdlKlikKaslookup = () => {
		setInpDlglookup(uInpKascaption); setInpKascaption("");

		const vTmpArr	= uKasGLArrObj.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_kaspenerima||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKlikDlglookuppilih=(_IDITEM)=>{
		const vIdx = UFunc.getObjectIndeks(uDataDlglookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		const vElsfocusnext = document.getElementById("inpketerangan");
		setInpKasID(uDataDlglookupArr[vIdx].id)
		setInpKascaption(uDataDlglookupArr[vIdx].caption)
		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			vElsfocusnext && vElsfocusnext.focus()
		},350);
	}
	const hdlKlikJual=async(_IDTABEL)=>{
		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setInpAsetnama(uDatamainArr[vObjdx].nama_aset);
		setInpHargabeli(uDatamainArr[vObjdx].harga_beli||"0");
		setInpNilaisusut((uDatamainArr[vObjdx].nilai_susut||"0"));
		setDialogHeader((uBahasaObj.caption_setjual||"caption_setjual")+": "+(uDatamainArr[vObjdx].nama_aset || "").toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}
	const hdlKlikSimpanJual=()=>{
		//--VALIDASI_SIMPAN--/
		if((parseInt(uInpHargajual)||0) < 0) {
			showToast((uBahasaObj.caption_hargajual||"caption_hargajual")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+".."); 
			document.getElementById("inphargajual").focus();
			return;
		}
		//--END VALIDASI_SIMPAN--/

		apiSetJual();
	}
	const hdlKlikRusak=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_setrusak||"caption_setrusak")+
			": <B>"+uDatamainArr[vObjdx].nama_aset.toUpperCase()+"</B>"+
			"<br/>"+(uBahasaObj.petunjuk_setrusak||"petunjuk_setrusak")+
			"<br/><br/><B>"+(uBahasaObj.confirm_hapus||"confirm_yakin")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiSetRusak(_IDTABEL);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initJmldata=()=>{
		const vKeyword = uKeywordObj.kwd_aset || "";
		if (vKeyword.length > 2) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFormAwal=()=>{
		setTabelIDPilih(0);

		setInpAsetnama("");
		setInpHargabeli(0);
		setInpNilaisusut(0);
		setInpHargajual(0);
		setInpKasID("0");
		setInpKascaption("");
		setInpNonota("");
		setInpKeterangan("");
	}
	//---END INIT_FUNCTION--/

	//--CONTENT--/
	const contentMain=()=>{
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_aset || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		//const vDataViewObj	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		const vDataViewObj	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="3" lg="4" className="text-start">{uBahasaObj.caption_namaaset||"caption_namaaset"}</CCol>
				<CCol md="2" lg="1" className="">{uBahasaObj.caption_tglbeli||"caption_tglbeli"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_hperolehan||"caption_hperolehan"}<div>(Rp)</div></CCol>
				<CCol md="2" className="">{uBahasaObj.word_penyusutan||"word_penyusutan"}<div>(Rp)</div></CCol>
				<CCol md="2" className="">{uBahasaObj.caption_nilaisisa||"caption_nilaisisa"}<div>(Rp)</div></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataViewObj.map((vItems,vKeys)=>{
			const {
				id,nama_aset,jeniscaption,tgl_beli,tgl_susut_nol,
				harga_beli,tarif_per_th,nilai_susut,nilai_sisa,
				is_processing
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (nama_aset||"").toUpperCase();

			const vAsetnama	= vKeyword==="" ? UFunc.capitalize(nama_aset||"") 
				: (UFunc.capitalize(nama_aset||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vJeniscaption= vKeyword==="" ? (jeniscaption||"-") 
				: (jeniscaption||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglBeli= vKeyword==="" ? UFunc.TglAngka(tgl_beli||"") 
				: (UFunc.TglAngka(tgl_beli)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
				
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+(uTabelRowSelect===vKeys?"classrowselect":"")}>
			<td align="right" className="px-1 pe-0">{(vNumber)}.</td>

			<td className="px-1">
			<CRow className="mx-0">

				<CCol md="3" lg="4" className="px-1 text-start d-flex justify-content-between">
					<div>
					<div className="text-info">
						{UFunc.renderHTML(vAsetnama)}
					</div>
					<small className="d-none d-lg-block">
						{uBahasaObj.caption_jenisaset||"caption_jenisaset"}:{" "}
						<span className="fw-bolder text-uppercase">{UFunc.renderHTML(vJeniscaption)}</span>
					</small>
					<small className="d-none d-lg-block">
						{uBahasaObj.caption_tarifperth||"caption_tarifperth"}:{" "}
						<strong>{UFunc.formatAngka(tarif_per_th)}%</strong>
					</small>
					
					<div className="d-none d-md-block d-lg-none classfontsmaller">
					<small>
						{uBahasaObj.caption_jenisaset||"caption_jenisaset"}:{" "}
						<span className="fw-bolder text-uppercase">{UFunc.renderHTML(vJeniscaption)}</span>
					</small>
					</div>
					<div className="d-none d-md-block d-lg-none classfontsmaller">
					<small>
						{uBahasaObj.caption_tarifperth||"caption_tarifperth"}:{" "}
						<strong>{UFunc.formatAngka(tarif_per_th)}%</strong>
					</small>
					</div>
					</div>

					{(uIsAllowedhapus && uIsEOMExecuted) && (
					<>
					{(is_processing||false)===true ? (
						<CSpinner color="dark" className="d-md-none" size="sm"/>
					) : (
					<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm" className="p-0"
							color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={23}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikJual(id)}>
							<CIcon className="classikonjual me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_setjual)}
						</CDropdownItem>
						
						<CDropdownItem onClick={()=>hdlKlikRusak(id)}>
							<CIcon className="classikonrusak me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_setrusak)}
						</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>
					)}
					</>
					)}
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom" />

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_tglbeli||"caption_tglbeli"}</CCol>
				<CCol xs="8" md="2" lg="1" className="px-1 text-md-center">
					<small>{UFunc.renderHTML(vTglBeli)}</small>
				</CCol>

				<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.caption_jenisaset||"caption_jenisaset"}</CCol>
				<CCol xs="9" className="px-1 text-end d-md-none">
					<small className="fw-bolder">{UFunc.renderHTML(vJeniscaption)}</small>
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_tarifperth||"caption_tarifperth"}</CCol>
				<CCol xs="7" className="px-1 text-end d-md-none">
					<small className="fw-bolder">{UFunc.formatAngka(tarif_per_th)}%</small>
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom" />

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_hperolehan||"caption_hperolehan"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-success">
					<span className="d-md-none">Rp </span>{UFunc.formatAngka(harga_beli)}
				</CCol>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_penyusutan||"word_penyusutan"}</CCol>
				<CCol xs="8" md="2" className="px-1">
					<span className="d-md-none">Rp </span>{UFunc.formatAngka(nilai_susut)}
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom" />

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_nilaisisa||"caption_nilaisisa"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-primary">
					{(tgl_susut_nol||"")!="" && (
						<CBadge className="text-uppercase" color="danger" shape="pill">{uBahasaObj.word_expired||"word_expired"}</CBadge>
					)}
					{(tgl_susut_nol||"")==="" && (
					<>
						<span className="d-md-none">Rp </span>{UFunc.formatAngka((nilai_sisa))}
					</>
					)}
				</CCol>

				<CCol md="1" className="px-0 text-md-center d-none d-md-block">
				{(uIsAllowedhapus && uIsEOMExecuted) && (
					<>
					{(is_processing||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					):(
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_setjual||"caption_setjual")+": "+vCaptionUpper}>
					<CLink className="classikontabel classikonjual classikoncoreui classcursorpointer px-0 mx-0"
						onClick={()=>hdlKlikJual(id)}/>
					</CTooltip>
					<span className="d-md-none"> &middot; </span>
					<CTooltip content={"--"+(uBahasaObj.caption_setrusak||"caption_setrusak")+": "+vCaptionUpper}>
					<CLink className="classikontabel classikonrusak classikoncoreui classcursorpointer px-0 ms-1"
						onClick={()=>hdlKlikRusak(id)} />
					</CTooltip>
					</>
					)}
					</>
				)}
				</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgform=()=>{
		if(!isShowForm) return (<></>)//>

		return (
			<>
			<CForm className="mx-lg-5">
			<CRow className="my-2">
				<CCol md="4" className="">
					{uBahasaObj.caption_namaaset||"caption_namaaset"}
				</CCol>
				<CCol>
					<CFormInput readOnly={true}
						type="text"
						size="sm"
						value={uInpAsetnama}
						className="text-info text-capitalize bg-light"
						id="inpnamaaset"/>
				</CCol>
			</CRow>

			<div className="my-3 d-md-none classborderbottom"/>

			<CRow className="my-2">
				<CCol xs="4" className="">{uBahasaObj.caption_hargabeli||"caption_hargabeli"}</CCol>
				<CCol xs="8" lg="4">
					<CFormInput readOnly={true}
						value={"Rp"+UFunc.formatAngka(uInpHargabeli)}
						size="sm"
						className="text-end bg-light"
						onFocus={(e)=>e.target.select()}
						id="inphargabeli"/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="d-none d-md-block">{uBahasaObj.word_nilai||"word_nilai"}</CCol>
				<CCol xs="4" className="d-md-none">{uBahasaObj.caption_nilaisusut||"caption_nilaisusut"}</CCol>
				<CCol xs="8" md="4">
					<CFormInput readOnly={true}
						value={"Rp"+UFunc.formatAngka(uInpNilaisusut)}
						size="sm"
						className="text-end mb-2 mb-md-0"
						onFocus={(e)=>e.target.select()}
						id="inpnilaisusut"/>
					<div className="classpetunjuk d-none d-md-block">
						<small>{uBahasaObj.caption_nilaisusut||"caption_nilaisusut"}</small>
					</div>
				</CCol>
				<CCol xs="4" className="d-md-none">{uBahasaObj.caption_nilaisisa||"caption_nilaisisa"}</CCol>
				<CCol xs="8" md="4">
					<CFormInput readOnly={true}
						value={"Rp"+UFunc.formatAngka(uInpHargabeli - uInpNilaisusut)}
						size="sm"
						className="text-end"
						onFocus={(e)=>e.target.select()}
						id="inphargabeli"/>
					<div className="classpetunjuk d-none d-md-block">
						<small>{uBahasaObj.caption_nilaisisa||"caption_nilaisisa"}</small>
					</div>
				</CCol>
			</CRow>
			
			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
				<CCol xs="4" className="">{uBahasaObj.word_nonota||"word_nonota"}</CCol>
				<CCol xs="8" lg="4">
					<CFormInput
						maxLength="15"
						size="sm"
						className="form-control text-uppercase"
						value={uInpNonota}
						onChange={(e) => setInpNonota(e.target.value)}
						id="inpnonota" />
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="">{uBahasaObj.caption_hargajual||"caption_hargajual"}</CCol>
				<CCol xs="8" lg="4">
					<NumberFormat 
						value={uInpHargajual}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpHargajual(values.value)}
						renderText={values => (
							<CFormInput 
								value={values}
								size="sm"
								className="text-end"
								onChange={(e)=>setInpHargajual(e.target.value)}
								onFocus={(e)=>e.target.select()}
							id="inphargajual"/>
						)} 
					prefix={isMobile?"":"Rp"}/>
				</CCol>
			</CRow>
			<CRow className="my-2">
				<CCol xs="4" className="d-md-none">{uBahasaObj.caption_akunkas||"word_kas"}</CCol>
				<CCol xs="4" className="d-none d-md-block">{uBahasaObj.caption_kaspenerima||"caption_kaspenerima"}</CCol>
				<CCol xs="8" lg="4">
					<CInputGroup size="sm">
						<CFormInput size="sm"
							value={uInpKascaption}
							onClick={()=>hdlKlikKaslookup()}
							placeholder={"--"+uBahasaObj.word_pilih+": "+(uBahasaObj.caption_kaspenerima)+".."}
							readOnly={true}
							id="inpkascaption"/>
					<CInputGroupText className="p-0 bg-dark">
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikKaslookup()}
							id="btnkaslookup">
						<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
					</CInputGroupText>
					</CInputGroup>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol>
					<CFormInput size="sm"
						maxLength={255}
						className="text-capitalize"
						value={uInpKeterangan}
						onChange={(e) => setInpKeterangan(e.target.value)}
						onKeyDown={(e) =>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpketerangan" />
				</CCol>
			</CRow>
			</CForm>
			</>
		)//>
	}
	//--END CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname = apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1;
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_aset || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_aset/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpArr]);
				setJmlData(parseInt(output_string.totaldata||uJmlData))
				/*if(vKeyword.length >= 3)
				else setJmlData(vTmpArr.length);//-*/

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiSetJual = () => {
		uFuncname = apiSetJual.name;

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_namaaset : uInpAsetnama,
			send_hargajual : uInpHargajual,
			send_nonota : uInpNonota,
			send_kasid : uInpKasID,
			send_keterangan : uInpKeterangan,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_aset/pr_jual";

		setLoading(true);
		uElBtnDlgsimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnDlgsimpan.disabled = false;

			if(output_string.sukses) {
				var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
				hdlToggleForm();

				showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtnDlgsimpan.disabled = true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			uElBtnDlgsimpan.disabled = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSetRusak = (_IDTABEL) => {
		uFuncname 	= apiSetRusak.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vNilaisisa	= uDatamainArr[vObjdx].nilai_sisa;
		const vAsetnama		= uDatamainArr[vObjdx].nama_aset;

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		console.log("("+uComponentname+"-"+uFuncname+") vObjdx : "+vObjdx);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_namaaset : vAsetnama,
			send_nilaisisa : vNilaisisa,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_aset/pr_rusak";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

		return()=>{ 
			hdlToggleForm();hdlToggleDlglookup();
			setDatamainArr([]); setDataDlglookupArr([]); 
		}
	},[]);
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;

		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return; }

		//console.log("("+uComponentname+"[isShowForm]) isShowForm 1: "+isShowForm);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			setBtnDlgsimpan(document.getElementById("btnDialogSimpan"))
			const vElFocus = document.getElementById("inpnonota");
			vElFocus && vElFocus.focus();
		},350);
	},[isShowForm]);
	React.useEffect(()=>{
		if(!uIsDlglookupshow) {
			setDataDlglookupArr([]); return
		}
	},[uIsDlglookupshow]);
	React.useEffect(()=>{
		if(!uElJmldata) return;
		initJmldata()
	},[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>
	
	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_aset || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block">{uBahasaObj.caption_dataaset||"Daftar Aset"}</span>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikSimpanJual()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={contentDlgform()}/>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlglookupheader||"uDlglookupheader"}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>
		</>
	)//>
}
	
export default Aset;
