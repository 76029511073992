import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	//CForm,CFormInput,CInputGroup,CInputGroupText,
	CFormCheck,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CSpinner,
	CLink,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Tembakdata= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uUserSettingObj 		= useSelector(state => state.gListUserSetting);
	const [uEfekview,setEfekview]= React.useState(false);

	const uISAllowedupdate		= uTokenObj.userhak==="FREEROLE";
	const uMaxData				= isMobile ? (uUserSettingObj.jml_mobile || 30) : (uUserSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderactionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderactionObj.isHeaderView || false;
	const uHeaderTembakdata 		= uHeaderactionObj.isHeaderTembakdata || false;
	const uHeaderBroken 		= uHeaderactionObj.isHeaderBroken || false;

	//---PAGINATION_DIATUR_BACKEND--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//---END PAGINATION_DIATUR_BACKEND--/

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDialogHeader,setDialogHeader]	= React.useState("");

	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlChangeStatus = (_EV) => {
		//alert(_EV.target.value); return;
		initSelectrow(_EV.target.value);	
		apiProsesstatus(_EV.target.value);
	}
	const hdlKlikStatus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL);
		initSelectrow(_IDTABEL);	

		apiProsesstatus(_IDTABEL);
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_tembakdata || "";
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} 

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setEfekview(!uEfekview)
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return (<></>)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_tembakdata || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="text-start">{uBahasaObj.word_tanggal||"Tanggal"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.caption_tabelref||"Tabel Reff"}</CCol>
				<CCol md="4" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
				<CCol md="2" className="">{uBahasaObj.word_nilai||"N.Transaksi"} (Rp)</CCol>
				<CCol md="1" className="px-0">Sts Jurnal</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none">
				<th className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{uDatamainArr.map((vItems,vKeys)=>{
			const {
				tgl_data,tgl_input,nama_modul,id_tabel,tabelref,status_jurnal,
				keterangan,nilai_transaksi,id,is_processing
			} = vItems

			vNumber++;
			const vNamamodul = vKeyword==="" ? (nama_modul||"MODULNAME") 
				: (nama_modul||"MODULNAME").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTabelref = vKeyword==="" ? (tabelref||"") 
				: (tabelref||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTgldata = vKeyword==="" ? UFunc.TglAngka(tgl_data) 
				: UFunc.TglAngka(tgl_data).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTglinput = vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: UFunc.WaktuAngka(tgl_input).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vKeterangan = vKeyword==="" ? (keterangan||"-") 
				: (keterangan||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");

			return (
			<tr id={"idtr"+id} className={"text-end align-top"+(uTabelRowSelect===vKeys?" classrowselect":"")} key={vKeys}>
			<td className="px-1">{vNumber}.</td>
			<td className="px-1">
			<CRow className="mx-0">
				<CCol xs="12" className="px-1 text-start d-flex d-md-none justify-content-between">
					<b className="text-uppercase text-info">{UFunc.renderHTML(vNamamodul)} {uISAllowedupdate}</b>
					<div>
					{uISAllowedupdate ? (
					<>
						{(is_processing||false)===true ? (
							<CSpinner color="primary" size="sm"/>
						) : (
						<CLink onClick={()=>hdlKlikStatus(id)}>
						{(status_jurnal||"BELUM")==="BELUM" ? (
							<CImage src={pjson.homepage+"api/images/icon_crossed.png"} height={25}/>
						) : (
							<CImage src={pjson.homepage+"api/images/icon_checked.png"} height={25}/>
						)}
						</CLink>
						)}
					</>
					) : (
					<>
					{(status_jurnal||"BELUM")==="BELUM" ? (
						<CImage src={pjson.homepage+"api/images/icon_crossed.png"} height={25}/>
					) : (
						<CImage src={pjson.homepage+"api/images/icon_checked.png"} height={25}/>
					)}
					</>
					)}
					</div>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="4" className="px-1 text-start d-md-none">{UFunc.word_tanggal||"Tanggal"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-start">
					<div>{UFunc.renderHTML(vTgldata)}</div>

					<div className="classfontsmaller text-danger d-none d-md-block">
					<small>[{UFunc.renderHTML(vTglinput)}]</small>
					</div>
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{UFunc.caption_tglinput||"Tgl Input"}</CCol>
				<CCol xs="8" className="px-1 d-md-none">
					<div className="classfontsmaller text-danger">
					<small>[{UFunc.renderHTML(vTglinput)}]</small>
					</div>
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{UFunc.caption_tabelref||"Tabel Reff"}</CCol>
				<CCol xs="8" md="3" className="px-1 text-md-start">
					<small>({id_tabel})</small>
					<span className="mx-1">&middot;</span>
					<b>{UFunc.renderHTML(vTabelref)}</b>
				</CCol>

				<CCol xs="3" className="px-1 text-start d-md-none">{UFunc.word_keterangan||"Keterangan"}</CCol>
				<CCol xs="9" md="4" className="px-1 text-md-start">
					<div className="fw-bolder text-info d-none d-md-block">{UFunc.renderHTML(vNamamodul)}</div>
					<div className="fst-italic">{UFunc.renderHTML(vKeterangan)}</div>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="6" className="px-1 text-start d-md-none">{UFunc.caption_nilaitransaksi||"Nilai Transaksi"} (Rp)</CCol>
				<CCol xs="6" md="2" className="px-1 text-success">{UFunc.formatAngka(nilai_transaksi)}</CCol>

				<CCol md="1" className="px-1 text-md-center d-none d-md-block">
					{uISAllowedupdate ? (
					<>
						{(is_processing||false)===true ? (
							<CSpinner color="primary" size="sm"/>
						) : (
						<>
						<CFormCheck 
							checked={(status_jurnal||"BELUM")==="SUDAH"}
							label=""
							value={id}
							onChange={(e)=>hdlChangeStatus(e)} 
							id={"inpchkstatus"+id}/>
						</>
						)}
					</>
					) : (
					<>
					{(status_jurnal||"BELUM")==="BELUM" ? (
						<CImage src={pjson.homepage+"api/images/icon_crossed.png"} height={23}/>
					) : (
						<CImage src={pjson.homepage+"api/images/icon_checked.png"} height={23}/>
					)}
					</>
					)}
				</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = (_PAGE) => {
		setHTMLError500();
		_PAGE		= parseInt(_PAGE)||1;

		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_tembakdata || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout); setLoading(false);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_tembakdata/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Tembakdata-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setFirstload("TIDAK");
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				vLoadArr.map(vItems=>vItems.pg = _PAGE);
				//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.dataobject));
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Tembakdata-apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Tembakdata-apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesstatus = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL); if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		//console.log("(Tembakdata - apiProsesstatus) uDatamainArr "+JSON.stringify(uDatamainArr));
		uDatamainArr[vArridx].is_processing = true;
		setEfekview(!uEfekview); 

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout); 
			uDatamainArr[vArridx].is_processing = false;
			uDatamainArr[vArridx].status_jurnal = (uDatamainArr[vArridx].status_jurnal||"BELUM")==="SUDAH"
				? "BELUM" : "SUDAH";
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_tembakdata/pr_status";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			uDatamainArr[vArridx].is_processing = false;
			setSessionaktif(true)
			//console.log("(Tembakdata - apiProsesstatus) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr[vArridx].status_jurnal = (uDatamainArr[vArridx].status_jurnal||"BELUM")==="SUDAH"
					? "BELUM" : "SUDAH";
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Tembakdata - apiProsesstatus) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			console.log("(Tembakdata - apiProsesstatus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) apiLoaddata();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]); setJmlData(0); setJmlHal(1); setFirstload("YA");
		}
	},[])
	//--PAGINATION_DIATUR_BACKEND--/
	React.useEffect(()=>{
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	//--END PAGINATION_DIATUR_BACKEND--/

	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]); 
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderactionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	
	//console.log("Tembakdata - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )

	return (
	<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_tembakdata || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>
	</>
	)
}	

export default Tembakdata