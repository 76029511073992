import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CInputGroup,CFormSelect,CFormInput,CButton,
	//CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	//MyDialoglookupapi,
	MyDialogform,
	MyDialogview,
	MyPagination,
	MyPage500,
} from '../components/index'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { UFunc,cBulanPanjang,cBulanPendek } from '../helpers/functions'
//import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Lapmutasistok 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	//const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uSettinguserObj		= useSelector(state => state.gListUserSetting);
	const uSettingsystemObj		= useSelector(state => state.gSystemsetting);
	const uHTMLstyleTabel		= useSelector(state => state.gHTMLstyleLaporan);
	const [uEfekview,setEfekview]= React.useState(false);

	const uMaxData				= isMobile ? (uSettinguserObj.jml_mobile || 20) : (uSettinguserObj.jml_laporan || 100);

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelReset	 		= uHeaderActionObj.isHeaderReset || false;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatavieworiArr,setDatavieworiArr]= React.useState([]);
	const [uDataviewArr,setDataviewArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDataloadedObj,setDataloadedObj]	= React.useState();
	const [uTitelObj,setTitelObj]			= React.useState({});
	const [uDataidentitasObj,setDataidentitasObj]	= React.useState({});
	const [uDatajenisstokArr,setDatajenisstokArr]	= React.useState([]);
	const [uSortedObj,setSortedObj]			= React.useState({});

	//---DLGFORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//---END DLGFORM--/

	/*//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]	= React.useState({});
	//--END DLG_LOOKUP--*/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewObj,setDlgviewObj]		= React.useState({});
	//--END DLG_VIEW--/

	//--DOM_ELEMENTS--/
	const uElJmldata	= document.getElementById("idjmldata");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_ELEMENTS--/

	//---HANDLE--/
	const hdlKlikDlgformsimpan = () => {
		let vElfocus	= document.getElementById("inpjenis");
		if(uDlgformObj.jenis_stok!=="BELI" && uDlgformObj.jenis_stok!=="KONSI") {
			vElfocus && vElfocus.focus();
			showToast("Jenis Stok Tidak Valid !"); return
		} 

		vElfocus	= document.getElementById("inpbulanid");
		if((parseInt(uDlgformObj.bln_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inptahun");
		if((parseInt(uDlgformObj.tahun)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Tahun Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inpbulanid");
		const vTanggalpilihDT = UFunc.toDate(uDlgformObj.tahun+"-"+UFunc.leadZero(uDlgformObj.bln_id)+"-01");
		if(UFunc.DateIsBeyond(vTanggalpilihDT)) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan/Tahun Tidak Valid !"); return
		}

		apiLoadview();
	}
	const hdlKlikDetilitem = (_IDTABEL) => {//--TIDAK_DIPAKAI--/
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vArridx	= UFunc.getObjectIndeks(uDataviewArr,_IDTABEL);
		if((uDataviewArr[vArridx].item_arr||[]).length > 0) {
			uDlgviewObj.header = "Detil Item: "+uDataviewArr[vArridx].no_nota;
			setDlgviewshow(true);
			return;
		}

		apiLoaditem(_IDTABEL);
	}
	const hdlKlikCetak = () => {
		if(uJmlData <= 0) return;

		let vContent 	= contentHTMLcetak();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;

		showToast("Maaf, Fitur Belum Tersedia !","ERROR"); return;
		contentExcel();
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		uElJmldata && 
			(uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		if(uJmlData <= 0) return (
		<div className="text-center my-2 my-lg-5">
			<big className="fst-italic text-primary">
			{uBahasaObj.pesan_dataempty||"Data dengan Filter yang Dipilih Tidak Ditemukan.."}
			</big>
		</div>
		)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;
		let vJenisbefore	= "";
		const vColspan		= 10;
		const vDataviewArr	= uDataviewArr.slice(vMin,(vMax));

		const vStylekolfixed	= {
			position: "sticky",
			left: 0,
			backgroundColor:"#f0f0f0",
			zIndex: 1,
		}


		return (
		<div style={{overflowX: "auto"}}>
			<table style={{minWidth:1200,width:"100%"}} 
				className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={50} className="border" style={vStylekolfixed}>No</th>
			<th width={300} className="text-start border" style={{...vStylekolfixed,left:50}}>{uBahasaObj.caption_namastok||"Nama Stok Barang"}</th>
			
			<th width={70} className="border">
				{uBahasaObj.word_sblm||"Sblm"}

				<CLink onClick={()=>{
						if((uSortedObj.field||"")!=="q_awal") 
							setSortedObj({ field: "q_awal",mode:"ASC" })
						else {
							if(uSortedObj.mode===undefined) uSortedObj.mode = "ASC";
							else if(uSortedObj.mode==="ASC") uSortedObj.mode = "DESC";
							else setSortedObj({});
						}
						setEfekview(!uEfekview);
					}}
					className={"ms-1 classcursorpointer "+((uSortedObj.field||"")==="q_awal"?"text-danger":"text-primary")}>
					<CIcon icon={(((uSortedObj.mode||"")==="DESC" && (uSortedObj.field||"")==="q_awal") ?"cilArrowBottom":"cilArrowTop")} className="align-middle" height={20}/>
				</CLink>
			</th>
			<th width={70} className=" border">{uBahasaObj.word_msk||"Msk"}</th>
			<th width={70} className=" border">{uBahasaObj.word_klr||"Klr"}</th>
			<th width={70} className=" border">
				{uBahasaObj.word_sisa||"Sisa"}

				<CLink onClick={()=>{
						if((uSortedObj.field||"")!=="q_sisa") 
							setSortedObj({ field: "q_sisa",mode:"ASC" })
						else {
							if(uSortedObj.mode===undefined) uSortedObj.mode = "ASC";
							else if(uSortedObj.mode==="ASC") uSortedObj.mode = "DESC";
							else setSortedObj({});
						}
						setEfekview(!uEfekview);
					}}
					className={"ms-1 classcursorpointer "+((uSortedObj.field||"")==="q_sisa"?"text-danger":"text-primary")}>
					<CIcon icon={(((uSortedObj.mode||"")==="DESC" && (uSortedObj.field||"")==="q_sisa") ?"cilArrowBottom":"cilArrowTop")} className="align-middle" height={20}/>
				</CLink>
			</th>

			<th width={150} className=" border">
				{uBahasaObj.caption_nsblm||"N.Sblm"} (Rp)

				<CLink onClick={()=>{
						if((uSortedObj.field||"")!=="n_awal") 
							setSortedObj({ field: "n_awal",mode:"ASC" })
						else {
							if(uSortedObj.mode===undefined) uSortedObj.mode = "ASC";
							else if(uSortedObj.mode==="ASC") uSortedObj.mode = "DESC";
							else setSortedObj({});
						}
						setEfekview(!uEfekview);
					}}
					className={"ms-1 classcursorpointer "+((uSortedObj.field||"")==="n_awal"?"text-danger":"text-primary")}>
					<CIcon icon={(((uSortedObj.mode||"")==="DESC" && (uSortedObj.field||"")==="n_awal") ?"cilArrowBottom":"cilArrowTop")} className="align-middle" height={20}/>
				</CLink>
			</th>

			<th width={120} className=" border">{uBahasaObj.caption_nmasuk||"N.Masuk"} (Rp)</th>
			<th width={120} className=" border">{uBahasaObj.caption_nkeluar||"N.Keluar"} (Rp)</th>
			
			<th width={150} className=" border">
				{uBahasaObj.caption_nsisa||"N.Sisa"} (Rp)

				<CLink onClick={()=>{
						if((uSortedObj.field||"")!=="n_sisa") 
							setSortedObj({ field: "n_sisa",mode:"ASC" })
						else {
							if(uSortedObj.mode===undefined) uSortedObj.mode = "ASC";
							else if(uSortedObj.mode==="ASC") uSortedObj.mode = "DESC";
							else setSortedObj({});
						}
						setEfekview(!uEfekview);
					}}
					className={"ms-1 classcursorpointer "+((uSortedObj.field||"")==="n_sisa"?"text-danger":"text-primary")}>
					<CIcon icon={(((uSortedObj.mode||"")==="DESC" && (uSortedObj.field||"")==="n_sisa")?"cilArrowBottom":"cilArrowTop")} className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={vColspan}>{JSON.stringify(uDataloadedObj)}</th></tr>
			</thead>

			<tbody>
			{vDataviewArr.map((vItems,vKeys) => {
				const {
					id,nama_barang,jenis_barang,q_awal,q_masuk,q_keluar,q_sisa,
					n_awal,n_masuk,n_keluar,n_sisa,id_jenis,
				} = vItems;

				vNumber++;
				const vJenisidrunning 	= parseInt(id_jenis) || 0;
				const vJenisrunning 	= (jenis_barang||"").toUpperCase();
				const vISchangejenis	= vJenisrunning !== vJenisbefore;

				const vHTMLRowjenis		= () => {
					if(!vISchangejenis) return "";
					if(uDataloadedObj.jenis_stok !== "BELI") return "";

					return (
					<tr>
					<td colSpan={2} style={vStylekolfixed} className="text-white bg-secondary text-center fw-bolder text-uppercase">{vJenisrunning}</td>
					<td colSpan={vColspan-2} className="bg-secondary"/>
					</tr>
					)//>
				} 
				const vHTMLRowsubtotal		= () => {
					if(uDataloadedObj.jenis_stok !== "BELI") return "";

					const vJenisidnext		= parseInt((vDataviewArr[vKeys+1]||{}).id_jenis) || 0

					const vTmpsubtotalObj	= (uDataloadedObj.sum_jenis_arr || []).
						filter(vItems=>parseInt(vItems.id)===vJenisidrunning)[0] || {};

					if(vJenisidrunning !== vJenisidnext) return (
					<tr className="bg-info text-white fw-bolder">
					<td colSpan={2} style={vStylekolfixed} className="text-white bg-info text-center fw-bolder text-uppercase">Sub Total {vJenisrunning}</td>
					<td className="text-center"/>
					<td className="text-center"/>
					<td className="text-center"/>
					<td className="text-center"/>
					<td className="text-end">{UFunc.formatAngka(vTmpsubtotalObj.sum_subawal)}</td>
					<td className="text-end">{UFunc.formatAngka(vTmpsubtotalObj.sum_submasuk)}</td>
					<td className="text-end">{UFunc.formatAngka(vTmpsubtotalObj.sum_subkeluar)}</td>
					<td className="text-end">{UFunc.formatAngka(vTmpsubtotalObj.sum_subsisa)}</td>
					</tr>
					)//>
				} 

				vJenisbefore			= (jenis_barang||"").toUpperCase();

				return (
				<>
				{vHTMLRowjenis()}
				<tr id={"idtr"+id} className={"align-top"+(uTabelRowSelect===vKeys?" classrowselect":"")} key={vKeys}>
				<td className="px-1 text-end" style={vStylekolfixed}>{vNumber}.</td>

				<td className="px-1 text-start" style={{...vStylekolfixed,left:50}}>
					<small>{(nama_barang||"Undf Nama")}</small>
				</td>
				
				<td className="text-center px-1 fw-bolder">
					{(parseFloat(q_awal)||0) >= 0 
						? (<div className="">{UFunc.formatAngka(q_awal)}</div>)
						: (<div className="text-danger">
							-({UFunc.formatAngka(Math.abs(q_awal||0))})
						</div>)
					}
				</td>
				<td className="text-center px-1">{UFunc.formatAngka(q_masuk)}</td>
				<td className="text-center px-1">{UFunc.formatAngka(q_keluar)}</td>
				<td className="text-center px-1 text-success">
					{(parseFloat(q_sisa)||0) >= 0 
						? (<div className="">{UFunc.formatAngka(q_sisa)}</div>)
						: (<div className="text-danger">
							-({UFunc.formatAngka(Math.abs(q_sisa||0))})
						</div>)
					}
				</td>
				
				<td className="text-end px-1 fw-bolder">
					{(parseFloat(n_awal)||0) >= 0 
						? (<div className="">{UFunc.formatAngka(n_awal)}</div>)
						: (<div className="text-danger">
							-({UFunc.formatAngka(Math.abs(n_awal||0))})
						</div>)
					}
				</td>
				<td className="text-end px-1">{UFunc.formatAngka(n_masuk)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(n_keluar)}</td>
				<td className="text-end px-1 text-success">
					{(parseFloat(n_sisa)||0) >= 0 
						? (<div className="">{UFunc.formatAngka(n_sisa)}</div>)
						: (<div className="text-danger">
							-({UFunc.formatAngka(Math.abs(n_sisa||0))})
						</div>)
					}
				</td>
				</tr>
				{vHTMLRowsubtotal()}
				</>
				)
			})}
			</tbody>

			<tfoot className="border-top border-top-primary">
			<tr className="fw-bolder text-white bg-dark">
			<td colSpan={2} className="text-center bg-dark" style={vStylekolfixed}>JUMLAH</td>
			<td className="text-center"/>
			<td className="text-center"/>
			<td className="text-center"/>
			<td className="text-center"/>
			<td className="text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_awal)}</td>
			<td className="text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_masuk)}</td>
			<td className="text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_keluar)}</td>
			<td className="text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_sisa)}</td>
			</tr>
			</tfoot>
			</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSettingsystemObj.jml_tahun_laporan)||5;

		return (
		<CForm>
		<CRow className="my-2">
		<CCol xs="3" md="4">Jenis Stok</CCol>
		<CCol>
			<CFormSelect size="sm"
				value={uDlgformObj.jenis_stok}
				onChange={(e)=>{uDlgformObj.jenis_stok=e.target.value; setEfekview(!uEfekview)}}
				id="inpjenis">
				<option value="BELI">PERSEDIAAN</option>
				<option value="KONSI">PERSEDIAAN KONSINYASI</option>
			})}
			</CFormSelect>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4">Periode</CCol>
		<CCol>
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.bln_id}
				onChange={(e)=>{uDlgformObj.bln_id=e.target.value; setEfekview(!uEfekview)}}
				id="inpbulanid">
			{cBulanPanjang.map((vItems,vKeys)=>{
				return (
				<option value={vKeys+1} key={vKeys}>{vItems.toUpperCase()}</option>
				)
			})}
			</CFormSelect>
			
			<span className="me-1"/>

			<CFormSelect size="sm"
				value={uDlgformObj.tahun}
				onChange={(e)=>{uDlgformObj.tahun=e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inptahun">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentDlgview = () => {//--TIDAK_DIPAKAI--/
		if(!uISDlgviewshow) return (<></>)//>

		const vArridx	= UFunc.getObjectIndeks(uDataviewArr,uTabelIDPilih);
		if(vArridx < 0) return (<></>)//>

		return (
		<small>
		<CRow className="align-items-stretch">
		<CCol lg="6">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Data Nota</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<div>Tgl Transaksi</div>
					<b>{UFunc.TglAngka(uDataviewArr[vArridx].tgl_pendapatan)}</b>
				</div>

				<div className="d-flex justify-content-between">
					<div>No.Nota</div>
					<div>{(uDataviewArr[vArridx].no_nota)}</div>
				</div>

				<div className="d-flex justify-content-between">
					<div>Customer</div>
					<b>{(uDataviewArr[vArridx].customer_caption||"-")}</b>
				</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol lg="6" className="mt-3 mt-lg-0">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Summary Transaksi</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<div>Jumlah Bruto (Rp)</div>
					<b>{UFunc.formatAngka(uDataviewArr[vArridx].nilai_bruto)}</b>
				</div>
				<div className="d-flex justify-content-between">
					<div>Diskon (Rp)</div>
					<b className="text-danger">{UFunc.formatAngka(uDataviewArr[vArridx].diskon)}</b>
				</div>
				<div className="d-flex justify-content-between">
					<div>Total (Rp)</div>
					<b className="text-success">{UFunc.formatAngka(uDataviewArr[vArridx].nilai_netto)}</b>
				</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CRow className="mt-3">
		<CCol>
			<CCard>
			<CCardHeader className="py-0 pt-1 classfontsmaller">Data Item</CCardHeader>
			<CCardBody className="px-0">
			<table width="100%" className="table table-borderless table-striped table-hover" 
				id="idtabelitem">
			<thead className="align-top classfontsmaller text-center">
			<tr>
				<th width="5%">No</th>
				<th className="text-start">Item Transaksi</th>
				<th width="25%">Nilai (Rp)</th>
			</tr>
			</thead>
			
			<tbody>
			{(uDataviewArr[vArridx].item_arr||[]).map((vItems,vKeys)=>{
				const {
					harga_satuan,qty,item_satuan,item_caption,
					pasien_caption,item_idx
				} = vItems;

				return (
				<tr className="align-top" key={vKeys}>
				<td align="right">{vKeys+1}.</td>

				<td>
					<div>
						{(pasien_caption||"")!==""
							? (<><span className="text-primary">{pasien_caption}</span> &middot; </>)
							: ("")
						}
						<small>{item_caption}</small>
					</div>

					<small className="fst-italic d-block ms-2">
						&#8702; <b>{qty}</b> {item_satuan} x Rp<b>{UFunc.formatAngka(harga_satuan)}</b>
					</small>
				</td>

				<td align="right">
					<b className="text-success">{UFunc.formatAngka(parseInt(qty||"1") * parseFloat(harga_satuan))}</b>
				</td>
				</tr>
				)
			})}
			</tbody>
			</table>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</small>
		)//>
	}
	const contentHTMLcetak = () => {
		let vHTMLs	= uHTMLstyleTabel;

		//console.log("(Lapmutasistok - contentHTMLcetak) uDataidentitasObj => "+(uDataidentitasObj));

		const vColspan 		= 10;
		const vDataviewArr	= uDataviewArr;

		vHTMLs	+= UFunc.RenderTabel(uDataidentitasObj,vColspan);
		
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()];
		const vJudulsub		= `
			<div>Periode <b>`+(uTitelObj.periode||"")+`</b></div>
			<div>Jenis Stok <b>`+(uTitelObj.subtitel||"")+`</b></div>
		`;

		const vTabelheader	= `
			<th width="7%">No</th>
			<th align="left">Nama Barang</th>
			<th width="7%">Awal</th>
			<th width="7%">Msk</th>
			<th width="7%">Klr</th>
			<th width="7%">Sisa</th>
			<th width="11%">N.Awal (Rp)</th>
			<th width="11%">N.Msk (Rp)</th>
			<th width="11%">N.Klr (Rp)</th>
			<th width="11%">N.Sisa (Rp)</th>
			</tr>
		`;
		
		let vTabelbody	= "";
		let vNumber		= 0;
		let vJenisidbefore	= 0;
		vTabelbody 		+= vDataviewArr.map((vItems,vKeys) => {
			const {
				id,nama_barang,id_jenis,jenis_barang,q_awal,q_masuk,q_keluar,q_sisa,
				n_awal,n_masuk,n_keluar,n_sisa,
			} = vItems;

			vNumber++;

			const vISchangejenis 	= vJenisidbefore !== parseInt(id_jenis) && 
				uDataloadedObj.jenis_stok==="BELI";
			const vJenisidnext		= parseInt((vDataviewArr[vKeys+1]||{}).id_jenis) || 0
			vJenisidbefore 			= parseInt(id_jenis);

			const vTmpsubtotalObj 	= (uDataloadedObj.sum_jenis_arr||[]).
				filter(vItems=>parseInt(vItems.id) === parseInt(id_jenis))[0] || {}

			return (
				(vISchangejenis 
					? `
					<tr valign="top" style="font-size:smaller;">
					<td align="right" style="border-top:1px solid black;border-bottom:1px solid black;">&nbsp;</td>
					<td colspan="`+(vColspan-1)+`" align="left" style="border-top:1px solid black;border-bottom:1px solid black;">
					<b>`+(jenis_barang||"JENIS").toUpperCase()+`</b>
					</td>
					</tr>
					` 
					: ``)+
				`
				<tr valign="top" key=`+vKeys+` style="font-size:smaller;">
				<td align="right">`+vNumber+`.</td>
				<td align="left">`+(nama_barang||"-")+`</td>
				<td align="right"><b>`+UFunc.formatAngka(q_awal)+`</b></td>
				<td align="right">`+UFunc.formatAngka(q_masuk)+`</td>
				<td align="right">`+UFunc.formatAngka(q_keluar)+`</td>
				<td align="right"><b>`+UFunc.formatAngka(q_sisa)+`</b></td>
				<td align="right"><b>`+UFunc.formatAngka(n_awal)+`</b></td>
				<td align="right">`+UFunc.formatAngka(n_masuk)+`</td>
				<td align="right">`+UFunc.formatAngka(n_keluar)+`</td>
				<td align="right"><b>`+UFunc.formatAngka(n_sisa)+`</b></td>
				</tr>
				`+
				(vJenisidnext !== parseInt(id_jenis) && uDataloadedObj.jenis_stok==="BELI"
					? `  
					<tr valign="top"style="font-size:smaller;`+(vKeys < vDataviewArr.length - 1 ? "page-break-after:always;" : "")+`">
					<td align="center" colspan="2" style="border-top:1px solid black;">Sub Total `+(jenis_barang||"").toUpperCase()+`</td>
					<td align="right" colspan="4" style="border-top:1px solid black;">&nbsp;</td>
					<td align="right" style="border-top:1px solid black;"><b>`+UFunc.formatAngka(vTmpsubtotalObj.sum_subawal)+`</b></td>
					<td align="right" style="border-top:1px solid black;">`+UFunc.formatAngka(vTmpsubtotalObj.sum_submasuk)+`</td>
					<td align="right" style="border-top:1px solid black;">`+UFunc.formatAngka(vTmpsubtotalObj.sum_subkeluar)+`</td>
					<td align="right" style="border-top:1px solid black;"><b>`+UFunc.formatAngka(vTmpsubtotalObj.sum_subsisa)+`</b></td>
					</tr>
					`
					: ``)+
			``);
		}).join("");
		
		vTabelbody 	+= `
			<tr valign="top" style="font-weight:bolder;">
			<td colspan="2" style="border-top:3px double black;border-bottom:1px solid black;">JUMLAH</td>
			<td align="right" colspan='4' style="border-top:3px double black;border-bottom:1px solid black;">&nbsp;</td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;">`+UFunc.formatAngka(uDataloadedObj.sum_nilai_awal)+`</td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;">`+UFunc.formatAngka(uDataloadedObj.sum_nilai_masuk)+`</td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;">`+UFunc.formatAngka(uDataloadedObj.sum_nilai_keluar)+`</td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;">`+UFunc.formatAngka(uDataloadedObj.sum_nilai_sisa)+`</td>
			</tr>
		`;

		vHTMLs 		= vHTMLs.replace(/_TITELSUB_/g,vJudulsub);
		vHTMLs 		= vHTMLs.replace(/_JUDULLAPORAN_/g,vJudullaporan);
		vHTMLs 		= vHTMLs.replace(/_TABELHEADER_/g,vTabelheader);
		vHTMLs 		= vHTMLs.replace(/_TABELBODY_/g,vTabelbody);
		vHTMLs 		= vHTMLs.replace(/_TABELFOOTER_/g,"");

		return vHTMLs;
	}
	const contentExcel = () => {
		const vBulanID	= parseInt((uDataloadedObj||{}).bln_id)||"0";
		const vTahun	= parseInt((uDataloadedObj||{}).tahun)||"0";

		const vColspan 	= (uDataloadedObj.jenis==="BULANAN") ? 8 : 7;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 10;

		let vTmpObj	= (uDataloadedObj.jenis==="BULANAN") ? ({
			no : "",
			tgl_pendapatan: "",
			customer_caption: "",
			payment_caption: "",
			nilai_netto: "",
			nilai_dp: "",
			nilai_bayar: "",
			nilai_sisa: "",
		}) : ({
			no : "",
			customer_caption: "",
			payment_caption: "",
			nilai_netto: "",
			nilai_dp: "",
			nilai_bayar: "",
			nilai_sisa: "",
		})
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDataviewArr.forEach((vItems,vKeys)=>{
			const {
				id,tgl_pendapatan,no_nota,customer_caption,payment_caption,
				nilai_netto,nilai_dp,nilai_sisa,nilai_bayar,
			} = vItems;

			vColposwidth = (customer_caption||"").length > vColposwidth 
				? (customer_caption||"").length : vColposwidth; 

			vTmpObj	= (uDataloadedObj.jenis==="BULANAN") ? ({
				no : {v:vKeys+1,t:"n"},
				tgl_pendapatan: UFunc.TglAngka(tgl_pendapatan),
				customer_caption: (customer_caption||no_nota),
				payment_caption: (payment_caption||"-"),
				nilai_netto: { v:parseFloat(nilai_netto || "0"), t:"n", s:{numFmt: "#,##0"} },
				nilai_dp: { v:parseFloat(nilai_dp || "0"), t:"n", s:{numFmt: "#,##0"} },
				nilai_bayar: { v:parseFloat(nilai_bayar || "0"), t:"n", s:{numFmt: "#,##0"} },
				nilai_sisa: { v:parseFloat(nilai_sisa || "0"), t:"n", s:{numFmt: "#,##0"} },
			}) : ({
				no : {v:vKeys+1,t:"n"},
				customer_caption: (customer_caption||no_nota),
				payment_caption: (payment_caption||"-"),
				nilai_netto: { v:parseFloat(nilai_netto), t:"n", s:{numFmt: "#,##0"} },
				nilai_dp: { v:parseFloat(nilai_dp || "0"), t:"n", s:{numFmt: "#,##0"} },
				nilai_bayar: { v:parseFloat(nilai_bayar || "0"), t:"n", s:{numFmt: "#,##0"} },
				nilai_sisa: { v:parseFloat(nilai_sisa || "0"), t:"n", s:{numFmt: "#,##0"} },
			}) 

			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			tgl_pendapatan: "",
			customer_caption: "",
			payment_caption: "",
			nilai_netto: { v:parseFloat(uDataloadedObj.sum_nilai_netto)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_dp: { v:parseFloat(uDataloadedObj.sum_nilai_dp)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_bayar: { v:parseFloat(uDataloadedObj.sum_nilai_bayar)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_sisa: { v:parseFloat(uDataloadedObj.sum_nilai_sisa)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		if(uDataloadedObj.jenis!=="BULANAN") delete vTmpObj.tgl_pendapatan;

		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:47}]
		const wscols	= [
			{wpx:40},
			{wpx:75},
			{wch:vColposwidth},
			{wpx:150},
			{wpx:85},
			{wpx:85},
			{wpx:85},
			{wpx:85},
		];
		if(uDataloadedObj.jenis!=="BULANAN") wscols.splice(1,1);
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 5}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_Lapmutasistok_"+
			(uDataloadedObj.jenis==="BULANAN"
				? (cBulanPendek[vBulanID-1]).toUpperCase()+"_"+vTahun+
				""
				: (uDataloadedObj.tanggal||"").replace("-","")+
				"")+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+(uDataloadedObj.jenis!=="BULANAN" ? "Bulan ": "Tanggal ")+uTitelObj.periode+
			"\r\n"+uTitelObj.subtitel+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tanggal",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Customer",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Metode Pembayaran",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Tagihan (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.DP (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Bayar (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Saldo (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];
		if(uDataloadedObj.jenis!=="BULANAN") vHeaderArr.splice(1,1);

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//---END CONTENT--/

	//---API--/
	const apiLoadinit = () => {
		setHTMLError500();
		setDataloadedObj();
		setSortedObj({});
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapmutasistok/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Lapmutasistok-apiLoadinit) output_string.databiaya : "+(output_string.databiaya));
				setDataidentitasObj(JSON.parse(output_string.dataidentitas || "{}"));
				setDatajenisstokArr(JSON.parse(output_string.datajenis || "[]"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapmutasistok-apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapmutasistok-apiLoadinit) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadview = () => {
		const vElsimpan	= document.getElementById("btnDialogSimpan");
		setDataloadedObj();
		setDatavieworiArr([]);
		setSortedObj({});
		setPageActive(1);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);

		vElsimpan && (vElsimpan.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parjson: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapmutasistok/ld_view";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			//console.log("(Lapmutasistok-apiLoadview) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = (JSON.parse(output_string.datalaporan || "[]"));
				setDatavieworiArr([{firstrow:1},...vTmpArr]);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);

				setDataloadedObj(uDlgformObj);
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapmutasistok-apiLoadview) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Lapmutasistok - apiLoadview) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaditem = (_IDTABEL) => {//--TIDAK_DIPAKAI--/
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDataviewArr,_IDTABEL);
		uDataviewArr[vArridx].is_processing = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid: _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapomzet/ld_item";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			uDataviewArr[vArridx].is_processing = false;
			//console.log("(Lapmutasistok-apiLoaditem) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vDetildataArr 			= JSON.parse(output_string.dataitem || "[]");
				uDataviewArr[vArridx].item_arr 	= vDetildataArr;

				uDlgviewObj.header = "Detil Item: "+uDataviewArr[vArridx].no_nota;
				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapmutasistok-apiLoaditem) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDataviewArr[vArridx].is_processing = false;
			console.log("(Lapmutasistok - apiLoaditem) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadinit();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDlgformshow(false);

			setTitelObj();
			setDataloadedObj();
			setDataviewArr([]);
			setDatavieworiArr([]);
			setSortedObj({});
			setDataidentitasObj({});
			setJmlData(0);setJmlHal(0);
			setDatajenisstokArr([]);

			/*//--TIDAK_DIPAKAI--/
			setDatapaymentArr([]);
			setDlgviewshow(false);
			//--END TIDAK_DIPAKAI--*/
		}
	},[])
	React.useEffect(()=>{ 
		setTabelIDPilih(0);
		setTabelRowSelect(-1); 
	},[uPageActive]);
	React.useEffect(()=>{
		const vDatadefaultArr = uDatavieworiArr.slice(1);
		setDataviewArr(vDatadefaultArr);
	},[uDatavieworiArr,uDatavieworiArr.length])
	React.useEffect(()=>{
		setJmlData(uDataviewArr.length);

		const vSumnilaiawal = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.n_awal)||0)
		}, 0);
		const vSumnilaimasuk = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.n_masuk)||0)
		}, 0);
		const vSumnilaikeluar = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.n_keluar)||0)
		}, 0);
		const vSumnilaisisa = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.n_sisa)||0)
		}, 0);

		const vTmpsubtotalArr = uDatajenisstokArr.map(vItems => {
			return {
				id:vItems.id,
				sum_subawal: uDataviewArr.
					filter(vItemview => parseInt(vItemview.id_jenis)===parseInt(vItems.id)).
					reduce((vNilaistored,vItemview)=>{
						return (parseFloat(vNilaistored)||0) + (parseFloat(vItemview.n_awal)||0)
					}, 0),
				sum_submasuk: uDataviewArr.
					filter(vItemview => parseInt(vItemview.id_jenis)===parseInt(vItems.id)).
					reduce((vNilaistored,vItemview)=>{
						return (parseFloat(vNilaistored)||0) + (parseFloat(vItemview.n_masuk)||0)
					}, 0),
				sum_subkeluar: uDataviewArr.
					filter(vItemview => parseInt(vItemview.id_jenis)===parseInt(vItems.id)).
					reduce((vNilaistored,vItemview)=>{
						return (parseFloat(vNilaistored)||0) + (parseFloat(vItemview.n_keluar)||0)
					}, 0),
				sum_subsisa: uDataviewArr.
					filter(vItemview => parseInt(vItemview.id_jenis)===parseInt(vItems.id)).
					reduce((vNilaistored,vItemview)=>{
						return (parseFloat(vNilaistored)||0) + (parseFloat(vItemview.n_sisa)||0)
					}, 0),
			}
		})

		uDataloadedObj && (setDataloadedObj({...uDataloadedObj,
			sum_jenis_arr: vTmpsubtotalArr,
			sum_nilai_awal: vSumnilaiawal,
			sum_nilai_masuk: vSumnilaimasuk,
			sum_nilai_keluar: vSumnilaikeluar,
			sum_nilai_sisa: vSumnilaisisa,
		}));
	},[uDataviewArr,uDataviewArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;

		const vTanggalnowDT	= new Date();
		if(uDlgformObj.bln_id === undefined)
			uDlgformObj.bln_id = uDataloadedObj ? uDataloadedObj.bln_id : vTanggalnowDT.getMonth()+1;
		if(uDlgformObj.tahun === undefined)
			uDlgformObj.tahun = uDataloadedObj ? uDataloadedObj.tahun : vTanggalnowDT.getFullYear();
		if(uDlgformObj.jenis_stok === undefined)
			uDlgformObj.jenis_stok = uDataloadedObj ? uDataloadedObj.jenis_stok : "BELI";
		
		uDlgformObj.header = "Pilih Filter Laporan";
		setDlgformshow(true);
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderReset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uDataloadedObj) apiLoadinit()
		else { setDatavieworiArr([]);setDataviewArr([]); setDataloadedObj(); }
	},[uHandelReset]);
	React.useEffect(()=>{
		const vElfilter	= document.getElementById("btnFilter");
		if(!uISDlgformshow) { 
			vElfilter && (vElfilter.disabled = false)
			setDlgformObj({}); return 
		}

		vElfilter && (vElfilter.disabled = true)
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(isMobile) return;

			const vElfocus = document.getElementById("inpjenis");
			if(!isMobile) vElfocus && vElfocus.focus();
		},350);
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uDataloadedObj) { setTitelObj({}); return }

		uTitelObj.periode 	= cBulanPanjang[parseInt(uDataloadedObj.bln_id)-1]+" "+uDataloadedObj.tahun;
		uTitelObj.subtitel = uDataloadedObj.jenis_stok === "BELI"
			? "PERSEDIAAN" : "PERSEDIAAN KONSINYASI";
	},[uDataloadedObj]);
	React.useEffect(()=>{
		if(uDatavieworiArr.length <= 0) return;

		if(uPageActive > 1) setPageActive(1);
		//else { setTabelRowSelect(-1); setTabelIDPilih(0); }

		if(UFunc.isEmpty(uSortedObj)) { 
			const vDatadefaultArr = uDatavieworiArr.slice(1);
			setDataviewArr(vDatadefaultArr);
			return
		}

		if(uSortedObj.mode==="ASC") {
			uDataviewArr.sort((a,b)=> a[uSortedObj.field] - b[uSortedObj.field])
		} else if(uSortedObj.mode==="DESC") {
			uDataviewArr.sort((a,b)=> b[uSortedObj.field] - a[uSortedObj.field])
		}
	},[uSortedObj,
	uSortedObj.field,
	uSortedObj.mode
	]);
	
	//console.log("Lapmutasistok - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )//>

	return (
	<>
		{(!uDataloadedObj) ? (
		<CCard className="classcardbg">
			<CCardHeader className="">
			<small className="fw-bolder">Pilih Filter Laporan</small>
			</CCardHeader>

			<CCardBody className="d-flex justify-content-center align-items-center" style={{minHeight:200}}>
			<div className="classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between">
			<div>
			<div className="">Periode <b>{uTitelObj.periode}</b></div>
			<small className="text-primary">Jenis STOK <b>{uTitelObj.subtitel}</b></small>
			</div>

			<div>
				<CLink onClick={()=>hdlKlikCetak()} className="classcursorpointer classikon classikoncetak"/>
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller">
					<small className="text-muted">
						{UFunc.formatAngka(uTimeElapsed)}ms
					</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialogview
			renderContent={contentDlgview()}
			dialogHeader={uDlgviewObj.header || "Detil Item"}
			toggle={()=>setDlgviewshow(false)}
			options={{centered:true}}
			show={uISDlgviewshow}
			{...props}/>

		<iframe id="ifmcontentstoprint" title="FrameCetak" className="d-none"/>
	</>
	)//>
}	

export default Lapmutasistok