import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CCardHeader,
	CCard,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CFormSelect,
	CBadge,
	CSpinner,
	CListGroup,
	CListGroupItem,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu, 
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import { 
	MyPagination,
	MyDialogview,
	MyPage500,
} from '../components/index';
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';

let pjson     	= require('../../package.json');
const Stokopname= (props) => {
	const uComponentname 	= Stokopname.name;
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props; 

	let uFuncname;//-->hanya untuk API
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uBahasaObj  		= useSelector(state => state.listBahasa);

	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	=useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview]			= React.useState(false);
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);

	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	const [uFirstload,setFirstload]			= React.useState("YA");

	//---VAR_GLOBALS--/
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelExcel 		= uHeaderActionObj.isHeaderExcel || false;

	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uDataexcelArr,setDataexcelArr]	= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);

	const [uInpBarangkode,setInpBarangkode]	= React.useState("");
	const [uInpBarangnama,setInpBarangnama] = React.useState("");
	const [uInpSatuan,setInpSatuan]			= React.useState("PCS");
	const [uInpHargajual,setInpHargajual]	= React.useState("");
	const [uInpJenisID,setInpJenisID]		= React.useState("1");

	//---FORM_DETIL--/
	const [uIsShowDetil,setShowDetil]		= React.useState(false);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uDetilArrObj,setDetilArrObj]		= React.useState([]);
	//---END FORM_DETIL--/

	//--DOM_ELEMENTS--/
	const uElsjmldata	= document.getElementById("idjmldata");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_ELEMENTS--/
	//---END VAR_GLOBALS--/

	//console.log("("+uComponentname+") uHeaderActionObj.isHeaderView: "+uHeaderActionObj.isHeaderView);
	//console.log("("+uComponentname+") uHandelView: "+uHandelView);


	//---HANDLE--/
	const hdlToggleDetil=()=>{ setShowDetil(false); }
	const hdlKlikProses=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		uDispatch({type: "set", gInitTabelSelected: {}});
		const vTmpObj	= { initID:_IDTABEL, initPage:uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj});

		uNavigate("/substok/stokopname/formproses/"+_IDTABEL);
	}
	const hdlKlikLihat = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		var vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vHeader	= 
			(uBahasaObj.caption_detilsisastok||"caption_detilsisastok")+": "+(uDatamainArr[vArridx].nama||"").toUpperCase()+""+
		"";
		setDialogHeader(vHeader);
		if((uDatamainArr[vArridx].items_arr || []).length > 0) {
			setDetilArrObj(uDatamainArr[vArridx].items_arr);
			setShowDetil(true); return;
		}

		apiLoaddetil(_IDTABEL);
	}
	//---END HANDLE--/

	//---Init_FUNCTION--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_stokopname || "";

		if (vKeyword.length >= 3)
			uElsjmldata && (uElsjmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElsjmldata && (uElsjmldata.innerHTML = "&#8734;");
	}
	const initExcelClosing = () => {
		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 10;
		const vColspan		= 8;

		//console.log("(Stokopname - initExcelClosing) uDataexcelArr => "+JSON.stringify(uDataexcelArr))

		let vTmpObj	={
			no : "",
			kode_barang: "",
			nama_barang: "",
			jenis: "",
			stok_lalu: "",
			jml_pakai: "",
			jml_proses: "",
			stok_sisa: "",
		}
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDataexcelArr.forEach((vItems,vKeys)=>{
			const {
				id,kode,nama,jenis,stok_lalu,satuan,jml_pakai,stok_sisa,
				jml_proses,
			} = vItems;

			vColposwidth = (nama||"").length > vColposwidth 
				? (nama||"").length : vColposwidth; 

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				kode_barang: kode||"-",
				nama_barang: nama || "-",
				jenis: {v:(jenis || "-"),s:{alignment:{horizontal:"center"}}},
				stok_lalu: { v:parseFloat(stok_lalu || "0"), t:"n", s:{numFmt: "#,##0"} },
				jml_pakai: { v:parseFloat(jml_pakai || "0"), t:"n", s:{numFmt: "#,##0"} },
				jml_proses: { v:parseFloat(jml_proses || "0"), t:"n", s:{numFmt: "#,##0"} },
				stok_sisa: { v:parseFloat(stok_sisa || "0"), t:"n", s:{numFmt: "#,##0"} },
			} 
			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		//--SUM_NILAI--/
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:47}]
		const wscols	= [
			{wpx:40},
			{wpx:100},
			{wch:vColposwidth},
			{wpx:75},
			{wpx:75},
			{wpx:60},
			{wpx:60},
			{wpx:60},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_stokopname_"+
			(vTanggalnowDT).valueOf()+
		"";
		const vSheetlabel = vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate());

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vKeyword = uKeywordObj.kwd_stokopname || "";
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\n("+UFunc.WaktuPendek()+")"+
			"\r\n"+(vKeyword!=="" ? (uBahasaObj.word_keyword||"word_keyword")+": "+vKeyword : "")+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Kode Barang",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Nama Barang",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Jenis",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Stok Bln Lalu",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"Pakai Bln Ini",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"Proses Bln Ini",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"Stok Bln Ini",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	const initFormAwal = () => {
		setTabelIDPilih(0);

		setInpBarangkode("");
		setInpBarangnama("");
		setInpSatuan("PCS");
		setInpHargajual("");
		setInpJenisID("1");
	}
	//---END Init_FUNCTION--/

	//---CONTENT_FUNC--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_stokopname || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		
		//console.log("("+uComponentname+"-contentMain) uPageActive = "+(uPageActive));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width="5%" className="px-1">No</th>
		<th className="px-1">
			<CRow className="mx-0">
			<CCol md="5" className="text-start">{uBahasaObj.word_barang||"word_barang"}</CCol>
			<CCol md="1" className="">{uBahasaObj.word_sat||"word_sat"}</CCol>
			<CCol md="5" className="d-flex justify-content-between">
				<div className="w-25">{uBahasaObj.caption_stoklalu||"caption_stoklalu"}</div>
				<div className="w-25">{uBahasaObj.caption_pakainow||"caption_pakainow"}</div>
				<div className="w-25">Jml Dlm Proses</div>
				<div className="w-25">{uBahasaObj.caption_stokakhir||"caption_stokakhir"}</div>
			</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
		</th>
		</tr>

		<tr className="d-md-none">
		<th width={35} className="p-0"/>
		<th className="p-0"/>
		</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,kode,nama,jenis,stok_lalu,satuan,jml_pakai,stok_sisa,
				jml_proses,is_loading
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (nama||"Undf").toUpperCase();

			const vBarangnama	= vKeyword==="" ? nama 
				: nama.replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vBarangkode= vKeyword==="" ? (kode||"-") 
				: (kode||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vBarangjenis= vKeyword==="" ? jenis 
				: jenis.replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vBarangsatuan= vKeyword==="" ? satuan 
				: satuan.replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}
				key={vKeys}>
				<td className="px-1">{vNumber}.</td>
				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="5" className="px-1 text-start d-flex justify-content-between">
					<div>
						{(kode||"")!=="" && (
						<span className="d-none d-md-inline">{UFunc.renderHTML(vBarangkode)} - </span>
						)}
						
						<span className="text-info">{UFunc.renderHTML(vBarangnama)}</span>
						
						<small className="d-none d-md-inline"> [{UFunc.renderHTML(vBarangjenis)}]</small>
						
						{uTokenObj.userhak==="FREEROLE" && (
						<span className="classfontsmaller"> &middot; <small>({id})</small></span>
						)}
					</div>

					<div className="d-md-none">
					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<CDropdown>
					<CDropdownToggle color="transparent" caret={false} className="p-0">
						<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
					</CDropdownToggle>

					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikLihat(id)}>
						<CIcon className="classikonhistori align-middle mx-0 me-2" height={25}/>
						{uBahasaObj.caption_lihatdetil||"Lihat Detil"}
						</CDropdownItem>
						
						{(uIsEOMExecuted) && (
							<CDropdownItem onClick={()=>hdlKlikProses(id)}>
							<CIcon className="classikonopname align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_setstokopname||"Set Stok Opname"}
							</CDropdownItem>
						)}
					</CDropdownMenu>
					</CDropdown>
					)}
					</div>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol md="1" className="px-1 text-center d-none d-md-block">{UFunc.renderHTML(vBarangsatuan)}</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_kodebarang||"caption_kodebarang"}</CCol>
				<CCol xs="7" className="px-1 text-md-start d-md-none text-uppercase">
					<small>{UFunc.renderHTML(vBarangkode.toUpperCase())}</small>
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.word_jenis||"word_jenis"}</CCol>
				<CCol xs="7" className="px-1 d-md-none text-uppercase">
					<small>[{UFunc.renderHTML(vBarangjenis)}]</small>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol md="5" className="px-1 text-center d-none d-md-flex justify-content-between">
					<div className="w-25">{UFunc.formatAngka(stok_lalu)}</div>
					<div className="w-25 text-danger">{UFunc.formatAngka(jml_pakai)}</div>
					<div className="w-25 text-primary">{UFunc.formatAngka(jml_proses)}</div>
					<div className="w-25 fw-bolder">{UFunc.formatAngka(stok_sisa)}</div>
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_stoklalu||"caption_stoklalu"}</CCol>
				<CCol xs="7" md="1" className="px-1 text-md-center d-md-none">
					{UFunc.formatAngka(stok_lalu)} {(satuan||"")}
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_pakainow||"caption_pakainow"}</CCol>
				<CCol xs="7" md="1" className="px-1 d-md-none text-danger">
					{UFunc.formatAngka(jml_pakai)} {(satuan||"")}
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_jmlproses||"Jml Dlm Proses"}</CCol>
				<CCol xs="7" md="1" className="px-1 d-md-none text-primary">
					{UFunc.formatAngka(jml_proses)} {(satuan||"")}
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>
				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_stokakhir||"caption_stokakhir"}</CCol>
				<CCol xs="7" className="px-1 fw-bolder d-md-none">
					{UFunc.formatAngka(stok_sisa)} {(satuan||"")}
				</CCol>

				<CCol md="1" className="px-0 text-center d-none d-md-block">
					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
						<CTooltip className="d-none d-lg-block" content={"--"+(uBahasaObj.caption_lihatdetil||"caption_lihatdetil")+": "+vCaptionUpper}>
						<CLink className="classcursorpointer" onClick={()=>hdlKlikLihat(id)}>
							<CIcon icon="cilSearch" className="classikoncoreui" height={23}/>
						</CLink>
						</CTooltip>

						{(uIsEOMExecuted && (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN")) && (
						<>
						<span className="d-md-none mx-1">&middot;</span>
						<CTooltip className="d-none d-lg-block" content={"--"+(uBahasaObj.caption_setstokopname||"caption_setstokopname")+": "+vCaptionUpper}>
						<CLink className="classikoncoreui classikontabel classikonopname classcursorpointer mx-0"
							onClick={()=>hdlKlikProses(id)}/>
						</CTooltip>
						</>
						)}
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDetil = () => {
		if(!uIsShowDetil) return (<></>)//>

		if(uDetilArrObj.length <= 0) return (<></>)//>

		return (
		<small>
		<table className="table table-striped table-borderless" id="idtabeldetil">
		<thead>
			<tr className="d-none d-md-table-row classfontsmaller align-top text-center">
			<td width="5%" className="px-1">No</td>
			<td width="15%" className="px-1 text-start">Tgl Masuk</td>
			<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" lg="4" className="text-start">BatchNo</CCol>
				<CCol lg="2" className="d-md-none d-lg-block">HPP (Rp)</CCol>
				<CCol lg="1" className="d-md-none d-lg-block">Masuk</CCol>
				<CCol lg="1" className="d-md-none d-lg-block">keluar</CCol>
				<CCol lg="2" className="d-md-none d-lg-block">Sisa</CCol>
				<CCol lg="2" className="d-md-none d-lg-block">N.Stok (Rp)</CCol>
				</CRow>
			</td>
			</tr>
		</thead>

		<tbody>
		{uDetilArrObj.map((vItems,vKeys)=>{
			const {id,tgl_masuk,batchno,expdate,hpp,masuk,keluar} = vItems;

			const vBgcolor 	= vKeys%2===0 ? "secondary" : "white";
			const vAccent 	= vKeys%2===0 ? "success" : "warning";

			return (
			<tr className={"align-top text-end border-start border-start-5 border-start-"+vAccent}
				key={vKeys}>
				<td className="px-1">{vKeys+1}.</td>
				<td className="px-1 text-start">
					{UFunc.TglAngka(tgl_masuk)}
				</td>

				<td className="px-1">
					<CRow className="mx-0">
					<CCol xs="12" lg="4" className="px-1 text-start">
						{(batchno||"") !=="" && (<div><small className="fw-bolder">{(batchno||"").toUpperCase()}</small></div>)}
						{(expdate||"") !=="" && (<div><small className="text-success">(EXP.{UFunc.TglAngka(expdate||"")})</small></div>)}
						{((expdate||"") ==="" && (batchno||"") ==="") && (<>-</>)}
					</CCol>

					<CCol xs="12" className="px-1 my-3 d-lg-none classborderbottom"/>

					<CCol xs="5" className="px-1 text-start d-lg-none">{uBahasaObj.word_hpp||"word_hpp"} (Rp)</CCol>
					<CCol xs="7" lg="2" className="px-1">{UFunc.formatAngka(hpp)}</CCol>
				
					<CCol xs="5" className="px-1 text-start d-lg-none">{uBahasaObj.word_masuk||"word_masuk"}</CCol>
					<CCol xs="7" lg="1" className="px-1 text-lg-center">
						<small>{UFunc.formatAngka(masuk)}</small>
					</CCol>
				
					<CCol xs="5" className="px-1 text-start d-lg-none">{uBahasaObj.word_keluar||"word_keluar"}</CCol>
					<CCol xs="7" lg="1" className="px-1 text-lg-center">
						<small className="text-danger">{UFunc.formatAngka(keluar)}</small>
					</CCol>
				
					<CCol xs="5" className="px-1 text-start d-lg-none">{uBahasaObj.word_sisa||"word_sisa"}</CCol>
					<CCol xs="7" lg="2" className="px-1 text-lg-center fw-bolder">
						{UFunc.formatAngka((masuk-keluar))}
					</CCol>
				
					<CCol xs="12" className="px-1 my-3 d-lg-none classborderbottom"/>

					<CCol xs="5" className="px-1 text-start d-lg-none">{uBahasaObj.caption_nilaistok||"caption_nilaistok"} (Rp)</CCol>
					<CCol xs="7" lg="2" className="px-1 text-primary">
						{UFunc.formatAngka(hpp*(masuk-keluar))}
					</CCol>
					</CRow>
				</td>
			</tr>
			);
		})}
		</tbody>
		</table>
		</small>
		)//>
	}
	const contentCetak=()=>{//--TIDAK_DIPAKAI--/
		const vKeyword = uKeywordObj.kwd_stokopname || "";
		var vHTMLs = uHTMLstyleTabel;

		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="7">
			<div>`+uBahasaObj["menus_"+uActiveroute.toLowerCase()]+`</div>
			<div style="font-size:smaller;font-weight:normal;font-style:italic;">(`+UFunc.WaktuPendek()+`)</div>
			`;
		vHTMLs += vKeyword!="" 
			? "<div style='font-size:smaller;font-weight:normal;'>"+(uBahasaObj.word_keyword||"word_keyword")+": <U>"+vKeyword+"</U></div>" : "";

		vHTMLs += `
			</td>
			</tr>
			<tr>
				<th width="7%">No</th>
				<th align="left">`+uBahasaObj.caption_namabarang+`</th>
				<th width="7%">`+(uBahasaObj.word_sat||"word_sat")+`</th>
				<th width="9%">`+(uBahasaObj.caption_stoklalu||"caption_stoklalu")+`</th>
				<th width="9%">`+(uBahasaObj.caption_pakainow||"caption_pakainow")+`</th>
				<th width="9%">`+(uBahasaObj.caption_stokakhir||"caption_stokakhir")+`</th>
				<th width="10%">CHK</th>
			</tr>
			</thead>
			<tbody>`;

		uDataexcelArr.forEach((vItems,vKey)=>{
			const {id,kode,nama,jenis,satuan,stok_lalu,jml_pakai,stok_sisa} = vItems;

				vHTMLs 	+= `
					<tr key={vKey} valign="top">
						<td align="right" style="font-size:smaller">`+(vKey+1)+`.</td>
						<td align="left">`+
							((kode||"")!==""?kode.toUpperCase()+"-":"")+
							nama+
							((jenis||"")!==""?` <span style='font-size:smaller;'>[`+jenis+`]</span>`:"")+
						`</td>
						<td align="center" style="font-size:smaller">`+satuan+`</td>
						<td align="center">`+UFunc.formatAngka(stok_lalu)+`</td>
						<td align="center" style="font-weight:smaller;">`+UFunc.formatAngka(jml_pakai)+`</td>
						<td align="center" style="font-weight:bolder;">`+UFunc.formatAngka(stok_sisa)+`</td>
						<td>&nbsp;</td>
					</tr>
				`;
		});
		vHTMLs 	+= `
			</tbody>
		`
		vHTMLs 	+= `</table>`;

		return vHTMLs;
	}
	//---END CONTENT_FUNC--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname 	= apiLoaddata.name;
		_PAGE		= parseInt(_PAGE)||1;
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_stokopname || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";

		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_stokopname/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				vLoadArr.map(vItems => vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vLoadArr]);
				setJmlData(parseInt(output_string.totaldata||uJmlData))
				/*if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);//-*/

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddetil = (_IDTABEL) => { 
		uFuncname 		= apiLoaddetil.name;
		_IDTABEL 		= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_loading = true;
		setEfekview(!uEfekview);
		setDetilArrObj([]);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoaddetil) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_barangid : _IDTABEL.toString(),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_stokopname/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			uDatamainArr[vArridx].is_loading = false;
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.detilobject || "[]");
				if(vTmpArr.length <= 0) {
					showToast((uBahasaObj.caption_dataempty||"caption_dataempty")+"..");
					return;
				}

				uDatamainArr[vArridx].items_arr = vTmpArr;
				setDetilArrObj(vTmpArr);
				setShowDetil(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_loading = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadexcel = () => {
		uFuncname = apiLoadexcel.name;
		const vKeyword = uKeywordObj.kwd_stokopname || "";

		if(vKeyword.length >= 3) 
			{ setDataexcelArr(uDatamainArr); return; }

		setLoading(true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		setDataexcelArr([]);
		const vDATAS	= JSON.stringify({
			send_initcetak: "YA",
			send_keyword: vKeyword,
			send_pg: 1,
			send_maxdata: uMaxData,
			send_loadjml: "YA",
			send_tokenauth: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_stokopname/ld_data";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setDataexcelArr(JSON.parse(output_string.dataobject || "[]"))
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{//--JIKA_PAGINATION_DIATUR_BACKEND--/
		//console.log("("+uComponentname+"-[uIsInitbefore]) uTabelSelectObj = "+JSON.stringify(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setJmlData(0);
			setTabelIDPilih(0);
			setTabelRowSelect(-1);
			setPageActive(1);

			setShowDetil(false);
    		setDatamainArr([]);
    		setDataexcelArr([]);
    	}
	},[]);
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= uTabelSelectObj.initID;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+vInitID);
		setTabelRowSelect(vRowIdx);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);
	React.useEffect(()=>{ uElsjmldata && initJmldata(); },[uElsjmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		initJmldata();
	},[uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setDatamainArr([]);
		setDataexcelArr([]);
		setJmlData(0);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelExcel) return;

		uHeaderActionObj.isHeaderExcel = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;
		if(uJmlData <= 0) return;

		apiLoadexcel();
	},[uHandelExcel]);
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);
	React.useEffect(()=>{
		if(!uIsShowDetil) 
			{setDetilArrObj([]); return}
	},[uIsShowDetil]);
	React.useEffect(()=>{
		const vJmlCetak = parseInt(uDataexcelArr.length)||0;
		if(vJmlCetak <= 0) return;

		initExcelClosing();

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDataexcelArr([]);
		},500)
	},[uDataexcelArr]);
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<div className="d-none">uPageActive = {uPageActive}</div>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_stokopname || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>
			
			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowDetil} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleDetil}
			renderContent={contentDetil()}/>
		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
		</>
	)//>
}
	
export default Stokopname;