import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CTooltip,
	CLink,
	CListGroup,
	CListGroupItem,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CButton,
	CFormSelect,
	CSpinner,
	CBadge,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPagination,
	MyDialogform,
	MyDialogview,
	MyDialoglookup,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../package.json');
const Hutangbeliaset= (props) => {
	const uComponentname 	= Hutangbeliaset.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//--GLOBAL_VARS--/
	const uIsAllowedbayar	= uIsEOMExecuted
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	//---DATA--/
	const uKeywordObj						= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  				= useSelector(state => state.gInitHeaderAction);
	const uHandelView 						= uHeaderActionObj.isHeaderView || false;
	const uHandelEdit 						= uHeaderActionObj.isHeaderEdit || false;
	const [uFirstload,setFirstload]			= React.useState("YA");
	const uKasGLloadArr						= JSON.parse(localStorage.getItem("kasgllist") || "[]");

	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const uElJmldata	= document.getElementById("idjmldata");
	//---END DATA--/

	//--FORMDIALOG--/
	const [uKasGLArr,setKasGLArr]			= React.useState([]);//-*/
	const [uIsShowForm,setShowForm]			= React.useState(false);
	const [uDialogHeader,setDialogHeader]	= React.useState("");

	const [uInpTglBayar,setInpTglBayar]		= React.useState(UFunc.DbDate());
	const [uInpNilaiBayar,setInpNilaibayar]	= React.useState(0);
	const [uInpKasID,setInpKasID]			= React.useState(0);
	const [uInpKascaption,setInpKascaption]	= React.useState(0);
	const [uInpNonota,setInpNonota]			= React.useState("");
	const [uInpStatustembak,setInpStatustembak]	= React.useState("REGULER");

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]= React.useState(document.getElementById("btnDialogSimpan"));
	//--END FORMDIALOG--/

	const [uIsShowhistori,setShowhistori]	= React.useState(false);
	const [uDatahistoriArr,setDatahistoriArr]= React.useState([]);

	//--DLG_LOOKUP--/
	const [uDlglookupheader,setDlglookupheader]	= React.useState("");
	const [uInpDlglookup,setInpDlglookup]		= React.useState("");
	const [uDataDlglookupArr,setDataDlglookupArr]= React.useState([]);
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false);
	//--END DLG_LOOKUP--/
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowForm(false) }
	const hdlKlikFormSimpan=()=>{
		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih)
		if(vArridx < 0) return;

		//---VALIDASI--/
		let vElfocus = document.getElementById("inptglbayar");
		if(UFunc.isEmpty(uInpTglBayar)) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_tglbayar||"caption_tglbayar")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}

		const vTanggalDt 	= UFunc.toDate(uInpTglBayar);
		const vDateBeyond 	= UFunc.DateIsBeyond(vTanggalDt);
		if(vDateBeyond) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
			return;
		}
		const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
		if(vDateExceededBl) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
			return;
		}
		const vTglHutangDt = UFunc.toDate(uDatamainArr[vArridx].tgl_hutang);
		if(vTanggalDt < vTglHutangDt) {
			vElfocus && vElfocus.focus();
			showToast("<b>Tanggal Bayar Tidak Valid</b>.."+
				"<br/>Tanggal Bayar Harus Dilakukan Sesudah Tanggal Hutangnya."+
				"<br/>Mohon Diperbaiki !");
			return;	
		}

		if((parseInt(uInpNilaiBayar)||0) <= 0 ) {
			document.getElementById("inpnilaibayar").focus();
			showToast((uBahasaObj.caption_nilaibayar||"caption_nilaibayar")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}
		if((parseInt(uInpKasID)||0) === 0) {
			document.getElementById("inpkasid").focus();
			showToast((uBahasaObj.caption_asalkas||"caption_kaspenerima")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		//---END VALIDASI--/

		apiSimpanbayar();
	}
	const hdlToggleHistori = () => { setShowhistori(false) }
	const hdlToggleDlglookup = () => { setDlglookupshow(false) }
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		const vIdx = UFunc.getObjectIndeks(uDataDlglookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		const vElsfocusnext = uElBtnDlgsimpan;
		setInpKasID(uDataDlglookupArr[vIdx].id)
		setInpKascaption(uDataDlglookupArr[vIdx].caption)
		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			vElsfocusnext && vElsfocusnext.focus()
		},350);
	}
	const hdlKlikKaslookup = () => {
		//setInpDlglookup(uInpKascaption);
		setInpKascaption(""); setInpKasID("");
		const vTmpArr	= uKasGLArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_asalkas||"").toUpperCase());
		setDlglookupshow(true)
	}

	const hdlKlikHistori = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);

		//alert(vArridx);return;
		const vHeadercaption = (uBahasaObj.caption_historibayar||"caption_historibayar")+": "+(uDatamainArr[vArridx].nama_supplier||"").toUpperCase();
		setDialogHeader(vHeadercaption);

		if((uDatamainArr[vArridx].histori_arr||[]).length > 0) {
			setDatahistoriArr(uDatamainArr[vArridx].histori_arr||[]);
			setShowhistori(true);
			return;
		}
		apiLoadhistori(_IDTABEL);
	}
	const hdlKlikBayar=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setTabelIDPilih(_IDTABEL);
		const vSisaHutang	= (parseInt(uDatamainArr[vArridx].nilai_hutang)||0)
			- (parseInt(uDatamainArr[vArridx].terbayar)||0);
		const vHeadercaption= (uBahasaObj.caption_bayarhutang||"caption_bayarhutang")+": "+
			(uDatamainArr[vArridx].nama_supplier||"").toUpperCase()+
			" ("+(uBahasaObj.word_sisa||"word_sisa")+" Rp "+UFunc.formatAngka(vSisaHutang)+")"+
			"";
		setDialogHeader(vHeadercaption);
		setShowForm(true);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initFormbayarAwal = () => {
		setTabelIDPilih(0);
		setInpTglBayar(UFunc.DbDate());
		setInpKasID("0");
		setInpKascaption("");
		setInpNilaibayar(0);
		setInpNonota("");
		setInpStatustembak("REGULER");
	}
	const initJmldata=()=>{
		const vKeyword = uKeywordObj.kwd_hutangbeliaset || "";
		if (vKeyword.length > 2) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_hutangbeliaset || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="3" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="3" className="d-flex justify-content-between">
					<div className="w-50">{uBahasaObj.caption_nilaihutang||"caption_nilaihutang"}<small className="d-block">(Rp)</small></div>
					<div className="w-50">{uBahasaObj.word_terbayar||"word_terbayar"}<small className="d-block">(Rp)</small></div>
				</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_sisahutang||"caption_sisahutang"}<div className="classfontsmaller">(Rp)</div></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,nama_supplier,tgl_hutang,nilai_hutang,tgl_input,tgl_jt,terbayar,
				no_rek,no_nota,is_loading
			} = vItems;

			vNumber++;
			const vCaptionUpper	= ((nama_supplier||"")+
				((no_nota||"")!==""?" ("+(no_nota||"")+")":"")).toUpperCase();

			const vNamaSupplier	= vKeyword==="" ? UFunc.capitalize(nama_supplier||"") 
				: (UFunc.capitalize(nama_supplier||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNonota = vKeyword==="" ? (no_nota||"") 
				: (no_nota||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNorek= vKeyword==="" ? (no_rek||"") 
				: (no_rek||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglHutang= vKeyword==="" ? UFunc.HariAngka(tgl_hutang||"") 
				: (UFunc.HariAngka(tgl_hutang||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglJT= vKeyword==="" ? UFunc.TglAngka(tgl_jt||"") 
				: (UFunc.TglAngka(tgl_jt||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
				
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td align="right" className="px-1 pe-0">{(vNumber)}.</td>
				<td className="px-1 ps-0">
				<CRow className="mx-0">
				<CCol md="3" className="px-1 d-none d-md-block text-md-start">
					<div>
					{UFunc.renderHTML(vTglHutang)}
					{uTokenObj.userhak==="FREEROLE" && (<span className="classfontsmaller"><small> &middot; ({id})</small></span>)}
					</div>				

					{(tgl_jt||"")!=="" && (
					<div className="classfontsmaller">
					<small>
						<span className="me-1">{uBahasaObj.caption_tgljt||"caption_tgljt"}:</span>

						{UFunc.DateIsMundur(UFunc.toDate(tgl_jt)) ? (
							<CBadge className="" color="danger">
							{UFunc.renderHTML(vTglJT)}
							<CIcon icon="cilBellExclamation" className="fw-bolder classblinking ms-1 align-middle" height={18}/>
							</CBadge>
						) : (
						<span className="text-success">{UFunc.renderHTML(vTglJT)}</span>
						)}
					</small>
					</div>
					)}
				</CCol>

				<CCol xs="12" md="3" className="px-1 text-start d-flex justify-content-between">
					<div>
						<div className="text-info">{UFunc.renderHTML(vNamaSupplier)}</div>
						{(no_rek||"")!=="" && (
						<div className="fst-italic classfontsmaller">
						<small>({uBahasaObj.word_rek||"word_reks"}: {UFunc.renderHTML(vNorek)})</small>
						</div>
						)}	

						{(no_nota||"")!=="" && (
						<div className="d-none d-md-block">
						<small>
							{uBahasaObj.word_nonota||"word_nonota"}:{" "}
							<span className="fw-bolder">{UFunc.renderHTML(vNonota)}</span>
						</small>
						</div>
						)}	
					</div>


					{(is_loading||false)===true ? (
						<CSpinner color="dark" className="d-md-none" size="sm"/>
					) : (
					<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm" className="p-0"
							color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
						{uIsEOMExecuted && (
						<CDropdownItem onClick={()=>hdlKlikBayar(id)}>
							<CIcon className="classikonbayar me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_bayarhutang)}
						</CDropdownItem>
						)}
						
						<CDropdownItem onClick={()=>hdlKlikHistori(id)}>
							<CIcon className="classikonhistoribayar me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_historibayar)}
						</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>
					)}
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>
				
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					{UFunc.renderHTML(vTglHutang)}
					{uTokenObj.userhak==="FREEROLE" && (<span className="classfontsmaller"><small> &middot; ({id})</small></span>)}
				</CCol>
				
				{(tgl_jt||"")!=="" && (
				<>
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_tgljt||"caption_tgljt"}</CCol>
				<CCol xs="6" className="px-1 d-md-none classfontsmaller">
					<small>
						{UFunc.DateIsMundur(UFunc.toDate(tgl_jt)) ? (
							<CBadge className="" color="danger">
							{UFunc.renderHTML(vTglJT)}
							<CIcon icon="cilBellExclamation" className="fw-bolder classblinking ms-1 align-middle" height={18}/>
							</CBadge>
						) : (
						<span className="text-success">{UFunc.renderHTML(vTglJT)}</span>
						)}
					</small>
				</CCol>
				</>
				)}

				{(no_nota||"")!=="" && (
				<>
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_nonota||"word_nonota"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					<small className="fw-bolder">{UFunc.renderHTML(vNonota)}</small>
				</CCol>
				</>
				)}

				<CCol md="3" className="px-0 d-none d-md-flex justify-content-between">
					<small className="w-50 px-1">
					<span className="d-md-none">Rp </span>{UFunc.formatAngka(nilai_hutang)}
					</small>

					<small className="w-50 px-1">
					<div className="">{UFunc.formatAngka(terbayar)}</div>
					</small>
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_nilaihutang||"caption_nilaihutang"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					<span className="d-md-none">Rp </span>{UFunc.formatAngka(nilai_hutang)}
				</CCol>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_terbayar||"word_terbayar"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					<div className="">{UFunc.formatAngka(terbayar)}</div>
				</CCol>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_sisahutang||"caption_sisahutang"}</CCol>
				<CCol xs="6" md="2" className="px-1 text-primary">
					<span className="d-md-none">Rp </span>{UFunc.formatAngka(nilai_hutang - terbayar)}
				</CCol>

				<CCol md="1" className="text-md-center px-0 d-none d-md-block">
					{(is_loading||false)===true ? (
						<CSpinner color="primary" className="" size="sm"/>
					) : (
					<>
					{(uIsEOMExecuted) && (
					<CTooltip content={"--"+(uBahasaObj.caption_bayarhutang||"caption_bayarhutang")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer classikontabel classikonbayar classikoncoreui mx-0 me-lg-1"
						onClick={()=>hdlKlikBayar(id)}/>
					</CTooltip>
					)}
					
					<CTooltip content={"--"+(uBahasaObj.caption_historibayar||"caption_historibayar")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer classikontabel classikonhistoribayar classikoncoreui mx-0"
						onClick={()=>hdlKlikHistori(id)}/>
					</CTooltip>
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform=()=>{
		if(!uIsShowForm) return "";

		return (
		<CForm>
			{uTokenObj.userhak === "FREEROLE" && (
			<>
			<CRow className=" my-2">
			<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
			</CCol>
			<CCol xs="8" lg="4">
				<CFormSelect size="sm"
						onChange={(e)=>setInpStatustembak(e.target.value)}
						value={uInpStatustembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
				</CFormSelect>

				<div className="classpetunjuk">
					<small>*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL</small>
				</div>
			</CCol>
			</CRow>
			<div className="mx-0 my-3 classborderbottom"/>
			</>
			)}

			<CRow className=" my-2">
			<CCol xs="4" className="text-start text-md-end text-info">{uBahasaObj.caption_tglbayar||"caption_tglbayar"}</CCol>
			<CCol xs="8" lg="4">
				<CFormInput
					size="sm"
					type="date"
					value={uInpTglBayar}
					onChange={(e)=>setInpTglBayar(e.target.value)}
					id="inptglbayar"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">{uBahasaObj.word_nonota||"word_nonota"}</CCol>
			<CCol xs="8" lg="4">
				<CFormInput maxLength="20"
					size="sm"
					value={uInpNonota}
					className="text-uppercase"
					onChange={(e)=>setInpNonota(e.target.value)}
					id="inpnonota"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</CCol>
			<CCol xs="8" lg="4">
				<NumberFormat 
					value={uInpNilaiBayar}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => setInpNilaibayar(values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							onChange={(e) => setInpNilaibayar(e.target.value)}
							size="sm"
							className="text-end"
							onFocus={(e)=>e.target.select()}
						id="inpnilaibayar"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">{uBahasaObj.caption_asalkas||"caption_asalkas"}</CCol>
			<CCol xs="8" lg="4">
				<CInputGroup size="sm">
					<CFormInput size="sm"
						value={uInpKascaption}
						onClick={()=>hdlKlikKaslookup()}
						placeholder={"--"+uBahasaObj.word_pilih+": "+(uBahasaObj.caption_asalkas)+".."}
						id="inpkascaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikKaslookup()}
						id="btnkaslookup">
					<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
				</CInputGroup>
			</CCol>
			</CRow>
		</CForm>
		)
	}
	const contentHistori = () => {
		if(!uIsShowhistori) return (<></>)

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if(vArridx < 0) return (<></>);

		return (
		<small>
		<CRow className="">
		<CCol lg="6" className="pe-lg-2">
			<CCard className="mb-3">
			<CCardHeader className="py-1">Data Hutang</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
				<div className="px-1">Tgl Hutang</div>
				<div className="px-1 text-end">{UFunc.HariAngka(uDatamainArr[vArridx].tgl_hutang)}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">Supplier</div>
				<div className="px-1 text-end text-info">{uDatamainArr[vArridx].nama_supplier}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">No.Nota</div>
				<div className="px-1 text-end">{uDatamainArr[vArridx].no_nota}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">Tgl JT</div>
				<div className="px-1 text-end text-success">{UFunc.TglAngka(uDatamainArr[vArridx].tgl_jt)}</div>
				</div>
					
				<div className="px-1 my-3 classborderbottom"/>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">Nilai Hutang (Rp)</div>
				<div className="px-1 text-end">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_hutang)}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">Terbayar (Rp)</div>
				<div className="px-1 text-end">{UFunc.formatAngka(uDatamainArr[vArridx].terbayar)}</div>
				</div>
				
				<div className="d-flex justify-content-between">
				<div className="px-1">Sisa Hutang (Rp)</div>
				<div className="px-1 text-end text-primary fw-bolder">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_hutang - uDatamainArr[vArridx].terbayar)}</div>
				</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol lg="6" className="ps-lg-2">
			<CCard className="mb-3">
			<CCardHeader className="py-1">Riwayat Pembayaran</CCardHeader>

			<CCardBody className="px-1">
			<CListGroup className="mx-0">
			{uDatahistoriArr.map((vItems,vKeys)=>{
				const {
					id,tgl_bayar,no_nota,nilai_bayar
				} = vItems;

				const vBgcolor 	= vKeys%2===0 ? "light" : "white";
				const vAccent 	= vKeys%2===0 ? "success" : "warning";
				return (
					<CListGroupItem color={vBgcolor}
						className={"p-1 py-2 border-none border-start-4 border-start-"+vAccent} 
						key={vKeys}>
					<CRow className="mx-0">
					<CCol xs="12" md="8" className="px-1">
						<div className="fw-bolder">{UFunc.HariAngka(tgl_bayar||"")}</div>
						{(no_nota||"")!=="" && (
						<div className="fst-italic d-none d-md-block">
							<small>
							<span className="d-none d-md-inline">{uBahasaObj.word_nota||"Nota"}: </span>
							<span className="text-info text-uppercase">{UFunc.capitalize(no_nota||"")}</span>
							</small>
						</div>
						)}
					</CCol>

					<CCol xs="12" className="d-md-none px-1 my-2 classborderbottom"/>

					<CCol xs="5" className="d-md-none px-1">{uBahasaObj.word_nota||"Nota"}</CCol>
					<CCol xs="7" className="text-end px-1 d-md-none">
						<span className="text-info text-uppercase">{UFunc.capitalize(no_nota||"-")}</span>
					</CCol>
					
					<CCol xs="5" className="d-md-none px-1">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</CCol>
					<CCol xs="7" md="4" className="text-end px-1">
						<div className="text-primary">Rp{UFunc.formatAngka(nilai_bayar)}</div>
					</CCol>
					</CRow>
					</CListGroupItem>
				)
			})}
			</CListGroup>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</small>
		)

		return (
			<CListGroup className="mx-1">
			{uDatahistoriArr.map((vItems,vKeys)=>{
				const {
					id,tgl_bayar,no_nota,nilai_bayar
				} = vItems;

				const vBgcolor 	= vKeys%2===0 ? "light" : "white";
				const vAccent 	= vKeys%2===0 ? "success" : "warning";
				return (
					<CListGroupItem color={vBgcolor}
						className={"p-1 py-2 border-none border-start-4 border-start-"+vAccent} 
						key={vKeys}>
					<CRow className="mx-0">
					<CCol xs="12" lg="8" className="px-1">
						<div>{UFunc.HariAngka(tgl_bayar||"")}</div>
						{(no_nota||"")!=="" && (
						<div className="fst-italic">
							<small>
							{uBahasaObj.word_nonota||"word_nonota"}:{" "}
							<strong className="text-info text-uppercase">{UFunc.capitalize(no_nota||"")}</strong>
							</small>
						</div>
						)}
					</CCol>
					<CCol xs="5" className="d-lg-none px-1">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</CCol>
					<CCol xs="7" lg="4" className="text-end px-1">
						<div className="text-primary">Rp{UFunc.formatAngka(nilai_bayar)}</div>
						{vKeys<=0 && (
						<div className="classpetunjuk d-none d-md-block d-sm-none d-lg-inline">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</div>
						)}
					</CCol>
					</CRow>
					</CListGroupItem>
				)
			})}
			</CListGroup>
		);
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname = apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1;
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_hutangbeliaset || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);
		//const vKasGLDefaultArr = JSON.parse(localStorage.getItem("kasgllist"));

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoaddata) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangbeliaset/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				if(uFirstload==="YA") {
					vTmpObj = JSON.parse(output_string.kastambahanobj || "[]");
					setKasGLArr([...uKasGLloadArr,...vTmpObj]);
				}
				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadhistori = (_IDTABEL) => {
		uFuncname 		= apiLoadhistori.name;
		_IDTABEL 		= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_loading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoadhistori) LEWAT ");
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_hutangid : _IDTABEL.toString(),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangbeliaset/ld_histori";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			uDatamainArr[vArridx].is_loading = false;
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.historiobject || []);
				if(vTmpArr.length <= 0) {
					showToast((uBahasaObj.caption_dataempty||"caption_dataempty")+"..");
					return;
				}
				setDatahistoriArr(vTmpArr);
				uDatamainArr[vArridx].histori_arr = vTmpArr;
				setShowhistori(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_loading = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanbayar = () => {
		uFuncname = apiSimpanbayar.name;

		const vHutangID = parseInt(uTabelIDPilih) || 0;
		if(vHutangID <= 0) return;
		const vIdx 	= UFunc.getObjectIndeks(uDatamainArr,vHutangID);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_hutangid : vHutangID,
			send_tglbayar : uInpTglBayar,
			send_nilaibayar : uInpNilaiBayar,
			send_kasid : uInpKasID,
			send_nonota : uInpNonota,
			send_statustembak : uInpStatustembak,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangbeliaset/pr_simpanbayar";

		setLoading(true);
		uElBtnDlgsimpan.disabled=true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnDlgsimpan.disabled=false;
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr[vIdx].terbayar = (parseInt(uDatamainArr[vIdx].terbayar)||0) + (parseInt(uInpNilaiBayar)||0);
				hdlToggleForm();

				if(output_string.pesankhusus) {
					const vPesan = output_string.pesankhusus;
					showToast(vPesan,"SUKSES");
				}
			} else if(output_string.info) {
				showToast(output_string.info);
				setShowhistori(false);
			} else if(output_string.errors) {
				setShowhistori(false);
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtnDlgsimpan.disabled=true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnDlgsimpan.disabled=false;
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		setKasGLArr(uKasGLloadArr);
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setShowForm(false); setShowhistori(false);hdlToggleDlglookup()
			setDatamainArr([]);
    		setDatahistoriArr([]);
    		initFormbayarAwal();
    	}
	},[]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelEdit) return;

		uHeaderActionObj.isHeaderEdit = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		uNavigate("/subkeuangan/hbeliaset/listdata"); 
	},[uHandelEdit]);
	React.useEffect(()=>{ uElJmldata && initJmldata() },[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata();
	},[uJmlData]);
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);
	React.useEffect(()=>{
		if(!uIsShowhistori) {
			setDatahistoriArr([]);setTabelIDPilih(0);
			return;
		}
	},[uIsShowhistori]);
	React.useEffect(()=>{
		if(!uIsShowForm) {
			initFormbayarAwal();
			return;
		}

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnDlgsimpan(document.getElementById("btnDialogSimpan"))
			document.getElementById("inptglbayar") &&
				document.getElementById("inptglbayar").focus();
		},350);
	},[uIsShowForm]);
	React.useEffect(()=>{
		if(!uIsDlglookupshow) {
			setInpDlglookup(""); setDataDlglookupArr([]);
			return
		}
	},[uIsDlglookupshow]);
	React.useEffect(()=>{
		if((parseInt(uInpNilaiBayar)||0) < 0) 
			{ setInpNilaibayar((parseInt(uInpNilaiBayar)||0) * -1); return; }
		if((parseInt(uTabelIDPilih)||0) <= 0) return; 

		const vIdx 	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if((parseInt(uInpNilaiBayar)||0) > (parseInt(uDatamainArr[vIdx].nilai_hutang)||0) - (parseInt(uDatamainArr[vIdx].terbayar)||0)) 
			setInpNilaibayar((parseInt(uDatamainArr[vIdx].nilai_hutang)||0) - (parseInt(uDatamainArr[vIdx].terbayar)||0));
	},[uInpNilaiBayar]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)

	return (
		<>
		<CCard card="classcardbg mb-3">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_hutangbeliaset || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block">{uBahasaObj.caption_daftarhutang||"Daftar Hutang"}</span>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlglookupheader||"uDlglookupheader"}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikFormSimpan()}
			show={uIsShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowhistori} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleHistori}
			renderContent={contentHistori()}/>
		</>
	)
}
	
export default Hutangbeliaset;
