import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	//CForm,CInputGroup,CFormInput,CButton,
	CTooltip,
	CLink,
	CImage,
} from '@coreui/react'
//import CIcon from '@coreui/icons-react'
//import { UFunc } from '../../helpers/functions'
import { Konfirm } from '../../helpers/onConfirm';

const pjson 	= require('../../../package.json')
const Headersubrole = (props) => {
	/*const {
		setToken,setLoading, showToast 
	} = props//-*/ 

	//--DOM--/
	//const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uActiveroute		= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uEfekapp			= useSelector(state => state.effectApp);

	//--HANDEL--/
	const hdlKlikReset=async()=>{
		const prosesReset = () => {
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
	}

	//console.log("Headersubrole - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
				className="me-1" height={30}/>
			<small><i>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||"List Data"}</i></small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
			</CTooltip>
		</div>
		</>
	)
}

export default Headersubrole
