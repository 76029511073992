import React from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { 
	CCollapse,
	CButton,CLink,
	CRow,CCol,
	CNav, CNavItem, CNavLink,
	CTabContent, CTabPane,
	CSpinner,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
//import PropTypes from "prop-types";
import { UFunc } from "../helpers/functions";
import { isMobile } from "react-device-detect";
import { 
	MyDatepicker,
} from '../components/index';

const pjson	= require('../../package.json');
const MyCollapsejadwal = (props) => {
	const {
		onClose, show, tglcek, setSessionaktif, prosesExpired, ...rest
	} = props;
	
	const uCollapseshow					= show;
	const uTanggalload					= tglcek;
	const uBahasaObj  					= useSelector(state => state.listBahasa);
	const [uISCekloading,setCekloading] = React.useState(false);
	const [uEfekview,setEfekview] 		= React.useState(false);

	const uTokenObj						= JSON.parse(localStorage.getItem("token") || "{}");
	const uLayananArr					= JSON.parse(localStorage.getItem("layananlist")||"[]");
	const [uHTMLError500,setHTMLError500]= React.useState(null);
	const [uDatacekObj,setDatacekObj]	= React.useState({});
	const [uNotelayanan,setNotelayanan]	= React.useState("");
	const uTanggalcek					= uDatacekObj.tgl_cek;
	const uDatajadwalArr				= uDatacekObj.jadwal_arr || [];
	/*const uDataviewArr					= parseInt(uDatacekObj.layanan_id||"0") <= 0
		? uDatajadwalArr : uDatajadwalArr.filter(vItems => 
			parseInt(vItems.layanan_id) === parseInt(uDatacekObj.layanan_id));//-*/

	const contentMain = () => {
		if(!uCollapseshow) return "";

		return (
		<div style={{minHeight:300}}
			className="p-0 position-relative d-block">
			<CLink
				className="position-absolute classcursorpointer top-0 end-0 text-danger fw-bolder" 
				style={{zIndex:1}}
				onClick={onClose}>
				<CIcon icon="cilXCircle" height={30}/>
			</CLink>

			<div className="start-0 rounded-3 position-absolute"
				style={{top:10,border:"7px #909090 solid",width:"99%",backgroundColor:"#D0D0D0"}}>
			<div id="iddivcektgl" className="mt-2 px-2 pb-2 text-center classborderbottom">
				<MyDatepicker
					tglValue={uDatacekObj.tgl_cek_dt}
					onChangevalue={(date) => {uDatacekObj.tgl_cek_dt = date; setEfekview(!uEfekview)}}
					id="inptglcek"/>

				{isMobile && (
				<CDropdown autoclose={true}>
				<CDropdownToggle color="transparent" className="p-0 m-0" 
					caret={false}>
					<CIcon icon="cilChevronBottom" className="classikoncoreui" height={30}/>
				</CDropdownToggle>
				<CDropdownMenu className="py-1">
					<CDropdownItem className="d-flex justify-content-between classfontsmaller"
						onClick={()=>{uDatacekObj.layanan_id = 0;setEfekview(!uEfekview)}}>
						<span className="">{uBahasaObj.word_semua||"word_semua"}</span>
						<b>[{UFunc.formatAngka(uDatajadwalArr.length)}]</b>
					</CDropdownItem>
					{uLayananArr.map((vItems,vKeys)=>{
						const vJmldata = uDatajadwalArr.filter(vItemdata =>
							parseInt(vItemdata.layanan_id) === parseInt(vItems.id)).length;

						return (
						<CDropdownItem key={vKeys} className="d-flex justify-content-between classfontsmaller"
							onClick={()=>{uDatacekObj.layanan_id = vItems.id;setEfekview(!uEfekview)}}>
							<span className="">{vItems.caption_layanan}</span>
							<b className="ps-2">[{UFunc.formatAngka(vJmldata)}]</b>
						</CDropdownItem>
						)
					})}
				</CDropdownMenu>
				</CDropdown>
				)}
			</div>

			{uNotelayanan !== "" && (
			<div style={{borderStyle:"dashed",borderRadius:2}} className="mb-2 py-1 classfontsmaller border-danger text-center">
				<marquee className="d-none d-lg-block">
				<small className="text-danger fst-italic">&middot; {UFunc.renderHTML(uNotelayanan)} &middot;</small>
				</marquee>

				<small className="text-danger fst-italic d-lg-none">&middot; {UFunc.renderHTML(uNotelayanan)} &middot;</small>
			</div>
			)}

			<div id="iddivceknav" className="px-2 py-1 bg-light classborderbottom">
				{!isMobile ? (
				<CNav variant="pills" role="tablist" className="d-flex justify-content-center">
				<CNavItem>
				<CNavLink className="p-1 classcursorpointer" 
					active={parseInt(uDatacekObj.layanan_id) <= 0} 
					color="warning"
					onClick={()=>{uDatacekObj.layanan_id = 0;setEfekview(!uEfekview)}}>
					<small className="fw-bolder">{uBahasaObj.word_semua||"word_semua"} [{UFunc.formatAngka(uDatajadwalArr.length)}]</small>
				</CNavLink>
				</CNavItem>
				&middot;
				{uLayananArr.map((vItems,vKeys)=>{
					const vJmldata = uDatajadwalArr.filter(vItemdata =>
						parseInt(vItemdata.layanan_id) === parseInt(vItems.id)).length;

					return(
					<>
					<CNavItem key={vKeys}>
					<CNavLink className="p-1 classcursorpointer" 
						active={parseInt(uDatacekObj.layanan_id) === parseInt(vItems.id)} 
						onClick={()=>{uDatacekObj.layanan_id = vItems.id;setEfekview(!uEfekview)}}>
						<small>{vItems.caption_layanan} [{UFunc.formatAngka(vJmldata)}]</small>
					</CNavLink>
					</CNavItem>
					{(vKeys < uLayananArr.length-1) && (<>&middot;</>)}
					</>
					);
				})}
				</CNav>
				) : (
				<div className="text-center classfontsmaller"><small>
				{(()=>{
					const vArridx	= UFunc.getObjectIndeks(uLayananArr,uDatacekObj.layanan_id);
					if(vArridx < 0) return (
						<>{uBahasaObj.word_semua||"Semuax"} [{uDatajadwalArr.length}]</>
					)

					const vJmldata = uDatajadwalArr.filter(vItemdata =>
						parseInt(vItemdata.layanan_id) === parseInt(uLayananArr[vArridx].id)).length;

					return (
					<span style={{color:"#"+uLayananArr[vArridx].color_layanan}}>
						{uLayananArr[vArridx].caption_layanan} [{vJmldata}]
					</span>
					)
				})()}
				</small></div>
				)}
			</div>

			<div style={{height:(
					window.innerHeight
					- (parseInt(document.getElementById("iddivcektgl") && document.getElementById("iddivcektgl").offsetHeight)||0)
					- (parseInt(document.getElementById("iddivceknav") && document.getElementById("iddivceknav").offsetHeight)||0)
					- (40)
					)
				}} 
				className="overflow-auto d-none d-md-block">
				{contentDatacek()}
			</div>

			<div style={{height:(window.outerHeight - 105)}} 
				className="overflow-auto d-md-none">
				{contentDatacek()}
			</div>
			</div>
		</div>
		)
	}
	const contentDatacek = () => {
		if(uHTMLError500) return (
		<>{UFunc.renderHTML(uHTMLError500)}</>
		)

		if(uISCekloading) return (
			<div className="text-center py-5"><CSpinner color="warning"/></div>
		)

		if(uDatajadwalArr.length <= 0) return (
			<div className="text-center py-5 fst-italic classpetunjuk fw-bolder">
			{uBahasaObj.caption_dataempty || "Data Tidak Ada"}...
			</div>
		)

		let vKeyTab	= -1;
		return (
		<>
		<div className="d-none">{JSON.stringify(uLayananArr)}</div>
		<CTabContent>
		<CTabPane role="tabpanel" aria-labelledby="semua-tab" visible={(parseInt(uDatacekObj.layanan_id)||0)===0}>
		{uDatajadwalArr.map((vItems,vKeys)=>{
			const {
				id,nama_pasien,signalement,pemilik,layanan_value,layanan_id,tgl_booking
			} = vItems;

			const vArridx	= UFunc.getObjectIndeks(uLayananArr,layanan_id);
			const vBgColorLayanan = vArridx >= 0 ? uLayananArr[vArridx].color_layanan : "000000";
			const vCaptionLayanan = vArridx >= 0 ? uLayananArr[vArridx].caption_layanan : "(UNDF)";

			vKeyTab++;

			return (
			<div key={vKeys} className="py-2 classborderbottom"
				style={{backgroundColor:"#"+vBgColorLayanan+"22"}}>
			<CRow className="mx-0">
			<CCol xs="1" className="px-1 text-end" >{vKeys+1}.</CCol>
			<CCol xs="7" className="px-1">
				<span className="text-primary">{nama_pasien||"-"}</span>
				{(signalement||"")!=="" && (
				<>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller"><small className="fst-italic">{signalement}</small></span>
				</>
				)}
				<div><small className="fw-bolder">{pemilik||"-"}</small></div>
			</CCol>
			<CCol xs="4" className="px-2 text-end classfontsmaller">
				<small className="fw-bolder" style={{color:"#"+vBgColorLayanan+""}}>{vCaptionLayanan}</small>
			</CCol>
			</CRow>
			</div>
			);
		})}
		</CTabPane>

		{uLayananArr.map((vItems,vKeys)=>{
		return (
		<CTabPane role="tabpanel" visible={(parseInt(uDatacekObj.layanan_id)||0) === parseInt(vItems.id)}
			 key={vKeys}>
		<div style={{backgroundColor:"#"+vItems.color_layanan+"22"}}>
		{uDatajadwalArr.filter(vItemChk => 
			parseInt(vItemChk.layanan_id) === parseInt(vItems.id)).
			map((vItemfound,vKeyfound) => {
				const {
					id,nama_pasien,signalement,pemilik,layanan_value,layanan_id,tgl_booking
				} = vItemfound;

				vKeyTab++;

				return (
				<CRow className="mx-0 py-2 classborderbottom" key={vKeyTab}>
				<CCol xs="1" className="px-1 text-end">{vKeyfound+1}.</CCol>
				<CCol xs="11" className="px-1">
					<span className="text-primary">{nama_pasien||"-"}</span>
					{(signalement||"")!=="" && (
					<>
					<span className="mx-1">&middot;</span>
					<span className="classfontsmaller"><small className="fst-italic">{signalement}</small></span>
					</>
					)}
					<div><small className="fw-bolder">{pemilik||"-"}</small></div>
				</CCol>
				</CRow>
				);
		})}
		</div>
		</CTabPane>
		);
		})}
		</CTabContent>
		<div className="d-none">{window.outerHeight}</div>
		<div className="d-none">{parseInt(document.getElementById("iddivcektgl") && document.getElementById("iddivcektgl").offsetHeight)||0}</div>
		<div className="d-none">{parseInt(document.getElementById("iddivceknav") && document.getElementById("iddivceknav").offsetHeight)||0}</div>
		</>
		)
	}

	const apiLoadjadwal = () => {
		setHTMLError500();
		setNotelayanan("");
		uDatacekObj.jadwal_arr	= [];
		setCekloading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tglchecking : uTanggalcek,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_daftar/ld_checking";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setCekloading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				//console.log("apiLoadchecking - LEWAT 2");
				uDatacekObj.jadwal_arr = JSON.parse(output_string.datachecking || "[]");
				setNotelayanan(output_string.datanotelayanan || "");
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyCollapsejadwal - apiLoadjadwal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setCekloading(false);
			console.log("(MyCollapsejadwal - apiLoadjadwal) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}

	React.useEffect(()=>{
		return () => {
			setDatacekObj({});
			onClose();
		}
	},[]);
	React.useEffect(()=>{
		if(uCollapseshow === false) {
			setDatacekObj({});
			return;
		}
		if(uTanggalload === undefined) return;
		
		const vTglload			= uTanggalload || UFunc.DbDate();
		uDatacekObj.tgl_cek_dt  = UFunc.toDatetime(vTglload+" 00:00:00");
		//apiLoadjadwal();
	},[uCollapseshow,uTanggalload]);
	React.useEffect(()=>{
		if(uTanggalcek === undefined) return;

		apiLoadjadwal();
	},[uTanggalcek]);
	React.useEffect(()=>{
		if(uDatacekObj.layanan_id === undefined)
			uDatacekObj.layanan_id = 0;
	},[uDatacekObj,
	uDatacekObj.tgl_cek_dt,
	uDatacekObj.layanan_id,
	uTanggalload
	]);
	React.useEffect(()=>{
		uDatacekObj.tgl_cek = UFunc.DbDate(uDatacekObj.tgl_cek_dt); 
	},[uDatacekObj,
	uDatacekObj.tgl_cek_dt,
	]);

	//console.log("MyCollapsejadwal - uCollapseshow => "+(uCollapseshow))	
	return (
		<>
		<CCollapse horizontal={false} className="position-fixed top-0 end-0 d-none d-md-block" 
			style={{zIndex:1090,minWidth:300,width:"40%"}}
			horizontal={false}
			visible={uCollapseshow}>{contentMain()}
		</CCollapse>
		<CCollapse horizontal={false} className="position-fixed top-0 end-0 d-md-none" 
			style={{zIndex:1090,minWidth:300,width:"99%"}}
			horizontal={false}
			visible={uCollapseshow}>{contentMain()}</CCollapse>
		</>
	)
}

export default MyCollapsejadwal