import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CHeaderBrand,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import NumberFormat from 'react-number-format';
import {
	MyPagination,
	MyProfileDetil,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 		= require('../../package.json')
const Updatedlogs 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	const { id } 			= useParams(); 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uIsEOMExecuted			= useSelector(state => state.gIsEOMExecuted);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_tabel || 20);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	/*const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uTimeelapse,setTimeelapse]		= React.useState(0); 

	const [uDatamainObj,setDatamainObj]		= React.useState({})//-*/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	const uDatalogsArr	= [
		{version:"1.9.1",updated_arr:[
			{teks_menu:"Operasional >> Purchasing >> Pembelian Barang >> Form Input",teks_content:"Update BUG, Lookup Supplier, ketika hanya SATU SUPPLIER, id-nya Tidak Terpilih dengan Benar."},
			{teks_menu:"Kasir >> COPY NOTA",teks_content:"Update Fitur, Hapus NOTA yang S.Jurnal BELUM DONE, dikembalikan ke KASIR KLINIK."},
			{teks_menu:"Kasir >> Kasir Klinik",teks_content:"Update BUG, Jika Ada Transaksi Pihak Lain, CRONJOB Belum memproses NAMA-NAMA Pasien.<br/>Perbaikan Ketika MULTIPLE Pasien, Kadang tidak Menjadi Satu Grup Customer."},
			{teks_menu:"Laporan >> Operasional >> Lap.Kasir",teks_content:"Update BUG, Jika Pembayaran Pasien NOL, yang MUNCUL seharusnya NILAI BAYAR Bukan Nilai Transaksi."},
		],tgl_updated:"2024-11-12"},
		{version:"1.9.0",updated_arr:[
			{teks_menu:"Stok >> Stok Opname",teks_content:"Update Akses Untuk Input Stok Opname, hanya untuk SUPERUSER dan SUPERVISOR."},
			{teks_menu:"Laporan >> Operasional >> Lap.Penjualan",teks_content:"Update Fitur Laporan Penjualan dengan Item Penjualannya."},
			{teks_menu:"Laporan >> Laporan Cocokan >> Lap.Uang Muka",teks_content:"Update Fitur Laporan Uang Muka per Bulan."},
		],tgl_updated:"2024-11-02"},
		{version:"1.8.0",updated_arr:[
			{teks_menu:"Klinik >> Data Pasien >> Edit Data Pasien",teks_content:"Update Upload Foto. <br/>Resize Foto Sebelum upload."},
			{teks_menu:"Klinik >> Data Pasien >> Card Profil Pasien",teks_content:"Update Fitur Foto Dapat Diperbesar."},
			{teks_menu:"Klinik >> Data Pasien >> Lihat Rekam Medis >> Detil Rekam Medis",teks_content:"Update Upload Foto. Fitur Multiple File dalam SATU Sesi Upload.<br/>Resize Foto Sebelum upload."},
			{teks_menu:"Klinik >> Pasien Dalam Proses >> Input Pemeriksaan",teks_content:"Update Upload Foto. Fitur Multiple File dalam SATU Sesi Upload.<br/>Resize Foto Sebelum upload."},
			{teks_menu:"Kasir >> Kasir Klinik >> Input Pembayaran",teks_content:"Perbaikan BUG, Nilai Bruto dan Jumlah Nilai Item Tidak Sama, Harus TIDAK BISA Disimpan.<br/>Update BUG Ketika Simpan Bayar, Foto Ketika Dalam Proses Dipindah ke Data Rekam Medis."},
			{teks_menu:"Laporan >> Operasional >> Lap.Kasir",teks_content:"Update Replace Laporan Pendapatan Menjadi Laporan Kasir."},
		],tgl_updated:"2024-10-23"},
		{version:"1.7.2",updated_arr:[
			{teks_menu:"Operasional >> Inventaris dan Aset >> Pembelian ASET >> Input Pembelian",teks_content:"Perbaikan BUG Ketika Menambahkan Supplier Baru."},
			{teks_menu:"Dashboard",teks_content:"Perbaikan BUG Api Top 5 Piutang Customer dan Top 5 Hutang Supplier."},
		],tgl_updated:"2024-10-15"},
		{version:"1.7.1",updated_arr:[
			{teks_menu:"Operasional >> Inventaris dan Aset >> Pembelian ASET >> Input Pembelian",teks_content:"Perbaikan BUG Sisa Piutang."},
			{teks_menu:"Operasional >> Inventaris dan Aset >> Pembelian ASET",teks_content:"Perbaikan BUG Terbayar Jika Pembelian Langsung Dilunasi."},
			{teks_menu:"Laporan >> Operasional >> Laporan Trx Customer",teks_content:"Update Fitur Detil Histori Pembayaran."},
			{teks_menu:"Laporan >> Inventori >> Laporan Mutasi Stok",teks_content:"Update Tambahan Fitur Laporan Mutasi Stok."},
			{teks_menu:"Laporan >> Keuangan >> Laporan Buku Besar",teks_content:"Request Update Fitur Dengan Filter Range Tanggal (Max:100 hari)."},
		],tgl_updated:"2024-10-09"},
		{version:"1.7.0",updated_arr:[
			{teks_menu:"Klinik >> Pasien >> Rekam Medis Pasien",teks_content:"Fitur Tambah/Edit/Hapus Reminder."},
			{teks_menu:"Operasional >> Hutang >> Hutang TJPL",teks_content:"Keterangan Transaksi Jasa Pihak Lain, Ditambah Nama Pasien."},
			{teks_menu:"Klinik >> Pasien Dalam Proses",teks_content:"BUG Kode Pasien Tidak Diisi Harus NULL."},
			{teks_menu:"Operasional >> Inventaris dan Aset >> Pembelian ASET >> Input Pembelian",teks_content:"Perbaikan Update tabel Item, menghapus Nilai NETTO, karena menimbulkan BUG. Cukup dengan JUMLAH TRANSAKSI saja."},
		],tgl_updated:"2024-10-03"},
		{version:"1.6.1",updated_arr:[
			{teks_menu:"Operasional >> Karyawan >> PAYROLL >> Detil Payroll",teks_content:"Koreksi Jurnal Reklas Tagihan BPJS.<br/>Mengaktifkan Fitur Export-to-Excel."},
		],tgl_updated:"2024-09-10"},
		{version:"1.6.0",updated_arr:[
			{teks_menu:"Update Logs",teks_content:"Menu Fitur Untuk menampilkan Log-log Perubahan pada Aplikasi."},
			{teks_menu:"Operasional >> Keuangan >> Biaya Perusahaan >> Input Biaya",teks_content:"Update Fitur Load GL Biaya Diperbaiki, sehinggga yang Baru masuk BIAYA OPERASIONAL.<br/>Update Default RESET dan BACK."},
			{teks_menu:"Operasional >> Tutup Periode",teks_content:"Tombol Fitur Untuk Pembatalan Status DALAM Proses EOD/EOM/EOY. Agar Transaksi Berikutnya Dapat Berjalan Kembali."},
			{teks_menu:"LAPORAN >> Operasional >> Lap.Hutang",teks_content:"Fitur Laporan Hutang Supplier Pembelian Barang dan Supplier Pembelian Aset."},
		],tgl_updated:"2024-09-06"},
		{version:"1.5.x",updated_arr:[
			{teks_menu:"Aplikasi",teks_content:"Seluruh Update Terakhir yang BELUM terdokumentasi."},
		],tgl_updated:"2024-09-03"},
	]
	const uJmlData	= uDatalogsArr.length;

	//---HANDEL--/
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentMain = () => {
		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);

		const vDataviewArr	= uDatalogsArr.slice(vMin,(vMax));

		return (
		<>
		{vDataviewArr.map((vItems,vKeys)=>{
			const vItemsArr	= vItems.updated_arr || [];

			return (
			<CCard className="mb-3 classcardbg" key={vKeys}>
			<CCardHeader className="d-flex justify-content-between">
				<div>Ver: <b>{vItems.version}</b></div>
				<div className="classfontsmaller"><small>{UFunc.HariAngka(vItems.tgl_updated)}</small></div>
			</CCardHeader>
			<CCardBody>
			<ul>
			{vItemsArr.map((vItemcontent,vKeycontent)=>{
				return (
				<li key={vKeycontent}>
					<div className="fw-bolder">{vItemcontent.teks_menu}</div>
					<small className="d-block fst-italic">{UFunc.renderHTML(vItemcontent.teks_content)}</small>
				</li>
				)//>
			})}
			</ul>
			</CCardBody>
			</CCard>
			)//>
		})}
		</>
		)//>
	}
	//---END CONTENT--/

	//---REST_API--/
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		
		return () => { setPageActive(1); }
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);
		setPageActive(uPageActive<=0 ? 1 : uPageActive);
	},[uJmlData]);
	//---END EFFECT--/

	//console.log("(Updatedlogs) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={()=>uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">
				Updated Logs<span className="d-none d-md-inline ms-1">{pjson.name}</span>
				</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
		<CContainer xxl className="px-0 mb-3">
			{contentMain()}
			{(uJmlData > uMaxData) && (
			<CCard>
			<CCardBody>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardBody>
			</CCard>
			)}
		</CContainer>
		</div>
		</div>
		</>
	) //>
}	

export default Updatedlogs