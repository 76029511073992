import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	CForm,
	CFormSelect,
	CImage,
} from '@coreui/react'
import { UFunc,cBulanPendek } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubdashboard = (props) => {
	//--DOM--/
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uActiveroute		= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uEfekapp			= useSelector(state => state.effectApp);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uKeyword,setKeyword] = React.useState(uKeywordObj.filter_dashboard || 
		UFunc.DbDate(new Date((new Date()).getFullYear(),(new Date()).getMonth(),1)));

	//--HANDEL--/
	const hdlKlikChange=(_VALUE)=>{
		//---SAVE_KEYWORD--/
		setKeyword(_VALUE);
		uKeywordObj.filter_dashboard = _VALUE; 
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/
		//setHeaderView(true);

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}

	React.useEffect(()=>{},[])

	//console.log("Headersubdashboard - uKeyword => "+(uKeyword));

	return (
		<>
		<div className="d-none d-lg-flex align-items-center">
			<CImage src={pjson.homepage+"api/images/icon_dashboard.png"} 
				className="me-1" height={30}/>
			<small><i>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||"UNDF"}</i></small>
		</div>

		<CForm className="mx-auto mx-lg-0">
			<CFormSelect size="sm"
				className="w-auto"
				style={{minWidth:150}}
				value={uKeyword}
				onChange={(e)=>hdlKlikChange(e.target.value)}
				id="inptglfilter">
			{([...new Array(6)].map((_,vKeys) => {
				const vTglNowDT = new Date();
				let vMonthcount	= vTglNowDT.getMonth() - vKeys;

				const vMonthidx = vMonthcount < 0 ? vMonthcount + 12 : vMonthcount;
				const vYear 	= vMonthcount < 0 ? vTglNowDT.getFullYear() - 1 : vTglNowDT.getFullYear();
				const vValue	= vYear+"-"+UFunc.leadZero(vMonthidx+1)+"-01";

				return (
				<option value={vValue} key={vKeys}>{cBulanPendek[vMonthidx].toUpperCase()} {vYear}</option>
				)
			}))}
			</CFormSelect>
		</CForm>
		</>
	)
}

export default Headersubdashboard
