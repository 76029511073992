import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CInputGroup,CInputGroupText,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,CTooltip,
	CDropdown,CDropdownItem,CDropdownToggle,CDropdownMenu,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyDialoglookup,
	MyPagination,
	MyPage500,
} from '../components/index'
import { UFunc, cKeycodebypass } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Payment 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uUserSettingObj 		= useSelector(state => state.gListUserSetting);
	const uIsEOMExecuted		= useSelector(state => state.gIsEOMExecuted);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uUserSettingObj.jml_mobile || 10) : (uUserSettingObj.jml_tabel || 30);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderactionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderactionObj.isHeaderView || false;
	const uHeaderTambah 		= uHeaderactionObj.isHeaderTambah || false;

	/*//---PAGINATION_DIATUR_BACKEND--/
	const uTabelSelectObj 		= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 		= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//---END PAGINATION_DIATUR_BACKEND--*/

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uDataposakunArr,setDataposakunArr]= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	//const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);

	//--DIALOG_FORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//--END DIALOG_FORM--*/

	//--DIALOG_FORM--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//--END DIALOG_FORM--*/

	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL); 
		const vCaptionupper = UFunc.Usercaptionfilter(uDatamainArr[vArridx].nama_metode,3).toUpperCase();

		const vRowIdx		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		uDlgformObj.dlg_header 	= "Edit Payment: "+vCaptionupper;
		uDlgformObj.tabelid 	= _IDTABEL;
		uDlgformObj.nama_metode= uDatamainArr[vArridx].nama_metode||"";
		uDlgformObj.gledger_caption= uDatamainArr[vArridx].gledger_caption||"";
		uDlgformObj.gledger_id	= uDatamainArr[vArridx].gledger_id||"0";

		setDlgformshow(true);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL); 
		const vCaptionupper = UFunc.Usercaptionfilter(uDatamainArr[vArridx].nama_metode,3).toUpperCase();

		const vRowIdx		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		if(await Konfirm("Hapus Data <b>"+vCaptionupper+"</b>.."+
			"<br/>Apakah Anda Yakin ?"+
			"")) {
			apiProseshapus(_IDTABEL);
		}
	}
	const hdlKDownGledgercaption = (_EV) => {
		if(!cKeycodebypass.includes(_EV.keyCode)) {
			uDlgformObj.gledger_id 		= 0;
		}

		if(_EV.keyCode !== 13) { setEfekview(!uEfekview); return; }
		if((uDlgformObj.gledger_caption||"").trim()==="") { setEfekview(!uEfekview); return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		const vDatacariArr 	= uDataposakunArr.filter((vItems)=>
			(vItems.caption||"").toUpperCase().includes((uDlgformObj.gledger_caption||"").toUpperCase())
		);

		if (vDatacariArr.length === 1) {
			uDlgformObj.gledger_id		= vDatacariArr[0].value;
			uDlgformObj.gledger_caption	= vDatacariArr[0].caption;
			setEfekview(!uEfekview);

			const vElfocus = document.getElementById("btnDialogSimpan");
			let vTimeout = setTimeout(() => {
				clearTimeout(vTimeout);
				vElfocus && vElfocus.focus();
			},350);

			return;
		} 

		hdlKlikGledgerlookup();
	}
	const hdlKlikGledgerlookup = () => {
		const vLookupArr	=  uDataposakunArr.map((vItems,vKeys)=>{
			return {
				id:vItems.value,
				caption:vItems.caption,
			};
		})
		uDlglookupObj.data_arr 	= vLookupArr;
		uDlglookupObj.inputvalue= uDlgformObj.gledger_caption||"";

		uDlglookupObj.dlg_header 	= ((uBahasaObj.word_lookup||"word_lookup")+
			": "+(uBahasaObj.caption_posakunkas||"Pos Akun KAS"));
		setDlglookupshow(true);
	}
	const hdlKlikDLglookuppilih = (_IDITEM) => {
		//_IDITEM = parseInt(_IDITEM);
		const vDatalookupArr = uDlglookupObj.data_arr || [];
		const vArridx = UFunc.getObjectIndeks(vDatalookupArr,_IDITEM,"id");
		if(vArridx < 0) return;

		const vElfocus = document.getElementById("btnDialogSimpan");
		uDlgformObj.gledger_id		= parseInt(vDatalookupArr[vArridx].id)||0;
		uDlgformObj.gledger_caption = (vDatalookupArr[vArridx].caption);

		setDlglookupshow(false)
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElfocus && vElfocus.focus();
		},350)
	}
	const hdlKlikDlgformsimpan = () => {
		//--VALIDASI--/
		let vElfocus = document.getElementById("inpnamapayment");
		if((uDlgformObj.nama_metode||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("Nama Metode Pembayaran Belum Diisi !");
			return;
		}
		vElfocus = document.getElementById("inpgledgercaption");
		if((uDlgformObj.gledger_caption||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("POS Akun Kas Belum Dipilih !");
			return;
		}
		if(parseInt(uDlgformObj.gledger_id||"0") <= 0) {
			vElfocus && vElfocus.focus();
			showToast("POS Akun Kas Belum Dipilih !");
			return;
		}
		//--END VALIDASI--/

		apiProsessimpan();
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_payment || "";
		/*if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} //-*/

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setEfekview(!uEfekview)
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_payment || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		/*//--PAGINATION_DIATUR_BACKEND--/
		const vDataviewArr	= uDatamainArr.filter(vItems=> parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		//--END PAGINATION_DIATUR_BACKEND--*/

		//--PAGINATION_DIATUR_FRONTEND--/
		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		//--END PAGINATION_DIATUR_FRONTEND--*/

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={35} className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="5" className="text-start">{uBahasaObj.caption_namapayment||"Nama Payment"}</CCol>
				<CCol md="5" className="text-start">{uBahasaObj.caption_posjurnal||"Pos Jurnal"}</CCol>
				<CCol md="2" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>
			<tr className="d-md-none classfontsmaller">
			<th width={35} className="px-1"/>
			<th className="px-1"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,nama_metode,gledger_id,gledger_caption,usercaption,tgl_input,
				is_processing
			} = vItems;

			vNumber++;
			const vCaptionupper	= UFunc.Usercaptionfilter((nama_metode||"Undf"),3).toUpperCase();

			const vNamapayment	= vKeyword==="" ? (nama_metode||"Undf") 
				: (nama_metode||"Undf").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vNamaGL	= vKeyword==="" ? (gledger_caption||"-") 
				: (gledger_caption||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter(usercaption,1) 
				: UFunc.Usercaptionfilter(usercaption,1).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");

			return (
			<tr id={"idtr"+id} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}
				key={vKeys}>
			<td className="text-end px-1 pe-0">{vNumber}.</td>
			<td className="text-end px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="5" className="px-1 text-start d-flex justify-content-between">
					<div>
					<div className="text-info text-capitalize">{UFunc.renderHTML(vNamapayment)}</div>
					<div className="text-danger classfontsmaller d-none d-md-block">
					<small>[
						{UFunc.WaktuAngka(tgl_input)}
						<span className="mx-1">&middot;</span>
						<span>{UFunc.renderHTML(vUserinput.toUpperCase())}</span>
					]</small>
					</div>
					</div>

					<div className="d-md-none">
					<CDropdown>
					<CDropdownToggle color="transparent" caret={false} className="p-0">
						<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
					</CDropdownToggle>

					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
						<CIcon className="classikonedit align-middle mx-0 me-2" height={25}/>
						{uBahasaObj.caption_editdata||"Edit Data"}
						</CDropdownItem>
						
						{(uIsAllowedhapus) && (
							<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
						)}
					</CDropdownMenu>
					</CDropdown>
					</div>
				</CCol>

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>

				<CCol xs="6" className="px-1 d-md-none text-start classfontsmaller">{uBahasaObj.caption_posjurnal||"Akun Pos Jurnal"}</CCol>
				<CCol xs="6" md="5" className="px-1 text-md-start fst-italic text-capitalize">
					{UFunc.renderHTML(vNamaGL)}
				</CCol>

				<CCol xs="6" className="px-1 d-md-none text-start classfontsmaller">{uBahasaObj.caption_inputby||"Input By"}</CCol>
				<CCol xs="6" md="5" className="px-1 d-md-none text-md-start classfontsmaller text-danger">
					<small>[
						{UFunc.WaktuAngka(tgl_input)}
						<span className="mx-1">&middot;</span>
						<span>{UFunc.renderHTML(vUserinput.toUpperCase())}</span>
					]</small>
				</CCol>
				
				<CCol md="2" className="px-1 text-md-center d-none d-md-block">
					<CTooltip className="d-none d-md-block" content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="classcursorpointer classikontabel classikonedit"/>
					</CTooltip>

					{uIsAllowedhapus && (
					<CTooltip className="d-none d-md-block" content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikHapus(id)} className="classcursorpointer classikontabel classikonhapus"/>
					</CTooltip>
					)}
				</CCol>
				</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		return (
		<CForm>
		<CRow className="my-2">
			<CCol xs="4" className="text-start text-lg-end pe-0 pe-md-2">
				<span className="d-none d-md-block">{uBahasaObj.caption_namapayment || "Nama Payment"}</span>
				<small className="d-md-none">{uBahasaObj.caption_namapayment || "Nama Payment"}</small>
			</CCol>
			<CCol className="text-end">
				<CFormInput size="sm"
					className="text-capitalize"
					value={uDlgformObj.nama_metode||""}
					onChange={(e)=>{uDlgformObj.nama_metode=e.target.value;setEfekview(!uEfekview)}}
				 	id="inpnamapayment"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-start text-lg-end pe-0 pe-md-2">
				<span className="d-none d-md-block">{uBahasaObj.caption_posjurnal || "Akun Pos Jurnal"}</span>
				<small className="d-md-none">{uBahasaObj.caption_posjurnal || "Akun Pos Jurnal"}</small>
			</CCol>

			<CCol className="text-end">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					placeholder="--Ekuivalensi Akun POS KAS.."
					className="text-capitalize"
					value={uDlgformObj.gledger_caption||""}
					onChange={(e)=>{uDlgformObj.gledger_caption=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>hdlKDownGledgercaption(e)}
				 	id="inpgledgercaption"/>
				<CButton size="sm" 
					color="dark"
					onClick={(e)=>hdlKlikGledgerlookup()}
					id="btnLookupgledger">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
		</CRow>
		</CForm>
		)//>
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = () => {
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_payment || "";
		/*//--PAGINATION_DIATUR_BACKEND--/
		_PAGE		= parseInt(_PAGE)||1;
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		//--END PAGINATION_DIATUR_BACKEND--*/

		//--PAGINATION_DIATUR_FRONTEND--/
		setJmlData(0);
		setDatamainArr([]);
		//setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);
		//--END PAGINATION_DIATUR_FRONTEND--*/
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			//send_pg	: _PAGE,//--PAGINATION_DIATUR_BACKEND-
			//send_maxdata	: uMaxData,//--PAGINATION_DIATUR_BACKEND-
			//send_firstload	: vHandelview,//--PAGINATION_DIATUR_BACKEND-
			send_firstload	: uFirstload,//--PAGINATION_DIATUR_FRONTEND
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payment/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Payment - apiProsessimpan) vTabelid : "+vTabelid);
				if(uFirstload === "YA")
					setDataposakunArr(JSON.parse(output_string.datakas||"[]"));

				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				/*//--PAGINATION_DIATUR_BACKEND--/
				vLoadArr.map(vItems=>vItems.pg = _PAGE);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}
				//--END PAGINATION_DIATUR_BACKEND--*/

				//--PAGINATION_DIATUR_FRONTEND--/
				setDatamainArr(vLoadArr);
				//--END PAGINATION_DIATUR_FRONTEND--*/

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payment-apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Payment-apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		const vTabelid	= parseInt(uDlgformObj.tabelid) || 0;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,vTabelid);
		const vElbutton	= document.getElementById("btnDialogSimpan");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelid,
			send_jsondata: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payment/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				console.log("(Payment - apiProsessimpan) vTabelid : "+vTabelid);
				if(vTabelid <= 0) {
					const vTmpObj 	= {
						id: output_string.tabelid || "255",
						nama_metode: uDlgformObj.nama_metode,
						gledger_caption: uDlgformObj.gledger_caption,
						gledger_id: uDlgformObj.gledger_id,
					}

					setDatamainArr([vTmpObj,...uDatamainArr]);

					uDlgformObj.nama_metode		= "";
					uDlgformObj.gledger_caption	= "";
					uDlgformObj.gledger_id		= "0";
					showToast(uBahasaObj.pesan_sukses || "Berhasil !","SUKSES");
				} else {
					uDatamainArr[vArridx].nama_metode 	= uDlgformObj.nama_metode;
					uDatamainArr[vArridx].gledger_caption = uDlgformObj.gledger_caption;
					uDatamainArr[vArridx].gledger_id 	= uDlgformObj.gledger_id;

					setDlgformshow(false);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payment-apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Payment-apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		const vTabelid	= parseInt(_IDTABEL) || 0;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,vTabelid);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
				
			uDatamainArr.splice(vArridx,1);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payment/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			if(output_string.sukses) {
				//console.log("(Payment - apiProsessimpan) vTabelid : "+vTabelid);
				uDatamainArr.splice(vArridx,1);
				//setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payment-apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Payment-apiProseshapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		//if(!uIsInitbefore) 
			apiLoaddata();

		return () => {
			setFirstload("YA");
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]); setDataposakunArr([]);
			setDlgformshow(false); setDlglookupshow(false);
		}
	},[])
	/*//--PAGINATION_DIATUR_BACKEND--/
	React.useEffect(()=>{
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Payment - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]); 
	//--END PAGINATION_DIATUR_BACKEND--*/

	//--PAGINATION_DIATUR_FRONTEND--/
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
	},[uDatamainArr,uDatamainArr.length])
	//--END PAGINATION_DIATUR_FRONTEND--*/
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderactionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		/*//--PAGINATION_DIATUR_BACKEND--/
		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);
		//--END PAGINATION_DIATUR_BACKEND--*/

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHeaderTambah) return;

		uHeaderactionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		if(uHTMLError500) return;

		uDlgformObj.dlg_header = "Tambah Metode Pembayaran";
		setDlgformshow(true);
	},[uHeaderTambah]);
	React.useEffect(()=>{
		if(!uISDlgformshow) { setDlgformObj({}); return }

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElfocus = document.getElementById("inpnamapayment");

			if(!isMobile) vElfocus && vElfocus.focus();
		},350);
	},[uISDlgformshow]);
	
	//console.log("Payment - uDatamainArr => "+JSON.stringify(uDatamainArr));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return "" }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )//>

	return (
	<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_payment || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.dlg_header||"Header Lookup"}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.data_arr||[]} 
			onKlikPilihItem={hdlKlikDLglookuppilih}
			id="iddialoglookup"/>

		<MyDialogform
			options={{centered:true}}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.dlg_header}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}
			id="iddialogform"/>
	</>
	)//>
}	

export default Payment