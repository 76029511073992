import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CForm,CFormInput,CInputGroup,
	CButton,
	CTooltip,
	CLink,
	CImage,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
//import { UFunc } from '../../helpers/functions'
import { Konfirm } from '../../helpers/onConfirm';

const pjson 	= require('../../../package.json')
const Headersubpayment = (props) => {
	/*const {
		setToken,setLoading,showToast 
	} = props;//-*/

	//--DOM--/
	//const uNavigate 		= useNavigate();
	const uDispatch 		= useDispatch();
	//--END DOM--/

	const uActiveroute		= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uEfekapp			= useSelector(state => state.effectApp);
	//const [uEfekview,setEfekview]= React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uKeyword,setKeyword] = React.useState(uKeywordObj.ks||"")

	//--HANDEL--/
	const hdlKDownkeyword = (_EV) => {
		if(_EV.which === 13) {
			_EV.stopPropagation();
			_EV.preventDefault();

			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_payment = uKeyword; 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikTambah = () => {
		uHeaderActionObj.isHeaderTambah = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_payment = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}

	//console.log("Headersubpayment - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-flex align-items-center">
			<CImage src={pjson.homepage+"api/images/menus_"+((uActiveroute||"").toLowerCase())+".png"}
				height={30} />
			<small className="ms-1 fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>

		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup 
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CFormInput size="sm" 
					value={uKeyword} 
					onChange={(e) => setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKDownkeyword(e)} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} 
					id="inpkeyword"/>
				<CButton size="sm" color="light" className="border" onClick={()=>hdlKlikCari()} id="btnCari">
				<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
			</CForm>

			<div className="ms-1">
				<span className="me-1 d-none d-lg-inline">&middot;</span>

				<CTooltip className="d-none d-lg-block" content={uBahasaObj.caption_tambahdata || "Tambah Data Payroll"}>
					<CLink className="classikon classikontambah classcursorpointer" 
						onClick={()=>hdlKlikTambah()}/>
				</CTooltip>
			
				<span className="d-none d-md-inline">
				<span className="d-none d-lg-inline mx-1">&middot;</span>
				<CTooltip className="d-none d-lg-block" content={uBahasaObj.caption_resetview || "caption_resetview"}>
					<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
				</CTooltip>

				<span className="d-none d-md-inline me-2">&middot;</span>
				<b id="idjmldata">0</b>{" "}{uBahasaObj.word_data||"word_data"}
				</span>
			</div>
		</div>
		</>
	)
}

export default Headersubpayment
