import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CCardHeader,
	CCard,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CFormTextarea,
	CFormSelect,
	CBadge,
	CSpinner,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { isMobile } from "react-device-detect";
import { 
	MyPage500,
	MyPagination,
	MyDialogform
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';

const pjson		= require('../../package.json');
const Karyawan 	= (props) => {
	const uComponentname 	= Karyawan.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;//-->hanya untuk API
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	=useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uSettingObj 		= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);

	//---VAR_GLOBALS--/
	const uIsAllowedhapus	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN");
	const uMaxData			= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpKaryawannama,setInpKaryawannama] 	= React.useState("");
	const [uInpTelphp,setInpTelphp]			= React.useState("");
	const [uInpAlamat,setInpAlamat]			= React.useState("");
	const [uInpPosisi,setInpPosisi]			= React.useState("");

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata	= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//console.log("("+uComponentname+") uHeaderActionObj.isHeaderView: "+uHeaderActionObj.isHeaderView);
	//console.log("("+uComponentname+") uHandelView: "+uHandelView);

	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowForm(false); }
	const hdlKlikSimpan=()=>{
		//--VALIDASI_SIMPAN--/
		if(uInpKaryawannama.trim() === "") {
			document.getElementById("inpkaryawannama").focus();
			showToast((uBahasaObj.caption_namakaryawan||"caption_namakaryawan")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
			return;
		}
		//--END VALIDASI_SIMPAN--/

		apiProsessimpan();
	}
	const hdlKlikUrutan=(_IDTABEL,_ACTION)=>{
		setTabelIDPilih(_IDTABEL);
		apiProsesurutan(_IDTABEL,_ACTION);
	}
	const hdlKlikEdit=async(_IDTABEL)=>{

		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setInpKaryawannama(uDatamainArr[vObjdx].nama);
		setInpTelphp(uDatamainArr[vObjdx].telp_hp||"");
		setInpAlamat(UFunc.br2nl(uDatamainArr[vObjdx].alamat||""));
		setInpPosisi((uDatamainArr[vObjdx].posisi||""));
		setDialogHeader((uBahasaObj.caption_editdata||"caption_editdata")+": "+(uDatamainArr[vObjdx].nama || "").toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}
	const hdlKlikHapus=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>"+uDatamainArr[vObjdx].nama.toUpperCase()+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiProseshapus(_IDTABEL);
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initJmldata=()=>{
		const vKeyword = uKeywordObj.kwd_karyawan || "";
		if (vKeyword.length >= 3) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFormAwal=()=>{
		setTabelIDPilih(0);

		setInpKaryawannama("");
		setInpTelphp("");
		setInpAlamat("");
		setInpPosisi("");
	}
	//---END Init_LOAD--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		uFuncname			= contentMain.name;

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_karyawan || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		
		//console.log("("+uComponentname+"-"+uFuncname+")");

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={40} className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="3" className="px-0 text-start">{uBahasaObj.caption_namakaryawan||"caption_namakaryawan"}</CCol>
				<CCol md="3" className="px-0">{uBahasaObj.word_posisi||"word_posisi"}</CCol>
				<CCol md="3" className="px-0 text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="3" className="px-0">&middot;</CCol>
				</CRow>
			</th>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,telp_hp,nama,alamat,posisi,urutan,is_processing
			} = vItems;

			vNumber++;
			const vCaptionUpper	= nama.toUpperCase();

			const vKaryawannama	= vKeyword==="" ? (nama) 
				: (nama).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTelphp= vKeyword==="" ? (telp_hp||"-") 
				: (telp_hp||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vAlamat= vKeyword==="" ? (alamat||"-") 
				: (alamat||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vPosisi= vKeyword==="" ? (posisi||"-") 
				: (posisi||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			const vUrutan	= parseInt(urutan) || 0;
				
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
					<CRow className="mx-0">
					<CCol xs="12" md="3" className="px-1 text-start d-flex justify-content-between">
						<div className="text-info text-capitalize">{UFunc.renderHTML(vKaryawannama)}</div>

							
						<div className="d-md-none">
						{(is_processing||false)===true ? (
							<CSpinner color="primary" size="sm"/>
						) : (
						<>
						{uIsAllowedhapus && (
						<>
							{vUrutan > 1 && (
							<CTooltip content={"--"+(uBahasaObj.petunjuk_ubahurutankeatas || "petunjuk_ubahurutankeatas")+": "+vCaptionUpper}>
							<CLink onClick={()=>hdlKlikUrutan(id,"UP")} className="classcursorpointer classikontabel classikonup"/>
							</CTooltip>
							)}

							{vUrutan < uJmlData && (
							<CTooltip content={"--"+(uBahasaObj.petunjuk_ubahurutankebawah || "petunjuk_ubahurutankebawah")+": "+vCaptionUpper}>
							<CLink onClick={()=>hdlKlikUrutan(id,"DOWN")} className="classikontabel classikondown classcursorpointer"/>
							</CTooltip>
							)}

							<span className="mx-1">&middot;</span>
						</>
						)}

						<CDropdown>
						<CDropdownToggle caret={false} className="p-0" color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu className="py-0">
							<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
							<CIcon className="classikonedit align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_editdata||"Edit Data"}
							</CDropdownItem>
							
							{uIsAllowedhapus && (
							<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
							)}
						</CDropdownMenu>
						</CDropdown>
						</>
						)}
						</div>
					</CCol>

					<CCol xs="12" className="d-md-none my-3 px-1 classborderbottom"/>
					
					<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.word_posisi||"word_posisi"}</CCol>
					<CCol xs="7" md="3" className="px-1 text-md-center">
						<small>{UFunc.renderHTML(vPosisi)}</small>
					</CCol>

					<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_alamat||"word_alamat"}</CCol>
					<CCol xs="8" md="3" className="px-1 text-md-start">
						{(alamat||"")!=="" ? (
							<span className="fst-italic classfontsmaller"><small>{UFunc.renderHTML(vAlamat)}</small></span>
						): (<div className="">-</div>)}

						{(telp_hp||"").length>=3 && (
							<div className="d-none d-md-block classfontsmaller">
								<small>
								{uBahasaObj.caption_telphp||"caption_telphp"}:
								<span className="text-success ms-1">
									{UFunc.renderHTML(vTelphp)}
								</span>
								</small>
							</div>
						)}
					</CCol>

					<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_telphp||"caption_telphp"}</CCol>
					<CCol xs="7" className="px-1 d-md-none">
						<span className="text-success">{UFunc.renderHTML(vTelphp.toUpperCase())}</span>
					</CCol>

					<CCol md="3" className="px-1 text-center d-none d-md-block">
						{(is_processing||false)===true ? (
							<CSpinner color="primary" size="sm"/>
						) : (
						<>
							{uIsAllowedhapus && (
							<>
							{vUrutan > 1 && (
							<>
							<CTooltip content={"--"+(uBahasaObj.petunjuk_ubahurutankeatas || "petunjuk_ubahurutankeatas")+": "+vCaptionUpper}>
							<CLink onClick={()=>hdlKlikUrutan(id,"UP")} className="classcursorpointer classikontabel classikonup"/>
							</CTooltip>
							</>
							)}

							{vUrutan < uJmlData && (
							<>
							<CTooltip content={"--"+(uBahasaObj.petunjuk_ubahurutankebawah || "petunjuk_ubahurutankebawah")+": "+vCaptionUpper}>
							<CLink onClick={()=>hdlKlikUrutan(id,"DOWN")} className="classikontabel classikondown classcursorpointer"/>
							</CTooltip>
							</>
							)}

							<span className="mx-1">&middot;</span>
							</>
							)}

							<CTooltip content={"--"+(uBahasaObj.caption_editdata || "caption_editdata")+": "+vCaptionUpper}>
							<CLink onClick={()=>hdlKlikEdit(id)} className="classcursorpointer classikontabel classikonedit"/>
							</CTooltip>

							{uIsAllowedhapus && (
							<>
							<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
							<CLink 
								className="classikontabel classikonhapus "
								onClick={()=>hdlKlikHapus(id)} />
							</CTooltip>
							</>
							)}
						</>
						)}
					</CCol>
					</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!isShowForm) return (<></>);

		return (
			<>
			<CForm className="mx-lg-5 px-lg-5">
			<CRow className="my-2">
				<CCol xs="12" md="4" className="text-info text-md-end">
					{uBahasaObj.caption_namakaryawan||"caption_namakaryawan"}
				</CCol>
				<CCol>
					<CFormInput maxLength="100"
						type="text"
						size="sm"
						className="form-control text-capitalize"
						value={uInpKaryawannama}
						onChange={(e) => setInpKaryawannama(e.target.value)}
						id="inpkaryawannama"/>
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
				<CCol xs="4" className="text-md-end">
					{uBahasaObj.word_posisi||"word_posisi"}
				</CCol>
				<CCol>
				<CFormInput maxLength={50}
					type="text"
					size="sm"
					className="form-control text-uppercase"
					value={uInpPosisi}
					onChange={(e) => setInpPosisi(e.target.value)}
					id="inpposisi" />
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-md-end">
					{uBahasaObj.caption_telphp||"caption_telphp"}
				</CCol>
				<CCol >
				<CFormInput maxLength={50}
					type="text"
					size="sm"
					className="form-control text-uppercase"
					value={uInpTelphp}
					onChange={(e) => setInpTelphp(e.target.value)}
					id="inptelphp" />
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-md-end">
					{uBahasaObj.word_alamat||"word_alamat"}
				</CCol>
				<CCol>
					<CFormTextarea
						size="sm"
						className="form-control"
						value={uInpAlamat}
						onChange={(e) => setInpAlamat(e.target.value)}
						onKeyDown={(e) => UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpalamat" />
					<div className="classpetunjuk">*) {uBahasaObj.petunjuk_textarea||"petunjuk_textarea"}</div>
				</CCol>
			</CRow>
			</CForm>
			</>
		)
	}
	//--END CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = () => {
		uFuncname = apiLoaddata.name;
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_karyawan || "";

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);
		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_karyawan/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDatamainArr(vTmpObj);
				}
				setJmlData((parseInt(vTmpObj.length)||0));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		/*//--TESTING_FRONTEND--//
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_karyawannama : uInpKaryawannama,
			send_telphp : uInpTelphp,
			send_alamat : uInpAlamat,
			send_posisi : uInpPosisi,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_karyawan/pr_simpan";

		setLoading(true);
		uElBtnDlgsimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnDlgsimpan.disabled = false;

			if(output_string.sukses) {
				let vDatamainArrNew;
				if(uTabelIDPilih <= 0) {
					const vTmpObject	= {
						id 		: output_string.tabelid || "255",
						nama 	: UFunc.capitalize(uInpKaryawannama.trim()),
						telp_hp : (uInpTelphp||"").trim(),
						posisi : UFunc.capitalize((uInpPosisi||"").trim()),
						alamat 	: UFunc.nl2br(UFunc.capitalize((uInpAlamat||"").trim())),
						urutan	: (uJmlData+1).toString(),
					}
					vDatamainArrNew = [...uDatamainArr,vTmpObject];
				} else {
					vDatamainArrNew = uDatamainArr;
					const vObjdx				= UFunc.getObjectIndeks(vDatamainArrNew,uTabelIDPilih);
					vDatamainArrNew[vObjdx].nama 	= UFunc.capitalize(uInpKaryawannama.trim());
					vDatamainArrNew[vObjdx].telp_hp = (uInpTelphp||"").trim();
					vDatamainArrNew[vObjdx].alamat 	= UFunc.nl2br(UFunc.capitalize((uInpAlamat||"").trim()));
					vDatamainArrNew[vObjdx].posisi 	= UFunc.capitalize((uInpPosisi||"").trim());
				}

				setDatamainArr(vDatamainArrNew);
				initFormAwal();

				if(uTabelIDPilih > 0) {
					setEfekview(!uEfekview);
					hdlToggleForm();
				}  else {
					setTabelRowSelect(-1);
					setJmlData((parseInt(vDatamainArrNew.length)||0));
					showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
					setPageActive(1);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtnDlgsimpan.disabled = true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			uElBtnDlgsimpan.disabled = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		uFuncname 	= apiProseshapus.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_karyawan/pr_hapus";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesurutan = (_IDTABEL,_ACTION) => {
		uFuncname 			= apiProsesurutan.name;
		var vObjidx			= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vUrutanBefore	= parseInt(uDatamainArr[vObjidx].urutan) || 1;
		var vUrutanNew;
		var vIdxNew;

		uDatamainArr[vObjidx].is_processing = true;
		setEfekview(!uEfekview);
		/*//---TESTING_FRONTEND--/
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_action : _ACTION,
			send_urutanold : vUrutanBefore.toString(),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_karyawan/pr_urutan";

		//setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vObjidx].is_processing = false;

			if(output_string.sukses) {
				setTabelIDPilih(0);
				if(_ACTION=="UP") {
					vUrutanNew	= vUrutanBefore--;
					vIdxNew		= vObjidx--;
				}else {
					vUrutanNew	= vUrutanBefore++;
					vIdxNew		= vObjidx++;
				}
				var vTmpObj 	= uDatamainArr[vIdxNew];
				uDatamainArr[vIdxNew]= uDatamainArr[vObjidx];
				uDatamainArr[vObjidx]= vTmpObj;

				uDatamainArr[vIdxNew].urutan 	= vUrutanNew.toString();
				uDatamainArr[vObjidx].urutan 	= vUrutanBefore.toString();
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjidx].is_processing = false;
			setEfekview(!uEfekview);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setShowForm(false);
    		setDatamainArr([]);
    	}
	},[]);
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;

		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(isShowForm) return;

		initFormAwal();
		setDialogHeader((uBahasaObj.word_tambah||"word_tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setShowForm(true);
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!isShowForm) 
			{ initFormAwal(); return; }
		
		//console.log("("+uComponentname+"[isShowForm]) isShowForm 1: "+isShowForm);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnDlgsimpan(document.getElementById("btnDialogSimpan"))
			const vElFocus = document.getElementById("inpkaryawannama");
			if(!isMobile) vElFocus && vElFocus.focus();
		},350);
	},[isShowForm]);
	React.useEffect(()=>{ uElJmldata && initJmldata() },[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_karyawan || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block"/>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikSimpan()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={contentDlgform()}/>
		</>
	)
}
	
export default Karyawan;
