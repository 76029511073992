import React from 'react'
//import { useDispatch } from 'react-redux'
import { 
	ContContent,
	ContSidebar,
	ContFooter,
	ContHeader,
	ContMasterdata,
} from '../containers/index'

const Layout = (props) => {
	//const { setToken, setLoading,showToast } = props; 

	//console.log("Layout - PASSED")
	return (
		<>
		<ContSidebar {...props}/>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
			<ContHeader {...props}/>
			<div className="body flex-grow-1 px-2 px-lg-4 mb-3" id="idmainisi">
				<ContContent {...props}/>
			</div>
			<ContFooter {...props}/>
		</div>
		<ContMasterdata {...props}/>
		</>
  )
}

export default Layout