import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CHeaderBrand,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormTextarea,CInputGroup,CInputGroupText,CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import NumberFormat from 'react-number-format';
import {
	MyButton,
	MyFormHapus,
	MyProfileDetil,
	MyPage500,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Hutangkonsipreviewpreview = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	//const { id } 			= useParams(); 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uUserSettingObj			= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uIsEOMExecuted			= useSelector(state => state.gIsEOMExecuted);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uUserSettingObj.jml_mobile || 10) : (uUserSettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= 
		(uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") &&
		(uIsEOMExecuted === true);
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);

	const [uInpKeyword,setInpKeyword]		= React.useState(uKeywordObj.kwd_hutangkonsibayar||"")
	const [uHandelview,setHandelview]		= React.useState(false);
	const [uDatamainArr,setDatamainArr]		= React.useState([])

	//---PAGINATION_DIATUR_BACKEND--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//---END PAGINATION_DIATUR_BACKEND--/

	//--DLG_FORM--/
	const [uISDlghapusshow,setDlghapusshow]	= React.useState(false)
	const [uDlghapusObj,setDlghapusObj]		= React.useState({})
	//--END DLG_FORM--/

	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDEL--/
	const hdlKDownkeyword = (_EV) => {
		if(_EV.which === 13) {
			_EV.preventDefault();
			_EV.stopPropagation();

			document.getElementById("btnCari") && 
				document.getElementById("btnCari").click();

			return;
		}
	}
	const hdlKlikCari = () => { setHandelview(true); }
	const hdlKlikReset = async() => {
		const vNextfunction = () => {
			setInpKeyword("");

			console.log("(hdlKlikReset) => LEWAT");
			document.getElementById("btnCari") && 
				document.getElementById("btnCari").click();
		}

		if(uInpKeyword.trim() !== "") {
			if(await Konfirm("Tampilan Data Akan DiRESET.."+
				"<br/><b>Apakah Anda Yakin ?</b>"+
				"")) vNextfunction();
		} else vNextfunction();
	}
	const hdlKlikHapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		uDlghapusObj.header 	= "Hapus Data: "+(uDatamainArr[vArridx].supplier_caption||"").toUpperCase();
		uDlghapusObj.tabelid 	= _IDTABEL;
		setDlghapusshow(true);
	}
	const hdlKlikDlghapussimpan = () => {
		//--VALIDASI--/
		if(parseInt(uDlghapusObj.tabelid||"0") <= 0) { return }

		let vElfocus = document.getElementById("inpkethapus");
		if((uDlghapusObj.inputvalue||"").trim().length < 3) {
			vElfocus && vElfocus.focus();
			showToast(uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi"); return;
		}
		//--END VALIDASI--/

		apiProseshapus();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const initJmldata = () => {
		const vKeyword = (uInpKeyword|| "").trim();
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} 

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_hutangkonsibayar||"");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=> parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={30} className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="text-start">{uBahasaObj.caption_tglbayar||"Tgl Bayar"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.caption_namasupplier||"Nama Supplier"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_kasasal||"Kas Asal"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_nilaibayar||"Nilai Bayar"}<small className="d-block">(Rp)</small></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none">
				<th width={30} className="px-0"/>
				<th className="px-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,supplier_caption,nilai_bayar,tgl_bayar,keterangan,kas_caption,is_loading,
				userinput,tgl_input,status_jurnal,init_expired
			} = vItems;

			const vInitexpired = init_expired || "TIDAK"; //--BIAR_BISA_TEST_MODE

			vNumber++;
			const vCaptionUpper	= ((supplier_caption||"Undf")).toUpperCase();

			const vTglbayar	= vKeyword==="" ? UFunc.TglAngka(tgl_bayar) 
				: (UFunc.TglAngka(tgl_bayar)).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vSuppliercaption	= vKeyword==="" ? (supplier_caption||"Undf") 
				: ((supplier_caption||"Undf")).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vKeterangan	= vKeyword==="" ? (keterangan||"-") 
				: ((keterangan||"-")).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vKascaption	= vKeyword==="" ? (kas_caption||"-") 
				: ((kas_caption||"-")).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter(userinput||"Undf") 
				: (UFunc.Usercaptionfilter(userinput||"Undf")).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTglinput	= vKeyword==="" ? UFunc.TglAngka(tgl_input) 
				: (UFunc.TglAngka(tgl_input)).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");

			const vColorstatustembak= (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";

			return (
			<tr className={"align-top text-end"+(vKeys===uTabelRowSelect?" classrowselect":"")} id={"idtr"+id}
				key={vKeys}>
			<td className="px-0 pe-1">{vNumber}.</td>
			
			<td className="px-1">
			<CRow className="mx-0">
				<CCol xs="12" md="2" className="px-1 text-start d-flex justify-content-between">
				<div>
					<span className="text-info align-middle">{UFunc.renderHTML(vTglbayar)}</span>
					{uTokenObj.userhak==="FREEROLE" && (
					<span className="ms-1 classfontsmaller fst-italic">
					<small>&middot; ({id})</small>
					</span>
					)}
					
					<div className="text-danger classfontsmaller d-none d-md-block">
					<small>[
					{UFunc.TglAngka(tgl_bayar)!==UFunc.TglAngka(tgl_input) && (
					<>
						{UFunc.renderHTML(vTglinput)}
						<span className="mx-1">&middot;</span>
					</>
					)}
					{UFunc.renderHTML(vUserinput)}
					]</small>
					</div>
				</div>

				{(uIsHapusallow && vInitexpired==="TIDAK") && (
					<CLink className="classikontabel classikonhapus classcursorpointer d-md-none"
						onClick={()=>hdlKlikHapus(id)}/>
				)}
				</CCol>

				<CCol md="12" className="px-1 my-3 text-start d-md-none classborderbottom"/>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_supplier}</CCol>
				<CCol xs="8" md="3" className="px-1 text-md-start">
					{UFunc.renderHTML(vSuppliercaption)}

					{((status_jurnal||"")!=="" && uTokenObj.userhak==="FREEROLE") && (
					<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
					</div>
					)}
				</CCol>
				
				<CCol xs="4" className="px-1 text-start d-md-none classfontsmaller">{uBahasaObj.word_keterangan}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-start">
					<small className="fst-italic">{UFunc.renderHTML(vKeterangan)}</small>
				</CCol>
				
				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_kasasal||"Kas Asal"}</CCol>
				<CCol xs="8" md="2" className="px-1 px-md-0 text-md-center fw-bolder">{UFunc.renderHTML(vKascaption)}</CCol>
				
				<CCol md="12" className="px-1 my-3 text-start d-md-none classborderbottom"/>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal||"-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="4" className="px-1 text-start d-md-none classfontsmaller">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
				<CCol xs="8" md="2" className="px-1 d-md-none classfontsmaller text-danger">
					<small>[
					{UFunc.TglAngka(tgl_bayar)!==UFunc.TglAngka(tgl_input) && (
					<>
						{UFunc.renderHTML(vTglinput)}
						<span className="mx-1">&middot;</span>
					</>
					)}
					{UFunc.renderHTML(vUserinput)}
					]</small>
				</CCol>

				<CCol md="12" className="px-1 my-3 text-start d-md-none classborderbottom"/>
				
				<CCol xs="5" className="px-1 text-start d-md-none text-primary">{uBahasaObj.caption_nilaibayar||"Nilai Bayar"} (Rp)</CCol>
				<CCol xs="7" md="2" className="px-1 text-primary">{UFunc.formatAngka(nilai_bayar)}</CCol>
				
				<CCol md="1" className="px-1 text-center d-none d-md-block">
					{(uIsHapusallow && vInitexpired==="TIDAK") ? (
						<CTooltip content={"--"+(uBahasaObj.caption_hapusdata)+": "+vCaptionUpper}>
						<CLink className="classikontabel classikonhapus classcursorpointer"
							onClick={()=>hdlKlikHapus(id)}/>
						</CTooltip>
					) : (<>&middot;</>)}
				</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = (_PAGE) => {
		setHTMLError500();
		_PAGE		= parseInt(_PAGE)||1;

		const vHandelview	= ((uHandelview||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		const vKeyword 		= (uKeywordObj.kwd_hutangkonsibayar || "").trim();
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE,
			send_maxdata	: uMaxData,
			send_firstload	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangkonsi/ld_preview";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Hutangkonsipreview - apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				vLoadArr.map(vItems=>vItems.pg = _PAGE);
				//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.dataobject));
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				setFirstload("TIDAK");

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Hutangkonsipreview - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Hutangkonsipreview - apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProseshapus = () => {
		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uDlghapusObj.tabelid);
		if(vArridx < 0) return;
		const vElbutton = document.getElementById("btnDialogSimpan");
		vElbutton && (vElbutton.disabled=true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElbutton && (vElbutton.disabled=false);
			setLoading(false);

			setDlghapusshow(false);
			uDatamainArr.splice(vArridx,1);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datajson : JSON.stringify(uDlghapusObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangkonsi/pr_hapusbayar";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElbutton && (vElbutton.disabled=false);
			setLoading(false);
			//console.log("(Hutangkonsipreview - apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				setDlghapusshow(false);
				uDatamainArr.splice(vArridx,1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Hutangkonsipreview - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled=false);
			setLoading(false);
			console.log("(Hutangkonsipreview - apiProseshapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
		if(!uIsInitbefore) apiLoaddata();

    	return () => {
    		setDlghapusshow(false);
    		setInpKeyword("");
    		setDatamainArr([]);
			setFirstload("YA");
    	}
	},[]);
	//--PAGINATION_DIATUR_BACKEND--/
	React.useEffect(()=>{
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]); 
	React.useEffect(()=>{ 
		if(!uHandelview) return;

		setHandelview(false);

		uKeywordObj.kwd_hutangkonsibayar = uInpKeyword;
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelview]); 
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uISDlghapusshow)
			{ setDlghapusObj({}); return }
	},[uISDlghapusshow]); 
	//--END PAGINATION_DIATUR_BACKEND--/
	//---END EFFECT--/

	//console.log("(Hutangkonsipreviewpreview) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={()=>uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{("Preview Pembayaran").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white m-0 px-2" style={{minHeight:52}}>
		<div className="d-none d-lg-flex align-items-center">
			<CIcon className="classikonedit" height={30}/>
			<small className="ms-1 fst-italic">Edit Data</small>
		</div>

		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup 
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CFormInput size="sm" 
					value={uInpKeyword} 
					onChange={(e) => setInpKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKDownkeyword(e)} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} 
					id="inpkeyword"/>
				<CButton size="sm" color="light" className="border" 
					onClick={()=>hdlKlikCari()} 
					id="btnCari">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
			</CForm>

			{!uIsEOMExecuted && (
			<>
				<span className="mx-1 d-none d-lg-inline">&middot;</span>
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui classblinking" height={30}/>
			</>
			)}

			<span className="d-none d-md-inline">
				<span className="d-none d-lg-inline mx-1">&middot;</span>
				<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
					<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
				</CTooltip>

				<span className="d-none d-md-inline me-2">&middot;</span>
				<b id="idjmldata" className="me-1">0</b>{uBahasaObj.word_data||"word_data"}
			</span>
		</div>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">
		<CContainer xxl className="px-0">
			{(uHTMLError500) ? (
			<MyPage500 content={uHTMLError500}/>
			) : (
			<CCard className="classbgcard mb-3">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_hutangkonsibayar || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>
			</CCard>
			)}
		</CContainer>
		</div>
		</div>

		<MyFormHapus
			value={uDlghapusObj.inputvalue||""}
			onCHvalue={(e)=>{uDlghapusObj.inputvalue = e.target.value;setEfekview(!uEfekview)}}
			onSimpan={()=>hdlKlikDlghapussimpan()}
			show={uISDlghapusshow} 
			dialogHeader={uDlghapusObj.header||"Hapus Data"}
			toggle={()=>setDlghapusshow(false)}/>
		</>
	) 
}	

export default Hutangkonsipreviewpreview