import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CForm,
	CFormInput,
	CFormTextarea,
	CFormSelect,
	CFormCheck,
	CBadge,
	CImage,
	CButton,
	CInputGroup,
	CInputGroupText,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
let pjson     = require('../../package.json');

const Nota = (props) => {
	const uComponentname 	= Nota.name;
	const { setLoading,showToast,prosesExpired } = props; 

	let uFuncname;
	const uHistory  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uIsInitHapus		= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN")
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const [uDataidentitas,setDataidentitas]	= React.useState({});
	const [uDatasetting,setDatasetting]		= React.useState({});

	const uFontstyleArr						= [
		{value:"Courier, Courier New, Sans Serif",caption:"Courier, Courier New, Sans Serif"},
		{value:"Verdana, Arial, Helvetica",caption:"Verdana, Arial, Helvetica"},
		{value:"Calibri, Trebuchet, Garamond",caption:"Calibri, Trebuchet, Garamond"},
		{value:"Monaco, DejaVu Sans Mono, OCR-A",caption:"Monaco, DejaVu Sans Mono, OCR-A"},
	];
	const uUrllogoDefault	= pjson.homepage+"api//images/header_nota.png";
	const uDatanotaObj		= {
		tgl_bayar: new Date(),
		no_nota: (new Date()).getFullYear()+UFunc.leadZero(((new Date()).getMonth()+1))+UFunc.leadZero((new Date()).getDate())+"T99",
		kasir: uTokenObj.usercaption,
		customer: "Klien Terbaik",
		arritems : [
			{caption_pasien:"Pasien 1",caption_item:"Item Nota ke-1",qty:2,h_jual_sat:85000,},
			{caption_pasien:"Pasien 2",caption_item:"Item Nota ke-2",qty:1,h_jual_sat:25000,},
			{caption_pasien:"Pasien 3",caption_item:"Item Nota ke-3",qty:1,h_jual_sat:45000,},
			{caption_pasien:"Pasien 4",caption_item:"Item Nota ke-4",qty:3,h_jual_sat:75000,},
			{caption_item:"Item Nota ke-5",qty:1,h_jual_sat:95000,},
		],
		total: 11123456,
		diskon: 123456,
		jml_bayar: 8500000,
		kembali: 2500000,
	};
	const refFileImg 						= React.useRef();
	const uHTMLstylenota					= useSelector(state => state.gHTMLstyleNota);

	//---FORMDATA--/
	const [uInpKliniknama,setInpKliniknama]			= React.useState(uDataidentitas.nama_perusahaan || "");
	const [uInpKliniktelp,setInpKliniktelp]			= React.useState(uDataidentitas.alamat || "");
	const [uInpKlinikalamat,setInpKlinikalamat]		= React.useState(uDataidentitas.telp || "");
	const [uInpKliniktagline,setInpKliniktagline]	= React.useState(uDataidentitas.slogan || "");
	const [uInpSettingfooter,setInpSettingfooter]	= React.useState(uDatasetting.url_image_nota || "");
	const [uChbSettinglogo,setChbSettinglogo]		= React.useState(uDatasetting.view_image_nota || "YA");
	const [uInpSettinglogourl,setInpSettinglogourl]	= React.useState(uDatasetting.footer_nota || "");
	
	const uSettingcetakObj 							= JSON.parse(localStorage.getItem("settingnota") || "{}")

	const [uInpCetaklebar,setInpCetaklebar]			= React.useState(uSettingcetakObj.lebarkertas || 0);
	const [uInpCetakkiri,setInpCetakkiri]			= React.useState(uSettingcetakObj.marginkiri || 0);
	const [uInpCetakfontsize,setInpCetakfontsize]	= React.useState(uSettingcetakObj.fontsize || 12);
	const [uInpCetakfontstyle,setInpCetakfontstyle]	= React.useState(uSettingcetakObj.fontstyle ||uFontstyleArr[0].value);

	const [uFormdataObj,setFormdataObj]				= React.useState({
		nama_perusahaan: uDataidentitas.nama_perusahaan,
		alamat: uDataidentitas.alamat,
		telp: uDataidentitas.telp,
		slogan: uDataidentitas.slogan,
		url_image_nota: uDatasetting.url_image_nota,
		view_image_nota: uDatasetting.view_image_nota,
		footer_nota: uDatasetting.footer_nota,
		lebarkertas: uSettingcetakObj.lebarkertas,
		marginkiri: uSettingcetakObj.marginkiri,
		fontsize: uSettingcetakObj.fontsize,
		fontstyle: uSettingcetakObj.fontstyle || uFontstyleArr[0].value,
	})
	//---END FORMDATA--/

	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const uElBtnsimpan	= document.getElementById("btnSimpan");
	//--END GLOBAL_VARS--/


	//---HANDLE--/
	const hdlCheckLogo=(_EV)=>{
		//console.log("(Nota - hdlCheckLogo) view_image_nota => "+uFormdataObj.view_image_nota)
		uFormdataObj.view_image_nota = (uFormdataObj.view_image_nota||"TIDAK")==="YA"
			? "TIDAK" : "YA";
		setEfekview(!uEfekview)
	}
	const hdlKlikReset=()=>{ if(uHTMLError500) apiLoaddata(); else initAwal(); }
	const hdlKlikPilihlogo=()=>{ refFileImg.current.click(); }
	const hdlChangeLogo=(_EV)=>{
		const fileUploaded 	= _EV.target.files[0];
		//console.log("(Pasieninput - hdlChangeFoto) fileUploaded => "+fileUploaded);

		UFunc.getBase64(fileUploaded).then(result => {
			const fileBase64 = result;
			uFormdataObj.url_image_nota = fileBase64; setEfekview(!uEfekview) 
			//console.log("File Is", fileBase64);
			_EV.target.value = '';
		}).catch(err => {
			_EV.target.value = '';
			console.log(err);
		});
	}
	const hdlKlikHapuslogo=async()=>{
		const vConfirm	= 
			"<B>"+(uBahasaObj.caption_hapuslogo||"caption_hapuslogo")+"</B>.."+
			"<BR><BR>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+""+
		"";
		const vFuncnext	= ()=>{
			uFormdataObj.url_image_nota = ""; setEfekview(!uEfekview) 
		}

		if(await Konfirm(vConfirm)) vFuncnext();
	}
	const hdlKlikSimpan=()=>{ apiProsessimpan(); }
	const hdlKlikCetak=()=>{
		var vContent 	= initCetaknota();
		var pri 		= uFrameExport.contentWindow;
		console.log(vContent);
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initAwal=()=>{
		/*setInpKliniknama(uDataidentitas.nama_perusahaan || "");
		setInpKliniktelp(uDataidentitas.telp || "");
		setInpKlinikalamat(uDataidentitas.alamat || "");
		setInpKliniktagline(uDataidentitas.slogan || "");

		setChbSettinglogo(uDatasetting.view_image_nota || "YA");
		setInpSettingfooter(uDatasetting.footer_nota || "");
		setInpSettinglogourl(uDatasetting.url_image_nota || "");

		setInpCetaklebar(uSettingcetakObj.lebarkertas || 0);
		setInpCetakkiri(uSettingcetakObj.marginkiri || 0);
		setInpCetakfontsize(uSettingcetakObj.fontsize || 12);
		setInpCetakfontstyle(uSettingcetakObj.fontstyle || uFontstyleArr[0].value);//-*/

		setFormdataObj({
			nama_perusahaan: uDataidentitas.nama_perusahaan,
			alamat: uDataidentitas.alamat,
			telp: uDataidentitas.telp,
			slogan: uDataidentitas.slogan,
			url_image_nota: uDatasetting.url_image_nota,
			view_image_nota: uDatasetting.view_image_nota,
			footer_nota: uDatasetting.footer_nota,
			lebarkertas: uSettingcetakObj.lebarkertas,
			marginkiri: uSettingcetakObj.marginkiri,
			fontsize: uSettingcetakObj.fontsize,
			fontstyle: uSettingcetakObj.fontstyle || uFontstyleArr[0].value,
		})
		setEfekview(!uEfekview)
	}
	const initCetaknota=()=>{
		let vHTMLs 	= uHTMLstylenota;

		const vLebarkertas 	= uSettingcetakObj.lebarkertas || "auto";
		const vMarginkiri 	= uSettingcetakObj.marginkiri || "0";
		const vFontsize		= uSettingcetakObj.fontsize || "12";
		const vFontfamily	= uSettingcetakObj.fontstyle || uFontstyleArr[0].value;
		
		const vViewlogo 	= uDatasetting.view_image_nota || "YA";
		const vURLlogo 		= uDatasetting.url_image_nota || uUrllogoDefault;

		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (vLebarkertas<=0?"auto":vLebarkertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,vMarginkiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,vFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,vFontfamily);

		const vIsWithlogo	= (vViewlogo||"TIDAK")==="YA"?true:false;

		const vMainbody	= UFunc.Rendernota(uDatanotaObj);
		vHTMLs 		+=  vMainbody;

		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<tr valign="top">
			<td colSpan="4" align="center">
				<img src="`+vURLlogo+`" height="25mm" style="height:25mm" />
			</td>
			</tr>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uDataidentitas.telp || "") !== "")
			vTelpPerusahaan += `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				//(uBahasaObj.caption_telpwa||"caption_telpwa")+". "+
				UFunc.capitalize(uDataidentitas.telp || "")+
			`</td>
			</tr>
			`;
		//---END TELP--/

		//---TAGLINE--/
		const vTagline	=  uDataidentitas.slogan !== "" 
			? `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				UFunc.capitalize(uDataidentitas.slogan || "")+
			`</td>
			</tr>
			` : ``;
		//---END TAGLINE--/

		const vFooternota = (uDatasetting.footer_nota||"").trim() !=="" ?
			`
			<tr>
			<td colSpan="4"><HR></td>
			</tr>
			<tr>
			<td colSpan="4" align="center">`+(uDatasetting.footer_nota||"")+`</td>
			</tr>
			` : ``;

		vHTMLs 		= vHTMLs.replace(/_TRBODYLOGO_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uDataidentitas.nama_perusahaan || "").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uDataidentitas.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_TRBODYTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_FOOTERNOTA_/g,vFooternota);
		vHTMLs 		= vHTMLs.replace(/_TRBODYTAGLINE_/g,vTagline);

		return vHTMLs;
	}
	//---END INIT_FUNCTION--/

	//---API_FUNC--/
	const apiLoaddata=()=>{
		uFuncname = apiLoaddata.name;
		setHTMLError500();

		setDatasetting({});
		setDataidentitas({});
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_nota/ld_data";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				setDataidentitas(JSON.parse(output_string.dataidentitas || "{}"));
				setDatasetting(JSON.parse(output_string.datasetting || "{}"));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				let vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan=()=>{
		uFuncname = apiProsessimpan.name;

		const vElstombol	= uElBtnsimpan;
		vElstombol && (vElstombol.disabled = true);
		setLoading(true);

		const vIdentitasObj	= {
			nama_perusahaan: (uFormdataObj.nama_perusahaan||"").trim(),
			telp: (uFormdataObj.telp||"").trim(),
			alamat: (uFormdataObj.alamat||"").trim(),
			slogan: (uFormdataObj.slogan||"").trim()
		}
		const vSettingObj	= {
			view_image_nota: (uFormdataObj.view_image_nota||"TIDAK").toUpperCase(),
			url_image_nota: (uFormdataObj.url_image_nota||"").trim(),
			footer_nota: (uFormdataObj.footer_nota||"").trim(),
		}
		const vCetakObj		= {
			lebarkertas: (uFormdataObj.lebarkertas||"0").toString(),
			marginkiri: (uFormdataObj.marginkiri||"0").toString(),
			fontsize: (uFormdataObj.fontsize||"12").toString(),
			fontstyle: (uFormdataObj.fontstyle||uFontstyleArr[0].value).toString(),
		}

		/*//--TESTING_FRONTEND--/
		let vTimeout	= setTimeout(function(){
			clearTimeout(vTimeout);
			setLoading(false);
			vElstombol && (vElstombol.disabled = false);

			setDataidentitas(vIdentitasObj);
			setDatasetting(vSettingObj);
			localStorage.setItem("settingnota",JSON.stringify(vCetakObj));
		},3500);
		return;
		//--END TESTING_FRONTEND--*/

		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_datalist: JSON.stringify(uFormdataObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_nota/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setLoading(false);
			vElstombol && (vElstombol.disabled = false);
			if(output_string.sukses) {
				const vNewurllogo	= output_string.newlogourl||(uFormdataObj.url_image_nota||"");
				uFormdataObj.url_image_nota	= vNewurllogo;
				vSettingObj.url_image_nota = vNewurllogo;

				setDataidentitas(vIdentitasObj);
				setDatasetting(vSettingObj);

				localStorage.setItem("settingnota",JSON.stringify(vCetakObj));
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				let vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElstombol && (vElstombol.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---Init_CONTENT--/
	const contentForm = () => {
		if(uHTMLError500) return "";

		//console.log("(Nota - contentForm) view_image_nota: "+uFormdataObj.view_image_nota);
		return (
		<CForm>
		<CRow className="my-2">
			<CCol className="ps-0 ps-lg-2">
			<CFormCheck size="sm"
				onChange={(e)=>hdlCheckLogo(e)}
				checked={((uFormdataObj.view_image_nota||"TIDAK")==="YA")}
				label={(uBahasaObj.caption_denganlogo||"caption_denganlogo").toUpperCase()}
				value={"YA"} 
				id="inpwithlogo"/> 
			</CCol>
			<CCol className="text-end pe-0 pe-lg-2">
				{(uFormdataObj.url_image_nota||"")==="" ? (
				<>
				<input
					type="file"
					accept="image/png,image/jpeg,image/gif"
					ref={refFileImg}
					onChange={(e)=>hdlChangeLogo(e)}
					className="d-none"
					id="inpfileimg"/>

				<CBadge color="dark">{(uBahasaObj.caption_logodefault||"caption_logodefault").toUpperCase()}</CBadge>

				{(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && (
				<>
				<span className="mx-1 d-none d-lg-inline">&middot;</span>
				<CTooltip content={"Ganti Logo Nota"}>
				<CLink className="classcursorpointer classikon classikonupload"
					onClick={()=>hdlKlikPilihlogo()}/>
				</CTooltip>
				</>
				)}
				</>
				) : (
				<>
				<CBadge color="success">
					{(uBahasaObj.caption_logocustom||"caption_logocustom").toUpperCase()}
				</CBadge>
				{(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && (
				<>
					<span className="mx-1">&middot;</span>
					<CLink className="classcursorpointer classikontabel classikonhapus"
						onClick={()=>hdlKlikHapuslogo()}/>
				</>
				)}
				</>
				)}
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom classborderdashed"/>
		
		<CRow className="my-2 mx-0">
			<CCol className="">
			<CFormInput size="sm" maxLength={100}
				placeholder={"--"+(uBahasaObj.caption_namaklinik||"caption_namaklinik").toUpperCase()+".."}
				value={uFormdataObj.nama_perusahaan||""}
				onChange={(e)=>{uFormdataObj.nama_perusahaan=e.target.value;setEfekview(!uEfekview)}}
				id="inpnamaklinik"/>
			</CCol>
		</CRow>
		<CRow className="my-2 mx-0">
			<CCol className="">
			<CFormInput size="sm" maxLength={50}
				placeholder={"--"+(uBahasaObj.caption_telpwa||"caption_telpwa").toUpperCase()+".."}
				value={uFormdataObj.telp||""}
				onChange={(e)=>{uFormdataObj.telp=e.target.value;setEfekview(!uEfekview)}}
				id="inptelp"/>
			</CCol>
		</CRow>
		<CRow className="my-2 mx-0">
			<CCol className="">
			<CFormTextarea size="sm"
				placeholder={"--"+(uBahasaObj.word_alamat||"word_alamat").toUpperCase()+".."}
				value={UFunc.br2nl(uFormdataObj.alamat||"")}
				onChange={(e)=>{uFormdataObj.alamat=e.target.value;setEfekview(!uEfekview)}}
				id="inpalamat"/>
			</CCol>
		</CRow>
		<CRow className="my-2 mx-0">
			<CCol className="">
			<CFormInput size="sm" maxLength={100}
				value={uFormdataObj.slogan||""}
				onChange={(e)=>{uFormdataObj.slogan=e.target.value;setEfekview(!uEfekview)}}
				placeholder={"--"+(uBahasaObj.word_tagline||"word_tagline").toUpperCase()+".."}
				id="inptagline"/>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom classborderdashed"/>
		
		<CRow className="my-2 mx-0">
			<CCol xs="6" lg="3">
				<div className="classpetunjuk px-0">{(uBahasaObj.caption_lebarkertas||"caption_lebarkertas")}</div>
				<CInputGroup size="sm">
				<CFormInput type="number" size="sm"
					step="5" min="0"
					className="text-end"
					value={uFormdataObj.lebarkertas||"0"}
					onChange={(e)=>{uFormdataObj.lebarkertas=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>(e.target.select())}
					id="inplebarkertas"/>
				<CInputGroupText><small>mm</small></CInputGroupText>
				</CInputGroup>
			</CCol>
			<CCol lg="2" className="d-none d-lg-block"/>
			<CCol xs="6" lg="3">
				<div className="classpetunjuk px-0">{(uBahasaObj.caption_marginkiri||"caption_marginkiri")}</div>
				<CInputGroup size="sm">
				<CFormInput type="number" size="sm"
					min="0" step="5"
					className="text-end"
					value={uFormdataObj.marginkiri||"0"}
					onChange={(e)=>{uFormdataObj.marginkiri=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>(e.target.select())}
					id="inpmarginkiri"/>
				<CInputGroupText><small>mm</small></CInputGroupText>
				</CInputGroup>
			</CCol>
			<CCol lg="4" className="d-none d-lg-block"/>
		</CRow>

		<CRow className="my-2 mx-0">
			<CCol xs="6" lg="3">
				<div className="classpetunjuk px-0">{(uBahasaObj.caption_fontsize||"caption_fontsize")}</div>
				<CInputGroup size="sm">
				<CFormInput type="number" size="sm"
					min={7} max={20}
					className="text-end"
					value={uFormdataObj.fontsize||"0"}
					onChange={(e)=>{uFormdataObj.fontsize=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>(e.target.select())}
					id="inpfontsize"/>
				<CInputGroupText>px</CInputGroupText>
				</CInputGroup>
			</CCol>
			<CCol lg="2" className="d-none d-lg-block"/>
			<CCol xs="6" lg="7">
				<div className="classpetunjuk px-0">{(uBahasaObj.caption_fontfamily||"caption_fontfamily")}</div>
				<CFormSelect size="sm"
					value={uFormdataObj.fontstyle}
					onChange={(e)=>{uFormdataObj.fontstyle=e.target.value;setEfekview(!uEfekview)}}
					id="inpfontfamily">
					{uFontstyleArr.map((vItems,vKeys)=>{
						return (<option value={vItems.value} key={vKeys}>{vItems.caption}</option>);
					})}
				</CFormSelect>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom classborderdashed"/>

		<CRow className="my-2 mx-0">
			<CCol className="">
			<CFormInput size="sm"
				maxLength={100}
				value={uFormdataObj.footer_nota}
				onChange={(e)=>{uFormdataObj.footer_nota=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
				placeholder={"--"+(uBahasaObj.caption_footernota||"caption_footernota").toUpperCase()+".."}
				id="inpfooternota"/>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom classborderdashed"/>
		
		<CRow className="my-2">
			<CCol className="classpetunjuk">
			*) {UFunc.renderHTML(uBahasaObj.petunjuk_settingnota || "petunjuk_settingnota")}
			</CCol>
		</CRow>

		</CForm>
		)//>
	}
	const contentPreview = () => {
		if(uHTMLError500) return "";

		//console.log("(Nota - contentPreview) uDatasetting: "+JSON.stringify(uDatasetting));
		return (
		<>
		<CRow className="mx-0">
			{((uDatasetting.view_image_nota||"TIDAK")==="YA") && (
				<CCol xs="12" className="d-flex justify-content-center">
				<CImage src={(uDatasetting.url_image_nota||"").trim()!==""?uDatasetting.url_image_nota:uUrllogoDefault} height="80"/>
				</CCol>
			)}
			<CCol xs="12" className="text-center">
			<strong>{uDataidentitas.nama_perusahaan||"HAPPY PETS"}</strong>
			</CCol>

			{(uDataidentitas.alamat||"").trim()!=="" && (
			<CCol xs="12" className="text-center">{UFunc.renderHTML(UFunc.nl2br(uDataidentitas.alamat||""))}</CCol>
			)}
			
			{(uDataidentitas.telp||"").trim()!=="" && (
			<CCol xs="12" className="text-center">{uDataidentitas.telp||""}</CCol>
			)}
			
			{(uDataidentitas.slogan||"").trim()!=="" && (
			<CCol xs="12" className="text-center fst-italic">{uDataidentitas.slogan||""}</CCol>
			)}
		</CRow>

		<div className="my-2 classborderbottom classborderdashed"/>

		<CRow className="mx-0">
			<CCol xs="5">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
			<CCol xs="7">{UFunc.WaktuPendek(uDatanotaObj.tgl_bayar)}</CCol>
			<CCol xs="5">{uBahasaObj.word_nonota||"word_nonota"}</CCol>
			<CCol xs="7">{uDatanotaObj.no_nota}</CCol>
			<CCol xs="5">{uBahasaObj.word_kasir||"word_kasir"}</CCol>
			<CCol xs="7">{uDatanotaObj.kasir}</CCol>
		</CRow>

		<div className="my-2 classborderbottom classborderdashed"/>

		<CRow className="mx-0">
			<CCol xs="5">{uBahasaObj.word_customer||"word_customer"}</CCol>
			<CCol xs="7">{uDatanotaObj.customer}</CCol>
		</CRow>

		<div className="my-2 classborderbottom classborderdashed"/>

		{(uDatanotaObj.arritems||[]).map((vItems,vKeys)=>{
			return(
				<CRow className="mx-0" key={vKeys}>
				<CCol xs="1" className="px-1 text-end">{(vKeys+1)}</CCol>
				<CCol xs="11" className="px-1">
					{(vItems.caption_pasien||"")!==""?"["+vItems.caption_pasien+"] ":""}{vItems.caption_item}</CCol>
				<CCol xs="8" className="text-end">({vItems.qty} x @{UFunc.formatAngka(vItems.h_jual_sat)})</CCol>
				<CCol xs="4" className="text-end">{UFunc.formatAngka(parseInt(vItems.qty) * parseInt(vItems.h_jual_sat))}</CCol>
				</CRow>
			);
		})}

		<div className="my-2 classborderbottom classborderdashed"/>

		<CRow className="mx-0">
			<CCol xs="8" className="text-end">{uBahasaObj.word_jumlah||"word_jumlah"} (Rp)</CCol>
			<CCol xs="4" className="text-end">{UFunc.formatAngka(uDatanotaObj.total)}</CCol>
		</CRow>
		<CRow className="mx-0">
			<CCol xs="8" className="text-end">{uBahasaObj.word_diskon||"word_diskon"} (Rp)</CCol>
			<CCol xs="4" className="text-end">{UFunc.formatAngka(uDatanotaObj.diskon||"0")}</CCol>
		</CRow>
		<CRow className="mx-0">
			<CCol xs="8" className="text-end">&nbsp;</CCol>
			<CCol xs="4" className="my-2 classborderbottom classborderdashed"/>
		</CRow>
		<CRow className="mx-0">
			<CCol xs="8" className="text-end">{uBahasaObj.caption_subtotal||"caption_subtotal"} (Rp)</CCol>
			<CCol xs="4" className="text-end">{UFunc.formatAngka(uDatanotaObj.total-(uDatanotaObj.diskon||0))}</CCol>
		</CRow>
		<CRow className="mx-0 fw-bolder text-uppercase">
			<CCol xs="8" className="text-end">{uBahasaObj.caption_jmlbayar||"caption_jmlbayar"} (Rp)</CCol>
			<CCol xs="4" className="text-end">{UFunc.formatAngka(uDatanotaObj.jml_bayar)}</CCol>
		</CRow>
		<CRow className="mx-0">
			<CCol xs="8" className="text-end">&nbsp;</CCol>
			<CCol xs="4" className="my-2 classborderbottom classborderdashed"/>
		</CRow>
		<CRow className="mx-0 fw-bolder">
			<CCol xs="8" className="text-end">{uBahasaObj.caption_kembalian||"caption_kembalian"} (Rp)</CCol>
			<CCol xs="4" className="text-end">{UFunc.formatAngka(Math.abs(uDatanotaObj.kembali))}</CCol>
		</CRow>
		{(uDatasetting.footer_nota||"").trim()!=="" && (
		<>
			<div className="my-2 classborderbottom classborderdashed"/>
			<CRow className="mx-0">
				<CCol xs="12" className="fst-italic text-center text-capitalize">{uDatasetting.footer_nota}</CCol>
			</CRow>
		</>
		)}
		</>
		)//>
	}
	//---END Init_CONTENT--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return()=>{
    		setDatasetting({});
    		setDataidentitas({});
    	}
	},[]);
	React.useEffect(()=>{ initAwal() },[uDataidentitas,uDatasetting]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_cComponentName
	if(uHTMLError500) return (
		<MyPage500 content={uHTMLError500} 
			isWithrefresh={true} 
			hdlReset={()=>apiLoaddata()}
			{...props}/>
	);

	return (
		<>
		<CRow className="align-items-stretch mb-3">
		<CCol xs="12" md="6" className="">
		<CCard className="classcardbg h-100">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<strong>{uBahasaObj["menus_"+uActiveroute.toLowerCase()]}</strong>
			<CLink className="classikontabel classikonreset classcursorpointer"
				onClick={()=>hdlKlikReset()}/>
			</CCardHeader>

			<CCardBody className="py-1">{contentForm()}</CCardBody>
			<CCardFooter className="d-flex justify-content-between align-items-center">
				<MyButton
					size="sm"
					onClick={()=>hdlKlikSimpan()}
					id="btnSimpan">
					{uBahasaObj.word_simpan||"word_simpan"}
				</MyButton>
				<CButton onClick={()=>hdlKlikCetak()} 
					color="primary"
					className="text-white" 
					size="sm"
					id="btnCetak">
					{uBahasaObj.caption_testprint||"caption_testprint"}
					<CIcon icon="cilArrowRight" className="ms-2"/>
				</CButton>
			</CCardFooter>
		</CCard>
		</CCol>

		<CCol xs="12" md="6" className="d-none d-md-block">
		<CCard className="classcardbg h-100">
			<CCardHeader>
			<strong>{uBahasaObj.caption_tampilannota||"caption_tampilannota"}</strong>
			</CCardHeader>
			<CCardBody style={{fontFamily:uInpCetakfontstyle,fontSize:uInpCetakfontsize+"px"}}>
			{contentPreview()}
			</CCardBody>
		</CCard>
		</CCol>
		</CRow>

		<iframe id="ifmcontentstoprint" className="d-none">
			<CImage src={uDatasetting.url_image_nota||uUrllogoDefault} id="idimglogo" />
		</iframe>
		</>
	)//>
}
	
export default Nota;
