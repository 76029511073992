import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CCardHeader,
	CCard,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CFormInput,
	CFormSelect,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import CIcon from '@coreui/icons-react';
import { isMobile } from "react-device-detect";
import { 
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';

const pjson     = require('../../package.json');
const Jasa = (props) => {
	const cComponentName 	= Jasa.name;
	const {
		setLoading, showToast, prosesExpired, setSessionaktif 
	} = props; 

	let uFuncName;//-->hanya untuk API
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uHTMLstyleTabel =useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom = useSelector(state => state.gIsScrollBottom);
	const uEfekapp		= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 		= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus	= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData							= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uJenisJasaArr,setJenisJasaArr]	= React.useState([{
		value:"0",caption:"--"+(uBahasaObj.caption_dataempty||"caption_dataempty").toUpperCase()+"--"}
	]);

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;
	const uHandelCetak 		= uHeaderActionObj.isHeaderCetak || false;

	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uDatacetakArr,setDatacetakArr]	= React.useState([]);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpJasakode,setInpJasakode]		= React.useState("");
	const [uInpJasanama,setInpJasanama] 	= React.useState("");
	const [uInpHargajasa,setInpHargajasa]	= React.useState("");
	const [uInpJenisID,setInpJenisID]		= React.useState("1");

	const [ElBtnSimpan,setElBtnSimpan]		= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata	= document.getElementById("idjmldata");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	//---END VAR_GLOBALS--/

	//console.log("("+cComponentName+") uHeaderActionObj.isHeaderView: "+uHeaderActionObj.isHeaderView);
	//console.log("("+cComponentName+") uHandelView: "+uHandelView);

	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowForm(false); }
	const hdlKlikSimpan=()=>{
		//--VALIDASI_SIMPAN--/
		if(uInpJasanama.trim() === "") {
			showToast((uBahasaObj.caption_namajasa||"caption_namajasa")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
			document.getElementById("inpjasanama").focus();
			return;
		}
		if(uInpJenisID.toString() === "0") {
			showToast((uBahasaObj.word_jenis||"word_jenis")+" "+(uBahasaObj.caption_mustchoosed||"caption_mustchoosed")+".."); 
			document.getElementById("inpjenisid").focus();
			return;
		}
		//---END VALIDASI--/
		//--END VALIDASI_SIMPAN--/

		apiProses();
	}
	const hdlKlikEdit=async(_IDTABEL)=>{

		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setInpJasakode(uDatamainArr[vObjdx].kode);
		setInpJasanama(uDatamainArr[vObjdx].nama);
		setInpJenisID(uDatamainArr[vObjdx].jenis_id);
		setInpHargajasa(uDatamainArr[vObjdx].biaya);
		setDialogHeader((uBahasaObj.caption_editdata||"caption_editdata")+": "+(uDatamainArr[vObjdx].nama || "").toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}
	const hdlKlikHapus=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>"+uDatamainArr[vObjdx].nama.toUpperCase()+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiHapus(_IDTABEL);
	}
	//---END HANDLE--/

	//---API_FUNC--/
	const apiLoadData=()=>{
		uFuncName = apiLoadData.name;
		setHTMLError500("");

		const vKeysword = uKeywordObj.kwd_jasa || "";

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		setDatamainArr([]);
		setDatacetakArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);
		const vDATAS	= JSON.stringify({
			send_keyword : vKeysword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jasa/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+cComponentName+"-"+uFuncName+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				if(output_string.jenislist) {
					const vTmpObjs = JSON.parse(output_string.jenislist);
					setJenisJasaArr(vTmpObjs);
				}

				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDatamainArr(vTmpObj);
				}

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+uFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProses=()=>{
		uFuncName = apiProses.name;

		/*//--TESTING_FRONTEND--//
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_jasakode : (uInpJasakode||"").trim(),
			send_jasanama : uInpJasanama.trim(),
			send_biaya : uInpHargajasa,
			send_jenisid : uInpJenisID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jasa/pr_simpan";

		setLoading(true);
		ElBtnSimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			ElBtnSimpan.disabled = false;

			if(output_string.sukses) {
				//---CONCAT_TO_CURRENT_DATA_OBJ--/
				let vDatamainArrNew;
				if(uTabelIDPilih === 0) {
					const vTmpObject	= {
						id: output_string.tabelid || "255",
						kode : (uInpJasakode||"").trim(),
						nama : UFunc.capitalize(uInpJasanama.trim()),
						jenis: uJenisJasaArr[uJenisJasaArr.findIndex(x => x.value === uInpJenisID.toString())].caption,
						jenis_id : uInpJenisID,
						biaya : uInpHargajasa,				
					}
					vDatamainArrNew = [vTmpObject].concat(uDatamainArr);
				} else {
					vDatamainArrNew = uDatamainArr;
					const vObjdx				= UFunc.getObjectIndeks(vDatamainArrNew,uTabelIDPilih);
					vDatamainArrNew[vObjdx].kode 	= (uInpJasakode||"").trim();
					vDatamainArrNew[vObjdx].nama 	= uInpJasanama.trim();
					vDatamainArrNew[vObjdx].jenis 	= uJenisJasaArr[uJenisJasaArr.findIndex(x => x.value === uInpJenisID.toString())].caption;
					vDatamainArrNew[vObjdx].jenis_id= uInpJenisID;
					vDatamainArrNew[vObjdx].biaya 	= uInpHargajasa;
				}

				setDatamainArr(vDatamainArrNew);
				//---END CONCAT_TO_CURRENT_DATA_OBJ--/

				if(uTabelIDPilih > 0) {
					setBodyInit(!uBodyInit);
					hdlToggleForm();
				}  else {
					setTabelRowSelect(-1);
					setJmlData((parseInt(vDatamainArrNew.length)||0));
					showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
					setPageActive(1);
					initFormAwal();
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				ElBtnSimpan.disabled = true;
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			setLoading(false);
			ElBtnSimpan.disabled = false;
			console.log("("+cComponentName+"-"+uFuncName+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiHapus=(_IDTABEL)=>{
		uFuncName 	= apiHapus.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		/*//---TESTING_FRONTEND--/
		console.log("("+cComponentName+"-"+uFuncName+") uTabelIDPilih : "+uTabelIDPilih);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jasa/pr_hapus";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setBodyInit(!uBodyInit);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+uFuncName+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadCetak=()=>{
		uFuncName = apiLoadCetak.name;

		const vKeysword = uKeywordObj.kwd_jasa || "";
		if(vKeysword.length > 2) {
			setDatacetakArr(uDatamainArr);
			return;
		}

		setDatacetakArr([]);
		const vDATAS	= JSON.stringify({
			send_initcetak : "YES",
			send_keyword : vKeysword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jasa/ld_data";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+cComponentName+"-"+uFuncName+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDatacetakArr(vTmpObj);
				}//-*/
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+uFuncName+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---Init_LOAD--/
	const initCetakClosing=()=>{
		var vContent 	= loadContentCetak();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();

		setDatacetakArr([]);
	}
	const initFormAwal=()=>{
		setTabelIDPilih(0);

		setInpJasakode("");
		setInpJasanama("");
		setInpHargajasa("");
		setInpJenisID("1");
	}
	const loadContent=()=>{
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeysword 		= UFunc.filterRegex(uKeywordObj.kwd_jasa || "");
		const vKeyswordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeysword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="px-0 text-start">{uBahasaObj.caption_kodejasa||"caption_kodejasa"}</CCol>
				<CCol md="4" className="px-0 text-start">{uBahasaObj.caption_namajasa||"caption_namajasa"}</CCol>
				<CCol md="2" className="px-0">{uBahasaObj.word_jenis||"word_jenis"}</CCol>
				<CCol md="2" className="px-0">{uBahasaObj.word_tarif||"word_tarif"} (Rp)</CCol>
				<CCol md="2">&middot;</CCol>
				</CRow>
			</th>
		</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,kode,nama,jenis,biaya
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (nama||"").toUpperCase();

			const vJasanama	= vKeysword==="" ? nama 
				: nama.replace(vReplace,"<strong><U style='color:"+vKeyswordcolor+"'>"+vKeysword.toUpperCase()+"</U></strong>");
			const vJasakode= vKeysword==="" ? (kode||"-") 
				: (kode||"-").replace(vReplace,"<strong><U style='color:"+vKeyswordcolor+"'>"+vKeysword.toUpperCase()+"</U></strong>");
			const vBarangjenis= vKeysword==="" ? jenis 
				: jenis.replace(vReplace,"<strong><U style='color:"+vKeyswordcolor+"'>"+vKeysword.toUpperCase()+"</U></strong>");
			const vBiaya	= UFunc.formatAngka(biaya);
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"text-end align-top"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>
				<td className="px-1 ps-0">
				<CRow className="mx-0">
					<CCol md="2" className="px-1 text-start text-uppercase d-none d-md-block">
						<small>{UFunc.renderHTML(vJasakode.toUpperCase())}</small>
					</CCol>
					
					<CCol xs="12" md="4" className="px-1 text-start d-flex justify-content-between">
						<div>
							<span className="text-info">{UFunc.renderHTML(vJasanama)}</span>
						</div>

						<CDropdown className="d-md-none">
						<CDropdownToggle color="transparent" className="p-0 m-0" 
							caret={false}>
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem 
								onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikontabel classikonedit me-2" height={25}/>
								<span>{uBahasaObj.caption_editdata}</span>
							</CDropdownItem>
							
							{uIsAllowedhapus && (
							<CDropdownItem 
								onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikontabel classikonhapus me-2" height={25}/>
								<span>{uBahasaObj.caption_hapusdata}</span>
							</CDropdownItem>
							)}
						</CDropdownMenu>
						</CDropdown>
					</CCol>
					
					<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

					<CCol xs="4" className="px-1 text-start text-md-center d-md-none">
						<span className="">{uBahasaObj.caption_kodejasa||"caption_kodejasa"} </span>
					</CCol>
					<CCol xs="8" className="px-1 d-md-none">{UFunc.renderHTML(vJasakode)}</CCol>

					<CCol xs="4" className="px-1 text-start text-md-center d-md-none">
						<span className="">{uBahasaObj.word_jenis||"word_jenis"} </span>
					</CCol>
					<CCol xs="8" md="2" className="px-1 text-md-center">
						{UFunc.renderHTML(vBarangjenis)}
					</CCol>
					
					<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

					<CCol xs="4" className="px-1 text-start text-md-center d-md-none">
						<span className="">{uBahasaObj.word_tarif||"word_tarif"}</span>
					</CCol>
					<CCol xs="8" md="2" className="px-1 text-end fw-bolder text-primary">
						<small className="d-md-none">Rp</small>{vBiaya}
					</CCol>

					<CCol md="2" className="px-1 d-none d-md-block text-md-center">
						<CTooltip content={"--"+(uBahasaObj.caption_editdata || "caption_editdata")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer"/>
						</CTooltip>
						{uIsAllowedhapus && (
						<>
							<span className="d-md-none"> &middot; </span>
							<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
							<CLink 
								className={"classikontabel classikonhapus classcursorpointer"}
								onClick={()=>hdlKlikHapus(id)} />
							</CTooltip>
						</>
						)}
					</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const loadContentForm = () => {
		if(uHTMLError500) return "";

		return (
			<>
			<CForm className="mx-lg-5">
			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end">
					{uBahasaObj.caption_namajasa||"caption_namajasa"}
				</CCol>
				<CCol xs="12" md="8">
					<CFormInput maxLength={50} size="sm"
						className="form-control text-capitalize"
						value={uInpJasanama}
						onChange={(e) => setInpJasanama(e.target.value)}
						id="inpjasanama"/>
				</CCol>
			</CRow>

			<div className="my-3 d-md-none classborderbottom"/>
	
			<CRow className="my-2">
				<CCol xs="4" className="text-md-end">
					{uBahasaObj.caption_kodejasa||"caption_kodejasa"}
				</CCol>
				<CCol xs="8" lg="4">
				<CFormInput maxLength={15} size="sm"
					className="form-control text-uppercase"
					value={uInpJasakode}
					onChange={(e) => setInpJasakode(e.target.value)}
					id="inpjasakode"/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-md-end">
					{uBahasaObj.word_jenis||"word_jenis"}
				</CCol>
				<CCol xs="8" lg="4">
					<CFormSelect name="inpjenisid" id="inpjenisid"
						onKeyDown={(e) => {
							if(!uIsAllowedhapus && uTabelIDPilih > 0)
								UFunc.hdlKeyDownInput(e,"btnDialogSimpan")
						}}
						value={uInpJenisID}
						onChange={(e)=>setInpJenisID(e.target.value)} 
						size="sm">
					{uJenisJasaArr.map((vItems,vKeys)=>{
						return(
						<option value={vItems.value} key={vKeys}>{vItems.caption}</option>
						)
					})}
					</CFormSelect>
				</CCol>
			</CRow>

			{(!uIsAllowedhapus && uTabelIDPilih > 0) ? (
			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end">
					{uBahasaObj.word_tarif||"word_tarif"}
				</CCol>
				<CCol xs="8" lg="4" className="text-end fw-bolder">
					Rp {UFunc.formatAngka(uInpHargajasa)}
				</CCol>
			</CRow>
			) : (
			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end">
					{uBahasaObj.word_tarif||"word_tarif"}
				</CCol>
				<CCol xs="8" lg="4">
					<NumberFormat 
						value={uInpHargajasa}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpHargajasa(values.value)}
						renderText={values => (
							<CFormInput 
								value={values}
								size="sm"
								className="text-end"
								onChange={(e) => setInpHargajasa(e.target.value)}
								onFocus={(e)=>e.target.select()}
								onKeyDown={(e) => UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
							id="inpbiaya"/>
						)} 
						prefix={isMobile?"":"Rp"}/>
				</CCol>
			</CRow>
			)}
			</CForm>
			</>
		)
	}
	const loadContentCetak=()=>{
		const vKeysword = uKeywordObj.kwd_jasa || "";
		var vHTMLs = uHTMLstyleTabel;

		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="6">
			<div>`+uBahasaObj["menus_"+uActiveroute.toLowerCase()]+`</div>
			`;
		vHTMLs += vKeysword!="" 
			? "<div style='font-size:smaller;font-weight:normal;'>"+(uBahasaObj.word_keyword||"word_keyword")+": <U>"+vKeysword+"</U></div>" : "";

		vHTMLs += `
			</td>
			</tr>
			<tr>
				<th width="7%">No</th>
				<th width="15%" align="left">`+uBahasaObj.caption_kodejasa+`</th>
				<th align="left">`+uBahasaObj.caption_namajasa+`</th>
				<th width="10%">`+uBahasaObj.word_jenis+`</th>
				<th width="20%">`+uBahasaObj.word_tarif+` (Rp)</th>
			</tr>
			</thead>
			<tbody>`;

		uDatacetakArr.forEach((vItemss,vKeys)=>{
			const {id,kode,nama,jenis,biaya} = vItemss;

				vHTMLs 	+= `
					<tr key={vKeys} valign="top">
						<td align="right" style="font-size:smaller">`+(vKeys+1)+`.</td>
						<td align="left" style="font-size:smaller">`+(kode||"-")+`</td>
						<td align="left">`+nama+`</td>
						<td align="center" style="font-size:smaller">`+jenis+`</td>
						<td align="right" style="font-weight:bolder;">`+UFunc.formatAngka(biaya)+`</td>
					</tr>
				`;
		});
		vHTMLs 	+= `
			</tbody>
		`
		vHTMLs 	+= `</table>`;

		return vHTMLs;
	}
	//---END Init_LOAD--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoadData();

    	return ()=>{
			setShowForm(false);
    		setJenisJasaArr([]);
    		setDatamainArr([]);
    		setDatacetakArr([]);
    	}
	},[]);
	React.useEffect(()=>{
		if(!uHandelView) return;
		apiLoadData();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(isShowForm) return;
		setDialogHeader((uBahasaObj.word_tambah||"word_tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setShowForm(true);
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return; }

		//console.log("("+cComponentName+"[isShowForm]) isShowForm 1: "+isShowForm);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnSimpan(document.getElementById("btnDialogSimpan"));

			if(isMobile) return;
			const vElFocus = document.getElementById("inpjasakode");
			vElFocus &&vElFocus.focus();
		},200);
	},[isShowForm]);
	React.useEffect(()=>{
		if(!uHandelCetak) return;

		apiLoadCetak();
		uHeaderActionObj.isHeaderCetak = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelCetak]);
	React.useEffect(()=>{ setJmlData(uDatamainArr.length); },[uDatamainArr,uDatamainArr.length]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);
	},[uJmlData]);
	React.useEffect(()=>{
		const vKeyword = (uKeywordObj.kwd_jasa || "").trim();
		if (vKeyword.length >= 3) {
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
			return;
		} 
		uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	},[uJmlData,uElJmldata]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		const vJmlCetak = parseInt(uDatacetakArr.length)||0;
		if(vJmlCetak <= 0) return;

		initCetakClosing();
	},[uDatacetakArr]);
	//---END EFFECT--/

	//console.log("("+cComponentName+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeysword = (uKeywordObj.jasa || "").trim();
					if(vKeysword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			
			<div className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{loadContent()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikSimpan()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={loadContentForm()}/>

		<iframe id="ifmcontentstoprint" className="d-none" />
		</>
	)//>
}
	
export default Jasa;
