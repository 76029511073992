import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {
	CForm,CInputGroup,CFormInput,
	CButton,
	CTooltip,
	CImage,
	CLink,
	//CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';

const pjson     = require('../../../package.json');
const Headersubuser = (props) => {
	//const cComponentName= Headersubuser.name;
	const uActiveroute	= useSelector(state => state.activeRoute);
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uDispatch		= useDispatch();
	const uEfekapp		= useSelector(state => state.effectApp);

	const uKeywordObj	= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const [uKeyword,setKeyword] = React.useState(uKeywordObj.kwd_user || "");

	const hdlKeydown=(_EV)=>{
		if(_EV.which===13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") && 
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari=()=>{
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_user = uKeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	}
	const hdlKlikTambah=()=>{
		uHeaderActionObj.isHeaderTambah = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset=async()=>{
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_user = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//prosesReset(); return;
		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}

	//console.log("("+cComponentName+") Checked..");
	return(
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+uActiveroute+".png"} 
				className="me-2 align-middle"
				height={30}/>
			<small className="fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CFormInput size="sm" 
					value={uKeyword} 
					onChange={(e) => setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKeydown(e)} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} 
					id="inpkeyword"/>
				<CButton size="sm" className="border"
					color="light" 
					onClick={()=>hdlKlikCari()} 
					id="btnCari">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
			</CForm>

			<div className="d-none d-md-block">
			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			
			<CTooltip content={uBahasaObj.caption_tambahdata || "caption_tambahdata"}>
				<CLink className="classikon classikontambah classcursorpointer" onClick={()=>hdlKlikTambah()}/>
			</CTooltip>

			<span className="me-2">&middot;</span>
			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
			</CTooltip>
			<span className="me-2">&middot;</span>

			<b id="idjmldata">0</b> {uBahasaObj.word_data||"word_data"}
			</div>
			
			<div className="d-md-none ms-1">
			<CLink className="classikon classikontambah classcursorpointer" onClick={()=>hdlKlikTambah()}/>
			</div>
		</div>
		</>
	);
}

export default Headersubuser;