import { createStore } from 'redux';

const initialState = {
	sidebarShow: true,
	sidebarUnfoldable: true,
	//sessionISnotify: false,
	activeRoute: "",
	listToast: [],
	listBahasa: [],
	listNav: [],
	listRoute: [],
	gListUserSetting: [],
	gSystemsetting: {},
	gUrlbefore:"",
	gListUserSetting: {
		jml_mobile: 20,
		jml_master: 15,
		jml_laporan: 100,
		color_keyword: "magenta",
	},
	gListDefaultSetting: {
		id_kas_keluar: 33,
		id_kas_masuk: 32,
		persen_konsi: 83,
	},
	gInitHeaderAction:{},
	gInitTabelSelected:{},
	gIsScrollBottom: false,
	gIsEOMExecuted: true,
	profileRoute:[
		{"path" : "/a1profile","exact" : true,"name":"a1profile"},
		{"path" : "/a1langs","exact" : true,"name":"a1langs"},
	],
	gHTMLstyleLaporan: `
		<style>
		@page {
			size: auto;
			margin: 5mm 5mm 5mm 5mm !important;
		}
		@media print {
			table#idtabelhtml {
				font-family: Calibri,Arial,Sans Serif;/**/
				width:100%;
				margin: 0 auto;
				border-spacing: 0;
				padding: 0;
				text-align: center;
				display: table;
			}
			table#idtabelhtml caption {
			}
			table#idtabelhtml thead tr td {
				padding: 3px;
				padding-bottom:10px;
				font-weight:normal;
			}
			table#idtabelhtml thead tr th  {
				border-top: 2px solid grey;
				border-bottom: 1px solid grey;
				padding: 10px 3px;
			}
			table#idtabelhtml tbody td {
				padding: 3px; border-bottom: 1px solid #f0f0f0;
				/*-ms-hyphens: auto; -moz-hyphens: auto; -webkit-hyphens: auto;
				hyphens: auto;
				overflow-wrap: break-word; word-wrap: break-word; -ms-word-break: break-all;
				word-break: break-all; word-break: break-word;/**/
			}
			table#idtabelhtml tbody td .classpetunjuk {
				font-size:smaller;font-style:italic;margin-top:3px;
				border-top:1px dashed #DCDCDC;padding:3px 7px;
				padding-bottom:0;
			}
			table#idtabelhtml tfoot td	{
				border-bottom: 2px solid grey;border-top: 1px solid grey;
				padding: 5px 3px;
				font-weight:bolder;
			}
			table#idtabelhtml tfoot td.classpagefooter	{
				border: none;
				font-weight:normal;
				padding-top: 10px;
			}
			#idpagefooter::after {
			    counter-increment:page;
			    content: counter(page);
			}
		}
		</style>`,

	gHTMLstyleNota: `
		<style>
		@page {
			size: auto;
			margin: 0;
		}
		@media print {
			html,body {
				width: _LEBARKERTAS_;
				margin: 0;
				padding: 0;
				margin-top: 1mm !important;
				margin-left: _MARGINKIRI_mm !important;
			}
			table#idtabelhtml {
				font-family: _FONTFAMILY_;/*Courier New,Courier,Sans Serif;/**/
				width: 100%;
				margin: 0 auto;
				border-spacing: 0;
				padding: 0;
				font-size: _FONTSIZE_px;
				text-align: left;
				display: table;
			}
			table#idtabelhtml hr {
				border-top-style: dotted;
				border-top-width: 0.05em;
				border-left: none;
				border-right: none;
				border-bottom: none;
			}
			table#idtabelhtml thead tr td {
				padding:10px 3px;
				font-weight:bolder;
				font-size:larger;
			}
			table#idtabelhtml thead tr th {
				border-top: 2px solid grey;border-bottom: 1px solid grey;
				padding: 10px 3px;
			}
			table#idtabelhtml tbody td {
				padding: 0; 
				/*overflow-wrap: break-word; word-wrap: break-word; -ms-word-break: break-all;
				word-break: break-all; word-break: break-word;
				-ms-hyphens: auto; -moz-hyphens: auto; -webkit-hyphens: auto;
				hyphens: auto;/**/
			}
			table#idtabelhtml tbody td .classpetunjuk {
				font-size:smaller;font-style:italic;margin-top:3px;
				border-top:1px dashed #DCDCDC;padding:3px 7px;
				padding-bottom:0;
			}
			table#idtabelhtml tfoot td	{
				border-bottom: 2px solid grey;border-top: 1px solid grey;
				padding: 5px 3px;
				font-weight:bolder;
			}
		}
		</style>`,
	//isHeaderView: false,
	//isHeaderTambah: false,
	listTabelID: {},
	gInitGrafikShow: true,
	gTeksAutoSelect: "",
	gHTMLstyleGrafik: `
		<style>
		@media print {
			@page {
				size: landscape;
			}
		}
		</style>
	`,
};

const changeState = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case 'set':
			return {...state, ...rest }
		default:
			return state
	}
}

const store = createStore(changeState);
export default store;