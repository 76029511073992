import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CInputGroup,CFormSelect,
	CButton,
	CImage,
	CLink,
	CTooltip,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
	CSpinner,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialoglookup,
	MyDialogview,
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Hutangkonsi 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uUserSettingObj 		= useSelector(state => state.gListUserSetting);
	const uIsEOMExecuted		= useSelector(state => state.gIsEOMExecuted);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uUserSettingObj.jml_mobile || 30) : (uUserSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderactionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderactionObj.isHeaderView || false;
	const uHeaderPreviewbayar 	= uHeaderactionObj.isHeaderPreview || false;

	//---PAGINATION_DIATUR_BACKEND--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//---END PAGINATION_DIATUR_BACKEND--/

	const uDatakasArr			= JSON.parse(localStorage.getItem("kasgllist")||"[]");
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);

	//--DLG_FORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewObj,setDlgviewObj]		= React.useState({});
	//--END DLG_VIEW--/

	//--DLG_VIEW--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]	= React.useState({});
	//--END DLG_VIEW--/

	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlKlikLihatdetil = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDlgviewObj.header 	= "Lihat Detil: "+(uDatamainArr[vArridx].supplier_caption||"").toUpperCase();
		if((uDatamainArr[vArridx].detil_arr || []).length > 0) {
			setTabelIDPilih(_IDTABEL);
			setDlgviewshow(true);
			return;
		}

		apiLoaddetil(_IDTABEL);
	}
	const hdlKlikBayar = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		setTabelIDPilih(_IDTABEL);
		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDlgformObj.header  = "Bayar Hutang: "+(uDatamainArr[vArridx].supplier_caption||"").toUpperCase();

		uDlgformObj.tgl_bayar 	= UFunc.DbDate();
		uDlgformObj.nilai_bayar	= 0;
		uDlgformObj.status_jurnal= "REGULER";
		uDlgformObj.nilai_hutang = parseFloat(uDatamainArr[vArridx].nilai_hutang);
		uDlgformObj.supplier_caption = (uDatamainArr[vArridx].supplier_caption || "Undf");
		setDlgformshow(true);
	}
	const hdlKlikKaslookup = () => {
		uDlgformObj.kas_caption = "";
		uDlglookupObj.header 	= "Lookup Data: KAS ASAL";
		const vDatalookupArr	= (uDatakasArr || []).map(vItems =>
			({id:vItems.value,caption:vItems.caption}))
		uDlglookupObj.data_arr	= vDatalookupArr;

		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vKasidx	= UFunc.getObjectIndeks(uDatakasArr,_IDITEM,"value");
		if(vKasidx < 0) return;

		uDlgformObj.kas_caption = uDatakasArr[vKasidx].caption || "";
		uDlgformObj.kas_id 		= uDatakasArr[vKasidx].value || 0;
		setDlglookupshow(false);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			const vElfocus = document.getElementById("inpketerangan");
			vElfocus && vElfocus.focus();
		},350)
	}
	const hdlKlikDlgformsimpan = () => {
		const vTabelID 	= parseInt(uTabelIDPilih)||0; if(vTabelID <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);

		let vElfocus = document.getElementById("inpstatustembak");
		if(uDlgformObj.status_jurnal !== "REGULER" && uDlgformObj.status_jurnal !== "TEMBAK") {
			vElfocus && vElfocus.focus();
			showToast("Status Jurnal Tidak Valid !"); return;
		}

		vElfocus = document.getElementById("inptglbayar");
		if(uDlgformObj.tgl_bayar === "") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Bayar Tidak Valid !"); return;
		}
		const vTglBayarDT	= UFunc.toDate(uDlgformObj.tgl_bayar);
		if(UFunc.DateIsBeyond(vTglBayarDT)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Bayar Melewati Tanggal Hari Ini !"); return;
		}

		const vNilaihutang	= parseFloat(uDatamainArr[vArridx].nilai_hutang)||0;
		const vNilaibayar	= parseFloat(uDlgformObj.nilai_bayar)||0;
		vElfocus = document.getElementById("inpnilaibayar");
		if(vNilaibayar <= 0 || vNilaibayar > vNilaihutang) {
			vElfocus && vElfocus.focus();
			showToast("Nilai Bayar Tidak Valid !"); return;
		}

		vElfocus = document.getElementById("inpkascaption");
		if((parseInt(uDlgformObj.kas_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Kas ASAL Harus Dipilih Terlebih Dahulu !"); return;
		}

		apiProsessimpan(vTabelID);
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_hutangkonsi || "";
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} 

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return (<></>)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_hutangkonsi || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=> parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="7" className="text-start">{uBahasaObj.caption_namasupplier||"Nama Supplier"}</CCol>
				<CCol md="1" className="">&middot;</CCol>
				<CCol md="3" className="">{uBahasaObj.caption_nilaihutang||"word_pemilik"}<small className="d-block">(Rp)</small></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>
			<tr className="d-md-none">
				<th width={30} className="px-0"/>
				<th className="px-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,supplier_caption,nilai_hutang,is_loading
			} = vItems;

			vNumber++;
			const vCaptionUpper	= ((supplier_caption||"Undf")).toUpperCase();

			const vSuppliercaption	= vKeyword==="" ? (supplier_caption||"Undf") 
				: ((supplier_caption||"Undf")).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");

			return (
			<tr className={"align-top text-end"+(vKeys===uTabelRowSelect?" classrowselect":"")} id={"idtr"+id}
				key={vKeys}>
			<td className="px-1">{vNumber}.</td>
			
			<td className="px-1">
			<CRow className="mx-0">
				<CCol xs="12" md="7" className="px-1 text-start d-flex justify-content-between">
					<div>{UFunc.renderHTML(vSuppliercaption)}</div>

					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm" className="d-md-none"/>
					) : (
					<CDropdown className="d-md-none">
					<CDropdownToggle caret={false} className="p-0" color="none">
					<CIcon icon="cilOptions" className="classikoncoreui m-0" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu className="py-0">
						<CDropdownItem onClick={()=>hdlKlikLihatdetil(id)}>
							<CIcon icon="cilSearch" className="classikoncoreui align-middle mx-0 me-2" height={23}/>
							Lihat Detil
						</CDropdownItem>
					
						{uIsEOMExecuted && (
						<CDropdownItem onClick={()=>hdlKlikBayar(id)}>
							<CIcon className="classikonbayar align-middle mx-0 me-1" height={25}/>Bayar Hutang
						</CDropdownItem>
						)}
					</CDropdownMenu>
					</CDropdown>
					)}
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol md="1" className="px-1 text-md-center d-none d-md-block">
					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<CTooltip content={(uBahasaObj.caption_lihatdetil||"caption_lihatdetil")+": "+vCaptionUpper}>
					<CLink className="classcursorpointer" onClick={()=>hdlKlikLihatdetil(id)}>
					<CIcon icon="cilSearch" className="classikoncoreui" height={21}/>
					</CLink>
					</CTooltip>
					)}
				</CCol>

				<CCol xs="8" className="px-1 text-start d-md-none">Nilai Hutang (Rp)</CCol>
				<CCol xs="4" md="3" className="px-1 text-primary">{UFunc.formatAngka(nilai_hutang)}</CCol>
				
				<CCol md="1" className="px-1 text-center d-none d-md-block">
					{uIsEOMExecuted ? (
					<CTooltip content={(uBahasaObj.caption_bayarhutang||"caption_bayarhutang")+": "+vCaptionUpper}>
					<CLink className="classikontabel classikonbayar classcursorpointer"
						onClick={()=>hdlKlikBayar(id)}/>
					</CTooltip>
					) : (<>&middot;</>)}
				</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if(vArridx < 0) return (<></>)

		return (
		<CForm>
		{uTokenObj.userhak === "FREEROLE" && (
		<>
			<CRow className=" my-2">
			<CCol xs="3" md="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
			</CCol>
			<CCol className="text-end text-md-start">
				<CFormSelect size="sm"
						onChange={(e)=>{uDlgformObj.status_jurnal=e.target.value;setEfekview(!uEfekview)}}
						value={uDlgformObj.status_jurnal}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
				</CFormSelect>

				<div className="classpetunjuk">
					<small>*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL</small>
				</div>
			</CCol>
		</CRow>
		<div className="mx-0 my-3 classborderbottom"/>
		</>
		)}

		<CRow className="my-2">
			<CCol xs="3" md="4" className="text-start text-md-end text-info">Sisa Hutang</CCol>
			<CCol className="text-end text-md-start fw-bolder">
				Rp{UFunc.formatAngka(uDatamainArr[vArridx].nilai_hutang)}
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="3" md="4" className="text-start text-md-end text-info">Tgl Bayar</CCol>
			<CCol className="text-end text-md-start">
				<CFormInput size="sm" type="date"
					value={uDlgformObj.tgl_bayar}
					onChange={(e)=>{uDlgformObj.tgl_bayar=e.target.value;setEfekview(!uEfekview)}}
					id="inptglbayar"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="3" md="4" className="text-start text-md-end text-info">Nilai Bayar</CCol>
			<CCol className="text-end text-md-start">
				<NumberFormat 
					value={uDlgformObj.nilai_bayar||0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => uDlgformObj.nilai_bayar = (values.value)}
					renderText={values => (
						<CFormInput size="sm" 
							className="text-end"
							value={values}
							onChange={(e)=>{uDlgformObj.nilai_bayar=e.target.value;setEfekview(!uEfekview)}}
							onFocus={(e)=>e.target.select()}
							id="inpnilaibayar"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="3" md="4" className="text-start text-md-end text-info">Asal Kas</CCol>
			<CCol className="text-end text-md-start">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					readOnly={true}
					value={uDlgformObj.kas_caption||""}
					onChange={(e)=>{uDlgformObj.kas_caption=e.target.value;setEfekview(!uEfekview)}}
					onClick={()=>hdlKlikKaslookup()}
					placeholder="--Pilih Kas.." 
					id="inpkascaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikKaslookup()}
					id="btnkaslookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="3" md="4" className="text-start text-md-end">Keterangan</CCol>
			<CCol className="text-end text-md-start">
				<CFormInput size="sm" 
					value={uDlgformObj.keterangan||""}
					onChange={(e)=>{uDlgformObj.keterangan=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpketerangan"/>
			</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if(vArridx < 0) return (<></>)

		const vJumlahnilai = (uDatamainArr[vArridx].detil_arr || []).reduce((vTotal,vItems)=>{
			return vTotal + (parseFloat(vItems.hpp_sat||"0") * parseInt(vItems.qty||"1"))
		},0);

		return (
		<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Detil Item</CCardHeader>
			<CCardBody className="px-0 classfontsmaller">
			<table className="table table-borderless table-striped table-hover" id="idtabeldetil">
			<thead>
			<tr className="classfontsmaller text-center align-top">
				<th width="5%">No</th>
				<th width="15%" className="text-start">Tgl Transaksi</th>
				<th className="text-start">Item Barang</th>
				<th width="10%">N.Satuan (Rp)</th>
				<th width="5%">Qty</th>
				<th width="15%">N.Konsi</th>
			</tr>
			</thead>

			<tbody>
			{(uDatamainArr[vArridx].detil_arr||[]).map((vItems,vKeys)=>{
				const {
					barang_id,barang_caption,hpp_sat,qty,tgl_transaksi,
					ket_hutang,id
				} = vItems;

				return (
				<tr key={vKeys}>
				<td className="px-1 text-end">{vKeys+1}.</td>
				<td className="px-1 text-success">{UFunc.TglAngka(tgl_transaksi)}</td>
				<td className="px-1">
					<div className="text-capitalize fw-bolder">{barang_caption||"Undf"}</div>
					<i className="text-capitalize d-block">{ket_hutang||""}</i>
				</td>
				<td className="px-1 text-end">{UFunc.formatAngka(hpp_sat)}</td>
				<td className="px-1 text-center">{UFunc.formatAngka(qty)}</td>
				<td className="px-1 text-end text-primary">{UFunc.formatAngka(hpp_sat * qty)}</td>
				</tr>
				)
			})}
			</tbody>
			
			<tfoot>
			<tr className="border-top">
			<td colSpan={5} className="text-end">JUMLAH (Rp)</td>
			<td className="fw-bolder text-end">{UFunc.formatAngka(vJumlahnilai)}</td>
			</tr>
			<tr className="">
			<td colSpan={5} className="text-end">Terbayar (Rp)</td>
			<td className="fw-bolder text-end">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_bayar)}</td>
			</tr>
			<tr>
			<td colSpan={5} className="text-end">Sisa Hutang (Rp)</td>
			<td className="fw-bolder text-primary text-end border-top">
				{UFunc.formatAngka( parseFloat(vJumlahnilai) - parseFloat(uDatamainArr[vArridx].nilai_bayar))}
			</td>
			</tr>
			</tfoot>
			</table>
			</CCardBody>
		</CCard>
		)
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = (_PAGE) => {
		setHTMLError500();
		_PAGE		= parseInt(_PAGE)||1;

		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_hutangkonsi || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE,
			send_maxdata	: uMaxData,
			send_firstload	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangkonsi/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Hutangkonsi-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				vLoadArr.map(vItems=>vItems.pg = _PAGE);
				//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.dataobject));
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				setFirstload("TIDAK");

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Hutangkonsi-apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Hutangkonsi-apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddetil = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		if(vArridx < 0) return;

		uDatamainArr[vArridx].is_loading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangkonsi/ld_detil";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			uDatamainArr[vArridx].is_loading = false;
			//console.log("(Hutangkonsi-apiLoaddetil) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				uDatamainArr[vArridx].detil_arr = JSON.parse(output_string.datadetil || "[]");
				uDatamainArr[vArridx].bayar_arr = JSON.parse(output_string.databayar || "[]");

				setTabelIDPilih(_IDTABEL);
				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Hutangkonsi-apiLoaddetil) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_loading = false;
			console.log("(Hutangkonsi-apiLoaddetil) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessimpan = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if(vArridx < 0) return;

		const vElbtnsimpan = document.getElementById("btnDialogSimpan");
		vElbtnsimpan && (vElbtnsimpan.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_datajson: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangkonsi/pr_simpan";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElbtnsimpan && (vElbtnsimpan.disabled = false);
			setLoading(false);
			//console.log("(Hutangkonsi-apiProsessimpan) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				const vNilaibayar = parseFloat(uDlgformObj.nilai_bayar)||0;
				uDatamainArr[vArridx].nilai_hutang = (parseFloat(uDatamainArr[vArridx].nilai_hutang)||0) - vNilaibayar;
				uDatamainArr[vArridx].nilai_bayar = (parseFloat(uDatamainArr[vArridx].nilai_bayar)||0) + vNilaibayar;

				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Hutangkonsi-apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbtnsimpan && (vElbtnsimpan.disabled = false);
			setLoading(false);
			console.log("(Hutangkonsi-apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) apiLoaddata();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]);
			setFirstload("YA");
			setDlgformshow(false); setDlglookupshow(false); setDlgviewshow(false);
		}
	},[])
	//--PAGINATION_DIATUR_BACKEND--/
	React.useEffect(()=>{
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	/*React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Hutangkonsi - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);//-*/
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]); 
	//--END PAGINATION_DIATUR_BACKEND--*/

	/*//--PAGINATION_DIATUR_FRONTEND--/
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
	},[uDatamainArr,uDatamainArr.length])
	//--END PAGINATION_DIATUR_FRONTEND--*/
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderactionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHeaderPreviewbayar) return;

		uHeaderactionObj.isHeaderPreview = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		if(uHTMLError500) return;

		uNavigate("/subhutang/hutangkonsi/listdata");
	},[uHeaderPreviewbayar]);
	React.useEffect(()=>{
		if(!uISDlgformshow)
			{ setDlgformObj({}); setTabelIDPilih(0); return }

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			let vElfocus = document.getElementById("inpnilaibayar");

			if(!isMobile) vElfocus && vElfocus.focus();
		},350)
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uISDlgviewshow)
			{ setDlgviewObj({}); setTabelIDPilih(0); return }
	},[uISDlgviewshow]);
	
	//console.log("Hutangkonsi - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )

	return (
	<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-none d-md-block">Daftar Hutang</span>
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_hutangkonsi || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.header||"Lookup Data"}
			inputValue={""}
			dataItemArr={uDlglookupObj.data_arr || []} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header || ""}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewObj.header||""}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>
	</>
	)
}	

export default Hutangkonsi