import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CInputGroup,CFormSelect,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyDialoglookup,
	MyPagination,
	MyPage500,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Jurnal 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uUserSettingObj 		= useSelector(state => state.gListUserSetting);
	const [uEfekview,setEfekview]= React.useState(false);
	const uIsEOMExecuted		= useSelector(state => state.gIsEOMExecuted);
	const uEOMLabel				= useSelector((state) => state.gEOMLabel) || "";

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uUserSettingObj.jml_mobile || 30) : (uUserSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderactionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderactionObj.isHeaderView || false;
	const uHeaderJurnal 		= uHeaderactionObj.isHeaderJurnal || false;
	const uHeaderBroken 		= uHeaderactionObj.isHeaderBroken || false;

	//---PAGINATION_DIATUR_BACKEND--/
	const uTabelSelectObj 		= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 		= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//---END PAGINATION_DIATUR_BACKEND--/

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDataakunposArr,setDataakunposArr]= React.useState([]);

	//---DLG_FORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//---END DLG_FORM--/

	//---DLG_FORM--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]	= React.useState({});
	//---END DLG_FORM--/

	//---DOM_KOMPONEN--/
	const uElJmldata			= document.getElementById("idjmldata");
	//---END DOM_KOMPONEN--/

	//---HANDLE--/
	const hdlKlikDebetlookup = () => {
		uDlglookupObj.header 		= "Lookup Pos Akun DEBET";
		uDlglookupObj.jenis 		= "JURNALDEBET";
		uDlglookupObj.inputvalue 	= "";
		
		if(uDataakunposArr.length <= 0) {
			apiLoadakun();
			return;
		}

		uDlglookupObj.items_arr 	= uDataakunposArr.map(vItems=>{
			return {
				id : vItems.value,
				caption : vItems.caption,
			}
		});
		setDlglookupshow(true);
	}
	const hdlKlikKreditlookup = () => {
		uDlglookupObj.header 		= "Lookup Pos Akun KREDIT";
		uDlglookupObj.jenis 		= "JURNALKREDIT";
		uDlglookupObj.inputvalue 	= "";
		
		if(uDataakunposArr.length <= 0) {
			apiLoadakun();
			return;
		}

		uDlglookupObj.items_arr 	= uDataakunposArr.map(vItems=>{
			return {
				id : vItems.value,
				caption : vItems.caption,
			}
		});
		setDlglookupshow(true);
	}
	const hdlKlikAkunlookup = () => {
		uDlglookupObj.header 		= "Lookup Pos Akun GL";
		uDlglookupObj.jenis 		= "JURNALBROKEN";
		uDlglookupObj.inputvalue 	= "";
		
		if(uDataakunposArr.length <= 0) {
			apiLoadakun();
			return;
		}

		uDlglookupObj.items_arr 	= uDataakunposArr.map(vItems=>{
			return {
				id : vItems.value,
				caption : vItems.caption,
			}
		});
		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		const vLookupArr = uDlglookupObj.items_arr||[];
		//console.log("(Jurnal - hdlKlikDlglookuppilih) vLookupArr => "+JSON.stringify(vLookupArr));
		const vArridx = UFunc.getObjectIndeks(vLookupArr,_IDITEM); if(vArridx < 0) return;

		let vElnext;
		if(uDlglookupObj.jenis === "JURNALDEBET") {
			uDlgformObj.akundebetcaption = vLookupArr[vArridx].caption;
			uDlgformObj.akundebetid = vLookupArr[vArridx].id;
			vElnext = document.getElementById("inpakunkreditcaption");
		} else if(uDlglookupObj.jenis === "JURNALKREDIT") {
			uDlgformObj.akunkreditcaption = vLookupArr[vArridx].caption;
			uDlgformObj.akunkreditid = vLookupArr[vArridx].id;
			vElnext = document.getElementById("inpjurnalid");
		} else if(uDlglookupObj.jenis === "JURNALBROKEN") {
			uDlgformObj.akunglcaption = vLookupArr[vArridx].caption;
			uDlgformObj.akunglid = vLookupArr[vArridx].id;
			vElnext = document.getElementById("inpjurnalstatus");
		}
		setDlglookupshow(false);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(!isMobile)
				vElnext && vElnext.focus();
		},350);
	}
	const hdlKlikDlgformsimpan = () => {
		if(uDlgformObj.jenis === "JURNALMANUAL") 
			{ prosesJurnal(); return; }
		if(uDlgformObj.jenis === "JURNALBROKEN") 
			{ prosesBroken(); return; }
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_jurnal || "";
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} 

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setEfekview(!uEfekview)
	}
	const prosesJurnal = async() => {
		//--VALIDASI--/
		let vElfocus = document.getElementById("inpakundebetcaption");
		if(parseInt(uDlgformObj.akundebetid) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Pos Akun DEBET Harus Dipilih !"); return
		}
		vElfocus = document.getElementById("inpakunkreditcaption");
		if(parseInt(uDlgformObj.akunkreditid) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Pos Akun KREDIT Harus Dipilih !"); return
		}

		if(parseInt(uDlgformObj.akunkreditid) === parseInt(uDlgformObj.akundebetid) ) {
			vElfocus && vElfocus.focus();
			showToast("Pos Akun DEBET dan KREDIT Tidak Boleh Sama !"); return
		}
		vElfocus = document.getElementById("inpnilai");
		if(parseFloat(uDlgformObj.nilai) === 0) {
			vElfocus && vElfocus.focus();
			showToast("Nilai Transaksi Tidak Valid !"); return
		}
		vElfocus = document.getElementById("inptgltransaksi");
		if((uDlgformObj.tgl_transaksi||"")==="") {
			vElfocus && vElfocus.focus();
			showToast("Tgl Transaksi Tidak Valid !"); return
		} else {
			const vTgltransksiDT = UFunc.toDate(uDlgformObj.tgl_transaksi);
			if(UFunc.DateIsBeyond(vTgltransksiDT)) {
				vElfocus && vElfocus.focus();
				showToast("Tgl Transaksi Melewati Hari Init !"); return
			}
		}
		if((uDlgformObj.waktu_input||"")==="") {
			document.getElementById("inpjaminputdesktop") && 
				document.getElementById("inpjaminputdesktop").focus();
			document.getElementById("inpjaminputmobile") && 
				document.getElementById("inpjaminputmobile").focus();

			showToast("Waktu Input Tidak Valid !"); return
		}

		vElfocus = document.getElementById("inptglinput");
		if((uDlgformObj.tgl_input||"")==="") {
			vElfocus && vElfocus.focus();
			showToast("Tgl Transaksi Tidak Valid !"); return
		} else {
			const vTglinputDT = UFunc.toDatetime(uDlgformObj.tgl_input+" "+
				uDlgformObj.waktu_input);

			if(UFunc.DateIsBeyond(vTglinputDT)) {
				vElfocus && vElfocus.focus();
				showToast("Tgl Input Melewati Hari Init !"); return
			}
		}
		vElfocus = document.getElementById("inptabelid");
		if(parseInt(uDlgformObj.tabelrefid) < 0) {
			vElfocus && vElfocus.focus();
			showToast("ID Tabel Ref Tidak Valid !"); return
		}
		vElfocus = document.getElementById("inpketerangan");
		if((uDlgformObj.ket||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("Keterangan Jurnal Harus Diisi !"); return
		}
		if(!uIsEOMExecuted) 
			if(uEOMLabel !== "" && uEOMLabel.toUpperCase() !== "EOM WARNING") {
				vElfocus = document.getElementById("inpjurnalid");
				const vJurnalID = parseInt(uDlgformObj.jurnalid)||0;
				if(vJurnalID <= 0) {
					vElfocus && vElfocus.focus();
					showToast("Setelah ID Jurnal Harus Diisi !"); return
				}
			}
		//--END VALIDASI--/

		if(await Konfirm(
			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Akun DEBET</div>"+
				"<div class='text-end fw-bolder'>"+uDlgformObj.akundebetcaption+"</div>"+
			"</div>"+
			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Akun KREDIT</div>"+
				"<div class='text-end fw-bolder'>"+uDlgformObj.akunkreditcaption+"</div>"+
			"</div>"+

			"<div class='my-3 classborderbottom'></div>"+

			"<div class='w-100 my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Tgl Transaksi</div>"+
				"<div class='text-end fw-bolder'>"+UFunc.TglAngka(uDlgformObj.tgl_transaksi)+"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Tgl Input</div>"+
				"<div class='text-end fw-bolder'>"+
					UFunc.TglAngka(uDlgformObj.tgl_input)+
					" "+(uDlgformObj.waktu_input)+
				"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Nilai Transaksi (Rp)</div>"+
				"<div class='text-end fw-bolder text-success'>"+UFunc.formatAngka(uDlgformObj.nilai)+"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start classfontsmaller'>After ID Jurnal</div>"+
				"<div class='text-end fw-bolder'>"+(uDlgformObj.jurnalid || "0")+"</div>"+
			"</div>"+

			"<div class='my-3 classborderbottom'></div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Tabel Ref ID</div>"+
				"<div class='text-end fw-bolder'>"+(uDlgformObj.tabelrefid||"")+"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Tabel Ref</div>"+
				"<div class='text-end fw-bolder'>"+(uDlgformObj.tabelref||"-")+"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>No.Ref/Nota</div>"+
				"<div class='text-end fw-bolder'>"+(uDlgformObj.noref||"-")+"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start pe-3'>Ket</div>"+
				"<div class='text-end fw-bolder fst-italic'>"+(uDlgformObj.ket)+"</div>"+
			"</div>"+

			"<div class='my-3 classborderbottom'></div>"+

			"<div class='fw-bolder m-3 w-100 text-center'>Apakah Anda Yakin ?</div>"+
			"")) apiProsesjurnal();
	}
	const prosesBroken = async() => {
		//--VALIDASI--/
		let vElfocus = document.getElementById("inpakunglcaption");
		if((parseInt(uDlgformObj.akunglid)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Pos Akun GL Harus Dipilih !"); return
		}
		vElfocus = document.getElementById("inpjurnalstatus");
		if(uDlgformObj.status!=="DEBET" && uDlgformObj.status!=="KREDIT") {
			vElfocus && vElfocus.focus();
			showToast("Status Jurnal Transaksi Tidak Valid !"); return
		}
		vElfocus = document.getElementById("inpnilai");
		if(parseFloat(uDlgformObj.nilai) === 0) {
			vElfocus && vElfocus.focus();
			showToast("Nilai Transaksi Tidak Valid !"); return
		}

		vElfocus = document.getElementById("inptgltransaksi");
		if((uDlgformObj.tgl_transaksi||"")==="") {
			vElfocus && vElfocus.focus();
			showToast("Tgl Transaksi Tidak Valid !"); return
		} else {
			const vTgltransksiDT = UFunc.toDate(uDlgformObj.tgl_transaksi);
			if(UFunc.DateIsBeyond(vTgltransksiDT)) {
				vElfocus && vElfocus.focus();
				showToast("Tgl Transaksi Melewati Hari Init !"); return
			}
		}
		if((uDlgformObj.waktu_input||"")==="") {
			document.getElementById("inpjaminputdesktop") && 
				document.getElementById("inpjaminputdesktop").focus();
			document.getElementById("inpjaminputmobile") && 
				document.getElementById("inpjaminputmobile").focus();

			showToast("Waktu Input Tidak Valid !"); return
		}

		vElfocus = document.getElementById("inptglinput");
		if((uDlgformObj.tgl_input||"")==="") {
			vElfocus && vElfocus.focus();
			showToast("Tgl Transaksi Tidak Valid !"); return
		} else {
			const vTglinputDT = UFunc.toDatetime(uDlgformObj.tgl_input+" "+
				uDlgformObj.waktu_input);

			if(UFunc.DateIsBeyond(vTglinputDT)) {
				vElfocus && vElfocus.focus();
				showToast("Tgl Input Melewati Hari Init !"); return
			}
		}
		vElfocus = document.getElementById("inptabelid");
		if(parseInt(uDlgformObj.tabelrefid) < 0) {
			vElfocus && vElfocus.focus();
			showToast("ID Tabel Ref Tidak Valid !"); return
		}
		vElfocus = document.getElementById("inpketerangan");
		if((uDlgformObj.ket||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("Keterangan Jurnal Harus Diisi !"); return
		}
		//--END VALIDASI--/

		if(await Konfirm(
			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Akun GL Tambahan</div>"+
				"<div class='text-end fw-bolder'>"+uDlgformObj.akunglcaption+"</div>"+
			"</div>"+
			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Status Jurnal</div>"+
				"<div class='text-end fw-bolder'>"+uDlgformObj.status+"</div>"+
			"</div>"+

			"<div class='my-3 classborderbottom'></div>"+

			"<div class='w-100 my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Tgl Transaksi</div>"+
				"<div class='text-end fw-bolder'>"+UFunc.TglAngka(uDlgformObj.tgl_transaksi)+"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Tgl Input</div>"+
				"<div class='text-end fw-bolder'>"+
					UFunc.TglAngka(uDlgformObj.tgl_input)+
					" "+(uDlgformObj.waktu_input)+
				"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Nilai Transaksi (Rp)</div>"+
				"<div class='text-end fw-bolder text-success'>"+UFunc.formatAngka(uDlgformObj.nilai)+"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start classfontsmaller'>After ID Jurnal</div>"+
				"<div class='text-end fw-bolder'>"+(uDlgformObj.jurnalid || "0")+"</div>"+
			"</div>"+

			"<div class='my-3 classborderbottom'></div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Tabel Ref ID</div>"+
				"<div class='text-end fw-bolder'>"+(uDlgformObj.tabelrefid||"")+"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>Tabel Ref</div>"+
				"<div class='text-end fw-bolder'>"+(uDlgformObj.tabelref||"-")+"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start'>No.Ref/Nota</div>"+
				"<div class='text-end fw-bolder'>"+(uDlgformObj.noref||"-")+"</div>"+
			"</div>"+

			"<div class='my-2 d-flex justify-content-between'>"+
				"<div class='text-start pe-3'>Ket</div>"+
				"<div class='text-end fw-bolder fst-italic'>"+(uDlgformObj.ket)+"</div>"+
			"</div>"+

			"<div class='my-3 classborderbottom'></div>"+

			"<div class='fw-bolder m-3 w-100 text-center'>Apakah Anda Yakin ?</div>"+
			"")) apiProsesbroken();
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return (<></>)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_jurnal || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=> parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		const vStylekolfixed	= {
			position: "sticky",
			left: 0,
			zIndex: 1,
			backgroundColor:"#f0f0f0",
			minWidth:90,
		}

		return (
		<div style={{overflowX: "auto"}}>
		<table style={{minWidth:1500,width:"100%"}} 
			className="table table-border table-striped table-hover" id="idtabeldata">
			<thead>
				<tr className="text-center align-top classfontsmaller">
				<th className="text-start px-1 border" style={vStylekolfixed}>Tabel ID</th>
				<th width={100} className="px-1 border">Tgl Transaksi</th>
				<th className="px-1 text-start border" style={{minWidth:250}}>Keterangan</th>
				<th width={200} className="px-1 border">Tgl Input</th>
				<th width={100} className="px-1 border">No.Nota</th>
				<th width="15%" style={{minWidth:200}} className="px-1 text-start border">Nama GL</th>
				<th width={80} className="px-1 border">Status</th>
				<th width={86} className="px-1 border">Nilai (Rp)</th>
				<th width={95} className="px-1 border">Saldo (Rp)</th>
				<th width={200} className="px-1 text-start border">Tabelref</th>
				</tr>
			</thead>

			<tbody>
			{vDataviewArr.map((vItems,vKeys)=>{
				const {
					tgl_transaksi,tgl_input,userinput,id_gl,caption_gl,ket,noref,
					status,nilai,saldo_akhir,id_tabel,tabelref,
					id
				} = vItems;

				vNumber++;

				const vStatus		= (status||"-");
				const vTgltransaksi = vKeyword==="" ? UFunc.TglAngka(tgl_transaksi) 
					: UFunc.TglAngka(tgl_transaksi).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
				const vTglinput = vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
					: UFunc.WaktuAngka(tgl_input).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
				const vUserinput = vKeyword==="" ? UFunc.Usercaptionfilter(userinput||"UNDF") 
					: UFunc.Usercaptionfilter(userinput||"UNDF").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
				const vGLcaption = vKeyword==="" ? (caption_gl||"-") 
					: (caption_gl||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
				const vKeterangan = vKeyword==="" ? (ket||"-") 
					: (ket||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
				const vNoref = vKeyword==="" ? (noref||"-") 
					: (noref||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
				const vTabelref = vKeyword==="" ? (tabelref||"[tabelref]") 
					: (tabelref||"[tabelref]").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");

				return (
				<tr id={"idtr"+id} className={"align-top text-end"+((uTabelRowSelect===vKeys && !isMobile)?" classrowselect":"")}
					key={vKeys}>
					<td className="px-1 text-start border" style={vStylekolfixed}>
						<b className="text-primary">[{id}]</b>
					</td>

					<td className="px-1 text-start text-info">{UFunc.renderHTML(vTgltransaksi)}</td>
					<td className="px-1 text-start fst-italic classfontsmaller">{UFunc.renderHTML(vKeterangan)}</td>
					<td className="px-1 text-center text-danger classfontsmaller">
						<small>[
							{UFunc.renderHTML(vTglinput)} &middot; {UFunc.renderHTML(vUserinput)}
						]</small>
					</td>
					<td className="px-1 text-center fw-bolder">{UFunc.renderHTML(vNoref)}</td>
					
					<td className="px-1 text-start">
						<div className="d-flex align-items-start">
						<small className="text-primary">[{id_gl}]</small>
						<small className="mx-1">&middot;</small>
						<small>{UFunc.renderHTML(vGLcaption)}</small>
						</div>
					</td>

					<td className="px-1 text-center">{UFunc.renderHTML(vStatus)}</td>
					<td className="px-1 fw-bolder text-success">{UFunc.formatAngka(nilai)}</td>
					<td className="px-1">{UFunc.formatAngka(saldo_akhir)}</td>
					<td className="px-1">
						<div className="d-flex align-items-start">
						<small className="fw-bolder">[{id_tabel}]</small>
						<span className="mx-1">&middot;</span>
						<small className="fw-bolder">{UFunc.renderHTML(vTabelref)}</small>
						</div>
					</td>
				</tr>
				)
			})}
			</tbody>
		</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		if(uDlgformObj.jenis === "JURNALMANUAL") return contentFormjurnal();
		if(uDlgformObj.jenis === "JURNALBROKEN") return contentFormbroken();

		return (<></>)//>
	}
	const contentFormjurnal = () => {
		if(uDlgformObj.jenis !== "JURNALMANUAL") return (<></>)//>

		return (
		<>
		<CForm>
		<CRow className="mx-0 my-2 py-4 border border-danger rounded-3">
			<CCol xs="12" className="d-none d-md-block">Pos Akun Jurnal</CCol>
			<CCol md="6">
				<div className="d-md-none">Akun Debet</div>
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					value={uDlgformObj.akundebetcaption||""}
					onClick={()=>hdlKlikDebetlookup()}
					placeholder="--Pilih AKUN DEBET"
					id="inpakundebetcaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikDebetlookup()}
					id="btnakundebetlookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
			<CCol md="6">
				<div className="d-md-none mt-2">Akun Kredit</div>
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					placeholder="--Pilih AKUN KREDIT"
					value={uDlgformObj.akunkreditcaption||""}
					onClick={()=>hdlKlikKreditlookup()}
					id="inpakunkreditcaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikKreditlookup()}
					id="btnakunkreditlookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end text-danger">
				<small className="d-md-none">Setelah ID Tabel</small>
				<span className="d-none d-md-block">Setelah ID Tabel</span>
			</CCol>
			<CCol xs="8" md="5" lg="3">
				<CFormInput size="sm" type="number"
					className="text-end"
					min={0}
					value={uDlgformObj.jurnalid||0}
					onChange={(e)=>{uDlgformObj.jurnalid = e.target.value; setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					id="inpjurnalid"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">Nilai (Rp)</CCol>
			<CCol xs="8" md="5" lg="4">
				<NumberFormat 
					value={uDlgformObj.nilai || 0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values)=>{uDlgformObj.nilai = values.value;}}
					renderText={values => (
						<CFormInput size="sm" 
							className="text-end"
							value={values}
							onChange={(e)=>{uDlgformObj.nilai = e.target.value; setEfekview(!uEfekview)}}
							onFocus={(e)=>e.target.select()}
							id="inpnilai"/>
					)} 
					prefix={isMobile?"":"Rp"}/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">Tgl Jurnal</CCol>
			<CCol xs="8" md="5" lg="4">
				<CFormInput size="sm" type="date"
					value={uDlgformObj.tgl_transaksi}
					onChange={(e)=>{uDlgformObj.tgl_transaksi = e.target.value; setEfekview(!uEfekview)}}
					id="inptgltransaksi"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end text-danger">Tgl Input</CCol>
			<CCol xs="8" md="5" lg="4">
				<CFormInput size="sm" type="date"
					value={uDlgformObj.tgl_input}
					onChange={(e)=>{uDlgformObj.tgl_input = e.target.value; setEfekview(!uEfekview)}}
					onKeyDown={(e)=>{
						if(e.which !== 13) return;

						e.preventDefault(); e.stopPropagation();
						document.getElementById("inpjaminputmobile") && 
							document.getElementById("inpjaminputmobile").focus();
						document.getElementById("inpjaminputdesktop") && 
							document.getElementById("inpjaminputdesktop").focus();
					}}
					id="inptglinput"/>
			</CCol>
			<CCol md="3" className="d-none d-md-block text-danger">
				<CFormInput size="sm" type="time" step="1"
					value={uDlgformObj.waktu_input||"00:00:00"}
					onChange={(e)=>{uDlgformObj.waktu_input = e.target.value; setEfekview(!uEfekview)}}
					onKeyDown={(e)=>{
						if(e.which !== 13) return;

						e.preventDefault(); e.stopPropagation();
						document.getElementById("inpnoref") && 
							document.getElementById("inpnoref").focus();
					}}
					id="inpjaminputdesktop"/>
			</CCol>
		</CRow>

		<CRow className="my-2 d-md-none text-danger">
			<CCol xs="4" className="text-lg-end">Waktu Input</CCol>
			<CCol xs="4" className="">
				<CFormInput size="sm" type="time" step="1"
					value={uDlgformObj.waktu_input||"00:00:00"}
					onChange={(e)=>{uDlgformObj.waktu_input = e.target.value; setEfekview(!uEfekview)}}
					id="inpjaminputmobile"/>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end text-warning">No.Ref</CCol>
			<CCol xs="8" md="5" lg="4" className="">
				<CFormInput size="sm"
					maxLength={100}
					value={uDlgformObj.noref}
					onChange={(e)=>{uDlgformObj.noref = e.target.value; setEfekview(!uEfekview)}}
					id="inpnoref"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end text-warning">ID Tabel Ref</CCol>
			<CCol xs="8" md="5" lg="3">
				<CFormInput size="sm" type="number" 
					value={uDlgformObj.tabelrefid || 0}
					onChange={(e)=>{uDlgformObj.tabelrefid = e.target.value; setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					className="text-end"
					id="inptabelid"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end text-warning">Tabel Ref</CCol>
			<CCol xs="8" md="5" lg="4" className="">
				<CFormInput size="sm"
					maxLength={100}
					value={uDlgformObj.tabelref}
					onChange={(e)=>{uDlgformObj.tabelref = e.target.value; setEfekview(!uEfekview)}}
					id="inptabelref"/>
			</CCol>
		</CRow>

		<div className="my-3 d-md-none classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">Keterangan</CCol>
			<CCol xs="12" md="8" className="">
				<CFormInput size="sm"
					maxLength={255}
					value={uDlgformObj.ket}
					onChange={(e)=>{uDlgformObj.ket = e.target.value; setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpketerangan"/>
			</CCol>
		</CRow>

		</CForm>
		</>
		)//>
	}
	const contentFormbroken = () => {
		if(uDlgformObj.jenis !== "JURNALBROKEN") return (<></>)//>

		return (
		<>
		<CForm>
		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">
				Nama Pos GL
			</CCol>
			<CCol xs="8" lg="4">
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					placeholder="--Pilih AKUN GL"
					value={uDlgformObj.akunglcaption||""}
					onClick={()=>hdlKlikAkunlookup()}
					id="inpakunglcaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikAkunlookup()}
					id="btnakungllookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">Status Transaksi</CCol>
			<CCol xs="8" md="5" lg="4">
				<CFormSelect size="sm" 
					value={uDlgformObj.status}
					onChange={(e)=>{uDlgformObj.status=e.target.value;setEfekview(!uEfekview)}}
					id="inpjurnalstatus">
				<option value="DEBET">DEBET</option>
				<option value="KREDIT">KREDIT</option>
				</CFormSelect>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">
				<small className="d-md-none">Setelah ID Tabel</small>
				<span className="d-none d-md-block">Setelah ID Tabel</span>
			</CCol>
			<CCol xs="8" md="5" lg="3">
				<CFormInput size="sm" type="number"
					className="text-end"
					min={0}
					value={uDlgformObj.jurnalid||0}
					onChange={(e)=>{uDlgformObj.jurnalid = e.target.value; setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					id="inpjurnalid"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">Nilai (Rp)</CCol>
			<CCol xs="8" md="5" lg="4">
				<NumberFormat 
					value={uDlgformObj.nilai || 0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values)=>{uDlgformObj.nilai = values.value;}}
					renderText={values => (
						<CFormInput size="sm" 
							className="text-end"
							value={values}
							onChange={(e)=>{uDlgformObj.nilai = e.target.value; setEfekview(!uEfekview)}}
							onFocus={(e)=>e.target.select()}
							id="inpnilai"/>
					)} 
					prefix={isMobile?"":"Rp"}/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">Tgl Jurnal</CCol>
			<CCol xs="8" md="5" lg="4">
				<CFormInput size="sm" type="date"
					value={uDlgformObj.tgl_transaksi}
					onChange={(e)=>{uDlgformObj.tgl_transaksi = e.target.value; setEfekview(!uEfekview)}}
					id="inptgltransaksi"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">Tgl Input</CCol>
			<CCol xs="8" md="5" lg="4">
				<CFormInput size="sm" type="date"
					value={uDlgformObj.tgl_input}
					onChange={(e)=>{uDlgformObj.tgl_input = e.target.value; setEfekview(!uEfekview)}}
					onKeyDown={(e)=>{
						if(e.which !== 13) return;

						e.preventDefault(); e.stopPropagation();
						document.getElementById("inpjaminputmobile") && 
							document.getElementById("inpjaminputmobile").focus();
						document.getElementById("inpjaminputdesktop") && 
							document.getElementById("inpjaminputdesktop").focus();
					}}
					id="inptglinput"/>
			</CCol>
			<CCol md="3" className="d-none d-md-block">
				<CFormInput size="sm" type="time" step="1"
					value={uDlgformObj.waktu_input||"00:00:00"}
					onChange={(e)=>{uDlgformObj.waktu_input = e.target.value; setEfekview(!uEfekview)}}
					onKeyDown={(e)=>{
						if(e.which !== 13) return;

						e.preventDefault(); e.stopPropagation();
						document.getElementById("inpnoref") && 
							document.getElementById("inpnoref").focus();
					}}
					id="inpjaminputdesktop"/>
			</CCol>
		</CRow>

		<CRow className="my-2 d-md-none">
			<CCol xs="4" className="text-lg-end">Waktu Input</CCol>
			<CCol xs="4" className="">
				<CFormInput size="sm" type="time" step="1"
					value={uDlgformObj.waktu_input||"00:00:00"}
					onChange={(e)=>{uDlgformObj.waktu_input = e.target.value; setEfekview(!uEfekview)}}
					id="inpjaminputmobile"/>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">No.Ref</CCol>
			<CCol xs="8" md="5" lg="4" className="">
				<CFormInput size="sm"
					maxLength={100}
					value={uDlgformObj.noref}
					onChange={(e)=>{uDlgformObj.noref = e.target.value; setEfekview(!uEfekview)}}
					id="inpnoref"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">ID Tabel Ref</CCol>
			<CCol xs="8" md="5" lg="3">
				<CFormInput size="sm" type="number" 
					value={uDlgformObj.tabelrefid || 0}
					onChange={(e)=>{uDlgformObj.tabelrefid = e.target.value; setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					className="text-end"
					id="inptabelid"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">Tabel Ref</CCol>
			<CCol xs="8" md="5" lg="4" className="">
				<CFormInput size="sm"
					maxLength={100}
					value={uDlgformObj.tabelref}
					onChange={(e)=>{uDlgformObj.tabelref = e.target.value; setEfekview(!uEfekview)}}
					id="inptabelref"/>
			</CCol>
		</CRow>

		<div className="my-3 d-md-none classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="4" className="text-lg-end">Keterangan</CCol>
			<CCol xs="12" md="8" className="">
				<CFormInput size="sm"
					maxLength={255}
					value={uDlgformObj.ket}
					onChange={(e)=>{uDlgformObj.ket = e.target.value; setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpketerangan"/>
			</CCol>
		</CRow>

		</CForm>
		</>
		)//>
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = (_PAGE) => {
		setHTMLError500();
		_PAGE		= parseInt(_PAGE)||1;

		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_jurnal || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE,
			send_maxdata	: uMaxData,
			send_firstload	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jurnal/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Jurnal-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				vLoadArr.map(vItems=>vItems.pg = _PAGE);
				//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.dataobject));
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}
				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Jurnal-apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Jurnal-apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadakun = () => {
		const vEls = document.getElementById("btnDialogSimpan");
		vEls && (vEls.disabled=true);
		setDataakunposArr([]);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jurnal/ld_akun";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vEls && (vEls.disabled=false);
			setLoading(false);
			//console.log("(Jurnal-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr	= JSON.parse(output_string.dataposakun || "[]");
				setDataakunposArr(vTmpArr);

				uDlglookupObj.items_arr 	= vTmpArr.map(vItems=>{
					return {
						id : vItems.value,
						caption : vItems.caption,
					}
				});
				setDlglookupshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Jurnal-apiLoadakun) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) => {
			vEls && (vEls.disabled=false);
			setLoading(false);
			console.log("(Jurnal-apiLoadakun) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesjurnal = () => {
		const vEls = document.getElementById("btnDialogSimpan");
		vEls && (vEls.disabled=true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parsdata: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jurnal/pr_jurnal";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vEls && (vEls.disabled=false);
			setLoading(false);
			//console.log("(Jurnal-apiProsesjurnal) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				console.log("(Jurnal-apiProsesjurnal) output_string.dataobjectnew : "+output_string.dataobjectnew);
				const vInputArr = JSON.parse(output_string.dataobjectnew || "[]");

				const vAfterjurnalid = parseInt(uDlgformObj.jurnalid)||0;
				const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vAfterjurnalid);
				if(vArridx <= 0) {
					setDatamainArr([...vInputArr,...uDatamainArr]);
				} else {
					uDatamainArr.filter((vItems,vKeys) => parseInt(vItems.id) > vAfterjurnalid).map(vItems =>
						vItems.id = parseInt(vItems.id) + 2
						);
					uDatamainArr.splice(vArridx,0,...vInputArr)
				}

				setJmlData(uJmlData + 2);
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Jurnal-apiProsesjurnal) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEls && (vEls.disabled=false);
			setLoading(false);
			console.log("(Jurnal-apiProsesjurnal) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesbroken = () => {
		const vEls = document.getElementById("btnDialogSimpan");
		vEls && (vEls.disabled=true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vEls && (vEls.disabled=false);
			setLoading(false);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parsdata: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jurnal/pr_broken";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vEls && (vEls.disabled=false);
			setLoading(false);
			//console.log("(Jurnal-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				const vInputObj = JSON.parse(output_string.dataobjectnew || "{}");

				const vAfterjurnalid= parseInt(uDlgformObj.jurnalid)||0;
				const vArridx		= UFunc.getObjectIndeks(uDatamainArr,vAfterjurnalid);
				if(vArridx <= 0) {
					setDatamainArr([vInputObj,...uDatamainArr]);
				} else {
					uDatamainArr.filter((vItems,vKeys) => parseInt(vItems.id) > vAfterjurnalid).map(vItems =>
						vItems.id = parseInt(vItems.id) + 1
						);
					uDatamainArr.splice(vArridx,0,vInputObj)
				}

				setJmlData(uJmlData + 1);
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Jurnal-apiProsesbroken) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEls && (vEls.disabled=false);
			setLoading(false);
			console.log("(Jurnal-apiProsesbroken) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) apiLoaddata();

		return () => {
			setDlglookupshow(false); setDlgformshow(false); 
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]);setJmlData(0);setJmlHal(1);
			setFirstload("YA");
			setDlgformObj({});setDlglookupObj({});
		}
	},[])
	//--PAGINATION_DIATUR_BACKEND--/
	React.useEffect(()=>{
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	/*React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Jurnal - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);//-*/
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems =>
			parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]); 
	//--END PAGINATION_DIATUR_BACKEND--*/
	/*//--PAGINATION_DIATUR_FRONTEND--/
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
	},[uDatamainArr,uDatamainArr.length])
	//--END PAGINATION_DIATUR_FRONTEND--*/
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderactionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHeaderJurnal) return;

		uHeaderactionObj.isHeaderJurnal = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		if(uHTMLError500) return;

		uDlgformObj.header 	= "Input Jurnal Manual";
		uDlgformObj.jenis	= "JURNALMANUAL";
		if(uDlgformObj.nilai === undefined) uDlgformObj.nilai = 0;
		//console.log("(Jurnal - [uHeaderJurnal]) uDlgformObj.waktu_input => "+uDlgformObj.waktu_input);
		if(uDlgformObj.waktu_input===undefined) {
			const vTglNowDT = new Date();
			uDlgformObj.waktu_input = UFunc.leadZero(vTglNowDT.getHours()) + ":" +
				UFunc.leadZero(vTglNowDT.getMinutes()) + ":" +
				UFunc.leadZero(vTglNowDT.getSeconds());
		}
		setDlgformshow(true);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			if(!isMobile)
				document.getElementById("inpakundebetcaption") &&
					document.getElementById("inpakundebetcaption").focus();
		},350);
	},[uHeaderJurnal]);
	React.useEffect(()=>{
		if(!uHeaderBroken) return;

		uHeaderactionObj.isHeaderBroken = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		if(uHTMLError500) return;

		uDlgformObj.header 	= "Input Jurnal Broken";
		uDlgformObj.jenis	= "JURNALBROKEN";
		if(uDlgformObj.nilai === undefined) uDlgformObj.nilai = 0;
		if(uDlgformObj.status === undefined) uDlgformObj.status = "DEBET";
		//console.log("(Jurnal - [uHeaderBroken]) uDlgformObj.waktu_input => "+uDlgformObj.waktu_input);
		if(uDlgformObj.waktu_input === undefined) {
			const vTglNowDT = new Date();
			uDlgformObj.waktu_input = UFunc.leadZero(vTglNowDT.getHours()) + ":" +
				UFunc.leadZero(vTglNowDT.getMinutes()) + ":" +
				UFunc.leadZero(vTglNowDT.getSeconds());
		}

		setDlgformshow(true);
	},[uHeaderBroken]);
	React.useEffect(()=>{
		if(!uISDlgformshow) 
			{ setDlgformObj({}); return }

		if(uDlgformObj.tgl_transaksi === undefined) 
			uDlgformObj.tgl_transaksi = UFunc.DbDate();
		if(uDlgformObj.tgl_input === undefined) 
			uDlgformObj.tgl_input = UFunc.DbDate();
	},[uISDlgformshow]);
	
	//console.log("Jurnal - uActiveroute => "+(uActiveroute));
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )//>

	return (
	<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_jurnal || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>

			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.header}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.items_arr||[]} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			{...props}/>

		<MyDialogform
			options={{size:"lg",centered:true}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow}
			dialogHeader={uDlgformObj.header||""}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}
			{...props}/>
	</>
	)//>
}	

export default Jurnal