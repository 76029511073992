import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CTooltip,
	CLink,
	CListGroup,
	CListGroupItem,
	CForm,
	CFormInput,
	CFormSelect,
	CSpinner,
	CInputGroup,
	CInputGroupText,
	CButton,
	CBadge,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,CDropdownDivider,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPagination,
	MyDialogform,
	MyDialogview,
	MyDialoglookup,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../package.json');
const Hutanglain 	= (props) => {
	const uComponentname 	= Hutanglain.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//--GLOBAL_VARS--/
	const uISAllowedhapus	= uTokenObj.userhak==="FREEROLE";// || uTokenObj.userhak==="ADMIN";
	const uMaxData							= isMobile? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	//---DATA--/
	const uKeywordObj						= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  				= useSelector(state => state.gInitHeaderAction);
	const uHandelView 						= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah						= uHeaderActionObj.isHeaderTambah || false;
	const uHandelEdit 						= uHeaderActionObj.isHeaderEdit || false;
	const [uFirstload,setFirstload]			= React.useState("YA");

	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const uElJmldata	= document.getElementById("idjmldata");
	//---END DATA--/

	//--FORMDIALOG--/
	const uKasGLArr							= JSON.parse(localStorage.getItem("kasgllist") || "[]");

	const [uIsShowForm,setShowForm]			= React.useState(false);
	const [uDialogHeader,setDialogHeader]	= React.useState("");

	const [uInpTglBayar,setInpTglBayar]		= React.useState(UFunc.DbDate());
	const [uInpNilaiBayar,setInpNilaibayar]	= React.useState(0);
	const [uInpKasID,setInpKasID]			= React.useState(0);
	const [uInpKascaption,setInpKascaption]	= React.useState("");
	const [uInpNonota,setInpNonota]			= React.useState("");
	const [uInpStatustembak,setInpStatustembak]= React.useState("REGULER");

	const [uElBtnSimpan,setElBtnSimpan]		= React.useState(document.getElementById("btnDialogSimpan"));
	//--END FORMDIALOG--/

	const [uIsShowhistori,setShowhistori]	= React.useState(false);
	const [uDataHistoriArr,setDataHistoriArr]= React.useState([]);

	//--DLG_LOOKUP--/
	const [uDlglookupheader,setDlglookupheader]	= React.useState("");
	const [uInpDlglookup,setInpDlglookup]		= React.useState("");
	const [uDataDlglookupArr,setDataDlglookupArr]= React.useState([]);
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false);
	//--END DLG_LOOKUP--/
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowForm(false) }
	const hdlKlikFormSimpan=()=>{
		//---VALIDASI--/
		if(UFunc.isEmpty(uInpTglBayar)) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.caption_tglbayar||"caption_tglbayar")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}

		const vTanggalDt 	= new Date(uInpTglBayar);

		const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
		if(vDateBeyond) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
			return;
		}
		const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
		if(vDateExceededBl) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
			return;
		}

		if((parseInt(uInpNilaiBayar)||0) <= 0 ) {
			document.getElementById("inpnilaibayar").focus();
			showToast((uBahasaObj.caption_nilaibayar||"caption_nilaibayar")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}
		if((parseInt(uInpKasID)||0) <= 0) {
			document.getElementById("inpkascaption").focus();
			showToast((uBahasaObj.caption_asalkas||"caption_kaspenerima")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		//---END VALIDASI--/

		apiSimpanBayar();
	}
	const hdlToggleHistori=()=>{ setShowhistori(false) }
	const hdlToggleDlglookup = () => { setDlglookupshow(false) }
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		const vIdx = UFunc.getObjectIndeks(uDataDlglookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		const vElsfocusnext = uElBtnSimpan;
		setInpKasID(uDataDlglookupArr[vIdx].id)
		setInpKascaption(uDataDlglookupArr[vIdx].caption)
		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			vElsfocusnext && vElsfocusnext.focus()
		},350);
	}
	const hdlKlikKaslookup = () => {
		//setInpDlglookup(uInpKascaption);
		setInpKascaption(""); setInpKasID("");
		const vTmpArr	= uKasGLArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_asalkas||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKlikHistori = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);

		//alert(vArridx);return;
		const vHeadercaption = (uBahasaObj.caption_historibayar||"caption_historibayar")+": "+
			((uDatamainArr[vArridx].jenis_hutang||"")).toUpperCase()+" - "+
			(UFunc.HariAngka(uDatamainArr[vArridx].tgl_hutang||""))+
			"";
		setDialogHeader(vHeadercaption);
		if((uDatamainArr[vArridx].histori_arr||[]).length > 0) {
			setDataHistoriArr(uDatamainArr[vArridx].histori_arr);
			setShowhistori(true);
			return;
		}

		apiLoadHistori(_IDTABEL);
	}
	const hdlKlikBayar = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setTabelIDPilih(_IDTABEL);
		const vSisaHutang	= (parseInt(uDatamainArr[vArridx].nilai_hutang)||0)
			- (parseInt(uDatamainArr[vArridx].terbayar)||0);
		const vHeadercaption= (uBahasaObj.caption_bayarhutang||"caption_bayarhutang")+": "+
			((uDatamainArr[vArridx].jenis_hutang||"")).toUpperCase()+
			" ("+(uBahasaObj.word_sisa||"word_sisa")+" Rp "+UFunc.formatAngka(vSisaHutang)+")"+
			"";
		setDialogHeader(vHeadercaption);
		setShowForm(true);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vHeadercaption = 
			((uDatamainArr[vArridx].jenis_hutang||"")).toUpperCase()+" - "+
			(UFunc.HariAngka(uDatamainArr[vArridx].tgl_hutang||""))+
			"";

		if(await Konfirm(
			"Hapus: <b>"+vHeadercaption+"</b>.."+
			"<br/>Apakah Anda Yakin ?"+
			"")) apiProseshapus(_IDTABEL);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initJmldata=()=>{
		const vKeyword = uKeywordObj.kwd_hutanglain || "";
		if (vKeyword.length > 2) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFormbayarAwal = () => {
		setTabelIDPilih(0);
		setInpTglBayar(UFunc.DbDate());
		setInpKasID("0");
		setInpKascaption("");
		setInpNilaibayar(0);
		setInpNonota("");
		setInpStatustembak("REGULER");
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_hutanglain || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_akunkas||"Kas Akun"}</CCol>
				<CCol md="2" lg="3" className="d-flex justify-content-between">
					<div className="w-50">{uBahasaObj.caption_nilaihutang||"caption_nilaihutang"} (Rp)</div>
					<div className="w-50">{uBahasaObj.word_terbayar||"word_terbayar"} (Rp)</div>
				</CCol>
				<CCol md="2" lg="1" className="">{uBahasaObj.caption_sisahutang||"caption_sisahutang"}<small className="d-block">(Rp)</small></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none classfontsmaller">
				<th width={25} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,keterangan,tgl_hutang,nilai_hutang,tgl_jt,terbayar,
				jenis_hutang,is_loading,init_expired,kas_caption,status_jurnal,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= ((jenis_hutang||"")+
				((tgl_hutang||"")!==""?" ("+UFunc.TglAngka(tgl_hutang||"")+")":"")).toUpperCase();

			const vKeterangan	= vKeyword==="" ? UFunc.capitalize(keterangan||"") 
				: (UFunc.capitalize(keterangan||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vJenisHutang	= vKeyword==="" ? (jenis_hutang||"") 
				: ((jenis_hutang||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKascaption	= vKeyword==="" ? (kas_caption||"UNDF") 
				: ((kas_caption||"UNDF")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglHutang	= vKeyword==="" ? UFunc.TglAngka(tgl_hutang||"") 
				: (UFunc.TglAngka(tgl_hutang||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglJT		= vKeyword==="" ? UFunc.TglAngka(tgl_jt||"") 
				: (UFunc.TglAngka(tgl_jt||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
				
			const vColorstatustembak= (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";

			return (
			<tr id={"idtr"+id} 
				className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}
				key={vKeys}>
				<td align="right" className="px-1 pe-0">{(vNumber)}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="px-1 text-start d-none d-md-block">
					<div>
					{UFunc.renderHTML(vTglHutang)}
					{uTokenObj.userhak==="FREEROLE" && (
						<span className="classfontsmaller"><small> &middot; ({id})</small></span>
					)}
					</div>			

					{(tgl_jt||"")!=="" && (
					<div className="classfontsmaller">
					<small>
						<span className="me-1">{uBahasaObj.word_jt||"JT"}:</span>

						{UFunc.DateIsMundur(UFunc.toDate(tgl_jt)) ? (
							<CBadge className="" color="danger">
							{UFunc.renderHTML(vTglJT)}
							<CIcon icon="cilBellExclamation" className="fw-bolder classblinking ms-1 align-middle" height={18}/>
							</CBadge>
						) : (
						<span className="text-success">{UFunc.renderHTML(vTglJT)}</span>
						)}
					</small>
					</div>
					)}
				</CCol>

				<CCol xs="12" md="3" className="px-1 text-start">
					<div className="d-flex justify-content-between align-items-start">
						<div className="text-info">{UFunc.renderHTML(vJenisHutang)}</div>
						
						{(is_loading||false)===true ? (
						<CSpinner color="primary" className="d-md-none mt-1" size="sm"/>
						) : (
						<CDropdown className="d-md-none mt-1">
							<CDropdownToggle caret={false} size="sm" className="p-0 mx-0"
								color="transparent">
								<CIcon icon="cilOptions" className="classikoncoreui mx-0" height={25}/>
							</CDropdownToggle>
							<CDropdownMenu>

							{(uIsEOMExecuted) && (
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikBayar(id)}>
								<CIcon className="classikonbayar me-2 align-middle" height={25}/>
								{(uBahasaObj.caption_bayarhutang)}
							</CDropdownItem>
							)}

							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikHistori(id)}>
								<CIcon className="classikonhistoribayar me-2 align-middle" height={25}/>
								{(uBahasaObj.caption_historibayar)}
							</CDropdownItem>
							
							{(uIsEOMExecuted 
								&& uISAllowedhapus 
								&& (init_expired||"YA")==="TIDAK"
								&& parseFloat(terbayar||"0") <= 0) 
							&& (
							<>
							<CDropdownDivider/>
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikHistori(id)}>
								<CIcon className="classikonhapus me-2 align-middle" height={25}/>
								{("Hapus Data Hutang")}
							</CDropdownItem>
							</>
							)}
							</CDropdownMenu>
						</CDropdown>
						)}
					</div>

					<small className="fst-italic d-none d-md-block classfontsmaller">{UFunc.renderHTML(vKeterangan)}</small>
					<small className="fst-italic d-block d-md-none">({UFunc.renderHTML(vKeterangan)})</small>

					{((status_jurnal||"")==="TEMBAK" && uTokenObj.userhak==="FREEROLE") && (
					<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
					</div>
					)}
				</CCol>
				
				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol xs="8" className="px-1 d-md-none">
					{UFunc.renderHTML(vTglHutang)}
					{uTokenObj.userhak==="FREEROLE" && (
						<span className="classfontsmaller"><small> &middot; ({id})</small></span>
					)}
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal||"-"}</b>
				</CCol>
				</>
				)}
				
				{(tgl_jt||"")!=="" && (
				<>
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_tgljt||"caption_tgljt"}</CCol>
				<CCol xs="6" className="px-1 d-md-none classfontsmaller">
					<small>
						{UFunc.DateIsMundur(UFunc.toDate(tgl_jt)) ? (
							<CBadge className="" color="danger">
							{UFunc.renderHTML(vTglJT)}
							<CIcon icon="cilBellExclamation" className="fw-bolder classblinking ms-1 align-middle" height={18}/>
							</CBadge>
						) : (
						<span className="text-success">{UFunc.renderHTML(vTglJT)}</span>
						)}
					</small>
				</CCol>
				</>
				)}

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_kaspenerima||"Kas Penerima"}</CCol>
				<CCol xs="6" md="2" className="px-1 text-md-center">{UFunc.renderHTML(vKascaption)}</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="6" md="2" lg="3" className="px-0 d-none d-md-flex justify-content-md-between classfontsmaller">
					<div className="w-50 px-1">{UFunc.formatAngka(nilai_hutang)}</div>
					<div className="w-50 px-1">{UFunc.formatAngka(terbayar)}</div>
				</CCol>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_nilaihutang||"caption_nilaihutang"} (Rp)</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					{UFunc.formatAngka(nilai_hutang)}
				</CCol>
				
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_terbayar||"word_terbayar"} (Rp)</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					{UFunc.formatAngka(terbayar)}
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_sisahutang||"caption_sisahutang"} (Rp)</CCol>
				<CCol xs="6" md="2" lg="1" className="px-1 text-primary">
					{UFunc.formatAngka(nilai_hutang - terbayar)}
				</CCol>

				<CCol md="1" className="text-center px-0 d-none d-md-block">
					{(is_loading||false)===true ? (
					<CSpinner color="primary" size="sm"/>
					) : (
					<>
					{(uIsEOMExecuted) && (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_bayarhutang||"caption_bayarhutang")+": "+vCaptionUpper}>
					<CLink 
						className="classikontabel classikonbayar classcursorpointer classikoncoreui mx-0 mx-lg-1"
						onClick={()=>hdlKlikBayar(id)} />
					</CTooltip>

					<span className="d-none d-lg-inline mx-1">&middot;</span>
					</>
					)}

					<CDropdown className="mx-0">
						<CDropdownToggle caret={false} size="sm" className="p-0 mx-0"
							color="transparent">
							<CIcon icon="cilChevronBottom" className="classikoncoreui mx-0" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikHistori(id)}>
							<CIcon className="classikonhistoribayar me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_historibayar)}
						</CDropdownItem>
						
						{(uIsEOMExecuted 
							&& uISAllowedhapus 
							&& (init_expired||"YA")==="TIDAK"
							&& parseFloat(terbayar||"0") <= 0) 
						&& (
						<>
						<CDropdownDivider/>
						<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							Hapus Data
						</CDropdownItem>
						</>
						)}
						</CDropdownMenu>
					</CDropdown>
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgform = () => {
		if(!uIsShowForm) return (<></>)//>
		if((parseInt(uTabelIDPilih)||0)<= 0) return (<></>)//>

		const vIdx 			= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		const vGLCaption	= uDatamainArr[vIdx].jenis_hutang;

		return (
		<CForm>
			<CRow className="my-2">
			<CCol className="text-primary fw-bolder text-uppercase text-center">{vGLCaption}</CCol>
			</CRow>

			<div className="classborderbottom my-3"/>

			{uTokenObj.userhak === "FREEROLE" && (
			<>
			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
			</CCol>
			<CCol xs="8" lg="4">
				<CFormSelect size="sm"
						onChange={(e)=>setInpStatustembak(e.target.value)}
						value={uInpStatustembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
				</CFormSelect>

				<div className="classpetunjuk">
					<small>*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL</small>
				</div>
			</CCol>
			</CRow>

			<div className="mx-0 my-3 classborderbottom"/>
			</>
			)}

			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">{uBahasaObj.caption_tglbayar||"caption_tglbayar"}</CCol>
			<CCol xs="8" lg="4" className="text-end">
				<CFormInput size="sm"
					type="date"
					value={uInpTglBayar}
					onChange={(e)=>setInpTglBayar(e.target.value)}
					id="inptglbayar"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</CCol>
			<CCol xs="8" lg="4">
				<NumberFormat 
					value={uInpNilaiBayar}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => setInpNilaibayar(values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							onChange={(e) => setInpNilaibayar(e.target.value)}
							size="sm"
							className="text-end"
							onFocus={(e)=>e.target.select()}
						id="inpnilaibayar"/>
					)} 
				prefix={isMobile ? "" : "Rp"}/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">{uBahasaObj.word_nonota||"word_nonota"}</CCol>
			<CCol xs="8" lg="4">
				<CFormInput maxLength={20} size="sm"
					value={uInpNonota}
					className="text-uppercase"
					onChange={(e)=>setInpNonota(e.target.value)}
					id="inpnonota"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">{uBahasaObj.caption_asalkas||"caption_asalkas"}</CCol>
			<CCol xs="8" lg="4">
				<CInputGroup size="sm">
					<CFormInput size="sm"
						value={uInpKascaption}
						onClick={()=>hdlKlikKaslookup()}
						placeholder={"--"+uBahasaObj.word_pilih+": "+(uBahasaObj.caption_asalkas)+".."}
						id="inpkascaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikKaslookup()}
						id="btnkaslookup">
					<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
				</CInputGroup>
			</CCol>
			</CRow>
		</CForm>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsShowhistori) return (<></>)//>
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if(vArridx < 0) return (<></>)//>

		return (
		<>
		<CRow>
		<CCol lg="5">
			<small>
			<CCard className="mb-3">
			<CCardHeader className="py-1">Data Hutang</CCardHeader>
			<CCardBody>
				<CRow>
				<CCol>Tgl Hutang</CCol>
				<CCol className="text-end">{UFunc.HariAngka(uDatamainArr[vArridx].tgl_hutang)}</CCol>
				</CRow>

				<small>
				<CRow>
				<CCol xs="3" className="pe-0">Keterangan</CCol>
				<CCol className="text-end fst-italic">{(uDatamainArr[vArridx].keterangan)}</CCol>
				</CRow>
				</small>

				<div className="classborderbottom my-2"/>

				<CRow>
				<CCol>Total Hutang</CCol>
				<CCol className="text-end">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_hutang)}</CCol>
				</CRow>

				<CRow>
				<CCol>Terbayar</CCol>
				<CCol className="text-end">{UFunc.formatAngka(uDatamainArr[vArridx].terbayar)}</CCol>
				</CRow>

				<CRow>
				<CCol>Sisa Hutang</CCol>
				<CCol className="text-end text-primary">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_hutang - uDatamainArr[vArridx].terbayar)}</CCol>
				</CRow>
			</CCardBody>
			</CCard>
			</small>
		</CCol>

		<CCol lg="7">
			<CListGroup className="">
			{uDataHistoriArr.map((vItems,vKeys)=>{
				const {
					id,tgl_bayar,no_nota,nilai_bayar
				} = vItems;

				const vBgcolor 	= vKeys%2===0 ? "secondary" : "white";
				const vAccent 	= vKeys%2===0 ? "success" : "warning";
				return (
					<CListGroupItem key={vKeys}
						 className={"p-1 py-2 border-start-5 border-start-"+vAccent} 
						color={vBgcolor}>
					<CRow className="mx-0">
					<CCol xs="6" md="4" className="px-1">
						<div className="fw-bolder">{UFunc.HariAngka(tgl_bayar||"")}</div>
						{(no_nota||"")!=="" && (
						<div className="fst-italic d-none d-md-block">
							<small>
							{uBahasaObj.word_nonota||"word_nonota"}:{" "}
							<strong className="text-info text-uppercase">{UFunc.capitalize(no_nota||"")}</strong>
							</small>
						</div>
						)}
					</CCol>
					<CCol md="8" className="text-end d-none d-md-block px-1">
						<div className="text-primary">Rp{UFunc.formatAngka(nilai_bayar)}</div>
					</CCol>
					<CCol xs="6" className="text-end d-md-none px-1">
					{(no_nota||"")!=="" ? (
						<small>
						{uBahasaObj.word_nonota||"word_nonota"}:{" "}
						<b className="text-info text-uppercase">{UFunc.capitalize(no_nota||"")}</b>
						</small>
					): ("")}
					</CCol>

					<CCol xs="5" className="d-md-none px-1">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</CCol>
					<CCol xs="7" className="text-end d-md-none px-1">
						<div className="text-primary">Rp{UFunc.formatAngka(nilai_bayar)}</div>
					</CCol>
					</CRow>
					</CListGroupItem>
				)
			})}
			</CListGroup>
		</CCol>
		</CRow>
		</>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname 	= apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1;
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_hutanglain || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoaddata) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth 	: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutanglain/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadHistori = (_IDTABEL) => {
		uFuncname = apiLoadHistori.name;
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_loading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoadHistori) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_hutangid : _IDTABEL.toString(),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutanglain/ld_histori";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			uDatamainArr[vArridx].is_loading = false;
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.historiobject||"[]");
				if(vTmpArr.length <= 0) {
					showToast((uBahasaObj.caption_dataempty||"caption_dataempty")+"..");
					return;
				}

				uDatamainArr[vArridx].histori_arr = vTmpArr;
				setDataHistoriArr(vTmpArr);
				setShowhistori(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_loading = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanBayar = () => {
		uFuncname = apiSimpanBayar.name;

		const vHutangID = parseInt(uTabelIDPilih) || 0;
		if(vHutangID <= 0) return;
		const vIdx 	= UFunc.getObjectIndeks(uDatamainArr,vHutangID);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiSimpanBayar) vIdx = "+vIdx);
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_hutangid : vHutangID,
			send_tglbayar : uInpTglBayar,
			send_nilaibayar : uInpNilaiBayar,
			send_kasid : uInpKasID,
			send_nonota : uInpNonota,
			send_tembakdata : uInpStatustembak,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutanglain/pr_simpanbayar";

		setLoading(true);
		uElBtnSimpan.disabled=true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnSimpan.disabled=false;
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr[vIdx].terbayar = (parseInt(uDatamainArr[vIdx].terbayar)||0) + (parseInt(uInpNilaiBayar)||0);
				hdlToggleForm();

				if(output_string.pesankhusus) {
					const vPesan = output_string.pesankhusus;
					showToast(vPesan,"SUKSES");
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				uElBtnSimpan.disabled=true;
				prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnSimpan.disabled=false;
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vArridx = UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiSimpanBayar) vArridx = "+vArridx);
		uDatamainArr.splice(vArridx,1);
		setJmlData(uJmlData-1);
		setLoading(false);
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_hutangid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutanglain/pr_hapusinput";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setJmlData(uJmlData-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return ()=>{
			uDispatch({type: "set", gInitHeaderAction:{}});
			setShowForm(false);
			setShowhistori(false);
			hdlToggleDlglookup()
			setDatamainArr([]);
    		setDataHistoriArr([]);
    		setDataDlglookupArr([]);
    		initFormbayarAwal();
    	}
	},[]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		uNavigate("/subkeuangan/hutanglain/forminput"); 
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!uHandelEdit) return;

		uHeaderActionObj.isHeaderEdit = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	
		uNavigate("/subkeuangan/hutanglain/listdata"); 
	},[uHandelEdit]);
	React.useEffect(()=>{ uElJmldata && initJmldata() },[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata();
	},[uJmlData]);
	React.useEffect(()=>{  
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uIsShowForm) { initFormbayarAwal(); return; }

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			setElBtnSimpan(document.getElementById("btnDialogSimpan"));
			document.getElementById("inptglbayar") && 
				document.getElementById("inptglbayar").focus();
		},350);
	},[uIsShowForm]);
	React.useEffect(()=>{ !uIsDlglookupshow && setDataDlglookupArr([]) },[uIsDlglookupshow]);
	React.useEffect(()=>{
		if((parseInt(uInpNilaiBayar)||0) < 0) 
			{ setInpNilaibayar((parseInt(uInpNilaiBayar)||0) * -1); return; }
		if((parseInt(uTabelIDPilih)||0) <= 0) return; 

		const vIdx 	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if((parseInt(uInpNilaiBayar)||0) > (parseInt(uDatamainArr[vIdx].nilai_hutang)||0) - (parseInt(uDatamainArr[vIdx].terbayar)||0)) 
			setInpNilaibayar((parseInt(uDatamainArr[vIdx].nilai_hutang)||0) - (parseInt(uDatamainArr[vIdx].terbayar)||0));
	},[uInpNilaiBayar]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg mb-3">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_hutanglain || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none"/>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlglookupheader||"uDlglookupheader"}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikFormSimpan()}
			show={uIsShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowhistori} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleHistori}
			renderContent={contentDlgview()}/>
		</>
	)//>
}
	
export default Hutanglain;