import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CTooltip,
	CLink,
	CListGroup,
	CListGroupItem,
	CForm,
	CFormInput,
	CFormSelect,
	CFormSwitch,
	CButton,
	CSpinner,
	CBadge,
	CInputGroup, CInputGroupText,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPagination,
	MyDialogform,
	MyDialogview,
	MyDialoglookup,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../package.json');
const Hutangsupplier= (props) => {
	const uComponentname 	= Hutangsupplier.name;
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//--GLOBAL_VARS--/
	const uIsAllowedbayar	= uIsEOMExecuted
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};

	//---DATA--/
	const uHeaderActionObj  				= useSelector(state => state.gInitHeaderAction);
	const uHandelView 						= uHeaderActionObj.isHeaderView || false;
	const uHandelEdit 						= uHeaderActionObj.isHeaderEdit || false;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const uElJmldata						= document.getElementById("idjmldata");
	//---END DATA--/

	//--DLG_FORM--/
	const uKasGLDefaultArr					= JSON.parse(localStorage.getItem("kasgllist")||"[]");
	const [uKasGLArr,setKasGLArr]			= React.useState(uKasGLDefaultArr);//-*/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformheader,setDlgformheader]	= React.useState("");
	const [uDlgforminputObj,setDlgforminputObj] = React.useState({});
	const [uIsDlgformloading,setDlgformloading]= React.useState(false);

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]= React.useState(document.getElementById("btnDialogSimpan"));
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	const [uDlgviewjenis,setDlgviewjenis]		= React.useState("");
	const [uIsDlgviewshow,setDlgviewshow]		= React.useState(false);
	const [uDatahistoriArr,setDatahistoriArr]	= React.useState([]);
	const [uDatadetilArr,setDatadetilArr]		= React.useState([]);
	const [uDetilBTransport,setDetilBTransport]	= React.useState(0);
	//--END DLG_VIEW--/

	//--DLG_LOOKUP--/
	const [uDlglookupheader,setDlglookupheader]	= React.useState("");
	const [uInpDlglookup,setInpDlglookup]		= React.useState("");
	const [uDataDlglookupArr,setDataDlglookupArr]= React.useState([]);
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false);
	//--END DLG_LOOKUP--/
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleDlgform = () =>{ setDlgformshow(false) }
	const hdlToggleDlgview = () =>{ setDlgviewshow(false) }
	const hdlToggleDlglookup = () =>{ setDlglookupshow(false) }
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		const vIdx = UFunc.getObjectIndeks(uDataDlglookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		const vElsfocusnext = uElBtnDlgsimpan;
		uDlgforminputObj.kas_id = (uDataDlglookupArr[vIdx].id)
		uDlgforminputObj.kas_caption = (uDataDlglookupArr[vIdx].caption)
		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			if(!isMobile)
				vElsfocusnext && vElsfocusnext.focus()
		},350);
	}
	const hdlKlikKaslookup = () => {
		//setInpDlglookup(uDlgforminputObj.kas_caption);
		uDlgforminputObj.kas_caption = (""); uDlgforminputObj.kas_id = ("");
		const vTmpArr	= uKasGLArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_asalkas||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKlikHistori = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		//alert(vArridx);return;
		const vHeadercaption = (uBahasaObj.caption_historibayar||"caption_historibayar")+": "+
			((uDatamainArr[vArridx].nama_supplier||"")+
				((uDatamainArr[vArridx].no_nota||"")!==""?" ("+(uDatamainArr[vArridx].no_nota||"")+")" : "")
				).toUpperCase();
		setDlgviewjenis("HISTORI");
		setDlgformheader(vHeadercaption);

		if((uDatamainArr[vArridx].histori_arr||[]).length > 0) {
			setDatahistoriArr(uDatamainArr[vArridx].histori_arr||[]);
			setDlgviewshow(true);
			return;
		}

		apiLoadhistori(_IDTABEL);
	}
	const hdlKlikDetil = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		//alert(vArridx);return;
		const vHeadercaption = (uBahasaObj.caption_detilitem||"caption_detilitem")+": "+
			((uDatamainArr[vArridx].nama_supplier||"")+
				((uDatamainArr[vArridx].no_nota||"")!==""?" ("+(uDatamainArr[vArridx].no_nota||"")+")" : "")
				).toUpperCase();
		setDlgviewjenis("DETIL");
		setDlgformheader(vHeadercaption);

		if((uDatamainArr[vArridx].detil_arr||[]).length > 0) {
			setDatadetilArr(uDatamainArr[vArridx].detil_arr||[]);
			setDetilBTransport(uDatamainArr[vArridx].biaya_transport || 0);
			setDlgviewshow(true);
			return;
		}
		apiLoaddetil(_IDTABEL);
	}
	const hdlKlikBayar=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		var vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setTabelIDPilih(_IDTABEL);
		uDlgforminputObj.nilai_hutangori = ((parseInt(uDatamainArr[vArridx].nilai_hutang)||0));
		uDlgforminputObj.nilai_bayarori = ((parseInt(uDatamainArr[vArridx].terbayar)||0));

		const vSisaHutang = (parseInt(uDatamainArr[vArridx].nilai_hutang)||0) 
			- (parseInt(uDatamainArr[vArridx].terbayar)||0); 

		const vHeadercaption = (uBahasaObj.caption_bayarhutang||"caption_bayarhutang")+": "+
			((uDatamainArr[vArridx].nama_supplier||"")).toUpperCase()+" ("+(uBahasaObj.word_sisa||"word_sisa")+": Rp"+UFunc.formatAngka(vSisaHutang)+")";
		setDlgformheader(vHeadercaption);
		setDlgformshow(true);
	}
	const hdlKlikFormSimpan=()=>{
		//---VALIDASI--/
		if(UFunc.isEmpty(uDlgforminputObj.tgl_bayar)) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.caption_tglbayar||"caption_tglbayar")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}

		const vTanggalDt 	= new Date(uDlgforminputObj.tgl_bayar);

		const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
		if(vDateBeyond) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
			return;
		}
		const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
		if(vDateExceededBl) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
			return;
		}
		if((parseInt(uDlgforminputObj.nilai_bayar)||0) < 0 ) {
			document.getElementById("inpnilaibayar").focus();
			showToast((uBahasaObj.caption_nilaibayar||"caption_nilaibayar")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}
		if(uDlgforminputObj.is_adaselisih && (parseInt(uDlgforminputObj.sisa_baru)||0) > 0) {
			document.getElementById("inptagihanbaru").focus();
			showToast((uBahasaObj.pesan_selisihmustpaid||"pesan_selisihmustpaid")+"..");
			return;
		}
		if((parseInt(uDlgforminputObj.nilai_bayar)||0) > (parseInt(uDlgforminputObj.sisa_bruto)||0)) {
			document.getElementById("inpnilaibayar").focus();
			showToast((uBahasaObj.pesan_nilaibayarexceeded||"pesan_nilaibayarexceeded")+"..");
			return;
		}
		//showToast(uDlgforminputObj.sisa_bruto+" "+uDlgforminputObj.nilai_bayar); return;
		if((parseInt(uDlgforminputObj.kas_id)||0) === 0) {
			document.getElementById("inpkascaption").focus();
			showToast((uBahasaObj.caption_asalkas||"caption_kaspenerima")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		//---END VALIDASI--/

		apiSimpanbayar();
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initJmldata = () => {
		/*//--PAGINATION_DIATUR_BACKEND--/
		const vKeyword = uKeywordObj.kwd_hsupplier || "";
		if (vKeyword.length > 2) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
		//--PAGINATION_DIATUR_BACKEND--*/
		//-*/
			
		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));//--PAGINATION_DIATUR_FRONTEND--/

		const vElJmldatamobile				= document.getElementById("idjmldatamobile");
		vElJmldatamobile && (vElJmldatamobile.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initFormbayarAwal = () => {
		setTabelIDPilih(0);
		setDlgforminputObj({});
	}
	const initHitungSisa = () => {
		const vNilaiBruto	= (parseFloat(uDlgforminputObj.tagihan_baru)||0)
			- (parseFloat(uDlgforminputObj.nilai_depositpakai)||0)
			- (parseFloat(uDlgforminputObj.nilai_bayarori)||0);//-->2.300
		const vNilaiSisa	= vNilaiBruto - (parseFloat(uDlgforminputObj.nilai_bayar)||0);
		//-->2.300 - 200.000
		if(vNilaiSisa < 0) 
			{ uDlgforminputObj.nilai_bayar = (vNilaiBruto); return; }
		uDlgforminputObj.sisa_baru = (vNilaiSisa);//-->2.100.000
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_hsupplier || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,vMax);

		/*//--PAGINATION_DIATUR_BACKEND--/
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		//--END PAGINATION_DIATUR_BACKEND--*/

		return (
		<table className="table table-borderless table-striped table-hover mx-0" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width="5%" className=" px-1">No</th>
		<th className="px-1">
			<CRow className="mx-0">
			<CCol md="3" className="text-start">{uBahasaObj.caption_tglhutang||"caption_tglhutang"}</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.word_supplier||"word_supplier"}</CCol>
			<CCol md="3" className="d-flex justify-content-between">
				<div className="w-50">{uBahasaObj.caption_nilaihutang||"caption_nilaihutang"} (Rp)</div>
				<div className="w-50">{uBahasaObj.word_terbayar||"word_terbayar"} (Rp)</div>
			</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_sisahutang||"caption_sisahutang"}<div className="classfontsmaller">(Rp)</div></CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
		</th>
		</tr>

		<tr className="d-md-none">
			<th width={25} className="p-0"/>
			<th className="p-0"/>
		</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,nama_supplier,tgl_hutang,no_nota,nilai_hutang,tgl_jt,terbayar,
				no_rek,is_loading
			} = vItems;

			vNumber++;
			const vCaptionUpper	= ((nama_supplier||"")+" "+
				((no_nota||"") !== "" ? "("+(no_nota||"")+")" : "")).toUpperCase();

			const vNamasupplier	= vKeyword==="" ? UFunc.capitalize(nama_supplier||"") 
				: (UFunc.capitalize(nama_supplier||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglHutang= vKeyword==="" ? UFunc.TglAngka(tgl_hutang||"") 
				: (UFunc.TglAngka(tgl_hutang||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglJT= vKeyword==="" ? UFunc.TglAngka(tgl_jt||"") 
				: (UFunc.TglAngka(tgl_jt||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNonota= vKeyword==="" ? (no_nota||"") 
				: ((no_nota||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNorek= vKeyword==="" ? (no_rek||"") 
				: ((no_rek||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
				
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+(uTabelRowSelect===vKeys?"classrowselect":"")}>
				<td className="px-1 pe-1">{(vNumber)}.</td>
				<td className="px-1 ps-0">
				<CRow className="mx-0">
				<CCol md="3" className="px-1 text-start d-none d-md-block">
					{(tgl_hutang||"")!=="" && (
					<div>
						{UFunc.renderHTML(vTglHutang)}
						{uTokenObj.userhak==="FREEROLE" && (
							<span className="classfontsmaller"><small> &middot; ({id})</small></span>
						)}
					</div>
					)}

					{(tgl_jt||"")!=="" && (
					<small className="d-block">
						<span className="me-1">{uBahasaObj.caption_tgljt||"caption_tgljt"}:</span>

						{UFunc.DateIsMundur(UFunc.toDate(tgl_jt)) ? (
							<CBadge className="" color="danger">
							{UFunc.renderHTML(vTglJT)}
							<CIcon icon="cilBellExclamation" className="fw-bolder classblinking ms-1 align-middle" height={18}/>
							</CBadge>
						) : (
						<span className="text-success">{UFunc.renderHTML(vTglJT)}</span>
						)}
					</small>
					)}
				</CCol>

				<CCol xs="12" md="3" className="px-1 text-start d-flex justify-content-between">
					<div>
					<div className="text-info">{UFunc.renderHTML(vNamasupplier)}</div>

					{(no_nota||"")!=="" && (
					<small className="d-none d-md-block">
						{uBahasaObj.word_nonota||"word_nonota"}:{" "}
						<b>{UFunc.renderHTML(vNonota)}</b>
					</small>
					)}

					{(no_rek||"").trim().length >= 3 && (
					<div className="d-none d-md-block fst-italic classfontsmaller">
					<small>({uBahasaObj.word_rek||"word_rek"}: {UFunc.renderHTML(vNorek)})</small>
					</div>
					)}	
					</div>

					{(is_loading||false)===true ? (
						<CSpinner color="primary d-md-none align-middle" size="sm"/>
					) : (
					<CDropdown className="d-md-none" autoClose={true}>
					<CDropdownToggle color="transparent" className="p-0 m-0" caret={false}>
						<CIcon icon="cilOptions" className="classikoncoreui align-middle mx-0" height={23}/>
					</CDropdownToggle>
					<CDropdownMenu className="py-0">
					
					{uIsAllowedbayar && (
					<CDropdownItem className="classcursorpointer"
						onClick={()=>hdlKlikBayar(id)}>
						<CIcon className="classikonbayar me-2 align-middle" height={25}/>
						{uBahasaObj.caption_bayarhutang}
					</CDropdownItem>
					)}

					<CDropdownItem className="classcursorpointer"
						onClick={()=>hdlKlikHistori(id)}>
						<CIcon className="classikonhistoribayar me-2 align-middle" height={25}/>
						{uBahasaObj.caption_historibayar}
					</CDropdownItem>
					<CDropdownItem className="classcursorpointer"
						onClick={()=>hdlKlikDetil(id)}>
						<CIcon className="classikondetil me-2 align-middle" height={25}/>
						{uBahasaObj.caption_detilitem}
					</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
					)}
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom" />

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_tglhutang||"caption_tglhutang"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					<span className="fw-bolder">{UFunc.renderHTML(vTglHutang)}</span>
					{uTokenObj.userhak==="FREEROLE" && (
					<span className="classfontsmaller"><small> &middot; ({id})</small></span>
					)}
				</CCol>

				{(no_nota||"")!=="" && (
				<>
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_nonota||"word_nonota"}</CCol>
				<CCol xs="6" className="px-1 text-end d-md-none">
					<b>{UFunc.renderHTML(vNonota)}</b>
				</CCol>
				</>
				)}

				{(tgl_jt||"")!=="" && (
				<>
				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_tgljt||"caption_tgljt"}</CCol>
				<CCol xs="6" className="px-1 text-end d-md-none">
					<small>
						{UFunc.DateIsMundur(UFunc.toDate(tgl_jt)) ? (
							<CBadge className="" color="danger">
							{UFunc.renderHTML(vTglJT)}
							<CIcon icon="cilBellExclamation" className="fw-bolder classblinking ms-1 align-middle" height={18}/>
							</CBadge>
						) : (
						<span className="text-success">{UFunc.renderHTML(vTglJT)}</span>
						)}
					</small>
				</CCol>
				</>
				)}

				<CCol md="3" className="px-0 d-none d-md-flex justify-content-between classfontsmaller">
					<div className="w-50">{UFunc.formatAngka(nilai_hutang)}</div>
					<div className="w-50">{UFunc.formatAngka(terbayar)}</div>
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_nilaihutang||"caption_nilaihutang"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					Rp {UFunc.formatAngka(nilai_hutang)}
				</CCol>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_terbayar||"word_terbayar"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					Rp {UFunc.formatAngka(terbayar)}
				</CCol>

				<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_sisahutang||"caption_sisahutang"}</CCol>
				<CCol xs="6" md="2" className="px-1 text-primary">
					<span className="d-md-none">Rp </span>{UFunc.formatAngka((nilai_hutang - terbayar))}
				</CCol>


				<CCol md="1" className="px-0 text-center d-none d-md-block">
					{(is_loading||false)===true ? (
						<CSpinner color="primary align-middle" size="sm"/>
					) : (
					<>
					{uIsAllowedbayar && (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_bayarhutang||"caption_bayarhutang")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer classikontabel classikonbayar classikoncoreui mx-0 me-lg-1"
						onClick={()=>hdlKlikBayar(id)} />
					</CTooltip>

					<span className="d-md-none mx-2">&middot;</span>
					</>
					)}
					
					<CDropdown autoClose={true}>
					<CDropdownToggle color="transparent" className="p-0 m-0" caret={false}>
						<CIcon icon="cilChevronBottom" className="classikoncoreui align-middle mx-0" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu className="py-0">
					<CDropdownItem className="classcursorpointer"
						onClick={()=>hdlKlikHistori(id)}>
						<CIcon className="classikonhistoribayar me-2 align-middle" height={25}/>
						{uBahasaObj.caption_historibayar}
					</CDropdownItem>
					<CDropdownItem className="classcursorpointer"
						onClick={()=>hdlKlikDetil(id)}>
						<CIcon className="classikondetil me-2 align-middle" height={25}/>
						{uBahasaObj.caption_detilitem}
					</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//>

		//<div>uDlgforminputObj.sisa_baru={UFunc.formatAngka(uDlgforminputObj.sisa_baru)}</div>
		return (
		<CForm>
			<CRow className="my-2">
			<CCol xs="4" md="5" className="text-start text-info">{uBahasaObj.caption_tglbayar||"caption_tglbayar"}</CCol>
			<CCol className="">
				<CFormInput
					size="sm"
					type="date"
					value={uDlgforminputObj.tgl_bayar}
					onChange={(e)=>{uDlgforminputObj.tgl_bayar = (e.target.value);setEfekview(!uEfekview)}}
					id="inptglbayar"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" md="5" className="text-info">{uBahasaObj.word_nonota||"word_nonota"}</CCol>
			<CCol className="">
				<CFormInput maxLength={20} size="sm"
					className="text-uppercase"
					value={uDlgforminputObj.no_nota||""}
					onChange={(e)=>{uDlgforminputObj.no_nota = (e.target.value);setEfekview(!uEfekview)}}
					id="inpnonota"/>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4" md="5" className="text-info">
				<span>{uBahasaObj.caption_depositsisaretur||"caption_depositsisaretur"}</span>
				<span className="me-1 align-bottom"/>
				{(uIsDlgformloading||false)===true ? (
					<CSpinner color="warning" className="align-middle" size="sm"/>
				):(
				<span className="text-primary classfontsmaller">
					<small className="align-middle">(Rp{UFunc.formatAngka(uDlgforminputObj.nilai_deposit)})</small>
				</span>
				)}
			</CCol>
			<CCol className="">
				{(parseInt(uDlgforminputObj.nilai_deposit)||0) <= 0 ? (
				<CFormInput size="sm"
					value={"Rp"+UFunc.formatAngka(uDlgforminputObj.nilai_depositpakai)}
					className="text-end"
					readOnly={true}
					onFocus={(e)=>e.target.select()}
				id="inpdepositpakai"/>
				) : (
				<NumberFormat 
					value={uDlgforminputObj.nilai_depositpakai}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => uDlgforminputObj.nilai_depositpakai = (values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							onChange={(e) => uDlgforminputObj.nilai_depositpakai = (e.target.value)}
							size="sm"
							className="text-end"
							onFocus={(e)=>e.target.select()}
						id="inpdepositpakai"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
				)}

				<div className="classpetunjuk">
					<small>*) {uBahasaObj.caption_depositdipakai||"caption_depositdipakai"}</small>
				</div>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="3" md="4" className="text-info">
				<small>{uBahasaObj.caption_adaselisihtagihan||"caption_adaselisihtagihan"}</small>
			</CCol>
			<CCol xs="1" className="">
				<CFormSwitch
					className="ml-1 mb-1 align-middle"
					color="warning"
					onChange={()=>{uDlgforminputObj.is_adaselisih = (!uDlgforminputObj.is_adaselisih);setEfekview(!uEfekview)}}
					checked={(uDlgforminputObj.is_adaselisih||false)===true}
					size="lg"
					id="inpadaselisih"/>
			</CCol>
			<CCol className="">
				{uDlgforminputObj.is_adaselisih===false ? (
					<CFormInput size="sm"
						value={"Rp"+UFunc.formatAngka(uDlgforminputObj.tagihan_baru)}
						className={"text-end"}
						onFocus={(e)=>e.target.select()}
						readOnly={true}
					id="inptagihanbaru"/>
				) : (
				<NumberFormat 
					value={uDlgforminputObj.tagihan_baru||0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values)=>{uDlgforminputObj.tagihan_baru = (values.value);}}
					renderText={values => (
						<CFormInput size="sm"
							value={values}
							onChange={(e)=>{uDlgforminputObj.tagihan_baru = (e.target.value);setEfekview(!uEfekview)}}
							className={"text-end"}
							onFocus={(e)=>e.target.select()}
						id="inptagihanbaru"/>
					)} 
				prefix="Rp"/>
				)}
				<div className="classpetunjuk">
					<small>*) {uBahasaObj.caption_tagihanbaru||"caption_tagihanbaru"}</small>
				</div>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4" md="5" className="text-info">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</CCol>
			<CCol className="">
				<NumberFormat 
					value={uDlgforminputObj.nilai_bayar||0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values)=>{uDlgforminputObj.nilai_bayar = (values.value);}}
					renderText={values => (
						<CFormInput 
							value={values}
							onChange={(e)=>{uDlgforminputObj.nilai_bayar = (e.target.value);setEfekview(!uEfekview)}}
							size="sm"
							className="text-end"
							onFocus={(e)=>e.target.select()}
						id="inpnilaibayar"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" md="5" className="text-info">{uBahasaObj.caption_asalkas||"caption_asalkas"}</CCol>
			<CCol className="text-end">
					<CInputGroup size="sm">
						<CFormInput size="sm"
							value={uDlgforminputObj.kas_caption}
							onClick={()=>hdlKlikKaslookup()}
							placeholder={"--"+uBahasaObj.word_pilih+": "+(uBahasaObj.caption_asalkas)+".."}
							id="inpkascaption"/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikKaslookup()}
							id="btnkaslookup">
						<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
					</CInputGroup>
				</CCol>
			</CRow>
		</CForm>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsDlgviewshow) return (<></>)//>

		if(uDlgviewjenis === "HISTORI") return contentViewhistori();
		if(uDlgviewjenis === "DETIL") return contentDetil();
		return (<></>)//>
	}
	const contentViewhistori = () => {
		if(uDlgviewjenis !== "HISTORI") return (<></>)//>
		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if(vArridx < 0) return (<></>)//>

		return (
		<small>
		<CRow>
			<CCol lg="5" className="">
			<CCard className="classcardbg mb-3">
				<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Data Nota</small></CCardHeader>
				<CCardBody>
				<div className="my-1 d-flex justify-content-between">
					<div>Supplier</div>
					<div className="text-end text-info">{uDatamainArr[vArridx].nama_supplier}</div>
				</div>
				<div className="my-1 d-flex justify-content-between">
					<div>Tgl Hutang</div>
					<div className="text-end">{UFunc.HariAngka(uDatamainArr[vArridx].tgl_hutang)}</div>
				</div>
				<div className="my-1 d-flex justify-content-between">
					<div>No.Nota</div>
					<div className="text-end">{(uDatamainArr[vArridx].no_nota||"-")}</div>
				</div>
				<div className="my-1 d-flex justify-content-between">
					<div>Total Hutang</div>
					<div className="text-end fw-bolder">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_hutang)}</div>
				</div>
				</CCardBody>
			</CCard>
			</CCol>

			<CCol>
			<CCard className="classcardbg mb-3">
				<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Histori Pembayaran</small></CCardHeader>
				<CCardBody className="px-0">
					{uDatahistoriArr.map((vItems,vKeys)=>{
					const {
						id,tgl_bayar,no_nota,nilai_bayar
					} = vItems;

					const vBgcolor 	= vKeys%2===0 ? "light" : "white";
					const vAccent 	= vKeys%2===0 ? "success" : "warning";
					return (
					<CRow className={"mx-0 py-2"+(" bg-"+vBgcolor)+(" border-bottom border-bottom-"+vAccent)}
						key={vKeys}>
					<CCol xs="1" className="px-1 text-end">{vKeys+1}.</CCol>

					<CCol xs="4" className="px-1 text-start">{UFunc.HariAngka(tgl_bayar)}</CCol>
					<CCol xs="3" className="px-1 text-start">
						<small className="fst-italic">{(no_nota||"-").toUpperCase()}</small>
					</CCol>
					<CCol xs="4" className="text-end px-1">
						<b>{UFunc.formatAngka(nilai_bayar)}</b>
					</CCol>
					</CRow>
					)
				})}
				</CCardBody>
			</CCard>
			</CCol>
		</CRow>
		</small>
		)//>
	}
	const contentDetil = () => {
		if(uDlgviewjenis !== "DETIL") return (<></>)//>
		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if(vArridx < 0) return (<></>)//>

		let vSubtotal	= 0;
		return (
		<small>
		<CRow>
			<CCol lg="5" className="">
			<CCard className="classcardbg mb-3">
				<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Data Nota</small></CCardHeader>
				<CCardBody>
				<div className="my-1 d-flex justify-content-between">
					<div>
						<small className="d-none d-lg-block">Supplier</small>
						<span className="d-lg-none">Supplier</span>
					</div>
					<div className="text-end text-info">{uDatamainArr[vArridx].nama_supplier}</div>
				</div>
				<div className="my-1 d-flex justify-content-between">
					<div>
						<small className="d-none d-lg-block">Tgl Hutang</small>
						<span className="d-lg-none">Tgl Hutang</span>
					</div>
					<div className="text-end">{UFunc.HariAngka(uDatamainArr[vArridx].tgl_hutang)}</div>
				</div>

				<div className="my-1 d-flex justify-content-between">
					<div>
						<small className="d-none d-lg-block">No.Nota</small>
						<span className="d-lg-none">No.Nota</span>
					</div>
					<div className="text-end">{(uDatamainArr[vArridx].no_nota||"-")}</div>
				</div>

				<div className="my-1 d-flex justify-content-between">
					<div>
						<small className="d-none d-lg-block">Total Hutang</small>
						<span className="d-lg-none">Total Hutang</span>
					</div>
					<div className="text-end fw-bolder">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_hutang)}</div>
				</div>
				</CCardBody>
			</CCard>
			</CCol>

			<CCol lg="7" className="">
			<CCard className="classcardbg mb-3">
				<CCardHeader className="py-0 pt-1 classfontsmaller"><small>Item Pembelian</small></CCardHeader>
				<CCardBody className="px-0 classfontsmaller">
					{uDatadetilArr.map((vItems,vKeys)=>{
						const {
							id,nama_barang,keterangan,hsatuan,qty,satuan
						} = vItems;

					const vBgcolor 	= vKeys%2===0 ? "light" : "white";
					const vAccent 	= vKeys%2===0 ? "success" : "warning";
					return (
					<CRow className={"mx-0 py-2"+(" bg-"+vBgcolor)+(" border-bottom border-bottom-"+vAccent)}
						key={vKeys}>
					<CCol xs="1" className="px-1 text-end">{vKeys+1}.</CCol>

					<CCol xs="11" md="3" className="px-1 text-start">
						<span className="d-none d-md-block">{(nama_barang||"")}</span>
						<span className="d-md-none text-uppercase text-info">{(nama_barang||"")}</span>
					</CCol>
					
					<CCol xs="1" className="text-start px-1 d-md-none"/>
					<CCol xs="11" md="3" className="px-1 text-start">
						<small className="fst-italic">{(keterangan||"-").toUpperCase()}</small>
					</CCol>

					<CCol xs="1" className="text-start px-1 d-md-none"/>
					<CCol xs="11" className="px-1 d-md-none classborderbottom my-2"/>

					<CCol xs="1" className="text-start px-1 d-md-none"/>
					<CCol xs="5" className="text-start px-1 d-md-none">Qty</CCol>
					<CCol xs="6" md="1" className="text-end text-md-center px-1 px-md-0">
						<b>{UFunc.formatAngka(qty)}<span className="d-lg-none"> {satuan||"UNIT"}</span></b>
					</CCol>

					<CCol xs="1" className="text-start px-1 d-md-none"/>
					<CCol xs="5" className="text-start px-1 d-md-none">H.Satuan (Rp)</CCol>
					<CCol xs="6" md="2" className="text-end px-1">
						<span>{UFunc.formatAngka(hsatuan)}</span>
					</CCol>

					<CCol xs="1" className="text-start px-1 d-md-none"/>
					<CCol xs="11" className="px-1 d-md-none classborderbottom my-2"/>

					<CCol xs="1" className="text-start px-1 d-md-none"/>
					<CCol xs="5" className="text-start px-1 d-md-none">Total (Rp)</CCol>
					<CCol xs="6" md="2" className="text-end px-1">
						<span className="text-primary">{UFunc.formatAngka(hsatuan * qty)}</span>
					</CCol>
					</CRow>
					)
				})}
				</CCardBody>
			</CCard>
			</CCol>
		</CRow>
		</small>
		)//-->
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname 	= apiLoaddata.name;
		//_PAGE		= parseInt(_PAGE) || 1;//-->PAGINATION_DIATUR_BACKEND--/
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_hsupplier || "";
		/*//--PAGINATION_DIATUR_BACKEND--/
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		//--END PAGINATION_DIATUR_BACKEND--*/

		//--PAGINATION_DIATUR_FRONTEND--/
		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);
		//--END PAGINATION_DIATUR_FRONTEND--*/
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			//send_pg: _PAGE,//-PAGINATION_DIATUR_BACKEND--/
			//send_maxdata	: uMaxData,//-PAGINATION_DIATUR_BACKEND--/
			//send_loadjml	: vHandelview,//-PAGINATION_DIATUR_BACKEND--/
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangsupplier/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				/*//--PAGINATION_DIATUR_BACKEND--/
				let vLoadArr = JSON.parse(output_string.dataobject||"[]");
				vLoadArr.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);
				//--END PAGINATION_DIATUR_BACKEND--*/

				//--PAGINATION_DIATUR_FRONTEND--/
				setDatamainArr(vLoadArr);
				//--END PAGINATION_DIATUR_FRONTEND--*/

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadhistori = (_IDTABEL) => {
		uFuncname 	= apiLoadhistori.name;
		_IDTABEL 	= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		const vArridx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_loading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoadhistori) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_hutangid : _IDTABEL.toString(),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangsupplier/ld_histori";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			uDatamainArr[vArridx].is_loading = false;
			if(output_string.tampil) {
				if(output_string.historiobject) {
					const vTmpArr = JSON.parse(output_string.historiobject || "[]");
					if(vTmpArr.length <= 0) {
						showToast((uBahasaObj.caption_dataempty||"caption_dataempty")+"..");
						hdlToggleDlgview();
						return;
					}

					uDatamainArr[vArridx].histori_arr = vTmpArr;
					setDatahistoriArr(vTmpArr);
					setDlgviewshow(true);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_loading = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaddetil = (_IDTABEL) => {
		uFuncname 	= apiLoaddetil.name;
		_IDTABEL 	= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		const vArridx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_loading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoaddetil) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		setDetilBTransport(0);
		const vDATAS	= JSON.stringify({
			send_hutangid : _IDTABEL.toString(),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangsupplier/ld_detilitem";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			uDatamainArr[vArridx].is_loading = false;
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr 			= JSON.parse(output_string.detilobject || "[]");
				const vBiayatransport  = parseFloat(output_string.detiltransport || "0");
				uDatamainArr[vArridx].detil_arr 		= vTmpArr;
				uDatamainArr[vArridx].biaya_transport 	= vBiayatransport;
				setDatadetilArr(vTmpArr);
				setDetilBTransport(vBiayatransport);
				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_loading = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaddeposit = (_IDTABEL) => {
		uFuncname = apiLoaddeposit.name;
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		uDlgforminputObj.nilai_deposit = (0);
		setDlgformloading(true);
		uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_hutangid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangsupplier/ld_deposit";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setDlgformloading(false);
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				if(output_string.nilaideposit) {
					uDlgforminputObj.nilai_deposit = (output_string.nilaideposit);
				}

				let vTambahanKasArr = [];
				if(output_string.kastambahanobject) {
					vTambahanKasArr = JSON.parse(output_string.kastambahanobject);
				}
				setKasGLArr([...uKasGLDefaultArr,...vTambahanKasArr]);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
				hdlToggleDlgform(false);
			} else if(output_string.expired) {
				setDlgformloading(true);
				uElBtnDlgsimpan.disabled = true;
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			setDlgformloading(false);
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false);
			hdlToggleDlgform(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanbayar = () => {
		uFuncname = apiSimpanbayar.name;

		const vHutangID = parseInt(uTabelIDPilih) || 0;
		if(vHutangID <= 0) return;
		const vIdx 	= UFunc.getObjectIndeks(uDatamainArr,vHutangID);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiSimpanbayar) vIdx = "+vIdx);
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_hutangid : vHutangID,
			send_tglbayar : uDlgforminputObj.tgl_bayar,
			send_nilaibayar : uDlgforminputObj.nilai_bayar,
			send_tagihanbaru: uDlgforminputObj.tagihan_baru,
			send_terbayar: uDlgforminputObj.nilai_bayarori,
			send_depositpakai: uDlgforminputObj.nilai_depositpakai,
			send_kasid : uDlgforminputObj.kas_id,
			send_nonota : uDlgforminputObj.no_nota,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangsupplier/pr_simpanbayar";

		setLoading(true);
		uElBtnDlgsimpan.disabled=true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnDlgsimpan.disabled=false;
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr[vIdx].nilai_hutang = uDlgforminputObj.tagihan_baru;
				uDatamainArr[vIdx].terbayar 	= (parseInt(uDlgforminputObj.nilai_bayarori)||0) 
					+ (parseInt(uDlgforminputObj.nilai_bayar)||0) + (parseInt(uDlgforminputObj.nilai_depositpakai)||0);
				hdlToggleDlgform();

				if(output_string.pesankhusus) {
					const vPesan = output_string.pesankhusus;
					showToast(vPesan,"SUKSES");
				}
			} else if(output_string.info) {
				showToast(output_string.info);
				setDlgviewshow(false);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
				setDlgviewshow(false);
			} else if(output_string.expired) {
				setLoading(true);
				uElBtnDlgsimpan.disabled=true;
				prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnDlgsimpan.disabled=false;
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		setKasGLArr(JSON.parse(localStorage.getItem("kasgllist")||"[]"));

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return ()=>{
			uDispatch({type: "set", gInitHeaderAction: {}});

    		hdlToggleDlgform(); hdlToggleDlgview();
    		setDatamainArr([]);
    		setDatadetilArr([]);
    		setDatahistoriArr([]);
    		setDataDlglookupArr([]);
    		setKasGLArr([]);
    	}
	},[]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		/*//--PAGINATION_DIATUR_BACKEND--/
		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);
		//--END PAGINATION_DIATUR_BACKEND_-*/

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelEdit) return;

		uHeaderActionObj.isHeaderEdit = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uIsEOMExecuted) return;
		
		uNavigate("/subkeuangan/hsupplier/listdata"); 
	},[uHandelEdit]);
	//--PAGINATION_DIATUR_FRONTEND--/
	React.useEffect(()=>{ setJmlData(uDatamainArr.length); },[uDatamainArr,uDatamainArr.length]);
	//--PAGINATION_DIATUR_FRONTEND--*/
	React.useEffect(()=>{ uElJmldata && initJmldata(); },[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		//setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata();
	},[uJmlData]);
	React.useEffect(()=>{ 
		setTabelRowSelect(-1);
		/*//--PAGINATION_DIATUR_FRONTEND--/
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
		//--END PAGINATION_DIATUR_FRONTEND--*/
	},[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{ 
		if(!uIsDlgviewshow) {
			setDlgviewjenis("");
			setDatahistoriArr([]); setDatadetilArr([]);
			return;
		}
	},[uIsDlgviewshow]);
	React.useEffect(()=>{
		if(!uIsDlgformshow) { initFormbayarAwal(); return; }

		if(uDlgforminputObj.tgl_bayar === undefined)
			uDlgforminputObj.tgl_bayar = UFunc.DbDate();

		apiLoaddeposit(uTabelIDPilih);

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			setElBtnDlgsimpan(document.getElementById("btnDialogSimpan"))

			if(!isMobile)
				document.getElementById("inptglbayar") && 
					document.getElementById("inptglbayar").focus();
		},350);
	},[uIsDlgformshow]);
	React.useEffect(()=>{ 
		if(!uDlgforminputObj.is_adaselisih) {
			uDlgforminputObj.tagihan_baru = (uDlgforminputObj.nilai_hutangori); return
		}
	},[uDlgforminputObj.is_adaselisih]);
	React.useEffect(()=>{ uDlgforminputObj.tagihan_baru = (uDlgforminputObj.nilai_hutangori); },[uDlgforminputObj.nilai_hutangori]);
	React.useEffect(()=>{ uDlgforminputObj.nilai_depositpakai = (uDlgforminputObj.nilai_deposit); },[uDlgforminputObj.nilai_deposit]);
	React.useEffect(()=>{
		//if((parseInt(uDlgforminputObj.nilai_depositpakai)||0) < 0) { uDlgforminputObj.nilai_depositpakai = (0); return; }
		if((parseInt(uDlgforminputObj.nilai_depositpakai)||0) > (parseInt(uDlgforminputObj.nilai_deposit)||0)) {
			uDlgforminputObj.nilai_depositpakai = (uDlgforminputObj.nilai_deposit);
			return;
		}
	},[uDlgforminputObj.nilai_depositpakai]);
	React.useEffect(()=>{
		//if((parseInt(uDlgforminputObj.tagihan_baru)||0) < 0) { uDlgforminputObj.tagihan_baru = (0); return; }
	},[uDlgforminputObj.tagihan_baru]);
	React.useEffect(()=>{
		//const vTagihanbaru	= parseInt(uDlgforminputObj.tagihan_baru)||0;
		//const vTerbayarOri	= parseInt(uDlgforminputObj.nilai_bayarori)||0;

		let vSisabruto		= (parseInt(uDlgforminputObj.tagihan_baru)||0) - (parseInt(uDlgforminputObj.nilai_bayarori)||0);
		if(vSisabruto < 0) { return; }

		let vDepositpakai	= parseInt(uDlgforminputObj.nilai_depositpakai)||0;
		if(vDepositpakai > vSisabruto) {
			vDepositpakai = vSisabruto;
			uDlgforminputObj.nilai_depositpakai = (vDepositpakai);
			return; 
		}
		vSisabruto 	= vSisabruto - vDepositpakai;

		uDlgforminputObj.sisa_bruto = (vSisabruto);
		uDlgforminputObj.nilai_bayar = (vSisabruto);
	},[uDlgforminputObj.tagihan_baru,uDlgforminputObj.nilai_depositpakai]);
	React.useEffect(()=>{
		//if((parseInt(uDlgforminputObj.nilai_bayar)||0) < 0) { uDlgforminputObj.nilai_bayar = ((parseInt(uDlgforminputObj.nilai_bayar)||0) * -1); return; }
		initHitungSisa();
	},[uDlgforminputObj.nilai_bayar]);//-*/
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
					(<span id="idjmldatamobile">0</span>)
				</span>
				<span className="d-none d-lg-block">Daftar Hutang</span>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlglookupheader||"uDlglookupheader"}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>

		<MyDialogform
			options={{centered:false}}
			onSimpan={()=>hdlKlikFormSimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformheader}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsDlgviewshow} 
			dialogHeader={uDlgformheader}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>
		</>
	)//>
}
	
export default Hutangsupplier;
