import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CCardHeader,
	CCard,
	CCardBody,
	CCardFooter,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CLabel,
	CFormInput,
	CFormTextarea,
	CFormSelect,
	CBadge,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { isMobile } from "react-device-detect";
import { 
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';

const pjson     = require('../../package.json');
const Dokter = (props) => {
	const cComponentName 	= Dokter.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncName;//-->hanya untuk API
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uHTMLstyleTabel=useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 		= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus	= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData							= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	const [uDatamainArr,setDatamainArr]				= React.useState([]);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpDokternama,setInpDokternama] 	= React.useState("");
	const [uInpTelphp,setInpTelphp]			= React.useState("");
	const [uInpAlamat,setInpAlamat]			= React.useState("");

	const [ElBtnSimpan,setElBtnSimpan]		= React.useState(document.getElementById("btnDialogSimpan"));
	const vElJmlData	= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//console.log("("+cComponentName+") uHeaderActionObj.isHeaderView: "+uHeaderActionObj.isHeaderView);
	//console.log("("+cComponentName+") uHandelView: "+uHandelView);

	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowForm(false); }
	const hdlLastFieldKDown = (_EV)=>{
		if(_EV.which === 13) {
			if (_EV.ctrlKey && _EV.target.nodeName === "TEXTAREA") {
				_EV.preventDefault();
				_EV.stopPropagation();
				var content = _EV.target.value;
				var start   = _EV.target.selectionStart;
				var substr1 = content.slice(0, start);
				var substr2 = content.slice(_EV.target.selectionEnd);
				//alert(_EV.target.selectionEnd);
				_EV.target.value  = substr1 + '\n' + substr2;
				return;
			}

			_EV.preventDefault();
			ElBtnSimpan.focus();
		}
	}
	const hdlKlikSimpan=()=>{
		//--VALIDASI_SIMPAN--/
		if(uInpDokternama.trim() === "") {
			showToast((uBahasaObj.caption_namadokter||"caption_namadokter")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
			document.getElementById("inpdokternama").focus();
			return;
		}
		//--END VALIDASI_SIMPAN--/

		apiProses();
	}
	const hdlKlikEdit=async(_IDTABEL)=>{

		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setInpDokternama(uDatamainArr[vObjdx].nama);
		setInpTelphp(uDatamainArr[vObjdx].telp_hp||"");
		setInpAlamat(UFunc.br2nl(uDatamainArr[vObjdx].alamat||""));
		setDialogHeader((uBahasaObj.caption_editdata||"caption_editdata")+": "+(uDatamainArr[vObjdx].nama || "").toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}
	const hdlKlikHapus=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>"+uDatamainArr[vObjdx].nama.toUpperCase()+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiHapus(_IDTABEL);
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initFormAwal = () => {
		setTabelIDPilih(0);

		setInpDokternama("");
		setInpTelphp("");
		setInpAlamat("");
	}
	const contentMain = () => {
		if(uHTMLError500) return ""
		uFuncName	= contentMain.name;

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_dokter || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		
		//console.log("("+cComponentName+"-"+uFuncName+")");

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow>
				<CCol md="4" className="text-start">{uBahasaObj.caption_namadokter||"caption_namadokter"}</CCol>
				<CCol md="4" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" className="text-center">{uBahasaObj.caption_telphp||"caption_telphp"}</CCol>
				<CCol md="2">&middot;</CCol>
				</CRow>
			</th>
			</tr>
			<tr className="d-md-none classfontsmaller">
			<th width={30} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>
			
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id, telp_hp, nama, alamat
			} = vItems;

			vNumber++;
			const vCaptionUpper	= nama.toUpperCase();

			const vDokternama	= vKeyword==="" ? ("DRH. "+nama) 
				: ("DRH. "+nama).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTelphp= vKeyword==="" ? (telp_hp||"-") 
				: (telp_hp||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vAlamat= vKeyword==="" ? (alamat||"-") 
				: (alamat||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
				
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td align="right" className="px-1">{vNumber}.</td>
				<td className="px-1 text-end">
				<CRow className="mx-0">
				<CCol xs="12" md="4" className="px-1 text-start d-flex justify-content-between">
					<div className="text-info">{UFunc.renderHTML(vDokternama)}</div>

					<CDropdown className="d-md-none">
					<CDropdownToggle caret={false} size="sm" className="p-0"
						color="transparent">
						<CIcon icon="cilOptions" className="classikoncoreui ms-0 me-2" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu className="px-0">
					<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
						<CIcon className="classikonedit ms-0 me-2 align-middle" height={25}/>
						{uBahasaObj.caption_editdata||"Edit Data"}
					</CDropdownItem>

					{uIsAllowedhapus && (
					<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
						<CIcon className="classikonhapus ms-0 me-2 align-middle" height={25}/>
						{uBahasaObj.caption_hapusdata||"Hapus Data"}
					</CDropdownItem>
					)}
					</CDropdownMenu>
					</CDropdown>
				</CCol>

				<CCol xs="12" className="d-md-none my-3 px-1 classborderbottom"/>
				
				<CCol xs="3" className="px-1 text-start classfontsmaller d-md-none">Alamat</CCol>
				<CCol xs="9" md="4" className="px-1 text-md-start classfontsmaller fst-italic">
					{UFunc.renderHTML(vAlamat)}
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none classfontsmaller">Telp/HP</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-center classfontsmaller">
					<strong className="text-success">{UFunc.renderHTML(vTelphp.toUpperCase())}</strong>
				</CCol>

				<CCol md="2" className="px-1 d-none d-md-block text-md-center">
					<CTooltip content={"--"+(uBahasaObj.caption_editdata || "caption_editdata")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer"/>
					</CTooltip>
					{uIsAllowedhapus && (
					<>
						<span className="mx-1">&middot;</span>
						<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
						<CLink 
							className={"classikontabel classikonhapus classcursorpointer"}
							onClick={()=>hdlKlikHapus(id)} />
						</CTooltip>
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const loadContentForm=()=>{
		if(!isShowForm) return (<></>)//>

		return (
			<>
			<CForm className="mx-auto mx-lg-5">
			<CRow className="my-2">
				<CCol md="4" className="text-md-end">
					{uBahasaObj.caption_namadokter||"caption_namadokter"} &middot; DRH.
				</CCol>
				<CCol md="8">
					<CFormInput maxLength="100"
						type="text"
						size="sm"
						className="form-control text-capitalize"
						value={uInpDokternama}
						onChange={(e) => setInpDokternama(e.target.value)}
						id="inpdokternama"/>
				</CCol>
			</CRow>
			<CRow className="my-2">
				<CCol md="4" className="text-md-end">
					{uBahasaObj.caption_telphp||"caption_telphp"}
				</CCol>
				<CCol md="8">
				<CFormInput maxLength="50"
					type="text"
					size="sm"
					className="form-control text-uppercase"
					value={uInpTelphp}
					onChange={(e) => setInpTelphp(e.target.value)}
					id="inptelphp" />
				</CCol>
			</CRow>
			<CRow className="my-2">
				<CCol md="4" className="text-md-end">
					{uBahasaObj.word_alamat||"word_alamat"}
				</CCol>
				<CCol md="8">
					<CFormTextarea
						size="sm"
						value={uInpAlamat}
						onChange={(e) => setInpAlamat(e.target.value)}
						onKeyDown={(e) => hdlLastFieldKDown(e)}
						id="inpalamat" />
					<div className="classpetunjuk">*) {uBahasaObj.petunjuk_textarea||"petunjuk_textarea"}</div>
				</CCol>
			</CRow>
			</CForm>
			</>
		)//>
	}
	//---END Init_LOAD--/

	//---API_FUNC--/
	const apiLoadData=()=>{
		uFuncName = apiLoadData.name;
		setHTMLError500();

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);
		setLoading(true);

		const vKeyword = uKeywordObj.kwd_dokter || "";

		/*/--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+cComponentName+"-"+uFuncName+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDatamainArr(vTmpObj);
				}
				setJmlData((parseInt(vTmpObj.length)||0));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+uFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProses=()=>{
		uFuncName = apiProses.name;

		/*/--TESTING_FRONTEND--//
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_dokternama : uInpDokternama,
			send_telphp : uInpTelphp,
			send_alamat : uInpAlamat,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/pr_simpan";

		setLoading(true);
		ElBtnSimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			ElBtnSimpan.disabled = false;

			if(output_string.sukses) {
				let vDatamainArrNew;
				if(uTabelIDPilih === 0) {
					const vTmpObject	= {
						id 		: output_string.tabelid || "255",
						telp_hp : (uInpTelphp||"").trim(),
						nama 	: UFunc.capitalize((uInpDokternama||"").trim()),
						alamat 	: UFunc.nl2br(UFunc.capitalize((uInpAlamat||"").trim())),
					}
					vDatamainArrNew = [vTmpObject].concat(uDatamainArr);
				} else {
					vDatamainArrNew = uDatamainArr;
					const vObjdx				= UFunc.getObjectIndeks(vDatamainArrNew,uTabelIDPilih);
					vDatamainArrNew[vObjdx].nama 	= UFunc.capitalize(uInpDokternama.trim());
					vDatamainArrNew[vObjdx].telp_hp = (uInpTelphp||"").trim();
					vDatamainArrNew[vObjdx].alamat 	= UFunc.nl2br(UFunc.capitalize((uInpAlamat||"").trim()));
				}

				setDatamainArr(vDatamainArrNew);
				initFormAwal();

				if(uTabelIDPilih > 0) {
					setEfekview(!uEfekview);
					hdlToggleForm();
				}  else {
					setTabelRowSelect(-1);
					setJmlData((parseInt(vDatamainArrNew.length)||0));
					showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
					setPageActive(1);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				ElBtnSimpan.disabled = true;
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			ElBtnSimpan.disabled = false;
			console.log("("+cComponentName+"-"+uFuncName+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiHapus=(_IDTABEL)=>{
		uFuncName 	= apiHapus.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		/*/---TESTING_FRONTEND--/
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/pr_hapus";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+uFuncName+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoadData();

    	return ()=>{ 
			uDispatch({type: "set", gInitHeaderAction: {}});
			setShowForm(false);
    		setDatamainArr([]) 
    	}
	},[]);
	/*React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;

		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoadData();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;

		setDialogHeader((uBahasaObj.word_tambah||"word_tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setShowForm(true);
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return; }

		//console.log("("+cComponentName+"[isShowForm]) isShowForm 1: "+isShowForm);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnSimpan(document.getElementById("btnDialogSimpan"));

			const vElFocus = document.getElementById("inpdokternama");
			vElFocus && vElFocus.focus();
		},200);
	},[isShowForm]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);
	},[uJmlData]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		if(vElJmlData) {
			const vKeyword = uKeywordObj.kwd_dokter || "";
			if (vKeyword.length > 2) {
				if(vElJmlData.innerHTML !== UFunc.formatAngka(uJmlData)) {
					vElJmlData.innerHTML = UFunc.formatAngka(uJmlData);
				}
			} else {
				if(vElJmlData.innerHTML !== "&#8734;") {
					vElJmlData.innerHTML = "&#8734;";
				}
			}
		}
	});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	//---END EFFECT--/

	//console.log("("+cComponentName+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>);
	
	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_dokter || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikSimpan()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={loadContentForm()}/>
			
		<iframe id="ifmcontentstoprint" className="d-none" />
		</>
	)//>
}
	
export default Dokter;
