import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CTooltip,
	CLink,
	CListGroup,
	CListGroupItem,
	CForm,
	CFormInput,
	CFormSelect,
	CSpinner,
	CButton,
	CInputGroup,
	CInputGroupText,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPagination,
	MyPage500,
	MyDialogform,
	MyDialogview,
	MyDialoglookup,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson		= require('../../package.json');
const Kasbon 	= (props) => {
	const uComponentname 	= Kasbon.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);

	//--GLOBAL_VARS--/
	const uIsAllowedhapus	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted;
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	//---DATA--/
	const uKeywordObj						= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  				= useSelector(state => state.gInitHeaderAction);
	const uHandelView 						= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah						= uHeaderActionObj.isHeaderTambah || false;
	const uHandelEdit 						= uHeaderActionObj.isHeaderEdit || false;
	const [uFirstload,setFirstload]			= React.useState("YA");

	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const uElJmldata						= document.getElementById("idjmldata");
	//---END DATA--/

	//--FORMDIALOG--/
	const uKasGLArr							= JSON.parse(localStorage.getItem("kasgllist")||"[]");
	const [uIsShowForm,setShowForm]			= React.useState(false);
	const [uDialogHeader,setDialogHeader]	= React.useState("");

	const [uInpStatustembak,setInpStatustembak]= React.useState("REGULER");
	const [uInpTglBayar,setInpTglBayar]		= React.useState(UFunc.DbDate());
	const [uInpKasbonbefore,setInpKasbonbefore]= React.useState(0);
	const [uInpNilaiBayar,setInpNilaibayar]	= React.useState(0);
	const [uInpKasID,setInpKasID]			= React.useState(0);
	const [uInpKascaption,setInpKascaption]	= React.useState("");
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]= React.useState(document.getElementById("btnDialogSimpan"));
	//--END FORMDIALOG--/

	const [uIsShowhistori,setShowhistori]	= React.useState(false);
	const [uDataHistoriObj,setDataHistoriObj]= React.useState([]);

	//--DLG_LOOKUP--/
	const [uDlglookupheader,setDlglookupheader]	= React.useState("");
	const [uInpDlglookup,setInpDlglookup]		= React.useState("");
	const [uDataDlglookupArr,setDataDlglookupArr]= React.useState([]);
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false);
	//--END DLG_LOOKUP--/
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleForm = () => { setShowForm(false) }
	const hdlKlikFormSimpan = () => {
		//---VALIDASI--/
		if(UFunc.isEmpty(uInpTglBayar)) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.caption_tglbayar||"caption_tglbayar")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}

		const vTanggalDt 	= new Date(uInpTglBayar);

		const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
		if(vDateBeyond) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
			return;
		}
		const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
		if(vDateExceededBl) {
			document.getElementById("inptglbayar").focus();
			showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
			return;
		}

		if((parseInt(uInpNilaiBayar)||0) <= 0 ) {
			document.getElementById("inpnilaibayar").focus();
			showToast((uBahasaObj.caption_nilaibayar||"caption_nilaibayar")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}
		if((parseInt(uInpNilaiBayar)||0) > (parseInt(uInpKasbonbefore)||0)) {
			document.getElementById("inpnilaibayar").focus();
			showToast((uBahasaObj.pesan_nilaibayarexceeded||"pesan_nilaibayarexceeded")+"..");
			return;
		}
		if((parseInt(uInpKasID)||0) === 0) {
			document.getElementById("inpkascaption").focus();
			showToast((uBahasaObj.caption_kaspenerima||"caption_kaspenerima")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		//---END VALIDASI--/

		apiSimpanBayar();
	}
	const hdlToggleHistori = () => { setShowhistori(false) }
	const hdlToggleDlglookup = () =>{ setDlglookupshow(false) }
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		const vIdx = UFunc.getObjectIndeks(uDataDlglookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		const vElsfocusnext = document.getElementById("inpketerangan");
		setInpKasID(uDataDlglookupArr[vIdx].id)
		setInpKascaption(uDataDlglookupArr[vIdx].caption)
		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			vElsfocusnext && vElsfocusnext.focus()
		},350);
	}
	const hdlKlikKaslookup = () => {
		//setInpDlglookup(uInpKascaption);
		setInpKascaption(""); setInpKasID("");
		const vTmpArr	= uKasGLArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_kaspenerima||"").toUpperCase());
		setDlglookupshow(true)
	}

	const hdlKlikHistori=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		//alert(vObjdx);return;
		const vHeadercaption = (uBahasaObj.caption_historikasbon||"caption_historikasbon")+": "+(uDatamainArr[vObjdx].nama_karyawan||"").toUpperCase();
		setDialogHeader(vHeadercaption);
		apiLoadHistori(_IDTABEL);

		setShowhistori(true);
	}
	const hdlKlikBayar=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		const vSisakasbon	= parseInt(uDatamainArr[vObjdx].jumlah_kasbon)||0;

		const vHeadercaption = (uBahasaObj.caption_bayarkasbon||"caption_bayarkasbon")+": "+
			(uDatamainArr[vObjdx].nama_karyawan||"").toUpperCase()+
			" (Sisa Rp "+UFunc.formatAngka(vSisakasbon)+")"+
			"";
		setTabelIDPilih(_IDTABEL);
		setInpKasbonbefore(vSisakasbon);
		setDialogHeader(vHeadercaption);
		setShowForm(true);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initJmldata=()=>{
		const vKeyword = uKeywordObj.kwd_kasbon || "";
		if (vKeyword.length >= 3) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFormbayarAwal=()=>{
		setInpStatustembak("REGULER");
		setTabelIDPilih(0);
		setInpTglBayar(UFunc.DbDate());
		setInpKasID("0")
		setInpKascaption("")
		setInpNilaibayar(0);
		setInpKasbonbefore(0);
		setInpKeterangan("");
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_kasbon || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataViewObj	= uDatamainArr.slice(vMin,(vMax));
		
		//console.log("("+uComponentname+"-loadContentBody) vDataViewObj "+JSON.stringify(vDataViewObj));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top text-center classfontsmaller">
			<th width={30} className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="4" className="text-start">{uBahasaObj.caption_namakaryawan||"caption_namakaryawan"}</CCol>
				<CCol md="4" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_totalkasbon||"caption_totalkasbon"}<div className="classfontsmaller">(Rp)</div></CCol>
				<CCol md="2" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>
		</thead>

		<tbody>
		{vDataViewObj.map((vItems,vKeys)=>{
			const {
				id,nama_karyawan,posisi,telp_hp,jumlah_kasbon,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (nama_karyawan||"").toUpperCase();

			const vNamaKaryawan	= vKeyword==="" ? UFunc.capitalize(nama_karyawan||"") 
				: (UFunc.capitalize(nama_karyawan||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vPosisi= vKeyword==="" ? (posisi||"") 
				: (posisi||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTelphp= vKeyword==="" ? (telp_hp||"") 
				: ((telp_hp||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
				
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td width={30}  className="px-1 pe-0">{(vNumber)}.</td>
				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="4" className="px-1 text-start d-flex justify-content-between">
					<span className="text-info">{UFunc.renderHTML(vNamaKaryawan)}</span>

					{(uIsAllowedhapus) && (
					<CDropdown className="d-md-none">
					<CDropdownToggle caret={false} className="p-0" color="transparent">
						<CIcon icon="cilChevronBottom" className="classikoncoreui" height={25}/>
					</CDropdownToggle>
					
					<CDropdownMenu className="py-0">
						<CDropdownItem onClick={()=>hdlKlikHistori(id)}>
						<CIcon className="classikonhistoribayar mx-0 me-2 align-middle" height={25}/>
						Histori Kasbon
						</CDropdownItem>

						<CDropdownItem onClick={()=>hdlKlikBayar(id)}>
						<CIcon className="classikonbayar mx-0 me-2 align-middle" height={25}/>
						Bayar Kasbon
						</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
					)}
				</CCol>

				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>
				
				{(posisi||"")!=="" && (
				<>
				<CCol xs="6" className="px-1 text-start d-md-none">{uBahasaObj.word_posisi||"word_posisi"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					<span className="fw-bolder">{UFunc.renderHTML(vPosisi)}</span>
				</CCol>
				</>
				)}
				
				{(telp_hp||"")!=="" && (
				<>
				<CCol xs="6" className="px-1 text-start d-md-none">{uBahasaObj.caption_telphp||"caption_telphp"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">
					<span className="fw-bolder">{UFunc.renderHTML(vTelphp)}</span>
				</CCol>
				</>
				)}

				<CCol md="4" className="px-1 text-start d-none d-md-block">
					{(posisi||"")!=="" && (
					<div><small>
						{uBahasaObj.word_posisi||"word_posisi"}:{" "}
						<span className="fw-bolder text-uppercase">{UFunc.renderHTML(vPosisi)}</span>
					</small></div>
					)}
					{(telp_hp||"")!=="" && (
					<div>
					<small>{uBahasaObj.caption_telphp||"caption_telphp"}: </small>
					<strong>{UFunc.renderHTML(vTelphp)}</strong>
					</div>
					)}
				</CCol>

				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_jmlkasbon||"caption_jmlkasbon"} (Rp)</CCol>
				<CCol xs="6" md="2" className="px-1 text-success">
					{UFunc.formatAngka(jumlah_kasbon)}
				</CCol>

				<CCol md="2" className="px-1 text-center d-none d-md-block">
				{(uIsAllowedhapus) ? (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_bayarkasbon||"caption_bayarkasbon")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer classikontabel classikonbayar"
						onClick={()=>hdlKlikBayar(id)} />
					</CTooltip>

					<span className="d-md-none mx-1">&middot;</span>

					<CTooltip content={"--"+(uBahasaObj.caption_historikasbon||"caption_historikasbon")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikHistori(id)} className="classcursorpointer classikontabel classikonhistoribayar"/>
					</CTooltip>
					</>
				) : ( <>&middot;</>)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		);
	}
	const contentDlgform = () => {
		if(!uIsShowForm) return (<></>)

		return (
		<CForm>
			{uTokenObj.userhak === "FREEROLE" && (
			<>
			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
			</CCol>
			<CCol xs="8" lg="4">
				<CFormSelect size="sm"
						onChange={(e)=>setInpStatustembak(e.target.value)}
						value={uInpStatustembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
				</CFormSelect>

				<div className="classpetunjuk">
					<small>*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL</small>
				</div>
			</CCol>
			</CRow>

			<div className="mx-0 my-3 classborderbottom"/>
			</>
			)}

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">{uBahasaObj.caption_tglbayar||"caption_tglbayar"}</CCol>
			<CCol xs="8" lg="4">
				<CFormInput
					size="sm"
					type="date"
					value={uInpTglBayar}
					onChange={(e)=>setInpTglBayar(e.target.value)}
					id="inptglbayar"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</CCol>
			<CCol xs="8" lg="4">
				<NumberFormat 
					value={uInpNilaiBayar}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => setInpNilaibayar(values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							onChange={(e) => setInpNilaibayar(e.target.value)}
							size="sm"
							className="text-end"
							onFocus={(e)=>e.target.select()}
						id="inpnilaibayar"/>
					)} 
				prefix={isMobile ? "" : "Rp"}/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">{uBahasaObj.caption_kaspenerima||"caption_kaspenerima"}</CCol>
			<CCol xs="8" lg="4">
					<CInputGroup size="sm">
						<CFormInput size="sm"
							value={uInpKascaption}
							onClick={()=>hdlKlikKaslookup()}
							placeholder={"--"+uBahasaObj.word_pilih+": "+(uBahasaObj.caption_kaspenerima)+".."}
							id="inpkascaption"/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikKaslookup()}
							id="btnkaslookup">
						<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
					</CInputGroup>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
			<CCol>
				<CFormInput size="sm"
					maxLength={255}
					value={uInpKeterangan}
					onChange={(e)=>setInpKeterangan(e.target.value)}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpketerangan"/>
			</CCol>
			</CRow>
		</CForm>
		)
	}
	const contentDlgview = () => {
		if(!uIsShowhistori) return (<></>);

		if(uDataHistoriObj.length <= 0) return (
			<div className="text-center">
				<CSpinner color="warning" style={{width: 40,height: 40}} role="status"/>
			</div>
		);

		return (
			<CListGroup className="mx-1">
			{uDataHistoriObj.map((vItems,vKeys)=>{
				const {
					id,tgl_kasbon,ket,nilai_kasbon,terbayar
				} = vItems;

				const vBgcolor 	= vKeys%2===0 ? "secondary" : "white";
				const vAccent 	= vKeys%2===0 ? "success" : "warning";
				return (
					<CListGroupItem key={vKeys}
						className={"p-1 py-2 border-start-5 border-start-"+vAccent}
						color={vBgcolor}>
					<CRow className="mx-0">
					<CCol xs="12" lg="6" className="px-1">
						<div><small>{UFunc.HariAngka(tgl_kasbon)}</small></div>
						<small className="classfontsmaller fst-italic text-info">{UFunc.capitalize(ket)}</small>
					</CCol>
					<CCol xs="5" className="d-lg-none px-1"><small>{uBahasaObj.caption_jmlkasbon||"caption_jmlkasbon"}</small></CCol>
					<CCol xs="7" lg="2" className="text-end px-1">
						<div className="fw-bolder">Rp{UFunc.formatAngka(nilai_kasbon)}</div>
						{vKeys<=0 && (
							<div className="classpetunjuk d-none d-md-block d-lg-inline">{uBahasaObj.caption_jmlkasbon||"caption_jmlkasbon"}</div>
						)}
					</CCol>
					<CCol xs="5" className="d-lg-none px-1"><small>{uBahasaObj.word_terbayar||"word_terbayar"}</small></CCol>
					<CCol xs="7" lg="2" className="text-end px-1">
						<div className="">Rp{UFunc.formatAngka(terbayar)}</div>
						{vKeys<=0 && (
						<div className="classpetunjuk d-none d-md-block d-lg-inline">{uBahasaObj.word_terbayar||"word_terbayar"}</div>
						)}
					</CCol>
					<CCol xs="5" className="d-lg-none px-1"><small>{uBahasaObj.caption_nilaisisa||"caption_nilaisisa"}</small></CCol>
					<CCol xs="7" lg="2" className="text-end px-1 text-primary">
						<div className="text-danger">Rp{UFunc.formatAngka((nilai_kasbon-terbayar))}</div>
						{vKeys<=0 && (
						<div className="classpetunjuk d-none d-md-block d-lg-inline">{uBahasaObj.caption_nilaisisa||"caption_nilaisisa"}</div>
						)}
					</CCol>
					</CRow>
					</CListGroupItem>
				)
			})}
			</CListGroup>
		);
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = () => {
		uFuncname = apiLoaddata.name;
		setHTMLError500();

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		const vKeyword = uKeywordObj.kwd_kasbon || "";
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoaddata) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasbon/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDatamainArr(vTmpObj);
				}
				setJmlData((parseInt(vTmpObj.length)||0));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadHistori = (_IDTABEL) => {
		uFuncname = apiLoadHistori.name;
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoadHistori) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		setDataHistoriObj([]);
		const vDATAS	= JSON.stringify({
			send_karyawanid : _IDTABEL.toString(),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasbon/ld_histori";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.historiobject||"[]");
				setDataHistoriObj(vTmpObj);
				if(vTmpObj.length <= 0) { hdlToggleHistori() }
			} else if(output_string.info) {
				showToast(output_string.info);
				setShowhistori(false);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
				setShowhistori(false);
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setShowhistori(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanBayar = () => {
		uFuncname = apiSimpanBayar.name;

		const vKaryawanID = parseInt(uTabelIDPilih) || 0;
		if(vKaryawanID <= 0) return;
		const vJmlKasbonbefore	= parseInt(uInpKasbonbefore) || 0;

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_statusjurnal : uInpStatustembak,
			send_karyawanid : vKaryawanID,
			send_tglbayar : uInpTglBayar,
			send_nilaibayar : uInpNilaiBayar,
			send_kasid : uInpKasID,
			send_keterangan : uInpKeterangan,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasbon/pr_simpanbayar";

		setLoading(true);
		uElBtnDlgsimpan.disabled=true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnDlgsimpan.disabled=false;
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				const vIdx 	= UFunc.getObjectIndeks(uDatamainArr,vKaryawanID);
				uDatamainArr[vIdx].jumlah_kasbon = vJmlKasbonbefore - (parseInt(uInpNilaiBayar)||0);
				hdlToggleForm();

				if(output_string.pesankhusus) {
					const vPesan = output_string.pesankhusus;
					showToast(vPesan,"SUKSES");
				}
			} else if(output_string.info) {
				showToast(output_string.info);
				setShowhistori(false);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
				setShowhistori(false);
			} else if(output_string.expired) {
				setLoading(true);
				uElBtnDlgsimpan.disabled=true;
				prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnDlgsimpan.disabled=false;
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return ()=>{
			uDispatch({type: "set", gInitHeaderAction: {}});
			setShowForm(false); hdlToggleDlglookup();
    		setDatamainArr([]);
    		setDataHistoriObj([]);
    		setDataDlglookupArr([]);
    	}
	},[]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uIsEOMExecuted) return;

		uNavigate("/subkeuangan/kasbon/forminput"); 
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!uHandelEdit) return;

		uHeaderActionObj.isHeaderEdit = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uIsEOMExecuted) return;

		uNavigate("/subkeuangan/kasbon/listdata");
	},[uHandelEdit]);
	React.useEffect(()=>{ uElJmldata && initJmldata() },[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uIsShowForm) 
			{ initFormbayarAwal(); return; }

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnDlgsimpan(document.getElementById("btnDialogSimpan"))

			if(!isMobile)
				document.getElementById("inptglbayar") && 
					document.getElementById("inptglbayar").focus();
		},350);
	},[uIsShowForm]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_kasbon || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block"/>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>
			
			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlglookupheader||"uDlglookupheader"}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikFormSimpan()}
			show={uIsShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowhistori} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleHistori}
			renderContent={contentDlgview()}/>
		</>
	)
}
	
export default Kasbon;
