import React from 'react';
import { useSelector } from 'react-redux';
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CCloseButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { MyButton } from './index';
import { UFunc } from "../helpers/functions";
import PropTypes from 'prop-types';

const MyDialogsession = props => {	
	const {
		show,toggle,Countertime,onKlikyes,...rest
	} = props;

	//const uDispatch		= useDispatch();
	const uBahasaob  	= useSelector(state => state.listBahasa);

	/*React.useEffect(() => {
		if(show) {
			uDispatch({type: "set", sessionISnotify: true});
		}
	},[show]);//-*/

	return (
		<CModal
			alignment="center"
			visible={show}
			scrollable 
			{...rest}>
		<CModalHeader closeButton={false}
			className="bg-danger text-white">
			<CModalTitle>
				<div className="d-none d-md-block">Alert: Waktu Session !</div>
				<small className="d-md-none">Alert: Waktu Session !</small>
			</CModalTitle>
			<CCloseButton onClick={toggle}/>
		</CModalHeader>

		<CModalBody>
			<div>
				<big>Waktu Session Anda Tinggal:{" "}
				<big className="ms-1"><b className={parseInt(Countertime)>=0?"text-primary":"text-danger"}>{UFunc.formatAngka(Countertime)}</b></big>
				<span className="ms-1">detik</span>
				</big>
			</div>

			<div className="classfontsmaller m-3">
			<small>
				<p className="my-1">
				Ketika Waktu Session <b>SUDAH <span className="text-danger">MINUS</span></b> dan Berwarna <b className="text-danger">MERAH</b>,
				Itu Menandakan Waktu Session ANDA <b>SUDAH HABIS</b>.
				</p>
				<p className="my-1">Jika SUDAH MINUS, Anda <b>TIDAK DAPAT</b> Melakukan Pembaruan SESSION, dan <b className="text-primary">WAJIB Melakukan LOGIN Ulang</b>..</p>
				<p className="my-1 fst-italic">
				Segera Lakukan Pembaruan untuk Mengupdate SESSION Anda, sebelum waktu Anda Habis dan <b className="text-danger">Berwarna MERAH</b>..
				</p>
			</small>
			</div>

			<div>
			Tekan <b className="text-primary">YA</b> Untuk Pembaruan SESSION !
			</div>
		</CModalBody>
		<CModalFooter>
		<MyButton
			onClick={()=>onKlikyes()}
			id="btnDlgsessionYa">
			{uBahasaob.word_ya||"YA"}
		</MyButton>{" "}
		<CButton color="light" onClick={toggle}>{uBahasaob.word_tidak||"Tidak"}</CButton>
		</CModalFooter>
		</CModal>
	)
}
MyDialogsession.defaultProps = {
	show: false,
	toggle: () => { return false }
};
MyDialogsession.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,      
	onKlikyes: PropTypes.func,
}

export default MyDialogsession;