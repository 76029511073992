import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,
	//CFormInput,CInputGroup,CInputGroupText,
	CButton,
	CImage,
	CSpinner,
	CCard,CCardHeader,CCardFooter,CCardBody,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyPagination,
	MyPage500,
	MyButton,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Tutupperiode= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,clearStorage
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uUserSettingObj 		= useSelector(state => state.gListUserSetting);
	const uSettingsystemObj		= useSelector(state => state.gSystemsetting);
	const [uEfekview,setEfekview]= React.useState(false);
	const uIsEOMExecuted		= useSelector(state => state.gIsEOMExecuted);
	const uEOMLabel				= useSelector((state) => state.gEOMLabel) || "";
	
	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uUserSettingObj.jml_mobile || 30) : (uUserSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderactionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderactionObj.isHeaderView || false;
	//const uHeaderTutupperiode 	= uHeaderactionObj.isHeaderTutupperiode || false;
	//const uHeaderBroken 		= uHeaderactionObj.isHeaderBroken || false;

	/*//---PAGINATION_DIATUR_BACKEND--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//---END PAGINATION_DIATUR_BACKEND--*/

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uDatamainObj,setDatamainObj]		= React.useState({});
	const [uISLoadingview,setLoadingview]	= React.useState(false);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uISLoadingeod,setLoadingeod]		= React.useState(false);

	//---DOM--/
	const uTanggalNowDT						= new Date();
	/*const uElCardlast	= document.getElementById("idcardlast")
	const uElCardnext	= document.getElementById("idcardnext")
	const uElCardlastHeight	= uElCardlast && (uElCardlast.offsetHeight);
	const uElCardnextHeight	= uElCardnext && (uElCardnext.offsetHeight);
	//---END DOM--*/

	//---HANDLE--/
	const hdlKlikProseseod = async() => {
		if(await Konfirm(
			"<b>PROSES End-Of-Day (EOD)..</b>"+
			"<br/>EOD akan memproses Jurnal Transaksi setiap harinya."+
			"<br/><br/>PERHATIAN: <b>Proses Ini tidak dapat dibatalkan !</b>"+
			"<br/><br/>Apakah Anda Yakin ?"+
			"")) apiProsesEOD();
	}
	const hdlKlikProses = async() => {
		if(await Konfirm(
			"<b>PROSES TUTUP PERIODE..</b>"+
			"<br/>EOM/EOY akan memproses Biaya Penyusutan per Bulan dan "+
			"menghitung Laba/Rugi Periode Tersebut."+
			"<br/><br/>PERHATIAN: <b>Proses Ini tidak dapat dibatalkan dan diulang !</b>"+
			"<br/><br/>Apakah Anda Yakin ?"+
			"")) apiProsestutup();
	}
	const hdlKlikProsesstatus = async() => {
		if(await Konfirm(
			"<b>PROSES Pembatalan Status PROSES..</b>"+
			"<br/>Status DALAM PROSES Tutup Perode Akan Dibatalkan, agar Input Transaksi Dapat Dilakukan Kembali."+
			"<br/><br/>Apakah Anda Yakin ?"+
			"")) apiProsesbatalstatus();
	}
	//---END HANDLE--/

	//---PROSES--/
	//---END PROSES--/

	//---CONTENT--/
	const contentData = () => {
		if(uHTMLError500) return ""

		if(uISLoadingview) return (
			<center><CSpinner color="primary" className="mx-auto"/></center>
		)//>

		return (
		<>
		<CRow className="mx-0">
			<CCol xs="6"><small>Tutup Periode Terakhir</small></CCol>
			<CCol xs="6" className="fw-bolder text-end">{UFunc.TglAngka(uDatamainObj.tgl_lasttutup)}</CCol>

			<div className="mt-3"/>

			<CCol xs="6"><small>Jenis Tutup Periode</small></CCol>
			<CCol xs="6" className="fw-bolder text-end text-primary">{uDatamainObj.caption_lasttutup || "END OF MONTH"}</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<div className="classpetunjuk px-0">
		<b>CATATAN</b>:
		<ol className="ps-3">
		<li>Tutup Periode terdiri dari 3 Proses. Yaitu, <b>End-Of-Days (EOD)</b>, <b>End-Of-Month (EOM)</b>, dan <b>End-Of-Year</b>.</li>
		<li className="mt-2"><b>EOD</b> Dilakukan setiap Akhir Hari, untuk memproses JURNAL AKUNTANSI Transaksi selama SATU Hari. Proses Ini tidak WAJIB dilakukan secara MANUAL, karena Sistem akan Memprosesnya Setiap Akhir Hari secara otomatis.</li>
		<li className="mt-2"><b>EOM</b> Harus Dilakukan Secara MANUAL pada Tanggal Akhir setiap Bulannya. 
			Proses Ini dilakukan untuk memproses JURNAL Penyusutan Aset per Bulan.</li>
		<li className="mt-2"><b>EOY</b> Harus Dilakukan Secara MANUAL pada Tanggal Akhir setiap Tahunnya. 
			Proses Ini dilakukan untuk mengakhiri Proses Pembukuan Tiap Tahun.</li>
		<li className="mt-2"><b>NOTE</b>: Terkadang Proses Tutup Perode <b>Tidak BERHASIL</b> selesai dengan SEMPURNA, dan Status Prosesnya <b>TETAP DALAM PROSES</b>.
			<br/>Jika Demikian, Silahkan KLIK Tombol <b>BATALKAN STATUS EOM</b>, agar Proses Input Transaksi Dapat Dilakukan Kembali.</li>
		</ol>
		</div>
		</>
		)//>
	}
	const contentFormeod = () => {
		if(uHTMLError500) return ""

		if(uISLoadingview) return (
			<center><CSpinner color="primary" className="mx-auto"/></center>
		)//>

		if(uISLoadingeod) return (
			<center><CSpinner color="danger" className="mx-auto"/></center>
		)//>

		return (
		<>
		<div className="d-flex justify-content-between px-3">
			<div><small>Tgl EOD Terakhir</small></div>
			<div className="text-end fw-bolder classfontsmaller text-primary">
			{!UFunc.isEmpty(uSettingsystemObj.tgl_last_eod) 
				? UFunc.HariAngka(uSettingsystemObj.tgl_last_eod)+" "+UFunc.JamMenitDetik(uSettingsystemObj.tgl_last_eod) 
				: "-" }
			</div>
		</div>

		{(uTokenObj.userhak === "FREEROLE" && (uDatamainObj.eom_is_running||"TIDAK")==="YA") && (
		<>
			<div className="my-3 classborderbottom"/>
			<div className="d-flex justify-content-between px-3">
				<div className="text-danger"><small>Reactivate Proses EOD/EOM/EOY</small></div>
				<div className="text-end fw-bolder">
				<CButton size="sm" color="danger" className="text-white"
					onClick={()=>hdlKlikProsesstatus()}
					id="btnBatalflageom">
					<small>Batalkan Status EOM</small>
				</CButton>
				</div>
			</div>
		</>
		)}

		<div className="my-3 classborderbottom"/>

		{(uTokenObj.userhak === "FREEROLE") ? (
		<CForm className="text-center">
			<MyButton
				onClick={()=>hdlKlikProseseod()}
				disabled={!uIsEOMExecuted || (uDatamainObj.eom_is_running||"TIDAK")==="YA"}
				id="btnProsesEOD">
				Proses EOD
			</MyButton>
		</CForm>
		) : (
		<div className="text-center fst-italic p-md-3 px-md-5 text-danger">
			Jenis User Anda, Tidak Diperbolehkan Melakukan Proses End-Of-Days (EOD)
		</div>
		)}
		</>
		)//>
	}
	const contentFormtutup = () => {
		if(uHTMLError500) return ""

		if(uISLoadingview) return (
			<center><CSpinner color="danger" className="mx-auto"/></center>
		)//>

		if(uISLoadingeod) return (
			<center><CSpinner color="danger" className="mx-auto"/></center>
		)//>

		const vISDisabledEOM = ((uEOMLabel !== "") 
				&& (uEOMLabel.toUpperCase()!=="EOM WARNING")
				&& ((uDatamainObj.caption_nexttutup||"").toUpperCase() === "END OF MONTH"))
			 || (uDatamainObj.eom_is_running||"TIDAK")==="YA";

		return (
		<>
		<CRow className="mx-0">
			<CCol xs="6"><small>Tgl Tutup Next</small></CCol>
			<CCol xs="6" className="text-end fw-bolder">{UFunc.HariAngka(uDatamainObj.tgl_nexttutup)}</CCol>
			
			<div className="mt-3"/>

			<CCol xs="6"><small>Jenis Tutup Next</small></CCol>
			<CCol xs="6" className="text-end fw-bolder">{uDatamainObj.caption_nexttutup||"END OF MONTH"}</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		{uTokenObj.userhak === "FREEROLE" ? (
		<CForm className="text-center">
			<MyButton
				onClick={()=>hdlKlikProses()}
				disabled={vISDisabledEOM}
				id="btnProses">
				Proses Tutup
			</MyButton>
		</CForm>
		) : (
		<div className="text-center fst-italic fw-bolder p-md-3 px-md-5 text-danger">
			Jenis User Anda, Tidak Diperbolehkan Melakukan TUTUP PERIODE
		</div>
		)}
		</>
		)//>
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = () => {
		setHTMLError500();
		setDatamainObj({});
		setLoadingview(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoadingview(false);
			setLoading(false);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_tutupperiode/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoadingview(false);
			setLoading(false);
			//console.log("(Tutupperiode - apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpObj	= JSON.parse(output_string.dataobject || "{}");
				//vTmpObj.eom_is_running = "YA";//--TESTING--/
				setDatamainObj(vTmpObj);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Tutupperiode - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoadingview(false);
			setLoading(false);
			console.log("(Tutupperiode - apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsestutup = () => {
		const vElbutton 	= document.getElementById("btnProses");
		const vElbuttoneod 	= document.getElementById("btnProsesEOD");
		vElbutton && (vElbutton.disabled = true);
		vElbuttoneod && (vElbuttoneod.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout); setLoading(false);
			vElbutton && (vElbutton.disabled = false);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parsdata : JSON.stringify(uDatamainObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_tutupperiode/pr_tutup";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElbutton && (vElbutton.disabled = false);
			vElbuttoneod && (vElbuttoneod.disabled = false);
			setLoading(false);
			//console.log("(Tutupperiode - apiProsestutup) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				//setDatamainObj(JSON.parse(output_string.dataobjectnew || "{}"));
				//uDispatch({type: "set", gIsEOMExecuted: ((output_string.status_eom||"TIDAK")==="YA")});
				setSessionaktif(false);
				showToast("<b>BERHASIL</b> !"+
					"<br/>Tutup Periode Telah Selesai."+
					"<br/><br/><b>Silahkan Login Kembali Untuk Proses Selanjutnya</b>.."+
					"","SUKSES");
		    	clearStorage();
				setToken("");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Tutupperiode - apiProsestutup) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			const vTimeEnd 	= new Date();
			const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			//vElbutton && (vElbutton.disabled = false);
			//vElbuttoneod && (vElbuttoneod.disabled = false);
			setLoading(false);
			console.log("(Tutupperiode - apiProsestutup) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");

			if(vTimeDiff > 30000) 
				{ clearStorage(); setToken(""); }
		});
	}
	const apiProsesEOD = () => {
		const vElbuttoneod 	= document.getElementById("btnProsesEOD");
		const vElbutton 	= document.getElementById("btnProses");
		vElbutton && (vElbutton.disabled = true);
		vElbuttoneod && (vElbuttoneod.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			vElbutton && (vElbutton.disabled = false);

			showToast("<b>BERHASIL</b> !"+
				"<br/>Proses EOD Telah Selesai."+
				"<br/><br/><b>Silahkan Lanjutkan Aktifitas Selanjutnya</b>.."+
				"","SUKSES");
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parsdata : JSON.stringify(uDatamainObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_tutupperiode/pr_eod";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			vElbutton && (vElbutton.disabled = false);
			vElbuttoneod && (vElbuttoneod.disabled = false);
			setLoading(false);
			//console.log("(Tutupperiode - apiProsesEOD) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDispatch({type: "set", gSystemsetting:JSON.parse(output_string.systemsetting||JSON.stringify(uSettingsystemObj))});
				showToast("<b>BERHASIL</b> !"+
					"<br/>Proses EOD Telah Selesai."+
					"<br/><br/>Mohon Logout dan Login Kembali, dan <b>Silahkan Lanjutkan Aktifitas Selanjutnya</b>.."+
					"","SUKSES");
		    	clearStorage();
				setToken("");
			} else {
				setSessionaktif(true);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Tutupperiode - apiProsesEOD) output_string.errors : "+output_string.errors);
					const vMsg 	= (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			const vTimeEnd 	= new Date();
			const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			//vElbutton && (vElbutton.disabled = false);
			//vElbuttoneod && (vElbuttoneod.disabled = false);
			setLoading(false);
			console.log("(Tutupperiode - apiProsesEOD) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");

			if(vTimeDiff > 30000) 
				{ clearStorage(); setToken(""); }
		});
	}
	const apiProsesbatalstatus = () => {
		const vElbuttoneod 	= document.getElementById("btnProsesEOD");
		const vElbuttoneom 	= document.getElementById("btnProses");
		const vElbutton 	= document.getElementById("btnBatalflageom");
		vElbutton && (vElbutton.disabled = true);
		vElbuttoneom && (vElbuttoneom.disabled = true);
		vElbuttoneod && (vElbuttoneod.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			vElbuttoneod && (vElbuttoneod.disabled = false);
			vElbuttoneom && (vElbuttoneom.disabled = false);
			vElbutton && (vElbutton.disabled = false);

			uDatamainObj.eom_is_running	= "TIDAK";
			showToast("<b>BERHASIL</b> !"+
				"<br/>Proses Pembatalan Status Telah Selesai."+
				"<br/><br/><b>Silahkan Lanjutkan Aktifitas Selanjutnya</b>.."+
				"","SUKSES");
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_tutupperiode/pr_batalstatus";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			//console.log("(Tutupperiode - apiProsesEOD) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				vElbuttoneom && (vElbuttoneom.disabled = false);
				vElbuttoneod && (vElbuttoneod.disabled = false);
				uDatamainObj.eom_is_running	= "TIDAK";
				showToast("<b>BERHASIL</b> !"+
					"<br/>Proses Pembatalan Status Telah Selesai."+
					"<br/><br/><b>Silahkan Lanjutkan Aktifitas Selanjutnya</b>.."+
					"","SUKSES");
			} else {
				setSessionaktif(true);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Tutupperiode - apiProsesbatalstatus) output_string.errors : "+output_string.errors);
					const vMsg 	= (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			//vElbuttoneom && (vElbuttoneom.disabled = false);
			//vElbuttoneod && (vElbuttoneod.disabled = false);
			setLoading(false);

			console.log("(Tutupperiode - apiProsesbatalstatus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoaddata();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainObj({});
		}
	},[])
	/*React.useEffect(()=>{
		uElCardlast && (uElCardlast.style.height = uElCardnextHeight+"px");
	},[uElCardnextHeight,uElCardlastHeight])//-*/
	//--PAGINATION_DIATUR_BACKEND--/
	//--END PAGINATION_DIATUR_BACKEND--*/
	/*//--PAGINATION_DIATUR_FRONTEND--/
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		setJmlData(uDatamainObj.length);
	},[uDatamainObj,uDatamainObj.length])
	//--END PAGINATION_DIATUR_FRONTEND--*/
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderactionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderactionObj});

		apiLoaddata();
	},[uHandelView]);
	
	console.log("Tutupperiode - uIsEOMExecuted => "+(uIsEOMExecuted));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )//>

	/*if(uTokenObj.userhak !== "FREEROLE") return (
		<CCard className="classcardbg mb-3">
			<CCardHeader className="">Forbidden</CCardHeader>
			<CCardBody className="px-md-5">
			<div className="mx-lg-5">
			<p className="fw-bolder">Maaf,</p>
			<p>Fitur ini hanya AVAILABLE bagi user {uBahasaObj.hakuser_freerole}.
				<br/>Agar Aplikasi/Sistem Ini dapat berjalan Kembali, Mohon Hubungi {uBahasaObj.hakuser_freerole} Anda, untuk melakukan Proses Tutup Buku..</p>
			<p className="fst-italic">Terimakasih,</p>
			</div>
			</CCardBody>
		</CCard>
	)//>//-*/

	return (
	<CRow className="align-items-stretch mb-3">
		<CCol md="6" className="">
		<CCard className="classcardbg h-100">
			<CCardHeader className="">Tutup Periode Sebelumnya</CCardHeader>
			<CCardBody className="">{contentData()}</CCardBody>
		</CCard>
		</CCol>

		<CCol className="" style={{display:"flex",flexDirection:"column"}}>
		<CCard className="classcardbg mb-3 mt-3 mt-md-0">
			<CCardHeader className="">End Of Days</CCardHeader>
			<CCardBody className="">{contentFormeod()}</CCardBody>
		</CCard>

		<CCard className="classcardbg" style={{flex: "1 0 0"}}>
			<CCardHeader className="fw-bolder">Proses EOM / EOY</CCardHeader>
			<CCardBody className="">{contentFormtutup()}</CCardBody>
		</CCard>
		</CCol>
	</CRow>
	)//>
}	

export default Tutupperiode