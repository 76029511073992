import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
	CSidebar,
	CSidebarBrand,
	CSidebarNav,
	CSidebarToggler,
	CImage,
	CCollapse,CLink
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import { ContSidebarNav } from './ContSidebarNav'

const pjson 		= require('../../package.json');
const ContSidebar 	= () => {
	const uDispatch 	= useDispatch()
	const uUnfoldable 	= useSelector((state) => state.sidebarUnfoldable)// || true;
	const uSidebarshow	= useSelector((state) => state.sidebarShow);
	const uListnav 		= useSelector((state) => state.listNav)
	const uMasterdatashow= useSelector((state) => state.masterdataShow) || false;

	//const uCheckfolding = isMobile ? false : uUnfoldable;
	//console.log("(ContSidebar) uListnav => "+JSON.stringify(uListnav));
	//console.log("(ContSidebar) navigation => "+JSON.stringify(navigation));
	//console.log("(ContSidebar) uSidebarshow => "+(uSidebarshow));
	return (
		<>
		<CSidebar position="fixed" 
			unfoldable={uUnfoldable}
			visible={uSidebarshow}
			onVisibleChange={(visible) => {
				uDispatch({ type: 'set', sidebarShow: visible })
			}}
			className=""
			id="idsidebarmain">
			<CSidebarBrand className="d-none d-md-flex">
				<CIcon className="classikonlogowhite sidebar-brand-narrow" height={45}/>
				<CIcon className="classikonlogo sidebar-brand-full me-1" height={40}/>
				<div className="d-flex align-items-end">
				<div className="classtitle classtitlesidebar sidebar-brand-full fs-5">{pjson.name}</div>
				</div>
			</CSidebarBrand>

			<CSidebarNav>
				<ContSidebarNav items={uListnav} />
			</CSidebarNav>

			<CSidebarToggler className="d-none d-lg-flex"
				onClick={() => uDispatch({ type: 'set', sidebarUnfoldable: !uUnfoldable })}/>
		</CSidebar>
		</>
	)
}

export default React.memo(ContSidebar)