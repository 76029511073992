import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CCardHeader,
	CCard,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CFormTextarea,
	CSelect,
	CBadge,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { isMobile } from "react-device-detect";
import { 
	MyPage500,
	MyPagination,
	MyDialogform,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';

const pjson     = require('../../package.json');
const Customer 	= (props) => {
	const uComponentname 	= Customer.name;
	const { 
		setLoading, showToast, prosesExpired, setSessionaktif
	} = props; 

	let uFuncname;//-->hanya untuk API
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uHTMLstyleTabel=useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj		= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsScrollBottom= useSelector(state => state.gIsScrollBottom);
	const uEfekapp		= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 	= useSelector(state => state.gListUserSetting);
	const uMaxData		= isMobile ? 50 : 100;
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uIsAllowedhapus	= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	const [uDatamainArr,setDatamainArr]				= React.useState([]);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpCustomernama,setInpCustomernama] 	= React.useState("");
	const [uInpTelphp,setInpTelphp]			= React.useState("");
	const [uInpAlamat,setInpAlamat]			= React.useState("");
	const [uInpKeyword,setInpKeyword]		= React.useState("");

	const [ElBtnSimpan,setElBtnSimpan]		= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata	= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//console.log("("+uComponentname+") uHeaderActionObj.isHeaderView: "+uHeaderActionObj.isHeaderView);
	//console.log("("+uComponentname+") uHandelView: "+uHandelView);

	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	//---HANDLE--/
	const hdlToggleForm = () => { setShowForm(false); }
	const hdlLastFieldKDown = (_EV)=>{
		if(_EV.which === 13) {
			if (_EV.ctrlKey && _EV.target.nodeName === "TEXTAREA") {
				_EV.preventDefault();
				_EV.stopPropagation();
				var content = _EV.target.value;
				var start   = _EV.target.selectionStart;
				var substr1 = content.slice(0, start);
				var substr2 = content.slice(_EV.target.selectionEnd);
				//alert(_EV.target.selectionEnd);
				_EV.target.value  = substr1 + '\n' + substr2;
				return;
			}

			_EV.preventDefault();
			ElBtnSimpan.focus();
		}
	}
	const hdlKlikSimpan = () => {
		//--VALIDASI_SIMPAN--/
		let vElfocus = document.getElementById("inpcustomernama");
		if(uInpCustomernama.trim().length < 3) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_namacustomer||"caption_namacustomer")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+".."); 
			return;
		}
		vElfocus = document.getElementById("inpkeyword");
		if(uInpKeyword.trim() !== "")
			if(uInpKeyword.trim().length < 3) {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.word_keyword||"Keyword")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+".."); 
				return;
			}
		vElfocus = document.getElementById("inptelphp");
		if(uInpTelphp.trim() !== "")
			if(uInpTelphp.trim().length < 3) {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.caption_telphp||"Telp/HP")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+".."); 
				return;
			}
		vElfocus = document.getElementById("inpalamat");
		if(uInpAlamat.trim() !== "")
			if(uInpAlamat.trim().length < 3) {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.word_alamat||"Alamat")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+".."); 
				return;
			}
		//--END VALIDASI_SIMPAN--/

		apiProsessimpan();
	}
	const hdlKlikEdit = async(_IDTABEL) => {
		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setInpCustomernama(uDatamainArr[vObjdx].nama);
		setInpKeyword(uDatamainArr[vObjdx].keyword||"");
		setInpTelphp(uDatamainArr[vObjdx].telp_hp||"");
		setInpAlamat(UFunc.br2nl(uDatamainArr[vObjdx].alamat||""));
		setDialogHeader((uBahasaObj.caption_editdata||"caption_editdata")+": "+(uDatamainArr[vObjdx].nama || "").toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>"+uDatamainArr[vObjdx].nama.toUpperCase()+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiProseshapus(_IDTABEL);
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initFormAwal = () => {
		setTabelIDPilih(0);

		setInpCustomernama("");
		setInpTelphp("");
		setInpAlamat("");
		setInpKeyword("");
	}
	//---END Init_LOAD--/

	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_customer || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataViewObj	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="4" className="text-start">{uBahasaObj.caption_namacustomer||"caption_namacustomer"}</CCol>
				<CCol md="4" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="3" className="text-center">{uBahasaObj.caption_telphp||"caption_telphp"}</CCol>
				<CCol md="1">&middot;</CCol>
				</CRow>
			</th>
			</tr>
			<tr className="d-md-none classfontsmaller">
			<th width={27} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataViewObj.map((vItems,vKeys)=>{
			const {
				id, telp_hp, kode, nama, alamat, keyword
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (nama||"").toUpperCase();

			const vCustomernama	= vKeyword==="" ? (kode+" &middot; "+nama) 
				: (kode+" &middot; "+nama).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vTelphp= vKeyword==="" ? (telp_hp||"-") 
				: (telp_hp||"-").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vAlamat= vKeyword==="" ? (alamat||"") 
				: (alamat||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vKeywordcaption = vKeyword==="" ? (keyword||"") 
				: (keyword||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
				
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
			<td align="right" className="px-1 pe-0">{vNumber}.</td>
			<td className="px-1">
			<CRow className="mx-0">
				<CCol md="4" className="px-1 d-flex justify-content-between">
					<div className="text-info">{UFunc.renderHTML(vCustomernama)}</div>

					<CDropdown autoclose={true} className="d-md-none">
					<CDropdownToggle color="transparent" className="p-0 m-0" 
						caret={false}>
						<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem 
							onClick={()=>hdlKlikEdit(id)}>
							<CIcon className="classikonedit me-2 align-middle" height={25}/>
							<span>{uBahasaObj.caption_editdata}</span>
						</CDropdownItem>
						
						{uIsAllowedhapus && (
						<CDropdownItem 
							onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							<span>{uBahasaObj.caption_hapusdata}</span>
						</CDropdownItem>
						)}
					</CDropdownMenu>
					</CDropdown>
				</CCol>

				<CCol xs="12" className="my-2 d-md-none classborderbottom"/>

				<CCol md="4" className="px-1 text-md-start d-none d-md-block">
				{((keyword||"")==="" && (alamat||"")==="") ? ("-") : (
				<>
					{(keyword||"")!=="" && (
					<div>&middot; {UFunc.renderHTML(vKeywordcaption)} &middot;</div>
					)}
					<small className="fst-italic">{UFunc.renderHTML(vAlamat)}</small>
				</>
				)}
				</CCol>

				<CCol md="3" className="px-1 text-md-center d-none d-md-block">
					<b className="">{UFunc.renderHTML(vTelphp.toUpperCase())}</b>
				</CCol>

				<CCol md="1" className="px-0 text-md-center d-none d-md-block">
					<CTooltip content={"--"+(uBahasaObj.caption_editdata || "caption_editdata")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer"/>
					</CTooltip>
					{uIsAllowedhapus && (
					<>
						<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
						<CLink 
							className={"classcursorpointer classikontabel classikonhapus"}
							onClick={()=>hdlKlikHapus(id)} />
						</CTooltip>
					</>
					)}
				</CCol>

				{(keyword||"")!=="" && (
				<>
				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_keyword}</CCol>
				<CCol xs="8" className="px-1 d-md-none">{UFunc.renderHTML(vKeywordcaption)}</CCol>
				</>
				)}

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_alamat}</CCol>
				<CCol xs="8" className="px-1 d-md-none fst-italic">
					<small>{(alamat||"")!=="" ? UFunc.renderHTML(vAlamat) : "-"}</small>
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_telphp}</CCol>
				<CCol xs="8" className="px-1 d-md-none fw-bolder">
					{(telp_hp||"")!=="" ? UFunc.renderHTML(vTelphp) : "-"}
				</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgform = () => {
		if(!isShowForm) return (<></>)//>

		return (
			<>
			<CForm className="mx-auto mx-lg-5">
			<CRow className="my-2">
				<CCol md="4" lg="3" className="text-md-end fw-bolder">
					{uBahasaObj.caption_namacustomer||"caption_namacustomer"}
				</CCol>
				<CCol md="8">
					<CFormInput maxLength={100}
						type="text"
						size="sm"
						className="form-control text-capitalize"
						value={uInpCustomernama}
						onChange={(e) => setInpCustomernama(e.target.value)}
						id="inpcustomernama"/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol md="4" lg="3" className="text-md-end">
					{uBahasaObj.word_keyword||"keyword"}
				</CCol>
				<CCol md="8">
				<CFormInput maxLength={100}
					size="sm"
					className="text-capitalize"
					value={uInpKeyword}
					onChange={(e) => setInpKeyword(e.target.value)}
					id="inpkeyword"/>
				</CCol>
			</CRow>

			<CRow className="my-3">
				<CCol md="4" lg="3" className="text-md-end"/>
				<CCol md="8" className="classborderbottom"/>
			</CRow>

			<CRow className="my-2">
				<CCol md="4" lg="3" className="text-md-end">
					{uBahasaObj.caption_telphp||"caption_telphp"}
				</CCol>
				<CCol md="8">
				<CFormInput maxLength={50}
					size="sm"
					className="form-control text-uppercase"
					value={uInpTelphp}
					onChange={(e) => setInpTelphp(e.target.value)}
					id="inptelphp" />
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol md="4" lg="3" className="text-md-end">
					{uBahasaObj.word_alamat||"word_alamat"}
				</CCol>
				<CCol md="8">
					<CFormTextarea
						size="sm"
						className="form-control"
						value={uInpAlamat}
						onChange={(e) => setInpAlamat(e.target.value)}
						onKeyDown={(e) => hdlLastFieldKDown(e)}
						id="inpalamat" />
					<div className="classpetunjuk">*) {uBahasaObj.petunjuk_textarea||"petunjuk_textarea"}</div>
				</CCol>
			</CRow>
			</CForm>
			</>
		)//>
	}

	//---API_FUNC--/
	const apiLoaddata = () => {
		uFuncname = apiLoaddata.name;
		setHTMLError500();

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vKeyword = uKeywordObj.kwd_customer || "";
		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_customer/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setDatamainArr(JSON.parse(output_string.dataobject||"[]"));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		/*//--TESTING_FRONTEND--//
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_customernama : uInpCustomernama,
			send_keyword : uInpKeyword,
			send_telphp : uInpTelphp,
			send_alamat : uInpAlamat,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_customer/pr_simpan";

		setLoading(true);
		ElBtnSimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			ElBtnSimpan.disabled = false;

			if(output_string.sukses) {
				let vDatamainArrNew;
				if(uTabelIDPilih === 0) {
					const vTmpObject	= {
						id 		: output_string.tabelid || "255",
						kode 	: UFunc.leadZero((output_string.tabelid || "255"),5),
						keyword : (uInpKeyword||"").trim(),
						telp_hp : (uInpTelphp||"").trim(),
						nama 	: UFunc.capitalize((uInpCustomernama||"").trim()),
						alamat 	: UFunc.nl2br(UFunc.capitalize((uInpAlamat||"").trim())),
					}
					vDatamainArrNew = [vTmpObject].concat(uDatamainArr);
				} else {
					vDatamainArrNew = uDatamainArr;
					const vObjdx				= UFunc.getObjectIndeks(vDatamainArrNew,uTabelIDPilih);
					vDatamainArrNew[vObjdx].nama 	= UFunc.capitalize(uInpCustomernama.trim());
					vDatamainArrNew[vObjdx].keyword = UFunc.capitalize(uInpKeyword.trim());
					vDatamainArrNew[vObjdx].telp_hp = (uInpTelphp||"").trim();
					vDatamainArrNew[vObjdx].alamat 	= UFunc.nl2br(UFunc.capitalize((uInpAlamat||"").trim()));
				}

				setDatamainArr(vDatamainArrNew);
				initFormAwal();

				if(uTabelIDPilih > 0) {
					setEfekview(!uEfekview);
					hdlToggleForm();
				}  else {
					setTabelRowSelect(-1);
					setJmlData((parseInt(vDatamainArrNew.length)||0));
					showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
					setPageActive(1);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				ElBtnSimpan.disabled = true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			ElBtnSimpan.disabled = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		uFuncname 	= apiProseshapus.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_customer/pr_hapus";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoaddata();

		return () => { 
			uDispatch({type: "set", gInitHeaderAction: {}});
			setShowForm(false);
			setDatamainArr([]);
		}
	},[]);
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
	},[uDatamainArr,uDatamainArr.length]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal); setPageActive(uPageActive<=0?1:uPageActive);

		const vKeyword = uKeywordObj.kwd_customer || "";
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		}
		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;
		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;
		if(isShowForm) return;
		setDialogHeader((uBahasaObj.word_tambah||"word_tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setShowForm(true);
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return; }

		//console.log("("+uComponentname+"[isShowForm]) isShowForm 1: "+isShowForm);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnSimpan(document.getElementById("btnDialogSimpan"));

			const vElFocus = document.getElementById("inpcustomernama");
			vElFocus && vElFocus.focus();
		},350);
	},[isShowForm]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return (<></>); }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>);

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_customer || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			<small className="text-muted classfontsmaller">{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}
				></MyPagination>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikSimpan()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={contentDlgform()}/>
		</>
	)//>
}
	
export default Customer;
