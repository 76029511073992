import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CButton,
	CTooltip,
	CLink,
	CImage,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { UFunc } from '../../helpers/functions'
import { Konfirm } from '../../helpers/onConfirm';
import { 
	MyDatepicker,
} from '../../components/index';

const pjson 			= require('../../../package.json')
const Headersubreminder = (props) => {
	const {
		//setToken,setLoading,
		showToast 
	} = props//-*/ 

	//--DOM--/
	//const uNavigate 		= useNavigate();
	const uDispatch 		= useDispatch();
	//--END DOM--/

	const uActiveroute		= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview]= React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uForminputObj,setForminputObj]	= React.useState({});

	//--HANDEL--/
	const hdlKlikCari=()=>{
		//--CEK_VARS--/
		if(uForminputObj.jenis==="KEYWORD") {
			if((uForminputObj.keyword||"").trim()==="") {
				showToast("Keyword Pencarian Harus Diisi !"); return;
			}
		}
		//--END CEK_VARS--/

		//---SAVE_KEYWORD--/
		uKeywordObj.filter_reminder 	= uForminputObj; 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikTambah=()=>{
		//uDispatch({type: "set", isHeaderTambah: true});
		uHeaderActionObj.isHeaderTambah = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset=async()=>{
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.filter_reminder = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setForminputObj({});
			//---END CLEAR_KEYWORD--/
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//console.log("(Headersubreminder - hdlKlikReset) uInpTglreminder => "+(uInpTglreminder));
		if((uForminputObj.jenis||"DAYNOW") !== "DAYNOW") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}

	React.useEffect(()=>{
		return () => { setForminputObj({}) }
	},[])
	React.useEffect(()=>{
		//console.log("(Headersubreminder - [uForminputObj]) uForminputObj.tgl_reminder => "+(uForminputObj.tgl_reminder));
		if(uForminputObj.jenis === undefined) {
			uForminputObj.jenis = "DAYNOW"
		} else {

		}
		if(uForminputObj.tgl_reminder_dt === undefined) {
			uForminputObj.tgl_reminder_dt = UFunc.toDate()
		}
	},[uForminputObj,
	uForminputObj.jenis,
	uForminputObj.tgl_reminder_dt
	])
	React.useEffect(()=>{
		if(uForminputObj.jenis === undefined) return;

		if(uForminputObj.jenis === "DAYNOW") {
			uForminputObj.tgl_reminder = UFunc.DbDate()
		} 
		if(uForminputObj.jenis !== "KEYWORD") {
			uForminputObj.keyword = "";
		}
	},[uForminputObj,uForminputObj.jenis])
	React.useEffect(()=>{
		if(uForminputObj.tgl_reminder_dt === undefined) return;
		uForminputObj.tgl_reminder = UFunc.DbDate(uForminputObj.tgl_reminder_dt);
	},[uForminputObj,uForminputObj.tgl_reminder_dt])

	//console.log("Headersubreminder - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"}
				className="me-1"
				height={30} />
			<small><i>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||"List Data"}</i></small>
		</div>

		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm className="d-none d-md-block">
				<CInputGroup 
					size="sm">
				<CFormSelect size="sm"
					className="w-auto"
					value={uForminputObj.jenis}
					onChange={(e) =>{ uForminputObj.jenis = e.target.value;setEfekview(!uEfekview); }}
					id="inpjenisreminder">
					<option value="DAYNOW">Hari Ini (By Tanggal)</option>
					<option value="WEEKNOW">Minggu Ini</option>
					<option value="MONTHNOW">Bulan Ini</option>
					<option value="KEYWORD">By Keyword</option>
				</CFormSelect>

				{uForminputObj.jenis==="DAYNOW" && (
				<>
				<CInputGroupText className="p-0 px-1">
				<MyDatepicker
					tglValue={uForminputObj.tgl_reminder_dt}
					onChangevalue={(date)=>{uForminputObj.tgl_reminder_dt=date;setEfekview(!uEfekview); }}
					id="inptglreminder"/>
				</CInputGroupText>
				</>
				)}

				{uForminputObj.jenis==="KEYWORD" && (
				<CFormInput size="sm"
					placeholder="--Keyword Pencarian.."
					value={uForminputObj.keyword}
					onChange={(e) =>{ uForminputObj.keyword = e.target.value;setEfekview(!uEfekview); }}
					onKeyDown={(e)=>{
						if(e.which===13) {
							e.preventDefault();e.stopPropagation();
							hdlKlikCari();
						}
					}}
					style={{minWidth:275,width:"auto"}}
					id="keyword"/>
				)}
				<CButton size="sm" color="light"
					className="border-secondary" 
					onClick={()=>hdlKlikCari()} 
					id="btnCarireminder">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
			</CForm>
			<span className="d-none d-md-block">
			<span className="mx-1 d-none d-lg-inline mx-1">&middot;</span>
			<span className="d-none d-md-inline d-lg-none me-1">&nbsp;</span>
		
			<CTooltip content={uBahasaObj.caption_tambahdata || "caption_tambahdata"}>
				<CLink className="classikon classikontambah classcursorpointer" onClick={()=>hdlKlikTambah()}/>
			</CTooltip>

			<span className="d-none d-lg-inline me-1">&middot;</span>

			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
			</CTooltip>

			<span className="d-none d-md-inline me-2">&middot;</span>
			<b id="idjmldata">0</b>{" "}{uBahasaObj.word_data||"word_data"}
			</span>

			<CForm className="d-md-none">
				<CInputGroup 
					style={{minWidth:295,width:"auto"}}
					size="sm">
				<CDropdown className="d-md-none mx-0">
					<CDropdownToggle caret={false} size="sm" className="p-0"
						color="transparent">
						<CIcon icon="cilEqualizer" className="classikoncoreui ms-0 me-2" height={30}/>
					</CDropdownToggle>
					<CDropdownMenu>
					<CDropdownItem onClick={()=>{uForminputObj.jenis = "DAYNOW";setEfekview(!uEfekview);}}>
						Hari Ini (By Tanggal)
					</CDropdownItem>
					<CDropdownItem onClick={()=>{uForminputObj.jenis = "WEEKNOW";setEfekview(!uEfekview);}}>
						Minggu Ini
					</CDropdownItem>
					<CDropdownItem onClick={()=>{uForminputObj.jenis = "MONTHNOW";setEfekview(!uEfekview);}}>
						Bulan Ini
					</CDropdownItem>
					<CDropdownItem onClick={()=>{uForminputObj.jenis = "KEYWORD";setEfekview(!uEfekview);}}>
						By Keyword
					</CDropdownItem>
					</CDropdownMenu>
				</CDropdown>

				{uForminputObj.jenis==="DAYNOW" && (
				<CInputGroupText style={{minWidth:225}} className="py-0">
				<MyDatepicker
					tglValue={uForminputObj.tgl_reminder_dt}
					onChangevalue={(date)=>{uForminputObj.tgl_reminder_dt=date;setEfekview(!uEfekview); }}
					id="inptglreminder"/>
				</CInputGroupText>
				)}

				{uForminputObj.jenis==="WEEKNOW" && (
				<CFormInput size="sm" readOnly={true} value="MINGGU INI"
					id="inpmingguinilabel"/>
				)}

				{uForminputObj.jenis==="MONTHNOW" && (
				<CFormInput size="sm" readOnly={true} value="BULAN INI"
					id="inpbulaninilabel"/>
				)}

				{uForminputObj.jenis==="KEYWORD" && (
				<CFormInput size="sm"
					placeholder="--Keyword Pencarian.."
					value={uForminputObj.keyword}
					onChange={(e) =>{ uForminputObj.keyword = e.target.value;setEfekview(!uEfekview); }}
					onKeyDown={(e)=>{
						if(e.which===13) {
							e.preventDefault();e.stopPropagation();
							hdlKlikCari();
						}
					}}
					id="keyword"/>
				)}
				<CButton size="sm" color="light" 
					onClick={()=>hdlKlikCari()}
					className="border-secondary" 
					id="btnCarireminder">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
			</CForm>

			<div className="d-md-none ms-1">
				<CLink className="classikon classikontambah classcursorpointer" onClick={()=>hdlKlikTambah()}/>
			</div> 
		</div>
		</>
	)
}

export default Headersubreminder
