import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CListGroup,
	CListGroupItem,
	CSpinner,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu, 
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPage500,
	MyPagination,
	MyDialogview,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     = require('../../package.json');
const Titipandp = (props) => {
	const uComponentname 	= Titipandp.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;
	const [uFirstload,setFirstload]			= React.useState("YA");

	const uIsInitbefore 	= ((JSON.stringify(uTabelSelectObj)||"{}")!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);

	//--GLOBAL_VARS--/
	const uIsAllowedhapus	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted;
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(0);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const uElJmldata						= document.getElementById("idjmldata");

	//---FORMDIALOG--/
	const [uIsShowview,setShowview]			= React.useState(false);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	//---END FORMDIALOG--/
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleView=()=>{ setShowview(false)}
	const hdlKlikDetil=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vHeadercaption = (uBahasaObj.caption_rinciandp||"caption_rinciandp")+": "+
			(uDatamainArr[vObjdx].caption_customer||"").toUpperCase();
		setDialogHeader(vHeadercaption);
		apiLoaddetil(_IDTABEL);
	}
	const hdlKlikRefund=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		uDispatch({type: "set", gInitTabelSelected: {}});
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vTmpObj	= {"initID":_IDTABEL,"initPage":uPageActive,}
		uDispatch({type: "set", gInitTabelSelected: vTmpObj});

		uDatamainArr[vObjdx].isloading = true;
		uNavigate("/kasir/titipandp/formrefund/"+_IDTABEL);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initJmldata=()=>{
		const vKeyword = uKeywordObj.kwd_titipandp || "";
		if (vKeyword.length >= 3) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_titipandp || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={35} className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="3" lg="4" className="text-start">{uBahasaObj.word_customer||"word_customer"}</CCol>
				<CCol md="5" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_saldodp||"caption_saldodp"} <small>(Rp)</small></CCol>
				<CCol md="2" lg="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none">
			<th width={35} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,caption_customer,saldo_dp,alamat,telp,isloading
			} = vItems;

			vNumber++;
			const vCaptionUpper	= ((caption_customer||"")).toUpperCase();

			const vNamaCustomer	= vKeyword==="" ? UFunc.capitalize(caption_customer||"") 
				: (UFunc.capitalize(caption_customer||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vAlamat = vKeyword==="" ? (alamat||"") 
				: (alamat||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTelp = vKeyword==="" ? (telp||"") 
				: (telp||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
				
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{(vNumber)}.</td>
				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="3" lg="4" className="px-1 text-start d-flex justify-content-between">
					<div className="text-info">{UFunc.renderHTML(vNamaCustomer)}</div>

					<div className="d-md-none">
					{(isloading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
						<CDropdown>
						<CDropdownToggle className="p-0" caret={false} color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
							{uIsEOMExecuted && (
							<CDropdownItem onClick={()=>hdlKlikRefund(id)}>
								<CIcon className="classikonbayar align-middle mx-0 me-2" height={25}/>
								{uBahasaObj.caption_refunddp||"caption_refunddp"}
							</CDropdownItem>
							)}

							<CDropdownItem onClick={()=>hdlKlikDetil(id)}>
								<CIcon className="classikondetil align-middle mx-0 me-2" height={25}/>
								{uBahasaObj.caption_rinciandp||"caption_rinciandp"}
							</CDropdownItem>
						</CDropdownMenu>
						</CDropdown>
					)}
					</div>
				</CCol>

				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.word_alamat||"word_alamat"}</CCol>
				<CCol xs="9" md="5" className="px-1 text-md-start">
					<small className="fst-italic d-block text-capitalize">{UFunc.renderHTML(vAlamat)}</small>
					{(telp||"")!=="" && (
						<div className="d-none d-md-block classfontsmaller">
							<small>{uBahasaObj.caption_telphp||"caption_telphp"}:
							<b className="ms-1">{UFunc.renderHTML(vTelp)}</b>
							</small>
						</div>
					)}
				</CCol>

				{(telp||"")!=="" && (
				<>
				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_telphp||"caption_telphp"}</CCol>
				<CCol xs="7" md="2" className="px-1 d-md-none classfontsmaller fw-bolder">
					{UFunc.renderHTML(vTelp)}
				</CCol>
				</>
				)}
				
				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_saldodp||"caption_saldodp"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-primary">
					<span className="d-md-none">Rp </span>{UFunc.formatAngka(saldo_dp)}
				</CCol>
				
				<CCol xs="12" md="2" lg="1" className="px-1 px-lg-0 text-center d-none d-md-block">
					{(isloading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
					{uIsEOMExecuted && (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_refunddp||"caption_refunddp")+": "+vCaptionUpper}>
						<CLink 
							className="classikontabel classikonbayar classcursorpointer classikoncoreui"
							onClick={()=>hdlKlikRefund(id)} />
					</CTooltip>

					<span className="d-md-none mx-1">&middot;</span>
					</>
					)}

					<CTooltip content={"--"+(uBahasaObj.caption_rinciandp||"caption_rinciandp")+" "+
						": "+vCaptionUpper}>
						<CLink 
							className="classikontabel classikondetil classcursorpointer classikoncoreui"
							onClick={()=>hdlKlikDetil(id)} />
					</CTooltip>
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if (!uIsShowview) return (<></>);//>

		const vTabelIDPilih	= parseInt(uTabelIDPilih) || 0; if(vTabelIDPilih <= 0) return "";
		
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,vTabelIDPilih);
		const vDetilArr	= uDatamainArr[vObjdx].rincianarr || [];
		if(vDetilArr.length <= 0) return "";

		let vSubtotal = 0;
		return (
		<>
		<CListGroup className="mx-0 mx-lg-5">
		{vDetilArr.map((vItems,vKeys)=>{
			const {
				id,tgl_dp,keterangan,nilai_hutang,nilai_refund
			} = vItems;

			vSubtotal	= vSubtotal + ((parseInt(nilai_hutang)||0) - (parseInt(nilai_refund)||0));

			const vBgcolor 	= vKeys%2===0 ? "secondary" : "white";
			const vAccent 	= vKeys%2===0 ? "success" : "warning";
			return (
			<CListGroupItem color={vBgcolor}
				 className={"p-1 py-2 border-start-4 border-start-"+vAccent}
				 key={vKeys}>
				<CRow className="mx-0">
				<CCol xs="1" className="px-1 text-end">{(vKeys+1)}.</CCol>
				<CCol xs="4" className="px-1 d-md-none">{uBahasaObj.caption_tgldp||"caption_tgldp"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-end text-md-center">
					{UFunc.TglAngka(tgl_dp)}
					{vKeys <= 0 && (
					<div className="d-none d-md-block"><small className="classpetunjuk">{uBahasaObj.caption_tgldp||"caption_tgldp"}</small></div>
					)}
				</CCol>
				<CCol xs="1" className="px-1 d-md-none"/>
				<CCol xs="4" className="px-1 d-md-none"><small>{uBahasaObj.word_keterangan||"word_keterangan"}</small></CCol>
				<CCol xs="7" md="3" className="px-1 text-end text-md-start">
					{((keterangan||"")!=="") && (
					<small className="text-info">{UFunc.capitalize(keterangan||"")}</small>
					)}
				</CCol>
				<CCol xs="12" className="px-1 d-md-none"/>
				<CCol xs="1" className="px-1 d-md-none"/>
				<CCol xs="4" className="px-1 d-md-none">{uBahasaObj.caption_nilaidp||"caption_nilaidp"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-end">
					{UFunc.formatAngka(nilai_hutang)}
					{vKeys <= 0 && (
					<div className="d-none d-md-block"><small className="classpetunjuk">{uBahasaObj.caption_nilaidp||"caption_nilaidp"}</small></div>
					)}
				</CCol>
				<CCol xs="12" className="px-1 d-md-none"/>
				<CCol xs="1" className="px-1 d-md-none"/>
				<CCol xs="4" className="px-1 text-primary d-md-none">{uBahasaObj.caption_jumlahrefund||"caption_jumlahrefund"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-end text-primary">
					{UFunc.formatAngka(nilai_refund)}
					{vKeys <= 0 && (
					<div className="d-none d-md-block"><small className="classpetunjuk">{uBahasaObj.caption_jumlahrefund||"caption_jumlahrefund"}</small></div>
					)}
				</CCol>
				<CCol xs="12" className="px-1 d-md-none"/>
				<CCol xs="1" className="px-1 d-md-none"/>
				<CCol xs="4" className="px-1 text-success d-md-none">{uBahasaObj.caption_sisadp||"caption_sisadp"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-end text-success">
					{UFunc.formatAngka(nilai_hutang - nilai_refund)}
					{vKeys <= 0 && (
					<div className="d-none d-md-block"><small className="classpetunjuk">{uBahasaObj.caption_sisadp||"caption_sisadp"}</small></div>
					)}
				
				</CCol>
				</CRow>
			</CListGroupItem>
			)
		})}
		</CListGroup>
		
		<CRow className="mx-0 mx-lg-5 mt-3 justify-content-end">
			<CCol xs="6" md="8" lg="10" className="px-1 text-end">
				<big>{uBahasaObj.word_total||"word_total"}</big>
			</CCol>
			<CCol xs="6" md="4" lg="2" className="px-1 text-end text-primary fw-bolder">
				<big>Rp{UFunc.formatAngka(vSubtotal)}</big>
			</CCol>
		</CRow>
		</>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname = apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_titipandp || "";
		const vHandelview	= (uHandelView===true || uFirstload==="YA") ?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoaddata) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_titipandp/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				setFirstload("TIDAK");

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddetil = (_IDTABEL) => {
		uFuncname = apiLoaddetil.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vDetilArr	= uDatamainArr[vObjdx].rincianarr || [];

		if(vDetilArr.length > 0) {
			setShowview(true); return;
		}

		uDatamainArr[vObjdx].isloading = true;

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoaddetil) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_customerid : _IDTABEL.toString(),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_titipandp/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uDatamainArr[vObjdx].isloading = false;
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.detilobject || "[]");
				uDatamainArr[vObjdx].rincianarr = vTmpObj;
				setShowview(true);
			} else if(output_string.info) {
				showToast(output_string.info);
				setShowview(false);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
				setShowview(false);
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjdx].isloading = false;
			setShowview(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{//--JIKA_PAGINATION_DIATUR_BACKEND--/
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	React.useEffect(()=>{
		hdlToggleView();
		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }
    	
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) { apiLoaddata(); }

		return()=>{
			setDatamainArr([]); 
		}
	},[]);
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= uTabelSelectObj.initID;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+vInitID);
		setTabelRowSelect(vRowIdx);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		uNavigate("/kasir/titipandp/forminput");
	},[uHandelTambah]);
	React.useEffect(()=>{ uElJmldata && initJmldata(); },[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		//console.log("("+uComponentname+" - [uPageActive]) vDatapageArr => "+JSON.stringify(vDatapageArr));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uIsShowview) {
			setTabelIDPilih(0); return;
		}
	},[uIsShowview]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_cComponentName
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)
	
	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_titipandp || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>
			
			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowview} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleView}
			renderContent={contentDlgview()}/>
		</>
	)//>
}
	
export default Titipandp;
