import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { 
	CRow,
	CCol,
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CForm,CFormInput,CFormSelect,CFormTextarea,CInputGroup,CInputGroupText,
	CLink,
	CSpinner,
	CCloseButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyCollapsejadwal,
	MyDatepicker,
} from '../components/index';
import { isMobile } from "react-device-detect";
import { UFunc } from "../helpers/functions";

const pjson     		= require('../../package.json');
const MyFormbooking 	= (props) => {
	const {
		objDataparse,toggleSimpan,isSimpan,funcDlgclose,
		showToast,prosesExpired,setLoading,setSessionaktif,
		...rest
	} = props;

	const uDispatch 		= useDispatch();

	const uTokenObj						= JSON.parse(localStorage.getItem("token"));
	const uBahasaObj  					= useSelector(state => state.listBahasa);

	const uDataLayananArr				= JSON.parse(localStorage.getItem("layananlist")||"[]");
	const uDataparseObj					= objDataparse;
	const [uDatajumlahArr,setDatajumlahArr]= React.useState([]);
	const [uEfekform,setEfekform] 		= React.useState(false);
	const [uLoadingjml,setLoadingjml]	= React.useState(false);
	const [uISShowjadwal,setShowjadwal]	= React.useState(false);

	const uStatusantarArr				= [
		(uBahasaObj.status_antarsendiri||"Antar Sendiri"),
		(uBahasaObj.status_antarjemput||"Antar JEMPUT"),
		(uBahasaObj.status_antarhadir||"Sudah di Klinik"),
		(uBahasaObj.status_antarlainnya||"Lainnya"),
	]

	const apiLoadjumlah = () => {
		const vBtnDlgsimpan = document.getElementById("btnDialogSimpan")
		vBtnDlgsimpan && (vBtnDlgsimpan.disabled = true);
		setLoadingjml(true);

		/*//--TESTING_FRONTEND--/
		console.log("(MyFormbooking - apiProsesbooking) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoadingjml(false);
			
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
		},3500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tglcek: uDataparseObj.tgl_booking,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_daftar/ld_jumlah";

		setSessionaktif(false);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
			setLoadingjml(false);
			if(output_string.tampil) {
				setDatajumlahArr(JSON.parse(output_string.datajumlah || "[]"))
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyFormbooking - apiProsesbooking) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
			setLoadingjml(false);
			console.log("(MyFormbooking - apiProsesbooking) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesbooking = () => {
		const vBtnDlgsimpan = document.getElementById("btnDialogSimpan")
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(MyFormbooking - apiProsesbooking) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
			funcDlgclose();

			showToast((uBahasaObj.pesan_sukses||"Berhasil !"),"SUKSES")
		},3500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uDataparseObj.id_booking,
			send_pasienid : uDataparseObj.id_pasien,
			send_databooking: JSON.stringify(uDataparseObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_simpanbooking";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				funcDlgclose();
				showToast((uBahasaObj.pesan_sukses||"Berhasil !"),"SUKSES")
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyFormbooking - apiProsesbooking) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
			setLoading(false);
			console.log("(MyFormbooking - apiProsesbooking) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	React.useEffect(()=>{
		return () => {}
	},[]);
	React.useEffect(()=>{
		if(uDataparseObj.tgl_booking_dt === undefined) {
			uDataparseObj.tgl_booking_dt = new Date();
		} else {
			uDataparseObj.tgl_booking = UFunc.DbDate(uDataparseObj.tgl_booking_dt);
			setEfekform(!uEfekform);
		}
		if(uDataparseObj.layanan_id === undefined) {
			uDataparseObj.layanan_id = uDataLayananArr[0].id;
		}
		if(uDataparseObj.status_antar === undefined) {
			uDataparseObj.status_antar = uStatusantarArr[0];
		}
	},[uDataparseObj,
	uDataparseObj.tgl_booking_dt,
	uDataparseObj.layanan_id,
	uDataparseObj.status_antar,
	]);
	React.useEffect(()=>{
		if(uDataparseObj.tgl_booking === undefined) return;

		apiLoadjumlah();
	},[uDataparseObj.tgl_booking]);
	React.useEffect(()=>{
		if(uDataparseObj.layanan_id === undefined) return;
		const vArridx = UFunc.getObjectIndeks(uDataLayananArr,uDataparseObj.layanan_id);
		uDataparseObj.layanan_value = uDataLayananArr[vArridx].value_layanan;
	},[uDataparseObj.layanan_id]);
	React.useEffect(()=>{
		if(!isSimpan) return;
		toggleSimpan();
		const vBtnDlgsimpan = document.getElementById("btnDialogSimpan")
		vBtnDlgsimpan && (vBtnDlgsimpan.disabled = true);

		//--CEK_VALIDASI--/
		let vElfocus	= document.getElementById("btntglbooking");
		if((uDataparseObj.tgl_booking||"") === "") {
			vElfocus && vElfocus.focus();
			showToast(uBahasaObj.caption_tanggal+" "+uBahasaObj.caption_invalid+"..");
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
			return;
		} else {
			const vTanggalDt 	= UFunc.toDate(uDataparseObj.tgl_booking);

			const vDateMundur = UFunc.DateIsMundur(vTanggalDt);
			if(vDateMundur) {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.pesan_tglisexpired||"pesan_tglisexpired")+"..");
				vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
				return;
			}
			//alert("("+uComponentname+") "+cFuncname+" vDateExceededBl = "+vDateExceededBl); return;
		}
		vElfocus	= document.getElementById("inpbookinglayanan");
		if((parseInt(uDataparseObj.layanan_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_jenislayanan||"caption_jenislayanan")+" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
			return;
		}
		vElfocus	= document.getElementById("inpbookingstatus");
		if((uDataparseObj.status_antar||"") === "") {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_statusantar||"caption_statusantar")+" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
			return;
		} else {
			if((uDataparseObj.status_antar||"").toUpperCase() === "LAINNYA") 
				if((uDataparseObj.status_antarlainnya||"").trim() === "") {
					vElfocus	= document.getElementById("inpbookingstatuslainnya");
					showToast((uBahasaObj.caption_statusantar||"caption_statusantar")+" LAINNYA "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
					vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
					return;
				}
		}
		//--END CEK_VALIDASI--/

		apiProsesbooking();
	},[isSimpan]);

	return (
		<>
		<CForm style={{minHeight:window.innerHeight - (isMobile?185:250)}} className="">
		<CRow className="mx-0 my-2">
			<CCol xs="4" className="text-md-end">
				<span className="">{uBahasaObj.caption_tglbooking||"caption_tglbooking"} ccc</span>
			</CCol>
			<CCol xs="7" lg="4" className="">
				<MyDatepicker
					tglValue={uDataparseObj.tgl_booking_dt}
					onChangevalue={(date)=>{uDataparseObj.tgl_booking_dt = date; setEfekform(!uEfekform)}}
					id="inpbookingtgl"/>
			</CCol>
			<CCol xs="1" className="px-0">
				<CButton size="sm" color="dark"
					onClick={()=>setShowjadwal(!uISShowjadwal)}
					id="btncekbooking">
					<CIcon icon="cilAddressBook"/>
				</CButton>
			</CCol>
		</CRow>

		<CRow className="mx-0 my-2">
			<CCol xs="4" className="text-md-end px-md-1">
				<span className="">{uBahasaObj.caption_jenislayanan||"caption_jenislayanan"}</span>
			</CCol>
			<CCol xs="7" lg="4" className="">
				<CFormSelect size="sm"
					value={uDataparseObj.layanan_id}
					onChange={(e)=>{uDataparseObj.layanan_id=(e.target.value);setEfekform(!uEfekform);}}
					id="inpbookinglayanan">
				{uDataLayananArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} style={{color:"#"+vItems.color_layanan}} value={vItems.id}>{vItems.caption_layanan}</option>
					); 
				})}
				</CFormSelect>
			</CCol>

			<CCol xs="1" className="px-0 text-center">
				{uLoadingjml||false === true ? (
					<CSpinner color="danger" size="sm"/>
				) : (
				<>
				{(()=>{
					if(uDataparseObj.layanan_id === undefined) return "";

					const vArridx 	= UFunc.getObjectIndeks(uDataLayananArr,uDataparseObj.layanan_id);
					const vJumlahidx= UFunc.getObjectIndeks(uDatajumlahArr,uDataparseObj.layanan_id);

					return (
						<b className="" style={{color:"#"+(uDataLayananArr[vArridx].color_layanan)}}>
						[{UFunc.formatAngka(vJumlahidx < 0 ? 0 : uDatajumlahArr[vJumlahidx].jumlah)}]
						</b>
					)
				})()}
				</>
				)}
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="mx-0 my-2">
			<CCol xs="4" className="text-md-end px-md-1">
				<span className="">{uBahasaObj.caption_statusantar||"caption_statusantar"}</span>
			</CCol>
			<CCol xs="8" md="7" lg="4" className="">
				<CFormSelect size="sm"
					value={uDataparseObj.status_antar||""}
					onChange={(e)=>{uDataparseObj.status_antar=(e.target.value);setEfekform(!uEfekform);}}
					id="inpbookingstatus">
				{uStatusantarArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems} key={vKeys}>{vItems}</option>
					)
				})}
				</CFormSelect>
				{(uDataparseObj.status_antar||"").toUpperCase()==="LAINNYA" && (
				<div className="my-1">
				<CFormInput size="sm" maxLength={100}
					placeholder="--Diisi Jika LAINNYA.."
					value={uDataparseObj.status_antarlainnya||""}
					onChange={(e)=>{uDataparseObj.status_antarlainnya=(e.target.value);setEfekform(!uEfekform);}}
					id="inpbookingstatuslainnya"/>
				</div>
				)}
			</CCol>
		</CRow>

		<CRow className="mx-0 my-2">
			<CCol xs="12" md="4" className="text-md-end px-md-1">
				<span className="">{uBahasaObj.caption_ketbooking||"caption_ketbooking"}</span>
			</CCol>
			<CCol xs="12" md="8" className="">
				<CFormTextarea size="sm"
					value={UFunc.br2nl(uDataparseObj.keterangan||"")}
					onChange={(e)=>{uDataparseObj.keterangan=UFunc.nl2br(e.target.value);setEfekform(!uEfekform);}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpbookingketerangan"/>
				<div className="classpetunjuk">*) {uBahasaObj.petunjuk_textarea||"petunjuk_textarea"}</div>
			</CCol>
		</CRow>
		</CForm>

		<MyCollapsejadwal 
			tglcek={uDataparseObj.tgl_booking||UFunc.DbDate()}
			onClose={()=>setShowjadwal(false)} 
			show={uISShowjadwal} 
			{...props}/>
		</>
	)//>
}

export default MyFormbooking;