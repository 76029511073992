import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CFooter,
	CForm,
	CRow,
	CCol,
	CFormInput,
	CFormSelect,
	CFormCheck,
	CButton,
	CInputGroup,
	CInputGroupText,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPage500,
	MyDialoglookup,
	MyButton,
	MyProfileDetil,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     		= require('../../../package.json');
const Hutanglaintambah 	= (props) => {
	const uComponentname	= Hutanglaintambah.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif 
	} = props; 
	let uFuncname;

	const uNavigate			= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uDefaultSetting	= useSelector(state => state.gListDefaultSetting);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted) || true;
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uKasGLArr							= JSON.parse(localStorage.getItem("kasgllist") || "[]");
	const [uHutangGLArr,setHutangGLArr]		= React.useState([]);//-*/

	const [uInpStatustembak,setInpStatustembak]= React.useState("REGULER");
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate());
	const [uInpTglJT,setInpTglJT]			= React.useState(null);
	const [uInpKasID,setInpKasID]			= React.useState(0);
	const [uInpKascaption,setInpKascaption]	= React.useState("");
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");
	const [uInpAkunID,setInpAkunID]			= React.useState(0);
	const [uInpAkuncaption,setInpAkuncaption]	= React.useState("");
	const [uInpNilai,setInpNilai]			= React.useState(0);
	const [uIsFormprocess,setIsFormprocess]	= React.useState(false);
	const [uIsFormloading,setIsFormloading]	= React.useState(false);

	//--DLG_LOOKUP--/
	const [uDlglookupjenis,setDlglookupjenis]	= React.useState("");
	const [uDlglookupheader,setDlglookupheader]	= React.useState("");
	const [uInpDlglookup,setInpDlglookup]		= React.useState("");
	const [uDataDlglookupArr,setDataDlglookupArr]= React.useState([]);
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false);
	//--END DLG_LOOKUP--/

	//---HANDEL--/
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			_EV.preventDefault();
			document.getElementById("btnSimpanmain") && hdlKlikSimpan();
		}
	}
	const hdlKlikSimpan=()=>{
		//---VALIDASI--/
		if(UFunc.isEmpty(uInpTanggal)) {
			document.getElementById("inptanggal").focus();
			showToast(uBahasaObj.caption_tanggal+" "+uBahasaObj.caption_invalid+"..");
			return;
		} else {
			const vTanggalDt 	= new Date(uInpTanggal);

			const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
			if(vDateBeyond) {
				document.getElementById("inptanggal").focus();
				showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
				return;
			}
			const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
			if(vDateExceededBl) {
				document.getElementById("inptanggal").focus();
				showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
				return;
			}
			//alert("("+uComponentname+") "+cFuncname+" vDateExceededBl = "+vDateExceededBl); return;
		}
		if((parseInt(uInpAkunID)||0) === 0) {
			document.getElementById("inphutangglcaption").focus();
			showToast((uBahasaObj.caption_akunhutang||"caption_akunhutang")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		if((parseInt(uInpNilai)||0) <= 0) {
			document.getElementById("inpnilai").focus();
			showToast((uBahasaObj.word_nilai||"word_nilai")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		if((parseInt(uInpKasID)||0) === 0) {
			document.getElementById("inpkascaption").focus();
			showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		if((uInpKeterangan||"") === "") {
			document.getElementById("inpketerangan").focus();
			showToast((uBahasaObj.word_keterangan||"word_keterangan")+ 
				" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+
				"..");
			return;
		}
		//---END VALIDASI--/

		apiSimpan();
	}
	const hdlToggleDlglookup = () => { setDlglookupshow(false) }
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		const vIdx = UFunc.getObjectIndeks(uDataDlglookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		let vElsfocusnext;
		if(uDlglookupjenis==="KAS") {
			vElsfocusnext = document.getElementById("inpketerangan");
			setInpKasID(uDataDlglookupArr[vIdx].id)
			setInpKascaption(uDataDlglookupArr[vIdx].caption)
		} else if(uDlglookupjenis==="AKUN") {
			vElsfocusnext = document.getElementById("inpnilai");
			setInpAkunID(uDataDlglookupArr[vIdx].id)
			setInpAkuncaption(uDataDlglookupArr[vIdx].caption)
		}
		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			vElsfocusnext && vElsfocusnext.focus()
		},350);
	}
	const hdlKlikKaslookup = () => {
		//setInpDlglookup(uInpKascaption);
		setInpKascaption(""); setInpKasID("");
		const vTmpArr	= uKasGLArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDlglookupjenis("KAS")
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_kaspenerima||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKlikAkunlookup = () => {
		//setInpDlglookup(uInpKascaption);
		setInpAkuncaption(""); setInpAkunID("");
		const vTmpArr	= uHutangGLArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDlglookupjenis("AKUN")
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_akunhutang||"").toUpperCase());
		setDlglookupshow(true)
	}
	//---END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		setInpTanggal(UFunc.DbDate());
		setInpKasID("0");
		setInpKascaption("");
		setInpAkunID("0");
		setInpAkuncaption("");
		setInpKeterangan("");
		setInpNilai(0);
		setInpTglJT("");
		setInpStatustembak("REGULER");
	}
	const initContentForm = () => {
		if(uHTMLError500) return ""

		return (
			<CForm className="mx-lg-3">

			{uTokenObj.userhak === "FREEROLE" && (
			<>
			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
			</CCol>
			<CCol xs="8" lg="4">
				<CFormSelect size="sm"
						onChange={(e)=>setInpStatustembak(e.target.value)}
						value={uInpStatustembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
				</CFormSelect>

				<div className="classpetunjuk">
					<small>*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL</small>
				</div>
			</CCol>
			</CRow>

			<div className="mx-0 my-3 classborderbottom"/>
			</>
			)}

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_tanggal||"word_tanggal"}
				</CCol>
				<CCol xs="8" md="5" lg="4">
					<CFormInput 
						size="sm"
						type="date"
						onChange={(e)=>setInpTanggal(e.target.value)}
						value={uInpTanggal}
						name="inptanggal"
						id="inptanggal"/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_akunhutang||"caption_akunhutang"}
				</CCol>
				<CCol xs={uIsFormloading?"7":"8"} md="5" lg="4">
					<CInputGroup size="sm">
						<CFormInput size="sm"
							value={uInpAkuncaption}
							onClick={()=>hdlKlikAkunlookup()}
							placeholder={"--"+uBahasaObj.word_pilih+": "+(uBahasaObj.caption_akunhutang)+".."}
							id="inphutangglcaption"/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikAkunlookup()}
							id="btnhutanggllookup">
						<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
					</CInputGroup>
				</CCol>
				<CCol xs="1" className={"px-0 text-start"+(uIsFormloading?"":" d-none")}>
					<CSpinner color="primary" size="sm"/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_nilaihutang||"caption_nilaihutang"}
				</CCol>
				<CCol xs="8" md="5" lg="4">
					<NumberFormat 
						value={uInpNilai}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpNilai(values.value)}
						renderText={values => (
							<CFormInput 
								value={values}
								size="sm"
								className="text-end"
								onChange={(e) => setInpNilai(e.target.value)}
								onFocus={(e)=>e.target.select()}
							id="inpnilai"/>
						)} 
						prefix={isMobile?"":"Rp"}/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				<span className="d-none d-md-block">{uBahasaObj.caption_kaspenerima||"caption_kaspenerima"}</span>
				<small className="d-md-none">Kas Penerima</small>
				</CCol>
				<CCol xs="8" md="5" lg="4">
					<CInputGroup size="sm">
						<CFormInput size="sm"
							value={uInpKascaption}
							onClick={()=>hdlKlikKaslookup()}
							placeholder={"--"+uBahasaObj.word_pilih+": "+(uBahasaObj.caption_kaspenerima)+".."}
							id="inpkascaption"/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikKaslookup()}
							id="btnkaslookup">
						<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
					</CInputGroup>
				</CCol>
			</CRow>
			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_keterangan||"word_keterangan"}
				</CCol>
				<CCol>
					<CFormInput maxLength={255} 
						size="sm"
						type="text"
						onChange={(e)=>setInpKeterangan(e.target.value)}
						value={uInpKeterangan}
						name="inpketerangan"
						id="inpketerangan"/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.caption_tgljt||"caption_tgljt"}
				</CCol>
				<CCol xs="8" md="5" lg="4">
					<CFormInput size="sm"
						type="date"
						value={uInpTglJT}
						onChange={(e)=>setInpTglJT(e.target.value)}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpanmain")}
						id="inptgljt"/>
				</CCol>
			</CRow>
			</CForm>
		)
	}
	const konfirmNextinput = async(_PESAN) => {
		_PESAN	= (_PESAN||"").trim();

		if(await Konfirm(
			_PESAN+
			"<div class='classborderbottom my-3'></div>"+
			"Apakah Anda Akan Kembali ?"+
			"<br/><small class='fst-italic'>(Klik <b>YA</b> untuk KEMBALI, "+
			"atau <b>TIDAK</b> untuk Melakukan INPUT Berikutnya..)</small>"+
			"")) uNavigate(-1);
	}
	//--END INIT--/

	//--API--/
	const apiLoadawal = () => {
		uFuncname = apiLoadawal.name;
		setHTMLError500();

		setIsFormloading(true);
		setIsFormprocess(true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutanglain/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			if(output_string.tampil) {
				setHutangGLArr(JSON.parse(output_string.hutangobject||"[]"));
				setIsFormprocess(false);
				setIsFormloading(false);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
				//showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setIsFormloading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiSimpan = () => {
		uFuncname = apiSimpan.name;
		setLoading(true);
		setIsFormprocess(true);

		/*//--TESTING_FRONTEND--//
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			setIsFormprocess(false);
			konfirmNextinput("HALo halo bandung");
		},2500);
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_tanggal 	: uInpTanggal,
			send_hutangglid	: uInpAkunID,
			send_kasid 		: uInpKasID,
			send_tgljt 		: uInpTglJT,
			send_keterangan : uInpKeterangan,
			send_nilai		: uInpNilai,
			send_statustembak: uInpStatustembak,
			send_tokenauth 	: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutanglain/pr_simpaninput";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setIsFormprocess(false);

			if(output_string.sukses) {
				initFormAwal();
				let vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				if(output_string.pesankhusus) vPesan = output_string.pesankhusus;
				//showToast(vPesan,"SUKSES");
				//if(!isMobile) document.getElementById("inptanggal").focus();

				konfirmNextinput(vPesan);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setIsFormprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);

		return () => {
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadawal();

		if(!isMobile) {
			const vElsTanggal = document.getElementById("inptanggal");
			if(!isMobile) vElsTanggal && vElsTanggal.focus();
		}

		return () => {
			initFormAwal();
			hdlToggleDlglookup();
			setDataDlglookupArr([]);
			setHutangGLArr([]);
		}
	},[]);
	React.useEffect(()=>{
		if(!uIsDlglookupshow) {
			setDataDlglookupArr([]); setDlglookupjenis("");
			return
		}
	},[uIsDlglookupshow]);
	//---END EFFECT---/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="px-3 classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() => uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={25}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_hutanglain||"menus_hutanglain").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-2">
			<CContainer xxl className="p-0">
				{(uHTMLError500) ? (
					<MyPage500 content={uHTMLError500} 
						isWithrefresh={true}
						hdlReset={()=>apiLoadawal()}
						{...props}/>
				) : (
				<CCard className="classcardbg">
					<CCardHeader>
						<CIcon className="classikon classikontambah me-2" height={30} />
						<b>{uBahasaObj.caption_inputhutangbaru||"caption_inputhutangbaru"}</b>
					</CCardHeader>

					<CCardBody className="px-lg-5">{initContentForm()}</CCardBody>
				</CCard>
				)}
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uIsFormprocess}
				onClick={()=>hdlKlikSimpan()}
				id="btnSimpanmain">Simpan [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlglookupheader}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>
		</>
	)
}

export default Hutanglaintambah;
