import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {
	CTooltip,
	CLink,
	CImage,
} from '@coreui/react';
//import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';
let pjson     = require('../../../package.json');

const Headersubkasirklinik = (props) => {
	//const cComponentName = Headersubkasirklinik.name;
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uDispatch			= useDispatch();

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword") || "{}");
	const uEfekapp			= useSelector(state => state.effectApp);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);

	const [uKeyword,setKeyword] 		= React.useState(uKeywordObj.kwd_kasirklinik || "");

	const hdlKlikReset=async()=>{
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_kasirklinik = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//prosesReset(); return;
		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}

	//console.log("("+cComponentName+") Checked..");
	return(
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+uActiveroute+".png"} 
				className="me-1 align-middle"
				height={30}/>
			<small className="fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			{uIsEOMExecuted ? (
			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink className="classcursorpointer classikon classikonreset"
					onClick={()=>hdlKlikReset()}/>
			</CTooltip>
			) : (
			<span className="classblinking">
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui" height={30}/>
			</span>
			)}
			<span className="me-2">&middot;</span>

			<b id="idjmldata" className="me-1">0</b>{uBahasaObj.word_data||"word_data"}
		</div>
		</>
	);
}

export default Headersubkasirklinik;