import React from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { 
	CRow,
	CCol,
	CForm,
	CFormInput,
} from '@coreui/react'
import {
	MyDialogform,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { isMobile } from "react-device-detect";

const MyFormHapus = (props) => {
	const {
		show,toggle,dialogHeader,value,onCHvalue,onSimpan, ...rest
	} = props
	const uBahasaObj  	= useSelector(state => state.listBahasa);

	const loadContentform = () => {
		if((show||false)===false) return (<></>);

		return (
			<>
			<CForm className="mx-md-2 mx-lg-4">
			<CRow className="my-2">
				<CCol className="">
					{uBahasaObj.caption_alasanpenghapusan||"caption_alasanpenghapusan"}
					<CFormInput size="sm"
						maxLength="255"
						className="text-capitalize"
						defaultValue={value}
						onChange={onCHvalue}
						onKeyDown={(e)=> UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpkethapus"/>
				</CCol>
			</CRow>

			<CRow className="my-3"><CCol className="classborderbottom"/></CRow>

			<CRow className="my-2">
				<CCol className="">
				{(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")}
				</CCol>
			</CRow>

			<CRow className="my-2"/>
			<CRow className="my-2">
				<CCol className="">
				<strong>
				Klik SIMPAN, untuk Menghapus Data..
				</strong>
				</CCol>
			</CRow>
			</CForm>
			</>
		)
	}	

	React.useEffect(()=>{
		if(!show) return;
		
		//console.log("(MyFormHapus - [show]) show => "+show);

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			const vElFocus = document.getElementById("inpkethapus");
			if(!isMobile) vElFocus && vElFocus.focus();
		},350);
	},[show]);

	return (
		<>
		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>{onSimpan && onSimpan()}}
			show={show} 
			dialogHeader={dialogHeader||"UNDF"}
			toggle={(toggle && toggle)}
			renderContent={loadContentform()}/>
		</>
	)
}	

export default MyFormHapus