import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CHeaderBrand,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CButton,
	CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import NumberFormat from 'react-number-format';
import {
	MyButton,
	MyDialoglookup,
	MyProfileDetil,
	MyPage500,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 			= require('../../../package.json')
const Tagihanpajakinput = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	const { id } 			= useParams(); 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uTimeelapse,setTimeelapse]		= React.useState(0); 

	const uKasGLArr					= JSON.parse(localStorage.getItem("kasgllist")||"[]");
	const [uDatamainObj,setDatamainObj]		= React.useState({})
	const [uAkunhutangArr,setAkunhutangArr]	= React.useState([])
	const [uAkunreklasArr,setAkunreklasArr]	= React.useState(uKasGLArr)

	//--DLG_FORM--/
	const [uDlgformheader,setDlgformheader]	= React.useState("")
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false)
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false)
	const [uDlglookupdataObj,setDlglookupdataObj]= React.useState({})
	//--END DLG_LOOKUP--/

	//---HANDEL--/
	const hdlKlikBack = async() => {
		if((uDatamainObj.is_updated||false)===true) {
			if(await Konfirm(
				"<b>Sudah Terjadi Perubahan Field Data pada Form Input</b>.."+
				"<br/>Anda Akan Menghilangkan SELURUH Field yang Sudah Anda Isikan pada FORM, dan Belum MENYIMPANNYA."+
				"<br/><br/>Apakah Anda Akan Melanjutkan ?"+
				"")) uNavigate(-1);

			return;
		}

		uNavigate(-1);
	}
	const hdlKDownDocument = (_EV) => {
		if(_EV.which === 120) {
			_EV.preventDefault();
			document.getElementById("btnSimpanmain") && hdlKlikSimpanmain();
		}
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		if(!uISDlglookupshow) return;
		_IDITEM = parseInt(_IDITEM);

		let vElsfocusnext;
		if(uDlglookupdataObj.jenis === "AKUNHUTANG") {
			vElsfocusnext = document.getElementById("inpnilai");
			const vArridx = UFunc.getObjectIndeks(uAkunhutangArr,_IDITEM); 

			uDatamainObj.akunhutang_caption	= uAkunhutangArr[vArridx].caption||"";
			uDatamainObj.akunhutang_id		= uAkunhutangArr[vArridx].id||"0";
		} else if(uDlglookupdataObj.jenis === "AKUNREKLAS") {
			vElsfocusnext = document.getElementById("inpket");
			const vArridx = UFunc.getObjectIndeks(uAkunreklasArr,_IDITEM,"value"); 
			
			uDatamainObj.akunreklas_caption	= uAkunreklasArr[vArridx].caption||"";
			uDatamainObj.akunreklas_id		= uAkunreklasArr[vArridx].value||"0";
		}
		setDlglookupshow(false);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			if(!isMobile) vElsfocusnext && vElsfocusnext.focus()
		},350);
	}
	const hdlKlikakunhutanglookup = () => {
		uDatamainObj.akunhutang_caption = "";
		uDatamainObj.akunhutang_id 		= 0;

		const vTmpArr	= uAkunhutangArr.map((vItems)=>{
			return {id:vItems.id,caption:vItems.caption}
		})
		uDlglookupdataObj.jenis 	= "AKUNHUTANG";
		uDlglookupdataObj.items_arr	= vTmpArr;
		uDlglookupdataObj.header	= uBahasaObj.word_pilih+": "+
			(uBahasaObj.caption_akuntagihanpajak||"Akun Tagihan Pajak").toUpperCase();

		setDlglookupshow(true)
	}
	const hdlKlikakunreklaslookup = () => {
		uDatamainObj.akunreklas_caption = "";
		uDatamainObj.akunreklas_id 		= 0;

		const vTmpArr	= uAkunreklasArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		uDlglookupdataObj.jenis 	= "AKUNREKLAS";
		uDlglookupdataObj.items_arr	= vTmpArr;
		uDlglookupdataObj.header	= uBahasaObj.word_pilih+": "+
			(uBahasaObj.caption_akunreklas||"Akun Reklas").toUpperCase();

		setDlglookupshow(true)
	}
	const hdlKlikSimpanmain = async() => {
		//---VALIDASI--/
		let vElfocus = document.getElementById("inptanggal");
		if((uDatamainObj.tgl_hutang||"") === "") {
			vElfocus && vElfocus.focus();
			showToast(uBahasaObj.caption_tanggal+" "+uBahasaObj.caption_invalid+"..");
			return;
		} else {
			const vTanggalDt 	= UFunc.toDate(uDatamainObj.tgl_hutang);

			const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
			if(vDateBeyond) {
				document.getElementById("inptanggal").focus();
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
				return;
			}
			const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
			if(vDateExceededBl) {
				document.getElementById("inptanggal").focus();
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
				return;
			}
			//alert("("+uComponentname+") "+cFuncname+" vDateExceededBl = "+vDateExceededBl); return;
		}

		vElfocus = document.getElementById("inpakunhutangcaption");
		if((parseInt(uDatamainObj.akunhutang_id)||0) === 0) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_akuntagihanpajak||"Akun Tagihan Pajak")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		vElfocus = document.getElementById("inpnilai");
		if((parseInt(uDatamainObj.nilai_hutang)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.word_nilai||"word_nilai")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		vElfocus = document.getElementById("inpkascaption");
		if((parseInt(uDatamainObj.akunreklas_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_akunreklas||"Akun KAS Reklas")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		vElfocus = document.getElementById("inpketerangan");
		if((uDatamainObj.ket||"") === "") {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.word_keterangan||"word_keterangan")+ 
				" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+
				"..");
			return;
		}
		//---END VALIDASI--/

		if(await Konfirm(
			"<b>Data Akan Disimpan</b>.."+
			"<br/><br/>Apakah Anda Yakin ?"+
			"")) apiProsesSimpan();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const konfirmNextprocess = async(_PESAN) => {
		_PESAN	= (_PESAN||"").trim();

		if(await Konfirm(
			_PESAN+
			"<div class='classborderbottom my-3'></div>"+
			"Apakah Anda Akan Kembali ?"+
			"<br/><small class='fst-italic'>(Klik <b>YA</b> untuk KEMBALI, "+
			"atau <b>TIDAK</b> untuk Melakukan INPUT Berikutnya..)</small>"+
			"")) uNavigate(-1);
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return (
			<MyPage500 content={uHTMLError500} isWithrefresh={true} hdlReset={()=>apiLoaddata()}/>
		)

		return (
		<CForm>
		<CCard className="classbgcard">
		<CCardHeader>
			<CIcon className="classikon classikontambah me-2" height={32}/>
			<b>Input Tagihan Pajak Baru</b>
		</CCardHeader>

		<CCardBody className="px-lg-5">
			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">Status Jurnal</CCol>
			<CCol className="text-end text-md-start">
				<div className="fw-bolder">Input TEMBAK DATA</div>

				<div className="classpetunjuk">
				<small>*) Input TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL</small>
				</div>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">Tanggal</CCol>
			<CCol xs="8" md="5" lg="4" className="text-end text-md-start">
				<CFormInput size="sm" type="date"
					value={uDatamainObj.tgl_hutang}
					onChange={(e)=>{uDatamainObj.tgl_hutang=e.target.value;setEfekview(!uEfekview)}}
					id="inptanggal"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">Akun Tagihan Pajak</CCol>
			<CCol xs="8" md="5" lg="4" className="text-end text-md-start">
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					placeholder="--Klik Untuk PILIH.."
					value={uDatamainObj.akunhutang_caption||""}
					onChange={(e)=>{uDatamainObj.akunhutang_caption=e.target.value;setEfekview(!uEfekview)}}
					onClick={()=>hdlKlikakunhutanglookup()}
					id="inpakunhutangcaption"/>
				<CButton size="sm" color="dark" 
					onClick={()=>hdlKlikakunhutanglookup()}
					id="btnakunhutanglookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">Nilai Tagihan</CCol>
			<CCol xs="8" md="5" lg="4" className="text-end text-md-start">
				<NumberFormat 
					value={uDatamainObj.nilai_hutang||0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => uDatamainObj.nilai_hutang = (values.value)}
					renderText={values => (
						<CFormInput size="sm"
							className="text-end"
							value={values}
							onChange={(e)=>{uDatamainObj.nilai_hutang=e.target.value;setEfekview(!uEfekview)}}
							onFocus={(e)=>e.target.select()}
							id="inpnilai"/>
					)} 
					prefix={isMobile?"":"Rp"}/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">Akun Kas Reklas</CCol>
			<CCol xs="8" md="5" lg="4" className="text-end text-md-start">
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					placeholder="--Klik Untuk PILIH.."
					value={uDatamainObj.akunreklas_caption||""}
					onChange={(e)=>{uDatamainObj.akunreklas_caption=e.target.value;setEfekview(!uEfekview)}}
					onClick={()=>hdlKlikakunreklaslookup()}
					id="inpakunreklascaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikakunreklaslookup()}
					id="btnakunreklaslookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">Keterangan</CCol>
			<CCol className="text-end text-md-start">
				<CFormInput size="sm" maxLength={255}
					value={uDatamainObj.ket||""}
					onChange={(e)=>{uDatamainObj.ket=e.target.value;setEfekview(!uEfekview)}}
					id="inpket"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end">Tanggal JT</CCol>
			<CCol xs="8" md="5" lg="4" className="text-end text-md-start">
				<CFormInput size="sm" type="date"
					value={uDatamainObj.tgl_jt}
					onChange={(e)=>{uDatamainObj.tgl_jt=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpanmain")}
					id="inptgljt"/>
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		</CForm>
		)
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHTMLError500();
		setLoading(true);

		const vElsimpan	= document.getElementById("btnSimpanmain");
		vElsimpan && (vElsimpan.disabled = true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_tagihanpajak/ld_form";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setAkunhutangArr(JSON.parse(output_string.hutanglist||"[]"));
				//setAkunreklasArr(JSON.parse(output_string.reklaslist||"[]"));

				vElsimpan && (vElsimpan.disabled = false);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
				//showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Tagihanpajakinput - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Tagihanpajakinput - apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesSimpan = () => {
		setLoading(true);

		const vElsimpan	= document.getElementById("btnSimpanmain");
		vElsimpan && (vElsimpan.disabled = true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datalist : JSON.stringify(uDatamainObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_tagihanpajak/pr_simpaninput";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElsimpan && (vElsimpan.disabled = false);
			if(output_string.sukses) {
				setDatamainObj({}); setEfekview(!uEfekview);
				let vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				if(output_string.pesankhusus) vPesan = output_string.pesankhusus;

				konfirmNextprocess(vPesan);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Tagihanpajakinput - apiProsesSimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			vElsimpan && (vElsimpan.disabled = false);
			console.log("(Tagihanpajakinput - apiProsesSimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

		const vElsTanggal = document.getElementById("inptanggal");
		if(!isMobile) vElsTanggal && vElsTanggal.focus();

    	return () => {
    		setDatamainObj({});
    		setAkunhutangArr([]);
    		setAkunreklasArr(uKasGLArr);
    		setDlglookupshow(false);
    		setDatamainObj({});
    		setEfekview(!uEfekview);
    	}
	},[])
	React.useEffect(()=>{
		if(!uISDlglookupshow) { setDlglookupdataObj({}); return }
	},[uISDlglookupshow])
	React.useEffect(()=>{
		if((uDatamainObj.tgl_hutang||UFunc.DbDate()) !== UFunc.DbDate()
			|| (parseInt(uDatamainObj.akunhutang_id)||0) > 0
			|| (parseInt(uDatamainObj.akunreklas_id)||0) > 0
			|| (parseFloat(uDatamainObj.nilai_hutang)||0) > 0
			|| (uDatamainObj.tgl_jt||"") !== ""
		) uDatamainObj.is_updated = true;
		else uDatamainObj.is_updated = false;
	},[uDatamainObj,
	uDatamainObj.tgl_hutang,
	uDatamainObj.akunhutang_id,
	uDatamainObj.akunreklas_id,
	uDatamainObj.nilai_hutang,
	uDatamainObj.ket,
	uDatamainObj.tgl_jt,
	])
	React.useEffect(()=>{
		if(uDatamainObj.tgl_hutang===undefined) uDatamainObj.tgl_hutang = UFunc.DbDate();
		if(uDatamainObj.tgl_jt===undefined) uDatamainObj.tgl_jt = "";
		if(uDatamainObj.status_tembak===undefined) uDatamainObj.status_tembak = "TEMBAK";
		if(uDatamainObj.nilai_hutang===undefined) uDatamainObj.nilai_hutang = 0;
	},[uDatamainObj,
	uDatamainObj.tgl_hutang,
	uDatamainObj.tgl_jt,
	uDatamainObj.status_tembak,
	])
	//---END EFFECT--/

	//console.log("(Tagihanpajakinput) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={()=>hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="me-2 align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_tagihanpajak||"Taguhan Pajak").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
		<CContainer xxl className="px-0">{contentMain()}</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				centered={true}
				iconname="cilCheckAlt"
				onClick={()=>hdlKlikSimpanmain()}
				disabled={uISDlglookupshow}
				id="btnSimpanmain">
				Simpan [F9]
			</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupdataObj.header}
			inputValue={uDlglookupdataObj.inputvalue||""}
			dataItemArr={uDlglookupdataObj.items_arr || [] } 
			onKlikPilihItem={hdlKlikDlglookuppilih}/>
		</>
	) 
}	

export default Tagihanpajakinput