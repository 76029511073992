import React from 'react';
import { useSelector } from 'react-redux';
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CCloseButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { MyButton } from './index';
import { UFunc } from "../helpers/functions";
import PropTypes from 'prop-types';

const MyDialogform = props => {	
	const {
		show,toggle,renderContent,dialogHeader,onSimpan,options,
		...rest
	} = props;
	const uBahasaob  = useSelector(state => state.listBahasa);

	//React.useEffect(()=>{ },[show]);

	return (
		<CModal
			size={options.hasOwnProperty("size")?options.size:undefined}
			alignment={(options.centered)===true?"center":"top"}
			visible={show}
			keyboard={true}
			scrollable
			{...rest}>
		<CModalHeader closeButton={false} className="bg-light text-dark py-2">
			<CModalTitle className="">
				<small className="d-none d-md-block">{UFunc.renderHTML(dialogHeader)}</small>
				<small className="d-md-none"><small>{UFunc.renderHTML(dialogHeader)}</small></small>
			</CModalTitle>
			<CCloseButton onClick={toggle}/>
		</CModalHeader>
		
		<CModalBody>{renderContent}</CModalBody>
		<CModalFooter>
		<MyButton
			onClick={()=>onSimpan()}
			id="btnDialogSimpan">
			<CIcon icon="cilCheckAlt" className="me-1 align-middle" height={25}/>
			{uBahasaob.word_simpan||"Simpan"}
		</MyButton>{" "}
		<CButton color="light" onClick={toggle}>{uBahasaob.word_batal||"Batal"}</CButton>
		</CModalFooter>
		</CModal>
	)
}
MyDialogform.defaultProps = {
	show: false,
	renderContent: "{UNDEFINED}..",
	dialogHeader: "Form Data",
	options: {centered:true,size:""},
};
MyDialogform.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,      
	renderContent: PropTypes.element, 
	dialogHeader: PropTypes.string,
	onSimpan: PropTypes.func,
	options: PropTypes.object,
}

export default MyDialogform;