import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CSpinner,
	CButton,
} from '@coreui/react';
import { 
	MyDialoglookup,
} from '../components/index';
import CIcon from '@coreui/icons-react';
import { MyButton } from './index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from "../helpers/onConfirm";
import { isMobile } from "react-device-detect";
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const pjson     	= require('../../package.json');
const MyFormHadir	= props => {
	const {
		setLoading,showToast,prosesExpired,
		objDataparse,
	} = props;

	const uDispatch		 	= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const refFileImg 		= React.useRef();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uDatalayananArr	= JSON.parse(localStorage.getItem("layananlist")||"[]");

	const [uFormefek,setFormefek]		= React.useState(false);
	const uDataparseObj 				= objDataparse || {};
	const uTglhadirDT	 				= UFunc.toDatetime(uDataparseObj.tgl_hadir||"");
	const uFotoArr						= uDataparseObj.foto_arr||[];
	const [uDokterArr,setDokterArr]		= React.useState([]);
	const [uDokterloading,setDokterloading]= React.useState(false);
	const [uLayananvalue,setLayananvalue]= React.useState((uDataparseObj.value_layanan || "").toUpperCase());
	
	const [uPaymentArr,setPaymentArr]	= React.useState([]);
	const [uPaymentloading,setPaymentloading]	= React.useState(false);
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uInpDlglookupkwd,setInpDlglookupkwd]	= React.useState("");

	const hdlTambahfoto=()=>{ refFileImg.current.click(); }
	const hdlChangeFotofake=(_EV)=>{
		const fileUploaded 	= _EV.target.files[0];
		//console.log("(Pasieninput - hdlChangeFoto) fileUploaded => "+fileUploaded);

		UFunc.getBase64(fileUploaded).then(result => {
			const fileBase64	= result;

			let vFotoArr		= uFotoArr;
			const vFotoObj		= {
				id:"0",url:fileBase64
				};

			uDataparseObj.foto_arr	= ([...uFotoArr, vFotoObj]);
			setFormefek(!uFormefek);

			//onCHfoto(fileBase64);
			_EV.target.value = '';
			//setChangefoto(!uChangefoto);
		}).catch(err => {
			_EV.target.value = '';
			console.log(err);
		});
	}
	const hdlKPressnumber = (_EV) => {//--TIDAK_DIPAKAI--/
		if (!/[\d,]/.test(_EV.key)) {
			_EV.preventDefault(); return;
		} 
	}
	const hdlHapusfoto=async(_IDX)=>{
		_IDX = parseInt(_IDX) || 0;
		if(_IDX < 0) return;

		const vActionhapus=()=>{
			//let vFotoArr		= uFotoArr;
			if((parseInt(uFotoArr[_IDX].id||"0")||0) <= 0)
				uFotoArr.splice(_IDX,1)
			else uFotoArr[_IDX].status_hapus = "YA";
			
			//setFotoArr(vFotoArr);
			//setChangefoto(!uChangefoto);
			setFormefek(!uFormefek);
		}

		if(await Konfirm(
			(uBahasaObj.petunjuk_hapusfoto||"petunjuk_hapusfoto")+
			"<BR>"+
			"<BR>"+
			"<B>"+(uBahasaObj.confirm_yakin||"confirm_yakin")+"</B>")) {
			vActionhapus();
		}
	}
	const hdlKlikPayment = () => {
		uDataparseObj.payment_id 		= undefined;
		uDataparseObj.payment_caption 	= undefined;
		if(parseInt(uDataparseObj.nilai_dp||"0") <= 0) {
			document.getElementById("btnDialogSimpan")
				&& document.getElementById("btnDialogSimpan").focus()
			return;
		}

		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0;if(_IDITEM <= 0) return;
		const vArrIdx = UFunc.getObjectIndeks(uPaymentArr,_IDITEM)

		uDataparseObj.payment_id 		= _IDITEM;
		uDataparseObj.payment_caption 	= uPaymentArr[vArrIdx].caption||"";
		//setFormefek(!uFormefek)

		setDlglookupshow(false)
	}

	const apiLoaddokter=()=>{
		const vElBtnsimpan	= document.getElementById("btnDialogSimpan");
		vElBtnsimpan && (vElBtnsimpan.disabled = true);
		setDokterloading(true)

		if(uTokenObj.userinit === "") 
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/ld_data";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			vElBtnsimpan && (vElBtnsimpan.disabled = false);
			setDokterloading(false)
			//console.log("("+uComponentname+"-"+uFuncName+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setDokterArr(JSON.parse(output_string.dataobject||"[]"));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyFormHadir - apiLoaddokter) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElBtnsimpan && (vElBtnsimpan.disabled = false);
			setDokterloading(false)
			console.log("(MyFormHadir - apiLoaddokter) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadpayment=()=>{
		const vElBtnsimpan	= document.getElementById("btnDialogSimpan");
		vElBtnsimpan && (vElBtnsimpan.disabled = true);
		setPaymentloading(true);

		if(uTokenObj.userinit === "") 
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_init/ld_payment";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			vElBtnsimpan && (vElBtnsimpan.disabled = false);
			setPaymentloading(false);
			//console.log("("+uComponentname+"-"+uFuncName+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setPaymentArr(JSON.parse(output_string.paymentlist||"[]"));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyFormHadir - apiLoadpayment) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElBtnsimpan && (vElBtnsimpan.disabled = false);
			setPaymentloading(false);
			console.log("(MyFormHadir - apiLoadpayment) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	//console.log("MyFormHadir = uDataparseObj => "+JSON.stringify(uDataparseObj));

	React.useEffect(()=>{
		apiLoaddokter();
		//apiLoadpayment();

		return ()=>{ 
			setDokterArr([]);
			setPaymentArr([]);
		}
	},[]);
	React.useEffect(()=>{
		let vELfocus = document.getElementById("btnDialogSimpan")
		if(!uIsDlglookupshow) {
			if(parseInt(uDataparseObj.payment_id||"0") <= 0)
				vELfocus = document.getElementById("inppaymentcaption")

			vELfocus && vELfocus.focus();
			return;
		}
	},[uIsDlglookupshow]);//-*/
	React.useEffect(()=>{
		if(parseInt(uDataparseObj.nilai_dp||"0") <= 0) {
			uDataparseObj.payment_id 		= undefined;
			uDataparseObj.payment_caption 	= undefined;
			setFormefek(!uFormefek);
		}
		if(uDataparseObj.layanan_id === undefined) {
			uDataparseObj.layanan_id = uDatalayananArr[0].id
		} else {
			const vArrIdx = UFunc.getObjectIndeks(uDatalayananArr,uDataparseObj.layanan_id);
			setLayananvalue(uDatalayananArr[vArrIdx].value_layanan);
		}
	},[uDataparseObj,
	uDataparseObj.nilai_dp,
	uDataparseObj.layanan_id
	]);
	React.useEffect(()=>{
		if(!isMobile) {
			uDataparseObj.berat = uDataparseObj.berat_tmp;
			return;
		}

        const vCorrectedvalue 	= (uDataparseObj.berat_tmp||"0").replace(/[^0-9,]/g, '');
		uDataparseObj.berat_tmp	= vCorrectedvalue;
		uDataparseObj.berat 	= parseFloat(vCorrectedvalue.replace(",","."))||0;
        setFormefek(!uFormefek);
	},[uDataparseObj,
	uDataparseObj.berat_tmp
	]);
	React.useEffect(()=>{
		if(uPaymentArr.length <= 0)  return;
		if(parseInt(uDataparseObj.id_ongoing||"0") <= 0) return;
		//console.log("uDataparseObj.id_ongoing => "+uDataparseObj.id_ongoing);

		const vArrIdx = UFunc.getObjectIndeks(uPaymentArr,parseInt(uDataparseObj.payment_id||"0"));
		if(vArrIdx < 0)  return;
		uDataparseObj.payment_caption 	= uPaymentArr[vArrIdx].caption||"";
		setFormefek(!uFormefek);
	},[uPaymentArr,uDataparseObj.id_ongoing]);
	React.useEffect(()=>{
		if(uLayananvalue !== "OPNAME") 
			{ uDataparseObj.id_dokter = 0;return }
		if(uLayananvalue !== "PENITIPAN") 
			{ uDataparseObj.tgl_keluar = null; return }
	},[uLayananvalue]);

	//console.log("(MyFormHadir) uDataparseObj => "+JSON.stringify(uDataparseObj));
	return (
		<>
		<CForm className="mx-lg-2">
			<input type="file"
				accept="image/png, image/jpeg"
				ref={refFileImg}
				onChange={(e)=>hdlChangeFotofake(e)}
				className="d-none"
				id="inpfileimg"/>
			<CRow className="mx-0 my-2">
			<CCol xs="6" md="4" className="text-start text-md-end">
				<span className="d-md-none d-lg-block">{uBahasaObj.caption_jamkedatangan||"caption_jamkedatangan"}</span>
				<small className="d-none d-md-block d-lg-none">{uBahasaObj.caption_jamkedatangan||"caption_jamkedatangan"}</small>
			</CCol>
			<CCol xs="6" md="8" className="text-end text-md-start">
			{UFunc.HariAngka(uTglhadirDT)}{" "}
			<strong>{UFunc.leadZero(uTglhadirDT.getHours())}:{UFunc.leadZero(uTglhadirDT.getMinutes())}</strong>
			</CCol>
			</CRow>

			<CRow className="mx-0 my-2">
			<CCol xs={((parseInt(objDataparse.id_ongoing)||0) > 0 || (parseInt(objDataparse.id_booking)||0)) > 0 ? "4" : "6"}
				md="4"
			 	className="text-start text-md-end">
				<span className="d-md-none d-lg-block">{uBahasaObj.caption_jenislayanan||"caption_jenislayanan"}</span>
				<small className="d-none d-md-block d-lg-none">{uBahasaObj.caption_jenislayanan||"caption_jenislayanan"}</small>
			</CCol>
			{((parseInt(objDataparse.id_ongoing)||0) > 0 || (parseInt(objDataparse.id_booking)||0)) > 0 ? (
			<CCol xs="8" md="5" lg="4" className="text-end text-md-start">
			<b className="text-primary">{uBahasaObj["layanan_"+(uDataparseObj.value_layanan||"").toLowerCase()]}</b>
			</CCol>
			) :(
			<CCol xs="6" md="5" lg="4" className="text-end text-md-start">
			<CFormSelect size="sm"
				value={objDataparse.layanan_id}
				onChange={(e)=> {objDataparse.layanan_id = e.target.value;setFormefek(!uFormefek); }}
				id="inplayananid">
				{uDatalayananArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} 
						style={{color:"#"+vItems.color_layanan}}
						value={vItems.id}>{vItems.caption_layanan}</option>
					)
				})}
			</CFormSelect>
			</CCol>
			)}
			</CRow>

			<div className="my-3 classborderbottom"/>

			{(uLayananvalue==="PENITIPAN") && (
			<CRow className="mx-0 my-2">
			<CCol xs="6" md="4" className="text-start text-md-end">
				<span className="d-md-none d-lg-inline">{uBahasaObj.caption_tglkeluar||"caption_tglkeluar"} <small>({uBahasaObj.word_estimate||"Estimasi"})</small></span>
				<small className="d-none d-md-block d-lg-none">{uBahasaObj.caption_tglkeluar||"caption_tglkeluar"} <small>({uBahasaObj.word_estimate||"word_estimate"})</small></small>
			</CCol>
			<CCol xs="6" md="5" lg="4" className="">
				<CFormInput size="sm" type="date"
					value={uDataparseObj.tgl_keluar||""}
					onChange={(e)=>{uDataparseObj.tgl_keluar=e.target.value;setFormefek(!uFormefek)}}
					id="inptgltitipexit"/>
			</CCol>
			</CRow>
			)}

			<CRow className="mx-0 my-2">
			<CCol xs="6" md="4" className="text-start text-md-end">
				<span className="d-none d-lg-block">{uBahasaObj.caption_preexam||"caption_preexam"}</span>
				<span className="d-md-none">{uBahasaObj.caption_cekberat||"caption_cekberat"}</span>
				<small className="d-none d-md-block d-lg-none">{uBahasaObj.caption_preexam||"caption_preexam"}</small>
			</CCol>
			<CCol xs="6" md="4" lg="3" className="">
				<CInputGroup size="sm">
				<CFormInput size="sm" type={isMobile ? "text" : "number"} 
					value={uDataparseObj.berat_tmp||"0"}
					onChange={(e)=>{uDataparseObj.berat_tmp=(e.target.value);setFormefek(!uFormefek)}}
					className="text-end"
					min={0} max={999} step=".1"
					lang="id-ID"
					onFocus={(e)=>e.target.select()}
					onKeyDown={(e)=>{
						if(uLayananvalue!=="OPNAME")UFunc.hdlKeyDownInput(e,"inpkeluhan")
					}}
					id="inpberat"/>
				<CInputGroupText>KG</CInputGroupText>
				</CInputGroup>
				<small className="d-none d-md-block classpetunjuk px-0">{uBahasaObj.caption_cekberat||"Cek Berat"}</small>
			</CCol>
			</CRow>

			<CRow className={"mx-0 my-2"+(uLayananvalue==="OPNAME"?"":" d-none")}>
			<CCol xs="6" md="4" className="text-start text-md-end">
				<span className="d-md-none d-lg-inline">{uBahasaObj.caption_dokterpenanggungjawab||"caption_dokterpenanggungjawab"}</span>
				<small className="d-none d-md-block d-lg-none">{uBahasaObj.caption_dokterpenanggungjawab||"caption_dokterpenanggungjawab"}</small>
			</CCol>
			<CCol xs="6" md="5" lg="4" className="">
				<CInputGroup size="sm">
				<CFormSelect size="sm"
					disabled={(uLayananvalue==="OPNAME"?false:true)}
					value={uDataparseObj.id_dokter||"0"}
					onChange={(e)=>{uDataparseObj.id_dokter=e.target.value;setFormefek(!uFormefek)}}
					id="inpdokterid">
				<option>--{(uBahasaObj.caption_silahkanpilih).toUpperCase()}</option>
				{uDokterArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} value={vItems.id}>DRH. {vItems.nama||""}</option>
					);
				})}
				</CFormSelect>
				{(uDokterloading) && (
				<div className="mx-2"><CSpinner color="primary" size="sm"/></div>
				)}
				</CInputGroup>
			</CCol>
			</CRow>

			<CRow className="mx-0 my-2">
			<CCol md="4" className="text-start text-md-end">
				<span className="d-md-none d-lg-block">{uBahasaObj.word_keluhan||"word_keluhan"}/{uBahasaObj.word_request||"word_request"}</span>
				<small className="d-none d-md-block d-lg-none">{uBahasaObj.word_keluhan||"word_keluhan"}/{uBahasaObj.word_request||"word_request"}</small>
			</CCol>
			<CCol md="8" className="">
				<CFormInput size="sm" maxLength={100}
					className="text-capitalize"
					onKeyDown={(e)=>{
						const vElpayment = document.getElementById("inppaymentcaption");
						if(!vElpayment) {
							UFunc.hdlKeyDownInput(e,"btnDialogSimpan")
						}
					}}
					value={uDataparseObj.keluhan||""}
					onChange={(e)=>{uDataparseObj.keluhan=e.target.value;setFormefek(!uFormefek)}}
					id="inpkeluhan"/>
			</CCol>
			</CRow>

			{0===1 && (
			<CRow className="mx-0 my-2 d-none">
			<CCol md="4" className="text-start text-md-end">
				<span className="d-md-none d-lg-block">{uBahasaObj.word_keterangan||"word_keterangan"}</span>
				<small className="d-none d-md-block d-lg-none">{uBahasaObj.word_keterangan||"word_keterangan"}</small>
			</CCol>
			<CCol md="8" className="">
				<CFormInput size="sm" maxLength={255}
					disabled={true}
					value={uDataparseObj.keterangan||""}
					onChange={(e)=>{uDataparseObj.keterangan=e.target.value;setFormefek(!uFormefek)}}
					id="inpketerangan"/>
			</CCol>
			</CRow>
			)}

			<CRow className="mx-0 my-2">
			<CCol md="4" className="text-start text-md-end">
				<span className="d-md-none d-lg-block">{uBahasaObj.caption_fotokedatangan||"caption_fotokedatangan"}</span>
				<small className="d-none d-md-block d-lg-none">{uBahasaObj.caption_fotokedatangan||"caption_fotokedatangan"}</small>
			</CCol>
			<CCol md="8" className="">
				<div className="p-2 border d-flex justify-content-start align-items-center" style={{borderStyle:"dashed !important",borderRadius:3}}>
				{uFotoArr.map((vItems,vKeys)=>{
					return (
					<div key={vKeys} className={"classbordersquare me-2 p-1"+((vItems.status_hapus||"TIDAK")==="YA"?" d-none":"")}>
						<div 
							style={{
								background: "transparent url("+vItems.url+") center center no-repeat",
								backgroundSize:"cover",
								height:65,width:65}}>
							<div 
								style={{
									cursor:"pointer",
									height:65,width:65
								}}
								className="classactionfoto"
								onClick={()=>{hdlHapusfoto(vKeys)}}/>
						</div>
					</div>
					);
				})}
				<div className="classbordersquare d-flex justify-content-center align-items-center classborderdashed" 
					style={{cursor:"pointer",height:67,width:67}}
					onClick={(e)=>hdlTambahfoto(e)}>
					<CIcon className="classikontambah4" height={30}/>
				</div>
				</div>
			</CCol>
			</CRow>

			{UFunc.DbDate(uTglhadirDT)===1 && (
			<>
			<div className="my-3 classborderbottom d-none"/>
			<CRow className="mx-0 my-2 d-none">
			<CCol md="4" className="text-start text-md-end">
				<span className="d-md-none d-lg-block">{uBahasaObj.caption_titipandp||"caption_titipandp"}</span>
				<small className="d-none d-md-block d-lg-none">{uBahasaObj.caption_titipandp||"caption_titipandp"}</small>
			</CCol>
			<CCol md="5" lg="4" className="">
				<NumberFormat 
					value={uDataparseObj.nilai_dp||"0"}
					displayType={"text"} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => uDataparseObj.nilai_dp=(values.value)}
					renderText={values => (
						<CFormInput size="sm"
							className="text-end"
							value={values}
							onChange={(e)=>{uDataparseObj.nilai_dp=e.target.value;setFormefek(!uFormefek)}}
							onFocus={(e)=>e.target.select()}
							id="inpnilaidp"/>
						)}
				prefix={isMobile?"":"Rp"}/>
			</CCol>
			</CRow>

			<CRow className="mx-0 my-2 d-none">
			<CCol md="4" className="text-start text-md-end">
				<span className="d-md-none d-lg-block">{uBahasaObj.caption_metodebayar||"caption_metodebayar"}</span>
				<small className="d-none d-md-block d-lg-none">{uBahasaObj.caption_metodebayar||"caption_metodebayar"}</small>
			</CCol>
			<CCol md="5" lg="4" className="">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					placeholder={"--"+(uBahasaObj.word_pilih)+" "+(uBahasaObj.caption_metodebayar)+".."}
					readOnly={true}
					value={uDataparseObj.payment_caption||""}
					onClick={()=>hdlKlikPayment()}
					id="inppaymentcaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikPayment()}>
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				{uPaymentloading && (
				<div className="mx-2"><CSpinner color="dark" size="sm"/></div>
				)}
				</CInputGroup>
			</CCol>
			</CRow>
			</>
			)}
		</CForm>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uBahasaObj.word_lookup+": "+uBahasaObj.caption_paymentmethod}
			inputValue={uInpDlglookupkwd}
			dataItemArr={uPaymentArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>
		</>
	)//>
}

MyFormHadir.defaultProps = {
	objDataparse:{},
};
MyFormHadir.propTypes = {
	objDataparse: PropTypes.object,
}

export default MyFormHadir;