import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CRow,
	CCol,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
	MyPagination,
	MyFormHapus,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     = require('../../package.json');
const Omzetlain = (props) => {
	const uComponentname 	= Omzetlain.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uHTMLstyleTabel= useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp		= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 	= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted
	const [uFirstload,setFirstload]			= React.useState("YA");
	//const uIsInitbefore = ((JSON.stringify(uTabelSelectObj)||"{}")!=="{}");
	//const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);

	const uMaxData							= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [uIsShowDlghapus,setShowDlghapus]	= React.useState(false);
	const [uDlghapusheader,setDlghapusheader]= React.useState("");
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpKethapus,setInpKethapus]		= React.useState("");

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata		= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowDlghapus(false); }
	const hdlKlikHapus=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);
		
		setDlghapusheader((uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			(UFunc.HariAngka(uDatamainArr[vObjdx].tanggal)+" "+(uDatamainArr[vObjdx].jenis || "")).toUpperCase());
		setShowDlghapus(true);
	}
	const hdlKlikSimpanHapus=()=>{
		if((uInpKethapus||"").trim() === "") {
			document.getElementById("inpkethapus") &&
				document.getElementById("inpkethapus").focus();
			showToast((uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi")); 
			return;
		}

		apiProseshapus();
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_omzetlain || "";
		if (vKeyword.length >= 3)
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFormAwal=()=>{
		setTabelIDPilih(0);
		setInpKethapus("");
	}

	const contentMain=()=>{
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_omzetlain || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=> parseInt(vItems.pg||1)===parseInt(uPageActive||1))
		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		
		const vTanggalnowDT	= new Date();

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width="5%" className="px-1">No</th>
		<th className="px-1">
			<CRow className="mx-0">
			<CCol md="3" lg="2" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
			<CCol md="2" className="text-start">{uBahasaObj.caption_akunpendapatan||"caption_akunpendapatan"}</CCol>
			<CCol md="3" lg="4" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
			<CCol md="2" lg="1" className="text-start px-0">{uBahasaObj.caption_akunkas||"Kas Akun"}</CCol>
			<CCol md="1" lg="2" className="">{uBahasaObj.word_nilai||"word_nilai"} (Rp)</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
		</th>
		</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tanggal,tgl_input,gl_caption,username,status_jurnal,
				keterangan,noref,nilai,init_expired,kas_caption
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (UFunc.HariAngka(tanggal)+" "+gl_caption).toUpperCase();

			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tanggal) 
				: (UFunc.TglAngka(tanggal)).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vNamaGL= vKeyword==="" ? (gl_caption||"") 
				: (gl_caption||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vKascaption= vKeyword==="" ? (kas_caption||"UNDF") 
				: (kas_caption||"UNDF").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vUsername= vKeyword==="" ? UFunc.Usercaptionfilter(username||"") 
				: UFunc.Usercaptionfilter(username||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			let vKeterangan= vKeyword==="" ? (keterangan||"") 
				: (keterangan||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vNoref= vKeyword==="" ? (noref||"") 
				: (noref||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: (UFunc.WaktuAngka(tgl_input)).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			
			vKeterangan		= vKeterangan===""&&vNoref==="" ? "-" : vKeterangan;

			const vColorstatustembak= (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";
			const vISInputexpired 	= (init_expired||"YA")==="YA"
				//(UFunc.toDatetime(tgl_input)).getFullYear() < vTanggalnowDT.getFullYear();

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>
				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="3" lg="2" className="px-1 text-start d-none d-md-block">
					{UFunc.renderHTML(vTanggal)}
					{(uTokenObj.userhak === "FREEROLE") && (<>
						<span className="mx-1">&middot;</span>
						<span className="classfontsmaller"><small>({id})</small></span>
					</>)}

					<div className="classfontsmaller text-danger">
					<small>[
						{(tgl_input||"")!=="" && (
						<>
						{UFunc.renderHTML(vTglInput)}
						<span className="mx-1">&middot;</span>
						</>
						)}
						{UFunc.renderHTML(vUsername.trim().toUpperCase())}
					]
					</small>
					</div>
				</CCol>

				<CCol md="2" className="px-1 text-start">
					<div className=" d-flex justify-content-between align-items-start">
					<span className="text-info d-md-none d-lg-block">{UFunc.renderHTML(vNamaGL)}</span>
					<small className="text-info d-none d-md-block d-lg-none">{UFunc.renderHTML(vNamaGL)}</small>

					{(uIsAllowedhapus && uIsEOMExecuted && !vISInputexpired) && (
					<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm" className="p-0"
							color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_hapusdata)}
						</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>
					)}
					</div>

					{((status_jurnal||"")!=="" && uTokenObj.userhak==="FREEROLE") && (
					<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
					</div>
					)}
				</CCol>

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_tanggal}</CCol>
				<CCol xs="7" className="px-1 text-md-start d-md-none">
					{UFunc.renderHTML(vTanggal)}
					{(uTokenObj.userhak === "FREEROLE") && (<>
						<span className="mx-1">&middot;</span>
						<span className="classfontsmaller"><small>({id})</small></span>
					</>)}
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal||"-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>
				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_keterangan}</CCol>
				<CCol xs="8" md="3" lg="4" className="px-1 text-md-start">
					<small className="fst-italic">{UFunc.renderHTML(vKeterangan)}</small>
					<div className="d-none d-md-block">
						{(noref||"").trim()!=="" && (
						<>
						{"No.Ref: "}
						<b className="text-uppercase">{UFunc.renderHTML(vNoref)}</b>
						</>
						)}
					</div>
				</CCol>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_akunkas}</CCol>
				<CCol xs="8" md="2" lg="1" className="px-1 px-lg-0 text-md-start">
					<small>{UFunc.renderHTML(vKascaption)}</small>
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_noref}</CCol>
				<CCol xs="7" className="px-1 d-md-none">
					<b>{UFunc.renderHTML((vNoref||"-").toUpperCase())}</b>
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Input By"}</CCol>
				<CCol xs="7" className="px-1 text-md-start d-md-none">
					<div className="classfontsmaller text-danger">
					<small>[
						{(tgl_input||"")!=="" && (
						<>
						{UFunc.renderHTML(vTglInput)}
						<span className="mx-1">&middot;</span>
						</>
						)}
						{UFunc.renderHTML(vUsername.trim().toUpperCase())}
					]
					</small>
					</div>
				</CCol>

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_nilai} (Rp)</CCol>
				<CCol xs="7" md="1" lg="2" className="px-1 text-success">
					<b className="d-md-none d-lg-block">{UFunc.formatAngka(nilai)}</b>
					<small className="d-none d-md-block d-lg-none">{UFunc.formatAngka(nilai)}</small>
				</CCol>

				<CCol md="1" className="px-1 d-none d-md-block text-md-center">
					{(uIsAllowedhapus && uIsEOMExecuted && !vISInputexpired) ? (
					<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer classikontabel classikonhapus"
						onClick={()=>hdlKlikHapus(id)} />
					</CTooltip>
					) : (<>&middot;</>)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	//---END Init_LOAD--/

	//---API_FUNC--/
	const apiLoaddata=(_PAGE)=>{
		uFuncname 	= apiLoaddata.name;
		_PAGE		= parseInt(_PAGE)||1;
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_omzetlain || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_omzetlain/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg,+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProseshapus=()=>{
		uFuncname 	= apiProseshapus.name;
		if(uTabelIDPilih <= 0) {showToast("[0] ERROR !"); return;}
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		console.log("("+uComponentname+"-"+uFuncname+") vObjdx : "+vObjdx);

		uDatamainArr.splice(vObjdx,1);
		setTabelRowSelect(-1);
		setJmlData((parseInt(uDatamainArr.length)||0));
		setEfekview(!uEfekview);
		hdlToggleForm();
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_kethapus : uInpKethapus,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_omzetlain/pr_hapus";

		setLoading(true);
		uElBtnDlgsimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnDlgsimpan.disabled = false;

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
				hdlToggleForm();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtnDlgsimpan.disabled = true;
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnDlgsimpan.disabled = false;
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

		return()=>{
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]); 
		}
	},[]);
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;

		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//*/
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setJmlData(0);
		setDatamainArr([]);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		
		if(uHTMLError500 || !uIsEOMExecuted) return;

		uNavigate("/subkeuangan/omzetlain/forminput");
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!uIsShowDlghapus) { initFormAwal(); return; }

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setElBtnDlgsimpan(document.getElementById("btnDialogSimpan"));
		},350);
	},[uIsShowDlghapus]);
	React.useEffect(()=>{
		if(!uElJmldata) return;
		initJmldata()
	},[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg mb-3">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_omzetlain || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block">{uBahasaObj.caption_rekapinput||"Rekap Input"}</span>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyFormHapus
			value={uInpKethapus}
			onCHvalue={(e)=>setInpKethapus(e.target.value)}
			onSimpan={()=>hdlKlikSimpanHapus()}
			show={uIsShowDlghapus} 
			dialogHeader={uDlghapusheader}
			toggle={hdlToggleForm}/>
		</>
	)//>
}
	
export default Omzetlain;
