import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CHeaderBrand,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
	CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import NumberFormat from 'react-number-format';
import {
	MyButton,
	MyFormHapus,
	MyProfileDetil,
	MyPage500,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Hutangjplpreview = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props;

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uUserSettingObj			= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uIsEOMExecuted			= useSelector(state => state.gIsEOMExecuted);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uFirstload,setFirstload]	= React.useState("YA");
	const [uEfekreset,setEfekreset]	= React.useState(false);
	const uMaxData					= isMobile ? (uUserSettingObj.jml_mobile || 30) : (uUserSettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHTMLError500,setHTMLError500]	= React.useState();

	const [uInpKeyword,setInpKeyword]		= React.useState("");
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uDatamainArr,setDatamainArr]		= React.useState([])
	const [uTimeelapsed,setTimeelapsed]		= React.useState(0);

	//--DLG_FORM--/
	const [uDlghapusObj,setDlghapusObj]		= React.useState({})
	const [uISDlghapusshow,setDlghapusshow]	= React.useState(false)
	//--END DLG_FORM--/

	//---HANDEL--/
	const hdlKDownKeyword = (_EV) => {
		if(_EV.keyCode === 13) {
			_EV.preventDefault(); _EV.stopPropagation();
			document.getElementById("btnCari") && 
				document.getElementById("btnCari").click();
			return;
		}
	}
	const hdlKlikKeywordcari = () => {
		uKeywordObj.kwd_hutangjplpreview = uInpKeyword.trim(); 
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		setEfekreset(true);
	}
	const hdlKlikReset = async() => {
		if(uInpKeyword.trim() !== "") 
			if(await Konfirm("Apakah Anda Yakin ?")) {
				setInpKeyword("");
				uKeywordObj.kwd_hutangjplpreview = undefined;
				localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
				setEfekreset(true);
				return;
			}

		setEfekreset(true);
	}
	const hdlKlikHapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		var vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);

		const vSisaHutang	= (parseInt(uDatamainArr[vArridx].nilai_hutang)||0)
			- (parseInt(uDatamainArr[vArridx].terbayar)||0);
		const vHeadercaption= "Hapus Pembayaran: "+
			(uDatamainArr[vArridx].nama_supplier||"").toUpperCase()+
			"";
		uDlghapusObj.headers 	= vHeadercaption;
		setDlghapusshow(true);
	}
	const hdlKlikDlghapussimpan = async() => {
		if(parseInt(uTabelIDPilih) <= 0) return;

		if((uDlghapusObj.ket_hapus||"").trim().length < 3) {
			document.getElementById("inpkethapus") && 
				document.getElementById("inpkethapus").focus();
			showToast("Keterangan Hapus Harus Diisi !"+
				"<br/>Minimal 3 Digit.."+
				""); return;
		}

		apiProseshapus();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentMainheader = () => {
		if(uHTMLError500) return (<b>Page 500</b>)

		return (
		<>
			<small className="text-primary">
				Rekap Pembayaran
				<span className="d-md-none fw-bolder">
					<span className="mx-1">&middot;</span>
					{(()=>{
						const vKeyword = uKeywordObj.kwd_hutangjplpreview || "";
						if(vKeyword.length < 3) {
							return (<>&#8734;</>)
						}

						return "("+UFunc.formatAngka(uJmlData)+")"
					})()}
				</span>
			</small>

			<div className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapsed)}ms</small>
			</div>
		</>
		)
	}
	const contentMain = () => {
		if(uHTMLError500) return (
			<div className="py-5 px-2">{UFunc.renderHTML(uHTMLError500)}</div>
		)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_hutangjplpreview || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.caption_pihaklain||"Pihak Lain"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.caption_akunkas||"Kas Akun"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}<small className="d-block">(Rp)</small></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>
			<tr className="d-md-none classfontsmaller">
			<th width={25} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,nama_supplier,tgl_bayar,nilai_bayar,tgl_input,userinput,no_nota,
				ket,is_loading,kas_caption,init_expired,status_jurnal
			} = vItems;

			vNumber++;
			const vCaptionUpper	= ((nama_supplier||"")+
				((no_nota||"")!=="" ? (": "+no_nota) : "")).toUpperCase();

			const vNamaSupplier	= vKeyword==="" ? UFunc.capitalize(nama_supplier||"") 
				: (UFunc.capitalize(nama_supplier||"")).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vKeterangan = vKeyword==="" ? (ket||"-") 
				: (ket||"-").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vTglbayar = vKeyword==="" ? UFunc.TglAngka(tgl_bayar) 
				: UFunc.TglAngka(tgl_bayar).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vTglinput = vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: UFunc.WaktuAngka(tgl_input).replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vNonota = vKeyword==="" ? (no_nota||"") 
				: (no_nota||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vUserinput = vKeyword==="" ? UFunc.Usercaptionfilter(userinput||"") 
				: UFunc.Usercaptionfilter(userinput||"").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vKascaption = vKeyword==="" ? (kas_caption||"Undf Kas") 
				: (kas_caption||"Undf Kas").replace(vReplace,"<b><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			
			const vColorstatustembak = (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>
				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="px-1 d-none d-md-block text-md-start">
					<div>
					{UFunc.renderHTML(vTglbayar)}
					{uTokenObj.userhak==="FREEROLE" && (<span className="classfontsmaller"><small> &middot; ({id})</small></span>)}
					</div>	

					<div className="text-danger classfontsmaller">
						<small>[
						{(tgl_input||"")!=="" && (
						<>
							{UFunc.renderHTML(vTglinput)}
							<span className="mx-1">&middot;</span>
						</>
						)}
						{UFunc.renderHTML(vUserinput)}
						]</small>
					</div>
				</CCol>

				<CCol xs="12" md="2" className="px-1 text-start d-flex justify-content-between">
					<div>
					<div className="text-info">{UFunc.renderHTML(vNamaSupplier)}</div>
					
					{((status_jurnal||"")!=="" && uTokenObj.userhak==="FREEROLE") && (
					<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot;&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;&middot;</small>
					</div>
					)}
					</div>

					{(uIsEOMExecuted && uIsHapusallow && (init_expired||"TIDAK")==="TIDAK") && (
					<div className="d-md-none">
					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
						<CLink onClick={()=>hdlKlikHapus(id)}
							className="classcursorpointer classikontabel classikonhapus"/>
					)}
					</div>
					)}
				</CCol>
				<CCol xs="12" className="px-1 mt-1 d-md-none text-start classfontsmaller">
					<small className="fst-italic">{UFunc.renderHTML(vKeterangan)}</small>
				</CCol>

				<CCol xs="12" className="classborderbottom px-1 my-3 d-md-none"/>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_tglbayar||"Tgl Bayar"}</CCol>
				<CCol xs="8" className="px-1 d-md-none">
					{UFunc.renderHTML(vTglbayar)}
					{uTokenObj.userhak==="FREEROLE" && (<span className="classfontsmaller"><small> &middot; ({id})</small></span>)}
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start classfontsmaller">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<small>&middot;&middot; <b>{status_jurnal}</b> &middot;&middot;</small>
				</CCol>
				</>
				)}

				<CCol xs="3" className="px-1 text-start d-none classfontsmaller">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
				<CCol xs="9" md="3" className="px-1 text-md-start d-none d-md-block">
					{((no_nota||"")==="" || (ket||"")!=="") && (
						<small className="fst-italic">{UFunc.renderHTML(vKeterangan)}</small>
					)}

					{(no_nota||"")!=="" && (
						<small className="d-none d-md-block">
						Nota: <b>{UFunc.renderHTML(vNonota)}</b>
						</small>
					)}
				</CCol>

				{(no_nota||"")!=="" && (
				<>
				<CCol xs="5" className="px-1 text-start d-md-none classfontsmaller">{uBahasaObj.word_nonota||"No.Nota"}</CCol>
				<CCol xs="7" className="px-1 d-md-none fw-bolder classfontsmaller">
					{UFunc.renderHTML(vNonota)}
				</CCol>
				</>
				)}

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_akunkas||"Akun Kas"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-start">
					<b>{UFunc.renderHTML(vKascaption)}</b>
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none classfontsmaller">{uBahasaObj.word_inputby||"Inputby"}</CCol>
				<CCol xs="8" className="px-1 d-md-none classfontsmaller text-danger">
					<small>[
						{(tgl_input||"")!=="" && (
						<>
							{UFunc.renderHTML(vTglinput)}
							<span className="mx-1">&middot;</span>
						</>
						)}
						{UFunc.renderHTML(vUserinput)}
					]</small>
				</CCol>

				<CCol xs="12" className="classborderbottom px-1 my-3 d-md-none"/>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_nilaibayar||"N.Bayar"} (Rp)</CCol>
				<CCol xs="7" md="2" className="px-1 text-primary">
					{UFunc.formatAngka(nilai_bayar)}
				</CCol>

				<CCol md="1" className="px-1 d-none d-md-block text-center">
					{(uIsEOMExecuted && uIsHapusallow && (init_expired||"TIDAK")==="TIDAK") && (
					<>
					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
						<CTooltip content={"--Hapus Data: "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikHapus(id)}
							className="classcursorpointer classikontabel classikonhapus"/>
						</CTooltip>
					)}
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = (_PAGE) => {
		_PAGE		= parseInt(_PAGE) || 1;
		setHTMLError500();

		const vKeyword 		= uKeywordObj.kwd_hutangjplpreview || "";
		const vHandelview	= (uEfekreset===true || uFirstload==="YA") ?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);
		//const vKasGLDefaultArr = JSON.parse(localStorage.getItem("kasgllist"));

		/*//--TESTING_FRONTEND--/
		console.log("(Hutangjpl-apiLoaddata) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangjpl/ld_databayar";

		setTimeelapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("(Hutangjplpreview-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Hutangjplpreview - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Hutangjplpreview - apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProseshapus = () => {
		if(uTabelIDPilih <= 0) {showToast("[0] ERROR !"); return;}
		const vArridx= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);

		const vElBtnsimpan = document.getElementById("btnDialogSimpan");
		vElBtnsimpan && (vElBtnsimpan.disabled = true);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		console.log("("+cComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);

		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_kethapus : uDlghapusObj.ket_hapus||"",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_hutangjpl/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElBtnsimpan && (vElBtnsimpan.disabled = false);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setTabelRowSelect(-1);
				setJmlData(uJmlData-1);
				setDlghapusshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Hutangjplpreview - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElBtnsimpan && (vElBtnsimpan.disabled = false);
			setLoading(false);
			console.log("(Hutangjplpreview - apiProseshapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		//setDlgviewshow(false);
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
		apiLoaddata();

		return () => {
			setDatamainArr([]);
			setInpKeyword("");
			setJmlData(0);
			setPageActive(1);setTabelRowSelect(-1);setTabelIDPilih(0);
			setDlghapusshow(false);
		}
	},[])
	React.useEffect(()=>{
		if(!uEfekreset) return;

		setEfekreset(false);
		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uEfekreset]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);
	},[uJmlData]);
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		/*const vDatapageArr = uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}//-*/

		apiLoaddata(uPageActive);
	},[uPageActive]);
	React.useEffect(()=>{ 
		if(!uISDlghapusshow)
			{ setDlghapusObj({}); return; }
	},[uISDlghapusshow]);
	//---END EFFECT--/

	//console.log("(Hutangjplpreview) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={()=>uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">Preview H. TJPL</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white m-0 px-2" style={{minHeight:52}}>
			<div className="d-none d-lg-block classikon classikonedit"/>
			<div className="d-flex align-items-center mx-auto mx-lg-0">
				<CForm>
				<CInputGroup size="sm">
				<CFormInput size="sm" 
					value={uInpKeyword} 
					onChange={(e) => setInpKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKDownKeyword(e)} 
					style={{minWidth:270,width:"auto"}} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} 
					id="inpkeyword"/>
				<CButton size="sm" color="light"
					className="border"
					onClick={()=>hdlKlikKeywordcari()} 
					id="btnCari">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
				</CForm>

				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
				<span className="d-none d-md-inline">
					<span className="mx-1 me-2">&middot;</span>
					<b className="me-1">{(()=>{
						const vKeyword = uKeywordObj.kwd_hutangjplpreview || "";
						if(vKeyword.length < 3) {
							return (<>&#8734;</>)
						}

						return "("+UFunc.formatAngka(uJmlData)+")"
					})()}
					</b>Data
				</span>
			</div>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">
		<CContainer xxl className="px-0">
			<CCard className="mb-3 classcardbg">
			<CCardHeader className="d-flex justify-content-between">
				{contentMainheader()}
			</CCardHeader>
			
			<CCardBody className="px-0">{contentMain()}</CCardBody>
			</CCard>
		</CContainer>
		</div>
		</div>

		<MyFormHapus
			value={uDlghapusObj.ket_hapus||""}
			onCHvalue={(e)=>{uDlghapusObj.ket_hapus = e.target.value;setEfekview(!uEfekview)}}
			onSimpan={hdlKlikDlghapussimpan}
			show={uISDlghapusshow} 
			dialogHeader={uDlghapusObj.headers||""}
			toggle={()=>setDlghapusshow(false)}/>
		</>
	) 
}	

export default Hutangjplpreview