import React from 'react'
//import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CTooltip,
	CLink,
	CImage,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
//import { UFunc } from '../../helpers/functions'
//import { Konfirm } from '../../helpers/onConfirm';
import {
	MyButton,
} from '../../components/index'

const pjson 	= require('../../../package.json')
const Headersublapbukubesar = (props) => {
	/*const {
		setToken,setLoading,showToast 
	} = props//-*/

	//--DOM--/
	//const uNavigate 		= useNavigate();
	const uDispatch 		= useDispatch();
	//--END DOM--/

	const uActiveroute		= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uEfekapp			= useSelector(state => state.effectApp);
	//const [uEfekview,setEfekview]= React.useState(false);

	//--HANDEL--/
	const hdlKlikView = () => {
		const vHeaderTmpObj	= uHeaderActionObj;
		//uDispatch({type: "set", gInitHeaderAction: {}});//-->HARUS_DIKOSONGIN_DULU_BIAR_ADA_EFFECT--/

		vHeaderTmpObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: vHeaderTmpObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
		//setEfekview(!uEfekview);
	}
	const hdlKlikReset = async() => {
		const prosesReset = () => {
			const vHeaderTmpObj	= uHeaderActionObj;
			vHeaderTmpObj.isHeaderReset = true;
			uDispatch({type: "set", gInitHeaderAction: vHeaderTmpObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		prosesReset(); return;
	}

	//console.log("Headersublapbukubesar - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
				className="me-2"
				height={30}/>
			<small><i>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||"List Data"}</i></small>
		</div>

		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<MyButton onClick={()=>hdlKlikView()} 
				className="py-1"
				id="btnFilter">
			<CIcon icon="cilApplications" className="me-2"/>
			Filter
			</MyButton>

			<span className="d-none d-md-block ms-1">
			<span className="mx-1 d-none d-lg-inline">&middot;</span>

			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
			</CTooltip>

			<span className="d-none d-md-inline me-2">&middot;</span>
			<b id="idjmldata">0</b> {uBahasaObj.word_data||"word_data"}
			</span>
		</div>
		</>
	)
}

export default Headersublapbukubesar
