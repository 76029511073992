import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CForm,
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CButton,
  CImage,
  CCard,
  CCardHeader,
  CCardBody,
  CAvatar,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Role 		= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uSettingObj			= useSelector(state => state.gListUserSetting);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 		= uHeaderActionObj.isHeaderTambah || false;

	const [uLoadinglokal,setLoadinglokal]= React.useState(false);
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatamainObj,setDatamainObj]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDialogHeader,setDialogHeader]	= React.useState("");

	const [ElBtnSimpan,setElBtnSimpan]= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlChangerole = (_IDTABEL,_EV) => {
		_IDTABEL= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return
		const vIDrole 	= parseInt(_EV.target.value)||0;
		//const vDatamenuArr 	= uDatamainObj.menu_arr || [];

		console.log("_EV.target.checked => "+_EV.target.checked);
		setTabelIDPilih(_IDTABEL);
		//const vObjdx	= UFunc.getObjectIndeks(vDatamenuArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		apiProsesrole(_IDTABEL,vIDrole,_EV.target.checked)
	}
	//---END HANDLE--/

	//---PROSES--/
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";
		if(uLoadinglokal) return (
			<div className="p-md-5 text-center text-danger classblinking">
			Loading..
			</div>
		)

		const vDatamenuArr 	= uDatamainObj.menu_arr || [];
		const vDataroleArr 	= uDatamainObj.role_arr || [];

		if(vDatamenuArr.length <= 0) return (
			<div className="p-md-5 text-center text-primary fst-italic">
			Maaf, Data Tidak Ada..
			</div>
		)

		const vWidthcol		= Math.round((100-25-3) / (vDataroleArr.length))
		return (
		<CForm>
		<table className="table table-borderless table-striped" id="idtabeldata">
		<thead className="text-center classborderbottom">
			<tr>
			<th width="3%" className="align-middle text-end px-1">&nbsp;</th>
			<th width="25%" className="align-middle px-1 text-start">
				Nama Menu
			</th>
			{vDataroleArr.map((vItems,vKeys)=>{
				return (
				<th width={vWidthcol+"%"} key={vKeys} className="align-middle px-0">
				<CAvatar src={pjson.homepage+"api/images/img_"+((vItems.hak||"").toLowerCase())+".png"} size="sm"/><br/>
				<small className="classfontsmaller">{uBahasaObj["hakuser_"+(vItems.hak||"").toLowerCase()] || (vItems.hak||"")}</small>
				</th>
				)
			})}
			</tr>
		</thead>
		<tbody>
		{vDatamenuArr.map((vItems,vKeys)=>{
			const {
				id,nama,levels
			} = vItems;
			const vLevels 	= parseInt(levels)||1;
			const vClassadd = vLevels < 2 ? "fw-bold text-uppercase"
				: "ps-3 "+(vLevels > 2 ? "ps-5 classfontsmaller " : "text-info "); 

			return (
			<tr id={"idtr"+id} className={"classborderbottom"+(uTabelRowSelect===vKeys?" classrowselect":"")}
				 key={vKeys}>
			<td className="align-middle text-end px-1">&nbsp;</td>
			<td className={"align-middle text-start px-0 "+vClassadd}>
				{uBahasaObj["menus_"+(nama||"").toLowerCase()]||(nama||"")}
			</td>
			{vDataroleArr.map((vItemrole,vKeyrole)=>{
				const vRoleidArr = vItemrole.menuid_arr || [];
				//vRoleidArr.map(vItemroleid=> vItemroleid = parseInt(vItemroleid));

				//checked={vRoleidArr.includes( parseInt(vItems.id||"0") )}
				return (
				<td key={vKeyrole} className="px-1 text-center">
				<CFormSwitch size="lg"
					className="d-inline-block"
					value={vItemrole.role_id}
					onChange={(e)=>hdlChangerole(id,e)}
					checked={vRoleidArr.indexOf((id)) >= 0}
					name="inproles"
					id={"inprole"+id+"_"+vItemrole.role_id}/>
				</td>
				)
			})}
			</tr>
			)
		})}
		</tbody>
		</table>
		</CForm>
		)
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata=()=>{
		setHTMLError500();
		setDatamainObj({});
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setLoadinglokal(true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= {
			menu_arr: [
				{id:"1",nama:"menu1",levels:1},
				{id:"2",nama:"menu2",levels:2},
				{id:"3",nama:"menu3",levels:3},
				{id:"4",nama:"menu4",levels:3},
				{id:"5",nama:"menu5",levels:1},
				{id:"6",nama:"menu6",levels:2},
				{id:"7",nama:"menu7",levels:3},
				{id:"8",nama:"menu8",levels:3},
			],
			role_arr:[
				{id:"1",hak:"ADMIN",menuid_arr:[1,2,3,4,5,6,7,8],},
				{id:"2",hak:"ENTRI",menuid_arr:[5,6,7,8],},
			]
		}
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout); setLoading(false);
			setLoadinglokal(false);

			setDatamainObj(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_role/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoadinglokal(false);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Role-apiLoaddata) output_string.dataobject : "+JSON.stringify(output_string.dataobject));
				const vDatamainObj = JSON.parse(output_string.dataobject || "{}");
				setDatamainObj(vDatamainObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Role-apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoadinglokal(false);
			setLoading(false);
			console.log("(Role-apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesrole = (_IDTABEL,_IDROLE,_STATECHECKED) => {
		_IDTABEL= parseInt(_IDTABEL)||0;
		_IDROLE	= parseInt(_IDROLE)||0;
		_STATECHECKED	= _STATECHECKED || false;
		//console.log("(Role - apiProsesrole) _STATECHECKED : "+_STATECHECKED);

		//const vDatamenuArr	= uDatamainObj.menu_arr || [];
		const vDataroleArr	= uDatamainObj.role_arr || [];
		const vIdxrole 		= UFunc.getObjectIndeks(vDataroleArr,_IDROLE,"role_id");
		const vEls 			= document.getElementsByName("inproles");
		vEls && (vEls.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout); setLoading(false);
			vEls && (vEls.disabled = false);

			if(_STATECHECKED)
				(vDataroleArr[vIdxrole].menuid_arr).push(_IDTABEL.toString());
			else {
				console.log("(Role - apiProsesrole) LEWAT");
				const vIdxrolwid	= (vDataroleArr[vIdxrole].menuid_arr).indexOf(_IDTABEL);
				(vDataroleArr[vIdxrole].menuid_arr).splice(vIdxrolwid,1);
			}

			setEfekview(!uEfekview)
		},2500); return;//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_roleid : _IDROLE,
			send_statuschecked : _STATECHECKED ? "TAMBAH" : "KURANG",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_role/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			vEls && (vEls.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				//console.log("(Role - apiProsesrole) _STATECHECKED : "+_STATECHECKED);
				if(_STATECHECKED) {
					console.log("(Role - apiProsesrole) LEWAT 1");
					(vDataroleArr[vIdxrole].menuid_arr).push(_IDTABEL.toString());
				} else {
					console.log("(Role - apiProsesrole) LEWAT 2");
					const vIdxrolwid	= (vDataroleArr[vIdxrole].menuid_arr).indexOf(_IDTABEL.toString());
					(vDataroleArr[vIdxrole].menuid_arr).splice(vIdxrolwid,1);
				}

				setEfekview(!uEfekview)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Role - apiProsesrole) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEls && (vEls.disabled = false);
			setLoading(false);

			console.log("(Role - apiProsesrole) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoaddata();
		return () => { 
			setDatamainObj({}); 
			uDispatch({type: "set", gStatusError500: undefined});
		}
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		const vStatus500 = uHTMLError500 ? true : false;
		uDispatch({type: "set", gStatusError500: vStatus500});
	},[uHTMLError500]);
	
	//console.log("Role - uActiveroute => "+(uActiveroute));

	//if((uTokenObj.userinit||"") === "") 
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return (<></>); }

	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )

	return (
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between">
			<b>Atur Role User</b>
			<small className="text-muted classfontsmaller">{UFunc.formatAngka(uTimeElapsed)}ms</small>
		</CCardHeader>
		<CCardBody className="px-0">
			<div className="d-md-none px-5 fst-italic">
			<b>Maaf</b>,
			<br/><br/>Fitur Ini Hanya Terbatas dalam Mode Tampilan Tertentu saja.. 
			<br/><br/>Mohon Ubah Mode Tampilan Anda dalam Mode HORIZONTAL, atau menggunakan PC / Laptop..
			</div>
			<div className="d-none d-md-block">{contentMain()}</div>
		</CCardBody>
		</CCard>
	)
}	

export default Role