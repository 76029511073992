import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CHeaderBrand,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CFormSelect,
	CFormCheck,
	CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyButton,
	MyDialoglookupapi,
	MyProfileDetil,
	MyCollapsejadwal,
	MyDatepicker,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Pasienbooking = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 10) : (uSettingob.jml_laporan || 100);
	const uKeywordobj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const uDataLayananArr				= JSON.parse(localStorage.getItem("layananlist")||"[]");
	const [uDatamainObj,setDatamainObj]	= React.useState({})
	const [uDatapasienArr,setDatapasienArr]	= React.useState([]);
	const [uISProcessing,setProcessing]	= React.useState(false)
	const [uISFieldupdate,setFieldupdate]= React.useState(false);

	const [uISShowjadwal,setShowjadwal]	= React.useState(false);
	const [uLoadingjml,setLoadingjml]	= React.useState(false);
	const [uDatajumlahArr,setDatajumlahArr]= React.useState([]);

	//--DLG_LOOKUP--/
	const [uDlgLookupheader,setDlgLookupheader]	= React.useState("")
	const [uInpLookupkwd,setInpLookupkwd]		= React.useState("")
	const [uDlgLookuptrigger,setDlgLookuptrigger]= React.useState(false)
	const [uDlgLookupshow,setDlgLookupshow]		= React.useState(false)
	//--END DLG_LOOKUP--/

	const uBtnSimpanmain	= document.getElementById("btnSimpanmain");

	const uStatusantarArr				= [
		(uBahasaObj.status_antarsendiri||"Antar Sendiri"),
		(uBahasaObj.status_antarjemput||"Antar JEMPUT"),
		(uBahasaObj.status_antarhadir||"Sudah di Klinik"),
		(uBahasaObj.status_antarlainnya||"Lainnya"),
	]

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {
		//console.log("_EV.which === "+_EV.which);
		if(_EV.which === 120) {
			_EV.preventDefault(); _EV.stopPropagation();

			uBtnSimpanmain && (uBtnSimpanmain.click());
			return;
		}
	}
	const hdlKlikBack = async() => {
		const vNextfunction = () => { uNavigate(-1) }

		if(uISFieldupdate) {
			if(await Konfirm(
				"<b>Data Form Ada Yang Berubah dan Belum Disimpan</b>.."+
				"<br/>Data tersebut Akan HILANG dan Tidak Dapat Dikembalikan !"+
				"<br/><br/>Apakah Anda Yakin ?"+
				"")) vNextfunction();
		} else vNextfunction();
	}
	const hdlKlikReset = async() => {
		//console.log("(hdlKlikReset) uDatamainObj => "+JSON.stringify(uDatamainObj));
		//console.log("(hdlKlikReset) uDatamainObj.customer_toggle => "+(uDatamainObj.customer_toggle));
		const vReset = () => {
			setDatamainObj({}); setEfekview(!uEfekview);
		}

		if(uISFieldupdate) {
			if(await Konfirm(
				"<b>Data Form Ada Yang Berubah dan Belum Disimpan</b>.."+
				"<br/>Data tersebut Akan DIRESET dan Tidak Dapat Dikembalikan !"+
				"<br/><br/>Apakah Anda Yakin ?"+
				"")) vReset();
		} else vReset();
	}
	const hdlKDownCustomer = (_EV) => {
		if(_EV.keyCode !== 13) {
			initResetcustomer()
			return;
		}
		if((uDatamainObj.customer_caption||"")==="") return;

		_EV.preventDefault();
		_EV.stopPropagation();

		setDlgLookuptrigger(true)
		hdlKlikCustomerlookup();
	}
	const hdlKlikCustomerlookup = () => {
		setDlgLookupheader("Lookup: "+(uBahasaObj.word_customer||"Customer"));
		setInpLookupkwd(uDatamainObj.customer_caption||"");
		setDlgLookupshow(true);
	}
	const hdlChangePasien = (_EV) => {
		const vPasienselectArr  = uDatamainObj.pasien_arr || [];
		const vValue			= (_EV.target.value || "").toString();
		const vIdx = vPasienselectArr.findIndex(vItems => vItems.toString() === vValue);
		/*console.log("(hdlChangePasien) vPasienselectArr 1 => "+JSON.stringify(vPasienselectArr))
		console.log("(hdlChangePasien) _EV.target.checked => "+(_EV.target.checked))
		console.log("(hdlChangePasien) vValue => "+(vValue))
		console.log("(hdlChangePasien) vIdx => "+(vIdx))//-*/
		if(_EV.target.checked) {
			if(vIdx < 0) vPasienselectArr.push(vValue)
		} else {
			vPasienselectArr.splice(vIdx,1)
		}
		//console.log("(hdlChangePasien) vPasienselectArr 2 => "+JSON.stringify(vPasienselectArr))
		uDatamainObj.pasien_arr = vPasienselectArr;
		setEfekview(!uEfekview);
	}
	const hdlToggleDlglookup = () => { setDlgLookupshow(false) }
	const hdlKlikDlglookuppilih = (_SELECTEDSTR) => {
		_SELECTEDSTR 		= _SELECTEDSTR||"{}";
		const vLookupObj	= JSON.parse(_SELECTEDSTR);
		if(vLookupObj==={}) return;

		uDatamainObj.customer_id 	= (vLookupObj.id || "0");
		uDatamainObj.customer_caption = (vLookupObj.caption);
		uDatamainObj.customer_nama 	= (vLookupObj.nama_customer || "");
		uDatamainObj.customer_alamat= ((vLookupObj.alamat));
		uDatamainObj.customer_telp 	= (vLookupObj.telp);

		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			const vElfocus	= document.getElementById("inpcustomeralamat");
			vElfocus && vElfocus.focus();
		},350);
	}
	const hdlKlikSimpanmain = () => {
		let vElfocus = document.getElementById("inpcustomernama");
		if(uDatamainObj.customer_toggle !== "LAMA") {
			if((uDatamainObj.customer_nama||"")==="") {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.caption_namacustomer)+" "+(uBahasaObj.caption_mustfilled)+" !"); return;
			}
		} else {
			vElfocus = document.getElementById("inpcustomercaption");
			if(parseInt(uDatamainObj.customer_id||"0") <= 0) {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.word_customer)+" "+(uBahasaObj.caption_mustchoose)+" !"); return;
			}
		}

		vElfocus = document.getElementById("inpcustomeralamat");
		if((uDatamainObj.customer_alamat||"")===""
			&& (uDatamainObj.customer_telp||"")===""
			) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.word_alamat)+" / "+(uBahasaObj.caption_telphp)+" "+(uBahasaObj.caption_mustfilled)+" !"); return;
		}

		vElfocus = document.getElementById("inptglbooking");
		if((uDatamainObj.tgl_booking||"")==="") {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_tglbooking)+" "+(uBahasaObj.caption_invalid)+" !"); return;
		}
		const vTglbookingDT	= UFunc.toDate(uDatamainObj.tgl_booking);
		if(UFunc.DateIsMundur(vTglbookingDT)){
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_tglbooking)+" "+(uBahasaObj.caption_hasexpired)+" !"); return;
		}
		vElfocus = document.getElementById("inpjenislayanan");
		if(parseInt(uDatamainObj.layanan_id||"0") <= 0) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_jenislayanan)+" "+(uBahasaObj.caption_mustchoose)+" !"); return;
		}
		vElfocus = document.getElementById("inpstatus");
		if((uDatamainObj.status_antar||"")==="") {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.caption_statusantar)+" "+(uBahasaObj.caption_mustchoose)+" !"); return;
		} else if((uDatamainObj.status_antar||"")===(uBahasaObj.status_antarlainnya||"Lainnya")) {
			if((uDatamainObj.status_antarlainnya||"")==="") {
				vElfocus && vElfocus.focus();
				showToast((uBahasaObj.caption_statusantar)+": "+(uBahasaObj.status_antarlainnya)+" "+(uBahasaObj.caption_mustfilled)+" !"); return;
			}
		}
		vElfocus = null;
		if((uDatamainObj.pasien_arr||[]).length <= 0) {
			vElfocus && vElfocus.focus();
			showToast((uBahasaObj.word_pasien)+" "+(uBahasaObj.caption_mustchoose)+" !"); return;
		}

		apiSimpanbooking();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const initResetcustomer = () => {
		uDatamainObj.customer_nama 	= undefined;
		uDatamainObj.customer_alamat= undefined;
		uDatamainObj.customer_telp 	= undefined;
		uDatamainObj.customer_id 	= undefined;
		uDatamainObj.pasien_arr		= undefined;
		setEfekview(!uEfekview);
	}
	const initDoneprocess = async() => {
		if(await Konfirm("Reservasi Booking Telah Disimpan.."+
			"<br/>Apakah Anda Kembali ke Halaman Sebelumnya ?"+
			"<br/><br/>(<b>Klik TIDAK untuk Tetap Berada di Form Reservasi</b>)"+
			"")) uNavigate(-1);
		else 
			{ setDatamainObj({}); setEfekview(!uEfekview); }
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentCustomer = () => {
		return (
		<CCard className="classcardbg">
		<CCardHeader><b>Data Klien (Customer)</b></CCardHeader>
		<CCardBody>
		<CForm>
		<CRow className="my-1">
			<CCol className={"px-md-1 pe-md-0 "+((uDatamainObj.customer_toggle||"LAMA")==="LAMA" ? "" : "text-danger")}>
				{uBahasaObj.caption_namacustomer||"Nama Klien/Customer"}
				{(uDatamainObj.customer_toggle||"LAMA")!=="LAMA" ? " BARU" : " Lama"}
			</CCol>
			
			<CCol md="8" lg="7" className="text-end">
			<CInputGroup size="sm">
			{(uDatamainObj.customer_toggle||"LAMA")==="LAMA" ? (
			<>
			<CFormInput size="sm"
				className="text-capitalize"
				value={uDatamainObj.customer_caption||""}
				onKeyDown={(e)=>hdlKDownCustomer(e)}
				onChange={(e)=>{uDatamainObj.customer_caption=e.target.value;setEfekview(!uEfekview)}}
				placeholder={(uBahasaObj.petunjuk_keywordcustomer||"Keyword Pasien LAMA")+".."}
				id="inpcustomercaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikCustomerlookup()}
				id="btncustomerlookup">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			<span className="ms-1"/>

			<CTooltip content="--Toggle Klien/Customer BARU">
			<CButton size="sm" color="secondary"
				onClick={()=>{uDatamainObj.customer_toggle = (uDatamainObj.customer_toggle||"LAMA")==="LAMA" ? "BARU" : "LAMA"; setEfekview(!uEfekview)}}
				id="btncustomertoggle">
				<CIcon icon="cibAddthis"/>
			</CButton>
			</CTooltip>
			</>
			) : (
			<>
			<CFormInput size="sm"
				className="text-capitalize"
				value={uDatamainObj.customer_nama||""}
				onChange={(e)=>{uDatamainObj.customer_nama=e.target.value;setEfekview(!uEfekview)}}
				placeholder={"Masukkan "+(uBahasaObj.caption_namacustomer||"Nama Customer")+".."}
				id="inpcustomernama"/>

			<CInputGroupText className="">
			<CTooltip content="--Toggle Klien/Customer LAMA">
			<CLink onClick={()=>{uDatamainObj.customer_toggle = (uDatamainObj.customer_toggle||"LAMA")==="LAMA" ? "BARU" : "LAMA"; setEfekview(!uEfekview)}}
				className="classcursorpointer">
				<CIcon icon="cilTouchApp" className="text-danger"/>
			</CLink>
			</CTooltip>
			</CInputGroupText>
			</>
			)}

			</CInputGroup>
			</CCol>
		</CRow>

		<CRow className="my-1">
			<CCol className="px-md-1">{uBahasaObj.word_alamat||"word_alamat"}</CCol>
			<CCol md="8" lg="7" className="text-end">
				<CFormTextarea size="sm"
					value={UFunc.br2nl(uDatamainObj.customer_alamat||"")}
					onChange={(e)=>{uDatamainObj.customer_alamat=e.target.value;setEfekview(!uEfekview)}}
					id="inpcustomeralamat"/>
			</CCol>
		</CRow>

		<CRow className="my-1">
			<CCol className="px-md-1">{uBahasaObj.caption_telphp||"caption_telphp"}</CCol>
			<CCol md="8" lg="7" className="text-end">
				<CFormInput size="sm"
					value={uDatamainObj.customer_telp||""}
					onChange={(e)=>{uDatamainObj.customer_telp=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"inpjenislayanan")}
					id="inpcustomertelp"/>
			</CCol>
		</CRow>
		</CForm>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentDatabooking = () => {
		return (
		<CCard className="classcardbg">
		<CCardHeader><b>Data Booking</b></CCardHeader>
		<CCardBody>
		<CForm>
		<CRow className="my-1 mx-0">
			<CCol xs="4" lg="5" className="px-0">{uBahasaObj.caption_tglbooking||"Tgl Booking"}</CCol>
			<CCol xs="7" lg="6" className="text-start">
				<MyDatepicker
					tglValue={uDatamainObj.tgl_booking_dt}
					onChangevalue={(date)=>{uDatamainObj.tgl_booking_dt = date; setEfekview(!uEfekview)}}
					id="inptglbooking"/>
			</CCol>
			<CCol xs="1" className="px-0">
				<CButton size="sm" color="dark"
					onClick={()=>setShowjadwal(!uISShowjadwal)}
					id="btnCekbooking">
					<CIcon icon="cilAddressBook"/>
				</CButton>
			</CCol>
		</CRow>

		<CRow className="my-1 mx-0">
			<CCol xs="4" lg="5" className="px-0">{uBahasaObj.caption_jenislayanan||"caption_jenislayanan"}</CCol>
			<CCol xs="7" lg="6" className="text-start">
				<CFormSelect size="sm"
					value={uDatamainObj.layanan_id}
					onChange={(e)=>{uDatamainObj.layanan_id=e.target.value;setEfekview(!uEfekview)}}
					id="inpjenislayanan">
				{uDataLayananArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.id} style={{color:"#"+vItems.color_layanan}} key={vKeys}>{vItems.caption_layanan}</option>
					); 
				})}
				</CFormSelect>
			</CCol>
			<CCol xs="1" className="px-0 text-center">
				{(uLoadingjml||false)===true ? (
				<CSpinner color="primary" size="sm"/>
				) : (
				<>
				{(()=>{
					if(uDatamainObj.layanan_id === undefined) return "";

					const vArridx 	= UFunc.getObjectIndeks(uDataLayananArr,uDatamainObj.layanan_id);
					const vJumlahidx= UFunc.getObjectIndeks(uDatajumlahArr,uDatamainObj.layanan_id);

					return (
						<span className="fw-bolder" style={{color:"#"+(uDataLayananArr[vArridx].color_layanan)}}>
						[{UFunc.formatAngka(vJumlahidx < 0 ? 0 : uDatajumlahArr[vJumlahidx].jumlah)}]
						</span>
					)
				})()}
				</>
				)}
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-1 mx-0">
			<CCol xs="4" lg="5" className="px-0">{uBahasaObj.caption_statusantar||"caption_statusantar"}</CCol>
			<CCol xs="8" lg="7" className="text-end pe-0">
				<CFormSelect size="sm"
					value={uDatamainObj.status_antar||""}
					onChange={(e)=>{uDatamainObj.status_antar=(e.target.value);setEfekview(!uEfekview);}}
					id="inpstatus">
				{uStatusantarArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems} key={vKeys}>{vItems}</option>
					)
				})}
				</CFormSelect>

				{(uDatamainObj.status_antar||"").toUpperCase()==="LAINNYA" && (
				<div className="my-1">
				<CFormInput size="sm" maxLength={100}
					placeholder="--Diisi Jika LAINNYA.."
					value={uDatamainObj.status_antarlainnya||""}
					onChange={(e)=>{uDatamainObj.status_antarlainnya=(e.target.value);setEfekview(!uEfekview);}}
					id="inpstatuslainnya"/>
				</div>
				)}
			</CCol>
		</CRow>

		<CRow className="my-1 mx-0">
			<CCol xs="4" lg="5" className="px-0">{uBahasaObj.caption_ketbooking||"caption_ketbooking"}</CCol>
			<CCol xs="8" lg="7" className="text-end pe-0">
				<CFormTextarea size="sm"
					value={UFunc.br2nl(uDatamainObj.keterangan||"")}
					onChange={(e)=>{uDatamainObj.keterangan=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>{
						let vElname = "inppasiennone";
						if(uDatapasienArr.length > 0)
							vElname = "inppasien"+uDatapasienArr[0].id;
						UFunc.hdlKeyDownInput(e,vElname);
					}}
					id="inpketerangan"/>
				<div className="classpetunjuk text-start">*) {uBahasaObj.petunjuk_textarea||"petunjuk_textarea"}</div>
			</CCol>
		</CRow>
		</CForm>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentPasien = () => {
		if(uISProcessing) return (
		<CCard className="classcardbg">
		<CCardHeader><span className="text-danger classblinking">Data Pasien</span></CCardHeader>
		<CCardBody>
			<div className="text-center">
			<CSpinner className="my-4" color="danger"/>
			</div>
		</CCardBody>
		</CCard>
		)//>

		const vJmlkolom			= 2;
		const vSisakolom		= (uDatapasienArr.length%vJmlkolom) > 0 ? (vJmlkolom - (uDatapasienArr.length%vJmlkolom)) : 0;
		const vPasienselectArr 	= uDatamainObj.pasien_arr || [];
		const vPasiensplitArr 	= [];
		let vRowArr				= [];
		uDatapasienArr.map((vItems,vKeys)=>{
			if(vKeys%vJmlkolom===0) {
				if(vRowArr.length > 0) {
					vPasiensplitArr.push(vRowArr);
					vRowArr = [];
				}
			}
			vRowArr.push(vItems);

			if(vKeys >= uDatapasienArr.length-1)
				vPasiensplitArr.push(vRowArr);
		})//>

		//console.log("vPasiensplitArr => "+JSON.stringify(vPasiensplitArr));
		//console.log("vSisakolom => "+(vSisakolom));
		return (
		<CCard className="classcardbg">
		<CCardHeader><b>Data Pasien ({uDatapasienArr.length})</b></CCardHeader>
		<CCardBody>
		<CForm>
		{vPasiensplitArr.map((vItems,vKeys)=>{
			const vRowpasienArr 	= vItems || [];

			return (
			<CRow className="classborderbottom py-2" key={vKeys}>
			<>
			{vRowpasienArr.map((vItemrow,vKeyrow)=>{
				const {
					nama_pasien,signamelent,id
				} = vItemrow;
				const vCaptionpasien = (nama_pasien||"UNDF")+
					((signamelent||"")!=="" 
						? " - ("+(signamelent||"").trim()+")"
						: "")+
					"";

				return (
				<CCol className="" key={vKeyrow}>
				<input type="checkbox" size="sm"
					value={id}
					onChange={(e)=> hdlChangePasien(e) }
					label={vCaptionpasien}
					checked={(vPasienselectArr.findIndex(vItemArr=>vItemArr.toString() === (id||"").toString()) >= 0)}
					className="me-2"
					id={"inppasien"+id}/>
				<label htmlFor={"inppasien"+id} className="classcursorpointer">{vCaptionpasien}</label>
				</CCol>
				)
			})}
			{(vKeys >= vPasiensplitArr.length-1) && (
			<>
				{([...new Array(vSisakolom)]).map((vItemrow,vKeyrow)=>{
					return (<CCol key={vKeyrow}/>)
				})}
			</>
			)}
			</>
			</CRow>
			)//>
		})}
		<CRow className="classborderbottom py-2 bg-light">
			<CCol>
			<input type="checkbox" size="sm"
				value="0"
				checked={(vPasienselectArr.findIndex(vItemArr=>vItemArr.toString() === "0") >= 0)}
				onChange={(e)=> hdlChangePasien(e) }
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpanmain")}
				className="me-2"
				id="inppasiennone"/>
			<label htmlFor="inppasiennone" className="classcursorpointer">Pasien Baru (Unregistered/Nama Belum Diketahui)</label>
			<div className="classpetunjuk ps-4">Harap Diinputkan Terlebih Dahulu Sebelum Set Kedatangan</div>
			</CCol>
		</CRow>
		</CForm>
		</CCardBody>
		</CCard>
		)//>
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoadpasien = () => {
		const vCustomerID = parseInt(uDatamainObj.customer_id||"0");
		setProcessing(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			setProcessing(false)

			setDatapasienArr(vTmpArr);
		},1500); return;
		//--END TESTING_FRONTEND--*/

		//console.log("("+uComponentName+"-"+uFuncname+") vInitCollapse 2 : "+vInitCollapse);
		const vDATAS	= JSON.stringify({
			send_customerid : vCustomerID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_family";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true)
			setProcessing(false)
			if(output_string.tampil) {
				//console.log("output_string.datapasien => "+(output_string.datapasien || "[]"));
				setDatapasienArr(JSON.parse(output_string.datapasien || "[]"));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasienbooking - apiLoadpasien) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setProcessing(false)
			console.log("(Pasienbooking - apiLoadpasien) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanbooking = () => {
		setLoading(true);
		uBtnSimpanmain && (uBtnSimpanmain.disabled=true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			setLoading(false)

			initDoneprocess();
		},1500); return;
		//--END TESTING_FRONTEND--*/

		//console.log("("+uComponentName+"-"+uFuncname+") vInitCollapse 2 : "+vInitCollapse);
		const vDATAS	= JSON.stringify({
			send_datalist : JSON.stringify(uDatamainObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_jadwal/pr_simpancustomer";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uBtnSimpanmain && (uBtnSimpanmain.disabled=false);
			if(output_string.sukses) {
				initDoneprocess();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasienbooking - apiSimpanbooking) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uBtnSimpanmain && (uBtnSimpanmain.disabled=false);
			setLoading(false);
			console.log("(Pasienbooking - apiSimpanbooking) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadjumlah = () => {
		const vBtnDlgsimpan = document.getElementById("btnSimpanmain")
		vBtnDlgsimpan && (vBtnDlgsimpan.disabled = true);
		setLoadingjml(true);

		/*//--TESTING_FRONTEND--/
		console.log("(MyFormbooking - apiProsesbooking) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoadingjml(false);
			
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
		},3500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tglcek: uDatamainObj.tgl_booking,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_daftar/ld_jumlah";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
			setLoadingjml(false);
			if(output_string.tampil) {
				setDatajumlahArr(JSON.parse(output_string.datajumlah || "[]"))
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyFormbooking - apiProsesbooking) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnDlgsimpan && (vBtnDlgsimpan.disabled = false);
			setLoadingjml(false);
			console.log("(MyFormbooking - apiProsesbooking) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		//setDlgviewshow(false);
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	const vELfocus =  document.getElementById("inpcustomercaption");
    	if(!isMobile) vELfocus && vELfocus.focus();

    	return () => {
    		setDatamainObj({}); setDatapasienArr([]);
    	}
	},[])
	React.useEffect(()=>{
		if(!uDlgLookupshow) {
			uBtnSimpanmain && (uBtnSimpanmain.disabled = false);
			setDlgLookuptrigger(false);
			return;
		}
		uBtnSimpanmain && (uBtnSimpanmain.disabled = true);
	},[uDlgLookupshow])
	React.useEffect(()=>{
		if(uDatamainObj.customer_toggle===undefined)
			uDatamainObj.customer_toggle = "LAMA";
		if(uDatamainObj.tgl_booking_dt===undefined) {
			uDatamainObj.tgl_booking_dt = UFunc.toDatetime((uKeywordobj.kwd_jadwal || UFunc.DbDate())+" 00:00:00");
		}
		if(uDatamainObj.layanan_id===undefined) {
			uDatamainObj.layanan_id = uDataLayananArr[0].id;
		}
		if(uDatamainObj.status_antar===undefined)
			uDatamainObj.status_antar = uStatusantarArr[0];
	},[uDatamainObj,
	uDatamainObj.customer_toggle,
	uDatamainObj.tgl_booking_dt,
	uDatamainObj.layanan_id,
	uDatamainObj.status_antar,
	])
	React.useEffect(()=>{
		if(uDatamainObj.tgl_booking_dt === undefined) return;
		uDatamainObj.tgl_booking = UFunc.DbDate(uDatamainObj.tgl_booking_dt);
	},[uDatamainObj.tgl_booking_dt])
	React.useEffect(()=>{
		if(uDatamainObj.tgl_booking === undefined) return;
		apiLoadjumlah();
	},[uDatamainObj.tgl_booking]);
	React.useEffect(()=>{
		uDatamainObj.customer_caption 	= undefined;
		initResetcustomer();

		let vELfocus;// = document.getElementById("inpcustomercaption")
		if(uDatamainObj.customer_toggle !== "LAMA")
			vELfocus = document.getElementById("inpcustomernama")

		vELfocus && vELfocus.focus()
	},[uDatamainObj.customer_toggle])
	React.useEffect(()=>{
		const vCustomerID = parseInt(uDatamainObj.customer_id || "0");
		if(vCustomerID <= 0) {
			setDatapasienArr([])
			uDatamainObj.pasien_arr = undefined;
			setEfekview(!uEfekview);
			return;
		}
		apiLoadpasien();
	},[uDatamainObj.customer_id])
	React.useEffect(()=>{
		if((uDatamainObj.customer_toggle||"")!=="LAMA"
			|| uDatamainObj.tgl_booking !== (uKeywordobj.kwd_jadwal || UFunc.DbDate())
			|| (uDatamainObj.customer_caption||"")!==""
			|| (uDatamainObj.customer_nama||"")!==""
			|| (uDatamainObj.customer_alamat||"")!==""
			|| (uDatamainObj.customer_telp||"")!==""
			|| uDatamainObj.status_antar !== uStatusantarArr[0]
			|| (uDatamainObj.status_antarlainnya||"") !== ""
			|| parseInt(uDatamainObj.customer_id||"0")!==0
			|| parseInt(uDatamainObj.layanan_id||"0")!==parseInt(uDataLayananArr[0].id||"0")
			|| (uDatamainObj.pasien_arr||[]).length > 0
			) setFieldupdate(true);
		else setFieldupdate(false);
	},[uDatamainObj.customer_toggle,
	uDatamainObj.customer_caption,
	uDatamainObj.customer_nama,
	uDatamainObj.customer_telp,
	uDatamainObj.customer_alamat,
	uDatamainObj.customer_id,
	uDatamainObj.tgl_booking_dt,
	uDatamainObj.layanan_id,
	uDatamainObj.status_antar,
	uDatamainObj.status_antarlainnya,
	uDatamainObj.pasien_arr,
	])
	//---END EFFECT--/

	//console.log("(Pasienbooking) uDatamainObj => "+JSON.stringify(uDatamainObj))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return (<></>); }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="p-0 classheadermain"
			style={{height:64}}>
			<CHeaderNav className="mx-2 classcursorpointer" onClick={()=>hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="" height={30}/>
			</CHeaderNav>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.caption_formbooking||"Form Reservasi Booking")}</small>
			</CHeaderBrand>

			<CHeaderNav className="align-items-center me-2">
				<CLink onClick={()=>hdlKlikReset()}
					className="classcursorpointer classikon classikonreset"/>
				<span className="me-1">&middot;</span>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-4 pb-5">
		<CContainer xxl className="mb-3">
			<CRow className="mx-0 mx-md-auto">
			<CCol xs="12" md="6" className="p-0 pe-md-2">
				{contentCustomer()}
				<div className="d-none d-md-block mt-3">
				{contentDatabooking()}
				</div>
			</CCol>

			<CCol xs="12" md="6" className="p-0 pt-3 pt-md-0 ps-md-2">{contentPasien()}</CCol>
			</CRow>
			
			<CRow className="mx-0 mt-3 d-md-none">
			<CCol className="p-0">{contentDatabooking()}</CCol>
			</CRow>

		</CContainer>
		</div>

		<CFooter position="fixed" className="bg-light">
			<MyButton
				centered={true}
				iconname="cilCheckAlt"
				onClick={()=>hdlKlikSimpanmain()}
				id="btnSimpanmain">
				Simpan [F9]
			</MyButton>
		</CFooter>
		</div>

		<MyCollapsejadwal 
			tglcek={uDatamainObj.tgl_booking||UFunc.DbDate()}
			onClose={()=>setShowjadwal(false)} 
			show={uISShowjadwal} 
			{...props}/>

		<MyDialoglookupapi
			show={uDlgLookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlgLookupheader||"dialogHeader"}
			inputValue={uInpLookupkwd}
			onKlikPilihItem={hdlKlikDlglookuppilih}
			targetApi={pjson.homepage+"api/api_customer/ld_lookup"} 
			kdownTrigger={uDlgLookuptrigger}
			id="iddialoglookupapi"
			{...props}/>
		</>
	)//>
}	

export default Pasienbooking