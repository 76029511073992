import React, { Component, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
	BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom'
import './assets/scss/style.scss'
import CIcon from '@coreui/icons-react'
import { UFunc } from './helpers/functions'
import useToken from './helpers/useToken'
import { 
	MyLoader,
	MyToaster,
	MyDialogsession,
} from './components/index'

//--PAGES--/
import { 
	Login,
	Error500,
	Updatedlogs,
	Layout
} from "./pages/index"
//--END PAGES--/

/*//---CONTAINERS--/
const Layout = React.lazy(() => import('./pages/Layout'))
//---END CONTAINERS--*/

const pjson	= require('../package.json');
const App 	= (props) => {
	const { token, setToken } 			= useToken();
	const uDispatch    					= useDispatch();

	const uToastlist					= useSelector(state => state.listToast);
	const [uRoutes,setRoutes]			= React.useState(JSON.parse(localStorage.getItem("routeslist")) || "[]");
	const uActiveRoute  				= useSelector(state => state.activeRoute);
	const uRouteProfileArr				= useSelector(state => state.profileRoute); 
	const uSystemsettingObj				= useSelector(state => state.gSystemsetting);
	const uEfekapp						= useSelector(state => state.effectApp);
	const uEfeksessionapp				= useSelector(state => state.effectsessionApp) || false;

	const [uIsTotopshow,setTotopshow]	= React.useState(false);
	const [uBahasaob,setBahasaob]		= React.useState({});
	const [isApploading,setApploading]	= React.useState(false);
	const [isLoading, setLoading]      	= React.useState(false);
	const [isInitKoneksi, setInitKoneksi ]= React.useState(true);

	//--TIMEOUTSESSION--/
	const uTimeouttimer					= parseInt(uSystemsettingObj.limit_session) || 900;
	const [uISSessionaktif,setSessionaktif]		= React.useState(false);
	const [uCoundownsession,setCoundownsession]	= React.useState(uTimeouttimer);

	const [uSessionTimeStart,setSessionTimeStart]= React.useState();
	//--END TIMEOUTSESSION--/

	//--DLG_FORM--/
	const [uSessionISNotify,setSessionNotify]  	= React.useState(false);
	const [uIsDlgsessionshow,setDlgsessionshow]	= React.useState(false)
	const [uKlikDlgsessionYA,setKlikDlgsessionYA]= React.useState(false)
	//--END DLG_FORM--/

	//---DETIL_PAGE--/
	const uDetilPageArr 		= [
		{path:"/user/rolesetting",name:"userrole",owner:"user",},
		{path:"/subkeuangan/kastransfer/forminput", name:"kastransferform",owner:"kastransfer",},
		{path:"/subkeuangan/kasselisih/forminput", name:"kasselisihform",owner:"kasselisih",},
		{path:"/subkeuangan/ruparupa/forminput",name:"ruparupaform",owner:"ruparupa",},
		{path:"/subkeuangan/omzetlain/forminput", name:"omzetlainform",owner:"omzetlain",},
		{path:"/subkeuangan/biaya/forminput", name:"biayaform",owner:"biaya",},
		{path:"/subkeuangan/belibarang/formmain", name:"belibarangmain",owner:"belibarang",},
		{path:"/subkeuangan/konsinyasi/formmain", name:"konsinyasimain",owner:"konsinyasi",},
		{path:"/subkeuangan/beliaset/formmain", name:"beliasetmain",owner:"beliaset",},
		{path:"/subkeuangan/kasbon/forminput", name:"kasbontambah",owner:"kasbon",},
		{path:"/subkeuangan/kasbon/listdata", name:"kasbonpreview",owner:"kasbon",},
		{path:"/subkeuangan/hsupplier/listdata",name:"hutangsupplierpreview",owner:"hutangsupplier",},
		{path:"/subkeuangan/hbeliaset/listdata",name:"hutangbeliasetpreview",owner:"hutangbeliaset",},
		{path:"/subkeuangan/hutangjpl/listdata",name:"hutangjplpreview",owner:"hutangjpl",},
		{path:"/subkeuangan/hutanglain/forminput",name:"hutanglaintambah",owner:"hutanglain",},
		{path:"/subkeuangan/hutanglain/listdata",name:"hutanglainpreview",owner:"hutanglain",},
		{path:"/subkeuangan/hutangpsaham/forminput",name:"hutangpsahamtambah",owner:"hutangpsaham",},
		{path:"/subkeuangan/hutangpsaham/formbayar",name:"hutangpsahambayar",owner:"hutangpsaham",},
		{path:"/subkeuangan/hutangpsaham/listdata",name:"hutangpsahampreview",owner:"hutangpsaham",},
		{path:"/subhutang/tagihanpajak/inputdata",name:"tagihanpajakinput",owner:"tagihanpajak",},
		{path:"/subhutang/tagihanpajak/listdata",name:"tagihanpajakpreview",owner:"tagihanpajak",},
		{path:"/substok/stokopname/formproses/:barang_id",name:"stokopnameset",owner:"stokopname",},
		{path:"/substok/pecahstok/forminput",name:"pecahstoktambah",owner:"pecahstok",},
		{path:"/substok/pakaiklinik/forminput",name:"pakaikliniktambah",owner:"pakaiklinik",},
		{path:"/substok/baranged/forminput",name:"barangedtambah",owner:"baranged",},
		{path:"/subkaryawan/payroll/formpayroll/:id",name:"payrollform",owner:"payroll",},
		{path:"/kasir/kasirtoko/forminput",name:"kasirtokomain",owner:"kasirtoko",},
		{path:"/kasir/kasirtoko/forminput",name:"kasirtokomain",owner:"copynota",},
		{path:"/kasir/titipandp/forminput",name:"titipandpmain",owner:"titipandp",},
		{path:"/kasir/titipandp/formrefund/:id",name:"titipandprefund",owner:"titipandp",},
		{path:"/klinik/pasien/forminput/:id",name:"pasieninput",owner:"pasien",},
		{path:"/klinik/pasien/rekammedis/:id",name:"pasienrekammedis",owner:"pasien",},
		{path:"/klinik/pasien/detilrekmed/:id_rm",name:"pasienrmdetil",owner:"pasien",},
		{path:"/klinik/pasien/formbooking/",name:"pasienbooking",owner:"jadwal",},
		{path:"/klinik/pasien/rekammedis/:id",name:"pasienrekammedis",owner:"jadwal",},
		{path:"/klinik/pasien/detilrekmed/:id_rm",name:"pasienrmdetil",owner:"jadwal",},
		{path:"/klinik/pasien/forminput/:id",name:"pasieninput",owner:"jadwal",},
		{path:"/klinik/pasien/formtreatment/:id",name:"ongoingitem",owner:"ongoing",},
		{path:"/klinik/pasien/rekammedis/:id",name:"pasienrekammedis",owner:"ongoing",},
		{path:"/klinik/pasien/detilrekmed/:id_rm",name:"pasienrmdetil",owner:"ongoing",},
		{path:"/klinik/pasien/forminput/:id",name:"pasieninput",owner:"ongoing",},
		{path:"/kasir/kasirklinik/formbayar/:customer_id",name:"kasirklinikmain",owner:"kasirklinik",},
		{path:"/subhutang/hutangkonsi/listdata",name:"hutangkonsipreview",owner:"hutangkonsi",},
		//{path:"/klinik/pasien/daftarperiksa/:id",name:"pasiendaftar",owner:"ongoing",},
		//{path:"/klinik/pasien/daftarperiksa/:id",name:"pasiendaftar",owner:"jadwal",},
	]
	//---END DETIL_PAGE--/

	//---HANDEL--/
	const hdlKlikToTop	= () => { window.scrollTo({ top: 0, behavior: "smooth" }); };
	const hdlKDownField	= (_EV) => {
		if (_EV.keyCode === 13 && (_EV.target.nodeName === "INPUT"
			|| _EV.target.nodeName === "SELECT"
			|| _EV.target.nodeName === "TEXTAREA")
		){
			if (_EV.target.nodeName === "TEXTAREA") {
				if(_EV.shiftKey) return;
			}

			var form      = _EV.target.form;
			/*console.log("("+uComponentname+") addEventListener => form 1 "+form);
			if(isNaN(form)) {
				console.log("("+uComponentname+") addEventListener => form 1a "+form);
			}//-*/
			if(UFunc.isEmpty(form)) return;
			//console.log("("+uComponentname+") addEventListener => form 2 "+form);
			var index     = Array.prototype.indexOf.call(form, _EV.target);
			var vJmlLoop  = 0;
			var vFocusedBl= false;
			do{
				index++;
				vJmlLoop++;
				var vElement  = form.elements[index];
				if(vElement && !vElement.disabled 
					&& vElement.style.visibility !== "hidden"
					&& vElement.style.display !== "none"
				) {
					vFocusedBl = true;
					vElement.focus();
				}
				/*console.log("("+uComponentname+") addEventListener => ID "+(vElement.id && vElement.id));
				console.log("("+uComponentname+") addEventListener => visibility : "+(vElement.style.visibility));
				console.log("("+uComponentname+") addEventListener => display : "+(vElement.style.display));//-*/
			} while(!vFocusedBl && vJmlLoop <= 5)
			_EV.preventDefault();
		}
	}
	const hdlToggleDlgsession = () => { setDlgsessionshow(false)}
	const hdlKlikDlgsessionYA = () => {
		setKlikDlgsessionYA(true);
		apiProsessession(); 
	}
	const hdlScroll=()=>{
		if (window.pageYOffset > 300) setTotopshow(true);
		else setTotopshow(false);
	}
	const hdlScrollWindow=(e)=>{
        /*const windowHeight 	= "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body 			= document.body;
        const html			= document.documentElement;
        const docHeight 	= Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom 	= windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight-5) {
			uDispatch({type: "set", gIsScrollBottom: true});
		} else {
			uDispatch({type: "set", gIsScrollBottom: false});
		}//-*/
	}
	//---END HANDEL--/

	//---GLOBAL_PROP_FUNCTION--/
	const clearStorage=()=>{
		localStorage.removeItem("routeslist");
		localStorage.removeItem("listkeyword");
		
		setDlgsessionshow(false);
		setSessionaktif(false);
		uDispatch({type: "set", gUrlbefore: ""});
		uDispatch({type: "set", gInitTabelSelected: {}});
	}
	const showToast   = (_PESAN, _TIPE="INFO") => {
		const vTmps  = { type : _TIPE, teksIsi : _PESAN };
		uDispatch({type: "set", listToast: [...uToastlist, vTmps]});
		//uDispatch({type: "set", listToast: vTmps});
	};
	const prosesExpired = () => {
		clearStorage();

		showToast(uBahasaob.pesan_hasexpired || pjson.mydefault.msgExpired);
		setToken("");
		setLoading(false);
		setDlgsessionshow(false);
	}
	const prosesSessioncount = () => {
		if(!uSessionTimeStart) return;
		//if(uSessionTimeStart === undefined) return;

		const vTimecekDT	= new Date();
		const vSelisihDTK	= parseInt((vTimecekDT.getTime() - uSessionTimeStart.getTime()) / 1000 );
		
		//console.log("(App - prosesSessioncount) vSelisihDTK => "+vSelisihDTK);
		const vCountsession	= uTimeouttimer - vSelisihDTK; 
		setCoundownsession(vCountsession);
		//console.log("(App - prosesSessioncount) uTimeouttimer => "+uTimeouttimer);
	}
	const initDashboard = () =>{
		/*//--
		1. Hapus Dashboard 6 bulan
		2. JIKA Tgl Satu Bulan Baru KOSONG,Tanggal BLN Kemarin Dihapus
		3. Cek setiap 3 jam, jika sudah tiga jam, Tanggal BLN Ini Dihapus.//-->Ini dilakukan di DASHBOARD
		//-*/
	
		const vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		const vTimestampTS		= vDatadashboardObj.timestampTS;
		if(!vTimestampTS) return;

		const vTglnowDT 		= new Date();
		const vDataArr			= vDatadashboardObj.data_arr || [];
		const vTglsatuDT		= new Date(vTglnowDT.getFullYear(),vTglnowDT.getMonth(),1);

		let vTgl6monthDT		= new Date();
		new Date(vTgl6monthDT.setMonth(vTgl6monthDT.getMonth() - 7));
		
		let vTglLastmonthDT 	= new Date();
		new Date(vTglLastmonthDT.setMonth(vTglLastmonthDT.getMonth() - 1));
		const vTgl1LastmonthDT	= new Date(vTglLastmonthDT.getFullYear(),vTglLastmonthDT.getMonth(),1);
		
		const vTglsatuTS		= vTglsatuDT.valueOf();
		const vTgl6monthTS		= vTgl6monthDT.valueOf();
		const vTgl1LastmonthTS	= vTgl1LastmonthDT.valueOf();

		/*console.log("(App - initDashboard) vTglnowDT => "+vTglnowDT);
		console.log("(App - initDashboard) vTgl6monthDT => "+vTgl6monthDT);
		console.log("(App - initDashboard) vTgl1LastmonthDT => "+vTgl1LastmonthDT);//-*/

		//--HAPUS_DATA_LEWAT_6_BULAN--/
		let vFilteredArr		= vDataArr.filter((vItems,vKeys) => {
			const vTglfilterTS	= UFunc.toDate(vItems.tgl_filter).valueOf();
			return (vTglfilterTS > vTgl6monthTS);
		})
		//--END HAPUS_DATA_LEWAT_6_BULAN--/

		const vDatanowArr = vFilteredArr.filter(vItems => {
			const vTglfilterTS 	= UFunc.toDate(vItems.tgl_filter).valueOf();
			return vTglfilterTS === vTglsatuTS;
		})

		//--JIKA_BULAN_INI_MASIH_KOSONG_HAPUS_BULAN_LALU--/
		if(vDatanowArr.length <= 0) {
			vFilteredArr = vFilteredArr.filter((vItems,vKeys) => {
				const vTglfilterTS	= UFunc.toDate(vItems.tgl_filter).valueOf();
				return (vTglfilterTS !== vTgl1LastmonthTS);
			})
		}
		//--END JIKA_BULAN_INI_MASIH_KOSONG_HAPUS_BULAN_LALU--/

		vDatadashboardObj.data_arr 		= vFilteredArr;
		localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
	}
	//---END GLOBAL_PROP_FUNCTION--/

	//---REST_API--/
	const apiLoadinit = () => {
		setApploading(true);
		setInitKoneksi(true);
		setSessionaktif(false);

		/*//---TEST_FRONTEND--/
		let vTimeout	= setTimeout(function(){
			setApploading(false);
			//setInitKoneksi(false);
			//setToken("")
		},2000); return;
		//---END TEST_FRONTEND--*/

		const vDATAS= JSON.stringify({ 
			send_tokenauth:token, 
			x:"x" 
		});
		const vURLs	= pjson.homepage+"api/api_init/ld_init";
		//console.log("(App - apiLoadinit) LEWAT "+vURLs);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> { if (response.status === 200) return response.json();
		}).then((output_string) => {
			//console.log("(App - apiLoadinit) output_string.systemsetting : "+(output_string.systemsetting));
			setApploading(false);
			setBahasaob(JSON.parse(output_string.langs||"[]"));
			uDispatch({type: "set", gSystemsetting:JSON.parse(output_string.systemsetting||"{}")});
			localStorage.setItem("layananlist", (output_string.layananlist||"[]")); 

			if(output_string.tampil) {
				if(token) {
					setSessionaktif(true);
					uDispatch({type: "set", listNav: JSON.parse(output_string.nav || "[]")});
					uDispatch({type: "set", gIsEOMExecuted: ((output_string.status_eom||"TIDAK")==="YA")});
					uDispatch({type: "set", gEOMLabel: (output_string.status_eom_label||"") });
					//uDispatch({type: "set", gIsEOMExecuted: true});//--TESTING--/
					
					//console.log("(App - apiLoadinit) output_string.status_eom : "+(output_string.status_eom));
					//console.log("(App - apiLoadinit) output_string.nav : "+output_string.nav);
					if(output_string.routes) {
						const vRouteLoadArr = JSON.parse(output_string.routes||"[]");
						const vNewRoutes	= [...uRouteProfileArr,...vRouteLoadArr];
						localStorage.setItem("routeslist", JSON.stringify(vNewRoutes)); 
					}
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(App - apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				if(token) { 
					showToast(uBahasaob.pesan_hasexpired || pjson.mydefault.msgExpired);
					setToken(""); 
				}
			}
		}).catch((error) =>{
			setInitKoneksi(false);
			setApploading(false);
			console.log("(App - apiLoadinit) catch-error : "+error);
		});
	}
	const apiProsessession = () => {
		const vEldisabled = document.getElementById("btnDlgsessionYa")
		vEldisabled && (vEldisabled.disabled=true);
		setLoading(true)
 		setSessionaktif(false);

		const vDATAS	= JSON.stringify({ send_tokenauth:token });
		const vURLs		= pjson.homepage+"api/api_init/pr_session";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { return response.json(); }})
		.then((output_string) => {
			vEldisabled && (vEldisabled.disabled=false);
			setLoading(false);
			//console.log("output_string => "+JSON.stringify(output_string));
			if(output_string.sukses) {
				//console.log("output_string => "+output_string.sukses);
				setSessionaktif(true);
				hdlToggleDlgsession();
			} else if(output_string.expired) {
				if(token) { 
					showToast(uBahasaob.pesan_hasexpired || pjson.mydefault.msgExpired);
					setToken(""); 
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(App - apiProsessession) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			}
		})
		.catch((error) =>{
			vEldisabled && (vEldisabled.disabled=false);
			setLoading(false);
			console.log("(App - apiProsessession) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(() => {
		document.addEventListener("keydown", hdlKDownField);
		window.addEventListener("keydown", hdlKDownField);
		document.addEventListener("scroll", hdlScroll);
		window.addEventListener("scroll", hdlScrollWindow);

		//return function cleanup() {
		return () => {
			window.removeEventListener("scroll", hdlScrollWindow);
			window.removeEventListener("keydown",hdlKDownField);
			document.removeEventListener("keydown",hdlKDownField);
			document.removeEventListener("scroll",hdlScroll);
		}
	});
	React.useEffect(() => {
		//---LOAD_BAHASA--/
		apiLoadinit();
		//---END LOAD_BAHASA--/

		//--INIT_DASHBOARD--/
		initDashboard();
		//--END INIT_DASHBOARD--/

		return ()=>{ setBahasaob([]); setSessionaktif(false); setDlgsessionshow(false); }
	},[]);
	React.useEffect(() => { uDispatch({type: "set", listBahasa: uBahasaob}); },[uBahasaob]);
	React.useEffect(() => { window.scrollTo({top: 0}); },[uEfekapp]);
	React.useEffect(() => { if(!token) clearStorage(); },[token]);
	React.useEffect(() => {
		//console.log("(App - [uISSessionaktif]) uTimeouttimer => "+uTimeouttimer);
		uDispatch({type: "set", effectsessionApp: uISSessionaktif});

		if(!uISSessionaktif) {
			setDlgsessionshow(false);
			setCoundownsession(uTimeouttimer);
			setSessionNotify(false);
			setSessionTimeStart();
			return;
		}

		setSessionTimeStart(new Date());
	},[uISSessionaktif]);//-*/
	React.useEffect(() => {
		//if(!uISSessionaktif) return;
		//console.log("(App - [uSessionTimeStart]) uSessionTimeStart => "+uSessionTimeStart);
		if(!uSessionTimeStart) return;
		
		//prosesSessioncount(); return;

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			prosesSessioncount();
		},1000)
	},[uSessionTimeStart]);//-*/
	//React.useEffect(() => { setCoundownsession(uTimeouttimer) },[uTimeouttimer]);
	React.useEffect(() => {
		if(!uISSessionaktif) return;

		//console.log("(App - [uCoundownsession]) uCoundownsession => "+uCoundownsession);
		//console.log("(App - [uCoundownsession]) uSessionISNotify => "+uSessionISNotify);
		if((uCoundownsession <= 30 && uCoundownsession >= 0) 
			&& !uSessionISNotify && !uIsDlgsessionshow
			) setDlgsessionshow(true);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			prosesSessioncount();
		},1000); 
	},[uCoundownsession]);//-*/
	React.useEffect(() => {
		if(!uIsDlgsessionshow) {
			if(!uKlikDlgsessionYA) setSessionaktif(false);
			return;
		}//-*/

		setKlikDlgsessionYA(false);
		setSessionNotify(true);
	},[uIsDlgsessionshow]);//-*/
	//---END EFFECT--/

	//---FIRSTLOADING--/
	if(isApploading) return ( <><MyLoader isShow={isApploading} /></> );
	//---END FIRSTLOADING--/

	//---JIKA_INTERNET_PROBLEMS--/
	if(!isInitKoneksi)  return (
			<BrowserRouter>
			<Routes>
				<Route path="/error500" element={ 
					<Error500
						showToast={showToast}
						setLoading={setLoading}
						{...props}/>
					}/>
				<Route path="*"
					element={<Navigate from="/" to="/error500"/>}/>
			</Routes>
			</BrowserRouter>
	)//>
	//---END JIKA_INTERNET_PROBLEMS--*/

	//---NO_TOKEN_AUTH--/
	if(!token) return (
		<>
		<MyLoader isShow={isLoading} />
		<MyToaster toastData={uToastlist} />
		<BrowserRouter>
		<Routes>
			<Route path="/error500" 
				element={
				<Error500
					showToast={showToast}
					setLoading={setLoading}
					setToken={setToken}
					prosesExpired={prosesExpired}
					clearStorage={clearStorage}
					setSessionaktif={setSessionaktif}
					{...props}/>
				}/>
			<Route path="/login" element={ 
				<Login
					setToken={setToken}
					showToast={showToast}
					setLoading={setLoading}
					setSessionaktif={setSessionaktif}
					{...props}/>
				}/>
			<Route path="*"
				element={<Navigate from="/" to="/login"/>}/>
		</Routes>
		</BrowserRouter>
		</>
	)//>
	//---END NO_TOKEN_AUTH--*/
	
	const loadDetilpage = (_MAINMENU) => {
	 	if(_MAINMENU.toUpperCase() === ("pasien").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("jadwal").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("ongoing").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("kastransfer").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("kasselisih").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("ruparupa").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("omzetlain").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("biaya").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("belibarang").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("konsinyasi").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("beliaset").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("hutangsupplier").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("hutangbeliaset").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("hutangjpl").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("hutanglain").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("hutangkonsi").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("tagihanpajak").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("kasbon").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("kasirtoko").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("copynota").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("titipandp").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("stokopname").toUpperCase()
	 		|| _MAINMENU.toUpperCase() === ("pecahstok").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("pakaiklinik").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("baranged").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("kasirklinik").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("payroll").toUpperCase() 
	 	) {
	 		return uDetilPageArr.map((vItem,vKey)=>{
				const {path,name,owner} = vItem;

				const vDetilname = name.toLowerCase();
				if(owner.toUpperCase() === _MAINMENU.toUpperCase()) {
					let KomponenDetil;
					try {
						KomponenDetil = require("./pages/detil/"+UFunc.capitalize(vDetilname)).default;
					} catch (ex) {
						//console.log("(App - loadDetilpage) uDetilPageArr.name(ex) = ./detil/"+vDetilname);
					}
					return KomponenDetil && (
						<Route
							key={vKey}
							path={path}
							exact
							name={UFunc.capitalize(vDetilname)}
							element={
								<KomponenDetil
									setToken={setToken}
									showToast={showToast}
									setLoading={setLoading}
									prosesExpired={prosesExpired}
									clearStorage={clearStorage}
									setSessionaktif={setSessionaktif}
									{...props}/>
							}/>
					)	
				}
			})
	 	} 
	}

	//console.log("(App) uActiveRoute = "+uActiveRoute)
	return (
		<>
		<MyLoader isShow={isLoading} />
		<MyToaster toastData={uToastlist} />
		<div style={{zIndex:2000}} className="position-fixed bottom-0 start-0 p-2 text-danger">[{uCoundownsession}]</div>
		<BrowserRouter>
		<Routes>
			{loadDetilpage(uActiveRoute)}
			<Route exact path="*" name="Layout" 
				element={
					<Layout 
						setToken={setToken}
						showToast={showToast}
						setLoading={setLoading}
						prosesExpired={prosesExpired}
						clearStorage={clearStorage}
						setSessionaktif={setSessionaktif}
						{...props}/>
				}/>
			<Route exact path="/updatedlogs" name="Updatedlogs" 
				element={
					<Updatedlogs 
						setToken={setToken}
						showToast={showToast}
						setLoading={setLoading}
						prosesExpired={prosesExpired}
						clearStorage={clearStorage}
						setSessionaktif={setSessionaktif}
						{...props}/>
				}/>
			<Route exact path="/" element={<Navigate from="/" to="/dashboard"/>}/>
			<Route exact path="/login" element={<Navigate to="/dashboard"/>}/>
			<Route exact path="/error500" element={<Navigate to="/dashboard"/>}/>
		</Routes>

		{uIsTotopshow &&
			<div onClick={()=>hdlKlikToTop()} className="text-danger classToTop" >
			<CIcon icon="cilArrowCircleTop" height={35} />
			</div>
		}
		</BrowserRouter>

		<MyDialogsession
			onKlikyes={()=>hdlKlikDlgsessionYA()}
			show={uIsDlgsessionshow} 
			toggle={hdlToggleDlgsession} 
			Countertime={uCoundownsession}/>
		</>
    )//>
}

export default App
	