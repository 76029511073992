import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CRow,
	CCol,
	CLink,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     = require('../../package.json');
const Kasirtoko = (props) => {
	const uComponentname 	= Kasirtoko.name;
	const { setLoading,showToast,prosesExpired } = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initFormAwal=()=>{}
	//---END INIT_FUNCTION--/

	//---API_FUNC--/
	const apiLoadData=()=>{}
	const apiHapus=()=>{}
	const apiLoadItem=(_IDTABEL)=>{}
	//---END API_FUNC--/

	//---Init_CONTENT--/
	const loadContentMain=()=>{}
	const loadContentFhapus=()=>{}
	//---END Init_CONTENT--/

	//---EFFECT--/
	React.useEffect(()=>{ });
	React.useEffect(()=>{
		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uNavigate("/kasir/kasirtoko/forminput"); 
		},10)

    	return()=>{ }
	},[]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	return (
		<>
		<CCard id="idtabcard" className="d-none">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<div><b>{uBahasaObj["menus_"+uActiveroute.toLowerCase()]}</b></div>
			<small className="text-muted classfontsmaller">{0}ms</small>
			</CCardHeader>

			<CCardBody><CLink onClick={()=>uNavigate("/kasir/kasirtoko/forminput")}>UNDEFINED</CLink></CCardBody>
		</CCard>
		</>
	)
}
	
export default Kasirtoko;
