import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CBadge,
	CButton,
	CImage,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CDropdownDivider,
	CCollapse,
	CModal,
	CModalHeader,
	CModalTitle,
	CModalBody,
	CForm,
	CFormInput,
	CFormSelect,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import { 
	MyButton,
	MyDialogform,
	MyDialogview,
	MyPagination,
	MyPage500,
	MyFormHadir,
} from '../components/index';
import { 
} from '../containers/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     = require('../../package.json');
const Ongoing = (props) => {
	const uComponentname 	= Ongoing.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token") || "{}");

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uLayananselect	= useSelector(state => state.gLayananselect) || "0";

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	//const uFilterObj 		= uKeywordObj.kwd_ongoing || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uIsInitHapus		= (uTokenObj.userhak!=="FREEROLE"&&uTokenObj.userhak!=="ADMIN")||!uIsEOMExecuted 
		? false : true;
	const uMaxData			= isMobile ? 10: 30;
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const uIsInitbefore 	= ((JSON.stringify(uTabelSelectObj)||"{}")!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	const uInitTabelIDBefore				= parseInt(uTabelSelectObj.initID) || 0;	
	const uInitPageBefore					= parseInt(uTabelSelectObj.initPage) || 1;	

	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uEfekview,setEfekview]			= React.useState(false);
	const [uEfekview2,setEfekview2]			= React.useState(false);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [uInitfirstload,setInitfirstload]	= React.useState("YA");
	const [uInpLayanan,setInpLayanan]		= React.useState(uLayananselect);
	const [uJmlLayanan,setJmlLayanan]		= React.useState(0);

	const uLayananArr						= JSON.parse(localStorage.getItem("layananlist")||"[]");
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	//const [uLayanannoteArr,setLayanannoteArr]= React.useState([]);

	//---FORMDIALOG--/
	const [uIsShowForm,setIsShowForm]		= React.useState(false);
	const [uForminit,setForminit]			= React.useState("");
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uFormhadirObj,setFormhadirObj]	= React.useState({});

	//const [uInpLayananTranfer,setInpLayananTranfer]	= React.useState("");
	const [uInpLayananbaruID,setInpLayananbaruID]	= React.useState("0");
	const [uInpDoneketerangan,setInpDoneketerangan]	= React.useState("");
	const [uIsFormprocess,setFormprocess]	= React.useState(false);
	//---END FORMDIALOG--/

	//---DIALOG_VIEW--/
	const [uIsShowview,setShowview]			= React.useState(false);
	const [uFotokedatanganArr,setFotokedatanganArr]= React.useState([]);
	//---END DIALOG_VIEW--/

	const [uTimeStampNow,setTimeStampNow]	= React.useState(new Date());
	const [uColorlayanan,setColorlayanan]	= React.useState("initial");
	const [uElBtndlgsimpan,setElBtndlgsimpan]= React.useState(document.getElementById("btnDialogSimpan"));
	const uElsJmlData		= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlToggleview=()=>{setShowview(false)}
	const hdlToggleForm=()=>{setIsShowForm(false)}
	const hdlKlikFormSimpan=()=>{
		if(uForminit==="TRANSFER") {
			initProsestransfer(); 
		} else if(uForminit==="DONE") {
			initProsesdone(); 
		} else {
			initProseskedatangan();
		}
	}

	const hdlKlikKedatanganedit=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL<=0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionUpper = (UFunc.leadZero(uDatamainArr[vObjdx].id_booking,6)+" - "+(uDatamainArr[vObjdx].pasien_caption||"")).toUpperCase();
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setTabelIDPilih(_IDTABEL);
		const vKehadiranObj	= {
			id_ongoing:_IDTABEL,
			tgl_hadir: uDatamainArr[vObjdx].tgl_hadir,
			value_layanan: uDatamainArr[vObjdx].layanan_value,
			berat: uDatamainArr[vObjdx].berat_badan,
			berat_tmp: uDatamainArr[vObjdx].berat_badan,
			keluhan: uDatamainArr[vObjdx].keluhan,
			id_dokter: uDatamainArr[vObjdx].dokter_id,
			tgl_keluar: uDatamainArr[vObjdx].tgl_keluar_titip,
			nilai_dp: uDatamainArr[vObjdx].nilai_dp,
			payment_id: uDatamainArr[vObjdx].payment_id,
		}

		const vFunctionnext=()=>{
			const vFotoArr	= uDatamainArr[vObjdx].foto_arr||[];

			let vFotodlgArr	= []
			vFotoArr.map(vItems=>{
				const vTmpObj = {id:vItems.foto_id,url:vItems.url_image}
				vFotodlgArr.push(vTmpObj);
			});

			vKehadiranObj.foto_arr = vFotodlgArr;
			setFormhadirObj(vKehadiranObj);
			//console.log("hdlKlikKedatanganedit - foto_arr => "+JSON.stringify(vFotodlgArr));
			setIsShowForm(true);
		}

		setForminit("KEHADIRAN");
		setDialogHeader((uBahasaObj.caption_editdata||"caption_editdata")+
			" "+(uBahasaObj.word_kedatangan)+
			": <B>"+vCaptionUpper+"</B>"+
		"");

		//console.log("hdlKlikKedatanganedit - foto_arr => "+JSON.stringify(uDatamainArr[vObjdx].foto_arr||[]));
		if(uDatamainArr[vObjdx].isAdafoto=== true
			&& (uDatamainArr[vObjdx].foto_arr||[]).length <= 0) {
			apiLoadfoto(_IDTABEL,vFunctionnext);
			return;
		}

		vFunctionnext();
	}
	const hdlKlikBatal=async()=>{
		if((parseInt(uTabelIDPilih)||0)<=0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);

		//---CEK_ITEM--/
		if((uDatamainArr[vObjdx].staff_start||"") !== "") {
			showToast(uBahasaObj.pesan_batalhadirinvalid_1||"pesan_batalhadirinvalid_1");
			return;
		}
		//---END CEK_ITEM--/

		const vCaptionUpper = (UFunc.leadZero(uDatamainArr[vObjdx].id_booking,6)+
			" - "+(uDatamainArr[vObjdx].pasien_caption||"")).toUpperCase();

		const vConfirm	= 
			(uBahasaObj.caption_pembatalankehadiran||"Pembatalan Kehadiran")+": <B>"+
			vCaptionUpper+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_warnbatalhadir||"petunjuk_warnbatalhadir")+
			"<BR><BR><B>"+(uBahasaObj.confirm_batal||"confirm_batal")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiBatalhadir(uTabelIDPilih);
	}
	const hdlKlikTreatment=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL<=0) return;

		const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		//---CEK_ITEM--/
		if((uDatamainArr[vObjdx].staff_start||"") === "") {
			showToast(uBahasaObj.pesan_examharusstart||"pesan_examharusstart"); return;
		}
		//---END CEK_ITEM--/

		const vTmpObj	= { initID:_IDTABEL, initPage:uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj});

		uDatamainArr[vObjdx].isItemLoading = true;
		uNavigate("/klinik/pasien/formtreatment/"+_IDTABEL);
	}
	const hdlKlikTransfer=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL<=0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		const vCaptionUpper = (UFunc.leadZero(uDatamainArr[vObjdx].id,6)+" - "+(uDatamainArr[vObjdx].pasien_caption||"")).toUpperCase();
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);

		setForminit("TRANSFER");
		setDialogHeader((uBahasaObj.word_transfer||"uBahasaObj.word_transfer")+" "+
			(uBahasaObj.caption_jenislayanan)+": "+vCaptionUpper);

		setIsShowForm(true);
	}
	const hdlKlikDone=async(_IDTABEL)=>{
		_IDTABEL	= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		//---CEK_ITEM--/
		if((uDatamainArr[vObjdx].staff_start||"") === "") {
			showToast(uBahasaObj.pesan_doneharusstart||"pesan_doneharusstart"); return;
		}
		//---END CEK_ITEM--/

		//console.log("("+uComponentname+"-hdlKlikDone) 1 Checked..");
		setTabelIDPilih(_IDTABEL);
		setForminit("DONE");
		setDialogHeader((uBahasaObj.caption_layanandone)+": "+
			(uDatamainArr[vObjdx].layanan_caption||"").toUpperCase()+
			"");
		setIsShowForm(true);
	}
	const hdlKlikfoto=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL<=0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionUpper = ((uDatamainArr[vObjdx].pasien_caption||"")+
			" ["+(uDatamainArr[vObjdx].layanan_caption||"")+"]"+
			"").toUpperCase();
		if((uDatamainArr[vObjdx].isAdafoto||false)=== false) return;

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vFunctionnext=()=>{
			const vFotoArr	= uDatamainArr[vObjdx].foto_arr||[];

			let vFotodlgArr	= []
			vFotoArr.map(vItems=>{
				const vTmpObj = {
					original:vItems.url_image,
					thumbnail:vItems.url_image,
					thumbnailHeight:85,
					thumbnailWidth:85,
				}
				vFotodlgArr.push(vTmpObj);
			});
			setFotokedatanganArr(vFotodlgArr);
			//console.log("hdlKlikKedatanganedit - foto_arr => "+JSON.stringify(vFotodlgArr));

			setDialogHeader((uBahasaObj.caption_fotokedatangan)+
				": <B>"+vCaptionUpper+"</B>"+
			"");
			setShowview(true);
		}

		if((uDatamainArr[vObjdx].foto_arr||[]).length <= 0) {
			apiLoadfoto(_IDTABEL,vFunctionnext);
			return;
		}

		vFunctionnext();
	}
	const hdlKlikStart=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL<=0) return;

		const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionUpper = (UFunc.leadZero(uDatamainArr[vObjdx].id_booking,6)+" - "+(uDatamainArr[vObjdx].pasien_caption||"")).toUpperCase();
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vStatusstaff	= uDatamainArr[vObjdx].staff_start || "";

		if(vStatusstaff !== "")
			if((uTokenObj.userhak||"")!=="FREEROLE" && (uTokenObj.userhak||"")!=="ADMIN") {
				showToast(uBahasaObj.pesan_batalstartdisallowed||"pesan_batalstartdisallowed"); return;
			}

		const vPesanwarn	= vStatusstaff===""
			? (uBahasaObj.pesan_warnstartmulai||"pesan_warnstartmulai")
			: (uBahasaObj.pesan_warnstartbatal||"pesan_warnstartbatal");

		const vConfirm	= 
			(uBahasaObj.caption_layananstart||"caption_layananstart")+": <B>"+
			vCaptionUpper+"</B>"+
			"<BR>"+vPesanwarn+
			"<BR><BR><B>"+(uBahasaObj.confirm_yakin||"confirm_yakin")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) {
			apiProsesstart(_IDTABEL);
		}
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initFormreset=()=>{
		setFormhadirObj({})
		setInpLayananbaruID("0");
		setInpDoneketerangan("");
		//console.log("initFormreset - uDatamainArr => "+JSON.stringify(uDatamainArr));
	}
	const initProseskedatangan=()=>{
		/*const vSuhu = parseInt(uInpSuhu)||0;
		if(vSuhu<=0) {
			document.getElementById("inpsuhu").focus();
			showToast((uBahasaObj.caption_suhubadan||"caption_suhubadan")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		const vAnamnesa = (uInpAnamnase || "").trim();
		if(vAnamnesa === "") {
			document.getElementById("inpanamnase").focus();
			showToast((uBahasaObj.word_anamnesa||"word_anamnesa")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
			return;
		}//-*/

		let vElFocus = document.getElementById("inppaymentcaption");
		if(parseInt(uFormhadirObj.nilai_dp||"0") > 0)
			if(parseInt(uFormhadirObj.payment_id||"0") <= 0) {
				vElFocus && vElFocus.focus();
				showToast((uBahasaObj.caption_metodebayar||"caption_metodebayar")+" "+(uBahasaObj.caption_mustchoose||"caption_mustfilled")+"..");
				return;
			}

		//console.log("initProseskedatangan - uInpFotoarr => "+JSON.stringify(uInpFotoarr)); 
		//setIsShowForm(false); return;//-*/
		apiEditkedatangan(uTabelIDPilih);
	}
	const initProsestransfer=()=>{
		const vLayanan = (uInpLayananbaruID || "").toUpperCase();
		if(vLayanan==="") {
			document.getElementById("inplayananbaruid").focus();
			showToast((uBahasaObj.caption_jenislayanan||"caption_jenislayanan")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}

		apiLayanantransfer();
	}
	const initProsesdone=()=>{ apiLayanandone(uTabelIDPilih); }
	//---END INIT_FUNCTION--/

	//---API_FUNC--/
	const apiLoaddata=()=>{
		uFuncname = apiLoaddata.name;
		setHTMLError500();

		const vKeyword		= uKeywordObj.kwd_ongoing || "";

		setJmlData(0);
		setJmlLayanan(0);
		//setLayanannoteArr([]);
		setDatamainArr([]);
		setPageActive(1);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setLoading(true);

		/*/---TESTING_FRONTEND--/
		const vTmpArr	= [];
		const vLayanannoteArr	= []

		let vTimeout	= setTimeout(function(){
			clearTimeout(vTimeout);
		},1500);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_firstload : uInitfirstload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ongoing/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				//setLayanannoteArr(JSON.parse(output_string.layanannote || "[]"));

				const vDatamainArr = JSON.parse(output_string.dataobject || "[]");
				setDatamainArr(vDatamainArr);
				setJmlData(vDatamainArr.length);
				setJmlLayanan(vDatamainArr.length);

				setInitfirstload("TIDAK");

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadfoto=(_IDTABEL,_NEXTFUNC)=>{
		uFuncname = apiLoadfoto.name;
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL<=0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vObjdx].isActionProcessing = true;

		/*//---TESTING_FRONTEND--/
		let vTimeout	= setTimeout(function(){
			clearTimeout(vTimeout);

			uDatamainArr[vObjdx].isActionProcessing = false;
			if(_NEXTFUNC && {}.toString.call(_NEXTFUNC) === '[object Function]')
				_NEXTFUNC();
		},2500);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ongoing/ld_foto";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vObjdx].isActionProcessing = false;
			if(output_string.tampil) {
				uDatamainArr[vObjdx].foto_arr = JSON.parse(output_string.datafoto||"[]");
				if(_NEXTFUNC && {}.toString.call(_NEXTFUNC) === '[object Function]')
					_NEXTFUNC();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjdx].isActionProcessing = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiBatalhadir=(_IDTABEL)=>{
		uFuncname = apiBatalhadir.name;
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL<=0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vLayananSelected = uInpLayanan;
		setFormprocess(true);
		uElBtndlgsimpan && (uElBtndlgsimpan.disabled = true);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		let vTimeout	= setTimeout(function(){
			clearTimeout(vTimeout);
		},1500);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ongoing/pr_batal";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setFormprocess(false);
			uElBtndlgsimpan && (uElBtndlgsimpan.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				setInpLayanan("");
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData(uDatamainArr.length);
				setInpLayanan(vLayananSelected);
			
				setIsShowForm(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setFormprocess(false);
			uElBtndlgsimpan && (uElBtndlgsimpan.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiEditkedatangan=(_IDTABEL)=>{
		uFuncname = apiEditkedatangan.name;
		
		const vTabelID = parseInt(_IDTABEL)||0; if(vTabelID<=0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		const vLayananSelected = uInpLayanan;
		setFormprocess(true);
		uElBtndlgsimpan && (uElBtndlgsimpan.disabled = true);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_berat : uFormhadirObj.berat||"0",
			send_keluhan : uFormhadirObj.keluhan||"",
			send_tglkeluartitip : uFormhadirObj.tgl_keluar||"",
			send_dokterid : uFormhadirObj.id_dokter||"0",
			send_fotolist : JSON.stringify(uFormhadirObj.foto_arr||"[]"),
			send_nilaidp : uFormhadirObj.nilai_dp||"0",
			send_paymentid : uFormhadirObj.payment_id||"0",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ongoing/pr_editkedatangan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setFormprocess(false);
			uElBtndlgsimpan && (uElBtndlgsimpan.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vObjdx].keluhan 	= (uFormhadirObj.keluhan||"").trim();
				uDatamainArr[vObjdx].berat_badan= (uFormhadirObj.berat||"0");
				uDatamainArr[vObjdx].dokter_id	= (uFormhadirObj.id_dokter||"0");
				uDatamainArr[vObjdx].dokter_opname	= output_string.doktercaption||"";//-->DARI_API
				uDatamainArr[vObjdx].tgl_keluar_titip= (uFormhadirObj.tgl_keluar||"");
				uDatamainArr[vObjdx].nilai_dp	= (uFormhadirObj.nilai_dp||"0");
				uDatamainArr[vObjdx].payment_id	= (uFormhadirObj.payment_id||"0");

				const vFotodlgArr	= JSON.parse(output_string.datafoto||"[]");//-->DARI_API_KARENA_BUTUH_ID
				const vIsAdafoto 	= vFotodlgArr.length > 0 ? true : false;

				uDatamainArr[vObjdx].isAdafoto 	= vIsAdafoto;
				uDatamainArr[vObjdx].foto_arr 	= vFotodlgArr;

				hdlToggleForm();
				//setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setFormprocess(false);
			uElBtndlgsimpan && (uElBtndlgsimpan.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLayanantransfer=()=>{
		uFuncname = apiLayanantransfer.name;
		
		const vTabelID = parseInt(uTabelIDPilih)||0;
		if(vTabelID<=0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		const vLayananSelected = uInpLayanan;
		uElBtndlgsimpan && (uElBtndlgsimpan.disabled = true);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		let vTimeout	= setTimeout(function(){
			uElBtndlgsimpan && (uElBtndlgsimpan.disabled = false);

			const vIdxLayanan	= UFunc.getObjectIndeks(uLayanannoteArr,uInpLayananbaruID,"value");

			uDatamainArr[vObjdx].layanan_caption 	= uLayanannoteArr[vIdxLayanan].caption;
			uDatamainArr[vObjdx].layanan_value 		= uInpLayananbaruID;

			//setEfekview(!uEfekview);

			setLoading(false);
			setIsShowForm(false);
			clearTimeout(vTimeout);
		},1500);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_layananbaruid : uInpLayananbaruID,
			send_tokenauth : uTokenObj.userinit
		});
		console.log("vDATAS = "+vDATAS)
		const vURLs	= pjson.homepage+"api/api_ongoing/pr_transfer";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uElBtndlgsimpan && (uElBtndlgsimpan.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				setIsShowForm(false);
				const vIdxLayanan	= UFunc.getObjectIndeks(uLayananArr,uInpLayananbaruID);

				uDatamainArr[vObjdx].layanan_caption= uLayananArr[vIdxLayanan].caption_layanan;
				uDatamainArr[vObjdx].layanan_value	= uLayananArr[vIdxLayanan].value_layanan;
				uDatamainArr[vObjdx].layanan_vid 	= uInpLayananbaruID;
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtndlgsimpan && (uElBtndlgsimpan.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLayanandone=(_IDTABEL)=>{
		uFuncname = apiLayanandone.name;
		const vTabelID = parseInt(_IDTABEL)||0;
		if(vTabelID <= 0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		const vLayananSelected = uInpLayanan;
		uElBtndlgsimpan && (uElBtndlgsimpan.disabled = true);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		let vTimeout	= setTimeout(function(){
			clearTimeout(vTimeout);
		},2500);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_doneketerangan : uInpDoneketerangan,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ongoing/pr_done";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			uElBtndlgsimpan && (uElBtndlgsimpan.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				setInpLayanan("");
				setIsShowForm(false);

				uDatamainArr.splice(vObjdx,1);
				setTabelIDPilih(0);
				setTabelRowSelect(-1);
				setJmlData(uDatamainArr.length);
				setInpLayanan(uInpLayanan);
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(false); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtndlgsimpan && (uElBtndlgsimpan.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesstart=(_IDTABEL)=>{
		uFuncname = apiProsesstart.name;
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL<=0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vObjdx].isActionProcessing = true;
		setEfekview2(!uEfekview2);

		/*//---TESTING_FRONTEND--/
		let vTimeout	= setTimeout(function(){
			clearTimeout(vTimeout);
		},2500);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_actionto: (uDatamainArr[vObjdx].staff_start||"")!=="" ? "STOP" : "START",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_ongoing/pr_start";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vObjdx].isActionProcessing = false;
			if(output_string.sukses) {
				uDatamainArr[vObjdx].staff_start = 
					(uDatamainArr[vObjdx].staff_start||"")!=="" ? ""
					: uTokenObj.usercaption||"UNDF";
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjdx].isActionProcessing = false;
			setEfekview(!uEfekview);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---Init_CONTENT--/
	const loadContentMain=()=>{
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		let vCaptionUpper	= "";
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_ongoing || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataViewSelectArr= uInpLayanan==="0" ? uDatamainArr 
			: uDatamainArr.filter((vItems,vKeys)=> (vItems.layanan_value||"0").toUpperCase() === (uInpLayanan||"0").toUpperCase());
		//const vDataViewObj	= isMobile ? vDataViewSelectArr.slice(0,(vMax)) : vDataViewSelectArr.slice(vMin,(vMax));
		const vDataViewObj	= vDataViewSelectArr.slice(vMin,(vMax));
		const vDatetimeNow	= uTimeStampNow;

		const vIsKolomstandar = (uInpLayanan==="PENITIPAN"
			||uInpLayanan==="OPNAME"||uInpLayanan==="GROOMING") ? false : true;

		let vKolomCaption	= "UNDF";
		if(uInpLayanan==="PENITIPAN") vKolomCaption = uBahasaObj.caption_tglkeluar;
		else if(uInpLayanan==="OPNAME") vKolomCaption = uBahasaObj.caption_dokterpenanggungjawab;
		else if(uInpLayanan==="GROOMING") vKolomCaption = uBahasaObj.caption_ketbooking;

		return (
		<table className="table table-borderless table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width="5%" className="px-1">No</th>
		<th className="px-1">
			<CRow className="mx-0">
			<CCol md={vIsKolomstandar?"3":"2"} className="text-start">{uBahasaObj.word_pasien||"word_pasien"}</CCol>
			<CCol md={vIsKolomstandar?"2":"2"} className="">{uBahasaObj.caption_jamdatang||"caption_jamdatang"}</CCol>
			{!vIsKolomstandar && (
				<CCol md="2" className="text-start">{vKolomCaption}</CCol>
			)}
			<CCol md={vIsKolomstandar?"4":"3"} className="text-start">{uBahasaObj.word_keluhan}/{uBahasaObj.word_keterangan}</CCol>
			<CCol md="2" className="">{uBahasaObj.word_staff||"word_staff"}</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
		</th>
		</tr>
		</thead>

		<tbody>
		{vDataViewObj.map((vItems,vKeys)=>{
			const {
				id,id_booking,tgl_hadir,pasien_caption,keluhan,pemilik_caption,
				pemilik_telp,ket_booking,tgl_keluar_titip,dokter_opname,berat_badan,
				layanan_value,layanan_caption,staff_start,isAdafoto,
				isActionProcessing
			} = vItems;

			vNumber++;
			vCaptionUpper	= (pasien_caption||"").toUpperCase();

			const vPasiennama	= vKeyword==="" ? (pasien_caption||"") 
				: (pasien_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vPemilikcaption	= vKeyword==="" ? (pemilik_caption||"-") 
				: (pemilik_caption||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vPemiliktelp	= vKeyword==="" ? (pemilik_telp||"") 
				: (pemilik_telp||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKetbooking	= vKeyword==="" ? (ket_booking||"-") 
				: (ket_booking||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKeluhan	= vKeyword==="" ? (keluhan||"") 
				: (keluhan||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vDokteropname	= vKeyword==="" ? (dokter_opname||"-") 
				: (dokter_opname||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vStaff	= vKeyword==="" ? UFunc.Usercaptionfilter(staff_start||"",2) 
				: UFunc.Usercaptionfilter(staff_start||"",2).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			const vTglHadirDT	= UFunc.toDatetime(tgl_hadir||"");
			const vSelisihObj	= UFunc.Datetimeselisih(vTglHadirDT,vDatetimeNow);
			const vWaitingCaption = vSelisihObj.mnt <= 0 ? ""
				: (vSelisihObj.jam <= 0 ? vSelisihObj.mnt+" Menit" 
					: (vSelisihObj.day <=  0 ? "&#8805; "+vSelisihObj.jam+" Jam" : "&#8805; 1 hari")
					);
			const vArrSelect	= (uLayananArr.filter((vItemslayanan,vKeyslayanan)=> 
				(vItemslayanan.value_layanan).toUpperCase() === (layanan_value||"").toUpperCase()
			));//-*/
			const vBgColor 		= vArrSelect.length > 0 ? vArrSelect[0].color_layanan : "DCDCDC";

			const vBerat		= parseFloat(berat_badan||"0") <= 0 
				? (<b>BELUM DITIMBANG</b>)
				: (<><b>{UFunc.formatAngka(berat_badan||0)}</b> KG</>)

			const vKedatangan	= (uInpLayanan==="PENITIPAN"||uInpLayanan==="OPNAME"
				//||vSelisihObj.day >= 1)
				|| UFunc.DbDate() !== UFunc.DbDate(vTglHadirDT)
				)
				? "<small>"+UFunc.WaktuAngka(tgl_hadir||"")+"</small>" : UFunc.JamMenit(tgl_hadir||"");

			//console.log("(Jadwal-vArrSelect) vArrSelect ("+vKey+") => "+JSON.stringify(vArrSelect[0] || []));
			let vKolomtambahan	= "-";
			if(uInpLayanan==="PENITIPAN") vKolomtambahan = UFunc.TglAngka(tgl_keluar_titip);
			else if(uInpLayanan==="OPNAME") vKolomtambahan = UFunc.renderHTML(vDokteropname);
			else if(uInpLayanan==="GROOMING") vKolomtambahan = UFunc.renderHTML("<span class='text-success'>"+vKetbooking+"</span>");

			const vActionmenu=()=>{
				return (
					<CDropdown>
					<CDropdownToggle color="transparent" className="p-0 m-0"
						caret={false}>
						<CIcon icon="cilChevronBottom" className="classikoncoreui d-none d-md-block" height={25}/>
						<CIcon icon="cilOptions" className="classikoncoreui d-md-none" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu className="pt-0">
						<CDropdownItem 
							className="classcursorpointer"
							onClick={()=>hdlKlikKedatanganedit(id)}>
							<CIcon className="classikonedit align-middle mx-0 me-2" height={27}/>
							{uBahasaObj.word_edit||"Edit"} {uBahasaObj.word_kedatangan||"word_kedatangan"}
						</CDropdownItem>
						<CDropdownItem 
							className="classcursorpointer"
							onClick={()=>hdlKlikTreatment(id)}>
							<CIcon className="classikontreatment classikoncoreui mx-0 me-2" height={25}/>
							{uBahasaObj.word_pemeriksaan||"word_pemeriksaan"}
						</CDropdownItem>

						<CDropdownDivider/>

						<CDropdownItem 
							className="classcursorpointer fw-bolder text-success"
							onClick={()=>hdlKlikTransfer(id)}>
							<CIcon className="classikontransfer classikoncoreui mx-0 me-2" height={25}/>
						{uBahasaObj.word_transfer}
						</CDropdownItem>

						{uIsEOMExecuted ? (
						<CDropdownItem 
							className="classcursorpointer fw-bolder text-primary"
							onClick={()=>hdlKlikDone(id)}>
						<CIcon className="classikonchecked align-middle mx-0 me-2" height={25} />
						{uBahasaObj.word_done}
						</CDropdownItem>
						) : (
						<CDropdownItem className="text-danger">
							<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classblinking classikoncoreui mx-0 me-2" height={25}/>
						{uBahasaObj.word_done} Unavailable
						</CDropdownItem>
						)}
					</CDropdownMenu>
					</CDropdown>
				);
			}

			return (
			<tr id={"idtr"+id} key={vKeys} 
				style={{backgroundColor:"#"+(vBgColor)+"22"}}
				className={"align-top text-end "+(uTabelRowSelect===vKeys?"border border-warning":"classborderbottom classborderdotted")}>
				<td className="px-1 pe-0">{vNumber}.</td>
				<td className="px-1">
				
				<CRow className="mx-0">
				<CCol md={vIsKolomstandar?"3":"2"} className="px-1 text-start d-flex justify-content-between align-items-start">
				<div>
					<div className="text-info fw-bolder">{UFunc.renderHTML(vPasiennama)}</div>
					<small className="d-none d-md-block">{UFunc.renderHTML(vPemilikcaption)}</small>
				</div>
				<div className="d-md-none">
					{(isActionProcessing||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>{vActionmenu()}</>
					)}
				</div>
				</CCol>
				<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>
				
				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_pemilik}</CCol>
				<CCol xs="8" md="2" className="px-1 d-md-none">
					<strong>{UFunc.renderHTML(vPemilikcaption)}</strong>
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_jamdatang}</CCol>
				<CCol xs="7" md={vIsKolomstandar?"2":"2"} className="px-1 px-md-0 text-md-center">
					{UFunc.renderHTML(vKedatangan)}
					{((staff_start||"")==="" && vWaitingCaption!=="") && (
					<>
					<div className="d-none d-md-block">
					<CBadge color="danger" className="px-2">{UFunc.renderHTML(vWaitingCaption)}</CBadge>
					</div>
					<span className="d-md-none ms-2">
					<CBadge color="danger" className="px-2">{UFunc.renderHTML(vWaitingCaption)}</CBadge>
					</span>
					</>
					)}
				</CCol>
				
				{!vIsKolomstandar && (
				<>
				<CCol xs="5" className="px-1 text-start d-md-none">{vKolomCaption}</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-start">
					<small className="d-none d-md-block">{vKolomtambahan}</small>
					<span className="d-md-none">{vKolomtambahan}</span>
				</CCol>
				</>
				)}
				
				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_keterangan}</CCol>
				<CCol xs="8" md={vIsKolomstandar?"4":"3"} className="px-1 text-md-start">
					<span className="fst-italic">{UFunc.renderHTML(vKeluhan)}</span>
					<div className="d-none d-md-block classfontsmaller">
						{uBahasaObj.caption_beratbadan||"Berat Badan"}: {vBerat}
					</div>
					{(isAdafoto||false)===true && (isActionProcessing||false)===false && (
					<div className="d-none d-md-block">
					<CTooltip content={"--"+(uBahasaObj.caption_fotokedatangan)+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikfoto(id)} className="classcursorpointer classikon classikonfoto"/>
					</CTooltip>
					</div>
					)}
					{((keluhan||"")==="" && (berat_badan||"0")==="0" 
						&& (isAdafoto||false)!==true) && (<></>)}
					{((keluhan||"")==="") && (<span className="d-md-none">-</span>)}
				</CCol>
				
				{(layanan_value||"")==="PERIKSA" && (
				<>
				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_beratbadan}</CCol>
				<CCol xs="7" md="2" className="px-1 d-md-none">
					<strong>{UFunc.formatAngka(berat_badan||0)}</strong> KG
				</CCol>
				</>
				)}

				{((isAdafoto||false)===true && (isActionProcessing||false)===false) && (
				<>
				<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>
				<CCol xs="12" className="px-1 text-center d-md-none">
					<CLink 
						className="classcursorpointer p-1 px-3 pb-2"
						style={{
							border:"1px dotted #FCC455",
							backgroundColor:"#FCC45555",
							color:"#EB7F0C",
							borderRadius:5
						}}
						onClick={()=>hdlKlikfoto(id)}>
						<CImage className="classikon classikonfoto"/>
						{uBahasaObj.caption_fotokedatangan}
					</CLink>
				</CCol>
				</>
				)}

				<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>
				
				{(staff_start||"")!=="" && (
				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.word_staff}</CCol>
				)}
				<CCol xs={(staff_start||"")!==""?"7":"12"} md="2" 
					className={((staff_start||"")!==""?"":"text-center ")+"px-1 text-md-center"}>
					{(isActionProcessing||false)===true ? (
					<>
					<div className="d-none d-md-block">
						<CSpinner color="primary" size="sm"/>
					</div>
					&nbsp;
					</>
					) : (
						<>
						{(staff_start||"")==="" ? (
							<CButton size="sm"
								color="primary"
								className="px-3"
								onClick={()=>hdlKlikStart(id)}
								id="btnstart">START</CButton>
						) : (
						<>
							{uTokenObj.userhak==="FREEROLE" ? (
							<CTooltip content="--Klik Untuk MEMBATALKAN START">
							<CLink 
								onClick={()=>hdlKlikStart(id)}
								className="classcursorpointer text-danger text-decoration-none">[{UFunc.renderHTML(vStaff)}]</CLink>
							</CTooltip>
							) : (
							<span className="text-danger">[{UFunc.renderHTML(vStaff)}]</span>
							)}
						</>
						)}
						</>
					)}
				</CCol>
				
				<CCol md="1" className="px-0 text-md-center d-none d-md-block">
					{(isActionProcessing||false)===false && (
					<>{vActionmenu()}</>
					)}
				</CCol>
				</CRow>

				</td>
			</tr>
			);
		})}
		</tbody>
		</table>
		)//>
	}
	const loadContentform=()=>{
		if(!uIsShowForm) return (<></>)//>

		if(uForminit === "KEHADIRAN") return loadFormkedatangan();
		else if(uForminit === "TRANSFER") return loadFormtransfer();
		else if(uForminit === "DONE") return loadFormdone();
		else return (<></>)//>
	}
	const loadFormkedatangan=()=>{
		if(uForminit !== "KEHADIRAN") return (<></>)//>
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		if(vObjdx < 0) return (<></>)//>

		const vNilaiDP		= parseInt(uDatamainArr[vObjdx].nilai_dp||"0");
		const vTglHadirDT	= UFunc.toDatetime(uDatamainArr[vObjdx].tgl_hadir);

		return (
		<CRow className="mx-0">
		{uTokenObj.userhak === "FREEROLE" && (
		<CCol md="4" className="px-0 pe-md-2">
			<CCard className="classcardbg mb-3">
			<CCardHeader className="py-2 text-muted classfontsmaller">
				{uBahasaObj.caption_pembatalankehadiran||"caption_pembatalankehadiran"}
			</CCardHeader>
			<CCardBody className="px-0 text-center">
			{vNilaiDP > 0 && (
			<>
				<CRow className="mx-0 my-1 text-start">
				<CCol xs="7"><small className="classfontsmaller">Nilai DP <i>(Ketika Hadir)</i></small></CCol>
				<CCol className="text-end text-success"><b>Rp{UFunc.formatAngka(vNilaiDP)}</b></CCol>
				</CRow>
				{(UFunc.DbDate() !== UFunc.DbDate(vTglHadirDT)) && (
				<>
					<div className="my-2 classborderbottom classborderdotted"/>
					<div className="mx-3 text-primary text-start fst-italic">
					*) Nilai DP Tidak Ikut DIBATALKAN, Jika Anda memerlukan Pembatalan Nilai DP, 
					Lakukan melalui Menu KASIR >> <b>Titipan Uang Muka</b>
					</div>
				</>
				)}
				<div className="my-2 classborderbottom classborderdotted"/>
			</>
			)}
			<div className="classpetunjuk text-start">*) {uBahasaObj.petunjuk_batalhadir||"petunjuk_batalhadir"}</div>
			<div className="my-2 classborderbottom classborderdotted"/>
			<MyButton size="sm"
				disabled={uIsFormprocess}
				onClick={hdlKlikBatal}
				id="btnBatalhadir">
				{uBahasaObj.word_batal||"word_batal"}
			</MyButton>
			</CCardBody>
			</CCard>
		</CCol>
		)}

		<CCol className="px-0">
			<CCard className="classcardbg">
			<CCardHeader className="py-2 text-muted classfontsmaller">{uBahasaObj.caption_editdata}</CCardHeader>
			<CCardBody className="px-0">
			<MyFormHadir
				objDataparse={uFormhadirObj}
				{...props}/>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		)//>
	}
	const loadFormtransfer=()=>{
		if(uForminit !== "TRANSFER") return (<></>)//>

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		const vLayananTrfArr	= vObjdx >=0 
			? uLayananArr.filter((vItems,vKeys)=>{
				 return (vItems.id||"0").toString() !== (uDatamainArr[vObjdx].layanan_id||"0").toString()
			}) 
			: [];

		return (
		<CForm className="mx-md-5">
		<CRow className="mx-0 my-1">
			<CCol md="12" className="px-1">
			{(uBahasaObj.caption_layananbefore||"caption_layananbefore")}:{" "}
			<strong>{uDatamainArr[vObjdx].layanan_caption.toUpperCase()}</strong>
			</CCol>
		</CRow>
		<CRow className="mx-0 my-2">
			<CCol md="12" className="px-1 classborderbottom"/>
		</CRow>
		<CRow className="mx-0 my-1">
			<CCol md="12" className="px-1">{(uBahasaObj.caption_transferke||"caption_transferke")}</CCol>
			<CCol md="12"  className="px-1">
				<CFormSelect size="sm"
					className="text-uppercase"
					value={uInpLayananbaruID}
					onChange={(e)=>setInpLayananbaruID(e.target.value)}
					id="inplayananbaruid">
				{vLayananTrfArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.id} key={vKeys}>
					{(vItems.caption_layanan||"").toUpperCase()}
					</option>
					);
				})}
				</CFormSelect>
			</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const loadFormdone=()=>{
		if(uForminit !== "DONE") return (<></>)//>

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		const vCaptionUpper = (UFunc.leadZero(uDatamainArr[vObjdx].id,6)+" - "+(uDatamainArr[vObjdx].pasien_caption||"")).toUpperCase();

		return (
		<CForm className="mx-0">
		<CRow className="mx-0 my-1">
			<CCol md="12" className="px-1">
			{(uBahasaObj.word_pasien||"Pasien")}:{" "}
			<strong>{uDatamainArr[vObjdx].pasien_caption.toUpperCase()}</strong>
			<div className="mt-2 fst-italic">{UFunc.renderHTML(uBahasaObj.pesan_warnlayananselesai)}</div>
			</CCol>
		</CRow>
		<CRow className="mx-0 my-2">
			<CCol md="12" className="px-1 classborderbottom"/>
		</CRow>
		<CRow className="mx-0 my-1">
			<CCol md="12" className="px-1">{(uBahasaObj.caption_catatankasir||"caption_catatankasir")}</CCol>
			<CCol md="12"  className="px-1">
				<CFormInput size="sm" type="text"
					maxLength="150"
					className="text-capitalize"
					value={uInpDoneketerangan}
					onChange={(e)=>setInpDoneketerangan(e.target.value)}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpdoneketerangan"/>
			</CCol>
		</CRow>
		<CRow className="mx-0 my-3">
			<CCol md="12" className="px-1 fw-bolder">{uBahasaObj.confirm_yakin}</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const loadContentview=()=>{
		if(!uIsShowview) return (<></>)//>

		return (
		<ImageGallery 
			items={uFotokedatanganArr}/>
		)//>
	}
	//---END Init_CONTENT--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		setShowview(false);
		setIsShowForm(false);

		uDispatch({type: "set", gStatePasien: ""});
		uDispatch({type: "set", gRoutebefore: window.location.pathname + window.location.search});
    	//localStorage.setItem("lasturl",window.location.pathname + window.location.search);

		setInpLayanan(uLayananselect);
    	apiLoaddata();

		let vTimeout = setTimeout(function(){
			setTimeStampNow(new Date());
			setEfekview(!uEfekview)
		},60000);

    	return ()=>{
    		clearTimeout(vTimeout);
    		setDatamainArr([]);
    		setFormhadirObj({});
    		setInpLayanan("0")
    	}
	},[]);
	React.useEffect(()=>{
		uElsJmlData && (uElsJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uElsJmlData,uJmlData]);
	React.useEffect(()=>{
		if(!uIsInitbefore) return;

		const vPageidx	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageidx);
	},[uIsInitbefore]);
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= parseInt(uTabelSelectObj.initID || -1);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+vInitID);
		setTabelRowSelect(vRowIdx);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlLayanan / uMaxData);
		setJmlHal(vJmlHal);//-*/

		if(uInitPageBefore > 1) {setPageActive(uInitPageBefore); return}
		setPageActive(uPageActive<=0?1:uPageActive);
	},[uJmlLayanan]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uHandelView) return;
		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView]);
	React.useEffect(()=>{
		setPageActive(1);
		setTabelRowSelect(-1);
		uDispatch({type: "set", gLayananselect: uInpLayanan});
		if(uInpLayanan==="0") {
			setColorlayanan("initial")
			setJmlLayanan(uDatamainArr.length);
			return;
		}
		const vLayananSelectedArr = 
			uDatamainArr.filter((vItems,vKeys)=> (vItems.layanan_value||"0").toUpperCase() === (uInpLayanan||"0").toUpperCase());
		setJmlLayanan(vLayananSelectedArr.length);

		const vArridx 	= UFunc.getObjectIndeks(uLayananArr,uInpLayanan,"value_layanan");
		setColorlayanan("#"+uLayananArr[vArridx].color_layanan||"000000");
	},[uDatamainArr,uInpLayanan]);
	React.useEffect(()=>{
		if(!uIsShowForm) { initFormreset();setTabelIDPilih(0); return; }

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			setElBtndlgsimpan(document.getElementById("btnDialogSimpan"))

			let vElFocus;
			if(uForminit === "KEHADIRAN") {
				vElFocus	= document.getElementById("inptgltitipexit")
				if(!vElFocus) {
					vElFocus	= document.getElementById("inpberat")
				}
			} else if(uForminit === "TRANSFER") {
				vElFocus	= document.getElementById("inplayananbaruid")
			} else if(uForminit === "DONE") {
				vElFocus	= document.getElementById("inpdoneketerangan")
			}
			vElFocus && vElFocus.focus();
		},350);
	},[uIsShowForm]);
	React.useEffect(()=>{
		if(!uIsShowForm) return;
		//if(uInpLayananbaruID!=="") return;

		//console.log("uInpLayananbaruID = "+uInpLayananbaruID);
		if(uInpLayananbaruID === undefined || uInpLayananbaruID.toString() === "0") {
			const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
			const vLayananTrfArr= uLayananArr.filter((vItems,vKeys)=>{
				return (vItems.value_layanan||"").toUpperCase() !== uDatamainArr[vObjdx].layanan_value.toUpperCase()
			});

			setInpLayananbaruID(vLayananTrfArr[0].id);
			console.log("vLayananTrfArr[0].id = "+vLayananTrfArr[0].id)
		}
	},[uIsShowForm]);
	React.useEffect(()=>{ if(!uIsShowview) setFotokedatanganArr([]); },[uIsShowview]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_cComponentName
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return (<></>); }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>
	
	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="py-1 d-flex justify-content-between align-items-center">
			<div>
			<CFormSelect size="sm"
				style={{minWidth:125,width:"auto"}}
				value={uInpLayanan}
				className="d-inline-block"
				onChange={(e)=>setInpLayanan(e.target.value)}
				id="inplayanan">
				<option value="0">--{(uBahasaObj.caption_semualayanan||"caption_semualayanan").toUpperCase()}</option>
				{uLayananArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.value_layanan} 
						style={{color:"#"+(vItems.color_layanan||"000000")}}
						key={vKeys}>
					{(uBahasaObj["layanan_"+(vItems.value_layanan).toLowerCase()]||"").toUpperCase()}
					</option>
					);
				})}
			</CFormSelect>
			<span className="mx-1">&middot;</span>
			<small style={{color:uColorlayanan}}>(<b>{uJmlLayanan}</b>)</small>
			</div>

			<div className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{loadContentMain()}</CCardBody>

			{(uJmlLayanan > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:(uForminit==="TRANSFER"||uForminit==="DONE"?undefined:"xl"),centered:false}}
			onSimpan={()=>hdlKlikFormSimpan()}
			show={uIsShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={loadContentform()}/>

		<MyDialogview
			renderContent={loadContentview()}
			dialogHeader={uDialogHeader}
			toggle={hdlToggleview}
			options={{centered:false,bgColor:"black",size:"xl"}}
			show={uIsShowview}/>
		</>
	)//>
}
	
export default Ongoing;
