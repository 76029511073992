import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CCard,
	CCardBody,
	CCardHeader,
	CCardFooter,
	CTooltip,
	CLink,
	CSpinner,
	CForm, CFormInput, CFormTextarea, CInputGroup, CInputGroupText, CButton,
	CDropdown,CDropdownToggle,CDropdownHeader,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CImage,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyPagination,
	MyPage500,
	MyDialoglookupapi,
	MyDialoglookup,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Reminder	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uUserSettingObj 		= useSelector(state => state.gListUserSetting);
	const [uEfekview,setEfekview]= React.useState(false);
	const [uHTMLError500,setHTMLError500]= React.useState();

	const uSettingObj			= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uFilterObj			= uKeywordObj.filter_reminder || {};

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 		= uHeaderActionObj.isHeaderTambah || false;

	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uEfekview2,setEfekview2]= React.useState(false);

	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgforminputObj,setDlgforminputObj]= React.useState({});
	const [uElBtnDlgsimpan,setElBtnDlgsimpan]= React.useState(document.getElementById("btnDialogSimpan"));
	const [uDatapasienArr,setDatapasienArr]	= React.useState([]);

	const [uISDlglookupAshow,setDlglookupAshow]	= React.useState(false);
	const [uDlglookupAinputObj,setDlglookupAinputObj]= React.useState({});
	const [uISDlglookupAtrigger,setDlglookupAtrigger]= React.useState(false);

	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupinputObj,setDlglookupinputObj]= React.useState({});

	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowidx		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		const vCaptionupper	= ((uDatamainArr[vArridx].pasien_caption||"")+" - ("+(uDatamainArr[vArridx].customer_caption||"")+")").toUpperCase();

		setTabelRowSelect(vRowidx);
		setTabelIDPilih(_IDTABEL);
		uDlgforminputObj.customer_caption = uDatamainArr[vArridx].customer_caption;
		uDlgforminputObj.customer_id 	= uDatamainArr[vArridx].customer_id;
		uDlgforminputObj.pasien_caption = uDatamainArr[vArridx].pasien_caption;
		uDlgforminputObj.pasien_id 		= uDatamainArr[vArridx].pasien_id;
		uDlgforminputObj.tgl_reminder 	= uDatamainArr[vArridx].tgl_reminder || UFunc.DbDate();
		uDlgforminputObj.note_reminder 	= uDatamainArr[vArridx].note_reminder;
		uDlgforminputObj.userinput 	= uDatamainArr[vArridx].userinput;
		uDlgforminputObj.tgl_input 	= uDatamainArr[vArridx].tgl_input;
		//console.log("(hdlKlikEdit) uDatamainArr[vArridx].customer_id => "+uDatamainArr[vArridx].customer_id);

		uDlgforminputObj.header = (uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionupper;
		uDlgforminputObj.jenis 	= "FORMMAIN";
		setDlgformshow(true);//-*/
			
		apiLoadpasien(uDlgforminputObj.customer_id);
	}
	const hdlKlikPindah = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowidx		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		const vCaptionupper	= ((uDatamainArr[vArridx].pasien_caption||"")+" - ("+(uDatamainArr[vArridx].customer_caption||"")+")").toUpperCase();

		setTabelRowSelect(vRowidx);
		setTabelIDPilih(_IDTABEL);
		uDlgforminputObj.tgl_reminder 	= uDatamainArr[vArridx].tgl_reminder || UFunc.DbDate();
		uDlgforminputObj.tgl_pindah 	= uDatamainArr[vArridx].tgl_reminder || UFunc.DbDate();

		uDlgforminputObj.header = (uBahasaObj.caption_pindahtanggal||"Pindah Tanggal")+": "+vCaptionupper;
		uDlgforminputObj.jenis 	= "FORMPINDAH";
		setDlgformshow(true);//-*/
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowidx		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		const vCaptionupper	= ((uDatamainArr[vArridx].pasien_caption||"")+" - ("+(uDatamainArr[vArridx].customer_caption||"")+")").toUpperCase();

		setTabelRowSelect(vRowidx);
		setTabelIDPilih(_IDTABEL);

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>"+vCaptionupper+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiProseshapus(_IDTABEL);
	}
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKDownCustomercaption = (_EV) => {
		if(_EV.which !== 13) {
			uDlgforminputObj.customer_id 	= 0;
			uDlgforminputObj.pasien_caption = "";
			uDlgforminputObj.pasien_id 		= 0;
			setDatapasienArr([]);
			setEfekview(!uEfekview);
			return;
		}
		if((uDlgforminputObj.customer_caption||"").trim() === "") return;
		_EV.preventDefault(); _EV.stopPropagation();

		setDlglookupAtrigger(true);
		hdlKlikCustomerlookup();
	}
	const hdlKlikCustomerlookup = () => {
		uDlglookupAinputObj.targetapi = pjson.homepage+"api/api_customer/ld_lookup";
		uDlglookupAinputObj.keyword = uDlgforminputObj.customer_caption;
		uDlglookupAinputObj.header 	= (uBahasaObj.word_lookup||"word_lookup")+
			": "+(uBahasaObj.word_customer||"word_customer").toUpperCase();
		setDlglookupAshow(true);
	}
	const hdlToggleDlglookupA = () => { setDlglookupAshow(false) }
	const hdlKlikDlglookupApilih = (_SELECTEDSTR) => {
		_SELECTEDSTR = _SELECTEDSTR||"{}";
		const vLookupObj	= JSON.parse(_SELECTEDSTR);
		if(vLookupObj==={}) return;

		uDlgforminputObj.pasien_caption = "";
		uDlgforminputObj.pasien_id 		= 0;
		uDlgforminputObj.customer_caption = vLookupObj.nama_customer+" - "+
			UFunc.leadZero(vLookupObj.id,5);
		uDlgforminputObj.customer_id = vLookupObj.id || "0";
		hdlToggleDlglookupA();
		apiLoadpasien(vLookupObj.id||"0");

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			let vElfocus	= document.getElementById("inppasiencaption");
			vElfocus && vElfocus.focus();
		},350);
	}
	const hdlKlikPasienlookup = () => {
		//console.log("(hdlKlikPasienlookup) uDlgforminputObj.customer_id => "+uDlgforminputObj.customer_id);
		if((parseInt(uDlgforminputObj.customer_id)||0) <= 0) return;

		uDlgforminputObj.pasien_caption = "";
		uDlgforminputObj.pasien_id 		= 0;
		uDlglookupinputObj.items_arr = (uDatapasienArr || []).map(
			vItems=> { return {id:vItems.id,caption:vItems.nama_pasien} });
		uDlglookupinputObj.keyword 	= "";
		uDlglookupinputObj.header 	= (uBahasaObj.word_lookup||"word_lookup")+
			": "+(uBahasaObj.word_pasien||"word_pasien").toUpperCase();
		setDlglookupshow(true);
	}
	const hdlToggleDlglookup = () => { setDlglookupshow(false) }
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM||0); if(_IDITEM <= 0) return;

		const vLookupdataArr = uDlglookupinputObj.items_arr || [];
		const vArridx 	= UFunc.getObjectIndeks(vLookupdataArr,_IDITEM);
		const vValue	= vLookupdataArr[vArridx].id||"0";
		const vCaption	= vLookupdataArr[vArridx].caption||"";

		uDlgforminputObj.pasien_caption = vCaption;
		uDlgforminputObj.pasien_id 		= vValue;
		hdlToggleDlglookup();

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElfocus = document.getElementById("inptanggal");
			vElfocus && vElfocus.focus();
		},350)
	}

	const hdlKlikDlgformsimpan = () => {
		if(uDlgforminputObj.jenis === "FORMMAIN") initKlikmain();
		else if(uDlgforminputObj.jenis === "FORMPINDAH") initKlikpindah();
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		uElJmldata && 
			(uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initKlikmain = () => {
		let vElfocus = document.getElementById("inpcustomercaption");
		if(parseInt(uDlgforminputObj.customer_id||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Customer Harus Dipilih Dahulu !"); return;
		}
		vElfocus = document.getElementById("inppasiencaption");
		if(parseInt(uDlgforminputObj.pasien_id||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Pasien Harus Dipilih Dahulu !"); return;
		}
		vElfocus = document.getElementById("inptanggal");
		if((uDlgforminputObj.tgl_reminder||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Reminder Tidak Valid !"); return;
		}
		const vTanggalDT = UFunc.toDate(uDlgforminputObj.tgl_reminder);
		if(UFunc.DateIsMundur(vTanggalDT)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Reminder Sudah Terlewati !"); return;
		}
		vElfocus = document.getElementById("inpnotereminder");
		if((uDlgforminputObj.note_reminder||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("Note Reminder Harus Diisi !"); return;
		}

		apiProsessimpan();
	}
	const initKlikpindah = () => {
		let vElfocus = document.getElementById("inptglpindah");
		if((uDlgforminputObj.tgl_pindah||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Reminder (BARU) Tidak Valid !"); return;
		}
		const vTanggalDT = UFunc.toDate(uDlgforminputObj.tgl_pindah);
		if(UFunc.DateIsMundur(vTanggalDT)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Reminder (BARU) Sudah Terlewati !"); return;
		}

		apiProsespindah();
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return (<></>)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uFilterObj.keyword || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<>
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center classfontsmaller">
			<th width="5%" className="align-top">No</th>
			<th className="align-top text-start">
				<CRow className="mx-0">
				<CCol md="2" className="">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="2" className="">{uBahasaObj.word_customer||"Customer"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_namahewan||"Nama Hewan"}</CCol>
				<CCol md="3" className="">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_madeorder||"Made Order"}</CCol>
				<CCol md="1" className="text-center">&middot;</CCol>
				</CRow>
			</th>
		</tr>
		<tr className="d-md-none classfontsmaller">
			<th width={30} className="p-0"/>
			<th className="p-0"/>
		</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				customer_caption,pasien_caption,tgl_reminder,
				customer_id,pasien_id,
				tgl_input,userinput,note_reminder,
				id,is_processing
			} = vItems

			vNumber++;
			const vCaptionupper	= (pasien_caption||"").toUpperCase()+" - ("+(customer_caption||"").toUpperCase()+")";
			
			const vPasien = vKeyword==="" ? (pasien_caption||"Undf") 
				: (pasien_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vCustomer = vKeyword==="" ? (customer_caption||"Undf") 
				: (customer_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNoteteks = vKeyword==="" ? (note_reminder||"-") 
				: (note_reminder||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput = vKeyword==="" ? (userinput||"UNDF") 
				: (userinput||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			return (
			<tr id={"idtr"+id} key={vKeys} className={(uTabelRowSelect===vKeys?"classrowselect":"")}>
			<td className="px-1 align-top text-end">{vNumber}.</td>
			<td className="px-1 align-top text-end">
				<CRow className="mx-0">
				<CCol md="2" className="px-1 text-start d-none d-md-block">{UFunc.TglAngka(tgl_reminder)}</CCol>

				<CCol md="2" className="px-1 text-md-start d-none d-md-block"><b>{UFunc.renderHTML(vCustomer)}</b></CCol>
				
				<CCol md="2" className="px-1 text-md-start text-info d-none d-md-block">{UFunc.renderHTML(vPasien)}</CCol>
				
				<CCol md="3" className="px-1 text-md-start d-none d-md-block">
					<small><i>{UFunc.renderHTML(vNoteteks)}</i></small>
				</CCol>
				
				<CCol md="2" className="px-1 text-md-start d-none d-md-block">
					<small>
					<span className="text-danger">[{UFunc.renderHTML(vUserinput)}]</span>: {UFunc.TglAngka(tgl_input)}
					</small>
				</CCol>
				
				<CCol md="1" className="px-0 text-md-center d-none d-md-block">
				{(is_processing||false)===true ? (
					<CSpinner color="primary" size="sm"/>
				):(
					<CDropdown autoclose={true}>
					<CDropdownToggle color="transparent" className="p-0 m-0" 
						caret={false}>
						<CIcon icon="cilChevronBottom" className="classikoncoreui" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikPindah(id)}>
							<CIcon className="me-2 text-warning" icon="cilCalendar" height={19}/>
							<span>{uBahasaObj.caption_pindahtanggal || "Pindah Tanggal"}</span>
						</CDropdownItem>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikEdit(id)}>
							<CIcon className="classikontabel classikonedit me-2" height={25}/>
							<span>{uBahasaObj.caption_editdata}</span>
						</CDropdownItem>
						<CDropdownDivider className="mt-0" />
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikontabel classikonhapus me-2" height={25}/>
							<span>{uBahasaObj.caption_hapusdata}</span>
						</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
				)}
				</CCol>

				<CCol xs="12" className="px-1 d-md-none">
				<div className="d-flex justify-content-between">
					<span className=" text-info">{UFunc.renderHTML(vPasien)}</span>
					{(is_processing||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					):(
						<CDropdown autoclose={true}>
						<CDropdownToggle color="transparent" className="p-0 m-0" 
							caret={false}>
							<CIcon icon="cilOptions" className="classikoncoreui" height={23}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem className="classcursorpointer"
								onClick={()=>hdlKlikPindah(id)}>
								<CIcon className="me-2 text-warning" icon="cilCalendar" height={19}/>
								<span>{uBahasaObj.caption_pindahtanggal || "Pindah Tanggal"}</span>
							</CDropdownItem>
							<CDropdownItem className="classcursorpointer"
								onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikontabel classikonedit me-2" height={25}/>
								<span>{uBahasaObj.caption_editdata}</span>
							</CDropdownItem>
							<CDropdownDivider className="mt-0" />
							<CDropdownItem className="classcursorpointer"
								onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikontabel classikonhapus me-2" height={25}/>
								<span>{uBahasaObj.caption_hapusdata}</span>
							</CDropdownItem>
						</CDropdownMenu>
						</CDropdown>
					)}
				</div>
				</CCol>

				<CCol xs="12" className="my-2 px-1 classborderbottom d-md-none"/>

				<CCol xs="6" className="px-1 text-start d-md-none">{uBahasaObj.word_tanggal}</CCol>
				<CCol xs="6" className="px-1 d-md-none">{UFunc.TglAngka(tgl_reminder)}</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_namacustomer||"caption_namacustomer"}</CCol>
				<CCol xs="8" className="px-1 d-md-none fw-bolder">{UFunc.renderHTML(vCustomer)}</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_keterangan}</CCol>
				<CCol xs="8" className="px-1 d-md-none">
					<small><i>{UFunc.renderHTML(vNoteteks)}</i></small>
				</CCol>

				<CCol xs="12" className="my-2 px-1 classborderbottom d-md-none"/>
				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_madeorder||"Made Order"}</CCol>
				<CCol xs="8" className="px-1 d-md-none">
					<small>
					<span className="text-danger">[{UFunc.renderHTML(vUserinput)}]</span>: {UFunc.TglAngka(tgl_input)}
					</small>
				</CCol>
				</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</>
		)//>
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//>

		if(uDlgforminputObj.jenis === "FORMMAIN") return contentFormmain();
		if(uDlgforminputObj.jenis === "FORMPINDAH") return contentFormpindah();

		return (<></>)//>
	}
	const contentFormmain = () => {
		if(uDlgforminputObj.jenis !== "FORMMAIN") return (<></>)//>

		return (
		<CForm className="mx-lg-5 px-lg-5">
		<CRow className="my-1">
		<CCol md="5" lg="4">Nama Customer</CCol>
		<CCol>
			<CInputGroup size="sm">
			<CFormInput size="sm"
				value={uDlgforminputObj.customer_caption||""}
				onChange={(e)=>{uDlgforminputObj.customer_caption=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>hdlKDownCustomercaption(e)}
				placeholder={"--"+(uBahasaObj.caption_pilihcustomer||"Pilih Customer")}
				id="inpcustomercaption"/>
			<CInputGroupText className="p-0">
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikCustomerlookup()}
				id="btnCustomerlookup">
			<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroupText>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-1">
		<CCol md="5" lg="4">Pasien (Pets)</CCol>
		<CCol>
			<CInputGroup size="sm">
			<CFormInput size="sm"
				value={uDlgforminputObj.pasien_caption||""}
				onChange={(e)=>{uDlgforminputObj.pasien_caption=e.target.value;setEfekview(!uEfekview)}}
				onClick={()=>hdlKlikPasienlookup()}
				placeholder={"--"+(uBahasaObj.caption_pilihhewan||"Pilih Pets/Hewan")}
				readOnly={true}
				id="inppasiencaption"/>
			<CInputGroupText className="p-0">
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikPasienlookup()}
				id="btnPasienlookup">
			<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroupText>
			{(uDlgforminputObj.is_loading||false)===true && (
			<CInputGroupText className="border-0 bg-transparent">
				<CSpinner color="secondary" size="sm"/>
			</CInputGroupText>
			)}
			</CInputGroup>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-1">
		<CCol md="5" lg="4">Tanggal</CCol>
		<CCol md="5">
			<CFormInput size="sm" type="date"
				value={uDlgforminputObj.tgl_reminder}
				onChange={(e)=>{uDlgforminputObj.tgl_reminder=e.target.value;setEfekview(!uEfekview)}}
				id="inptanggal"/>
		</CCol>
		</CRow>

		<CRow className="my-1">
		<CCol md="5" lg="4">Note Reminder</CCol>
		<CCol>
			<CFormTextarea size="sm" rows={4}
				value={UFunc.br2nl(uDlgforminputObj.note_reminder||"")}
				onChange={(e)=>{uDlgforminputObj.note_reminder=UFunc.nl2br(e.target.value);setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpnotereminder"/>
			<small className="classpetunjuk">*) {uBahasaObj.petunjuk_textarea}</small>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-1">
		<CCol md="5" lg="4">Making Order by</CCol>
		<CCol className="">
			<small className="text-danger">[{uDlgforminputObj.userinput || uTokenObj.usercaption}]</small>
			<span className="mx-1">&middot;</span>
			<small>{UFunc.WaktuAngka(uDlgforminputObj.tgl_input)}</small>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentFormpindah = () => {
		if(uDlgforminputObj.jenis !== "FORMPINDAH") return (<></>)//>

		return (
		<CForm>
		<CRow className="my-1">
		<CCol md="5">Tgl Semula</CCol>
		<CCol><b className="text-primary">{UFunc.TglAngka(uDlgforminputObj.tgl_reminder)}</b></CCol>
		</CRow>

		<div className="my-2 classborderbottom"/>

		<CRow className="my-1">
		<CCol md="5">Pindah Tanggal</CCol>
		<CCol>
			<CFormInput size="sm" type="date"
				value={uDlgforminputObj.tgl_pindah}
				onChange={(e)=>{uDlgforminputObj.tgl_pindah=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inptglpindah"/>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = () => {
		setHTMLError500();
		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);
		setLoading(true);

		if(uFilterObj.tgl_reminder === undefined)
			uFilterObj.tgl_reminder = UFunc.DbDate();
		if(uFilterObj.jenis === undefined)
			uFilterObj.jenis = "DAYNOW";
		const vFilter = JSON.stringify(uFilterObj);
		//console.log("(Reminder-apiLoaddata) vFilter : "+vFilter);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			setLoading(false);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_filterlist : vFilter,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_reminder/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Reminder-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vDatamainArr = JSON.parse(output_string.dataobject || "[]");
				setDatamainArr(vDatamainArr);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Reminder - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Reminder - apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadpasien = (_IDCUSTOMER) => {
		_IDCUSTOMER		= parseInt(_IDCUSTOMER)||0;
		
		const vElfocus 	= document.getElementById("inpcustomercaption");
		const vEldisabled= document.getElementById("btnPasienlookup");
		uDlgforminputObj.is_loading = true;
		vEldisabled && (vEldisabled.disabled = true);
		uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = true);
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDlgforminputObj.is_loading = false;
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false);
			setEfekview2(!uEfekview2);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_customerid : _IDCUSTOMER,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_family";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true)
			uDlgforminputObj.is_loading = false;
			vEldisabled && (vEldisabled.disabled = false);
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false);
			//console.log("(Reminder-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.datapasien || "[]");
				setDatapasienArr(vTmpArr);
				setEfekview2(!uEfekview2);
			} else {
				vElfocus && vElfocus.focus();
				setEfekview2(!uEfekview2);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Reminder - apiLoadpasien) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDlgforminputObj.is_loading = false;
			vEldisabled && (vEldisabled.disabled = false);
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false);
			vElfocus && vElfocus.focus();
			setEfekview2(!uEfekview2);

			console.log("(Reminder - apiLoadpasien) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessimpan = () => {
		const vTabelID = parseInt(uTabelIDPilih)||0; 
		const vTglnowDT = new Date();
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = true)
		setLoading(true)

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false)
			setLoading(false)
				const vTmpObj = {
					id: vTabelID > 0 ? vTabelID : (255),
					customer_caption: uDlgforminputObj.customer_caption,
					customer_id: uDlgforminputObj.customer_id,
					pasien_caption: uDlgforminputObj.pasien_caption,
					pasien_id: uDlgforminputObj.pasien_id,
					note_reminder: uDlgforminputObj.note_reminder,
					tgl_reminder: uDlgforminputObj.tgl_reminder,
					userinput: uTokenObj.usercaption,
					tgl_input: UFunc.DbDate()+" "+
						vTglnowDT.getHours()+":"+
						vTglnowDT.getMinutes()+":"+
						vTglnowDT.getSeconds(),
				}

				if(vTabelID <= 0) setDatamainArr([vTmpObj,...uDatamainArr]);
				else uDatamainArr[vArridx] = vTmpObj;

				hdlToggleDlgform()
		},2500); return//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_formlist: JSON.stringify(uDlgforminputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_reminder/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true)
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false)
			setLoading(false)
			//console.log("(Reminder-apiProseshapus) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				const vTmpObj = {
					id: vTabelID > 0 ? vTabelID : (output_string.tabelid||0),
					customer_caption: uDlgforminputObj.customer_caption,
					customer_id: uDlgforminputObj.customer_id,
					pasien_caption: uDlgforminputObj.pasien_caption,
					pasien_id: uDlgforminputObj.pasien_id,
					note_reminder: uDlgforminputObj.note_reminder,
					tgl_reminder: uDlgforminputObj.tgl_reminder,
					userinput: uTokenObj.usercaption,
					tgl_input: UFunc.DbDate()+" "+
						vTglnowDT.getHours()+":"+
						vTglnowDT.getMinutes()+":"+
						vTglnowDT.getSeconds(),
				}

				if(vTabelID <= 0) setDatamainArr([vTmpObj,...uDatamainArr]);
				else uDatamainArr[vArridx] = vTmpObj;

				hdlToggleDlgform()
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Reminder - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false)
			setLoading(false)
			console.log("(Reminder - apiProseshapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsespindah = () => {
		const vTabelID 	= parseInt(uTabelIDPilih)||0; if(vTabelID<=0) return;
		const vTglnowDT = new Date();
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);

		if(uDatamainArr[vArridx].tgl_reminder === uDlgforminputObj.tgl_pindah) {
			hdlToggleDlgform()
			return;
		}
		uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = true)
		setLoading(true)

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false)
			setLoading(false)

			uDatamainArr[vArridx].tgl_reminder = uDlgforminputObj.tgl_pindah;
			hdlToggleDlgform()
		},2500); return//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_formlist: JSON.stringify(uDlgforminputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_reminder/pr_pindah";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true)
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false)
			setLoading(false)
			//console.log("(Reminder-apiProseshapus) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr[vArridx].tgl_reminder = uDlgforminputObj.tgl_pindah;
				hdlToggleDlgform()
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Reminder - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false)
			setLoading(false)
			console.log("(Reminder - apiProseshapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true)

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false)

			uDatamainArr.splice(vArridx,1);
			setTabelIDPilih(0); setTabelRowSelect(-1);
		},2500); return//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_reminder/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false)
			//console.log("(Reminder-apiProseshapus) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setTabelIDPilih(0); setTabelRowSelect(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Reminder - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false)
			console.log("(Reminder - apiProseshapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoaddata();
		return () => {
			setDatamainArr([]);
			setDatapasienArr([]); setDlgforminputObj({});
			setDlglookupinputObj({}); setDlglookupAinputObj({});
			hdlToggleDlgform()
			hdlToggleDlglookup()
			hdlToggleDlglookupA()
		}
	},[])
	React.useEffect(()=>{
		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{ setJmlData(uDatamainArr.length); },[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		if(!uHandelView) return;
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;
		if(uIsDlgformshow) return;

		uDlgforminputObj.header = (uBahasaObj.caption_tambahdata||"Tambah Data")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()];
		uDlgforminputObj.jenis 	= "FORMMAIN";
		setDlgformshow(true);//-*/
	},[uHandelTambah]);
	React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);
	React.useEffect(()=>{
		if(!uIsDlgformshow) {
			setDlgforminputObj({})
			setElBtnDlgsimpan();
			setDatapasienArr([]);
			return;
		}

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)

			setElBtnDlgsimpan(document.getElementById("btnDialogSimpan"));

			let vElfocus = document.getElementById("inpcustomercaption");
			if(uDlgforminputObj.jenis === "FORMPINDAH")
				vElfocus = document.getElementById("inptglpindah");
			else {
				if(uTabelIDPilih > 0)
					vElfocus = document.getElementById("inppasiencaption");
			}

			vElfocus && vElfocus.focus();
		},350);
	},[uIsDlgformshow]);
	React.useEffect(()=>{
		if(uDlgforminputObj.tgl_reminder === undefined)
			uDlgforminputObj.tgl_reminder = UFunc.DbDate();
		if(uDlgforminputObj.tgl_pindah === undefined)
			uDlgforminputObj.tgl_pindah = UFunc.DbDate();
	},[uDlgforminputObj,
		uDlgforminputObj.tgl_reminder,
		uDlgforminputObj.tgl_pindah
	]);
	React.useEffect(()=>{
		if(!uISDlglookupAshow) {
			setDlglookupAtrigger(false);
			//setDlglookupAinputObj({});
			//uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false);
			return;
		}
		//uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = true);
	},[uISDlglookupAshow]);
	React.useEffect(()=>{
		if(!uISDlglookupshow) {
			setDlglookupinputObj({});
			//uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = false);
			return;
		}
		//uElBtnDlgsimpan && (uElBtnDlgsimpan.disabled = true);
		setEfekview(!uEfekview)
	},[uISDlglookupshow]);
	
	//console.log("Reminder - uActiveroute => "+(uActiveroute));
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500} {...props}/> )

	return (
		<>
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">({UFunc.formatAngka(uJmlData)})</span>
			</small>
			<small className="text-muted classfontsmaller">{UFunc.formatAngka(uTimeElapsed)}ms</small>
		</CCardHeader>
		<CCardBody className="px-0">{contentMain()}</CCardBody>
		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i) => setPageActive(i)}/>
		</CCardFooter>
		)}
		</CCard>

		<MyDialogform
			options={{size:((uDlgforminputObj.jenis||"FORMMAIN")==="FORMMAIN"?"lg":null),centered:false}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgforminputObj.header||""}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}
			{...props}/>

		<MyDialoglookupapi
			show={uISDlglookupAshow}
			toggle={()=>hdlToggleDlglookupA()}
			dialogHeader={uDlglookupAinputObj.header}
			inputValue={uDlglookupAinputObj.keyword}
			onKlikPilihItem={hdlKlikDlglookupApilih}
			targetApi={uDlglookupAinputObj.targetapi||""} 
			kdownTrigger={uISDlglookupAtrigger}
			id="iddialoglookupapi"
			{...props}/>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDlglookupinputObj.header}
			inputValue={uDlglookupinputObj.keyword}
			onKlikPilihItem={hdlKlikDlglookuppilih}
			dataItemArr={uDlglookupinputObj.items_arr||[]}
			id="iddialoglookup"
			{...props}/>
		</>
	)//>
}	

export default Reminder