import React from 'react';
//import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CForm,CFormInput,CInputGroup,
	CButton,
	CTooltip,
	CLink,
	CImage,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,CDropdownDivider,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';

const pjson     			= require('../../../package.json');
const Headersubtagihanpajak = (props) => {
	const uComponentname 	= Headersubtagihanpajak.name;
	//const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	
	const uActiveroute 		= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uEfekapp			= useSelector(state => state.effectApp);

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted)||false;
	const [uKeyword,setKeyword] = React.useState(uKeywordObj.kwd_tagihanpajak || "");

	const uIsAllowedtambah	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN")

	const hdlKeydown=(_EV)=>{
		if(_EV.which === 13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") && 
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_tagihanpajak = uKeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/
		//setHeaderView(true);

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_tagihanpajak = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//prosesReset(); return;
		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}
	const hdlKlikTambah = () => { 
		uHeaderActionObj.isHeaderTambah = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikEdit = () => { 
		uHeaderActionObj.isHeaderEdit = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}

	//console.log("("+uComponentname+") Checked..");
	return(
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+uActiveroute+".png"} 
				className="me-2 align-middle"
				height={30}/>
			<small className="fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup 
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CFormInput size="sm" 
					value={uKeyword} 
					onChange={(e) => setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKeydown(e)} 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} 
					id="inpkeyword" 
					name="inpkeyword"/> 
					<CButton size="sm" color="light"
						className="border" 
						onClick={()=>hdlKlikCari()}
						id="btnCari">
						<CIcon icon="cilMagnifyingGlass" />
					</CButton>
				</CInputGroup>
			</CForm>

			<div className="d-none d-md-block">
			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			{(uIsEOMExecuted) ? (
				<>
				{uIsAllowedtambah && (
				<>
				<CTooltip content={(uBahasaObj.caption_tambahdata||"caption_tambahdata")+": "+
					(uBahasaObj["menus_"+uActiveroute.toLowerCase()] || uComponentname).toUpperCase()}>
					<CLink 
						className="classcursorpointer classikon classikontambah"
						onClick={()=>hdlKlikTambah()}/>
				</CTooltip>
				<span className="mx-1">&middot;</span>
				</>
				)}
				<CTooltip content={(uBahasaObj.caption_editpembayaran||"caption_editpembayaran")+": "+
					(uBahasaObj["menus_"+uActiveroute.toLowerCase()] || uComponentname).toUpperCase()}>
					<CLink className="classcursorpointer classikon classikonedit"
						 onClick={()=>hdlKlikEdit()}/>
				</CTooltip>
				<span className="mx-1">&middot;</span>
				</>
			) : (
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui classblinking" height={30}/>
			)}

			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
			</CTooltip>

			<span className="me-1">&middot;</span>
			<b id="idjmldata">0</b> {uBahasaObj.word_data||"word_data"}
			</div>

			{(uIsEOMExecuted) ? (
			<CDropdown className="d-md-none ms-1">
				<CDropdownToggle caret={false} size="sm" className="p-0"
					color="transparent">
					<CIcon icon="cilChevronBottom" className="classikoncoreui" height={30}/>
				</CDropdownToggle>
				<CDropdownMenu>
				{uIsAllowedtambah && (
				<>
				<CDropdownItem onClick={()=>hdlKlikTambah()}>
					<CIcon className="classikontambah ms-0 me-2 align-middle" height={25}/>
					Input Tagihan Pajak
				</CDropdownItem>
				<CDropdownDivider/>
				</>
				)}
				<CDropdownItem onClick={()=>hdlKlikEdit()}>
					<CIcon className="classikonedit ms-0 me-2 align-middle" height={25}/>
					Edit Pembayaran Tagihan
				</CDropdownItem>
				</CDropdownMenu>
			</CDropdown>
			) : (
			<span className="classblinking d-md-none ms-1">
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui" height={30}/>
			</span>
			)}
		</div>
		</>
	);
}

export default Headersubtagihanpajak;