import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CForm,
	CInputGroup,
	CFormInput,
	CListGroup,
	CListGroupItem,
	CRow,
	CCol,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import PropTypes from 'prop-types';
import { UFunc } from "../helpers/functions";
import { isMobile } from "react-device-detect";

const pjson     		= require('../../package.json');
const MyDialoglookupapi = props => {
	const {
		show, toggle, dialogHeader, inputValue,
		targetApi, kdownTrigger, onKlikPilihItem,
		setLoading, prosesExpired, setSessionaktif,
		...rest
	} = props;

	const uDispatch			= useDispatch();
	const uBahasaob  		= useSelector(state => state.listBahasa);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uDataselectedObj	= useSelector(state => state.lookupapiobj) || {};

	const [uInputValue,setInputValue]	= React.useState(inputValue || "xx");
	const [uCursorPos,setCursorPos]		= React.useState(-1);
	const [uMsgerror500,setMsgerror500]	= React.useState();
	const [uDataSearchArr,setDataSearchArr]	= React.useState([]);
	const [uISProcessing,setProcessing]	= React.useState(false);
	const [uViewfirst,setViewfirst]		= React.useState(false);

	const hdlKeyDownModal=(_EV)=>{
		if (_EV.keyCode === 38 && uCursorPos >= 0) {//--->UP
			_EV.preventDefault();
			setCursorPos(uCursorPos-1);
			return;
		} else if (_EV.keyCode === 40 && uCursorPos < uDataSearchArr.length-1) {//--->DOWN
			_EV.preventDefault();
			setCursorPos(uCursorPos+1);
			return;
		}

		if(_EV.keyCode === 13) {
			if(uCursorPos < 0 || uCursorPos >= uDataSearchArr.length) return;

			_EV.preventDefault();
			const vIdx = uCursorPos;
			hdlKlikPilihitem(uDataSearchArr[vIdx].id);
			return;
		}//-*/
	}
	const hdlKeyDownInput=(_EV)=>{
		if(_EV.keyCode===13) {
			_EV.preventDefault();
			_EV.stopPropagation();
			//setCursorPos(0);
			const vElfocus = document.getElementById("btnlookupsearch");
			vElfocus && (vElfocus.click());
			return;
		}//-*/
	}
	const hdlKlikCari = () => {
		let vElfocus = document.getElementById("inpdialoglookup")
		if((uInputValue||"").trim() === "") {
			if(!isMobile) vElfocus && vElfocus.focus();
			setMsgerror500("<b>Pencarian Harus Lebih Spesifik</b>.."+
				"<br/>Mohon Gunakan Keyword Dengan Jumlah Karakter yang Tepat !"+
				"");
			return;
		}

		apiLoaddata();
	}
	const hdlKlikPilihitem = (_IDTABEL) =>{
		_IDTABEL = parseInt(_IDTABEL)||0;
		const vArridx = UFunc.getObjectIndeks(uDataSearchArr,_IDTABEL) ;
		onKlikPilihItem(JSON.stringify(uDataSearchArr[vArridx]));
	}

	const apiLoaddata = () => {
		setMsgerror500();
		setDataSearchArr([])
		setProcessing(true);

		/*//--TESTING_FRONTEND--/
		console.log("(MyDialoglookupapi-apiLoaddata) LEWAT ");
		const vTmpArr	= [//]);
			{id:"1",caption:"Nama Customer 1 - 0001",alamat:"Jl.Madegondo",telp:"081734564"},
			{id:"2",caption:"Nama Customer 2 - 0002",alamat:"Jl.Madegondo",telp:"081734564"},
			{id:"3",caption:"Nama Customer 3 - 0003",alamat:"Jl.Madegondo",telp:"081734564"},
			{id:"4",caption:"Nama Customer 4 - 0004",alamat:"Jl.Madegondo",telp:"081734564"},
			{id:"5",caption:"Nama Customer 5 - 0005",alamat:"Jl.Madegondo",telp:"081734564"},
			{id:"6",caption:"Nama Customer 6 - 0006",alamat:"Jl.Madegondo",telp:"081734564"},
		]
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setProcessing(false);
			setDataSearchArr(vTmpArr)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword: uInputValue||"",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= (targetApi||"");
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setProcessing(false);
			//console.log("(MyDialoglookupapi - apiLoaddata) output_string : "+output_string);
			if(output_string.tampil) {
				const vTmpArr = (JSON.parse(output_string.dataobject||"[]"));
				//console.log("(MyDialoglookupapi - apiLoaddata) vTmpArr : "+JSON.stringify(vTmpArr));
				//console.log("(MyDialoglookupapi - apiLoaddata) uViewfirst : "+uViewfirst);
				setDataSearchArr(vTmpArr);
			} else if(output_string.info) {
				setMsgerror500(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyDialoglookupapi - apiLoaddata) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				setMsgerror500(vMsg);
			} else if(output_string.expired) {
				setLoading(true);prosesExpired();
			}
		})
		.catch((error) =>{
			setProcessing(false);
			console.log("(MyDialoglookupapi - apiLoaddata) catch-error: "+error);
			setMsgerror500(pjson.mydefault.msg500str.join(" "));
		});
	}

	React.useEffect(()=>{
		if(!show) { 
			setDataSearchArr([]);
			setInputValue(""); setCursorPos(-1); 
			setViewfirst(false); 
			return;
		}
		
		setInputValue(inputValue || "");
		if((inputValue || "") === "") apiLoaddata();
		else setViewfirst(true);

		//console.log("[show] inputValue = "+inputValue);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			const vElsinput	= document.getElementById("inpdialoglookup");
			const vElsrow	= document.getElementById("idrowscroll");

			//console.log("[show] Checked..");
			vElsinput && vElsinput.scrollTo(0,0);
			if(inputValue === "") {
				if(!isMobile) vElsinput && vElsinput.focus();
			} else
				vElsinput && vElsinput.select();
		},350);//-*/
	},[show])
	React.useEffect(()=>{
		if(!uViewfirst) return;
		apiLoaddata();
	},[uViewfirst]);
	React.useEffect(()=>{
		if(uDataSearchArr.length <= 0) return;

		//console.log("(MyDialoglookupapi - [uDataSearchArr]) uDataSearchArr.length : "+uDataSearchArr.length);
		if((kdownTrigger||false)===true) {
			if(uDataSearchArr.length === 1) {
				hdlKlikPilihitem(uDataSearchArr[0].id);
				return;
			}
		}
	},[uDataSearchArr,uDataSearchArr.length]);
    React.useEffect(() => {
		const vElsinput	= document.getElementById("inpdialoglookup");
		const vElsrow	= document.getElementById("idrowscroll");

		if(uCursorPos <= 0) {
			vElsinput && vElsinput.scrollTo(0,0);
		}
		if(uCursorPos < 0) {
			if(!isMobile) vElsinput && vElsinput.focus();
			return;
		}

		if(!isMobile)
			document.getElementById("idlistitem"+uCursorPos) && 
				document.getElementById("idlistitem"+uCursorPos).focus();
    }, [uCursorPos]);

	//console.log("("+cComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { 
		prosesExpired(); 
		return (<></>)//> 
	}

	return (
		<div onKeyDown={(e)=>hdlKeyDownModal(e)}>
		<CModal size="lg"
			alignment={"top"}
			className="z-2099"
			visible={show} 
			scrollable
			onClose={toggle}>
		<CModalHeader closeButton className="bg-dark text-light py-2">
			<CModalTitle>
				<small className="d-none d-md-block">{dialogHeader || MyDialoglookupapi.defaultProps.dialogHeader} (Advanced)</small>
				<small className="d-md-none"><small>{dialogHeader || MyDialoglookupapi.defaultProps.dialogHeader} (Advanced)</small></small>
			</CModalTitle>
		</CModalHeader>

		<CModalBody className="p-0" 
			id="idmodalbody">
			<CRow className="sticky-top bg-gray-100 mx-auto" id="idheaderlookup">
			<CCol className="p-3 px-lg-5">
				<CForm>
				<CInputGroup size="sm">
				<CFormInput size="sm"
					autoFocus={true}
					autoComplete="true"
					value={uInputValue}
					onChange={(e)=>setInputValue(e.target.value)}
					onKeyDown={(e)=>hdlKeyDownInput(e)}
					onFocus={(e)=>setCursorPos(-1)}
					readOnly={uISProcessing}
					placeholder="--Masukkan Keyword Kemudian Tekan ENTER.."
					id="inpdialoglookup"/>
				<CButton size="sm" color="secondary"
					className="text-white"
					onClick={()=>hdlKlikCari()}
					id="btnlookupsearch">
					<CIcon icon="cilMagnifyingGlass" size="sm"/>
				</CButton>
				</CInputGroup>
				</CForm>
			</CCol>
			</CRow>
			<CRow className="py-1 mx-auto">
			<CCol
				style={{maxHeight:(window.innerHeight - (isMobile ? 210 : 285)),overflowY:"auto",overflowX:"hidden"}}
				id="idrowscroll">
			{(uMsgerror500) ? (
				<div className="mx-5 text-center">
					<span className="classpetunjuk">{UFunc.renderHTML(uMsgerror500)}</span>
				</div>
			) : (
			<>
			{uISProcessing ? (
				<center className="my-2">
				<CSpinner color="danger"/>
				</center>
			) : (
				<CListGroup> 
				{(uDataSearchArr).map((vItems,vKeys)=>{
				//console.log("(MyDialoglookupapi - uDataSearchArr.map) uCursorPos===vKey => "+uCursorPos+"==="+vKey);
				return (
					<CListGroupItem key={vKeys}
						id={"idlistitem"+vKeys}
						tabIndex={vKeys}
						active={uCursorPos===vKeys}
						onClick={()=>hdlKlikPilihitem(vItems.id)}
						className="py-2 list-group-item-action classcursorpointer">
						<CRow>
						<CCol className="px-1">
							{vItems.caption}
							{vItems.caption_sub && (
							<>
							<span className="mx-1">&middot;</span>
							<small className="fw-bolder fst-italic">{vItems.caption_sub}</small>
							</>
							)}
						</CCol>
						{vItems.nilai && (
						<CCol xs="3" className="px-1 text-end fw-bolder">
							Rp{UFunc.formatAngka(vItems.nilai)}
						</CCol>
						)}
						</CRow>
					</CListGroupItem>
				)
				})}
				</CListGroup>
			)}
			</>
			)}
			</CCol>
			</CRow>
		</CModalBody>
		<CModalFooter className="bg-light">
		<CButton 
			className="px-3 text-white"
			color="secondary" 
			onClick={toggle}
			id="btnDialoglookupOK"> 
		{uBahasaob.word_ok||"Ok !"}
		</CButton>
		</CModalFooter>
		</CModal>
		</div>
	)//>
}
MyDialoglookupapi.defaultProps = {
	show: false,
	dialogHeader: "Lookup Data Item",
	onKlikPilihItem:()=>{},
	targetApi: pjson.homepage+"api/",
	kdownTrigger: false,
};

MyDialoglookupapi.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,     
	dialogHeader: PropTypes.string,
	inputValue: PropTypes.string,
	targetApi: PropTypes.string, 
	kdownTrigger: PropTypes.bool,            
	onKlikPilihItem: PropTypes.func,     
}

export default MyDialoglookupapi;
