import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CFooter,
	CForm,
	CRow,
	CCol,
	CFormInput,
	CFormSelect,
	CFormCheck,
	CInputGroup,
	CInputGroupText,
	CButton,
	CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,MyPage500,MyProfileDetil,MyDialoglookup
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../../package.json');
const Omzetlainform = (props) => {
	const uComponentname	= Omzetlainform.name;
	const { 
		prosesExpired,setLoading,showToast,setSessionaktif 
	} = props; 

	let uFuncname;
	const uNavigate 		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted) || true;

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uKasGLArrObj,setKasGLArrObj]		= React.useState(JSON.parse(localStorage.getItem("kasgllist")||"[]"));
	const [uPendapatanGLArrObj,setPendapatanGLArrObj]= React.useState([]);

	const [uInpStatustembak,setInpStatustembak]= React.useState("REGULER");
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate());
	const [uInpGLID,setInpGLID]				= React.useState("0");
	const [uInpGLcaption,setInpGLcaption]	= React.useState("");
	const [uInpKasID,setInpKasID]			= React.useState("0");
	const [uInpKascaption,setInpKascaption]	= React.useState("");
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");
	const [uInpNoref,setInpNoref]			= React.useState("");
	const [uInpNilai,setInpNilai]			= React.useState(0);
	const [uIsFormprocess,setIsFormprocess]	= React.useState(false);
	const [uISFormupdated,setFormupdated]	= React.useState(false);

	//---LOOKUP--/
	const [uIsDlglookupshow,setDlglookupshow]		= React.useState(false);
	const [uDlglookupjenis,setDlglookupjenis]		= React.useState("");
	const [uDlglookupheader,setDlglookupheader]		= React.useState("");
	const [uInpLookupKwd,setInpLookupKwd]			= React.useState("");
	const [uDatalookupArr,setDatalookupArr]			= React.useState([]);
	//---END LOOKUP--/

	//---HANDEL--/
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			_EV.preventDefault();
			_EV.stopPropagation();

			const vEltombol = document.getElementById("btnSimpan");
			vEltombol && (vEltombol.click());

			return;
		}
	}
	const hdlKlikKembali = async() => {
		if(uISFormupdated) {
			if(await Konfirm("<b>Data Input Ada yang Belum Disimpan</b>.."+
				"<br/>Data yang Telah Anda Inputkan Akan Terhapus !"+
				"<br/><br/>Apakah Anda Yakin Akan Melanjutkan ?"+
				"")) uNavigate(-1);
			return;
		}

		uNavigate(-1);
	}
	const hdlToggleLookup = () => { setDlglookupshow(false) }
	const hdlKlikGLlookup = () => {
		const vTmpArr	= uPendapatanGLArrObj.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}	
		})
		setDatalookupArr(vTmpArr)

		setDlglookupjenis("PENDAPATAN");
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_akunpendapatan||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKlikKaslookup = () => {
		const vTmpArr	= uKasGLArrObj.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}	
		})
		setDatalookupArr(vTmpArr)

		setDlglookupjenis("KAS");
		setDlglookupheader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_kaspenerima||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKlikPilihLookup = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM||0); if(_IDITEM <= 0) return;

		let vElnextfocus
		const vObjidx 	= UFunc.getObjectIndeks(uDatalookupArr,_IDITEM);
		const vValue	= uDatalookupArr[vObjidx].id||"0";
		const vCaption	= uDatalookupArr[vObjidx].caption||"";
		if(uDlglookupjenis==="KAS") {
			vElnextfocus = document.getElementById("inpket")

			setInpKasID(vValue);
			setInpKascaption(vCaption);
		} else {
			vElnextfocus = document.getElementById("inpkascaption")

			setInpGLID(vValue);
			setInpGLcaption(vCaption);
		}
		hdlToggleLookup();

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElnextfocus && vElnextfocus.focus();
		},350)
	}
	const hdlKlikReset = () => {
		if(uISFormupdated) {
			initFormAwal(); return;
		}

		apiLoadGL();
	}
	const hdlKlikSimpan = async() => {
		//---VALIDASI--/
		let vELfocus 	= document.getElementById("inpstatustembak");
		if(uInpStatustembak!=="TEMBAK" && uInpStatustembak!=="REGULER") {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.caption_statustembakjurnal||"Status Jurnal")+" "+uBahasaObj.caption_invalid+"..");
			return;	
		}

		vELfocus 	= document.getElementById("inptanggal");
		if(UFunc.isEmpty(uInpTanggal)) {
			vELfocus && vELfocus.focus();
			showToast(uBahasaObj.caption_tanggal+" "+uBahasaObj.caption_invalid+"..");
			return;
		} else {
			const vTanggalDt 	= new Date(uInpTanggal);

			const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
			if(vDateBeyond) {
				vELfocus && vELfocus.focus();
				showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
				return;
			}
			const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
			if(vDateExceededBl) {
				vELfocus && vELfocus.focus();
				showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
				return;
			}
			//alert("("+uComponentname+") "+cFuncname+" vDateExceededBl = "+vDateExceededBl); return;
		}

		vELfocus 	= document.getElementById("inpnilai");
		if((parseInt(uInpNilai)||0) <= 0) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.word_nilai||"word_nilai")+
				" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}

		vELfocus 	= document.getElementById("inpglcaption");
		if((parseInt(uInpGLID)||0) <= 0) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.caption_akunpendapatan||"caption_akunpendapatan")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}

		vELfocus 	= document.getElementById("inpkascaption");
		if((parseInt(uInpKasID)||0) <= 0) {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		vELfocus 	= document.getElementById("inpket");
		if((uInpKeterangan||"") === "") {
			vELfocus && vELfocus.focus();
			showToast((uBahasaObj.word_keterangan||"word_keterangan")+ 
				" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+
				"..");
			return;
		}
		//---END VALIDASI--/

		setIsFormprocess(true);
		if(await Konfirm(
			"<b>INPUT PENDAPATAN LAINNYA</b>.."+
			"<hr/>"+
			"<div class='d-flex my-md-2 justify-content-between'>"+
			"<span>Nilai</span>"+
			"<b>"+uInpNilai+"</b>"+
			"</div>"+

			"<div class='d-flex justify-content-between my-md-2'>"+
			"<span>Pos Pendapatan</span>"+
			"<b>"+uInpGLcaption+"</b>"+
			"</div>"+
			"<hr/>"+
			"Apakah Anda Yakin ?"+
			"")) {
			apiProsessimpan();
			return;
		}
		setIsFormprocess(false);
	}
	//---END HANDEL--/

	//--INIT--/
	const initFormAwal=()=>{
		setInpTanggal(UFunc.DbDate());

		setInpStatustembak("REGULER");
		setInpKasID("0");
		setInpKascaption("");
		setInpGLID("0");
		setInpGLcaption("");
		setInpKeterangan("");
		setInpNoref("");
		setInpNilai(0);
	}
	const contentMainform=()=>{
		if(uHTMLError500) return "";

		return (
			<CForm className="">
			{uTokenObj.userhak === "FREEROLE" && (
			<>
			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
				</CCol>
				<CCol xs="8" md="4" className="text-end">
					<CFormSelect size="sm"
						onChange={(e)=>setInpStatustembak(e.target.value)}
						value={uInpStatustembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
					</CFormSelect>
				</CCol>
				<CCol md="4" className="d-none d-md-block"/>
				<CCol xs="4" className=""/>
				<CCol className="classpetunjuk">
					*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL
				</CCol>
			</CRow>
			
			<div className="mx-0 my-3 classborderbottom"/>
			</>
			)}

			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_tanggal||"word_tanggal"}
				</CCol>
				<CCol xs="8" md="4" className="text-md-end">
					<CFormInput 
						size="sm"
						type="date"
						onChange={(e)=>setInpTanggal(e.target.value)}
						value={uInpTanggal}
						name="inptanggal"
						id="inptanggal"/>
				</CCol>
			</CRow>

			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_nilai||"word_nilai"}
				</CCol>
				<CCol xs="8" md="4" className="text-md-end">
					<NumberFormat 
						value={uInpNilai}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpNilai(values.value)}
						renderText={values => (
							<CFormInput 
								value={values}
								size="sm"
								className="text-end"
								onChange={(e) => setInpNilai(e.target.value)}
								onFocus={(e)=>e.target.select()}
							id="inpnilai"/>
						)} 
						allowNegative={false}
						prefix={isMobile?"":"Rp"}/>
				</CCol>
			</CRow>

			<CRow className="mx-0 my-2">
				<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.caption_nobukti||"caption_nobukti"}
				<span className="d-none d-md-inline">/{uBahasaObj.word_noref||"word_noref"}</span>
				</CCol>
				<CCol xs="8" md="4" className="text-md-end">
					<CFormInput maxLength={50} 
						size="sm"
						className="text-uppercase"
						onChange={(e)=>setInpNoref(e.target.value)}
						value={uInpNoref}
						name="inpnoref"
						id="inpnoref"/>
				</CCol>
			</CRow>

			<CRow className="mx-0 my-3 classborderbottom"/>

			<CRow className="my-2 mx-0">
				<CCol md="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_akunpendapatan||"caption_akunpendapatan"}
				</CCol>
				<CCol md="4">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						readOnly={true}
						value={uInpGLcaption}
						onChange={(e)=>setInpGLcaption(e.target.value)}
						placeholder={"--"+uBahasaObj.word_pilih+" "+uBahasaObj.caption_akunpendapatan+".."}
						onClick={()=>hdlKlikGLlookup()}
						id="inpglcaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikGLlookup()}
						id="btngllookup">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
					</CInputGroup>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-0">
				<CCol md="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_kaspenerima||"caption_kaspenerima"}
				</CCol>
				<CCol md="4">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						readOnly={true}
						value={uInpKascaption}
						onChange={(e)=>setInpKascaption(e.target.value)}
						placeholder={"--"+uBahasaObj.word_pilih+" "+uBahasaObj.caption_kaspenerima+".."}
						onClick={()=>hdlKlikKaslookup()}
						id="inpkascaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikKaslookup()}
						id="btnkaslookup">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
					</CInputGroup>
				</CCol>
			</CRow>

			<CRow className="mx-0 my-3 classborderbottom"/>

			<CRow className="my-2 mx-0">
				<CCol md="4" className="text-start text-md-end text-info">
				{uBahasaObj.word_keterangan||"word_keterangan"}
				</CCol>
				<CCol>
					<CFormInput maxLength={255} 
						size="sm"
						type="text"
						onChange={(e)=>setInpKeterangan(e.target.value)}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
						value={uInpKeterangan}
						className="text-capitalize"
						name="inpket"
						id="inpket"/>
				</CCol>
			</CRow>
			</CForm>
		)//>
	}
	//--END INIT--/

	//--API--/
	const apiLoadGL = () => {
		uFuncname = apiLoadGL.name;
		setHTMLError500();
		setIsFormprocess(true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		//setKasGLArrObj([]);
		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_omzetlain/ld_datagl";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false);return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.tampil) {
				setIsFormprocess(false);
				setPendapatanGLArrObj(JSON.parse(output_string.pendapatanobject||"[]"));
			} else if(output_string.info) {
				setIsFormprocess(false);
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;
		setIsFormprocess(true);
		setLoading(true);

		/*//--TESTING_FRONTEND--//
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_statustembak : uInpStatustembak,
			send_tanggal : uInpTanggal,
			send_kasid : uInpKasID,
			send_glid : uInpGLID,
			send_keterangan : uInpKeterangan,
			send_noref : uInpNoref,
			send_nilai: uInpNilai,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_omzetlain/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false);return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setIsFormprocess(false);

			if(output_string.sukses) {
				let vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				if(output_string.pesankhusus) vPesan = output_string.pesankhusus;
				showToast(vPesan,"SUKSES");
				initFormAwal();

				if(!isMobile) document.getElementById("inptanggal").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setIsFormprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);

		return () => {
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		if(!uIsEOMExecuted) uNavigate(-1);

		setKasGLArrObj(JSON.parse(localStorage.getItem("kasgllist")||"[]"));

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		
		apiLoadGL();

		if(!isMobile) {
			const vElsTanggal = document.getElementById("inptanggal");
			vElsTanggal && vElsTanggal.focus();
		}

		return ()=>{
			initFormAwal();
			setPendapatanGLArrObj([]);
			setDatalookupArr([]);
		}
	},[]);
	React.useEffect(()=>{
		if(uInpTanggal !== UFunc.DbDate()
			|| uInpStatustembak !== "REGULER"
			|| parseInt(uInpKasID) > 0
			|| parseInt(uInpGLID) > 0
			|| parseFloat(uInpNilai) > 0
			|| uInpKeterangan.trim() !== ""
			|| uInpNoref.trim() !== ""
		) setFormupdated(true);
		else setFormupdated(false);
	},[uInpTanggal,
	uInpStatustembak,
	uInpKasID,
	uInpGLID,
	uInpKeterangan,
	uInpNilai
	]);
	//---END EFFECT---/

	console.log("("+uComponentname+") Checked..");
	
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return "" }
	
	if(uHTMLError500) return (
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="px-3 classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className=""
				onClick={() =>{uNavigate(-1);}}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle" height={25}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||"caption_daftartreatment").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
		<CContainer xxl className={"px-0"}>
			<MyPage500 content={uHTMLError500} isWithrefresh={true} hdlReset={apiLoadGL} {...props}/>
		</CContainer>
		</div>
		</div>
	)//>

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="px-3 classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={()=>hdlKlikKembali()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={25}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_omzetlain||"menus_omzetlain").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-2">
			<CContainer xxl className="px-lg-5">
				<CCard className="mx-lg-5 classcardbg">
				<CCardHeader className="d-flex justify-content-between">
					<b>{uBahasaObj.caption_forminput||"caption_forminput"}</b>
					<CLink className="classcursorpointer classikon classikonreset"
						onClick={()=>hdlKlikReset()}/>
				</CCardHeader>
				<CCardBody className="px-lg-5">{contentMainform()}</CCardBody>
				</CCard>
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uIsFormprocess}
				onClick={()=>hdlKlikSimpan()}
				id="btnSimpan">Simpan [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleLookup()}
			dialogHeader={uDlglookupheader||"dialogHeader"}
			inputValue={uInpLookupKwd}
			dataItemArr={uDatalookupArr} 
			onKlikPilihItem={hdlKlikPilihLookup}
			id="iddialoglookup"/>
		</>
	)//>
}

export default Omzetlainform;
