import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CInputGroup,CFormSelect,CFormInput,CButton,
	//CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialoglookupapi,
	MyDialogform,
	MyDialogview,
	MyPagination,
	MyPage500,
} from '../components/index'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { UFunc,cBulanPanjang,cBulanPendek } from '../helpers/functions'
//import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Lappendapatan 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	//const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uSettinguserObj		= useSelector(state => state.gListUserSetting);
	const uSettingsystemObj		= useSelector(state => state.gSystemsetting);
	const uHTMLstyleTabel		= useSelector(state => state.gHTMLstyleLaporan);
	const [uEfekview,setEfekview]= React.useState(false);

	const uMaxData				= isMobile ? (uSettinguserObj.jml_mobile || 20) : (uSettinguserObj.jml_laporan || 100);

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelReset	 		= uHeaderActionObj.isHeaderReset || false;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatavieworiArr,setDatavieworiArr]= React.useState([]);
	const [uDataviewArr,setDataviewArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDataloadedObj,setDataloadedObj]	= React.useState();
	const [uTitelObj,setTitelObj]			= React.useState({});
	const [uDataidentitasObj,setDataidentitasObj]	= React.useState({});
	const [uDatapaymentArr,setDatapaymentArr]	= React.useState([]);
	const [uSortedObj,setSortedObj]			= React.useState({});

	//---DLGFORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//---END DLGFORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]	= React.useState({});
	//--END DLG_LOOKUP--/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewObj,setDlgviewObj]		= React.useState({});
	//--END DLG_VIEW--/

	//--DOM_ELEMENTS--/
	const uElJmldata	= document.getElementById("idjmldata");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_ELEMENTS--/

	//---HANDLE--/
	const hdlKDownCustomerlookup = (_EV) => {
		uDlgformObj.customer_id 	= undefined;

		if(_EV.keyCode !== 13) { setEfekview(!uEfekview); return }
		if((uDlgformObj.customer_caption||"").trim() === "") 
			{ setEfekview(!uEfekview); return }

		_EV.preventDefault(); _EV.stopPropagation();

		uDlglookupObj.is_triggered = true; setEfekview(!uEfekview);
		hdlKlikCustomerlookup();
	}
	const hdlKlikCustomerlookup = () => {
		uDlglookupObj.inputvalue= uDlgformObj.customer_caption||"";
		uDlglookupObj.header 	= (uBahasaObj.word_lookup||"word_lookup")+": "+(uBahasaObj.word_customer||"word_customer");
		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_SELECTEDSTR) => {
		_SELECTEDSTR = _SELECTEDSTR||"{}";
		//if(_SELECTEDSTR==="" || _SELECTEDSTR === "{}") return;
		const vLookupObj	= JSON.parse(_SELECTEDSTR);
		if(vLookupObj==={}) return;

		uDlgformObj.customer_id 		= parseInt(vLookupObj.id || "0");
		uDlgformObj.customer_caption	= vLookupObj.caption || "";

		setDlglookupshow(false);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			const vElsFocus	= document.getElementById("btnDialogSimpan");
			vElsFocus && vElsFocus.focus();
		},550);
	}
	const hdlKlikDlgformsimpan = () => {
		let vElfocus	= document.getElementById("inpjenis");
		if(uDlgformObj.jenis!=="HARIAN" && uDlgformObj.jenis!=="BULANAN") {
			vElfocus && vElfocus.focus();
			showToast("Jenis Laporan Tidak Valid !"); return
		} 

		if(uDlgformObj.jenis === "BULANAN") {
			vElfocus	= document.getElementById("inpbulanid");
			if((parseInt(uDlgformObj.bln_id)||0) <= 0) {
				vElfocus && vElfocus.focus();
				showToast("Periode Bulan Tidak Valid !"); return
			} 
			vElfocus	= document.getElementById("inptahun");
			if((parseInt(uDlgformObj.tahun)||0) <= 0) {
				vElfocus && vElfocus.focus();
				showToast("Periode Tahun Tidak Valid !"); return
			} 
			vElfocus	= document.getElementById("inpbulanid");
			const vTanggalpilihDT = UFunc.toDate(uDlgformObj.tahun+"-"+UFunc.leadZero(uDlgformObj.bln_id)+"-01");
			if(UFunc.DateIsBeyond(vTanggalpilihDT)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Bulan/Tahun Tidak Valid !"); return
			}
		} else {
			vElfocus	= document.getElementById("inptanggal");
			if(uDlgformObj.tanggal === "") {
				vElfocus && vElfocus.focus();
				showToast("Periode Tanggal Tidak Valid !"); return
			}

			const vTanggalDT = UFunc.toDate(uDlgformObj.tanggal);
			if(UFunc.DateIsBeyond(vTanggalDT)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Tanggal Belum Terjadi !"); return
			} 
		}
		/*vElfocus	= document.getElementById("inpcustomercaption");
		if((uDlgformObj.customer_caption||"").trim() !== "")
			if((parseInt(uDlgformObj.customer_id)||0) <= 0) {
				vElfocus && vElfocus.focus();
				showToast("Nama Customer TIDAK Dipilih Dengan Benar..<br/>Mohon Diperbaiki !"); return
			}//-*/

		apiLoadview();
	}
	const hdlKlikDetilitem = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vArridx	= UFunc.getObjectIndeks(uDataviewArr,_IDTABEL);
		if((uDataviewArr[vArridx].is_expand||false)===true) {
			uDataviewArr[vArridx].is_expand = false;
			setEfekview(!uEfekview);
			return;
		}

		if((uDataviewArr[vArridx].item_arr||[]).length > 0) {
			uDataviewArr[vArridx].is_expand = true;
			setEfekview(!uEfekview);
			return;
		}

		apiLoaditem(_IDTABEL);
	}
	const hdlKlikCetak = () => {
		if(uJmlData <= 0) return;

		let vContent 	= contentHTMLcetak();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;
		contentExcel();
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		uElJmldata && 
			(uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		if(uJmlData <= 0) return (
		<div className="text-center my-2 my-lg-5">
			<big className="fst-italic text-primary">
			{uBahasaObj.pesan_dataempty||"Data dengan Filter yang Dipilih Tidak Ditemukan.."}
			</big>
		</div>
		)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;

		const vColspan		= uDataloadedObj.jenis==="BULANAN" ? 7 : 6;
		const vDataviewArr	= uDataviewArr.slice(vMin,(vMax));

		const vStylekolfixed	= {
			position: "sticky",
			left: 0,
			backgroundColor:"#f0f0f0",
			zIndex: 1,
		}

		return (
		<div style={{overflowX: "auto"}}>
			<table style={{minWidth:1200,width:"100%"}} 
				className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={45} className="border" style={vStylekolfixed}>No</th>
			{uDataloadedObj.jenis==="BULANAN" ? (
			<>
				<th width={120} className="text-start border" style={{...vStylekolfixed,left:45}}>{uBahasaObj.word_tanggal||"Tanggal"}</th>
				<th width={300} className="text-start border">{uBahasaObj.word_customer||"Customer"}</th>
			</>
			) : (
			<>
				<th width={150} className="text-start border d-md-none" style={{...vStylekolfixed,left:45}}>{uBahasaObj.word_customer||"Customer"}</th>
				<th width={250} className="text-start border d-none d-md-table-cell" style={{...vStylekolfixed,left:45}}>{uBahasaObj.word_customer||"Customer"}</th>
			</>
			)}
			
			<th className="text-start border">{uBahasaObj.word_deskripsi||"Deskripsi"}</th>
			
			<th width={150} className=" border">
				{uBahasaObj.caption_ntransaksi||"N.Transaksi"} (Rp)

				<CLink onClick={()=>{
						if((uSortedObj.field||"")!=="nilai_bruto") 
							setSortedObj({ field: "nilai_bruto",mode:"ASC" })
						else {
							if(uSortedObj.mode===undefined) uSortedObj.mode = "ASC";
							else if(uSortedObj.mode==="ASC") uSortedObj.mode = "DESC";
							else setSortedObj({});
						}
						setEfekview(!uEfekview);
					}}
					className={"ms-1 classcursorpointer "+((uSortedObj.field||"")==="nilai_bruto"?"text-danger":"text-primary")}>
					<CIcon icon={(((uSortedObj.mode||"")==="DESC" && (uSortedObj.field||"")==="nilai_bruto") ?"cilArrowBottom":"cilArrowTop")} className="align-middle" height={20}/>
				</CLink>
			</th>

			<th width={120} className=" border">{uBahasaObj.word_diskon||"Diskon"} (Rp)</th>
			
			<th width={150} className=" border">
				{uBahasaObj.caption_ntagihan||"N.Tagihan"} (Rp)

				<CLink onClick={()=>{
						if((uSortedObj.field||"")!=="nilai_netto") 
							setSortedObj({ field: "nilai_netto",mode:"ASC" })
						else {
							if(uSortedObj.mode===undefined) uSortedObj.mode = "ASC";
							else if(uSortedObj.mode==="ASC") uSortedObj.mode = "DESC";
							else setSortedObj({});
						}
						setEfekview(!uEfekview);
					}}
					className={"ms-1 classcursorpointer "+((uSortedObj.field||"")==="nilai_netto"?"text-danger":"text-primary")}>
					<CIcon icon={(((uSortedObj.mode||"")==="DESC" && (uSortedObj.field||"")==="nilai_netto")?"cilArrowBottom":"cilArrowTop")} className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>

			<tr className="d-none"><th colSpan={vColspan}>{JSON.stringify(uDataloadedObj)}</th></tr>
			</thead>

			<tbody>
			{vDataviewArr.map((vItems,vKeys) => {
				const {
					id,tgl_pendapatan,no_nota,customer_caption,nilai_bruto,item_arr,
					nilai_netto,nilai_diskon,first_harga_satuan,first_qty,
					first_item_satuan,first_item_caption,first_pasien_caption,
					count_items,
					is_processing,is_expand
				} = vItems;

				vNumber++;

				const vDataitemsArr	= item_arr || [];

				return (
				<tr id={"idtr"+id} className={"align-top"+(uTabelRowSelect===vKeys?" classrowselect":"")} key={vKeys}>
				<td className="px-1 text-end" style={vStylekolfixed}>{vNumber}.</td>

				{uDataloadedObj.jenis==="BULANAN" ? (
				<>
					<td className="px-1 text-start" style={{...vStylekolfixed,left:45}}>
						{UFunc.TglAngka(tgl_pendapatan)}
					</td>
					<td className="px-1 text-start">
						<div className="d-flex justify-content-between">
							<small className="fw-bolder">{(customer_caption||no_nota)}</small>

							<div>
							{(is_processing||false)===true ? (
								<CSpinner color="primary" size="sm"/>
							) : (
							<CTooltip className="d-none d-lg-block" content={"--Detil Item: "+(no_nota||"").toUpperCase()}>
							<CLink className="classcursorpointer d-none" onClick={()=>hdlKlikDetilitem(id)}>
								<CIcon icon={(is_expand||false)===true ? "cilArrowTop" : "cilArrowBottom"} className="classikoncoreui align-middle" height={25}/>
							</CLink>
							</CTooltip>
							)}
							</div>
						</div>

						{(customer_caption||"")!=="" && (
						<div className="classfontsmaller">
							<small>{no_nota}</small>
						</div>
						)}
					</td>
				</>
				) : (
					<td className="px-1 text-start" style={{...vStylekolfixed,left:45}}>
						<div className="d-flex justify-content-between">
							<small className="fw-bolder">{(customer_caption||no_nota)}</small>

							<div>
							{(is_processing||false)===true ? (
								<CSpinner color="primary" size="sm"/>
							) : (
							<CTooltip className="d-none d-lg-block" content={"--Detil Item: "+(no_nota||"").toUpperCase()}>
							<CLink className="classcursorpointer d-none" onClick={()=>hdlKlikDetilitem(id)}>
								<CIcon icon={(is_expand||false)===true ? "cilArrowTop" : "cilArrowBottom"} className="classikoncoreui align-middle" height={25}/>
							</CLink>
							</CTooltip>
							)}
							</div>
						</div>
						
						{(customer_caption||"")!=="" && (
						<div className="classfontsmaller">
							<small>{no_nota}</small>
						</div>
						)}
					</td>
				)}

				<td className="text-start px-1">
				<small>
				<ol className="ps-4">
				{(is_expand||false)===false ? (
					<li className="text-muted fst-italic">
						{(first_pasien_caption||"")!=="" && (
						<>
						<span className="">{first_pasien_caption}</span>
						<span className="mx-1">&middot;</span>
						</>
						)}
						<i>{first_item_caption||"Undefined Items"}</i>

						<span className="ps-1">(...)</span>
					</li>
				) : (
				<>
					{vDataitemsArr.map((vItembarang,vKeybarang)=>{
						const {
							harga_satuan,qty,item_satuan,item_caption,
							pasien_caption,item_idx
						} = vItembarang;

						return (
						<li key={vKeybarang}>
						<div className="d-flex justify-content-between">
							<div>
								{(pasien_caption||"")!=="" && (
								<>
								<span className="text-primary">{pasien_caption}</span>
								<span className="mx-1">&middot;</span>
								</>
								)}
								<i>{item_caption}</i>
							</div>

							<div className="ps-1">(
								<b>{UFunc.formatAngka(qty)}</b>
								<span className="ms-1">{item_satuan||"UNT"}</span>
								<span className="mx-1">x</span>
								<b>{UFunc.formatAngka(harga_satuan)}</b>
							)</div>
						</div>
						</li>
						)
					})}
				</>
				)}
				</ol>
				</small>
				</td>
				
				<td className="text-end px-1">{UFunc.formatAngka(nilai_bruto)}</td>

				<td className="text-end px-1 text-danger">{UFunc.formatAngka(nilai_diskon)}</td>

				<td className="text-end px-1 fw-bolder text-success">{UFunc.formatAngka(nilai_netto)}</td>
				</tr>
				)
			})}
			</tbody>

			<tfoot className="border-top border-top-primary">
			<tr className="fw-bolder text-white bg-dark">
			<td colSpan={2} className="text-center bg-dark" style={{...vStylekolfixed}}>JUMLAH</td>
			<td className="text-center"/>
			{uDataloadedObj.jenis==="BULANAN" && (<td className="text-center"/>)}
			<td className="text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_bruto)}</td>
			<td className="text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_diskon)}</td>
			<td className="text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_netto)}</td>
			</tr>
			</tfoot>
			</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSettingsystemObj.jml_tahun_laporan)||5;

		return (
		<CForm>
		<CRow className="my-2">
		<CCol xs="3" md="4">Jenis Periode</CCol>
		<CCol>
			<CFormSelect size="sm"
				value={uDlgformObj.jenis}
				onChange={(e)=>{uDlgformObj.jenis=e.target.value; setEfekview(!uEfekview)}}
				id="inpjenis">
				<option value="HARIAN">HARIAN</option>
				<option value="BULANAN">BULANAN</option>
			})}
			</CFormSelect>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4">Periode</CCol>
		<CCol>
			{uDlgformObj.jenis === "BULANAN" ? (
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.bln_id}
				onChange={(e)=>{uDlgformObj.bln_id=e.target.value; setEfekview(!uEfekview)}}
				id="inpbulanid">
			{cBulanPanjang.map((vItems,vKeys)=>{
				return (
				<option value={vKeys+1} key={vKeys}>{vItems.toUpperCase()}</option>
				)
			})}
			</CFormSelect>
			
			<span className="me-1"/>

			<CFormSelect size="sm"
				value={uDlgformObj.tahun}
				onChange={(e)=>{uDlgformObj.tahun=e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inptahun">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
			) : (
			<CFormInput type="date" size="sm"
				value={uDlgformObj.tanggal}
				onChange={(e)=>{uDlgformObj.tanggal=e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inptanggal"/>
			)}
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom d-none"/>

		<CRow className="my-2 d-none">
		<CCol xs="3" md="4">Payment</CCol>
		<CCol>
			<CFormSelect size="sm"
				value={uDlgformObj.payment_id||0}
				onChange={(e)=>{uDlgformObj.payment_id=e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inppaymentid">
			<option value="0">--SEMUA METODE PEMBAYARAN--</option>
			{uDatapaymentArr.map((vItems,vKeys)=>{
				return (
					<option key={vKeys} value={vItems.id}>{(vItems.caption||"").toUpperCase()}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentDlgview = () => {//--TIDAK_DIPAKAI--/
		if(!uISDlgviewshow) return (<></>)//>

		const vArridx	= UFunc.getObjectIndeks(uDataviewArr,uTabelIDPilih);
		if(vArridx < 0) return (<></>)//>

		return (
		<small>
		<CRow className="align-items-stretch">
		<CCol lg="6">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Data Nota</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<div>Tgl Transaksi</div>
					<b>{UFunc.TglAngka(uDataviewArr[vArridx].tgl_pendapatan)}</b>
				</div>

				<div className="d-flex justify-content-between">
					<div>No.Nota</div>
					<div>{(uDataviewArr[vArridx].no_nota)}</div>
				</div>

				<div className="d-flex justify-content-between">
					<div>Customer</div>
					<b>{(uDataviewArr[vArridx].customer_caption||"-")}</b>
				</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol lg="6" className="mt-3 mt-lg-0">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Summary Transaksi</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<div>Jumlah Bruto (Rp)</div>
					<b>{UFunc.formatAngka(uDataviewArr[vArridx].nilai_bruto)}</b>
				</div>
				<div className="d-flex justify-content-between">
					<div>Diskon (Rp)</div>
					<b className="text-danger">{UFunc.formatAngka(uDataviewArr[vArridx].diskon)}</b>
				</div>
				<div className="d-flex justify-content-between">
					<div>Total (Rp)</div>
					<b className="text-success">{UFunc.formatAngka(uDataviewArr[vArridx].nilai_bruto)}</b>
				</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CRow className="mt-3">
		<CCol>
			<CCard>
			<CCardHeader className="py-0 pt-1 classfontsmaller">Data Item</CCardHeader>
			<CCardBody className="px-0">
			<table width="100%" className="table table-borderless table-striped table-hover" 
				id="idtabelitem">
			<thead className="align-top classfontsmaller text-center">
			<tr>
				<th width="5%">No</th>
				<th className="text-start">Item Transaksi</th>
				<th width="25%">Nilai (Rp)</th>
			</tr>
			</thead>
			
			<tbody>
			{(uDataviewArr[vArridx].item_arr||[]).map((vItems,vKeys)=>{
				const {
					harga_satuan,qty,item_satuan,item_caption,
					pasien_caption,item_idx
				} = vItems;

				return (
				<tr className="align-top" key={vKeys}>
				<td align="right">{vKeys+1}.</td>

				<td>
					<div>
						{(pasien_caption||"")!==""
							? (<><span className="text-primary">{pasien_caption}</span> &middot; </>)
							: ("")
						}
						<small>{item_caption}</small>
					</div>

					<small className="fst-italic d-block ms-2">
						&#8702; <b>{qty}</b> {item_satuan} x Rp<b>{UFunc.formatAngka(harga_satuan)}</b>
					</small>
				</td>

				<td align="right">
					<b className="text-success">{UFunc.formatAngka(parseInt(qty||"1") * parseFloat(harga_satuan))}</b>
				</td>
				</tr>
				)
			})}
			</tbody>
			</table>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</small>
		)//>
	}
	const contentHTMLcetak = () => {
		let vHTMLs	= uHTMLstyleTabel;

		//console.log("(Lappendapatan - contentHTMLcetak) uDataidentitasObj => "+(uDataidentitasObj));

		const vColspan 		= uDataloadedObj.jenis==="BULANAN" ? 7 : 6;
		const vDataviewArr	= uDataviewArr;

		vHTMLs	+= UFunc.RenderTabel(uDataidentitasObj,vColspan);
		
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()];
		const vJudulsub		= `
			<div>Periode `+
			(uDataloadedObj.jenis==="BULANAN" ? "Bulan" : "Tanggal")+
			` <b>`+(uTitelObj||{}).periode+`</b></div>
		`;

		const vTabelheader	= `
			<th width="5%">No</th>`+
			(uDataloadedObj.jenis==="BULANAN" 
				? `<th width="11%" align="left">`+(uBahasaObj.word_tanggal||"Tanggal")+`</th>`+
				``
				: ``)+
			`<th width="20%" align="left">Customer</th>
			<th align="left">`+(uBahasaObj.word_deskripsi||"Deskripsi")+`</th>
			<th width="11%">N.Transaksi (Rp)</th>
			<th width="11%">Diskon (Rp)</th>
			<th width="11%">N.Total (Rp)</th>
			</tr>
		`;
		let vTabelbody	= "";
		let vNumber		= 0;
		vTabelbody 		+= vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_pendapatan,no_nota,customer_caption,nilai_bruto,nilai_diskon,
				nilai_netto,item_arr,
			} = vItems;

			vNumber++;

			const vDataitemsArr = item_arr || [];

			return (`
				<tr valign="top" key=`+vKeys+` style="font-size:smaller;">
				<td align="right">`+vNumber+`.</td>`+
				(uDataloadedObj.jenis==="BULANAN" 
					? `<td align="left">`+UFunc.TglAngka(tgl_pendapatan)+`</td>`+
					``
					: ``)+`
				<td align="left">
					<small style="font-weight:bolder">`+(customer_caption||no_nota)+`</small>`+
					((customer_caption||"")!=="" 
						? `<div>`+
							`<small>`+no_nota+`</small>`+
							`</div>`+
						``
						:``)+`
				</td>
				<td align="left">
				<small>
				<ol style="padding-left:9px">`+
				(vDataitemsArr.map((vItembarang,vKeybarang)=>{
					const {
						harga_satuan,qty,item_satuan,item_caption,
						pasien_caption,item_idx
					} = vItembarang;

					return (`
						<li key=`+vKeybarang+`>`+

						((pasien_caption||"")!=="" ? `
						<span>`+pasien_caption+`</span>
						<span> - </span>
						` : ``)+

						`<i>`+item_caption+`</i>
						<span> (<b>`+UFunc.formatAngka(qty)+`</b> `+
							item_satuan+
							` x `+
							`<b>`+UFunc.formatAngka(harga_satuan)+`</b>)</span>
						</li>
					`)
				}).join(""))+
				`</small>
				</ol>
				</td>
				<td align="right"><b>`+UFunc.formatAngka(nilai_bruto)+`</b></td>
				<td align="right">`+UFunc.formatAngka(nilai_diskon)+`</td>
				<td align="right">`+UFunc.formatAngka(nilai_netto)+`</td>
				</tr>
			`);
		}).join("");
		
		const vTabelfooter 	= `
			<td colspan="`+(vColspan-3)+`">JUMLAH</td>
			<td align="right">`+UFunc.formatAngka(uDataloadedObj.sum_nilai_bruto)+`</td>
			<td align="right">`+UFunc.formatAngka(uDataloadedObj.sum_nilai_diskon)+`</td>
			<td align="right">`+UFunc.formatAngka(uDataloadedObj.sum_nilai_netto)+`</td>
		`;

		vHTMLs 		= vHTMLs.replace(/_TITELSUB_/g,vJudulsub);
		vHTMLs 		= vHTMLs.replace(/_JUDULLAPORAN_/g,vJudullaporan);
		vHTMLs 		= vHTMLs.replace(/_TABELHEADER_/g,vTabelheader);
		vHTMLs 		= vHTMLs.replace(/_TABELBODY_/g,vTabelbody);
		vHTMLs 		= vHTMLs.replace(/_TABELFOOTER_/g,vTabelfooter);

		return vHTMLs;
	}
	const contentExcel = () => {
		const vBulanID	= parseInt((uDataloadedObj||{}).bln_id)||"0";
		const vTahun	= parseInt((uDataloadedObj||{}).tahun)||"0";

		const vColspan 	= (uDataloadedObj.jenis==="BULANAN") ? 7 : 6;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 10;

		let vTmpObj	= (uDataloadedObj.jenis==="BULANAN") ? ({
			no : "",
			tgl_pendapatan: "",
			customer_caption: "",
			deskripsi: "",
			nilai_bruto: "",
			nilai_diskon: "",
			nilai_netto: "",
		}) : ({
			no : "",
			customer_caption: "",
			deskripsi: "",
			nilai_bruto: "",
			nilai_diskon: "",
			nilai_netto: "",
		})
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDataviewArr.forEach((vItems,vKeys)=>{
			const {
				id,tgl_pendapatan,no_nota,customer_caption,item_arr,
				nilai_bruto,nilai_diskon,nilai_netto,
			} = vItems;

			vColposwidth = (customer_caption||"").length > vColposwidth 
				? (customer_caption||"").length : vColposwidth; 


			const vCustomer =
				((customer_caption||"")===""?"":(customer_caption+"\n\r"))+
				 (no_nota||"UNDF")+
			"";
			const vDeskripsi = 
			(item_arr||[]).map((vItembarang,vKeybarang)=>{
				const {
					harga_satuan,qty,item_satuan,item_caption,
					pasien_caption,item_idx
				} = vItembarang;
					
				return (
				(vKeybarang+1)+". "+
				((pasien_caption||"")!=="" 
						? pasien_caption+" - "+
						""
						: "")+

					item_caption+
					" ("+UFunc.formatAngka(qty)+" "+
						item_satuan+
						" x "+UFunc.formatAngka(harga_satuan)+
						")"+
				(vKeybarang < (item_arr||[]).length - 1 ? "\n\r" : "")+
				"");
			}).join("")+
			"";

			vTmpObj	= (uDataloadedObj.jenis==="BULANAN") ? ({
				no : {v:vKeys+1,t:"n", s:{alignment:{vertical:"top"}} },
				tgl_pendapatan: { v:UFunc.toDate(tgl_pendapatan), t:"d", s:{alignment:{horizontal:"left",vertical:"top"}} },
				customer_caption: { v:vCustomer, t:"s", s:{alignment:{vertical:"top",wrapText:true}} },
				deskripsi: { v:vDeskripsi, t:"s", s:{alignment:{vertical:"top",wrapText: true}} },
				nilai_bruto: { v:parseFloat(nilai_bruto || "0"), t:"n", s:{numFmt: "#,##0",alignment:{vertical:"top"}} },
				nilai_diskon: { v:parseFloat(nilai_diskon || "0"), t:"n", s:{numFmt: "#,##0",alignment:{vertical:"top"}} },
				nilai_netto: { v:parseFloat(nilai_netto || "0"), t:"n", s:{numFmt: "#,##0",alignment:{vertical:"top"}} },
			}) : ({
				no : {v:vKeys+1,t:"n", s:{alignment:{vertical:"top"}} },
				customer_caption: { v:vCustomer, t:"s", s:{alignment:{vertical:"top",wrapText:true}} },
				deskripsi: { v:vDeskripsi, t:"s", s:{alignment:{vertical:"top",wrapText: true}} },
				nilai_bruto: { v:parseFloat(nilai_bruto), t:"n", s:{numFmt: "#,##0",alignment:{vertical:"top"}} },
				nilai_diskon: { v:parseFloat(nilai_diskon || "0"), t:"n", s:{numFmt: "#,##0",alignment:{vertical:"top"}} },
				nilai_netto: { v:parseFloat(nilai_netto || "0"), t:"n", s:{numFmt: "#,##0",alignment:{vertical:"top"}} },
			}) 

			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			tgl_pendapatan: "",
			customer_caption: "",
			deskripsi: "",
			nilai_bruto: { v:parseFloat(uDataloadedObj.sum_nilai_bruto)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_diskon: { v:parseFloat(uDataloadedObj.sum_nilai_diskon)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_netto: { v:parseFloat(uDataloadedObj.sum_nilai_netto)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		if(uDataloadedObj.jenis!=="BULANAN") delete vTmpObj.tgl_pendapatan;

		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:32}]
		const wscols	= [
			{wpx:40},
			{wpx:75},
			{wpx:150},
			{wpx:300},
			{wpx:85},
			{wpx:85},
			{wpx:85},
			{wpx:85},
		];
		if(uDataloadedObj.jenis!=="BULANAN") wscols.splice(1,1);
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 5}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_Lappenjualan_"+
			(uDataloadedObj.jenis==="BULANAN"
				? (cBulanPendek[vBulanID-1]).toUpperCase()+"_"+vTahun+
				""
				: (uDataloadedObj.tanggal||"").replace("-","")+
				"")+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+(uDataloadedObj.jenis!=="BULANAN" ? "Bulan ": "Tanggal ")+(uTitelObj||{}).periode+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tanggal",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Customer",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Deskripsi",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Transaksi (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Diskon (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Total (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];
		if(uDataloadedObj.jenis!=="BULANAN") vHeaderArr.splice(1,1);

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//---END CONTENT--/

	//---API--/
	const apiLoadinit = () => {
		setHTMLError500();
		setDatapaymentArr([]);
		setDataloadedObj();
		setSortedObj({});
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_laphtps/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Lappendapatan-apiLoadinit) output_string.databiaya : "+(output_string.databiaya));
				setDataidentitasObj(JSON.parse(output_string.dataidentitas || "{}"));
				//setDatapaymentArr(JSON.parse(output_string.datapayment || "[]"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lappendapatan-apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lappendapatan-apiLoadinit) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadview = () => {
		const vElsimpan	= document.getElementById("btnDialogSimpan");
		setDataloadedObj();
		setDatavieworiArr([]);
		setSortedObj({});
		setPageActive(1);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);

		vElsimpan && (vElsimpan.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parjson: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lappendapatan/ld_view";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			//console.log("(Lappendapatan-apiLoadview) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = (JSON.parse(output_string.datalaporan || "[]"));
				vTmpArr.map((vItems,vKeys)=>{
					const vDataitemsArr = vItems.item_arr || [];
					vItems.first_harga_satuan = vDataitemsArr[0].harga_satuan;
					vItems.first_qty = vDataitemsArr[0].qty;
					vItems.first_item_satuan = vDataitemsArr[0].item_satuan;
					vItems.first_item_caption = vDataitemsArr[0].item_caption;
					vItems.first_pasien_caption = vDataitemsArr[0].pasien_caption

					//if(vKeys < 7) 
					vItems.is_expand = true;
				})
				setDatavieworiArr([{firstrow:1},...vTmpArr]);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);

				setDataloadedObj(uDlgformObj);
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lappendapatan-apiLoadview) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Lappendapatan - apiLoadview) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDataviewArr,_IDTABEL);
		uDataviewArr[vArridx].is_processing = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid: _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lappendapatan/ld_item";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			uDataviewArr[vArridx].is_processing = false;
			//console.log("(Lappendapatan-apiLoaditem) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vDetildataArr 			= JSON.parse(output_string.dataitem || "[]");

				uDataviewArr[vArridx].item_arr 	= vDetildataArr;
				uDataviewArr[vArridx].is_expand = true;
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lappendapatan-apiLoaditem) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDataviewArr[vArridx].is_processing = false;
			console.log("(Lappendapatan - apiLoaditem) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadinit();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDlgformshow(false);
			setDlglookupshow(false);
			setDlgviewshow(false);

			setDataloadedObj();
			setTitelObj({});
			setDataviewArr([]);
			setDatavieworiArr([]);
			setDatapaymentArr([]);
			setSortedObj({});
			setDataidentitasObj({});
			setJmlData(0);setJmlHal(0);
		}
	},[])
	React.useEffect(()=>{ 
		setTabelIDPilih(0);
		setTabelRowSelect(-1); 
	},[uPageActive]);
	React.useEffect(()=>{
		const vDatadefaultArr = uDatavieworiArr.slice(1);
		setDataviewArr(vDatadefaultArr);
	},[uDatavieworiArr,uDatavieworiArr.length])
	React.useEffect(()=>{
		setJmlData(uDataviewArr.length);

		const vSumnilainetto = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.nilai_bruto)||0)
		}, 0);
		const vSumnilaidiskon = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.nilai_diskon)||0)
		}, 0);
		const vSumnilaibayar = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.nilai_netto)||0)
		}, 0);

		uDataloadedObj && (setDataloadedObj({...uDataloadedObj,
					sum_nilai_bruto: vSumnilainetto,
					sum_nilai_diskon: vSumnilaidiskon,
					sum_nilai_netto: vSumnilaibayar,
				}));
	},[uDataviewArr,uDataviewArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;

		const vTanggalnowDT	= new Date();
		if(uDlgformObj.bln_id === undefined)
			uDlgformObj.bln_id = uDataloadedObj ? uDataloadedObj.bln_id : vTanggalnowDT.getMonth()+1;
		if(uDlgformObj.tahun === undefined)
			uDlgformObj.tahun = uDataloadedObj ? uDataloadedObj.tahun : vTanggalnowDT.getFullYear();
		if(uDlgformObj.jenis === undefined)
			uDlgformObj.jenis = uDataloadedObj ? uDataloadedObj.jenis : "HARIAN";
		if(uDlgformObj.tanggal === undefined)
			uDlgformObj.tanggal = uDataloadedObj ? uDataloadedObj.tanggal : UFunc.DbDate(vTanggalnowDT);
		if(uDlgformObj.payment_id === undefined)
			uDlgformObj.payment_id = uDataloadedObj ? uDataloadedObj.payment_id : 0;
		
		uDlgformObj.header = "Pilih Filter Laporan";
		setDlgformshow(true);
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderReset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uDataloadedObj) apiLoadinit()
		else { setDatavieworiArr([]);setDataviewArr([]); setDataloadedObj(); }
	},[uHandelReset]);
	React.useEffect(()=>{
		const vElfilter	= document.getElementById("btnFilter");
		if(!uISDlgformshow) { 
			vElfilter && (vElfilter.disabled = false)
			setDlgformObj({}); return 
		}

		vElfilter && (vElfilter.disabled = true)
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(isMobile) return;

			const vElfocus = document.getElementById("inpjenis");
			if(!isMobile) vElfocus && vElfocus.focus();
		},350);
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uISDlglookupshow)
			{ setDlglookupObj({}); return }
	},[uISDlglookupshow]);
	React.useEffect(()=>{
		if(!uDataloadedObj) { setTitelObj({}); return }

		uTitelObj.periode 	= 
			uDataloadedObj.jenis === "BULANAN"
			? cBulanPanjang[parseInt(uDataloadedObj.bln_id)-1]+" "+uDataloadedObj.tahun
			: UFunc.TglAngka(uDataloadedObj.tanggal);

		if((parseInt(uDataloadedObj.payment_id)||0) < 0)  uTitelObj.subtitel = "SEMUA METODE";
		else {
			const vArridx = UFunc.getObjectIndeks(uDatapaymentArr,uDataloadedObj.payment_id);
			uTitelObj.subtitel = vArridx >= 0 
				? "Metode: "+(uDatapaymentArr[vArridx].caption||"") : "SEMUA METODE PEMBAYARAN";
		}
	},[uDataloadedObj]);
	React.useEffect(()=>{
		if(uDatavieworiArr.length <= 0) return;

		if(uPageActive > 1) setPageActive(1);
		//else { setTabelRowSelect(-1); setTabelIDPilih(0); }

		if(UFunc.isEmpty(uSortedObj)) { 
			const vDatadefaultArr = uDatavieworiArr.slice(1);
			setDataviewArr(vDatadefaultArr);
			return
		}

		if(uSortedObj.mode==="ASC") {
			uDataviewArr.sort((a,b)=> a[uSortedObj.field] - b[uSortedObj.field])
		} else if(uSortedObj.mode==="DESC") {
			uDataviewArr.sort((a,b)=> b[uSortedObj.field] - a[uSortedObj.field])
		}
	},[uSortedObj,
	uSortedObj.field,
	uSortedObj.mode
	]);
	
	//console.log("Lappendapatan - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )//>

	return (
	<>
		{(!uDataloadedObj) ? (
		<CCard className="classcardbg">
			<CCardHeader className="">
			<small className="fw-bolder">Pilih Filter Laporan</small>
			</CCardHeader>

			<CCardBody className="d-flex justify-content-center align-items-center" style={{minHeight:200}}>
			<div className="classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between">
			<div>
			<div className="">Periode <b>{(uTitelObj||{}).periode}</b></div>
			<small className="text-primary d-none">{(uTitelObj||{}).subtitel}</small>
			</div>

			<div>
				<CLink onClick={()=>hdlKlikCetak()} className="classcursorpointer classikon classikoncetak"/>
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller">
					<small className="text-muted">
						{UFunc.formatAngka(uTimeElapsed)}ms
					</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialoglookupapi
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.header||"dialogHeader"}
			inputValue={uDlglookupObj.inputvalue||""}
			onKlikPilihItem={hdlKlikDlglookuppilih}
			targetApi={pjson.homepage+"api/api_customer/ld_lookup"} 
			kdownTrigger={uDlglookupObj.is_triggered || false}
			id="iddialoglookupapi"
			{...props}/>

		<MyDialogview
			renderContent={contentDlgview()}
			dialogHeader={uDlgviewObj.header || "Detil Item"}
			toggle={()=>setDlgviewshow(false)}
			options={{centered:true}}
			show={uISDlgviewshow}
			{...props}/>

		<iframe id="ifmcontentstoprint" title="FrameCetak" className="d-none"/>
	</>
	)//>
}	

export default Lappendapatan