import React from 'react';
//import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CForm,
	CInputGroup,
	CFormInput,
	CButton,
	CTooltip,
	CLink,
	CImage,
	//CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu, 
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';

const pjson     		= require('../../../package.json');
const Headersubtitipandp = (props) => {
	const uActiveroute 	= useSelector(state => state.activeRoute);
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	//const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uEfekapp			= useSelector(state => state.effectApp);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);

	const [uKeyword,setKeyword] = React.useState(uKeywordObj.kwd_titipandp || "");

	const hdlKeydown=(_EV)=>{
		if(_EV.which===13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari=()=>{
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_titipandp = uKeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset=async()=>{
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_titipandp = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		//prosesReset(); return;
		if((uKeyword||"").trim() !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}
	const hdlKliktambah = () => {
		uHeaderActionObj.isHeaderTambah = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}

	//console.log("(Headersubtitipandp) uIsEOMExecuted => "+uIsEOMExecuted);
	return(
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+uActiveroute+".png"}
				className="align-middle me-1" 
				height={30} />
			<small className="fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>
		
		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup 
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CFormInput id="inpkeyword" name="inpkeyword" 
					value={uKeyword} 
					onChange={(e) => setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKeydown(e)} 
					size="sm" 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} />
					<CButton size="sm" color="light" onClick={()=>hdlKlikCari()}
						className="border" 
						id="btnCari">
					<CIcon icon="cilMagnifyingGlass" />
					</CButton>
				</CInputGroup>
			</CForm>

			<span className="d-none d-md-block">
			<span className="mx-1 ms-2 d-none d-lg-inline">&middot;</span>
			{(uIsEOMExecuted) ? (
			<>
			<CTooltip content={(uBahasaObj.caption_tambahdata)+" "+(uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||"").toUpperCase()}>
				<CLink
					className="classcursorpointer classikon classikontambah" 
					onClick={()=>hdlKliktambah()}>
				</CLink>
			</CTooltip>
			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			</>
			) : (
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui classblinking" height={30}/>
			)}
		
			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink
					 className="classcursorpointer classikon classikonreset" 
					onClick={()=>hdlKlikReset()}/>
			</CTooltip>
			<span className="me-1">&middot;</span>
			<b id="idjmldata">0</b> {uBahasaObj.word_data||"word_data"}
			</span>

			<div className="d-md-none ms-1">
			{(uIsEOMExecuted) ? (
				<CLink className="classcursorpointer classikon classikontambah" 
					onClick={()=>hdlKliktambah()}/>
			) : (
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui classblinking" height={30}/>
			)}
			</div>
		</div>
		</>
	);
}

export default Headersubtitipandp;