import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CInputGroup,CInputGroupText,CFormCheck,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,
	CTooltip,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu, CDropdownDivider,
} from '@coreui/react';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogview,
	MyDialogform,
	MyDialoglookup,
	MyPagination,
	MyPage500,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Payroll = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uUsersettingObj		= useSelector(state => state.gListUserSetting);
	const [uEfekview,setEfekview]= React.useState(false);
	const uIsEOMExecuted		= useSelector(state => state.gIsEOMExecuted);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uUsersettingObj.jml_mobile || 20) : (uUsersettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 		= uHeaderActionObj.isHeaderTambah || false;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uDatabtkArr,setDatabtkArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);

	//--INIT_SELECT_BEFORE--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//--END INIT_SELECT_BEFORE--/

	//--DLGFORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]	= React.useState({});
	//--END DLGFORM--/

	//--DLGVIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]= React.useState({});
	//--END DLGVIEW--/

	//--DLGLOOKUP--/
	const uDataglkasArr 					= JSON.parse(localStorage.getItem("kasgllist")||"[]");
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]	= React.useState({});
	//--END DLGLOOKUP--/

	const uElJmldata			= document.getElementById("idjmldata");

	//---HANDLE--/
	const hdlDlglookuptoggle = () => { setDlglookupshow(false) }
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM||0); if(_IDITEM <= 0) return;

		const vArridx 	= UFunc.getObjectIndeks(uDataglkasArr,_IDITEM,"value");

		let vElstmp 	= document.getElementById("btnDialogSimpan")
		uDlgformObj.gl_kas_id 		= uDataglkasArr[vArridx].value||"0";
		uDlgformObj.gl_kas_caption	= uDataglkasArr[vArridx].caption||"";
		setEfekview(!uEfekview);

		hdlDlglookuptoggle();
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElnextfocus = vElstmp;
			vElnextfocus && vElnextfocus.focus();
		},350)
	}
	const hdlDlgformtoggle = () => { setDlgformshow(false) }
	const hdlKDownkascaption = (_EV) => {}
	const hdlKlikKaslookup = () => {
		const vTmpArr	= uDataglkasArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		//console.log("(Payroll - hdlKlikKaslookup) vTmpArr => "+JSON.stringify(vTmpArr));
		uDlglookupObj.data_arr 	= vTmpArr || [];
		uDlglookupObj.header	= uBahasaObj.word_pilih+": "+
			(uBahasaObj.caption_akunkas||"").toUpperCase();
		setDlglookupshow(true)
	}
	const hdlDlgformsimpan = () => {
		let vElfocus	= document.getElementById("inptglpenggajian")
		if((uDlgformObj.tgl_penggajian||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Rencana Tgl Penggajian Tidak Valid (1) !"); return;
		}
		const vTanggalDT = UFunc.toDate(uDlgformObj.tgl_penggajian);
		if(UFunc.DateIsMundur(vTanggalDT)) {
			vElfocus && vElfocus.focus();
			showToast("Rencana Tgl Penggajian Tidak Valid (2) !"); return;
		}
		vElfocus	= document.getElementById("inpkascaption")
		if(parseInt(uDlgformObj.gl_kas_id || 0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Akun GL KAS Asal Harus DIPILIH !"); return;
		}

		apiProsessimpan();
	}
	const hdlKlikViewkomponen = (_IDTABEL) => {
		_IDTABEL	= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx =  UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		uDlgviewdataObj.header 		= "Komp.Payroll: <b>"+UFunc.TglAngka(uDatamainArr[vArridx].tgl_penggajian)+"</b>";
		uDlgviewdataObj.data_obj 	= uDatamainArr[vArridx];
		setDlgviewshow(true);
	}
	const hdlKlikEditdata = (_IDTABEL) => {
		_IDTABEL	= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);

		const vTmpObj	= { initID:_IDTABEL, initPage:uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj});

		uNavigate("/subkaryawan/payroll/formpayroll/"+_IDTABEL);
	}
	const hdlKlikHapusdata = async(_IDTABEL) => {
		_IDTABEL	= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = UFunc.TglAngka(uDatamainArr[vArridx].tgl_penggajian).toUpperCase();
		initSelectrow(_IDTABEL);

		if(await(Konfirm(
			"Hapus Payroll: <b>"+vCaptionupper+"</b>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiHapusdata(_IDTABEL);
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		const vKeyword = uKeywordObj.kwd_payroll || "";
		if (vKeyword.length < 3) {
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
			return;
		} 

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setEfekview(!uEfekview)
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_payroll || "");
		const vKeywordcolor = uUsersettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vKolom		= parseInt(100/uDatabtkArr.length);
		const vDataviewArr	= uDatamainArr.slice(vMin,vMax);
		//console.log("(Payroll - contentMain) vDataviewArr : "+JSON.stringify(vDataviewArr));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={30} className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="1" className="">{uBahasaObj.caption_tglpayroll||"Tgl Payroll"}</CCol>
				<CCol md="1" className="">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol md="8" className="d-flex justify-content-between">
				{uDatabtkArr.map((vItembtk,vKeybtk)=>{
					return (
					<div key={vKeybtk} style={{width:vKolom+"%"}} className="text-center">
					{vItembtk.nama_gl||"Undf"} (Rp)
					</div>
					)
				})}
				</CCol>
				<CCol md="1" className="">N.Total<div>(Rp)</div></CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr style={{maxHeight:3}} className="d-md-none text-center">
			<td width={35} className="p-0">&nbsp;</td>
			<td className="p-0">&nbsp;</td>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				tgl_penggajian,btk_arr,status_jurnal,id
			} = vItems;

			const vCaptionupper = UFunc.TglAngka(tgl_penggajian).toUpperCase();
			const vTglPenggajian= vKeyword==="" ? UFunc.TglAngka(tgl_penggajian) 
				: (UFunc.TglAngka(tgl_penggajian)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			const vISSudahjurnal= (status_jurnal||"BELUM")!=="SUDAH" ? false : true;
			const vBtkArr		= btk_arr || [];
			const vTotalnilai= vBtkArr.reduce((a,b)=>{
				return a + parseFloat(b.nilai_btk || 0)
			},0);			

			vNumber++;
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end classfontsmaller"+((uTabelRowSelect===vKeys && !isMobile)?" classrowselect":"")}>
				<td align="right" className="px-1">{vNumber}.</td>
				<td className="px-1">
				<CRow className={"mx-0"+(vISSudahjurnal?" text-secondary":"")}>
				<CCol xs="12" md="1" className="px-1 text-start d-flex justify-content-between">
					<div>{UFunc.renderHTML(vTglPenggajian)}</div>

					<CDropdown className="d-md-none">
					<CDropdownToggle className="p-0" caret={false} color="transparent">
						<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu className="py-0">
						<CDropdownItem onClick={()=>hdlKlikViewkomponen(id)}>
							<CIcon icon="cilSearch" className="classikoncoreui align-middle mx-0 me-2" height={25}/>
							Komponen Payroll
						</CDropdownItem>

						{uIsEOMExecuted && (
						<>
							<CDropdownDivider/>

							{(!vISSudahjurnal) ? (
							<>
							<CDropdownItem onClick={()=>hdlKlikEditdata(id)}>
								<CIcon className="classikonedit align-middle mx-0 me-2" height={25}/>
								Edit Data
							</CDropdownItem>

							<CDropdownItem onClick={()=>hdlKlikHapusdata(id)}>
								<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
								Hapus Payroll
							</CDropdownItem>
							</>
							) : (
							<CDropdownItem onClick={()=>hdlKlikEditdata(id)}>
								<CIcon className="classikondetil align-middle mx-0 me-2" height={25}/>
								Lihat Detil Payroll
							</CDropdownItem>
							)}
						</>
						)}
					</CDropdownMenu>
					</CDropdown>
				</CCol>
				
				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" md="1" className="px-1 text-md-center">
					<span className={(!vISSudahjurnal)
						? ("text-success fw-bolder") 
						: ("text-danger fst-italic")}>
					{status_jurnal||"BELUM"}
					</span>
				</CCol>

				<CCol md="8" className="d-none d-md-flex justify-content-between px-0">
				{vBtkArr.map((vItembtk,vKeybtk)=>{
					return (
					<div key={vKeybtk} style={{width:vKolom+"%"}}>
					{UFunc.formatAngka(vItembtk.nilai_btk)}
					</div>
					)
				})}
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_ntotal||"N.Total"} (Rp)</CCol>
				<CCol xs="7" md="1" className="px-1 fw-bolder">{UFunc.formatAngka(vTotalnilai)}</CCol>
				
				<CCol md="1" className="px-0 d-none d-md-block text-md-center">
					{(uIsEOMExecuted) && (
					<CTooltip content={"--"+(vISSudahjurnal?"Lihat Detil":"Edit Payroll")+": "+vCaptionupper}>
					<CLink className={"classcursorpointer classikontabel"+(vISSudahjurnal?" classikondetil classikoncoreui":" classikonedit")}
						onClick={()=>hdlKlikEditdata(id)}/>
					</CTooltip>
					)}

					{(!vISSudahjurnal && uIsEOMExecuted) && (
					<CTooltip content={"--Hapus Payroll: "+vCaptionupper}>
					<CLink className="classcursorpointer classikontabel classikonhapus"
						onClick={()=>hdlKlikHapusdata(id)}/>
					</CTooltip>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return ""

		return (
		<CForm>
		<CRow className="my-2">
		<CCol md="5" className="text-md-end classfontsmaller">Tgl Penggajian (Rencana)</CCol>
		<CCol md="7" className="">
			<CFormInput size="sm" type="date"
				value={uDlgformObj.tgl_penggajian}
				onChange={(e)=>{uDlgformObj.tgl_penggajian = e.target.value; setEfekview(!uEfekview)}}
				id="inptglpenggajian"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol md="5" className="text-md-end classfontsmaller">Data Nilai Payroll</CCol>
		<CCol md="7" className="">
			<CInputGroupText className="classfontsmaller fw-bolder bg-light">
			<CFormCheck size="sm" type="radio"
				value="LASTVALUE"
				name="inpjenisnilai"
				label="SET Nilai Sesuai Bulan Sebelumnya "
				checked={(uDlgformObj.jenisnilai||"LASTVALUE")==="LASTVALUE"}
				onChange={(e)=>{uDlgformObj.jenisnilai = e.target.value; setEfekview(!uEfekview)}}
				id="inpjenisnilailast"/>
			</CInputGroupText>
			<CInputGroupText className="mt-2 classfontsmaller fst-italic bg-light">
			<CFormCheck size="sm" type="radio"
				value="RESETVALUE"
				name="inpjenisnilai"
				label="Reset Semua Nilai Menjadi NOL"
				checked={(uDlgformObj.jenisnilai||"LASTVALUE")==="RESETVALUE"}
				onChange={(e)=>{uDlgformObj.jenisnilai = e.target.value; setEfekview(!uEfekview)}}
				id="inpjenisnilaireset"/>
			</CInputGroupText>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol md="5" className="text-md-end classfontsmaller">Akun Kas Asal</CCol>
		<CCol md="7" className="">
			<CInputGroup size="sm">
			<CFormInput size="sm" readOnly={true}
				className="bg-light"
				placeholder="--Pilih Akun Kas"
				value={uDlgformObj.gl_kas_caption||""}
				onClick={()=>hdlKlikKaslookup()}
				id="inpkascaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikKaslookup()}
				id="btnkasloopkup">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>);

		const vDataviewObj	= (uDlgviewdataObj||{}).data_obj || {};
		if(UFunc.isEmpty(vDataviewObj)) return (<></>);

		const vISSudahjurnal= (vDataviewObj.status_jurnal||"BELUM")==="SUDAH";
		const vItembtkArr	= vDataviewObj.btk_arr || [];
		const vTotalnilai	= vItembtkArr.reduce((a,b)=>{
			return a + parseFloat(b.nilai_btk || 0)
		},0);		

		return (
		<CCard>
		<CCardBody className={(vISSudahjurnal? "bg-light" : "")}>
			<CRow className="my-2">
			<CCol xs="4">{uBahasaObj.caption_tglpayroll || "Tgl Payroll"}</CCol>
			<CCol xs="8" className="text-end fw-bolder">{UFunc.TglAngka(vDataviewObj.tgl_penggajian)}</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4">{uBahasaObj.caption_statusjurnal || "Status Jurnal"}</CCol>
			{vISSudahjurnal ? (
			<CCol xs="8" className="text-end fst-italic text-danger">{vDataviewObj.status_jurnal}</CCol>
			) : (
			<CCol xs="8" className="text-end fw-bolder text-success">{vDataviewObj.status_jurnal}</CCol>
			)}
			</CRow>

			<div className="my-3 classborderbottom"/>

			<small className="d-block">
			{vItembtkArr.map((vItembtk,vKeybtk)=>{
				return (
				<CRow className="my-2" key={vKeybtk}>
				<CCol xs="6" className="text-end">{vItembtk.nama_gl || "GL Komponen"} (Rp)</CCol>
				<CCol xs="6" className="text-end">{UFunc.formatAngka(vItembtk.nilai_btk)}</CCol>
				</CRow>
				)
			})}
			</small>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="6">{uBahasaObj.caption_ntotal || "Nilai Total"} (Rp)</CCol>
			<CCol xs="6" className="text-end fw-bolder">{UFunc.formatAngka(vTotalnilai)}</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		);
	}
	//---END CONTENT--/

	//---API--/
	const apiLoaddata = () => {
		setHTMLError500();
		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		const vKeyword = uKeywordObj.kwd_payroll || "";

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDatamainArr(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payroll/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Payroll-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setDatabtkArr(JSON.parse(output_string.databtk || JSON.stringify(uDatabtkArr)));
				setDatamainArr(JSON.parse(output_string.dataobject || "[]"));

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payroll-apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Payroll-apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		const vElbutton	= document.getElementById("btnDialogSimpan");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);

			hdlDlgformtoggle();
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datajson : JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payroll/pr_tambah";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			//console.log("(Payroll-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				const vResponseObj = JSON.parse(output_string.dataresponse||"{}");
				//console.log("(Payroll-apiLoaddata) vResponseObj : "+JSON.stringify(vResponseObj));
				setDatamainArr([vResponseObj,...uDatamainArr]);

				hdlDlgformtoggle();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payroll - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Payroll - apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiHapusdata = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true);

		/*const vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);

			setTabelRowSelect(-1)
			uDatamainArr.splice(vArridx,1);
		},2500); return;//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payroll/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Payroll-apiHapusdata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				setTabelRowSelect(-1)
				uDatamainArr.splice(vArridx,1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payroll - apiHapusdata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Payroll - apiHapusdata) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit);
	},[uIsInitbefore]);
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;
		setInitbeforeprocess(false)

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoaddata();
		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]);
			hdlDlgformtoggle(); hdlDlglookuptoggle();setDlgviewshow(false);
		}
	},[])
	React.useEffect(()=>{
		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		if(!uHandelView) return;
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		setEfekview(!uEfekview);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		setEfekview(!uEfekview);

		if(uHTMLError500) return;

		uDlgformObj.header 	= "Input Penggajian Baru";
		setDlgformshow(true);
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!uISDlgformshow) { setDlgformObj({}); return }

		if(uDlgformObj.tgl_penggajian === undefined) {
			const vMonthnow		= (new Date()).getMonth();
			const vLastdateDT 	= new Date((new Date()).getFullYear(), vMonthnow + 1, 0, 0,0,0);
			uDlgformObj.tgl_penggajian = UFunc.DbDate(vLastdateDT);
		}
		if(uDlgformObj.jenisnilai === undefined) {
			uDlgformObj.jenisnilai = "LASTVALUE";
		}

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(!isMobile)
				document.getElementById("inptglpenggajian") &&
					document.getElementById("inptglpenggajian").focus();
		},300);
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uISDlglookupshow) { setDlglookupObj({}); return }
	},[uISDlglookupshow]);
	React.useEffect(()=>{
		if(!uISDlgviewshow) { setDlgviewdataObj({}); return }
	},[uISDlgviewshow]);
	
	//console.log("Payroll - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )

	return (
	<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_payroll || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={hdlDlgformtoggle}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewdataObj.header}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>hdlDlglookuptoggle()}
			dialogHeader={uDlglookupObj.header}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.data_arr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>
	</>
	)
}	

export default Payroll