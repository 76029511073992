import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CHeaderBrand,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import NumberFormat from 'react-number-format';
import {
	MyButton,
	MyDialogform,
	MyProfileDetil,
	MyPage500,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Pasienbooking = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	const { id } 			= useParams(); 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uIsEOMExecuted			= useSelector(state => state.gIsEOMExecuted);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uTimeelapse,setTimeelapse]		= React.useState(0); 

	const [uDatamainObj,setDatamainObj]		= React.useState({})

	//--DLG_FORM--/
	const [uDlgformheader,setDlgformheader]	= React.useState("")
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false)
	//--END DLG_FORM--/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {}
	const hdlKlikReset = () => {}
	const hdlToggleDlgform = () => {}
	const hdlKlikDlgformsimpan = () => {}
	const hdlKlikSimpanmain = () => {}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const loadContentnota = () => {}
	const loadContentCustomer = () => {}
	const loadContentitemform = () => {}
	const loadContentitem = () => {}
	const loadContentDlgform = () => {}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		//setDlgviewshow(false);
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	return () => {
    		setDatamainObj({});
    	}
	},[])
	//---END EFFECT--/

	console.log("(Pasienbooking) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={()=>uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.caption_formbooking||"Form Reservasi Booking").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		<CContainer fluid className="bg-white m-0 px-2" style={{minHeight:52}}>
			SUB HEADER
			<div>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
			{" "}&middot;{" "}
			<small className="align-top">Rp</small>
			<small className="align-middle text-success fw-bold display-6">0</small>
			</div>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
		<CContainer xxl className={"px-0"}>
			<CRow className="mx-0 mb-3">
			<CCol className="p-0 pe-2">
			<CCard>
			<CCardBody>{loadContentnota()}</CCardBody>
			</CCard>
			</CCol>

			<CCol className="p-0 ps-2">
			<CCard>
			<CCardBody>{loadContentCustomer()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>

			<CRow className="mx-0">
			<CCol className="p-0">
			<CCard>
			<CCardHeader>{loadContentitemform()}</CCardHeader>
			<CCardBody>{loadContentitem()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>
		</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				centered={true}
				iconname="cilCheckAlt"
				onClick={()=>hdlKlikSimpanmain()}
				id="btnSimpan">
				Simpan [F9]
			</MyButton>
		</CFooter>
		</div>

		<MyDialogform
			options={{centered:true,size:"lg"}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformheader}
			toggle={hdlToggleDlgform}
			renderContent={loadContentDlgform()}/>
		</>
	) 
}	

export default Pasienbooking