import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	//CForm,CFormInput,CInputGroup, CButton,
	CTooltip,
	CLink,
	CImage,
	//CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react'
//import CIcon from '@coreui/icons-react'
//import { UFunc } from '../../helpers/functions'
import { Konfirm } from '../../helpers/onConfirm';

const pjson 	= require('../../../package.json')
const Headersubtutupperiode = (props) => {
	/*const {
		setToken,setLoading,showToast 
	} = props;//-*/

	//--DOM--/
	//const uNavigate 		= useNavigate();
	const uDispatch 		= useDispatch();
	//--END DOM--/

	const uActiveroute		= useSelector(state => state.activeRoute);
	const uTokenObj			= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uEfekapp			= useSelector(state => state.effectApp);
	//const [uEfekview,setEfekview]= React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uKeyword,setKeyword] = React.useState(uKeywordObj.kwd_jurnal||"")

	//--HANDEL--/
	const hdlKlikReset = async() => {
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_jurnal = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}

	//console.log("Headersubtutupperiode - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-flex align-items-center">
			<CImage src={pjson.homepage+"api/images/menus_"+((uActiveroute||"").toLowerCase())+".png"}
				height={30} />
			<small className="ms-1 fst-italic">{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		</div>

		<div className="d-flex align-items-center mx-auto mx-lg-0">
			{uTokenObj.userhak === "FREEROLE" && (
			<CTooltip content={uBahasaObj.caption_refreshtampilan || "Refresh Tampilan"}>
				<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
			</CTooltip>
			)}
		</div>
		</>
	)
}

export default Headersubtutupperiode
