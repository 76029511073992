import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CForm,
	CInputGroup,
	CFormInput,
	CButton,
	CTooltip,
	CLink,
	CImage,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
//import { UFunc } from '../../helpers/functions'
import { Konfirm } from '../../helpers/onConfirm';

const pjson 	= require('../../../package.json')
const Headersubpayroll = (props) => {
	/*const {
		setToken,setLoading,showToast 
	} = props//-*/

	//--DOM--/
	//const uNavigate 		= useNavigate();
	const uDispatch 		= useDispatch();
	//--END DOM--/

	const uActiveroute		= useSelector(state => state.activeRoute);
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp			= useSelector(state => state.effectApp);
	//const [uEfekview,setEfekview]= React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uKeyword,setKeyword] = React.useState(uKeywordObj.kwd_payroll||"")

	//--HANDEL--/
	const hdlKDownkeyword = (_EV) => {
		if(_EV.which === 13) {
			_EV.stopPropagation();
			_EV.preventDefault();

			hdlKlikCari();
		}
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_payroll = uKeyword; 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
		//setEfekview(!uEfekview);
	}
	const hdlKlikTambah = () => {
		uHeaderActionObj.isHeaderTambah = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
		//setEfekview(!uEfekview);
	}
	const hdlKlikReset = async() => {
		const prosesReset = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_payroll = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setKeyword("");
			//---END CLEAR_KEYWORD--/
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
			//setEfekview(!uEfekview);
		}

		//prosesReset(); return;
		if((uKeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"confirm_reset")) prosesReset();
		} else prosesReset();	
	}

	//console.log("Headersubpayroll - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
			<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
				className="me-2"
				height={30}/>
			<small><i>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||"List Data"}</i></small>
		</div>

		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm>
				<CInputGroup 
					style={{minWidth:295,width:"auto"}} 
					size="sm">
				<CFormInput id="inpkeyword" name="inpkeyword" 
					value={uKeyword} 
					onChange={(e) => setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKDownkeyword(e)} 
					size="sm" 
					placeholder={"--"+(uBahasaObj.petunjuk_keyword || "petunjuk_keyword")+".."} />
				<CButton size="sm" color="light" 
					className="border" onClick={()=>hdlKlikCari()} id="btnCari">
					<CIcon icon="cilMagnifyingGlass" />
				</CButton>
				</CInputGroup>
			</CForm>

			<span className="ms-1 d-none d-md-block">
			<span className="mx-1 d-none d-lg-inline">&middot;</span>

			{(uIsEOMExecuted) ? (
			<>
			<CTooltip content={uBahasaObj.caption_tambahdata || "Tambah Data Payroll"}>
				<CLink className="classikon classikontambah classcursorpointer" 
					onClick={()=>hdlKlikTambah()}/>
			</CTooltip>
			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			</>
			) : (
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui classblinking" height={30}/>
			)}

			<CTooltip content={uBahasaObj.caption_resetview || "caption_resetview"}>
				<CLink className="classikon classikonreset classcursorpointer" onClick={()=>hdlKlikReset()}/>
			</CTooltip>

			<span className="d-none d-md-inline me-1">&middot;</span>
			<b id="idjmldata">0</b>{" "}{uBahasaObj.word_data||"word_data"}
			</span>

			<div className="d-md-none ms-1">
			{(uIsEOMExecuted) ? (
				<CLink className="classikon classikontambah" onClick={()=>hdlKlikTambah()}/>
			) : (
				<CImage src={pjson.homepage+"api/images/icon_eom.png"} className="classikoncoreui classblinking" height={30}/>
			)}
			</div>
		</div>
		</>
	)
}

export default Headersubpayroll
