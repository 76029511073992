import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CSpinner,
	CImage,
	CForm,CFormInput,CFormSelect,CFormTextarea,
	CDropdown,CDropdownToggle,CDropdownHeader,CDropdownMenu,CDropdownItem,CDropdownDivider,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyDialogview,
	MyPagination,
	MyPage500,
	MyFormHadir,
	MyDialogform,
	MyFormbooking,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson	= require('../../package.json');
const Pasien= (props) => {
	const uComponentname 	= Pasien.name;
	const {
		setLoading, showToast, prosesExpired, setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token")||"{}");

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);

	//--GLOBAL_VARS--/
	const uIsAllowedhapus	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN");

	const uMaxData			= isMobile ? 30 : 100;
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uFirstload,setFirstload]			= React.useState("YA");

	const uElJmlData			= document.getElementById("idjmldata");

	//---VIEW_DIALOG--/
	const [uIsShowDlgview,setShowDlgview]		= React.useState(false);
	const [uDlgviewheader,setDlgviewheader]		= React.useState("");
	//---END VIEW_DIALOG--/

	//---FORM_DIALOG--/
	const [uISDlgformshow,setDlgformshow]		= React.useState(false);
	const [uDlgformbookingsimpan,setDlgformbookingsimpan]= React.useState(false);
	const [uDlgformbookingdone,setDlgformbookingdone]= React.useState(false);
	const [uDlgformObj,setDlgformObj]			= React.useState({});
	//---END FORM_DIALOG--/

	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleDlgview = () => setShowDlgview(false);
	const hdlToggleDlgform = () => setDlgformshow(false);
	const hdlKlikPemilik = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		setTabelIDPilih(_IDTABEL);
		const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vObjdx].nama_pasien||"").toUpperCase();

		setDlgviewheader(uBahasaObj.caption_lihatdetil+" "+
			uBahasaObj.word_pemilik+": "+vCaptionupper);
		const vPemilikObj	= uDatamainArr[vObjdx].pemilik_obj || {};
		if(JSON.stringify(vPemilikObj)!=="{}") {
			setShowDlgview(true)
			return;
		}
		apiLoadpemilik(_IDTABEL);
	}
	const hdlKlikRekammedis=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		uDispatch({type: "set", gInitTabelSelected: {}});
		const vTmpObj	= { initID:_IDTABEL, initPage:uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj});

		uDispatch({type: "set", gStatePasien: window.location.pathname});
		uDatamainArr[vObjdx].isloading = true;
		uNavigate("/klinik/pasien/rekammedis/"+_IDTABEL);
	}
	const hdlKlikDaftar=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		setTabelIDPilih(_IDTABEL);
		const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vObjdx].nama_pasien||"").toUpperCase();

		uDlgformObj.id_pasien 	= _IDTABEL;
		uDlgformObj.header 		= "Booking Pasien: "+vCaptionupper;
		uDlgformObj.jenis 		= "BOOKING";
		setDlgformshow(true);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			if(!isMobile)
				document.getElementById("inptglbooking") &&
					document.getElementById("inptglbooking").focus();
		},250)
	}
	const hdlKlikHadir = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		setTabelIDPilih(_IDTABEL);
		const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vObjdx].nama_pasien||"").toUpperCase();

		uDlgformObj.header 	= "Set Kedatangan: "+vCaptionupper;
		uDlgformObj.jenis 	= "KEHADIRAN";
		setDlgformshow(true);
	}
	const hdlKlikDlgformsimpan = () => {
		if(uDlgformObj.jenis === "KEHADIRAN") {
			prosesKehadiran(); return;
		}
		if(uDlgformObj.jenis === "BOOKING") {
			prosesBooking(); return;
		}
	}
	const hdlKlikEdit=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		uDispatch({type: "set", gInitTabelSelected: {}});
		const vTmpObj	= {"initID":_IDTABEL,"initPage":uPageActive,}
		uDispatch({type: "set", gInitTabelSelected: vTmpObj});

		uDispatch({type: "set", gStatePasien: window.location.pathname});
		uDatamainArr[vObjdx].isloading = true;
		uNavigate("/klinik/pasien/forminput/"+_IDTABEL);
	}
	const hdlKlikHapus=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			"<B>"+uDatamainArr[vObjdx].nama_pasien.toUpperCase()+" ("+uDatamainArr[vObjdx].signalement+")</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiProseshapus(_IDTABEL);
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initReset = () => {
		setDatamainArr([]);
		setJmlData(0);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);
	}
	const initSelectrow=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setEfekview(!uEfekview)
	}
	const prosesKehadiran = () => {
		let vElFocus = document.getElementById("inppaymentcaption");
		if(parseInt(uDlgformObj.nilai_dp||"0") > 0)
			if(parseInt(uDlgformObj.payment_id||"0") <= 0) {
				vElFocus && vElFocus.focus();
				showToast((uBahasaObj.caption_metodebayar||"caption_metodebayar")+" "+(uBahasaObj.caption_mustchoose||"caption_mustfilled")+"..");
				return;
			}

		//alert("uDlgformObj.berat => "+parseFloat(uDlgformObj.berat));return;

		if((uDlgformObj.foto_arr||[]).length > 0) {
			(uDlgformObj.foto_arr||[]).map((vItems,vKeys)=>{
				const vBase64str = vItems.url || "";

				/*if(!UFunc.validateBase64image(vBase64str)) {
					showToast("Image Upload Tidak Valid (idx=>"+vKeys+") !");
					return;
				}//-*/

				UFunc.validateBase64image(vBase64str).
					then((vResultBl)=>{
						if(!vResultBl) {
							//console.log("(Pasien - prosesKehadiran)  vResultBl 1 => "+vResultBl);
							showToast("Image Upload Tidak Valid (idx=>"+vKeys+") !");
							return;
						}

						//console.log("(Pasien - prosesKehadiran)  vResultBl 2 => "+vResultBl);
						if(vKeys >= (uDlgformObj.foto_arr||[]).length-1) {
							//console.log("(Pasien - prosesKehadiran)  vKeys => "+vKeys);
							apiProseshadir();
						}
					});//-*/
			})
		} else apiProseshadir();
	}
	const prosesBooking = () => { setDlgformbookingsimpan(true) }
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_pasien || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataViewObj	= uDatamainArr.filter(vItems=> parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="6" className="text-start">{uBahasaObj.word_pasien||"word_pasien"}</CCol>
				<CCol md="4" className="text-start">{uBahasaObj.word_pemilik||"word_pemilik"}</CCol>
				<CCol md="2" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none classfontsmaller">
			<th width={25} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataViewObj.map((vItems,vKeys)=>{
			const {
				id,nama_pasien,signalement,customer_caption,customer_keyword,
				isloading,jenis_pasien,kode_pasien,isLoadingpemilik
			} = vItems;

			//console.log("("+uComponentname+" - contentMain) umur = "+umur);//---KALO_GA_ADA_YG_PAKE_uComponentname
			
			vNumber++;
			const vCaptionupper	= ((nama_pasien||"")).toUpperCase();

			const vSignalementfilter = (signalement||"").length >= 3 ? signalement : "";
			const vPasien = vKeyword==="" ? (nama_pasien||"") 
				: (nama_pasien||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vSignalement = vKeyword==="" ? (vSignalementfilter||"-") 
				: (vSignalementfilter||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vCustomercaption	= vKeyword==="" ? UFunc.capitalize(customer_caption||"") 
				: (UFunc.capitalize(customer_caption||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vCustomerKwd	= vKeyword==="" ? UFunc.capitalize(customer_keyword||"") 
				: (UFunc.capitalize(customer_keyword||"")).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vJenishewan = vKeyword==="" ? (jenis_pasien||"") 
				: (jenis_pasien||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKodepasien = vKeyword==="" ? (kode_pasien||"") 
				: (kode_pasien||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={((uTabelRowSelect===vKeys && !isMobile)?"classrowselect":"")}>
				<td align="right" className="px-1">{vNumber}.</td>
				<td className="text-end px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="6" className="px-1 text-start d-flex justify-content-between align-items-start">
				<div>
					<span className="text-info">{UFunc.renderHTML(vPasien)}</span>
					{(vSignalementfilter!=="") && (
					<>
					<span className="d-none d-md-inline mx-1">&middot;</span>
					<small className="fst-italic d-none d-md-inline">{UFunc.renderHTML(vSignalement)}</small>
					</>
					)}
				</div>

				<div className="d-md-none">
					{((isLoadingpemilik||false)===false) ? (
					<CDropdown>
					<CDropdownToggle color="transparent" className="p-0 m-0" 
						caret={false}>
						<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem 
							onClick={()=>hdlKlikDaftar(id)}>
							<CImage src={pjson.homepage+"api/images/menus_jadwal.png"} className="me-2" height={25}/>
							<span>{uBahasaObj.caption_daftartreatment}</span>
						</CDropdownItem>
						<CDropdownItem 
							onClick={()=>hdlKlikHadir(id)}>
							<CIcon className="classikontabel classikonarrived me-2" height={25}/>
							<span>{uBahasaObj.caption_setkedatangan||"caption_setkedatangan"}</span>
						</CDropdownItem>
						<CDropdownDivider className="mt-0" />
						<CDropdownItem 
							onClick={()=>hdlKlikPemilik(id)}>
							<CIcon icon="cilFindInPage" className="classikoncoreui me-2" height={23}/>
							<span>{uBahasaObj.caption_lihatdetil+" "+uBahasaObj.word_pemilik}</span>
						</CDropdownItem>
						<CDropdownItem 
							onClick={()=>hdlKlikRekammedis(id)}>
							<CIcon className="classikontabel classikonrm me-2" height={25}/>
							<span>{uBahasaObj.caption_viewrm}</span>
						</CDropdownItem>
						<CDropdownDivider className="mt-0" />
						<CDropdownItem 
							onClick={()=>hdlKlikEdit(id)}>
							<CIcon className="classikontabel classikonedit me-2" height={25}/>
							<span>{uBahasaObj.caption_editdata}</span>
						</CDropdownItem>
						<CDropdownItem 
							onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikontabel classikonhapus me-2" height={25}/>
							<span>{uBahasaObj.caption_hapusdata}</span>
						</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
					) : (<CSpinner color="info" size="sm"/>)}
				</div>
				</CCol>

				<CCol xs="12" className="d-md-none my-2 classborderbottom" />

				<CCol md="4" className="px-1 d-none d-md-block text-md-start">
					<b>{UFunc.renderHTML(vCustomercaption)}</b>
					{(customer_keyword||"")!=="" && (
					<>
					<span className="mx-1">&middot;</span>
					<small>{UFunc.renderHTML(vCustomerKwd)}</small>
					</>
					)}
					<span className="d-none d-md-inline ms-2">
						{((isLoadingpemilik||false)===false) ? (
						<CTooltip content={"--"+(uBahasaObj.caption_lihatdetil)+
							" "+(uBahasaObj.word_pemilik)+": "+vCaptionupper}>
							<CLink className="classcursorpointer"
								onClick={()=>hdlKlikPemilik(id)}>
							<CIcon icon="cilFindInPage" className="classikoncoreui" height={23}/>
							</CLink>
						</CTooltip>
						) : (<CSpinner color="info" size="sm"/>)}
					</span>
				</CCol>

				<CCol md="2" className="px-0 text-center d-none d-md-block">
					{(isloading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_daftartreatment||"caption_daftartreatment")+" "+
						": "+vCaptionupper}>
						<CLink className="classcursorpointer"
							onClick={()=>hdlKlikDaftar(id)}>
							<CImage src={pjson.homepage+"api/images/menus_jadwal.png"} className="classikoncoreui me-1" height={24}/>
						</CLink>
					</CTooltip>
					<CTooltip content={"--"+(uBahasaObj.caption_viewrm||"caption_viewrm")+" "+
						": "+vCaptionupper}>
						<CLink className="classcursorpointer"
							onClick={()=>hdlKlikRekammedis(id)}>
							<CIcon className="classikonrm classikoncoreui" height={24}/>
						</CLink>
					</CTooltip>

					<CDropdown autoClose={true}>
					<CDropdownToggle color="transparent"
						className="p-0 m-0" caret={false}>
						<CIcon icon="cilChevronBottom" className="classikoncoreui" height={23}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikHadir(id)}>
							<CIcon className="classikontabel classikonarrived me-2" height={25}/>
							{uBahasaObj.caption_setkedatangan}
						</CDropdownItem>

						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikEdit(id)}>
							<CIcon className="classikontabel classikonedit me-2" height={25}/>
							{uBahasaObj.caption_editdata||"caption_editdata"}
						</CDropdownItem>

						<CDropdownItem className="classcursorpointer"
							onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikontabel classikonhapus me-2" height={25}/>
							{uBahasaObj.caption_hapusdata||"caption_hapusdata"}
						</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
					</>
					)}
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_signalement||"Signalemen"}</CCol>
				<CCol xs="7" className="px-1 d-md-none fst-italic"><small>{UFunc.renderHTML(vSignalement)}</small></CCol>
				
				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_pemilik||"word_pemilik"}</CCol>
				<CCol xs="7" className="px-1 d-md-none fw-bolder">{UFunc.renderHTML(vCustomercaption)}</CCol>
				
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsShowDlgview) return (<></>)//>
		if(uTabelIDPilih <= 0) return (<></>)//>

		const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		//if(vObjdx < 0) return "";

		const vPemilikObj 	= uDatamainArr[vObjdx].pemilik_obj || {};
		const vPetsArr		= vPemilikObj.pets_arr || [];
		return (
		<small>
		<div className="d-none">{JSON.stringify(vPetsArr)}</div>
		<CRow className="mx-0">
		<CCol md="6" className="pe-md-1">
			<CCard className="mb-2">
			<CCardHeader className="py-1">{uBahasaObj.menus_customer}</CCardHeader>
			<CCardBody>
				<CRow className="mx-0">
				<CCol xs="5" className="px-0">{uBahasaObj.caption_namacustomer}</CCol>
				<CCol className="px-0 text-end fw-bolder">{uDatamainArr[vObjdx].customer_caption}</CCol>
				</CRow>

				<CRow className="mx-0">
				<CCol xs="5" className="px-0">{uBahasaObj.word_keyword}</CCol>
				<CCol className="px-0 text-end">{uDatamainArr[vObjdx].customer_keyword}</CCol>
				</CRow>

				<CRow className="mx-0">
				<CCol xs="4" className="px-0">{uBahasaObj.word_alamat}</CCol>
				<CCol className="px-0 text-end fst-italic"><small>{UFunc.renderHTML(vPemilikObj.alamat||"-")}</small></CCol>
				</CRow>
				
				<CRow className="mx-0">
				<CCol xs="4" className="px-0">{uBahasaObj.caption_telphp}</CCol>
				<CCol className="px-0 text-end text-primary">{UFunc.renderHTML(vPemilikObj.telp||"-")}</CCol>
				</CRow>
			</CCardBody>
			</CCard>

			<CCard className="mb-2">
			<CCardHeader className="py-1">{uBahasaObj.caption_saldodp}</CCardHeader>
			<CCardBody>
				<CRow className="mx-0">
				<CCol xs="4" className="px-0">{uBahasaObj.word_saldo}</CCol>
				<CCol className="px-0 text-end text-success">
					<big className="fw-bolder">{UFunc.formatAngka(vPemilikObj.saldo_dp)}</big>
				</CCol>
				</CRow>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol md="6" className="ps-md-1">
			<CCard>
			<CCardHeader className="py-1">{uBahasaObj.caption_petfamily}</CCardHeader>
			<CCardBody className="px-1">
				<ol className="mx-0">
				{vPetsArr.map((vItems,vKeys)=>{
					const {
						id,nama_pasien,signalement
					} = vItems;

					return (
					<li key={vKeys}>
						<b>{nama_pasien}</b>
						{(signalement||"")!=="" && (
						<>
						<span className="mx-1">&middot;</span>
						<span className="text-success">{signalement||uBahasaObj.word_lainnya}</span>
						</>
						)}
					</li>
					)
				})}
				</ol>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</small>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		if(uDlgformObj.jenis === "KEHADIRAN") return (
			<MyFormHadir
				objDataparse={uDlgformObj}
				{...props}/>
		)//>

		if(uDlgformObj.jenis === "BOOKING") return (
			<MyFormbooking
				objDataparse={uDlgformObj}
				isSimpan={uDlgformbookingsimpan}
				toggleSimpan={()=>setDlgformbookingsimpan(false)}
				funcDlgclose={()=>setDlgformbookingdone(!uDlgformbookingdone)}
				{...props}/>
		)//>

		return (<></>)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata=(_PAGE)=>{
		uFuncname 	= apiLoaddata.name;
		_PAGE		= parseInt(_PAGE)||1;
		setHTMLError500();

		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_pasien || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE,
			send_maxdata	: uMaxData,
			send_firstload	: vHandelview,
			send_tokenauth  : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]")
				vLoadArr.map(vItems=>vItems.pg = _PAGE);
				//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.dataobject));
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vLoadArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}

				setFirstload("TIDAK");
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500(vMsg);
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadpemilik = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; if(_IDTABEL<=0) return;
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vObjdx].isLoadingpemilik = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiSimpanbooking) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vObjdx].isLoadingpemilik = false;
			setShowDlgview(true);
		},3500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_pemilik";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vObjdx].isLoadingpemilik = false;
			if(output_string.tampil) {
				uDatamainArr[vObjdx].pemilik_obj = JSON.parse(output_string.pemiliklist||"{}");
				setShowDlgview(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-apiLoadpemilik) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjdx].isLoadingpemilik = false;
			console.log("("+uComponentname+"-apiLoadpemilik) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshadir = () => {
		uFuncname		= apiProseshadir.name;
		const vTabelID	= uTabelIDPilih;
		const vElbtn 	= document.getElementById("btnDialogSimpan");

		vElbtn && (vElbtn.disabled = true);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_listdata: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_hadir";

		console.log("("+uComponentname+"-"+uFuncname+") vURLs : "+vURLs);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElbtn && (vElbtn.disabled = false);

			if(output_string.sukses) {
				hdlToggleDlgform();
				showToast(uBahasaObj.pesan_sukses||"Berhasil !","SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			vElbtn && (vElbtn.disabled = false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus=(_IDTABEL)=>{
		uFuncname	= apiProseshapus.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		console.log("("+uComponentname+"-"+uFuncname+") vObjdx : "+vObjdx);

		uDatamainArr.splice(vObjdx,1);
		setTabelRowSelect(-1);
		setJmlData((parseInt(uDatamainArr.length)||0));
		setEfekview(!uEfekview);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_hapus";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{//--JIKA_PAGINATION_DIATUR_BACKEND--/
		//console.log("("+uComponentname+"-[uTabelSelectObj]) uTabelSelectObj = "+(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) {
			//console.log("("+uComponentname+"-[]) uTabelSelectObj = "+JSON.stringify(uTabelSelectObj));
			apiLoaddata();
		}

		return()=>{
			setDatamainArr([]); 
			hdlToggleDlgview();
			hdlToggleDlgform();
		}
	},[]);
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 
	},[uInitbeforeprocess]);
	React.useEffect(()=>{
		if(!uElJmlData) return;

		const vKeyword = uKeywordObj.kwd_pasien || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		const vKeyword 		= uKeywordObj.kwd_pasien || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData]);
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]); 
	React.useEffect(()=>{
		if(!uHandelView) return;

		initReset();
		if(uPageActive > 1) setPageActive(1);
		else apiLoaddata()

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uISDlgformshow) { setDlgformObj({});setDlgformbookingsimpan(false); return }
	},[uISDlgformshow]);
	React.useEffect(()=>{ setDlgformshow(false) },[uDlgformbookingdone]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname

	if(UFunc.isEmpty(uTokenObj))  { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_pasien || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>

			<div className="text-muted classfontsmaller">
			<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowDlgview} 
			toggle={hdlToggleDlgview}
			dialogHeader={uDlgviewheader}
			renderContent={contentDlgview()}
			{...props}/>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}
			{...props}/>
		</>
	)//>
}
	
export default Pasien;
