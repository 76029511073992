import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux';

import {
	CContainer,
	CHeader,
	CHeaderBrand,
	CHeaderDivider,
	CHeaderNav,
	CHeaderToggler,
	CImage,
	CCardHeader,
	CCardBody,
	CCard,
	CCardFooter,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { MyButton } from '../components/index'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json');
const Error500 	= (props) => {
	const uNavigate 		= useNavigate();
	const uDispatch			= useDispatch();
	const uLastURL  		= useSelector(state => state.gUrlbefore)||"";

	const hdlKlikRefresh=()=>{
		//const vLastURL	= localStorage.getItem("lasturl") || "";
		const vLastURL  	= uLastURL;
		//console.log("(Error500 - hdlKlikRefresh) vLastURL (1) = "+vLastURL)

		document.getElementById("btnRefresh") 
			&& (document.getElementById("btnRefresh").disabled = true);
		if(vLastURL==="")
			//{ uNavigate("/",{replace:true}); return; }
			{ window.location.assign("/"); return; }

    	uNavigate(-1);
		uDispatch({type: "set", gUrlbefore: undefined});
	}

	React.useEffect(()=>{
		return () => {
			document.getElementById("btnRefresh") 
				&& (document.getElementById("btnRefresh").disabled = false);
		}
	},[])

	return (
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid style={{height:64}} className="classheadermain border-bottom">
			<CHeaderToggler className="px-0"
				onClick={() => hdlKlikRefresh()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderNav className="me-auto">
				<b>Page Error 500</b>
			</CHeaderNav>
		</CContainer>
		</CHeader>
			
		<div className="body flex-grow-1">
			<CContainer fluid className="mb-3">
			<CCard className="classcardbg mx-lg-5 text-center">
			<CCardHeader className="py-1">
				<span className="classtitlelogin" style={{color:"#000346"}}>
					<CIcon className="classikonlogo align-middle me-0" 
						height={45}/>
					{pjson.name}
				</span>
				<span className="ms-1">v{pjson.version}</span>
			</CCardHeader>

			<CCardBody className="px-2 px-lg-5 py-1">
			<CImage src={window.location.origin+"/images/img_error500.png"} height={200}/>
			<div id="idcontentmsg">{UFunc.renderHTML((pjson.mydefault.msg500page||"UNDF").join(""))}</div>
			</CCardBody>
			
			<CCardFooter>
				<MyButton 
					iconname="cilReload" 
					id="btnRefresh"
					onClick={()=>hdlKlikRefresh()}>Refresh</MyButton>
			</CCardFooter>
			</CCard>
			</CContainer>
		</div>
		</div>
	)
}

export default Error500
