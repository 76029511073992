import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownHeader,
  CDropdownItem,
  CDropdownItemPlain,
  CDropdownDivider,
  CAvatar,
  CImage,
  CLink,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../helpers/onConfirm';
import { UFunc } from '../helpers/functions'

const pjson 			= require('../../package.json');
const ContHeaderProfile = (props) => {
	const {
		setToken, setLoading, showToast, clearStorage 
	} = props; 

	const uNavigate 	= useNavigate();
	const uDispatch 	= useDispatch();

	const uTokenobj		= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uListnav		= useSelector((state) => state.listNav) || []

	const [uShowindex,setShowindex]	= React.useState(-1);

	const uAvatardefault= () => {  
		switch((uTokenobj.userhak||"ENTRI").toUpperCase()) {
			case "FREEROLE":
				return pjson.homepage+"api/images/img_freerole.png";
				break
			case "ADMIN":
				return pjson.homepage+"api/images/img_admin.png";
				break
			case "DOKTER":
				return pjson.homepage+"api/images/img_dokter.png";
				break
			case "PARAMEDIS":
				return pjson.homepage+"api/images/img_paramedis.png";
				break
			default:
				return pjson.homepage+"api/images/img_entri.png";
		}
	}

	const contentMenuheader = () => {
		const vMenuheaderArr = uListnav.filter(vItems=>vItems.posisi !== "SIDEBAR");
		
		let vMenuextractArr = []
		let vTmpObj 		= {}
		let vChildrenArr 	= []
		let vKomponenbefore	= "";
		let vIdx			= 0;
		vMenuheaderArr.map((vItems,vKeys)=>{
			const { komponen, caption, src, to,name } = vItems

			if(vIdx > 1 && vKomponenbefore !== komponen) {
				vTmpObj.childrens = vChildrenArr;
				vMenuextractArr.push(vTmpObj);

				vTmpObj				= {}
				vChildrenArr		= []
				vIdx				= 0;
			}
			vIdx++;

			if(komponen==="CNavTitle") {
				vTmpObj = {
					name: name,
					caption: caption,
					src: (src||"")!==""?src:pjson.homepage+"api/images/menus_"+(name||"").toLowerCase()+".png",
					to: to,
				};
			} else {
				vChildrenArr.push({
					name: name,
					caption: caption,
					src: src,
					to: to,
				})
			}
			if(vKeys >= vMenuheaderArr.length-1) {
				vTmpObj.childrens = vChildrenArr;
				vMenuextractArr.push(vTmpObj);
			}
			vKomponenbefore = komponen;
		})//-*/
		//console.log("(ContHeaderProfile - contentMenuheader) vMenuextractArr => "+JSON.stringify(vMenuextractArr))

		const dropItem = (item, index) => {
			const { name, caption, src, to } = item

			return (
				<CDropdownItem key={index} 
					onClick={()=>uNavigate(to)}
					className="px-3 py-2 classcursorpointer">
				<CImage src={src} className="me-2 align-middle" height={25}/>
				{caption}
				</CDropdownItem>
			)//>
		}

		const dropMain = (item, index) => {
			const { caption, src, to,name } = item
			return (
				<CDropdownItem className={"p-0"} key={index}>
				<CDropdown autoclose={true} variant="nav-item"
					visible={index===uShowindex}
				    onMouseEnter={() => setShowindex(index)}
				    onMouseLeave={() => setShowindex(-1)}
					direction="dropstart" className="m-0 pe-2">
				<CDropdownToggle 
					caret={true}>
					<CImage src={(src||"")!==""?src:pjson.homepage+"api/images/menus_"+(name||"").toLowerCase()+".png"} className="mx-2 classverticalmiddle" height={18}/>
					{caption}
				</CDropdownToggle>
				<CDropdownMenu className="pt-0">
				{item.childrens?.map((item, index) => dropItem(item,index))}
				</CDropdownMenu>
				</CDropdown>
				</CDropdownItem>
			)
		}

		return (
		<>
		{vMenuextractArr &&
			vMenuextractArr.map((item, index) => dropMain(item, index))}
		</>
		)//>
	}
 
	const hdlKlikLogout	= async() => {
		if(await Konfirm(uBahasaObj.confirm_logout||"Apakah Yakin Akan Logout ?")) 
			ApiProseslogout();
	}

	const ApiProseslogout = () => {
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout	= setTimeout(() => {
			setLoading(false);
			clearStorage();
			showToast(uBahasaObj.pesan_hasloggedout||"Anda Telah Logout..");
			setToken("");
		},2000); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenobj.userinit,
			send_device	: pjson.name
		});
		const vURLs	= pjson.homepage+"api/api_login/pr_logout";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=>{ if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setLoading(false);
			if(output_string.sukses) {
		    	clearStorage();
				setToken("");
				showToast(uBahasaObj.pesan_hasloggedout||"Anda Telah Logout..");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(ContHeaderProfile - ApiProseslogout) output_string.errors: "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setToken("");
			}
		}) .catch((error) =>{
			setLoading(false);
			console.log("(ContHeaderProfile - ApiProseslogout) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(""),"ERROR");
		});
	}

	return (
		<>
		<CDropdown variant="nav-item">
		<CDropdownToggle placement="bottom-end" className="p-0" caret={false}>
			<CAvatar src={uAvatardefault()} size="35"/>
		</CDropdownToggle>
		<CDropdownMenu className="pt-0" placement="bottom-end">
        	<CDropdownHeader className="bg-light fw-semibold py-2 px-4 text-center">
	          	<div className="mb-2"><CIcon icon="cilUser" height={30} /></div>
				<strong>{(UFunc.Usercaptionfilter(uTokenobj.usercaption)).toUpperCase()}</strong>
				<div className="classfontsmaller">{"Hak Akses: "+(uBahasaObj["hakuser_"+(uTokenobj.userhak||"").toLowerCase()]||(uTokenobj.userhak||"")).toUpperCase()}</div>
        	</CDropdownHeader>

			<CDropdownItem className="py-1 classcursorpointer" 
				onClick={()=>uNavigate("/a1profile")}>
				<CIcon icon="cilUser" className="me-2 text-danger align-middle" height={20}/>
				{uBahasaObj.caption_profileanda||"Profile Anda"}
			</CDropdownItem>

			<CDropdownItem className="py-1 classcursorpointer" 
				onClick={()=>uNavigate("/updatedlogs")}>
				<CIcon icon="cilNotes" className="me-2 text-danger align-middle" height={20}/>
				Updated Logs
			</CDropdownItem>

			<CDropdownDivider className="mb-0"/>

			<CDropdownItem className="py-1 classcursorpointer" 
				onClick={()=>hdlKlikLogout()}>
				<CIcon icon="cilAccountLogout" className="me-2 text-danger align-middle" height={20}/>
			{uBahasaObj.caption_logout||"Logout Aplikasi"}
			</CDropdownItem>
		</CDropdownMenu>
		</CDropdown>
		</>
	)
}

export default ContHeaderProfile
	